import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function ReactES6modules() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }


    const code =`
    // example.js
    export const add = (a, b) => a + b;
    export const multiply = (a, b) => a * b;
       `;
  
    const code2 =`
    // example.js
    const subtract = (a, b) => a - b;
    export default subtract;
       `;

    const code3 =`
    // App.js
    import { add, multiply } from './example';
    import subtract from './example';
    
    console.log(add(2, 3)); // Output: 5
    console.log(multiply(2, 3)); // Output: 6
    console.log(subtract(5, 3)); // Output: 2
       `;

    const code4 =`
    src/
     components/
       Header.js
       Footer.js
     utils/
      api.js
      helpers.js
    App.js

    `

    const code5 =`
    // App.js
    import React from 'react';
    import Header from './components/Header';
    import Footer from './components/Footer';
    import { fetchData } from './utils/api';
    import { formatData } from './utils/helpers';
    
    const App = () => {
      // Your React component logic here
      return (
        <div>
          <Header />
          {/* Rest of your app */}
          <Footer />
        </div>
      );
    };
    
    export default App;
       `
    return (
   <body>
     <Helmet>
        
        <title>React ES6 Modules</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React ES6 Modules, 
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="" />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_es6"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-arrow-functioning"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>React ES6 Modules</h1>
</header>

<Navbar />

<main>
<section>

<p>React ES6 modules refer to the usage of ECMAScript 6 (ES6) modules within React applications. </p>
<p>ES6 modules provide a way to organize and structure your code by allowing you to import and export variables, functions, classes, or any other JavaScript entity across different files.</p>
<p>Here's an explanation of how ES6 modules are used in React:</p>
 <h2>Exporting from a Module</h2>
 <p>You can export entities (variables, functions, classes) from a module using the 'export' keyword. </p>
 <p>There are two main ways to export entities:</p>

 <h3>Named Exports: Exporting individual entities by name.</h3>
 <SyntaxHighlighterComponent code={code} language="js" />

 <h3>Default Export: Exporting a single entity as the default export of the module.</h3>
 <SyntaxHighlighterComponent code={code2} language="js" />

 <h2>Importing into a Module</h2>
 <p>You can import exported entities from other modules using the 'import' keyword.</p>
 <SyntaxHighlighterComponent code={code3} language="js" />

 <h2>Using ES6 Modules in React</h2>
 <p>In a React application, you can use ES6 modules to organize your components, utility functions, constants, 
    and other resources into separate files and import them as needed.</p>
 <p>For example, you might have a file structure like this:</p>
 <SyntaxHighlighterComponent code={code4} language="js" />
 <br />
<p>In your App.js, you can import components and utility functions from other modules within your application:</p>
 <SyntaxHighlighterComponent code={code5} language="js" />

<h2>Benefits of Using ES6 Modules in React</h2>
<ul>
    <li>Modularity: ES6 modules promote modular code, making it easier to organize and maintain large React applications.</li>
    <br />
    <li>Encapsulation: Modules encapsulate functionality, reducing namespace collisions and making code more predictable.</li>
    <br />
    <li>Reusability: Exported entities can be reused across different parts of your application, promoting code reuse.</li>
    <br />
    <li>Dependency Management: ES6 modules provide a clear and explicit way to manage dependencies between different parts of your application.</li>

</ul>

    <p>Overall, using ES6 modules in React applications helps improve code organization, maintainability, and scalability.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_es6"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-arrow-functioning"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}