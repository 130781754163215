import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ComingSoon from "../../components/coming_soon";
import ScrollToTopLink from "../../components/ScrollToTop";


export default function JSTypeofOperator() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
            let age = 25;
            let str = "John is an applicant";
            let old = false;
            typeof age // "number"
            typeof str // "string"
            typeof old; // "boolean"
`;
const code2 = `
var x;
typeof x; // "undefined"
`;
const code3 = `
let firstName = "John";
let lastName = "Doe";
let fullName = firstName + " " + lastName; // "John Doe"

`;

    return (
   <body>
        <Helmet>
    
    <title>JavaScript Typeof Operator</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="JavaScript Typeof Operator, String Concatenation Operator." />
  <meta name="description" content="In JavaScript, the typeof operator returns a string indicating the type of a value." />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
    
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_comparison_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Typeof Operator</h1>
</header>

<Navbar />

<main>
    <section>
    <p>The typeof operator returns a string indicating the type of a value.This will be explained better with the 
    example below.
 </p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
<ScrollToTopLink to="/try_js41"><Tryitout /></ScrollToTopLink>   
    
    <p>This is another example of the typeof operator.In this case no value is assigned to the variable.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js42"><Tryitout /></ScrollToTopLink>   
    <h2>String Concatenation Operator</h2>
    <p>The + operator also concatenates strings.This operator is capable of joining strings together.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code3} language="javascript" />
<ScrollToTopLink to="/try_js43"><Tryitout /></ScrollToTopLink>   
</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_comparison_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
</div>


</div>

<Footertutorials />
   </body>
    )
}