export const europeData = [
    {
      question: "What is the currency of Switzerland?",
      answer: "The currency of Switzerland is the Swiss Franc (CHF)."
    },
    {
      question: "Who was the first President of Russia?",
      answer: "Boris Yeltsin was the first President of Russia."
    },
    {
      question: "What is the largest island in the Mediterranean Sea?",
      answer: "The largest island in the Mediterranean Sea is Sicily."
    },
    {
      question: "Which European city is known as the 'City of Love'?",
      answer: "Paris, France, is known as the 'City of Love'."
    },
    {
      question: "What is the highest mountain in Europe?",
      answer: "The highest mountain in Europe is Mount Elbrus, located in Russia."
    },
    {
      question: "Which country is known for its windmills and tulip fields?",
      answer: "The Netherlands is known for its windmills and tulip fields."
    },
    {
      question: "Who is the current Chancellor of Germany?",
      answer: "As of January 2022, the Chancellor of Germany is Olaf Scholz."
    },
    {
      question: "What is the national dish of Spain?",
      answer: "The national dish of Spain is paella."
    },
    {
      question: "What is the name of the strait that separates Europe from Asia?",
      answer: "The strait that separates Europe from Asia is the Bosporus Strait."
    },
    {
      question: "Which country in Europe is known for its fjords?",
      answer: "Norway is known for its fjords."
    },
    {
      question: "What is the largest city by population in Europe?",
      answer: "The largest city by population in Europe is Istanbul, Turkey."
    },
    {
      question: "Who wrote 'The Communist Manifesto'?",
      answer: "Karl Marx and Friedrich Engels wrote 'The Communist Manifesto'."
    },
    {
      question: "What is the official language of Belgium?",
      answer: "The official languages of Belgium are Dutch, French, and German."
    },
    {
      question: "Which European country is known for its famous composers Mozart, Beethoven, and Brahms?",
      answer: "Austria is known for its famous composers Mozart, Beethoven, and Brahms."
    },
    {
      question: "What is the capital of Sweden?",
      answer: "The capital of Sweden is Stockholm."
    },
    {
      question: "Which European city is divided into two parts by the Danube River?",
      answer: "The city of Budapest, Hungary, is divided into two parts by the Danube River."
    },
    {
      question: "Who was the first female Prime Minister of Norway?",
      answer: "Gro Harlem Brundtland was the first female Prime Minister of Norway."
    },
    {
      question: "What is the name of the famous cathedral in Barcelona, Spain, designed by architect Antoni Gaudí?",
      answer: "The famous cathedral in Barcelona, Spain, designed by architect Antoni Gaudí, is called the Sagrada Família."
    },
    {
      question: "Which European country is known as the 'Land of Fire and Ice'?",
      answer: "Iceland is known as the 'Land of Fire and Ice'."
    },
    {
      question: "Who was the first female Chancellor of Germany?",
      answer: "Angela Merkel was the first female Chancellor of Germany."
    },
    {
      question: "What is the name of the ancient city located in present-day Italy, known for its well-preserved ruins?",
      answer: "The ancient city located in present-day Italy, known for its well-preserved ruins, is Pompeii."
    },
    {
      question: "Which European country is known for its production of Guinness beer?",
      answer: "Ireland is known for its production of Guinness beer."
    },
    {
      question: "What is the name of the body of water that separates England from France?",
      answer: "The body of water that separates England from France is the English Channel."
    },
    {
      question: "Who is the current Prime Minister of Italy?",
      answer: "As of January 2022, the Prime Minister of Italy is Mario Draghi."
    },
    {
      question: "Which country is famous for its wooden shoes called 'clogs'?",
      answer: "The Netherlands is famous for its wooden shoes called 'clogs'."
    },
    {
      question: "What is the national animal of Ireland?",
      answer: "The national animal of Ireland is the Irish hare."
    },
    {
      question: "Which European city is known for its canals and gondolas?",
      answer: "Venice, Italy, is known for its canals and gondolas."
    },
    {
      question: "What is the name of the fortified palace complex located in Granada, Spain, built during the Nasrid dynasty?",
      answer: "The fortified palace complex located in Granada, Spain, built during the Nasrid dynasty, is called the Alhambra."
    },
    {
      question: "Who was the first female President of Lithuania?",
      answer: "Dalia Grybauskaitė was the first female President of Lithuania."
    },
    {
      question: "Which European country is known for its famous porcelain manufacturing in cities like Meissen and Dresden?",
      answer: "Germany is known for its famous porcelain manufacturing in cities like Meissen and Dresden."
    },
    {
      question: "What is the name of the mountain range that forms a natural border between France and Spain?",
      answer: "The mountain range that forms a natural border between France and Spain is the Pyrenees."
    },
    {
      question: "Which European country is known for its traditional wooden architecture and saunas?",
      answer: "Finland is known for its traditional wooden architecture and saunas."
    },
    {
      question: "What is the official language of Hungary?",
      answer: "The official language of Hungary is Hungarian."
    },
    {
      question: "Which European country is known for its famous Neuschwanstein Castle?",
      answer: "Germany is known for its famous Neuschwanstein Castle."
    },
    {
      question: "What is the national flower of the United Kingdom?",
      answer: "The national flower of the United Kingdom is the rose."
    },
    {
      question: "Which European country is known for its annual Oktoberfest beer festival?",
      answer: "Germany is known for its annual Oktoberfest beer festival."
    },
    {
      question: "What is the name of the square in Moscow, Russia, known for its historic significance and as the site of Kremlin?",
      answer: "The square in Moscow, Russia, known for its historic significance and as the site of Kremlin, is called Red Square."
    },
    {
      question: "Who was the first female Prime Minister of Denmark?",
      answer: "Margrethe Vestager was the first female Prime Minister of Denmark."
    },
    {
      question: "What is the official language of Portugal?",
      answer: "The official language of Portugal is Portuguese."
    },
    {
      question: "Which European country is known for its delicious chocolate and waffles?",
      answer: "Belgium is known for its delicious chocolate and waffles."
    },
    {
      question: "What is the national animal of Germany?",
      answer: "The national animal of Germany is the eagle."
    },
    {
      question: "Which European city is known as the 'City of Music'?",
      answer: "Vienna, Austria, is known as the 'City of Music'."
    },
    {
      question: "What is the name of the mountain range that stretches across several countries in Eastern Europe, including Slovakia, Ukraine, and Romania?",
      answer: "The mountain range that stretches across several countries in Eastern Europe, including Slovakia, Ukraine, and Romania, is the Carpathian Mountains."
    },
    {
      question: "Who is the current Prime Minister of Spain?",
      answer: "As of January 2022, the Prime Minister of Spain is Pedro Sánchez."
    },
    {
      question: "What is the official language of Croatia?",
      answer: "The official language of Croatia is Croatian."
    },
    {
      question: "Which European country is known for its stunning fjords and northern lights?",
      answer: "Norway is known for its stunning fjords and northern lights."
    },
    {
      question: "What is the name of the famous tower in Pisa, Italy, known for its unintended tilt?",
      answer: "The famous tower in Pisa, Italy, known for its unintended tilt, is called the Leaning Tower of Pisa."
    },
    {
      question: "Who is the current President of Poland?",
      answer: "As of January 2022, the President of Poland is Andrzej Duda."
    },
    {
      question: "What is the name of the famous Greek philosopher known for his contributions to Western philosophy?",
      answer: "The famous Greek philosopher known for his contributions to Western philosophy is Socrates."
    },
    {
      question: "Which European country is known for its stunning coastline along the Adriatic Sea?",
      answer: "Croatia is known for its stunning coastline along the Adriatic Sea."
    },
    {
      question: "What is the name of the iconic tower located in London, England?",
      answer: "The iconic tower located in London, England, is called the Tower Bridge."
    },
    {
      question: "Who was the first female Prime Minister of Finland?",
      answer: "Anneli Jäätteenmäki was the first female Prime Minister of Finland."
    },
    {
      question: "What is the official language of Greece?",
      answer: "The official language of Greece is Greek."
    },
    {
      question: "Which European city is known as the 'City of a Hundred Spires'?",
      answer: "Prague, Czech Republic, is known as the 'City of a Hundred Spires'."
    },
    {
      question: "What is the name of the famous palace in Versailles, France, known for its opulent architecture and gardens?",
      answer: "The famous palace in Versailles, France, known for its opulent architecture and gardens, is called the Palace of Versailles."
    },
    {
      question: "Who is the current President of Italy?",
      answer: "As of January 2022, the President of Italy is Sergio Mattarella."
    },
    {
      question: "What is the national dish of Hungary?",
      answer: "The national dish of Hungary is goulash."
    },
    {
      question: "Which European country is known for its windmills, wooden shoes, and tulip fields?",
      answer: "The Netherlands is known for its windmills, wooden shoes, and tulip fields."
    },
    {
      question: "What is the name of the iconic cathedral located in Cologne, Germany?",
      answer: "The iconic cathedral located in Cologne, Germany, is called Cologne Cathedral."
    },
    {
      question: "Who is the current Prime Minister of the United Kingdom?",
      answer: "As of January 2022, the Prime Minister of the United Kingdom is Boris Johnson."
    },
    {
      question: "What is the official language of the Czech Republic?",
      answer: "The official language of the Czech Republic is Czech."
    },
    {
      question: "Which European country is known for its medieval castles and picturesque villages in Transylvania?",
      answer: "Romania is known for its medieval castles and picturesque villages in Transylvania."
    },
    {
      question: "What is the name of the river that flows through Paris, France?",
      answer: "The river that flows through Paris, France, is the Seine River."
    },
    {
      question: "Who was the first female Prime Minister of Spain?",
      answer: "María Teresa Fernández de la Vega was the first female Prime Minister of Spain."
    },
    {
      question: "What is the national animal of France?",
      answer: "The national animal of France is the rooster."
    },
    {
      question: "Which European city is known for its historic canals, Anne Frank House, and Van Gogh Museum?",
      answer: "Amsterdam, Netherlands, is known for its historic canals, Anne Frank House, and Van Gogh Museum."
    },
    {
      question: "What is the name of the famous ancient arena located in Rome, Italy, known for its gladiatorial contests and other public spectacles?",
      answer: "The famous ancient arena located in Rome, Italy, known for its gladiatorial contests and other public spectacles, is called the Colosseum."
    },
    {
      question: "Who is the current Chancellor of Austria?",
      answer: "As of January 2022, the Chancellor of Austria is Karl Nehammer."
    },
    {
      question: "What is the official language of Slovakia?",
      answer: "The official language of Slovakia is Slovak."
    },
    {
      question: "Which European country is known for its beautiful fjords, Viking history, and midnight sun?",
      answer: "Norway is known for its beautiful fjords, Viking history, and midnight sun."
    },
    {
      question: "What is the name of the medieval citadel located in Carcassonne, France, known for its well-preserved fortifications?",
      answer: "The medieval citadel located in Carcassonne, France, known for its well-preserved fortifications, is called the Carcassonne Citadel."
    },
    {
      question: "Who was the first female Prime Minister of Italy?",
      answer: "Giovanni Agnelli was the first female Prime Minister of Italy."
    },
    {
      question: "What is the official language of Bulgaria?",
      answer: "The official language of Bulgaria is Bulgarian."
    },
    {
      question: "Which European country is known for its delicious seafood cuisine?",
      answer: "Portugal is known for its delicious seafood cuisine."
    },
    {
      question: "What is the name of the iconic tower located in Paris, France?",
      answer: "The iconic tower located in Paris, France, is called the Eiffel Tower."
    },
    {
      question: "Who is the current President of Greece?",
      answer: "As of January 2022, the President of Greece is Katerina Sakellaropoulou."
    },
    {
      question: "What is the name of the famous castle located in Edinburgh, Scotland?",
      answer: "The famous castle located in Edinburgh, Scotland, is called Edinburgh Castle."
    },
    {
      question: "Which European country is known for its famous flamenco dance?",
      answer: "Spain is known for its famous flamenco dance."
    },
    {
      question: "What is the official language of Romania?",
      answer: "The official language of Romania is Romanian."
    },
    {
      question: "Which European city is known as the 'City of Light'?",
      answer: "Paris, France, is known as the 'City of Light'."
    },
    {
      question: "What is the name of the ancient amphitheater located in Rome, Italy, known for its gladiatorial contests and other public spectacles?",
      answer: "The ancient amphitheater located in Rome, Italy, known for its gladiatorial contests and other public spectacles, is called the Roman Colosseum."
    },
    {
      question: "Who is the current Prime Minister of Greece?",
      answer: "As of January 2022, the Prime Minister of Greece is Kyriakos Mitsotakis."
    },
    {
      question: "What is the official language of Serbia?",
      answer: "The official language of Serbia is Serbian."
    },
    {
      question: "Which European country is known for its famous Edinburgh Festival Fringe?",
      answer: "Scotland is known for its famous Edinburgh Festival Fringe."
    },
    {
      question: "What is the name of the famous bridge located in Prague, Czech Republic, known for its Gothic architecture?",
      answer: "The famous bridge located in Prague, Czech Republic, known for its Gothic architecture, is called the Charles Bridge."
    },
    {
      question: "Who was the first female President of Ireland?",
      answer: "Mary Robinson was the first female President of Ireland."
    },
    {
      question: "What is the official language of Denmark?",
      answer: "The official language of Denmark is Danish."
    },
    {
      question: "Which European country is known for its famous fjords, Viking heritage, and Aurora Borealis?",
      answer: "Norway is known for its famous fjords, Viking heritage, and Aurora Borealis."
    },
    {
      question: "What is the name of the famous cathedral located in Milan, Italy, known for its Gothic architecture and the statue of the Virgin Mary on its highest spire?",
      answer: "The famous cathedral located in Milan, Italy, known for its Gothic architecture and the statue of the Virgin Mary on its highest spire, is called the Milan Cathedral."
    },
    {
      question: "Who is the current Prime Minister of Sweden?",
      answer: "As of January 2022, the Prime Minister of Sweden is Magdalena Andersson."
    },
    {
      question: "What is the official language of Latvia?",
      answer: "The official language of Latvia is Latvian."
    },
    {
      question: "Which European country is known for its famous thermal baths and ruin pubs?",
      answer: "Hungary is known for its famous thermal baths and ruin pubs."
    },
    {
      question: "What is the name of the famous bell tower located in Venice, Italy?",
      answer: "The famous bell tower located in Venice, Italy, is called the St. Mark's Campanile."
    },
    {
      question: "Who is the current President of Ireland?",
      answer: "As of January 2022, the President of Ireland is Michael D. Higgins."
    },
    {
      question: "What is the official language of Switzerland?",
      answer: "The official languages of Switzerland are German, French, Italian, and Romansh."
    },
    {
      question: "Which European city is known as the 'Pearl of the Danube'?",
      answer: "Budapest, Hungary, is known as the 'Pearl of the Danube'."
    },
    {
      question: "What is the name of the famous palace complex located in Madrid, Spain, known for its grandeur and extensive art collection?",
      answer: "The famous palace complex located in Madrid, Spain, known for its grandeur and extensive art collection, is called the Royal Palace of Madrid."
    },
    {
      question: "Who is the current Prime Minister of Portugal?",
      answer: "As of January 2022, the Prime Minister of Portugal is António Costa."
    },
    {
      question: "What is the official language of Lithuania?",
      answer: "The official language of Lithuania is Lithuanian."
    },
    {
      question: "Which European country is known for its famous Brandenburg Gate and Berlin Wall?",
      answer: "Germany is known for its famous Brandenburg Gate and Berlin Wall."
    },
    {
      question: "What is the name of the mountain range that separates France and Spain?",
      answer: "The mountain range that separates France and Spain is the Pyrenees."
    },
    {
      question: "Who is the current President of Hungary?",
      answer: "As of January 2022, the President of Hungary is János Áder."
    },
    {
      question: "What is the official language of Estonia?",
      answer: "The official language of Estonia is Estonian."
    },
    {
      question: "Which European city is known as the 'City of Seven Hills'?",
      answer: "Rome, Italy, is known as the 'City of Seven Hills'."
    },
    {
      question: "What is the name of the famous palace located in Vienna, Austria, known for its Baroque architecture and extensive gardens?",
      answer: "The famous palace located in Vienna, Austria, known for its Baroque architecture and extensive gardens, is called Schönbrunn Palace."
    },
    {
      question: "Who is the current Prime Minister of France?",
      answer: "As of January 2022, the Prime Minister of France is Jean Castex."
    },
    {
      question: "What is the official language of Ukraine?",
      answer: "The official language of Ukraine is Ukrainian."
    },
    {
      question: "Which European country is known for its famous Transylvania region and Bran Castle, often associated with the legend of Dracula?",
      answer: "Romania is known for its famous Transylvania region and Bran Castle, often associated with the legend of Dracula."
    },
    {
      question: "What is the name of the river that flows through Budapest, Hungary?",
      answer: "The river that flows through Budapest, Hungary, is the Danube River."
    },
    {
      question: "Who is the current President of Slovakia?",
      answer: "As of January 2022, the President of Slovakia is Zuzana Čaputová."
    },
    {
      question: "What is the official language of Slovenia?",
      answer: "The official language of Slovenia is Slovenian."
    },
    {
      question: "Which European country is known for its famous Moorish palace complex, the Alcázar of Seville?",
      answer: "Spain is known for its famous Moorish palace complex, the Alcázar of Seville."
    },
    {
      question: "What is the name of the famous tower located in Moscow, Russia?",
      answer: "The famous tower located in Moscow, Russia, is called the Ostankino Tower."
    }
  ];
  