import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIFacialRecognition() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
# Example 1: Face Detection using OpenCV
import cv2

# Load the cascade
face_cascade = cv2.CascadeClassifier('haarcascade_frontalface_default.xml')

# Read the input image
img = cv2.imread('test_image.jpg')

# Convert into grayscale
gray = cv2.cvtColor(img, cv2.COLOR_BGR2GRAY)

# Detect faces
faces = face_cascade.detectMultiScale(gray, 1.1, 4)

# Draw rectangle around the faces
for (x, y, w, h) in faces:
    cv2.rectangle(img, (x, y), (x+w, y+h), (255, 0, 0), 2)

# Display the output
cv2.imshow('img', img)
cv2.waitKey()
  `;

  const example2 = `
# Example 2: Face Recognition using Deep Learning (FaceNet)
from keras.models import load_model
from keras.preprocessing import image
import numpy as np

# Load the pre-trained FaceNet model
model = load_model('facenet_keras.h5')

# Load an image
img = image.load_img('test_image.jpg', target_size=(160, 160))
img = image.img_to_array(img)
img = np.expand_dims(img, axis=0)

# Normalize pixel values
img = img / 255.0

# Perform face recognition
embedding = model.predict(img)

# Display the embedding vector
print(embedding)
  `;

  const example3 = `
# Example 3: Facial Landmark Detection using Dlib
import dlib
import cv2

# Load the detector
detector = dlib.get_frontal_face_detector()
predictor = dlib.shape_predictor('shape_predictor_68_face_landmarks.dat')

# Read the image
img = cv2.imread('test_image.jpg')
gray = cv2.cvtColor(img, cv2.COLOR_BGR2GRAY)

# Detect faces
faces = detector(gray)

# Iterate over detected faces
for face in faces:
    landmarks = predictor(gray, face)
    for n in range(0, 68):
        x = landmarks.part(n).x
        y = landmarks.part(n).y
        cv2.circle(img, (x, y), 1, (255, 0, 0), -1)

# Display the image with landmarks
cv2.imshow('Facial Landmarks', img)
cv2.waitKey(0)
  `;

  return (
    <body>
      <Helmet>
        <title>AI Facial Recognition</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Facial Recognition, Face Detection, Face Recognition, Computer Vision" />
        <meta name="description" content="Learn about AI facial recognition, its applications, and practical examples using popular frameworks like OpenCV, Dlib, and FaceNet." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/AI_image_segmentation"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/AI_robotics"><ButtonNext /></ScrollToTopLink>
          <h1>AI Facial Recognition</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Facial recognition is a branch of artificial intelligence that aims to identify and verify individuals based on their facial features. It has applications in security systems, user authentication, and more.</p>

            <h2>Examples of AI Facial Recognition</h2>

            <h3>Example 1: Face Detection using OpenCV</h3>
            <SyntaxHighlighterComponent code={example1} language="python" />

            <h3>Example 2: Face Recognition using Deep Learning (FaceNet)</h3>
            <SyntaxHighlighterComponent code={example2} language="python" />

            <h3>Example 3: Facial Landmark Detection using Dlib</h3>
            <SyntaxHighlighterComponent code={example3} language="python" />
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_image_segmentation"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_robotics"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
