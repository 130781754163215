import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import toggle from "../responsivetopnav";
import ScrollToTopLink from "../ScrollToTop"; // Assuming ScrollToTopLink is defined in ScrollToTopLink.js
import { useLocation } from 'react-router-dom';
import JSSearchBar from "../Searchbar/JS SearchBar/JSSearchBar";

export default function Sidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  var nav = {
    color: 'white',
    fontSize: '30px'
  }
  var newCode = {
    float: 'right'
  }
  return (
    <div>
      <div className={`sidebar ${isOpen ? 'responsive' : ''}`} id="mySidebar">
        <span className="close" onClick={toggle}>&times;</span>
        <h2>JavaScript Tutorial</h2><br />
        <span><JSSearchBar /></span><br />
        <p className="p-sidebar">...Empowering Your Online Presence...</p><br />
        <ScrollToTopLink to="/JavaScript_Tutorial_intro"><a className={location.pathname === '/JavaScript_Tutorial_intro' ? 'active' : ''}>JS Introduction</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_writing_code"><a className={location.pathname === '/JS_writing_code' ? 'active' : ''}>JS Writing Code</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_syntax"><a className={location.pathname === '/JS_syntax' ? 'active' : ''}>JS Syntax</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_comment"><a className={location.pathname === '/JS_comment' ? 'active' : ''}>JS Comments</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_environment"><a className={location.pathname === '/JS_environment' ? 'active' : ''}>JS Environment</a></ScrollToTopLink><br />
        <h2>JS Output</h2><br />
        <ScrollToTopLink to="/JS_display_output"><a className={location.pathname === '/JS_display_output' ? 'active' : ''}>JS Display Output</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_method"><a className={location.pathname === '/JS_method' ? 'active' : ''}>JS Method</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_variables"><a className={location.pathname === '/JS_variables' ? 'active' : ''}>JS Variables</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_data_types"><a className={location.pathname === '/JS_data_types' ? 'active' : ''}>JS Data Types</a></ScrollToTopLink><br />
        <h2>JS Operators</h2><br />
        <ScrollToTopLink to="/JS_operators"><a className={location.pathname === '/JS_operators' ? 'active' : ''}>JS Operators</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_arithmetic_operators"><a className={location.pathname === '/JS_arithmetic_operators' ? 'active' : ''}>JS Arithmetic Operators</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_assignment_operators"><a className={location.pathname === '/JS_assignment_operators' ? 'active' : ''}>JS Assignment Operators</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_logical_conditional_operators"><a className={location.pathname === '/JS_logical_conditional_operators' ? 'active' : ''}>JS Logical and Conditional Operators</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_comparison_operators"><a className={location.pathname === '/JS_comparison_operators' ? 'active' : ''}>JS Comparison Operators</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/JS_typeof_operators"><a className={location.pathname === '/JS_typeof_operators' ? 'active' : ''}>JS Typeof Operators</a></ScrollToTopLink><br />
      </div>

      <div className="burger-icon" onClick={toggle}>
        &#9776;
        <p style={newCode}>Menu</p>
      </div>
    </div>
  );
}