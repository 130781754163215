import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AISentimentAnalysis() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const sentimentAnalysisExample = `
# Example of Sentiment Analysis with TextBlob
from textblob import TextBlob

# Example text
text = "This tutorial is really helpful!"

# Perform sentiment analysis
analysis = TextBlob(text)
sentiment = analysis.sentiment

# Output sentiment polarity and subjectivity
print(f"Sentiment Polarity: {sentiment.polarity}")
print(f"Sentiment Subjectivity: {sentiment.subjectivity}")
  `;

  const sentimentAnalysisExampleNLTK = `
# Example of Sentiment Analysis with NLTK
import nltk
from nltk.sentiment import SentimentIntensityAnalyzer

# Download the VADER lexicon
nltk.download('vader_lexicon')

# Initialize the sentiment intensity analyzer
sia = SentimentIntensityAnalyzer()

# Example text
text = "I love this product! It's amazing."

# Perform sentiment analysis
sentiment = sia.polarity_scores(text)

# Output sentiment scores
print(sentiment)
  `;

  const sentimentAnalysisExampleTransformers = `
# Example of Sentiment Analysis with Transformers (Hugging Face)
from transformers import pipeline

# Initialize the sentiment-analysis pipeline
nlp = pipeline("sentiment-analysis")

# Example text
text = "I am so happy with the results!"

# Perform sentiment analysis
result = nlp(text)

# Output sentiment result
print(result)
  `;

  return (
    <body>
      <Helmet>
        <title>AI Sentiment Analysis</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Sentiment Analysis, Natural Language Processing, AI Tutorial, Sentiment Analysis with TextBlob, Sentiment Analysis with NLTK, Sentiment Analysis with Transformers" />
        <meta name="description" content="Learn about AI sentiment analysis, its applications, and practical examples using libraries like TextBlob, NLTK, and Hugging Face Transformers." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_problem_solving"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_ner"><ButtonNext /></ScrollToTopLink>
          <h1>AI Sentiment Analysis</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Sentiment analysis is the automated process of analyzing text to determine the sentiment expressed within it, such as positive, negative, or neutral. It's widely used in various applications, including social media monitoring, customer feedback analysis, and market research.</p>

            <h2>Introduction to Sentiment Analysis</h2>
            <p>There are different libraries and tools available for performing sentiment analysis. Let's look at a few examples using TextBlob, NLTK, and Hugging Face Transformers.</p>

            <h2>Example: Sentiment Analysis with TextBlob</h2>
            <SyntaxHighlighterComponent code={sentimentAnalysisExample} language="python" />
            <p>In this example, we use TextBlob to analyze the sentiment of a given text. TextBlob provides a simple API for diving into common natural language processing (NLP) tasks.</p>

            <h2>Example: Sentiment Analysis with NLTK</h2>
            <SyntaxHighlighterComponent code={sentimentAnalysisExampleNLTK} language="python" />
            <p>NLTK (Natural Language Toolkit) is a powerful Python library used for working with human language data. In this example, we use NLTK's SentimentIntensityAnalyzer to analyze the sentiment of a given text.</p>

            <h2>Example: Sentiment Analysis with Transformers (Hugging Face)</h2>
            <SyntaxHighlighterComponent code={sentimentAnalysisExampleTransformers} language="python" />
            <p>Hugging Face's Transformers library provides a variety of pre-trained models for various NLP tasks, including sentiment analysis. In this example, we use a pre-trained sentiment analysis model to analyze the sentiment of a given text.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_problem_solving"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_ner"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
