import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactES6destructuring() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    // Array destructuring
    const [a, b] = [1, 2]; // a = 1, b = 2
    const [c, d] = [3, 4]; // c = 3, d = 4
    `

    const code2=`
    // Object destructuring
    const { x, y } = { x: 1, y: 2 }; // x = 1, y = 2 
    const { v, w } = { v: 9, w: 10 }; // v = 9, w = 10 
    `
  
    return (
   <body>
     <Helmet>
        
        <title>React ES6 Destructuring Assignment</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React ES6 Destructuring Assignment, ES6 Destructuring Assignment, ES6 Destructuring Assignment Examples
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="Destructuring allows us to extract values from arrays or properties from objects into distinct variables." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_es6-arrow-functioning"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-template-literals"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React ES6 Destructuring Assignment</h1>
</header>

<Navbar />

<main>
<section>
<p>Destructuring allows us to extract values from arrays or properties from objects into distinct variables.</p>
  <p> It simplifies code and improves readability.</p>
  <p>There are two types of destructuring assignment, they are:</p>
  <ul>
    <li>Array Destructuring</li>
    <li>Object Destructuring</li>
  </ul>
 <h2>Example</h2>
 <p>This is an array destructuring example</p>
 <SyntaxHighlighterComponent code={code} language="js" />

 <p>This is an object destructuring example</p>
 <SyntaxHighlighterComponent code={code2} language="js" />
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_es6-arrow-functioning"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-template-literals"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}