import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLViewOptimization() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const optimizeViewCode = `
-- Optimize the view with indexes
CREATE OR REPLACE VIEW EmployeeDepartment AS
SELECT employees.employee_id, employees.employee_name, departments.department_name
FROM employees
INNER JOIN departments ON employees.department_id = departments.department_id;

-- Create indexes on the underlying tables
CREATE INDEX idx_employee_id ON employees(employee_id);
CREATE INDEX idx_department_id ON departments(department_id);
  `;

  const materializedViewCode = `
-- Create a materialized view for better performance
CREATE MATERIALIZED VIEW MaterializedEmployeeDepartment AS
SELECT employees.employee_id, employees.employee_name, departments.department_name
FROM employees
INNER JOIN departments ON employees.department_id = departments.department_id;
  `;

  return (
    <body>
      <Helmet>
        <title>SQL View Optimization</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL View Optimization, SQL Tutorial, Creating Indexes, Materialized Views" />
        <meta name="description" content="Learn how to optimize SQL views with indexes, materialized views, and other techniques to improve query performance." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/SQL_managing_views"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/SQL_indexes"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL View Optimization</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Optimizing SQL views can significantly improve query performance by utilizing indexes and materialized views. Here are some optimization techniques:</p>

            <h2>Optimize with Indexes</h2>
            <p>Adding indexes to the underlying tables of a view can speed up data retrieval. Example:</p>
            <SyntaxHighlighterComponent code={optimizeViewCode} language="sql" />

            <h2>Create Materialized Views</h2>
            <p>Materialized views store the result set physically, updating them periodically. Example:</p>
            <SyntaxHighlighterComponent code={materializedViewCode} language="sql" />
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_managing_views"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_indexes"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
