import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";


export default function AIMachineLearning() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const basicExampleCode = `
# Python example for linear regression
import numpy as np
from sklearn.linear_model import LinearRegression

# Sample data
X = np.array([[1, 1], [1, 2], [2, 2], [2, 3]])
y = np.dot(X, np.array([1, 2])) + 3

# Create model
model = LinearRegression().fit(X, y)

# Make a prediction
prediction = model.predict(np.array([[3, 5]]))
print(prediction)
`;

  return (
    <body>
      <Helmet>
        <title>AI Machine Learning</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI, Machine Learning, ML, Algorithms, Supervised Learning, Unsupervised Learning" />
        <meta name="description" content="Learn about AI Machine Learning techniques including supervised and unsupervised learning, algorithms, and practical examples." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_problem_solving"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_supervised_learning"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>AI Machine Learning</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Machine Learning (ML) is a subset of artificial intelligence (AI) focused on building systems that learn from data. It allows computers to identify patterns, make decisions, and improve over time without being explicitly programmed.</p>
     
            <h2>Types of Machine Learning</h2>
            <p>There are three main types of machine learning:</p>
            <ul>
              <li><strong>Supervised Learning</strong>: The model is trained on a labeled dataset, meaning that each training example is paired with an output label. The model learns to map inputs to outputs.</li>
              <li><strong>Unsupervised Learning</strong>: The model is trained on an unlabeled dataset and must find patterns and relationships in the data without guidance.</li>
              <li><strong>Reinforcement Learning</strong>: The model learns by interacting with an environment and receiving rewards or penalties based on its actions.</li>
            </ul>

            <h2>Basic Example of Machine Learning</h2>
            <p>Here is a simple example of using Python to perform linear regression, a common supervised learning task:</p>
            <SyntaxHighlighterComponent code={basicExampleCode} language="python" />
            <p>In this example:</p>
            <ul>
              <li>We import the necessary libraries and create sample data.</li>
              <li>We create a linear regression model and fit it to the data.</li>
              <li>We make a prediction using the trained model.</li>
            </ul>

            <h2>Applications of Machine Learning</h2>
            <p>Machine learning is used in various applications such as:</p>
            <ul>
              <li><strong>Image and Speech Recognition</strong>: Identifying objects in images or understanding spoken language.</li>
              <li><strong>Recommendation Systems</strong>: Suggesting products or content to users based on their preferences and behavior.</li>
              <li><strong>Fraud Detection</strong>: Identifying fraudulent activities in financial transactions.</li>
              <li><strong>Predictive Maintenance</strong>: Predicting equipment failures before they occur.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_problem_solving"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_supervised_learning"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
