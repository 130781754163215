import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactJSX() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    // Example JSX
    const element = <h1>Hello, world!</h1>;
    
    // Transpiled JavaScript
    const element = React.createElement('h1', null, 'Hello, world!');
            `

    const code2=`
    const name = 'John Doe';
    const element = <h1>Hello, {name}</h1>;
    // Output: <h1>Hello, John Doe</h1>
        `
  
     const code3=`
     function Greeting(props) {
        return <h1>Hello, {props.name}</h1>;
      }
      
      const element = <Greeting name="John" />;
      // Output: <h1>Hello, John</h1>
        `

         const code4=`
         const element = <input type="text" className="input" />;
         // Output: <input type="text" class="input" />
             `
        const code5=`
        const isLoggedIn = true;
        const element = (
          <div>
            {isLoggedIn ? <p>Welcome, user!</p> : <p>Please log in.</p>}
          </div>
        );
            `
        const code6=`
        const numbers = [1, 2, 3, 4, 5];
        const listItems = numbers.map((number) => <li key={number}>{number}</li>);
        const element = <ul>{listItems}</ul>;
            `

        const code7=`
          const style = {
            color: 'blue',
            backgroundColor: 'lightgray'
           };
          
          const element = <div style={style}>Styled Div</div>;
        `

        const code8=`
        const element = (
            <>
              <h1>Title</h1>
              <p>Paragraph</p>
            </>
          );
              `
    return (
   <body>
     <Helmet>
        
        <title>React JSX</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React JSX, 
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="" />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_es6-rest"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_components"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React JSX</h1>
</header>

<Navbar />

<main>
<section>
<p>React JSX is a syntax extension for JavaScript that allows you to write HTML-like code directly within your JavaScript code.</p>
  <p>It stands for JavaScript XML, and it's a convenient way to describe the structure of UI components in React applications.</p>

  <p>JSX gets transformed into JavaScript by a build process (usually using tools like Babel) before it gets interpreted by the browser.</p>
  <p>In JSX, you can write HTML-like syntax directly within JavaScript code using curly braces '{}' for embedding JavaScript expressions.</p>

  <h2>Example</h2>
 <SyntaxHighlighterComponent code={code} language="js" />

 <h2>Embedding Expressions</h2>
<p>JSX allows embedding JavaScript expressions within curly braces. </p>
<p>This is useful for dynamic content.</p>
 <SyntaxHighlighterComponent code={code2} language="js" />

 <h2>Using JSX with Components</h2>
 <p>JSX can also be used with React components. Custom components are also written in JSX.</p>
 <SyntaxHighlighterComponent code={code3} language="js" />

 <h3>Attributes and Props</h3>
 <p>In JSX, HTML attributes are camelCase, and you can pass props to components just like HTML attributes.</p>
 <SyntaxHighlighterComponent code={code4} language="js" />

 <h3>Conditional Rendering</h3>
 <p>You can use JavaScript expressions within JSX to conditionally render elements.</p>
 <SyntaxHighlighterComponent code={code5} language="js" />

 <h3>Lists and Keys</h3>
 <p>You can use JavaScript's 'map()'' function to render lists of elements in JSX.</p>
 <p>Each list item should have a unique 'key' prop.</p>
 <SyntaxHighlighterComponent code={code6} language="js" />

 <h3>Styling in JSX</h3>
 <p>You can apply inline styles using JavaScript objects within JSX.</p>
 <SyntaxHighlighterComponent code={code7} language="js" />

 <h3>Fragments</h3>
 <p>JSX also supports Fragments( &lt;&gt; &nbsp; &lt;/&gt;), which let you return multiple elements without a wrapper.</p>
 <SyntaxHighlighterComponent code={code8} language="js" />

</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_es6-rest"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_components"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}