import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPAssignment() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    $x = 10;
    `;
    const code2 = `
    $x = 5;
    $x += 3; // Equivalent to: $x = $x + 3;
    print $x; // Output: 8
          `;
    const code3 = `
    $num1 = 6;
    $x = 10;
    $x -= 4; // Equivalent to: $x = $x - 4;
    print $x; // Output: 6
          `;

    const code4 = `
    $x = 5;
    $x *= 2; // Equivalent to: $x = $x * 2;
    print $x; // Output: 10
          `;

    const code5 = `
    $x = 20;
    $x /= 4; // Equivalent to: $x = $x / 4;
    print $x; // Output: 5
              `;

    const code6 = `
    $x = 10;
    $x %= 3; // Equivalent to: $x = $x % 3;
    print $x; // Output: 1 (remainder of 10 divided by 3)
          `;

    const code7 = `
    $str = "Hello";
    $str .= " World!"; // Equivalent to: $str = $str . " World!";
    print $str; // Output: Hello World!
          `;

  
    return (
   <body>
      <Helmet>
        <title>PHP Assignment Operators</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Operators, PHP Assignment Operator, Assignment Operator, Addition Assignment, Subtraction Assignment, Multiplication Assignment, Division Assignment, Modulus Assignment, Concatenation Assignment,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="PHP assignment operators are used to assign values to variables. They combine the assignment operation with other operators, making it more concise to perform common tasks like addition, subtraction, multiplication, division, etc." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_arithmetic"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_comparison"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Assignment Operators</h1>
</header>

<Navbar />

<main>
<section>
<p>PHP assignment operators are used to assign values to variables.</p>
<p>They combine the assignment operation with other operators, making it more concise to perform common tasks like addition, subtraction, multiplication, division, etc. </p>
<p>Here's an explanation of each PHP assignment operator along with code examples:</p>

<h2>Assignment (=)</h2>
<p>This is the basic assignment operator used to assign a value to a variable.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="php" />

<h2>Addition Assignment (+=)</h2>
<p>This operator adds the right operand to the left operand and assigns the result to the left operand.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="php" />


<h2>Subtraction Assignment (-=)</h2>
<p>This operator subtracts the right operand from the left operand and assigns the result to the left operand.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code3} language="php" />


<h2>Multiplication Assignment (*=)</h2>
<p>This operator multiplies the right operand with the left operand and assigns the result to the left operand.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code4} language="php" />


<h2>Division Assignment (/=)</h2>
<p>This operator divides the left operand by the right operand and assigns the result to the left operand.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code5} language="php" />


<h2>Modulus Assignment (%=)</h2>
<p>This operator divides the left operand by the right operand and assigns the remainder to the left operand.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code6} language="php" />

<h2>Concatenation Assignment (.=)</h2>
<p>This operator concatenates the right operand to the left operand and assigns the result to the left operand.</p>
<p>This is used specifically for string concatenation.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code7} language="php" />

<p>These assignment operators are very handy in PHP for performing common operations while assigning values to variables, making code more concise and readable.</p>
</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_arithmetic"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_comparison"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}