import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import ScrollToTopLink from '../components/ScrollToTop';
import "../css/LandingPage.css";

function LandingPage() {
 
  

  const containerStyle = {
    margin: 0,
    padding: 0,
    fontFamily: 'Arial, sans-serif',
    backgroundColor: 'darkblue',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  };

  const codeStyle = {
    color: 'white',
    textAlign: 'center',
    fontSize: '50px',
    marginBottom: '20px'
  };

  const buttonContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  };

  const buttonStyle = {
    backgroundColor: 'darkblue',
    color: 'white',
    width: 'calc(100% - 20px)', // Adjusted width to be 33.33% of the container width with a 20px margin
    maxWidth: '400px', // Added max width for better responsiveness
    height: '60px', // Increased button height
    fontSize: '24px', // Increased font size
    fontWeight: 'bold',
    borderRadius: '12px',
    margin: '10px',
    cursor: 'pointer'
  };

  return (
    <body style={containerStyle}>
      <h1 style={codeStyle}>Welcome to Beta Thread</h1>
      <p style={{ ...codeStyle, fontSize: '40px' }}>...coding the future...</p>
      <h2 style={{ color: 'white', fontSize: '24px', marginBottom: '20px' }}>Select your destination</h2>
      <div style={buttonContainerStyle}>
        <ScrollToTopLink to="/">
          <button style={buttonStyle}>Home</button>
        </ScrollToTopLink>
        <ScrollToTopLink to="/ai">
          <button style={buttonStyle}>Beta AI</button>
        </ScrollToTopLink>
        <ScrollToTopLink to="/signup">
          <button style={buttonStyle}>Signup</button>
        </ScrollToTopLink>
        <ScrollToTopLink to="/login">
          <button style={buttonStyle}>Login</button>
        </ScrollToTopLink>
      </div>
      <p style={{ ...codeStyle, fontSize: '20px' }}>Note: Select login if you have been prevented from the accessing the dashboard</p>
    </body>
  );
}

export default LandingPage;
