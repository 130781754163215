import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

const XMLDataDeletion = () => {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<library>
  <book>
    <title>Learning XML</title>
    <author>John Doe</author>
    <year>2024</year>
  </book>
  <book>
    <title>Advanced XML</title>
    <author>Jane Smith</author>
    <year>2025</year>
  </book>
</library>
`;

  const example2 = `
<employees>
  <employee>
    <name>John Doe</name>
    <position>Developer</position>
    <salary>50000</salary>
  </employee>
  <employee>
    <name>Jane Smith</name>
    <position>Manager</position>
    <salary>75000</salary>
  </employee>
</employees>
`;

  const example3 = `
<catalog>
  <product>
    <name>Widget</name>
    <price>25.00</price>
    <quantity>100</quantity>
  </product>
</catalog>
`;

  return (
    <body>
      <Helmet>
        <title>XML Data Deletion</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML Data Deletion, XML Tutorial, XML Data Handling" />
        <meta name="description" content="Learn how to delete data from XML documents with examples and explanations of various techniques." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/XML_data_insertion"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_data_querying"><ButtonNext /></ScrollToTopLink>
          <h1>XML Data Deletion</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XML data deletion involves removing elements or attributes from an XML document. This can be done manually or programmatically.</p>

            <h2>Example 1: Basic XML Structure</h2>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This example shows a basic XML structure with a library containing books. Deleting an element would involve removing the entire book entry.</p>

            <h2>Example 2: Employee Details</h2>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>This example shows employee details in an XML format. Deleting an element would involve removing an employee entry.</p>

            <h2>Example 3: Product Catalog</h2>
            <SyntaxHighlighterComponent code={example3} language="xml" />
            <p>This example displays a product catalog. Deleting an element would involve removing a product entry.</p>

            <h2>Deleting XML Data Programmatically</h2>
            <p>To delete data from an XML document programmatically, you can use various tools and libraries in different programming languages, such as:</p>
            <ul>
              <li>Using the DOM API in JavaScript to remove elements</li>
              <li>Using Python's ElementTree module to delete elements from an XML tree</li>
              <li>Using Java's JDOM or DOM4J libraries for XML manipulation</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_data_insertion"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_data_querying"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}

export default XMLDataDeletion;
