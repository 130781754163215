import Header from "../components/Header-about";
import Footer from "../components/Footer";
import Navbar from "../components/navbar";
import Topnav from "../components/topnav";
import { Helmet } from 'react-helmet';
import '../css/my style.css';
import '../css/my style2.css';
import { Link } from "react-router-dom";
import SVGEditor from "./SVGEditor";
import ScrollToTopLink from "../components/ScrollToTop";


export default function About() {
  return (
    <body className="main-theme">
      <Helmet>
        <title>About Us | Beta Thread</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-compatible" content="IE-edge"/>
        <meta name="Keywords" content="About Beta Thread, About Page, HTML, Python, CSS, SQL, JavaScript, How to, PHP, XML, MySQL, React, AI, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, Examples, Learn to code, Coding the future, Source code, Website
        C++ programming tips for real-world applications," />
        <meta name="description" content="Beta Thread is an online web tutorial website optimized for learning various programming languages.
        We constantly make sure that all our content is correct and accurate.For more info visit ourservices page" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Topnav />
      <Header />
      <Navbar />
      <section>
        <h1><b>What is Beta Thread?</b></h1>  
        <div>
          <p>
            Beta Thread is an online web tutorial platform optimized for learning various programming languages.
          </p>
          <p>
            We constantly make sure that all our content is correct and accurate. For more info visit our
            <ScrollToTopLink style={{color: 'blue'}} to="/services">services page</ScrollToTopLink>
          </p>
        </div>
        <p>Our aim is to empower individuals with the knowledge of coding and programming</p>
        <p>We work with the latest technologies and always update what we have for users to make tutorials easier and fun</p>
      </section>
      <section>
        <h2><b>Our History</b></h2>
        <p>
          Beta Thread was founded to reach everyone with the knowledge of coding and technology.
          Our motto "coding the future" emanated from our tireless effort to make the world better.
        </p>
        <p>We are passionate about bringing software and web development to you.</p>
      </section>
      <section>
        <h2>Our Mission</h2>
        <p>
          Our aim is to create a world where anyone can learn coding and digital skills to make an impact on the world around them.
        </p>
        <p>
          Software and web development contains a world of possibilities - all that's required is the curiosity to learn and grow.
        </p>
        <p>At Beta Thread, we are committed to empowering all individuals with this knowledge.</p>
      </section>
      <section>
        <h2>Beta Thread is completely free</h2>
        <p>The tutorials we provide for you to learn is completely free, so just enjoy yourself while learning</p>
      </section>
      <section>
        <h2>Our Online Code Editor</h2>
        <p>
          Our online code editor helps you run codes specified for some of the tutorials e.g HTML
        </p>
      
      </section>
      <section>
        <h1><b>Why Choose Beta Thread?</b></h1>
        <p>We provide tutorials that are up to date with the latest technologies.</p>
        <p>Our tutorials are simple and very easy to grasp and understand and interact with.</p>
      </section>
      <section>
        <p style={{ textAlign: 'center' }}>
          If you have any inquiries or feedback, feel free to reach out to us:
        </p>
        <address style={{ textAlign: 'center' }}>
          Email: betathreadhelp@gmail.com<br />
          Phone: +2348141772821<br />
        </address>
      </section>
      <br />
      <Footer />
    </body>
  );
}