import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubySymbols() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const symbolCreationCode = `
# Creating Symbols
symbol1 = :my_symbol
symbol2 = :"another symbol"

puts symbol1  # Output: my_symbol
puts symbol2  # Output: another symbol
  `;

  const symbolComparisonCode = `
# Symbol Comparison
symbol1 = :ruby
symbol2 = :ruby
symbol3 = :python

puts symbol1 == symbol2  # Output: true
puts symbol1 == symbol3  # Output: false
  `;

  const symbolUsageCode = `
# Symbols as Hash Keys
my_hash = { name: "Alice", age: 30, city: "Wonderland" }

puts my_hash[:name]  # Output: Alice
puts my_hash[:age]   # Output: 30
puts my_hash[:city]  # Output: Wonderland
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Symbols</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Symbols, Creating Symbols, Symbol Comparison, Symbols as Hash Keys" />
        <meta name="description" content="Learn about symbols in Ruby, including how to create symbols, compare them, and use them as hash keys." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_ranges"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_date-time"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Symbols</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Symbols in Ruby are lightweight string-like objects that are often used as identifiers or keys.</p>

            <h2>Creating Symbols</h2>
            <p>Symbols are created by prefixing a word with a colon <code>:</code>.</p>
            <SyntaxHighlighterComponent code={symbolCreationCode} language="ruby" />
            <p>In the above example, we create symbols using the colon notation.</p>

            <h2>Symbol Comparison</h2>
            <p>Symbols with the same content are treated as the same object, making comparison fast.</p>
            <SyntaxHighlighterComponent code={symbolComparisonCode} language="ruby" />
            <p>In the above example, we compare symbols to see if they are equal.</p>

            <h2>Symbols as Hash Keys</h2>
            <p>Symbols are often used as keys in hashes because they are immutable and unique.</p>
            <SyntaxHighlighterComponent code={symbolUsageCode} language="ruby" />
            <p>In the above example, we use symbols as keys in a hash and access the values using those keys.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_ranges"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_date-time"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
