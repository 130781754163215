import React from 'react';
import { Link } from 'react-router-dom';

const ScrollToTopLink = ({ to, children, style, className }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    
    <Link to={to} onClick={handleClick} style={style} className={className}>
      {children}
    </Link>
   
  );
};

export default ScrollToTopLink;
