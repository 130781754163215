import Header from "../components/Header-terms&privacy";
import Footer from "../components/Footer";
import Navbar from "../components/navbar";
import Topnav from "../components/topnav";
import { Helmet } from 'react-helmet';
import '../css/my style.css';
import '../css/my style2.css';
import { Link } from "react-router-dom";


export default function TermsPrivacy() {
  var linkCode = {
    textAlign: 'left'
  }
  var twoLinks = {
    color: 'black'
  }
    return (
        <body className="main-theme">
        <Helmet>
        
        <title>Privacy Policy | Beta Thread</title>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Privacy Policy, Terms and Privacy page, terms and conditions, terms and conditions for using Beta Thread, HTML, Python, CSS, SQL, JavaScript, How to, PHP, XML, MySQL, React, AI, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, Examples, Learn to code, Coding the future, Source code, Website" />
    <meta name="description" content="Welcome to Beta Thread! We are excited to have you here. Before you explore our website and use our services, we want to ensure you understand and agree to our Terms and Privacy Policy." />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />
    

        </Helmet>
         <br />
        <br />
        <br />
        <br />
        <br />
        <Topnav />
        <Header />
        <Navbar />
        <section>
                <article> 
          
            
                <h2>1. Introduction</h2>

<p>Welcome to Beta Thread! We are excited to have you here. Before you explore our website and use our services, we want to ensure you understand and agree to our Terms and Privacy Policy. By accessing or using our website (hereinafter referred to as "Site") and any related services (hereinafter referred to as "Services"), you acknowledge that you have read, understood, and agreed to these Terms and Privacy Policy.</p>

<h2>2. Terms of Use</h2>

<h3>2.1 Acceptance of Terms</h3>

<p>By using our Site and Services, you agree to comply with and be bound by the following Terms:</p>

<h3>2.1.1 Eligibility</h3>

<p>Our services are open to all users, regardless of age or legal majority in your jurisdiction. You are welcome to use our Services without any age restrictions. If you are using our Services on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that entity to these Terms.</p>

<h3>2.1.2 License</h3>

<p>We grant you a non-exclusive, revocable, limited license to use our Site and Services in accordance with these Terms for personal and non-commercial purposes only.</p>

<h3>2.1.3 Prohibited Conduct</h3>

<p>You agree not to engage in any conduct that violates these Terms or is harmful to other users or Beta Thread. This includes, but is not limited to, the following:</p>

<ul>
    <li>Violating any applicable laws or regulations.</li>
    <li>Infringing upon the intellectual property rights of Beta Thread or any third party.</li>
    <li>Transmitting any malicious software, viruses, or harmful code.</li>
    <li>Harassing, stalking, or engaging in any harmful or disruptive behavior.</li>
    <li>Attempting to gain unauthorized access to our systems or user data.</li>
    <li>Creating or distributing false information or impersonating others.</li>
</ul>

<h3>2.2 Termination</h3>

<p>Beta Thread reserves the right to terminate or suspend your access to our Services at any time for any reason, without prior notice.</p>

<h3>2.3 Changes to Terms</h3>

<p>We may update or modify these Terms from time to time. Your continued use of our Services after such changes constitutes your acceptance of the revised Terms.</p>

<h2>3. Privacy Policy</h2>

<h3>3.1 Information Collection and Use</h3>

<p>We value your privacy. Our Privacy Policy explains how we collect, use, and protect your personal information. By using our Services, you consent to our collection and use of your information as described in our Privacy Policy.</p>

<h3>3.2 Cookies</h3>

<p>We may use cookies and similar technologies to enhance your user experience. You can manage your cookie preferences through your browser settings.</p>

<h3>3.3 Data Security</h3>

<p>We take reasonable measures to protect your data, but no method of transmission over the Internet or electronic storage is 100% secure. We cannot guarantee the security of your information.</p>

<h3>3.4 Proper Use of our Artificial Intelligence (Beta AI)</h3>

<p>At Beta Thread, we utilize artificial intelligence (AI) technology to enhance user experience and provide valuable services. It is imperative that users understand and adhere to the proper use of our AI. While interacting with our AI systems, users are prohibited from engaging in discussions or inquiries related to sensitive topics, including but not limited to:</p>

<ul>
    <li>Politics, political ideologies, or political figures.</li>
    <li>Violent or harmful activities.</li>
    <li>Offensive language, hate speech, or discriminatory remarks.</li>
    <li>Illegal activities or substances.</li>
    <li>Inappropriate or explicit content.</li>
</ul>

<p>We reserve the right to monitor and moderate interactions with our AI to ensure compliance with our community guidelines and standards. Users found violating these guidelines may face account termination or other appropriate actions at our discretion.</p>

<p><strong>Limitations and Mistakes Disclaimer:</strong> Our AI chatbot operates based on the data available globally and may have limitations in its responses. Users should be aware that while we strive for accuracy, our AI can make mistakes and may not always provide perfect responses. Additionally, it may not respond well to follow-up questions. We advise users to ask complete questions for better interaction.</p>

<p>We encourage users to engage with our AI responsibly and contribute to a positive and respectful online environment. If you have any feedback or would like to leave a review about your experience with our AI, please visit our <a href="https://www.betathread.com/contact">contact page</a>.</p>

<h2>4. Disclaimer of Warranties</h2>

<p>Our Services are provided "as is" and "as available" without any warranties, express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>

<h2>5. Limitation of Liability</h2>

<p>To the maximum extent permitted by law, Beta Thread shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>


<h2>6. Contact Us</h2>

<p>If you have any questions or concerns about these Terms and Privacy Policy, please contact us at betathread10@gmail.com.</p>

<p>Thank you for choosing Beta Thread!</p>


            </article>
            </section>

<br />
        <Footer />
        </body>
    )
}