import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLelements() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <p>I am an element content</p>
  `;
      const code2 = `
      <p><i>I am an italicized text</i></p>
      `;
    return (
   <body>
     <Helmet>    
        <title>HTML Elements</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Elements, Nested HTML Elements, Empty Elements/Tags, Container Elements/Tags
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
       <meta name="description" content="An HTML element is usually composed of a 'start tag'(also known as opening tag), the 'element content' and the 'end tag'(also known as closing tag).
       Elements in HTML are also known as tags" />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">
<header className="headertutorials" style={newCode}>

<ScrollToTopLink to="/HTML_comments"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_attributes"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Elements</h1>
</header>

<Navbar />

<main>
<section>

<p>An HTML element is usually composed of a "start tag"(also known as opening tag),
    the "element content" and the "end tag"(also known as closing tag).
    <p>Elements in HTML are also known as tags</p>
</p>
    </section>
    
    <section>
        <h2>Example:</h2>
        <SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html4"><Tryitout /></ScrollToTopLink>

        <p>The example above is composed of the following:</p>
        <ul>
            <li>start tag: &lt;p&gt;</li>
            <li>element content: I am an element content.</li>
            <li>end tag: &lt;/p&gt;</li>
        </ul>
</section>

<section>
    <h2>Nested HTML Elements</h2>
    <p>In some cases an HTML element can contain one or multiple HTML elements.</p>
    <p>Inorder for you to understand this concept look at the example below.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html5"><Tryitout /></ScrollToTopLink>

</section>

<section>
    <p>The nested HTML elements above are composed of the following:</p>
    <ul>
        <li>Start tag: &lt;p&gt;</li>
        <li>Start tag: &lt;i&gt;</li>
        <li>Element Content: Italicized text</li>
        <li>End tag: &lt;/p&gt;</li>
        <li>End tag: &lt;/i&gt;</li>
    </ul>
    <p>As you can see from the example above,there are two start tags and two end tags.</p>
    <p>The &lt;i&gt; tag italicizes the text.</p>
    <p>There are two types of elements in HTML</p>
    <ul>
        <li>Empty Elements/Tags</li>
        <li>Container Elements/Tags</li>
    </ul>
    <h2>Empty Elements/Tags</h2>
    <p>An empty element is one which does not have an element content and an end tag.</p>
    <p>Some of the empty elements include:</p>
    <ul>
        <li>&lt;input /&gt;</li>
        <li>&lt;br /&gt;</li>
        <li>&lt;hr /&gt;</li>
        <li>&lt;meta /&gt;</li>
        <li>&lt;link /&gt;</li>
        <li>&lt;img /&gt;</li>
    </ul>
    <p>It is always advised to put a forward slash / sign before the greater than sign.</p>
    <p>This way they are closed at their start tags.</p>
</section>


<section>
    <h2>Container Elements/Tags</h2>
    <p>Container Elements begin with an opening tag and end with a closing tag.</p>
    <p>The closing tag is similar to the opening except that it contains a forward slash (/) after the opening bracket</p>
    <p>Examples of container tags are &lt;p&gt;&lt;/p&gt;, &lt;h1&gt;&lt;/h1&gt;, &lt;title&gt;&lt;/title&gt; etc.</p>
<p>The basic tags in HTML are discussed below:</p>
<li>&lt;!DOCTYPE html&gt;</li><br />
<p>This tag is used to tell the web browser that the following text document is a HTML document.</p>
<p>There is no need to mention the HTML version.</p>

<li>&lt;html&gt;</li>
<p>This tag comprises of the entire HTML document and everything needs to be written inside these angular brackets.</p>

<li>&lt;head&gt;</li>
<p>This tag is used for the document's header.</p>

<li>&lt;title&gt;</li>
<p>This tag is used to mention the title of the document and is placed inside the &lt;head&gt; tag.</p>

<li>&lt;body&gt;</li>
<p>This tag consists of the body of the HTML document.It includes other HTML tags like &lt;h1&gt; and &lt;p&gt;</p>

<li>&lt;h1&gt;</li>
<p>This tag represents the main heading of the document.</p>
<p>A document can have multiple headings and can be denoted as &lt;h1&gt;,&lt;h2&gt;,...&lt;h6&gt;.</p>

<li>&lt;p&gt;</li>
<p>This is an element used to represent the paragraph of the HTML document.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/HTML_comments"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_attributes"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}