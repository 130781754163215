import React from "react";
import "../../../css/my style.css";
import "../../../css/my style2.css";
import { Helmet } from "react-helmet";
import runcode from "../../../components/CodeEditor";
import SyntaxHighlighterComponent from "../../../components/SyntaxHighlighterComponent";
import SaveCodeButton from "../../../components/Savecodebutton";
import { Link } from "react-router-dom";

export default function JSTry1() {

    const code = `
    <!DOCTYPE html>
    <html>
    <head>
    <title>My First Web Page</title>
    </head>
    <body>
    <script>
    alert("Welcome to my page!");
    </script>
    </body>
    </html>
`;

    return (
    <div>
    <Helmet>
     <title>JavaScript Editor</title>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="HTML, Python, CSS, SQL, JavaScript, How to, PHP, Java, C, C++, C#, jQuery, Bootstrap, Colors, W3.CSS, XML, MySQL, Icons, NodeJS, React, Graphics, Angular, R, AI, Git, Data Science, Code Game, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, References, Examples, Learn to code, Source code, Demos, Tips, Website, Coding tutorials and guides Best coding courses,Front-end and back-end development,Step-by-step Python programming tutorials Java coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
    C++ programming tips for real-world applications." />
   <meta name="description" content="Beta Thread try it out editor for JavaScript with syntax highlighting, saving code functionality, a button to change the theme and other features" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>




<SyntaxHighlighterComponent code={code} language="html" />
   
<Link to="/output_js1"><button>Run Code</button></Link>
<SaveCodeButton code={code} />

</div>


)
}