import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonDataTypes() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeIntegers = `
x = 10
y = -5
z = 0
    `;

    const codeFloats = `
x = 3.14
y = -7.1
z = 0.0
    `;

    const codeStrings = `
x = "Hello, World!"
y = 'Python'
z = """This is a 
multiline string"""
    `;

    const codeLists = `
x = [1, 2, 3, 4, 5]
y = ["apple", "banana", "cherry"]
z = [1, "hello", 3.14]
    `;

    const codeTuples = `
x = (1, 2, 3)
y = ("apple", "banana", "cherry")
z = (1, "hello", 3.14)
    `;

    const codeDictionaries = `
x = {"name": "Alice", "age": 25}
y = {"brand": "Ford", "model": "Mustang", "year": 1964}
    `;

    const codeSets = `
x = {1, 2, 3}
y = {"apple", "banana", "cherry"}
z = {1, "hello", 3.14}
    `;

    return (
        <body>
            <Helmet>
                <title>Python Data Types</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Data Types, Integers, Floats, Strings, Lists, Tuples, Dictionaries, Sets" />
                <meta name="description" content="Learn about the different data types in Python, including integers, floats, strings, lists, tuples, dictionaries, and sets." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                <ScrollToTopLink to="/Python_variables_conversion"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_type_conversion"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Data Types</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Python supports various data types to store different kinds of data. Understanding these data types is fundamental to writing effective Python programs.</p>
                        
                        <h2>Integers</h2>
                        <p>Integers are whole numbers, both positive and negative, including zero.</p>
                        <SyntaxHighlighterComponent code={codeIntegers} language="python" />
                        
                        <h2>Floats</h2>
                        <p>Floats are numbers that have a decimal point. They can also be negative.</p>
                        <SyntaxHighlighterComponent code={codeFloats} language="python" />
                        
                        <h2>Strings</h2>
                        <p>Strings are sequences of characters, used to store text. Strings can be enclosed in single, double, or triple quotes.</p>
                        <SyntaxHighlighterComponent code={codeStrings} language="python" />
                        
                        <h2>Lists</h2>
                        <p>Lists are ordered collections of items that can be of different types. Lists are mutable, meaning their content can be changed after creation.</p>
                        <SyntaxHighlighterComponent code={codeLists} language="python" />
                        
                        <h2>Tuples</h2>
                        <p>Tuples are ordered collections of items similar to lists, but they are immutable, meaning their content cannot be changed after creation.</p>
                        <SyntaxHighlighterComponent code={codeTuples} language="python" />
                        
                        <h2>Dictionaries</h2>
                        <p>Dictionaries are collections of key-value pairs. They are unordered and mutable, and the keys must be unique.</p>
                        <SyntaxHighlighterComponent code={codeDictionaries} language="python" />
                        
                        <h2>Sets</h2>
                        <p>Sets are unordered collections of unique items. Sets are mutable, but they do not allow duplicate elements.</p>
                        <SyntaxHighlighterComponent code={codeSets} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Understanding Python's data types is crucial for effective programming. Each type has its own characteristics and uses, allowing you to choose the best type for your data and operations.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_variables_conversion"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_type_conversion"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
