import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLXPathFunctions() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<bookstore>
  <book category="cooking">
    <title lang="en">Everyday Nigerian</title>
    <author>Uche Anyanwu</author>
    <year>2008</year>
    <price>31.00</price>
  </book>
  <book category="children">
    <title lang="en">Harry Potter</title>
    <author>J K. Rowling</author>
    <year>2005</year>
    <price>29.99</price>
  </book>
  <book category="web">
    <title lang="en">XML Tutorial</title>
    <author>Beta Thread</author>
    <year>2023</year>
    <price>39.95</price>
  </book>
</bookstore>
`;

  const example2 = `
XPath: count(/bookstore/book)
Result: 3
`;

  const example3 = `
XPath: sum(/bookstore/book/price)
Result: 100.94
`;

  const example4 = `
XPath: string-length(/bookstore/book[1]/title)
Result: 17
`;

  return (
    <body>
      <Helmet>
        <title>XPath Functions</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML XPath Functions, XML Tutorial, XML Data Querying" />
        <meta name="description" content="Learn how to use XPath functions for querying XML data with examples and explanations of the syntax and usage." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/XML_xpath_expressions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xpath_axes"><ButtonNext /></ScrollToTopLink>
          <h1>XPath Functions</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XPath functions are used to perform operations on strings, numbers, and boolean values in XML documents. They are essential for complex querying and data manipulation.</p>

            <h2>Example 1: Basic XML Structure</h2>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This example shows a basic XML structure with a bookstore containing books. We will use XPath functions to query and manipulate this data.</p>

            <h2>Example 2: Counting Nodes</h2>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>This example demonstrates the use of the <code>count()</code> function to count the number of book nodes in the bookstore.</p>

            <h2>Example 3: Summing Values</h2>
            <SyntaxHighlighterComponent code={example3} language="xml" />
            <p>This example demonstrates the use of the <code>sum()</code> function to sum the prices of all books in the bookstore.</p>

            <h2>Example 4: String Length</h2>
            <SyntaxHighlighterComponent code={example4} language="xml" />
            <p>This example demonstrates the use of the <code>string-length()</code> function to find the length of the title of the first book.</p>

            <h2>Understanding XPath Functions</h2>
            <p>XPath functions provide a powerful way to perform operations on XML data. They include functions for working with strings, numbers, boolean values, and nodes.</p>

            <ul>
              <li><strong>String Functions:</strong> <code>concat()</code>, <code>contains()</code>, <code>substring()</code>, etc.</li>
              <li><strong>Number Functions:</strong> <code>sum()</code>, <code>count()</code>, <code>number()</code>, etc.</li>
              <li><strong>Boolean Functions:</strong> <code>boolean()</code>, <code>not()</code>, etc.</li>
            </ul>
          </section>
        </main>
        <div className="head">
        <ScrollToTopLink to="/XML_xpath_expressions"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_xpath_axes"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
