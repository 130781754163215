import React, { useEffect, useState } from "react";
import "../css/my style.css";
import "../css/my style3.css";
import "../components/my script";
import { Helmet } from "react-helmet";
import ScrollToTopLink from "../components/ScrollToTop";
import XTipsSidenav from "../components/Sidenav/XTipSidenav";
import ButtonNext from "../components/Buttonnext";
import SyntaxHighlighterComponent from "../components/SyntaxHighlighterComponent";
import Footertutorials from "../components/Footer-tutorials";
import Navbar from "../components/navbar";
import { useNavigate } from "react-router-dom";
import { getAuthStatus } from "../localstorage";

export default function XTips() {
  var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  }
  var secondCode = {
    color: 'black'
  }
  var thirdCode = {
    padding: '20px'
  }

  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate(); // Move useNavigate here

  useEffect(() => {
    const checkAuthentication = () => {
      const isAuthenticated = getAuthStatus('_id');
      if (!isAuthenticated) {
        navigate("/login");
      } else {
        setAuthenticated(true);
      }
    };

    checkAuthentication();
  }, [navigate]);

  const code = `
  <book>
  <title>Harry Potter</title>
  <author>J.K. Rowling</author>
  </book>
  `;
  const code2 = `
  <book category="fantasy">
  <title>Harry Potter</title>
  <author>J.K. Rowling</author>
  </book>

  `;
  const code3 = `
  <description>This is a classic fantasy novel.</description>
  `;

  return (
    <body className="main-theme">
      <Helmet>
        <title>Tutorial Tips</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-compatible" content="IE-edge" />
        <meta name="Keywords" content="Tutorial Tips, HTML, CSS, JS, PHP, Python, Ruby, React, XML, AI, SQL" />
        <meta name="description" content="Welcome to Tutorial Tips, your one-stop shop for all your coding needs. Get expert tips and tutorials on HTML, CSS, JavaScript, PHP, Python, Ruby, React, XML, AI, and SQL." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <XTipsSidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/html_tips"><ButtonNext /></ScrollToTopLink>
          <h1>Tutorial Tips</h1>
              </header>
        <Navbar />
        <main>
        <section>
            <h2>Welcome to Your One-Stop Shop for Coding Solutions</h2>
            <p>Welcome to Tutorial Tips, your ultimate resource for coding tutorials and expert tips. Whether you're a beginner or an experienced developer, we've got you covered.</p>
            <p>Are you tired of searching for hours to find the right coding solution? Do you struggle to keep up with the latest technologies and best practices? Look no further than Tutorial Tips, your one-stop shop for all your coding needs.</p>
          </section>
          <section>
            <h2>What is Tutorial Tips?</h2>
            <p>Tutorial Tips is a comprehensive resource for coders of all levels. Our team of expert developers has put together a vast library of tutorials, tips, and tricks to help you master the latest technologies and improve your coding skills.</p>
          </section>
     
          <section>
            <h2>What Topics Do We Cover?</h2>
            <p>HTML, CSS, JavaScript, React, PHP, Python, Ruby, XML and AI, SQL and database management.</p>
          </section>
         
          <section>
            <h2>Get Started Today!</h2>
            <p>Browse our tutorials and tips to start improving your coding skills today. Whether you're a beginner or an experienced developer, we have something for everyone. Join our community to get help, feedback, and support from others who are on the same journey as you.</p>
          </section>
          <section>
            <h2>Stay Up-to-Date with the Latest Technologies</h2>
            <p>Our tutorials and tips are constantly updated to reflect the latest technologies and best practices. Stay ahead of the curve and improve your coding skills with Tutorial Tips.</p>
          </section>

          <section>
            <h2>Learn from Expert Developers</h2>
            <p>Our team of expert developers has years of experience in the industry and has worked on numerous projects. They have a deep understanding of the latest technologies and best practices, and are passionate about sharing their knowledge with others.</p>
            <p>Our expert developers are dedicated to providing high-quality tutorials and tips that are easy to follow and understand. They use real-world examples and provide step-by-step instructions to help you master the latest technologies.</p>
            <p>Whether you're a beginner or an experienced developer, our expert developers are here to help you improve your coding skills and stay up-to-date with the latest technologies.</p>
          </section>
          <section>
            <h2>Improve Your Coding Skills</h2>
            <p>With Tutorial Tips, you can improve your coding skills and become a better developer. Our tutorials and tips are designed to help you master the latest technologies and best practices, and our expert developers are here to guide you every step of the way.</p>
            <p>Whether you're looking to improve your coding skills for personal or professional reasons, Tutorial Tips has everything you need to succeed.</p>
          </section>
         
          <section>
            <h2>Start Coding Today</h2>
            <p>Don't wait any longer to start improving your coding skills. Browse our tutorials and tips today and start coding like a pro.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/html_tips"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  )
}