import React from "react";
import "../css/my style.css";


export default function GoBack() {
    var navButton = {
        width: 'auto',
        padding: '20px',
        color: 'white',
        margin: '1px',
        borderRadius: '20px',
        height: '45px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: "darkblue"
     };
    return(
        <button className="button" style={navButton}>Go Back</button>
    )
}