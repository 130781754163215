import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PythonMetatag from "../../components/Python_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PythonComments() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    print("Python is fun") #I am a single-line comment
    `;
   
    const code2 = `
    print("Python is fun") 
    #I am a multi-line comment
    #I am a multi-line comment
    #I am a multi-line comment
    #I am a multi-line comment
    #I am a multi-line comment
    `;
 
    return (
   <body>
     <Helmet>
        
        <title>Python Comments</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="Python Comments, Step-by-step Python programming tutorials, Python coding exercises for beginners, Advanced Python coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       Python programming tips for real-world applications," />
      <meta name="description" content="Learn how Python Comments streamline code organization, 
      enhance readability, and unlock advanced features in Python development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/Python_syntax"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_variables"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>Python Comments</h1>
</header>

<Navbar />

<main>
<section>
<p>Python comments are used to give a short explanation for a piece of code.They are used to make programming
            easier and fun.
        </p>
        <p>In Python there are two ways of writing comments,they are:</p>
       <ul>
       <li>single-line comments</li>
        <li>multi-line comments</li>
       </ul>
        
        <h2>Single-line Comments</h2>
        <p>To make useof single-line comments use the hash # character.</p>
        <br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code} language="python" />

<h2>Multi-Line Comments</h2>
<p>Sometimes you might want to write multiple lines of comments to do specify the hash # character on each line.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="python" />
</section>
</main>
<div className="head">
<ScrollToTopLink to="/Python_syntax"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_variables"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}