import React from "react";
import "../../../css/my style.css";
import "../../../css/my style3.css";
import "../../../components/my script";
import Sidenav from "../../../components/Sidenav/PythonSidenav";
import Navbar from "../../../components/navbar";
import Footertutorials from "../../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../../components/Buttonnext";
import Tryitout from "../../../components/Tryitoutbutton";
import PythonMetatag from "../../../components/Python_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../../components/Buttonprevious";
import ScrollToTopLink from "../../../components/ScrollToTop";



export default function PythonVariables() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    x = 5       # Integer
    y = "Hello" # String
   `;
   
    const code2 = `
    my_var = 10
    _myVar = 20
    myVar2 = 30
     `;

    const code3 = `
    x = 10
    print(x)  # Output: 10
    x = "Hello"
    print(x)  # Output: Hello
    `;

    const code4 = `
    x = 5
    y = "10"
    z = int(y)  # Convert to integer
    print(x + z)  # Output: 15
     `;
 
    return (
   <body>
     <Helmet>
        
        <title>Python Variables</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="Python Variables, Variable Naming Rules, Changing Values, Type Conversion" />
      <meta name="description" content="Variables in Python are used to store data. You don't need to declare them explicitly. Just assign a value, and Python will handle the rest." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/Python_comments"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_variables_naming"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>Introduction to Variables</h1>
</header>

<Navbar />

<main>
<section>
<p>Variables in Python are used to store data. You don't need to declare them explicitly. Just assign a value, and Python will handle the rest. </p>
   <h2>Creating Variables</h2>
<p>Simply assign a value to a variable:</p>
    <br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code} language="python" />

<h2>Variable Naming Rules</h2>
<ol type="1">
    <li>Start with a letter or an underscore.</li>
    <li>Use letters, numbers, or underscores.</li>
    <li>Case-sensitive ('myVar' ≠ 'myvar').</li>
</ol>
<h4><b>Valid names:</b></h4>
<SyntaxHighlighterComponent code={code2} language="python" />

<h2>Changing Values</h2>
<p>Variables can change type dynamically:</p>
<SyntaxHighlighterComponent code={code3} language="python" />

<h2>Type Conversion</h2>
<p>Convert between types using built-in functions:</p>
<SyntaxHighlighterComponent code={code4} language="python" />
</section>
</main>
<div className="head">
<ScrollToTopLink to="/Python_comments"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_variables_naming"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}