import React from 'react';
import '../css/my style.css';
import '../css/my style2.css';
import '../css/my style6.css';


 function Header() {

const headerai = {
    padding: '140px',
    textAlign: 'center',
    background: 'darkblue'
}
 const headeraih1 = {
    fontSize: '50px',
    color: 'white',
   
 }
    return (
    <header style={headerai}>
           <div className="header-content">
     <div className="centered-content">
   <h1 className="topple" style={headeraih1}>
  <span>B</span>
<span>e</span>
<span>t</span>
<span>a</span><br />
<span>A</span>
<span>s</span>
<span>s</span>
<span>i</span>
<span>s</span>
<span>t</span>
<span>a</span>
<span>n</span>
<span>t</span>
  
  </h1>
  </div>
  </div>
      </header>
    );
}
export default Header;