import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function CSScolors() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    var secondCode = {
        color: 'black'
    };
    var thirdCode = {
        padding: '20px'
    };

    const code = `
    h1 {
        color: red; /* Using a keyword */
    }  
    `;
    const code2 = `
    #example {
        color: #ff0000; /* Using a hex value */
    }
    h1 {
        color: #00ff00;
    }
    `;
    const code3 = `
    .example {
        color: #f00; /* Using a short hand hex value */
    }
    h1 {
        color: #0f0;
    }
    `;
    const code4 = `
    .example {
        border-color: rgb(255, 0, 0); /* Red color using RGB */
    }
    p {
        border-color: rgb(0, 255, 0); /* Green color using RGB */
    }
    `;
    const code5 = `
    .example {
        color: hsl(120, 100%, 50%); /* Fully saturated green */
    }
    h3 {
        color: hsl(360, 100%, 50%); /* Fully saturated red */
    }
    `;
    const code6 = `
    .rgba-color {
        background-color: rgba(0, 0, 255, 0.5); /* rgb with the alpha channel */
    }
    #hsla-color {
        background-color: hsla(240, 100%, 50%, 0.7); /* hsl with the alpha channel */
    }
    `;

    return (
        <body>
        <Helmet>     
        <title>CSS Colors</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Colors, Types of CSS Colors, Keyword, Hexadecimal (Hex) Values, RGB Values, HSL Values , Alpha Channel
        Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="CSS provides a variety of ways to define colors for elements on a web page.
      These colors can be specified using keywords, hexadecimal values, RGB values, HSL values, and more." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
            <br />
        <br />
        <br />
        <br />
        <br />
            <Sidenav />
        
            <div className="content">

                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/CSS_combinators"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/CSS_text_color"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>CSS Colors</h1>
                </header>

                <Navbar />

                <main>
                    <section>
                        <p>CSS provides a variety of ways to define colors for elements on a web page. </p>
                        <p>These colors can be specified using keywords, hexadecimal values, RGB values, HSL values, and more.</p>
                        <h2>Types of CSS Colors</h2>
                        <li>Keyword</li>
                        <li>Hexadecimal (Hex) Values</li>
                        <li>RGB Values</li>
                        <li>HSL Values</li>
                        <h2>Keyword</h2>
                        <p>CSS provides a set of named colors that you can use directly.</p>
                        <br />
                        <h2>Example:</h2>
                        <SyntaxHighlighterComponent code={code} language="css" />
                        <ScrollToTopLink to="/try_css14"><Tryitout /></ScrollToTopLink>

                        <h2>Hexadecimal (Hex) Values</h2>
                        <p>Hexadecimal values represent colors using a combination of six characters (0-9 and A-F).</p>
                        <p>The hex color value starts with the # character followed by the six characters, the first two indicating
                            color red, the third and fourth indicating green, and the fifth and sixth characters representing blue.
                        </p>
                        <br />
                        <h2>Example:</h2>
                        <SyntaxHighlighterComponent code={code2} language="css" />
                        <ScrollToTopLink to="/try_css15"><Tryitout /></ScrollToTopLink>
                        <p>There is a shorthand format for displaying the hex color value.</p>

                        <br />
                        <h2>Example:</h2>
                        <SyntaxHighlighterComponent code={code3} language="css" />
                        <ScrollToTopLink to="/try_css16"><Tryitout /></ScrollToTopLink>
                        <h2>RGB Values</h2>
                        <p>RGB stands for Red, Green, and Blue. Each color component is represented by an integer
                            value between 0 and 255.
                        </p>
                        <br />
                        <h2>Example:</h2>
                        <SyntaxHighlighterComponent code={code4} language="css" />
                        <ScrollToTopLink to="/try_css17"><Tryitout /></ScrollToTopLink>
                        <h2>HSL Values</h2>
                        <p> HSL stands for Hue, Saturation, and Lightness. It provides a more intuitive way to work with colors.
                        </p>
                        <h3>Hue</h3>
                        <p>This represents the type of color, essentially the angle on the color wheel.</p>
                        <p>It ranges from 0 to 360 degrees, where 0° and 360° are red, 120° is green, and 240° is blue, with
                            other colors in between.</p>
                        <h2>Saturation</h2>
                        <p>This represents the intensity or purity of the color. </p>
                        <p> A saturation of 100% means the color is fully intense, while a saturation of 0% results in
                            a shade of gray.</p>
                        <h2>Lightness</h2>
                        <p>This represents how light or dark the color is. </p>
                        <p>A lightness of 0% results in black, while a lightness of 100% results in white.
                            A lightness of 50% is the "normal" color.</p>
                        <br />
                        <h2>Example:</h2>
                        <SyntaxHighlighterComponent code={code5} language="css" />
                        <ScrollToTopLink to="/try_css18"><Tryitout /></ScrollToTopLink>

                        <h2>Alpha Channel</h2>
                        <p>The alpha channel controls the transparency of a color.
                            It's a value between 0 (fully transparent) and 1 (fully opaque).</p>
                        <p>This allows you to create colors with varying degrees of transparency. The alpha channel applies only to RGBA and HSLA.</p>
                        <p>The alpha channel value controls the transparency of the color. For example,
                            an RGBA value of rgba(0, 0, 255, 0.5) creates a semi-transparent blue color.</p>
                        <br />
                        <h2>Example:</h2>
                        <SyntaxHighlighterComponent code={code6} language="css" />
                        <ScrollToTopLink to="/try_css19"><Tryitout /></ScrollToTopLink>
                    </section>
                </main>
                <div className="head">
                <ScrollToTopLink to="/CSS_combinators"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/CSS_text_color"><ButtonNext /></ScrollToTopLink>
                </div>

            </div>

            <Footertutorials />
        </body>
    );
}
