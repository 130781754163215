import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLlists() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <ul type="square">
      <li>HTML</li>
      <li>CSS</li>
      <li>JavaScript</li>
      </ul>
  `;

    return (
   <body>
     <Helmet>    
        <title>HTML Lists</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Lists, Unordered list, Ordered list, Definition list, <li> element, <ol> element, <ul> element, <dl> element
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="A list provides a way for placing or naming items in an ordered or unordered manner.
       There are three types of lists in HTML." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_pre"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_unordered"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Lists</h1>
</header>

<Navbar />

<main>

<section>
<p>A list provides a way for placing or naming items in an ordered or unordered manner.</p>
<p>There are three types of lists in HTML.</p>
<li>Unordered list</li>
<li>Ordered list</li>
<li>Definition list</li>
<p>HTML list item in HTML is represented with the &lt;li&gt; element.</p>
<p>Below is a simple example of how a list in HTML looks like</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html56"><Tryitout /></ScrollToTopLink>

</section>
 
<div className="head">
<ScrollToTopLink to="/HTML_pre"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_unordered"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}