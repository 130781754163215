import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyVariables() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const variableCreationCode = `
# Defining Variables
name = "Alice"     # String variable
age = 30           # Integer variable
height = 5.7       # Float variable
is_student = true  # Boolean variable

# Outputting Variables
puts name          # Output: Alice
puts age           # Output: 30
puts height        # Output: 5.7
puts is_student    # Output: true
  `;

  const variableScopeCode = `
# Variable Scope

# Global variable
$global_var = "I'm a global variable"

# Class variable
class Example
  @@class_var = "I'm a class variable"

  def show_vars
    # Instance variable
    @instance_var = "I'm an instance variable"
    local_var = "I'm a local variable"

    puts $global_var
    puts @@class_var
    puts @instance_var
    puts local_var
  end
end

example = Example.new
example.show_vars
  `;

  const variableTypesCode = `
# Variable Types

# Local Variables
local_var = "I am local"
def local_example
  local_var = "I am local inside a method"
  puts local_var
end
local_example
puts local_var

# Instance Variables
class InstanceExample
  def initialize
    @instance_var = "I am an instance variable"
  end

  def show_instance_var
    puts @instance_var
  end
end

instance_example = InstanceExample.new
instance_example.show_instance_var

# Class Variables
class ClassExample
  @@class_var = "I am a class variable"

  def self.show_class_var
    puts @@class_var
  end
end

ClassExample.show_class_var

# Global Variables
$global_var = "I am a global variable"

def show_global_var
  puts $global_var
end

show_global_var
  `;

  const constantsCode = `
# Constants

PI = 3.14159
USERNAME = "admin"

# Constants cannot be changed, but Ruby will only warn you, not prevent the change
PI = 3.14   # This will generate a warning
puts PI     # Output: 3.14
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Variables</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Variables, Local Variables, Instance Variables, Class Variables, Global Variables, Constants" />
        <meta name="description" content="Learn about variables in Ruby, including local, instance, class, global variables, and constants." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/Ruby_comments"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/Ruby_data_types"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Variables</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Variables in Ruby are used to store data that can be referenced and manipulated in a program.</p> 
            <p>Ruby has several types of variables, each serving different purposes and having different scopes.</p>
            
            <h2>Defining Variables</h2>
            <p>Variables in Ruby can be defined simply by assigning a value to a name. The type of the variable is determined by the value assigned to it.</p>
            <SyntaxHighlighterComponent code={variableCreationCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>name</code> is a string variable.</li>
              <li><code>age</code> is an integer variable.</li>
              <li><code>height</code> is a float variable.</li>
              <li><code>is_student</code> is a boolean variable.</li>
            </ul>

            <h2>Variable Scope</h2>
            <p>The scope of a variable determines where in the code it can be accessed. Ruby has several variable scopes, including global, class, instance, and local scopes.</p>
            <ul>
              <li><strong>Global Variables:</strong> These are prefixed with <code>$</code> and are accessible from anywhere in the Ruby program.</li>
              <li><strong>Class Variables:</strong> These are prefixed with <code>&#64;&#64;</code> and are shared among all instances of a class.</li>
              <li><strong>Instance Variables:</strong> These are prefixed with <code>&#64;</code> and are available throughout the current instance of the class.</li>
              <li><strong>Local Variables:</strong> These are defined within a method or a block and are not accessible outside of it.</li>
            </ul>
            <SyntaxHighlighterComponent code={variableScopeCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>$global_var</code> is a global variable.</li>
              <li><code>&#64;&#64;class_var</code> is a class variable.</li>
              <li><code>&#64;instance_var</code> is an instance variable.</li>
              <li><code>local_var</code> is a local variable.</li>
            </ul>

            <h2>Variable Types</h2>
            <p>There are different types of variables in Ruby:</p>
            <ul>
              <li><strong>Local Variables:</strong> These are defined within a method or a block and are not accessible outside of it.</li>
              <li><strong>Instance Variables:</strong> These are prefixed with <code>&#64;</code> and are available throughout the current instance of the class.</li>
              <li><strong>Class Variables:</strong> These are prefixed with <code>&#64;&#64;</code> and are shared among all instances of a class.</li>
              <li><strong>Global Variables:</strong> These are prefixed with <code>$</code> and are accessible from anywhere in the Ruby program.</li>
            </ul>
            <SyntaxHighlighterComponent code={variableTypesCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>local_var</code> demonstrates local variables inside and outside a method.</li>
              <li><code>&#64;instance_var</code> demonstrates instance variables within a class.</li>
              <li><code>&#64;&#64;class_var</code> demonstrates class variables within a class.</li>
              <li><code>$global_var</code> demonstrates global variables accessed from a method.</li>
            </ul>

            <h2>Constants</h2>
            <p>Constants are variables that, once assigned a value, are not intended to be changed. Constants are written in all uppercase letters.</p>
            <SyntaxHighlighterComponent code={constantsCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>PI</code> and <code>USERNAME</code> are constants.</li>
              <li>Trying to change a constant will generate a warning, but Ruby will not prevent the change.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_comments"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_data_types"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
