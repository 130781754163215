import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIRoboticsSoftware() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
# Example 1: ROS (Robot Operating System) - Basic Publisher and Subscriber
import rospy
from std_msgs.msg import String

def callback(data):
    rospy.loginfo("I heard %s", data.data)

def listener():
    rospy.init_node('listener', anonymous=True)
    rospy.Subscriber('chatter', String, callback)
    rospy.spin()

def talker():
    pub = rospy.Publisher('chatter', String, queue_size=10)
    rospy.init_node('talker', anonymous=True)
    rate = rospy.Rate(1)  # 1 Hz
    while not rospy.is_shutdown():
        hello_str = "hello world %s" % rospy.get_time()
        rospy.loginfo(hello_str)
        pub.publish(hello_str)
        rate.sleep()

if __name__ == '__main__':
    try:
        talker()
    except rospy.ROSInterruptException:
        pass
    listener()
  `;

  const example2 = `
# Example 2: Using OpenCV for Object Detection
import cv2

# Load pre-trained model and config file
net = cv2.dnn.readNet('yolov3.weights', 'yolov3.cfg')

# Load class names
with open('coco.names', 'r') as f:
    classes = [line.strip() for line in f.readlines()]

# Load image
image = cv2.imread('image.jpg')

# Prepare image for model
blob = cv2.dnn.blobFromImage(image, 0.00392, (416, 416), (0, 0, 0), True, crop=False)
net.setInput(blob)
outs = net.forward(net.getUnconnectedOutLayersNames())

# Process detections
for out in outs:
    for detection in out:
        scores = detection[5:]
        class_id = np.argmax(scores)
        confidence = scores[class_id]
        if confidence > 0.5:
            center_x = int(detection[0] * image.shape[1])
            center_y = int(detection[1] * image.shape[0])
            w = int(detection[2] * image.shape[1])
            h = int(detection[3] * image.shape[0])
            x = int(center_x - w / 2)
            y = int(center_y - h / 2)
            cv2.rectangle(image, (x, y), (x + w, y + h), (255, 0, 0), 2)
            cv2.putText(image, classes[class_id], (x, y - 10), cv2.FONT_HERSHEY_SIMPLEX, 0.5, (255, 0, 0), 2)

cv2.imshow('Object Detection', image)
cv2.waitKey(0)
cv2.destroyAllWindows()
  `;

  const example3 = `
# Example 3: Robot Simulation with Gazebo
import os
import rospy
from gazebo_msgs.srv import SpawnModel
from geometry_msgs.msg import Pose

rospy.init_node('spawn_robot', anonymous=True)

# Load robot model
model_path = os.path.join(os.getenv('GAZEBO_MODEL_PATH'), 'robot_description.urdf')
with open(model_path, 'r') as f:
    robot_description = f.read()

# Define robot initial pose
initial_pose = Pose()
initial_pose.position.x = 0
initial_pose.position.y = 0
initial_pose.position.z = 0

# Spawn robot in Gazebo
rospy.wait_for_service('/gazebo/spawn_urdf_model')
spawn_model = rospy.ServiceProxy('/gazebo/spawn_urdf_model', SpawnModel)
spawn_model('robot', robot_description, '', initial_pose, 'world')
  `;

  return (
    <body>
      <Helmet>
        <title>AI Robotics Software</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Robotics Software, ROS, OpenCV, Gazebo, Robot Simulation, Object Detection" />
        <meta name="description" content="Learn about AI robotics software, including using ROS, OpenCV for object detection, and robot simulation with Gazebo." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/AI_robotics_hardware"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/AI_robotics_applications"><ButtonNext /></ScrollToTopLink>
          <h1>AI Robotics Software</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>AI robotics software involves the programs and algorithms that control and simulate robotic systems. This section covers essential software elements like ROS (Robot Operating System), OpenCV for computer vision, and Gazebo for robot simulation.</p>

            <h2>Examples of Robotics Software</h2>

            <h3>Example 1: ROS (Robot Operating System) - Basic Publisher and Subscriber</h3>
            <SyntaxHighlighterComponent code={example1} language="python" />

            <h3>Example 2: Using OpenCV for Object Detection</h3>
            <SyntaxHighlighterComponent code={example2} language="python" />

            <h3>Example 3: Robot Simulation with Gazebo</h3>
            <SyntaxHighlighterComponent code={example3} language="python" />

            <p>Each example demonstrates different aspects of robotics software. The first example shows how to use ROS for basic communication between nodes, the second example illustrates object detection with OpenCV, and the third example explains how to simulate a robot using Gazebo.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_robotics_hardware"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_robotics_applications"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
