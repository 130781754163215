// CSS questions and answers
export const cssData = [
    {
        question: 'What is CSS',
        answer: 'CSS stands for Cascading Style Sheets.'
    },
    {
        question: 'CSS',
        answer: 'CSS stands for Cascading Style Sheets.'
    },
  {
      question: 'What does CSS stand for?',
      answer: 'CSS stands for Cascading Style Sheets.'
  },
  {
      question: 'What is the purpose of CSS?',
      answer: 'The purpose of CSS is to style web pages, controlling the layout, colors, fonts, and other visual aspects of HTML elements.'
  },
  {
    question: 'What are the the ways to add CSS to HTML?',
    answer: 'The three ways to include CSS in an HTML document are: inline styles, internal styles, and external stylesheets.'
},
  {
      question: 'What are the three ways to include CSS in an HTML document?',
      answer: 'The three ways to include CSS in an HTML document are: inline styles, internal styles, and external stylesheets.'
  },
  {
      question: 'What is the syntax for an inline style?',
      answer: 'The syntax for an inline style is using the style attribute within an HTML element, like this: <p style="color: red;">This is a red paragraph</p>.'
  },
  {
      question: 'What is the syntax for an internal style?',
      answer: 'The syntax for an internal style is using the <style> element within the <head> section of an HTML document, like this: <style> p { color: blue; } </style>.'
  },
  {
      question: 'What is the syntax for an external stylesheet?',
      answer: 'The syntax for an external stylesheet is linking to a separate CSS file using the <link> element within the <head> section of an HTML document, like this: <link rel="stylesheet" href="styles.css">.'
  },
  {
      question: 'What is the selector in CSS?',
      answer: 'A selector in CSS is the part of a CSS rule that selects the HTML elements to which the style will be applied.'
  },
  {
      question: 'What are the different types of selectors in CSS?',
      answer: 'The different types of selectors in CSS include element selectors, class selectors, ID selectors, attribute selectors, and pseudo-selectors.'
  },
  {
      question: 'What is the difference between class and ID selectors?',
      answer: 'Class selectors can be used to style multiple elements, while ID selectors should be unique and can only be used to style one element.'
  },
  {
      question: 'How can you group multiple selectors to apply the same styles?',
      answer: 'You can group multiple selectors by separating them with commas in a CSS rule, like this: h1, h2, h3 { color: blue; }.'
  },
  {
      question: 'What is the box model in CSS?',
      answer: 'The box model in CSS describes the design and layout of elements, consisting of content, padding, border, and margin.'
  },
  {
      question: 'What is the difference between padding and margin in CSS?',
      answer: 'Padding is the space between the content and the border of an element, while margin is the space between the border of an element and surrounding elements.'
  },
  {
      question: 'What is the difference between display: block and display: inline in CSS?',
      answer: 'Elements with display: block take up the full width available and start on a new line, while elements with display: inline only take up as much width as necessary and do not start on a new line.'
  },
  {
      question: 'What is the purpose of the float property in CSS?',
      answer: 'The float property in CSS is used to position elements to the left or right within their containing element, allowing text and other elements to wrap around them.'
  },
  {
      question: 'What is the purpose of the position property in CSS?',
      answer: 'The position property in CSS is used to specify the positioning method of an element, such as static, relative, absolute, or fixed.'
  },
  {
      question: 'What is the difference between position: relative and position: absolute in CSS?',
      answer: 'Position: relative positions an element relative to its normal position in the document flow, while position: absolute positions an element relative to its closest positioned ancestor.'
  },
  {
      question: 'What is the purpose of the z-index property in CSS?',
      answer: 'The z-index property in CSS controls the stacking order of positioned elements, determining which elements appear on top of others.'
  },
  {
      question: 'What is the purpose of the media query in CSS?',
      answer: 'The media query in CSS is used to apply different styles based on characteristics of the device, such as screen size, resolution, and orientation.'
  },
  {
      question: 'What is the purpose of the @keyframes rule in CSS?',
      answer: 'The @keyframes rule in CSS is used to define animations, specifying the style changes that occur at different points during the animation.'
  },
  {
      question: 'What is the purpose of the transform property in CSS?',
      answer: 'The transform property in CSS is used to apply transformations to elements, such as rotating, scaling, skewing, or translating them.'
  },
  {
      question: 'What is the purpose of the transition property in CSS?',
      answer: 'The transition property in CSS is used to control the smoothness of changes in CSS properties over time, allowing for animated effects.'
  },
  {
      question: 'What is the purpose of the flexbox layout model in CSS?',
      answer: 'The flexbox layout model in CSS is used to design flexible and responsive layouts, allowing elements to grow, shrink, and align dynamically within a container.'
  },
  {
      question: 'What is the purpose of the grid layout model in CSS?',
      answer: 'The grid layout model in CSS is used to design complex grid-based layouts, allowing elements to be placed and aligned in rows and columns.'
  },
  {
      question: 'What is the purpose of the box-shadow property in CSS?',
      answer: 'The box-shadow property in CSS is used to add shadow effects to elements, creating depth and dimensionality in the layout.'
  },
  {
      question: 'What is the purpose of the text-shadow property in CSS?',
      answer: 'The text-shadow property in CSS is used to add shadow effects to text, creating emphasis and visual interest.'
  },
  {
      question: 'What is the purpose of the overflow property in CSS?',
      answer: 'The overflow property in CSS is used to control how content that overflows the container is handled, such as clipping, scrolling, or displaying scrollbars.'
  },
  {
      question: 'What is the purpose of the background property in CSS?',
      answer: 'The background property in CSS is used to specify the background color or image of an element, controlling its appearance.'
  },
  {
      question: 'What is the purpose of the gradient property in CSS?',
      answer: 'The gradient property in CSS is used to create smooth transitions between two or more colors, adding depth and dimensionality to elements.'
  },
  {
      question: 'What is the purpose of the flex property in CSS?',
      answer: 'The flex property in CSS is used to specify how a flexible item grows or shrinks to fill available space in a flex container.'
  },
  {
      question: 'What is the purpose of the justify-content property in CSS?',
      answer: 'The justify-content property in CSS is used to align flex items along the main axis of the flex container, distributing space between them.'
  },
  {
      question: 'What is the purpose of the align-items property in CSS?',
      answer: 'The align-items property in CSS is used to align flex items along the cross axis of the flex container, controlling their vertical alignment.'
  },
  {
      question: 'What is the purpose of the align-self property in CSS?',
      answer: 'The align-self property in CSS is used to override the alignment of individual flex items within a flex container, allowing for custom alignment.'
  },
  {
      question: 'What is the purpose of the flex-direction property in CSS?',
      answer: 'The flex-direction property in CSS is used to specify the direction of the main axis in a flex container, determining the layout direction of flex items.'
  },
  {
      question: 'What is the purpose of the order property in CSS?',
      answer: 'The order property in CSS is used to control the order in which flex items appear within a flex container, overriding their default order.'
  },
  {
      question: 'What is the purpose of the align-content property in CSS?',
      answer: 'The align-content property in CSS is used to align multiple lines of flex items along the cross axis of the flex container, controlling their spacing.'
  },
  {
      question: 'What is the purpose of the word-wrap property in CSS?',
      answer: 'The word-wrap property in CSS is used to specify whether or not long words or strings of text can be broken and wrapped onto the next line within an element.'
  },
  {
      question: 'What is the purpose of the text-align property in CSS?',
      answer: 'The text-align property in CSS is used to specify the horizontal alignment of text within an element, such as left, right, center, or justify.'
  },
  {
      question: 'What is the purpose of the text-decoration property in CSS?',
      answer: 'The text-decoration property in CSS is used to specify the decoration added to text, such as underline, overline, line-through, or blink.'
  },
  {
      question: 'What is the purpose of the line-height property in CSS?',
      answer: 'The line-height property in CSS is used to control the height of lines of text within an element, affecting vertical spacing and alignment.'
  },
  {
      question: 'What is the purpose of the font-family property in CSS?',
      answer: 'The font-family property in CSS is used to specify the typeface or font family used for text within an element, allowing for custom font choices.'
  },
  {
      question: 'What is the purpose of the font-size property in CSS?',
      answer: 'The font-size property in CSS is used to specify the size of text within an element, controlling its appearance and legibility.'
  },
  {
      question: 'What is the purpose of the font-weight property in CSS?',
      answer: 'The font-weight property in CSS is used to specify the thickness or boldness of text within an element, altering its visual weight.'
  },
  {
      question: 'What is the purpose of the font-style property in CSS?',
      answer: 'The font-style property in CSS is used to specify the style of text within an element, such as normal, italic, or oblique.'
  },
  {
      question: 'What is the purpose of the font-variant property in CSS?',
      answer: 'The font-variant property in CSS is used to specify whether or not small-caps are used for lowercase letters within an element.'
  },
  {
      question: 'What is the purpose of the font property in CSS?',
      answer: 'The font property in CSS is a shorthand property for setting the font-related properties, such as font-size, font-family, font-weight, etc.'
  },
  {
      question: 'What is the purpose of the color property in CSS?',
      answer: 'The color property in CSS is used to specify the color of text within an element, controlling its appearance and readability.'
  },
  {
      question: 'What is the purpose of the border property in CSS?',
      answer: 'The border property in CSS is used to specify the width, style, and color of the border of an element, controlling its appearance.'
  },
  {
      question: 'What is the purpose of the background-color property in CSS?',
      answer: 'The background-color property in CSS is used to specify the background color of an element, controlling its appearance behind the content.'
  },
  {
      question: 'What is the purpose of the background-image property in CSS?',
      answer: 'The background-image property in CSS is used to specify an image as the background of an element, controlling its appearance behind the content.'
  },
  {
      question: 'What is the purpose of the background-size property in CSS?',
      answer: 'The background-size property in CSS is used to specify the size of a background image relative to the element, controlling its scaling behavior.'
  },
  {
      question: 'What is the purpose of the background-position property in CSS?',
      answer: 'The background-position property in CSS is used to specify the starting position of a background image within an element, controlling its placement.'
  },
  {
      question: 'What is the purpose of the background-repeat property in CSS?',
      answer: 'The background-repeat property in CSS is used to specify whether and how a background image should repeat within an element, controlling its tiling behavior.'
  },
  {
      question: 'What is the purpose of the background-attachment property in CSS?',
      answer: 'The background-attachment property in CSS is used to specify whether a background image is fixed or scrolls with the content of an element.'
  },
  {
      question: 'What is the purpose of the background property in CSS?',
      answer: 'The background property in CSS is a shorthand property for setting all background-related properties, such as background-color, background-image, etc.'
  },
  {
      question: 'What is the purpose of the padding property in CSS?',
      answer: 'The padding property in CSS is used to specify the space between the content of an element and its border, controlling its internal spacing.'
  },
  {
      question: 'What is the purpose of the margin property in CSS?',
      answer: 'The margin property in CSS is used to specify the space between the border of an element and surrounding elements, controlling its external spacing.'
  },
  {
      question: 'What is the purpose of the width property in CSS?',
      answer: 'The width property in CSS is used to specify the width of an element, controlling its horizontal size within its containing element.'
  },
  {
      question: 'What is the purpose of the height property in CSS?',
      answer: 'The height property in CSS is used to specify the height of an element, controlling its vertical size within its containing element.'
  },
  {
      question: 'What is the purpose of the max-width property in CSS?',
      answer: 'The max-width property in CSS is used to specify the maximum width of an element, preventing it from expanding beyond a certain size.'
  },
  {
      question: 'What is the purpose of the max-height property in CSS?',
      answer: 'The max-height property in CSS is used to specify the maximum height of an element, preventing it from expanding beyond a certain size.'
  },
  {
      question: 'What is the purpose of the min-width property in CSS?',
      answer: 'The min-width property in CSS is used to specify the minimum width of an element, ensuring that it does not shrink below a certain size.'
  },
  {
      question: 'What is the purpose of the min-height property in CSS?',
      answer: 'The min-height property in CSS is used to specify the minimum height of an element, ensuring that it does not shrink below a certain size.'
  },
  {
      question: 'What is the purpose of the overflow-x property in CSS?',
      answer: 'The overflow-x property in CSS is used to specify how horizontal overflow content is handled within an element, controlling its visibility and scrolling behavior.'
  },
  {
      question: 'What is the purpose of the overflow-y property in CSS?',
      answer: 'The overflow-y property in CSS is used to specify how vertical overflow content is handled within an element, controlling its visibility and scrolling behavior.'
  },
  {
      question: 'What is the purpose of the display property in CSS?',
      answer: 'The display property in CSS is used to specify the type of rendering box used for an element, controlling its layout and behavior within the document flow.'
  },
  {
      question: 'What is the purpose of the visibility property in CSS?',
      answer: 'The visibility property in CSS is used to specify whether an element is visible or hidden, controlling its display without affecting layout.'
  },
  {
      question: 'What is the purpose of the cursor property in CSS?',
      answer: 'The cursor property in CSS is used to specify the type of cursor to be displayed when hovering over an element, providing visual feedback to the user.'
  },
  {
      question: 'What is the purpose of the position property in CSS?',
      answer: 'The position property in CSS is used to specify the positioning method of an element, such as static, relative, absolute, or fixed.'
  },
  {
      question: 'What is the purpose of the top property in CSS?',
      answer: 'The top property in CSS is used to specify the distance between the top edge of a positioned element and the top edge of its containing block.'
  },
  {
      question: 'What is the purpose of the right property in CSS?',
      answer: 'The right property in CSS is used to specify the distance between the right edge of a positioned element and the right edge of its containing block.'
  },
  {
      question: 'What is the purpose of the bottom property in CSS?',
      answer: 'The bottom property in CSS is used to specify the distance between the bottom edge of a positioned element and the bottom edge of its containing block.'
  },
  {
      question: 'What is the purpose of the left property in CSS?',
      answer: 'The left property in CSS is used to specify the distance between the left edge of a positioned element and the left edge of its containing block.'
  },
  {
      question: 'What is the purpose of the float property in CSS?',
      answer: 'The float property in CSS is used to position elements to the left or right within their containing element, allowing text and other elements to wrap around them.'
  },
  {
      question: 'What is the purpose of the clear property in CSS?',
      answer: 'The clear property in CSS is used to control whether an element can be positioned next to floating elements that precede it in the document flow.'
  },
  {
      question: 'What is the purpose of the overflow property in CSS?',
      answer: 'The overflow property in CSS is used to control how content that overflows the container is handled, such as clipping, scrolling, or displaying scrollbars.'
  },
  {
      question: 'What is the purpose of the clip property in CSS?',
      answer: 'The clip property in CSS is used to specify the clipping region of an absolutely positioned element, cropping its content to fit within the defined area.'
  },
  {
      question: 'What is the purpose of the zoom property in CSS?',
      answer: 'The zoom property in CSS is used to specify the zoom level of an element, affecting its size and scale relative to other elements.'
  },
  {
      question: 'What is the purpose of the box-sizing property in CSS?',
      answer: 'The box-sizing property in CSS is used to specify whether an element\'s width and height include padding and border sizes, affecting its overall dimensions.'
  },
  {
      question: 'What is the purpose of the direction property in CSS?',
      answer: 'The direction property in CSS is used to specify the direction of text within an element, controlling the writing mode and text flow.'
  },
  {
      question: 'What is the purpose of the unicode-bidi property in CSS?',
      answer: 'The unicode-bidi property in CSS is used to specify the directionality of text within an element, controlling the behavior of bidirectional text.'
  },
  {
      question: 'What is the purpose of the border-collapse property in CSS?',
      answer: 'The border-collapse property in CSS is used to specify whether the borders of table cells are collapsed into a single border or separated.'
  },
  {
      question: 'What is the purpose of the border-spacing property in CSS?',
      answer: 'The border-spacing property in CSS is used to specify the distance between adjacent borders of table cells when border-collapse is set to separate.'
  },
  {
      question: 'What is the purpose of the caption-side property in CSS?',
      answer: 'The caption-side property in CSS is used to specify the placement of the table caption, such as top, bottom, left, or right.'
  },
  {
      question: 'What is the purpose of the empty-cells property in CSS?',
      answer: 'The empty-cells property in CSS is used to specify whether or not to display borders and backgrounds of table cells that contain no content.'
  },
  {
      question: 'What is the purpose of the table-layout property in CSS?',
      answer: 'The table-layout property in CSS is used to specify the algorithm used to layout the table cells, controlling their width distribution.'
  },
  {
      question: 'What is the purpose of the visibility property in CSS?',
      answer: 'The visibility property in CSS is used to specify whether an element is visible or hidden, controlling its display without affecting layout.'
  },
  {
      question: 'What is the purpose of the white-space property in CSS?',
      answer: 'The white-space property in CSS is used to specify how white space within an element is handled, controlling whether it collapses or preserves.'
  },
  {
      question: 'What is the purpose of the position property in CSS?',
      answer: 'The position property in CSS is used to specify the positioning method of an element, such as static, relative, absolute, or fixed.'
  },
  {
      question: 'What is the purpose of the clip-path property in CSS?',
      answer: 'The clip-path property in CSS is used to specify the clipping region of an element, defining the area that is visible and hiding the rest.'
  },
  {
      question: 'What is the purpose of the mask property in CSS?',
      answer: 'The mask property in CSS is used to specify an SVG image or CSS gradient as a mask for an element, defining areas of visibility and transparency.'
  },
  {
      question: 'What is the purpose of the filter property in CSS?',
      answer: 'The filter property in CSS is used to apply graphical effects like blur or color shifting to elements, altering their appearance.'
  },
  {
      question: 'What is the purpose of the opacity property in CSS?',
      answer: 'The opacity property in CSS is used to specify the transparency level of an element, controlling how much it blends with its background.'
  },
  {
      question: 'What is the purpose of the transition property in CSS?',
      answer: 'The transition property in CSS is used to control the smoothness of changes in CSS properties over time, allowing for animated effects.'
  },
  {
      question: 'What is the purpose of the animation property in CSS?',
      answer: 'The animation property in CSS is used to specify the keyframes and timing of an animation, controlling its playback and appearance.'
  },

    {
        question: 'What is the purpose of the box-shadow property in CSS?',
        answer: 'The box-shadow property in CSS is used to add shadow effects to elements, creating depth and dimensionality in the layout.'
    },
    {
        question: 'Explain the difference between margin and padding in CSS.',
        answer: 'Margin is the space outside the border of an element, while padding is the space inside the border of an element.'
    },
    {
        question: 'How can you center align a block-level element horizontally in CSS?',
        answer: 'You can center align a block-level element horizontally by setting its left and right margins to auto and giving it a specified width.'
    },
    {
        question: 'What is the purpose of the float property in CSS? Provide an example.',
        answer: 'The float property in CSS is used to position elements to the left or right within their containing element, allowing text and other elements to wrap around them. Example: .float-example { float: left; }'
    },
    {
        question: 'Explain the difference between position: relative and position: absolute in CSS.',
        answer: 'Position: relative positions an element relative to its normal position in the document flow, while position: absolute positions an element relative to its closest positioned ancestor.'
    },
    {
        question: 'What is the purpose of the z-index property in CSS?',
        answer: 'The z-index property in CSS controls the stacking order of positioned elements, determining which elements appear on top of others.'
    },
    {
        question: 'How can you create a responsive design using CSS?',
        answer: 'You can create a responsive design using CSS by using media queries to apply different styles based on the size and orientation of the viewport.'
    },
    {
        question: 'Explain the purpose of the flexbox layout model in CSS.',
        answer: 'The flexbox layout model in CSS is used to design flexible and responsive layouts, allowing elements to grow, shrink, and align dynamically within a container.'
    },
    {
        question: 'What is the purpose of the grid layout model in CSS?',
        answer: 'The grid layout model in CSS is used to design complex grid-based layouts, allowing elements to be placed and aligned in rows and columns.'
    },
    {
        question: 'What is the purpose of the transform property in CSS?',
        answer: 'The transform property in CSS is used to apply transformations to elements, such as rotating, scaling, skewing, or translating them.'
    },
    {
        question: 'How can you create an animation in CSS?',
        answer: 'You can create an animation in CSS by using the @keyframes rule to define the keyframes of the animation and then applying it to an element using the animation property.'
    },
    {
        question: 'What is the purpose of the transition property in CSS?',
        answer: 'The transition property in CSS is used to control the smoothness of changes in CSS properties over time, allowing for animated effects.'
    },
    {
        question: 'How can you vertically align an element in CSS?',
        answer: 'You can vertically align an element in CSS by using the line-height property set to the same height as the container or by using flexbox properties like align-items or align-self.'
    },
    {
        question: 'What is the purpose of the display property in CSS?',
        answer: 'The display property in CSS is used to specify the type of rendering box used for an element, controlling its layout and behavior within the document flow.'
    },
    {
        question: 'Explain the difference between display: inline and display: inline-block in CSS.',
        answer: 'Display: inline elements do not start on a new line and only take up as much width as necessary, while display: inline-block elements start on a new line and can have specified width and height.'
    },
    {
        question: 'How can you hide an element in CSS?',
        answer: 'You can hide an element in CSS by using the display property and setting it to none, or by using the visibility property and setting it to hidden.'
    },
    {
        question: 'What is the purpose of the overflow property in CSS?',
        answer: 'The overflow property in CSS is used to control how content that overflows the container is handled, such as clipping, scrolling, or displaying scrollbars.'
    },
    {
        question: 'What is the purpose of the cursor property in CSS?',
        answer: 'The cursor property in CSS is used to specify the type of cursor to be displayed when hovering over an element, providing visual feedback to the user.'
    },
    {
        question: 'Explain the purpose of the ::before and ::after pseudo-elements in CSS.',
        answer: 'The ::before and ::after pseudo-elements in CSS are used to insert content before and after an element’s content, respectively, without needing to add additional markup in the HTML.'
    },
    {
        question: 'What is the purpose of the content property in CSS?',
        answer: 'The content property in CSS is used with the ::before and ::after pseudo-elements to insert content before and after an element’s content, respectively.'
    },
    {
        question: 'How can you create a tooltip in CSS?',
        answer: 'You can create a tooltip in CSS by using the ::before or ::after pseudo-elements along with the content property to insert the tooltip text, and then styling it with positioning and visibility properties.'
    },
    {
        question: 'Explain the purpose of the opacity property in CSS.',
        answer: 'The opacity property in CSS is used to specify the transparency level of an element, controlling how much it blends with its background.'
    },
    {
        question: 'What is the purpose of the visibility property in CSS?',
        answer: 'The visibility property in CSS is used to specify whether an element is visible or hidden, controlling its display without affecting layout.'
    },
    {
        question: 'How can you create a drop shadow effect in CSS?',
        answer: 'You can create a drop shadow effect in CSS by using the box-shadow property to add a shadow to an element, specifying the horizontal and vertical offsets, blur radius, spread radius, and color of the shadow.'
    },
    {
        question: 'Explain the purpose of the clip-path property in CSS.',
        answer: 'The clip-path property in CSS is used to specify the clipping region of an element, defining the area that is visible and hiding the rest.'
    },
    {
        question: 'What is the purpose of the filter property in CSS?',
        answer: 'The filter property in CSS is used to apply graphical effects like blur or color shifting to elements, altering their appearance.'
    },
    {
        question: 'How can you create a gradient background in CSS?',
        answer: 'You can create a gradient background in CSS by using the linear-gradient() or radial-gradient() functions within the background property, specifying the starting and ending colors and direction of the gradient.'
    },
    {
        question: 'What is the purpose of the transition-timing-function property in CSS?',
        answer: 'The transition-timing-function property in CSS is used to specify the speed curve of the transition effect, controlling how the transition progresses over time.'
    },
    {
        question: 'Explain the purpose of the @media rule in CSS.',
        answer: 'The @media rule in CSS is used to apply different styles based on the characteristics of the output device, such as screen size, resolution, or orientation, allowing for responsive design.'
    },
    {
        question: 'How can you create a fixed header in CSS?',
        answer: 'You can create a fixed header in CSS by setting the position property of the header element to fixed, and specifying the top and left properties to position it at the top of the viewport.'
    },
    {
        question: 'What is the purpose of the text-shadow property in CSS?',
        answer: 'The text-shadow property in CSS is used to add shadow effects to text, creating depth and dimensionality in the layout.'
    },
    {
        question: 'How can you create a sticky footer in CSS?',
        answer: 'You can create a sticky footer in CSS by setting the position property of the footer element to fixed or absolute, and specifying the bottom property to position it at the bottom of the viewport.'
    },
    {
        question: 'Explain the purpose of the perspective property in CSS.',
        answer: 'The perspective property in CSS is used to create a 3D perspective effect for transformed elements, determining the distance between the z=0 plane and the user in order to calculate the depth of the 3D effect.'
    },
    {
        question: 'What is the purpose of the backface-visibility property in CSS?',
        answer: 'The backface-visibility property in CSS is used to control whether or not the back face of a transformed element is visible, affecting the rendering of 3D transformed elements.'
    },
    {
        question: 'Explain the purpose of the transition-delay property in CSS.',
        answer: 'The transition-delay property in CSS is used to specify a delay before the transition effect starts, allowing for more control over the timing of the transition.'
    },
    {
        question: 'What is the purpose of the animation-timing-function property in CSS?',
        answer: 'The animation-timing-function property in CSS is used to specify the speed curve of the animation effect, controlling how the animation progresses over time.'
    },
    {
        question: 'How can you create a parallax scrolling effect in CSS?',
        answer: 'You can create a parallax scrolling effect in CSS by using background-attachment: fixed and background-size: cover properties on background images, and adjusting the positioning of elements relative to the scroll position.'
    },
    {
        question: 'What is the purpose of the transform-origin property in CSS?',
        answer: 'The transform-origin property in CSS is used to specify the point around which a transformation is applied, determining the center of rotation, scaling, or skewing.'
    },
    {
        question: 'Explain the purpose of the perspective-origin property in CSS.',
        answer: 'The perspective-origin property in CSS is used to specify the origin point of the perspective for transformed elements, determining the position where the viewer is looking at the 3D scene.'
    },
    {
        question: 'What is the purpose of the justify-content property in CSS?',
        answer: 'The justify-content property in CSS is used to align flex items along the main axis of the flex container, distributing space between them.'
    },
    {
        question: 'Explain the purpose of the align-items property in CSS.',
        answer: 'The align-items property in CSS is used to align flex items along the cross axis of the flex container, controlling their vertical alignment.'
    },
    {
        question: 'What is the purpose of the align-self property in CSS?',
        answer: 'The align-self property in CSS is used to override the alignment of individual flex items within a flex container, allowing for custom alignment.'
    },
    {
        question: 'Explain the purpose of the flex-grow property in CSS.',
        answer: 'The flex-grow property in CSS is used to specify the ability of a flex item to grow to fill available space within a flex container, distributing any extra space proportionally.'
    },
    {
        question: 'What is the purpose of the flex-shrink property in CSS?',
        answer: 'The flex-shrink property in CSS is used to specify the ability of a flex item to shrink to fit into the available space within a flex container, preventing it from overflowing.'
    },
    {
        question: 'Explain the purpose of the flex-basis property in CSS.',
        answer: 'The flex-basis property in CSS is used to specify the initial size of a flex item before any extra space is distributed, determining its starting width or height.'
    },
    {
        question: 'What is the purpose of the flex property in CSS?',
        answer: 'The flex property in CSS is a shorthand property for setting the flex-grow, flex-shrink, and flex-basis properties of a flex item in a single declaration.'
    },
    {
        question: 'Explain the purpose of the flex-wrap property in CSS.',
        answer: 'The flex-wrap property in CSS is used to control whether flex items are forced onto a single line or can wrap onto multiple lines within a flex container.'
    },
    {
        question: 'What is the purpose of the align-content property in CSS?',
        answer: 'The align-content property in CSS is used to align multiple lines of flex items along the cross axis of the flex container, controlling their spacing.'
    },
    {
        question: 'Explain the purpose of the grid-template-columns property in CSS.',
        answer: 'The grid-template-columns property in CSS is used to define the columns of a grid container, specifying their size, width, and alignment.'
    },
    {
        question: 'What is the purpose of the grid-template-rows property in CSS?',
        answer: 'The grid-template-rows property in CSS is used to define the rows of a grid container, specifying their size, height, and alignment.'
    },
    {
        question: 'Explain the purpose of the grid-column-gap property in CSS.',
        answer: 'The grid-column-gap property in CSS is used to specify the size of the gap between columns in a grid layout, controlling the spacing between grid items horizontally.'
    },
    {
        question: 'What is the purpose of the grid-row-gap property in CSS?',
        answer: 'The grid-row-gap property in CSS is used to specify the size of the gap between rows in a grid layout, controlling the spacing between grid items vertically.'
    },
    {
        question: 'Explain the purpose of the grid-gap property in CSS.',
        answer: 'The grid-gap property in CSS is a shorthand property for setting the grid-row-gap and grid-column-gap properties of a grid layout in a single declaration.'
    },
    {
        question: 'What is the purpose of the grid-auto-columns property in CSS?',
        answer: 'The grid-auto-columns property in CSS is used to specify the size of implicitly created grid tracks in a grid layout, controlling the size of columns not explicitly defined.'
    },
    {
        question: 'Explain the purpose of the grid-auto-rows property in CSS.',
        answer: 'The grid-auto-rows property in CSS is used to specify the size of implicitly created grid tracks in a grid layout, controlling the size of rows not explicitly defined.'
    },
    {
        question: 'What is the purpose of the grid-auto-flow property in CSS?',
        answer: 'The grid-auto-flow property in CSS is used to specify the placement of implicitly created grid items in a grid layout, controlling their arrangement and direction.'
    },
    {
        question: 'Explain the purpose of the grid-template property in CSS.',
        answer: 'The grid-template property in CSS is a shorthand property for setting the grid-template-rows, grid-template-columns, and grid-template-areas properties of a grid layout in a single declaration.'
    },
    {
        question: 'What is the purpose of the gap property in CSS?',
        answer: 'The gap property in CSS is a shorthand property for setting the row-gap and column-gap properties of a grid layout in a single declaration.'
    },
    {
        question: 'Explain the purpose of the place-items property in CSS.',
        answer: 'The place-items property in CSS is a shorthand property for setting the align-items and justify-items properties of a grid or flex container in a single declaration.'
    },
    {
        question: 'What is the purpose of the place-content property in CSS?',
        answer: 'The place-content property in CSS is a shorthand property for setting the align-content and justify-content properties of a grid or flex container in a single declaration.'
    },
    {
        question: 'Explain the purpose of the place-self property in CSS.',
        answer: 'The place-self property in CSS is used to align an individual grid or flex item within its container along the grid or flex axis, overriding the align-self and justify-self properties of the container.'
    },
    {
        question: 'What is the purpose of the object-fit property in CSS?',
        answer: 'The object-fit property in CSS is used to specify how an image or video should be resized to fit into its container, controlling its aspect ratio and cropping behavior.'
    },
    {
        question: 'Explain the purpose of the object-position property in CSS.',
        answer: 'The object-position property in CSS is used to specify the alignment of an image or video within its container, controlling its position relative to the container.'
    },
    {
        question: 'What is the purpose of the scroll-behavior property in CSS?',
        answer: 'The scroll-behavior property in CSS is used to specify the scrolling behavior of an element, controlling whether it scrolls smoothly or jumps to each scroll position.'
    },
    {
        question: 'Explain the purpose of the scroll-snap-type property in CSS.',
        answer: 'The scroll-snap-type property in CSS is used to control the snapping behavior of scroll positions within a scroll container, allowing for precise scrolling and paging effects.'
    },
    {
        question: 'What is the purpose of the scroll-snap-align property in CSS?',
        answer: 'The scroll-snap-align property in CSS is used to control the alignment of snapped positions within a scroll container, specifying where the container should snap to after scrolling.'
    },
    {
        question: 'Explain the purpose of the backdrop-filter property in CSS.',
        answer: 'The backdrop-filter property in CSS is used to apply graphical effects like blur or color shifting to the area behind an element, creating a frosted glass effect.'
    },
    {
        question: 'What is the purpose of the overscroll-behavior property in CSS?',
        answer: 'The overscroll-behavior property in CSS is used to control the scrolling behavior when reaching the boundaries of a scroll container, such as allowing or preventing rubber band scrolling.'
    },
    {
        question: 'Explain the purpose of the aspect-ratio property in CSS.',
        answer: 'The aspect-ratio property in CSS is used to specify the aspect ratio of an element, controlling its width relative to its height or vice versa.'
    },
    {
        question: 'What is the purpose of the contain property in CSS?',
        answer: 'The contain property in CSS is used to specify how an element should contain its children, controlling their size, layout, and paint behavior.'
    },
    {
        question: 'Explain the purpose of the isolation property in CSS.',
        answer: 'The isolation property in CSS is used to control the stacking context of an element, isolating it from other elements to prevent blending and compositing effects.'
    },
    {
        question: 'What is the purpose of the mix-blend-mode property in CSS?',
        answer: 'The mix-blend-mode property in CSS is used to specify how the colors of an element should blend with the colors of its background, allowing for various blending effects.'
    },
    {
        question: 'Explain the purpose of the mask-image property in CSS.',
        answer: 'The mask-image property in CSS is used to specify an image or gradient as a mask for an element, defining areas of visibility and transparency.'
    },
    {
        question: 'What is the purpose of the mask-size property in CSS?',
        answer: 'The mask-size property in CSS is used to specify the size of the mask layer relative to the element’s background, controlling its scale and positioning.'
    },
    {
        question: 'Explain the purpose of the mask-position property in CSS.',
        answer: 'The mask-position property in CSS is used to specify the position of the mask layer relative to the element’s background, controlling its alignment within the element.'
    },
    {
        question: 'What is the purpose of the mask-repeat property in CSS?',
        answer: 'The mask-repeat property in CSS is used to control how the mask layer is repeated or clipped to fit the element’s background, controlling its tiling behavior.'
    },
    {
        question: 'Explain the purpose of the mask-origin property in CSS.',
        answer: 'The mask-origin property in CSS is used to specify the positioning area of the mask layer relative to the element’s box, determining where the mask is applied.'
    },
    {
        question: 'What is the purpose of the mask-composite property in CSS?',
        answer: 'The mask-composite property in CSS is used to specify how multiple mask layers are combined together, controlling their blending behavior.'
    },
    {
        question: 'Explain the purpose of the mask-mode property in CSS.',
        answer: 'The mask-mode property in CSS is used to specify how the mask layer is composited with the element’s content, controlling its rendering mode.'
    },
    {
        question: 'What is the purpose of the mask-clip property in CSS?',
        answer: 'The mask-clip property in CSS is used to specify the painting area of the mask layer relative to the element’s box, determining where the mask is visible.'
    },
    {
        question: 'Explain the purpose of the mask-border-source property in CSS.',
        answer: 'The mask-border-source property in CSS is used to specify an image or gradient as a mask for the border of an element, defining areas of visibility and transparency.'
    },
    {
        question: 'What is the purpose of the mask-border-slice property in CSS?',
        answer: 'The mask-border-slice property in CSS is used to specify how the mask border image is sliced into regions to be used as corners, edges, and middle parts of the border.'
    },
    {
        question: 'Explain the purpose of the mask-border-width property in CSS.',
        answer: 'The mask-border-width property in CSS is used to specify the width of the mask border image to be used as corners, edges, and middle parts of the border.'
    },
    {
        question: 'What is the purpose of the mask-border-outset property in CSS?',
        answer: 'The mask-border-outset property in CSS is used to specify the amount by which the mask border image is extended beyond the border box, controlling its outward offset.'
    },
    {
        question: 'Explain the purpose of the mask-border-repeat property in CSS.',
        answer: 'The mask-border-repeat property in CSS is used to specify how the mask border image is repeated or clipped to fit the border box, controlling its tiling behavior.'
    },
    {
        question: 'What is the purpose of the mask-border property in CSS?',
        answer: 'The mask-border property in CSS is a shorthand property for setting the mask-border-source, mask-border-slice, mask-border-width, mask-border-outset, and mask-border-repeat properties of an element’s border in a single declaration.'
    },
    {
        question: 'Explain the purpose of the mask-border-mode property in CSS.',
        answer: 'The mask-border-mode property in CSS is used to specify how the mask border image is composited with the element’s content, controlling its rendering mode.'
    },
    {
        question: 'What is the purpose of the mask-border-clip property in CSS?',
        answer: 'The mask-border-clip property in CSS is used to specify the painting area of the mask border image relative to the border box, determining where the mask is visible.'
    },
    {
        question: 'Explain the purpose of the mask-border-composite property in CSS.',
        answer: 'The mask-border-composite property in CSS is used to specify how multiple mask border images are combined together, controlling their blending behavior.'
    }

];
