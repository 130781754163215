import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLformselementattr2() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <input type="text" name="fullname" />
      <input type="text" name="age" />
      <input type="submit" />
  `;
  const code2 = `
  <input type="file" multiple />
  <input type="submit" />

`;
const code3 = `
<input type="text" autofocus />
<input type="date" autofocus />
<input type="submit" />
`;
const code4 = `
<input type="text" disabled />
<input type="submit" />
`;

const code5 = `
<input type="text" readonly />
<input type="submit" />
`;

    return (
   <body>
    <Helmet>    
        <title>HTML5 Forms Element Attributes 2</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML5 Forms Element Attributes 2, name Attribute, multiple Attribute, autofocus Attribute, disabled Attribute, readonly Attribute, 
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
       <meta name="description" content="In the last lesson we learn about some form element attributes now we are going to learn about some other form element attribute.They are as follows:" />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_forms_attr"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_js"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML5 Forms Element Attributes 2</h1>
</header>

<Navbar />

<main>

<section>
<p>In the last lesson we learn about some form element attributes now we are going
    to learn about some other form element attribute.They are as follows:
  </p>
  <h3>name Attribute</h3>
  <p>This attribute indicates the name of a control which represents its data.</p>
  <p>When you submit a form the form-handler uses the the name given to the element to identify
    or get the value.
  </p>
  <br />
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html70"><Tryitout /></ScrollToTopLink>

<h3>multiple Attribute</h3>
    <p>This indicate that multiple items can be selected in a form control.
        It is a boolean attribute just like the required attribute.</p>
    <p>This attribute is used with the input type value which is file.</p>
<br />
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html71"><Tryitout /></ScrollToTopLink>
</section>

<section>
    <h3>autofocus Attribute</h3>
    <p>This attribute indicates whether a text field or form control should be focused.</p>
    <p>This is a boolean attribute</p>
  <br />
  <SyntaxHighlighterComponent code={code3} language="html" />
  <ScrollToTopLink to="/try_html72"><Tryitout /></ScrollToTopLink>

  <h3>disabled Attribute</h3>
  <p>This indicates that a textfield should be disabled.This is a boolean attribute.</p>
      <br />
      <SyntaxHighlighterComponent code={code4} language="html" />
<ScrollToTopLink to="/try_html73"><Tryitout /></ScrollToTopLink>

<h3>readonly Attribute</h3>
    <p>This attribute indicates that a text field should be readonly i.e,you cannot type any text inside.It is a boolean attribute.</p>
    <br />
    <SyntaxHighlighterComponent code={code5} language="html" />
<ScrollToTopLink to="/try_html74"><Tryitout /></ScrollToTopLink>

<br />

</section>
<div className="head">
<ScrollToTopLink to="/HTML_forms_attr"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_js"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}