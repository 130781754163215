import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PythonMetatag from "../../components/Python_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";


export default function Pythoninstalling() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    x = 10
    y = 20
    add = 10 + 20
    print(add)
    `;
   
    const code2 = `
    x = 10
    y = 20
    add = 10 + 20
    print(add)
    `;

    const code3 = `
    x = 10
    y = 20
    add = 10 + 20
    print(add)
    `;
    
    return (
   <body>
    <Helmet>
        
        <title>Python Installing</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="Python Installing, Python Compiler Step-by-step Python programming tutorials, Python coding exercises for beginners, Advanced Python coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       Python programming tips for real-world applications," />
      <meta name="description" content="" />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/Python_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_writing_code"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>Python Installing</h1>
</header>

<Navbar />

<main>
<section>
<p>Python code can be run on your personal computer but to do that you must first install python.</p>

<p>Here's a step-by-step guide on how to install Python.</p>
<h2>Step 1: Download Python</h2>
<ul>
    <li>Go to the official Python website: <a href="https://www.python.org/downloads/" target="_blank" rel="nofollow">https://www.python.org</a></li>
    <li>Click on the "Downloads" tab</li>
    <li>Select the appropriate version for your operating system (Windows, macOS, or Linux)</li>
    <li>Click on the download link to download the installer</li>
</ul>

<h2>Step 2: Run the Installer</h2>
<ul>
    <li>Once the download is complete, run the installer</li>
    <li>Follow the installation prompts to install Python</li>
    <li>Make sure to select the option to add Python to your PATH environment variable (this will allow you to run Python from the command line)</li>
</ul>

<h2>Step 3: Verify the Installation</h2>
<ul>
    <li>Open a terminal or command prompt</li>
    <li>Type python --version to verify that Python has been installed correctly</li>
</ul>

<h2>Step 4: Set up your Environment</h2>
<ul>
    <li>You can now use Python from the command line or install an Integrated Development Environment (IDE) like PyCharm, VSCode, or Spyder</li>
    <li>Create a new file with a .py extension and write your first Python program!</li>
</ul>

<h2>Troubleshooting</h2>
<p>If you encounter any issues during installation, check the official Python documentation or seek help from a online community.</p>
<p>Make sure to install the correct version of Python for your operating system (32-bit or 64-bit).</p>

<h2>Conclusion</h2>
<p>Congratulations! You have successfully installed Python on your computer.</p>
<p>You can now start exploring the world of Python programming and take advantage of its many features and libraries. Happy coding!</p>

</section>
</main>
<div className="head">
<ScrollToTopLink to="/Python_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_writing_code"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}