import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyArrays() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const arrayCreationCode = `
# Creating Arrays
empty_array = []
numbers = [1, 2, 3, 4, 5]
mixed_array = [1, "two", 3.0, true]

puts empty_array
puts numbers
puts mixed_array
# Output: 
# []
# [1, 2, 3, 4, 5]
# [1, "two", 3.0, true]
  `;

  const arrayAccessCode = `
# Accessing Elements
numbers = [1, 2, 3, 4, 5]

puts numbers[0]    # Output: 1
puts numbers[-1]   # Output: 5
puts numbers[2, 3] # Output: [3, 4, 5]
puts numbers[1..3] # Output: [2, 3, 4]
  `;

  const arrayModificationCode = `
# Modifying Arrays
numbers = [1, 2, 3, 4, 5]

# Adding elements
numbers << 6
numbers.push(7)
numbers.unshift(0)

# Removing elements
numbers.pop
numbers.shift

# Updating elements
numbers[1] = 10

puts numbers
# Output: [0, 10, 2, 3, 4, 5]
  `;

  const arrayIterationCode = `
# Iterating Over Arrays
numbers = [1, 2, 3, 4, 5]

numbers.each do |number|
  puts number
end

# Output:
# 1
# 2
# 3
# 4
# 5
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Arrays</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Arrays, Creating Arrays, Accessing Elements, Modifying Arrays, Iterating Arrays" />
        <meta name="description" content="Learn about arrays in Ruby, including how to create, access, modify, and iterate over arrays." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_procs-lambda"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_array_methods"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Arrays</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Arrays in Ruby are ordered, integer-indexed collections of objects. They can hold objects of any type, including other arrays.</p>

            <h2>Creating Arrays</h2>
            <p>Arrays can be created using the literal constructor <code>[]</code> or the <code>Array.new</code> method.</p>
            <SyntaxHighlighterComponent code={arrayCreationCode} language="ruby" />
            <p>In the above example, we create an empty array, an array of numbers, and a mixed array containing different types of objects.</p>

            <h2>Accessing Elements</h2>
            <p>Array elements can be accessed using their index. Ruby supports both positive and negative indices.</p>
            <SyntaxHighlighterComponent code={arrayAccessCode} language="ruby" />
            <p>In the above example, we access elements by index, including ranges and slices of the array.</p>

            <h2>Modifying Arrays</h2>
            <p>Arrays can be modified by adding, removing, and updating elements.</p>
            <SyntaxHighlighterComponent code={arrayModificationCode} language="ruby" />
            <p>In the above example, we demonstrate adding elements to the end and beginning of an array, removing elements from the end and beginning, and updating an element at a specific index.</p>

            <h2>Iterating Over Arrays</h2>
            <p>Ruby provides several methods to iterate over arrays. The most common method is <code>each</code>.</p>
            <SyntaxHighlighterComponent code={arrayIterationCode} language="ruby" />
            <p>In the above example, we use the <code>each</code> method to iterate over the elements of the array and print each element.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_procs-lambda"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_array_methods"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
