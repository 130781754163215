import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyMethods() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const methodDefinitionCode = `
# Method Definition
def greet(name)
  "Hello, #{name}!"
end

puts greet("Alice")
# Output: Hello, Alice!
  `;

  const defaultParametersCode = `
# Method with Default Parameters
def greet(name="Guest")
  "Hello, \#{name}!"
end

puts greet("Alice")
puts greet
# Output: Hello, Alice!
# Output: Hello, Guest!
  `;

  const variableNumberOfArgumentsCode = `
# Method with Variable Number of Arguments
def sum(*numbers)
  numbers.inject(0) { |sum, number| sum + number }
end

puts sum(1, 2, 3, 4)
# Output: 10
  `;

  const methodWithBlockCode = `
# Method with Block
def greet
  if block_given?
    yield
  else
    "Hello!"
  end
end

puts greet { "Hello, Block!" }
puts greet
# Output: Hello, Block!
# Output: Hello!
  `;

  const returnValuesCode = `
# Method with Return Values
def multiply(a, b)
  a * b
end

result = multiply(2, 3)
puts result
# Output: 6
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Methods</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Methods, Method Definition, Default Parameters, Variable Number of Arguments, Blocks, Return Values" />
        <meta name="description" content="Learn about methods in Ruby, including method definition, default parameters, variable number of arguments, blocks, and return values." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_loops"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_blocks"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Methods</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Methods in Ruby are used to encapsulate reusable code. You can define methods to perform specific tasks, which makes your code more modular and easier to maintain.</p>

            <h2>Method Definition</h2>
            <p>Methods are defined using the <code>def</code> keyword, followed by the method name and any parameters.</p>
            <SyntaxHighlighterComponent code={methodDefinitionCode} language="perl" />
            <p>In the above example, the <code>greet</code> method takes one parameter, <code>name</code>, and returns a greeting string.</p>

            <h2>Default Parameters</h2>
            <p>You can define methods with default parameters by assigning a default value to the parameters.</p>
            <SyntaxHighlighterComponent code={defaultParametersCode} language="perl" />
            <p>In the above example, the <code>greet</code> method has a default parameter <code>name</code> with a default value of "Guest".</p>

            <h2>Variable Number of Arguments</h2>
            <p>Ruby methods can accept a variable number of arguments by using the splat operator (<code>*</code>).</p>
            <SyntaxHighlighterComponent code={variableNumberOfArgumentsCode} language="ruby" />
            <p>In the above example, the <code>sum</code> method takes a variable number of arguments and returns their sum.</p>

            <h2>Method with Block</h2>
            <p>Ruby methods can accept blocks, which are chunks of code that can be executed within the method using the <code>yield</code> keyword.</p>
            <SyntaxHighlighterComponent code={methodWithBlockCode} language="ruby" />
            <p>In the above example, the <code>greet</code> method executes a block if one is given, otherwise it returns a default greeting.</p>

            <h2>Return Values</h2>
            <p>Methods can return values using the <code>return</code> keyword. If no explicit return is given, the last evaluated expression is returned.</p>
            <SyntaxHighlighterComponent code={returnValuesCode} language="ruby" />
            <p>In the above example, the <code>multiply</code> method returns the product of its two parameters.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_loops"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_blocks"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
