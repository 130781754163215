import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLlinks() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <a href="file.html">Click me</a>
  `;
  
     const code2 = `
     <a href="https://www.chase360.com.ng">External Link</a>
  `;
     const code3 = `
     <a href="#">Empty Link</a>
  `;
    const code4 = `
    <a href="file2.html"><img src="a monitor.jpg" /></a>
  `;
 
    return (
   <body>
 <Helmet>    
        <title>HTML Links</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Links, What is a link, what is a hyperlink, Structure of HTML Links, Internal Links, External Links, Empty Links, Image as a Link, Target Link Methods,
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="HTML links are used to navigate through the pages of a website.
       HTML links are very essential to every website.HTML links are known as hyperlinks." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
<br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">
<header className="headertutorials" style={newCode}>

<ScrollToTopLink to="/HTML_colors"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_horizontal_rule"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Links</h1>
</header>

<Navbar />

<main>

<section>
<p>HTML links are used to navigate through the pages of a website.</p>
<p>HTML links are very essential to every website.HTML links are known as hyperlinks.</p>
<p>To create links we use the anchor element &lt;a&gt; with the href attribute which species the URL of the page.</p>
<h2>Structure of HTML Links</h2>
<li>start tag:&lt;a&gt;</li>
<li>href Attribute.e.g,href="home.html"</li>
<li>Element Content(a text) e.g,Home Page</li>
<li>End tag:&lt;/a&gt;</li>
<h2>Internal Links</h2>
<p>HTML allows linking to other HTML documents within a website or folder</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html29"><Tryitout /></ScrollToTopLink>

<h2>External Links</h2>
<p>HTML also allows linking to external documents.External documents could be websies and browsers.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html30"><Tryitout /></ScrollToTopLink>

</section>

<h2>Empty Links</h2>
<p>As the name presumes an empty link is link that is not directed to any specific location.</p>
<p>The difference between a specified link and an empty link is the value assigned to the href attribute which is the pound sign #.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code3} language="html" />
<ScrollToTopLink to="/try_html31"><Tryitout /></ScrollToTopLink>



<section>
    <h2>Image as a Link</h2>
    <p>An image can also be used as a link</p>
    <br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code4} language="html" />
<ScrollToTopLink to="/try_html32"><Tryitout /></ScrollToTopLink>


<h2>Target Link Methods</h2>
<table>
<thead>
    <tr>
    <th>Target Value</th>
    <th>Description</th>
    </tr>
</thead>
<tbody>
    <tr>
        <td>_blank</td>
        <td>This value opens the linked document in a new window or tab.</td>
    </tr>
    <tr>
        <td>_self</td>
        <td>This value opens the linked document in the same frame as it was clicked.</td>
    </tr>
    <tr>
        <td>_parent</td>
        <td>This value opens the linked document in the parent frame</td>
    </tr>
    <tr>
        <td>_top</td>
        <td>This value opens the linked document in the full body of the window</td>
    </tr>
</tbody>
</table>
</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_colors"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_horizontal_rule"><ButtonNext /></ScrollToTopLink>
</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}