import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSOperators() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
    var x = 27;
    var y = 9;
    var add = x + y;
    alert(add);   
`;
const code2 = `
let a = 10;
let b = 5;

const add = a += b; // Equivalent to: a = a + b; => a = 15
alert(add);
`;

const code3 = `
let sunny = true;
let warm = false;

var isGoodDay = sunny && warm; // false (AND)
let isAnyConditionMet = sunny || warm; // true (OR)
const isNotSunny = !sunny; // false (NOT)

`;
const code4 = `
let isMorning = true;
let greeting = isMorning ? "Good morning!" : "Hello!";
// Result: greeting = "Good morning!"
`;
const code5 = `
    var str1 = 'hello';
    var str2 = 'Hello';      
    var compare = str1 == str2;         
    alert(compare);  //false
`;
const code6 = `
var age = 25;
typeof age; // "number"

`;

    return (
   <body>
        <Helmet>
    
    <title>JavaScript Operators</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="Arithmetic Operators, Assignment Operators, Logical Operators, Conditional Operators
   Comparison Operators, Typeof Operator" />
  <meta name="description" content="JavaScript operators are symbols or keywords that perform operations on values, allowing you to manipulate, compare, and combine data." />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_data_types"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_arithmetic_operators"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Operators</h1>
</header>

<Navbar />

<main>
    <section>
    <p>JavaScript operators are symbols or keywords that perform operations on values, allowing you to manipulate, compare, 
    and combine data.
</p>
<p>They are an essential part of writing code to perform various tasks.Here is a list of the basic types of 
    operators we have in JavaScript.
</p>
<li>Arithmetic Operators</li>
<li>Assignment Operators</li>
<li>Logical Operators</li>
<li>Conditional Operators</li>
<li>Comparison Operators</li>
<li>Typeof Operators</li>
<p>All the listed operators above will be explained in seperate lessons.</p>
<p>Here is a brief defintion of them</p>
 <h2>Arithmetic Operators</h2>
 <p>The arithmetic operator perfors simple mathematical operations</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
<ScrollToTopLink to="/try_js23"><Tryitout /></ScrollToTopLink>

    <h2>Assignment Operators</h2>
    <p>Assignment operators are used to assign values to variables.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js24"><Tryitout /></ScrollToTopLink>

    <h2>Logical Operators</h2>
 <p>Logical operators are used to combine or manipulate boolean values.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code3} language="javascript" />
<ScrollToTopLink to="/try_js25"><Tryitout /></ScrollToTopLink>
    <h2>Conditional Operators</h2>
    <p>The conditional operator also known as tenary operator provides a way to write conditional statements.</p>
       <br />
       <h2>Example:</h2>
       <SyntaxHighlighterComponent code={code4} language="javascript" />
<ScrollToTopLink to="/try_js26"><Tryitout /></ScrollToTopLink>

       <h2>Comparison Operators</h2>
       <p>Comparison operators are used to compare values and return a boolean result.</p>
          <br />
          <h2>Example:</h2>
          <SyntaxHighlighterComponent code={code5} language="javascript" />
<ScrollToTopLink to="/try_js27"><Tryitout /></ScrollToTopLink>

          <h2>Typeof Operator</h2>
          <p>The typeof operator returns the data type of a value.</p>
             <br />
             <h2>Example:</h2>
             <SyntaxHighlighterComponent code={code6} language="javascript" />
<ScrollToTopLink to="/try_js28"><Tryitout /></ScrollToTopLink>  
</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_data_types"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_arithmetic_operators"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}