import React from 'react';
import '../css/my style.css';
import '../css/my style2.css';
import '../css/my style6.css';


 function Header() {

const headerContact = {
    padding: '140px',
    textAlign: 'center',
    background: 'darkblue'
}
 const headerContacth1 = {
    fontSize: '50px',
    color: 'white',
   
 }
    return (
    <header style={headerContact}>
           <div className="header-content">
     <div className="centered-content">
   <h1 className="topple" style={headerContacth1}>
        <span>c</span>
        <span>o</span>
        <span>n</span>
        <span>t</span>
        <span>a</span>
        <span>c</span>
        <span>t</span>
       <br />
        <span>U</span>
        <span>s</span>
  
  </h1>
  </div>
  </div>
      </header>
    );
}
export default Header;