import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonDateTime() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const dateCreationCode = `
# Creating Date and Time Objects

from datetime import datetime

# Current date and time
now = datetime.now()
print("Current date and time:", now)

# Specific date and time
specific_date = datetime(2024, 6, 6, 12, 30, 45)
print("Specific date and time:", specific_date)
  `;

  const dateOperationsCode = `
# Date Operations

from datetime import datetime, timedelta

# Adding days to a date
today = datetime.now()
next_week = today + timedelta(days=7)
print("Next week:", next_week)

# Difference between two dates
birthday = datetime(2000, 5, 15)
age = today - birthday
print("Age:", age.days, "days")
  `;

  const dateFormattingCode = `
# Date Formatting

from datetime import datetime

# Formatting dates
now = datetime.now()
formatted_date = now.strftime("%Y-%m-%d %H:%M:%S")
print("Formatted date:", formatted_date)
  `;

  const getCurrentDateTimeCode = `
# Getting Current Date and Time

from datetime import datetime

# Get current date and time
current_date_time = datetime.now()
print("Current date and time:", current_date_time)
  `;

  const getLocalDateTimeCode = `
# Getting Local Date and Time

from datetime import datetime
import pytz

# Set the local timezone
local_timezone = pytz.timezone('Nigeria/Anambra')

# Get current local date and time
local_date_time = datetime.now(local_timezone)
print("Local date and time (Anambra):", local_date_time)
  `;

  return (
    <body>
      <Helmet>
        <title>Python Date and Time</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Python Date and Time, Date Creation, Date Operations, Date Formatting" />
        <meta name="description" content="Learn about handling dates and times in Python using the datetime module." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Python_strings"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Python Date and Time</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>In Python, the `datetime` module provides classes for manipulating dates and times.</p>
            <p>It allows you to work with dates, times, and time intervals in a simple and consistent manner.</p>
            <p>Let's explore the various aspects of date and time handling in Python.</p>
            <h2>Creating Date and Time Objects</h2>
            <p>Create date and time objects using the `datetime` module.</p>
            <SyntaxHighlighterComponent code={dateCreationCode} language="python" />

            <h2>Date Operations</h2>
            <p>Perform operations like addition and subtraction on dates.</p>
            <SyntaxHighlighterComponent code={dateOperationsCode} language="python" />

            <h2>Date Formatting</h2>
            <p>Format dates using the `strftime()` method.</p>
            <SyntaxHighlighterComponent code={dateFormattingCode} language="python" />

            <h2>Getting Current Date and Time</h2>
            <p>In python you can get the current date and time using the 'datetime.now()' method.</p>
            <SyntaxHighlighterComponent code={getCurrentDateTimeCode} language="python" />

            <h2>Getting Local Date and Time</h2>
            <p>You can get the local date and time of your region by using 'pytz'.</p>
            <p>The 'pytz.timezone()' method is then used with a local timezone variable placed in it to obtain the local date and time.</p>
            <SyntaxHighlighterComponent code={getLocalDateTimeCode} language="python" />
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_strings"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
