import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PythonMetatag from "../../components/Python_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PythonSyntax() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
if 10 < 15:&nbsp;&nbsp; &nbsp;     #Proper indentation
    z = "Python is fun" #of the python
    print(z)            #code
#Python is fun will printed
#because 10 is less than 15
#but if you change it to 
#15 &lt; 10 there will be no
#output
    `;
   
    const code2 = `
    if 10 < 15:#Indentation error
    z = "Python is fun"
      print(z) 
    `;
    const code3 = `
if 10 < 15
    z = "Beta Thread is awesome"
    print(z)    
print("This is the end of this block of statements") #I am the next unindented line
    `;
   
    return (
   <body>
      <Helmet>
        <title>Python Syntax</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="Python Syntax, Step-by-step Python programming tutorials, Python coding exercises for beginners, Advanced Python coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       Python programming tips for real-world applications," />
      <meta name="description" content="Learn how Python Syntax streamline code organization, 
      enhance readability, and unlock advanced features in Python development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/Python_display_output"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_comments"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>Python Syntax</h1>
</header>

<Navbar />

<main>
<section>
<p>A syntax is a set of rules you follow when programming.Virtually every language has its own syntax.
</p>
<p>When coding in Python we follow a certain syntax.</p>
<h2>Python Indentation</h2>
<p>Identation in simple terms is the way code is aligned line by line, indentation is a very important rule
    to take into consideration.
</p>
<p>In some languages indentation is only used for better readability their compilers do not produce errors
    when their code is not properly indented.
</p>
<p>Whitespaces or tabs are used to compute the indentation level of a line.
</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code} language="python" />
<h2>Indentation Error</h2>
<p>When your Python code is not properly indented it will produce an indentation error.</p>
<p>The example below will produce an error because the third line is not properly indented.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="python" />
<h2>Ending a Block of Statements</h2>
<p>A group or block of statements ends with the next unindented line.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code3} language="python" />
</section>
</main>
<div className="head">
<ScrollToTopLink to="/Python_display_output"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_comments"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}