import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLinput() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <input type="date" />
  `;
  const code2 = `
  <input type="file" id="demo" accept=".jpg" multiple />
 
`;
const code3 = `
<input type="month" id="demo" />
<button onclick="obtainValue()">Obtain Value</button>
<script type="text/javascript">
  function obtainValue() {
    var x = document.getElementById("demo").value;
    alert(x);
  }
</script>
`;
const code4 = `
<input type="text" placeholder="Enter your firstname" id="demo1" />
<button onclick="obtainValue()">Obtain Value</button>
<script type="text/javascript">
  function obtainValue() {
    var y = document.getElementById("demo").value;
    alert(y);
  }
</script>
`;

const code5 = `
<input type="email" id="demo" placeholder="Enter your email" />
<button onclick="obtainValue()">Obtain Value</button>
<script type="text/javascript">
  function obtainValue() {
let val = document.getElementById("demo").value;
let output = document.getElementById("demo").innerHTML = "Your email is" + val;
  alert(output);
 }
</script>
`;
const code6 = `
<input type="number" placeholder="Enter your age" />
`;

const code7 = `
<input type="time" />
`;

const code8 = `
<label for="HTML">HTML
<input type="checkbox" name="html" value="HTML" /><br />
</label><br />
<label for="CSS">CSS
<input type="checkbox" name="css" value="CSS" /><br />
</label><br />
<label for="JavaScript">JavaScript
<input type="checkbox" name="javascript" value="JavaScript" /><br />
</label><br />
<input type="submit" />
`;

    return (
   <body>
    <Helmet>    
        <title>HTML5 Input Types</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML5 Input Types, What is an input type, Date, File, Month, Text, Email, Number, Time, Checkbox
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="In one of the previous lessons we mentioned some form element attributes and explained them,if you go back to the examples you will see that we used the <input /> tag together with the type attribute to create the text fields where users can input their data." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_js"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_input2"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML5 Input Types</h1>
</header>

<Navbar />

<main>

<section>
<p>In one of the previous lessons we mentioned some form element attributes and explained them,if you go back to 
    the examples you will see that we used the &lt;input /&gt; tag together with the type attribute to create
    the text fields where users can input their data.
  </p>
  <p>We will dicuss on HTML5 input types.</p>
  <p>In this lesson we will explain some of the input types we have in HTML and give proper examples
    for you to better understand.
  </p>
  <p>The input element represents a typed data field, usually with a 
    form control to allow the user to edit the data.</p>
  <p>The input types include:</p>
  <h3>Date</h3>
  <p>This introduces an interface/control for entering a date i.e,year, month and day.Minutes and hours are not included.</p>

  <br />
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html79"><Tryitout /></ScrollToTopLink>

<h3>File</h3>
    <p>This control is used to select a file. Files such as images, music and videos.</p>
    <p>This input type can be followed by an attribute-accept attribute.The accept attribute can be used in
        instances whereby by we want a particular file format to be selected for example .jpg, .jpeg, .mp3, .mp4
    </p>
<br />
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html80"><Tryitout /></ScrollToTopLink>
</section>

<section>

<h3>Month</h3>
    <p>This control enables you to select a month and year of your choice.</p>
    <p>In this example we will make use of javascript to get the value of the month that you select.</p>
  <br />
  <SyntaxHighlighterComponent code={code3} language="html" />
  <ScrollToTopLink to="/try_html81"><Tryitout /></ScrollToTopLink>

  <h3>Text</h3>
    <p>This control displays a textfield where data such as firstname, lastname, username can be inputed.</p>
    <p>In this example we will make use of javascript to get the value of the text that you input.</p>
      <br />
      <SyntaxHighlighterComponent code={code4} language="html" />
<ScrollToTopLink to="/try_html82"><Tryitout /></ScrollToTopLink>

<h3>Email</h3>
    <p>This control displays a text field where users can place their email.</p>
<br />
    <SyntaxHighlighterComponent code={code5} language="html" />
<ScrollToTopLink to="/try_html83"><Tryitout /></ScrollToTopLink>

<h3>Number</h3>
<p>This control displays a text field for inputing a number.This control can be used when you want to
get a users age.
</p>
<br />
    <SyntaxHighlighterComponent code={code6} language="html" />
<ScrollToTopLink to="/try_html84"><Tryitout /></ScrollToTopLink>

<h3>Time</h3>
<p>This control enables you to select time based on the current time on your device.</p>
<br />
    <SyntaxHighlighterComponent code={code7} language="html" />
<ScrollToTopLink to="/try_html85"><Tryitout /></ScrollToTopLink>

<h3>Checkbox</h3>
<p>A checkbox allows a user to select multiple choices out of a range of options.</p>
<br />
    <SyntaxHighlighterComponent code={code8} language="html" />
<ScrollToTopLink to="/try_html86"><Tryitout /></ScrollToTopLink>


</section>
<div className="head">
<ScrollToTopLink to="/HTML_js"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_input2"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}