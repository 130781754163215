import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLDeserialization() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
import java.io.StringReader;
import javax.xml.bind.JAXBContext;
import javax.xml.bind.JAXBException;
import javax.xml.bind.Unmarshaller;

public class DeserializeExample {
    public static void main(String[] args) throws JAXBException {
        String xml = "<book><title>Learning XML</title><author>John Doe</author></book>";
        JAXBContext context = JAXBContext.newInstance(Book.class);
        Unmarshaller unmarshaller = context.createUnmarshaller();
        Book book = (Book) unmarshaller.unmarshal(new StringReader(xml));
        System.out.println(book);
    }
}
`;

  const example2 = `
import java.beans.XMLDecoder;
import java.io.ByteArrayInputStream;

public class DeserializePerson {
    public static void main(String[] args) {
        String xml = "<java><object class='Person'><void property='name'><string>Jane Doe</string></void><void property='age'><int>30</int></void></object></java>";
        ByteArrayInputStream bais = new ByteArrayInputStream(xml.getBytes());
        XMLDecoder decoder = new XMLDecoder(bais);
        Person person = (Person) decoder.readObject();
        decoder.close();
        System.out.println(person);
    }
}
`;

  const example3 = `
import com.fasterxml.jackson.dataformat.xml.XmlMapper;

public class DeserializeEmployee {
    public static void main(String[] args) throws Exception {
        String xml = "<Employee><id>12345</id><department>Sales</department></Employee>";
        XmlMapper xmlMapper = new XmlMapper();
        Employee employee = xmlMapper.readValue(xml, Employee.class);
        System.out.println(employee);
    }
}
`;

  return (
    <body>
      <Helmet>
        <title>XML Deserialization</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML Deserialization, XML Tutorial, Java XML Deserialization, XML Examples" />
        <meta name="description" content="Learn about XML Deserialization with examples and explanations of how to deserialize XML data to objects." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_serialization"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_ajax"><ButtonNext /></ScrollToTopLink>
          <h1>XML Deserialization</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XML Deserialization is the process of converting XML data back into an object's state. This technique is crucial for retrieving and working with data that has been stored or transmitted in XML format.</p>

            <h2>Introduction to XML Deserialization</h2>
            <p>Deserialization allows the reconstruction of objects from XML data. This is useful for applications that need to read and manipulate XML data in a structured and object-oriented manner.</p>

            <h2>Example 1: JAXB Deserialization in Java</h2>
            <SyntaxHighlighterComponent code={example1} language="java" />
            <p>In this example, we use JAXB to deserialize an XML string into a <code>Book</code> object. JAXB simplifies the process of converting XML data into Java objects.</p>

            <h2>Example 2: XMLDecoder Deserialization in Java</h2>
            <SyntaxHighlighterComponent code={example2} language="java" />
            <p>This example demonstrates how to deserialize a <code>Person</code> object using Java's <code>XMLDecoder</code>. This method is straightforward and built into the Java standard library.</p>

            <h2>Example 3: Jackson XML Deserialization in Java</h2>
            <SyntaxHighlighterComponent code={example3} language="java" />
            <p>In this example, we use the Jackson library's <code>XmlMapper</code> to deserialize an XML string into an <code>Employee</code> object. Jackson is a versatile library that supports both JSON and XML deserialization.</p>

            <h2>Benefits of XML Deserialization</h2>
            <ul>
              <li>Allows data to be easily read and manipulated in an object-oriented manner.</li>
              <li>Facilitates data exchange between different systems and platforms.</li>
              <li>Ensures data integrity and consistency during transmission and storage.</li>
            </ul>

            <h2>Common Use Cases</h2>
            <ul>
              <li>Reading configuration data from XML files.</li>
              <li>Consuming XML data from web services.</li>
              <li>Restoring object states from XML data.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_serialization"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_ajax"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
