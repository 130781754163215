import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/navbar';
import { Helmet } from 'react-helmet';
import Topnav from '../components/topnav';

const NeedWebsitePage = () => {
    const containerStyles = {
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        paddingTop: '70px',
    };

    const headerStyles = {
        position: 'relative',
        marginBottom: '0',
    };

    const imgStyles = {
        width: '100%',
        height: '450px',
    };

    const sectionStyles = {
        padding: '50px 0',
    };

    const h1Styles = {
        fontSize: '36px',
        fontWeight: 'bold',
        marginBottom: '20px',
    };

    const pStyles = {
        fontSize: '18px',
        marginBottom: '30px',
    };

    const btnContainerStyles = {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    };

    const btnStyles = {
        backgroundColor: 'darkblue',
        color: 'white',
        padding: '12px 24px',
        borderRadius: '5px',
        textDecoration: 'none',
        transition: 'background-color 0.3s ease',
        display: 'inline-block',
        width: '45%',
        textAlign: 'center',
        margin: '16px'
    };

    const btnHoverStyles = {
        ...btnStyles,
        backgroundColor: 'blue',
    };

    return (
        <div className="main-theme">
         <Helmet>
        <title>Need A Website</title>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-compatible" content="IE-edge" />
        <meta name="Keywords" content="Need a Website, Website, HTML, Python, CSS, SQL, JavaScript, How to, PHP, XML, MySQL, React, AI, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, Examples, Learn to code, Coding the future, Source code, Website" />
        <meta name="description" content="Are you ready to take your business online? Let us help you create a stunning website that showcases your brand and drives results. Our team of experienced developers and designers will work with you to bring your vision to life." />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      </Helmet>
     
        <div style={containerStyles}><br />
        <Topnav />
            <header style={headerStyles}>
                <div>
                    <img src={require('../image/web.svg').default} style={imgStyles} alt='code' />
                </div>
                <Navbar />
                
            </header>

            <section style={sectionStyles}>
                <h1 style={h1Styles}>Need a Website?</h1>
                <p style={pStyles}>
                    Are you ready to take your business online? Let us help you create a stunning website that showcases your brand and drives results. Our team of experienced developers and designers will work with you to bring your vision to life.
                </p>
                <p style={pStyles}>
                    From custom designs to seamless functionality, we've got you covered. With our expertise, we'll build a website that not only looks great but also helps you achieve your business goals.
                </p>
                <p style={pStyles}>
                    Ready to get started? Sign up now to schedule a consultation and let's build something amazing together! If you have any questions, feel free to reach out to us. We're here to help!
                </p>
                <p style={pStyles}>
                    Building a website is an investment in your business's future. With our team of experts, you can trust that your project is in good hands. Let us handle the technical details so you can focus on what you do best – running your business.
                </p>
                <p style={pStyles}>
                    Don't wait any longer to establish your online presence. Contact us today and visit the portfolio to learn more about how we can help you grow your business with a custom website. Your success is our priority.
                </p>
                <div style={btnContainerStyles}>
                    <a href="/contact" style={btnStyles}>Contact Us</a>
                    <a href="https://dumebioruchex.github.io" title="Visit Portfolio" target="_blank" rel="noopener noreferrer" style={btnStyles}>Portfolio</a>
                </div>
            </section>

            <Footer />
        </div>
        </div>
    );
};

export default NeedWebsitePage;
