import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLCreatingViews() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const createViewCode = `
-- Create a view to show employee names and department names
CREATE VIEW EmployeeDepartment AS
SELECT employees.employee_name, departments.department_name
FROM employees
INNER JOIN departments ON employees.department_id = departments.department_id;
  `;

  const selectFromViewCode = `
-- Select from the created view
SELECT * FROM EmployeeDepartment;
  `;

  const employeesTable = `
CREATE TABLE employees (
    employee_id INT PRIMARY KEY,
    employee_name VARCHAR(100),
    department_id INT
);

INSERT INTO employees (employee_id, employee_name, department_id) VALUES
(1, 'John Doe', 1),
(2, 'Jane Smith', 2),
(3, 'Emily Davis', 1),
(4, 'Michael Brown', 3);
  `;

  const departmentsTable = `
CREATE TABLE departments (
    department_id INT PRIMARY KEY,
    department_name VARCHAR(100)
);

INSERT INTO departments (department_id, department_name) VALUES
(1, 'Human Resources'),
(2, 'Finance'),
(3, 'IT');
  `;

  return (
    <body>
      <Helmet>
        <title>SQL Creating Views</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL Creating Views, SQL Tutorial, Creating Views, Using Views" />
        <meta name="description" content="Learn how to create SQL Views, including examples and syntax for creating and using views in your database." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_views"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_managing_views"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL Creating Views</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Creating a view in SQL allows you to simplify complex queries and enhance data security by abstracting the underlying table structures. Here's how you can create and use views.</p>

            <h2>Example Tables</h2>
            <p>Consider the following tables:</p>
            <SyntaxHighlighterComponent code={employeesTable} language="sql" />
            <SyntaxHighlighterComponent code={departmentsTable} language="sql" />

            <h2>Creating a View</h2>
            <p>You can create a view to combine and present data from multiple tables. Here's an example:</p>
            <SyntaxHighlighterComponent code={createViewCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The view <code>EmployeeDepartment</code> combines data from the <code>employees</code> and <code>departments</code> tables.</li>
              <li>The <code>INNER JOIN</code> is used to retrieve matching records from both tables based on <code>department_id</code>.</li>
            </ul>

            <h2>Using a View</h2>
            <p>Once a view is created, you can query it as if it were a table:</p>
            <SyntaxHighlighterComponent code={selectFromViewCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The query selects all columns from the <code>EmployeeDepartment</code> view.</li>
              <li>This simplifies the process of retrieving combined data from the <code>employees</code> and <code>departments</code> tables.</li>
            </ul>

            <h2>Result of the View</h2>
            <p>If we execute the above query on the sample tables, we would get the following result:</p>
            <table>
              <thead>
                <tr>
                  <th>employee_name</th>
                  <th>department_name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>John Doe</td>
                  <td>Human Resources</td>
                </tr>
                <tr>
                  <td>Jane Smith</td>
                  <td>Finance</td>
                </tr>
                <tr>
                  <td>Emily Davis</td>
                  <td>Human Resources</td>
                </tr>
                <tr>
                  <td>Michael Brown</td>
                  <td>IT</td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_views"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_managing_views"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
