import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonLists() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeCreateList = `
# Creating a list
fruits = ["apple", "banana", "cherry"]
print(fruits)  # Output: ["apple", "banana", "cherry"]
    `;

    const codeAccessList = `
# Accessing list elements
print(fruits[0])  # Output: "apple"
print(fruits[1])  # Output: "banana"
    `;

    const codeModifyList = `
# Modifying list elements
fruits[1] = "blueberry"
print(fruits)  # Output: ["apple", "blueberry", "cherry"]
    `;

    const codeAppendList = `
# Appending elements to a list
fruits.append("orange")
print(fruits)  # Output: ["apple", "blueberry", "cherry", "orange"]
    `;

    const codeInsertList = `
# Inserting elements into a list
fruits.insert(1, "grape")
print(fruits)  # Output: ["apple", "grape", "blueberry", "cherry", "orange"]
    `;

    const codeRemoveList = `
# Removing elements from a list
fruits.remove("blueberry")
print(fruits)  # Output: ["apple", "grape", "cherry", "orange"]
    `;

    const codePopList = `
# Popping elements from a list
fruits.pop(1)
print(fruits)  # Output: ["apple", "cherry", "orange"]
    `;

    const codeSortList = `
# Sorting a list
fruits.sort()
print(fruits)  # Output: ["apple", "cherry", "orange"]
    `;

    return (
        <body>
            <Helmet>
                <title>Python Lists</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Lists, Create List, Access List Elements, Modify List, Append List, Insert List, Remove List, Pop List, Sort List" />
                <meta name="description" content="Learn about lists in Python, including how to create, access, modify, append, insert, remove, pop, and sort elements in a list." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_arrays"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_list_methods"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Introduction to Lists</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Lists in Python are used to store multiple items in a single variable. This tutorial covers creating lists, accessing elements, modifying lists, and various list methods.</p>
                        
                        <h2>Creating a List</h2>
                        <p>Create a list by placing elements inside square brackets, separated by commas.</p>
                        <SyntaxHighlighterComponent code={codeCreateList} language="python" />
                        
                        <h2>Accessing List Elements</h2>
                        <p>Access elements in a list using their index.</p>
                        <SyntaxHighlighterComponent code={codeAccessList} language="python" />
                        
                        <h2>Modifying List Elements</h2>
                        <p>Modify elements in a list by accessing their index and assigning a new value.</p>
                        <SyntaxHighlighterComponent code={codeModifyList} language="python" />
                        
                        <h2>Appending Elements to a List</h2>
                        <p>Use the <code>append()</code> method to add elements to the end of a list.</p>
                        <SyntaxHighlighterComponent code={codeAppendList} language="python" />
                        
                        <h2>Inserting Elements into a List</h2>
                        <p>Use the <code>insert()</code> method to add elements at a specific position.</p>
                        <SyntaxHighlighterComponent code={codeInsertList} language="python" />
                        
                        <h2>Removing Elements from a List</h2>
                        <p>Use the <code>remove()</code> method to delete specific elements.</p>
                        <SyntaxHighlighterComponent code={codeRemoveList} language="python" />
                        
                        <h2>Popping Elements from a List</h2>
                        <p>Use the <code>pop()</code> method to remove elements by index.</p>
                        <SyntaxHighlighterComponent code={codePopList} language="python" />
                        
                        <h2>Sorting a List</h2>
                        <p>Use the <code>sort()</code> method to sort elements in a list.</p>
                        <SyntaxHighlighterComponent code={codeSortList} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Lists are a versatile and essential data structure in Python. This tutorial covered creating lists, accessing elements, modifying elements, appending elements, inserting elements, removing elements, popping elements, and sorting elements in a list.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_arrays"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_list_methods"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
