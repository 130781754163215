import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

const XMLDataInsertion = () => {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<students>
  <student>
    <name>John Doe</name>
    <age>20</age>
  </student>
</students>
`;

  const example2 = `
<inventory>
  <item>
    <name>Widget</name>
    <quantity>100</quantity>
    <price>25.00</price>
  </item>
  <item>
    <name>Gadget</name>
    <quantity>200</quantity>
    <price>15.00</price>
  </item>
</inventory>
`;

  const example3 = `
<menu>
  <food>
    <name>Burger</name>
    <price>5.00</price>
  </food>
  <food>
    <name>Fries</name>
    <price>2.00</price>
  </food>
</menu>
`;

  return (
    <body>
      <Helmet>
        <title>XML Data Insertion</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML Data Insertion, XML Tutorial, XML Data Handling" />
        <meta name="description" content="Learn how to insert data into XML documents with examples and explanations of various techniques." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_data_manipulation"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_data_modification"><ButtonNext /></ScrollToTopLink>
          <h1>XML Data Insertion</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XML data insertion involves adding new elements and attributes to an existing XML document. This can be done programmatically or manually.</p>

            <h2>Example 1: Basic Student Data</h2>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This example shows a simple XML structure with student data, including name and age.</p>

            <h2>Example 2: Inventory List</h2>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>This example shows an inventory list with items, quantities, and prices.</p>

            <h2>Example 3: Menu Items</h2>
            <SyntaxHighlighterComponent code={example3} language="xml" />
            <p>This example shows a menu with food items and their prices.</p>

            <h2>Inserting Data Programmatically</h2>
            <p>To insert data into an XML document programmatically, you can use libraries and tools available in various programming languages. For example:</p>
            <ul>
              <li>Using the DOM API in JavaScript to create and append new elements</li>
              <li>Using Python's ElementTree module to add new elements to an XML tree</li>
              <li>Using Java's JDOM or DOM4J libraries for XML manipulation</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_data_manipulation"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_data_modification"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}

export default XMLDataInsertion;
