import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSwritingcode() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
    <script>
    alert("Welcome to my page!"");
    </script>
`;
const code2 = `
           // script.js
            function showMessage() {
                alert("Hello, external JavaScript!");
            }
`;
const code3 = `
<button onclick="showMessage()">Click me</button>

<script src="script.js"></script>
`;
const code4 = `
<button onclick="showMessage()">Click me</button>  
 <script>
    function showMessage() {
      alert("Hello, internal JavaScript!");
      }
</script>
`;
    return (
   <body>
          <Helmet>
    <title>JavaScript Writing Code</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="JavaScript Writing Code, Displaying a Welcome Message, External JavaScript, Create an External JavaScript File,
   Link the External JavaScript File in HTML, Internal JavaScript, " />
  <meta name="description" content="Writing JavaScript code involves creating instructions that a computer can understand and execute.
   In JavaScript, there are two primary ways to include and use JavaScript code within an HTML document: external JavaScript and internal JavaScript." />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JavaScript_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_syntax"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Writing Code</h1>
</header>

<Navbar />

<main>
    <section>
    <p>Writing JavaScript code involves creating instructions that a computer can understand and execute.</p>

<p> JavaScript is a scripting language, which means you write human-readable code that the browser or 
    interpreter can interpret and execute without the need for compilation.</p>

    <h2>Displaying a Welcome Message</h2>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
<ScrollToTopLink to="/try_js1"><Tryitout /></ScrollToTopLink>
 
    <br />
<p>
    In JavaScript, there are two primary ways to include and use JavaScript code within an HTML document: 
    external JavaScript and internal JavaScript.
</p>
<p>Let's explore both concepts and provide examples for each.</p>
<h2>External JavaScript</h2>
<p>External JavaScript involves creating a separate .js file and linking it to your HTML document.</p>
<p>This approach keeps your HTML code clean and separates the JavaScript logic from the HTML structure.
    Follow the steps below to create an external javascript file and place your code.
</p>
<h2>Step 1: Create an External JavaScript File</h2>
<p>Create a new file named <code>script.js</code> (you can name it anything you want, but <code>.js</code> is the standard extension
     for JavaScript files). Place your JavaScript code in this file.</p>
     <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
 
    <h2>Step 2: Link the External JavaScript File in HTML</h2>
    <p>In your HTML file, include a <code>&lt;script&gt;</code> tag to link the external JavaScript file. Place this <code>&lt;script&gt;</code> tag just
     before the closing <code>&lt;/body&gt;</code> tag to ensure that the JavaScript code is loaded after the HTML content.</p>
     <br />
     <h2>Example:</h2>
     <SyntaxHighlighterComponent code={code3} language="javascript" />
<ScrollToTopLink to="/try_js2"><Tryitout /></ScrollToTopLink>
 
   <h2>Internal JavaScript</h2>
   <p>Internal JavaScript involves embedding the JavaScript code directly within the HTML document.</p>
   <p>This approach is suitable for small scripts or cases where you want to apply JavaScript to a 
    specific section of the page.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code4} language="javascript" />
<ScrollToTopLink to="/try_js3"><Tryitout /></ScrollToTopLink>
 
    <p>In this example, the JavaScript code is placed within the <code>&lt;script&gt;</code> tag inside the <code>&lt;head&gt;</code> section.</p>
    <p> You can also place the <code>&lt;script&gt;</code> tag just before the closing <code>&lt;/body&gt;</code> tag, similar to external JavaScript.</p>
   <h3>NOTE:</h3>
   <p>It's a good practice to place your JavaScript code just before the closing <code>&lt;/body&gt;</code> tag or use the defer attribute
    on the <code>&lt;script&gt;</code> tag to ensure that the JavaScript code is executed after the HTML content is loaded.</p>
<p>In both cases, when the user clicks the "Click me" button, the <code>showMessage()</code> function will be called, and an
     alert will be displayed with the specified message.</p>
    
</section>
</main>
<div className="head">
<ScrollToTopLink to="/JavaScript_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_syntax"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}