import React from "react";
import GoBack from "../../../components/Gobackbutton";
import { Link } from "react-router-dom";
import "../../../css/my style2.css";



export default function File() {
    return (
        <>
        <p style={{color: "black"}}>I am a file in the same folder.</p>
        <br /><br /><br />
        <Link to="/try_html42"><GoBack /></Link>
        </>
    )
}