import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../css/my style.css';
import '../css/my style2.css';
import '../css/my style6.css';
import SearchBar from './Searchbar/SearchBar';

class Header extends Component {
  render() {
    return (
     <body>
     
      <header className='header-home'>

      <div className="header-contents">
      
        <div className="centered-contents">
    
        <h1 className='topple' style={{textAlign: "center", fontFamily: "sans-serif", fontSize: "8px"}}>
          <span>C</span>
          <span>o</span>
          <span>d</span>
          <span>i</span>
          <span>n</span>
          <span>g</span>
          <span>T</span>
          <span>h</span>
          <span>e</span>
          <span>F</span>
          <span>u</span>
          <span>t</span>
          <span>u</span>
          <span>r</span>
          <span>e</span>
        </h1>
        <br />
          <SearchBar />
        </div>
      </div>
      <div className="dropdown-input"></div>
    </header>
   </body>
  
    );
  }
}

export default Header;
