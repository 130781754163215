import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLattributespt2() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <!-- I am an attribute -->
      <a href="#">click me</a>
    `;
  
      const code2 = `
     <img src="a monitor.jpg" />
    `;
      const code3 = `
      <!-- image with px -->
      <img src="a monitor.jpg" width="100px" height="150px" />
      <!-- image without px -->
      <img src="a monitor.jpg" width="100" height="150" />
     `;
      const code4 = `
      <h1 style="color:blue;">I love HTML.</h1>
      <h1 style="font-size:50px;">I love HTML.</h1>
      <h1 style="text-decoration:underline;">I love HTML.</h1>
    `;
    
    const code5 = `
    <img src="a monitor.jpg" alt="a blue monitor" />
  `;
    const code6 = `
  <html lang="en">
`;
    const code7 = `
    <div class="text">
    <!--content goes here-->
    </div>
    <div class="text">
    <!--content goes here-->
    </div>
    <div id="text">
    <!--content goes here-->
    </div>
    `
    return (

   <body>
     <Helmet>    
        <title>HTML Attrubutes pt.2</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Attribute pt.2, href Attribute, src Attribute, Width & Height Attribute, style Attribute, alt Attribute, lang Attribute, id and class Attribute,
         Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
      <meta name="description" content="HTML attributes only appear at the start tag.
       HTML attributes are comprised of name/value pairs." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">
<header className="headertutorials" style={newCode}>
   
<ScrollToTopLink to="/HTML_attributes"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_heading"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Attributes pt.2</h1>
</header>

<Navbar />

<main>
<section>
<p>In our previous lesson we have learnt about HTML attributes now we will learn about their purpose and how they can be use.</p>
<h2>href Attribute</h2>
<p>The href attribute is used to insert the address link of any web page to a text.</p>
<p>To use the href attribute we use the anchor tag &lt;a&gt;.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html7"><Tryitout /></ScrollToTopLink>
</section>

<section>
    <h2>Example Explained:</h2>
    <p>The example above consists of the following components:</p>
    <li>An elementname:&lt;a&gt;&lt;/a&gt; known as the anchor tag.</li>
    <li>An attributename:href</li>
    <li>The value assigned to the attributename:"#"</li>
    <li>The text after the opening anchor tag:click me</li>
    <p>As you can see the &lt;a&gt; tag has an attribute attached to it and this attribute carries a value which is #.</p>
    <p>The # sign in HTML is called "pound sign".</p>
</section>


<section>
    <h2>src Attribute</h2>
    <p>The src attribute in HTML is used to insert an image into a web page.</p>
    <p>Inorder to insert an image we use the image tag &lt;img&gt; but to insert an image with a link we need to use the source attribute.</p>
    <br />
    
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html8"><Tryitout /></ScrollToTopLink>
</section>


<section>
    <h2>Width & Height Attribute</h2>
    <p>The width & height attributes are used inside the image tag &lt;img&gt; along with the src attribute to define the width and height of an image.</p>
  <p>It is not compulsory to place px(pixels) at the end of the calue assigned to either the height or width attribute.
You might decide to write 250 instead of 250px, they both represent the unit pixels.
  </p>
    <br />
    
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code3} language="html" />
<ScrollToTopLink to="/try_html9"><Tryitout /></ScrollToTopLink>
    <p>From the example above we can see that the width & height of the image have been increased to 100px and 150px respectively.</p>

</section>


<section>
    <h2>style Attribute</h2>
    <p>The style attribute enables us to add some level of styling to HTML elements.</p>
    <p>This is known as inline styling, you will learn more about in our <Link to="/CSS_tutorial_intro">CSS tutorial.</Link></p>
    <br />
    
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code4} language="html" />
<ScrollToTopLink to="/try_html10"><Tryitout /></ScrollToTopLink>

    <h2>Explanation:</h2>
    <p>The above example inludes three &lt;h1&gt; elements which are style using the style attribute.</p>
    <p>The first &lt;h1&gt; element has a color of blue.</p>
    <p>The second &lt;h1&gt; element has a font-size of 50px.</p>
    <p>The third &lt;h1&gt; element has a text-decoration of underline.</p>
</section>


<section>
    <h2>alt Attribute</h2>
    <p>The alternate attribute is used inside the image tag to store description about an image.</p>
    <p>If your image fails to load then the text inside the alt attribute will be shown.</p>
    <br />
    
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code5} language="html" />
<ScrollToTopLink to="/try_html11"><Tryitout /></ScrollToTopLink>

</section>


<section>
<h2>lang Attribute</h2>
<p>The lang attribute is used to specify the language of an HTML document.</p>
<br />
    
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code6} language="html" />

<p>The value assigned to the lang attribute "en" tells the browser that the HTML documents language is general english.</p>
</section>

<section>
    <h2>id and class Attribute</h2>
    <p>The id and class attributes give reference to elements inside an HTML document.</p>
    <p>Mutiple elements can have the same class name or value but for the id it's value must be unique for each element.</p>
    <p>They help us select elements in style sheets and scripts.</p>
    <br />
    
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code7} language="html" />
<ScrollToTopLink to="/try_html12"><Tryitout /></ScrollToTopLink>

</section>
 
</main>
<div className="head">
<ScrollToTopLink to="/HTML_attributes"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_heading"><ButtonNext /></ScrollToTopLink>
</div>
</div>

<Footertutorials />
   </body>
   
    )
}