import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubySyntax() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const variablesCode = `
# Variables in Ruby
name = "Alice"
age = 30
is_student = true

puts name       # Output: Alice
puts age        # Output: 30
puts is_student # Output: true
  `;

  const dataTypesCode = `
# Data Types in Ruby

# Strings
greeting = "Hello, world!"
puts greeting  # Output: Hello, world!

# Numbers
int_num = 42
float_num = 3.14
puts int_num   # Output: 42
puts float_num # Output: 3.14

# Booleans
is_ruby_fun = true
puts is_ruby_fun  # Output: true

# Arrays
fruits = ["apple", "banana", "cherry"]
puts fruits[0]    # Output: apple

# Hashes
person = { name: "Alice", age: 30 }
puts person[:name]  # Output: Alice
  `;

  const methodsCode = `
# Methods in Ruby

def say_hello
  puts "Hello!"
end

say_hello  # Output: Hello!

def add(a, b)
  a + b
end

result = add(5, 3)
puts result  # Output: 8
  `;

  const controlStructuresCode = `
# Control Structures in Ruby

# If-Else
age = 18
if age >= 18
  puts "You are an adult."
else
  puts "You are a minor."
end

# Loops
# While Loop
counter = 0
while counter < 5
  puts "Counter: \#{counter}"
  counter += 1
end

# Each Loop
fruits = ["apple", "banana", "cherry"]
fruits.each do |fruit|
  puts fruit
end
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Syntax</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Syntax, Variables, Data Types, Methods, Control Structures" />
        <meta name="description" content="Learn the basic syntax of Ruby, including variables, data types, methods, and control structures." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_environment"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_classes"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Syntax</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Ruby is a dynamic, open-source programming language with a focus on simplicity and productivity. Here's an introduction to the basic syntax in Ruby.</p>

            <h2>Variables</h2>
            <p>Variables in Ruby are used to store data that can be referenced and manipulated later in the program. They do not require explicit declaration to reserve memory space.</p>
            <SyntaxHighlighterComponent code={variablesCode} language="ruby" />
            <p>In this example:</p>
            <ul>
              <li><code>name</code> is a string variable.</li>
              <li><code>age</code> is an integer variable.</li>
              <li><code>is_student</code> is a boolean variable.</li>
            </ul>

            <h2>Data Types</h2>
            <p>Ruby supports several data types including strings, numbers, booleans, arrays, and hashes.</p>
            <SyntaxHighlighterComponent code={dataTypesCode} language="ruby" />
            <p>In this example:</p>
            <ul>
              <li><code>greeting</code> is a string.</li>
              <li><code>int_num</code> is an integer.</li>
              <li><code>float_num</code> is a float.</li>
              <li><code>is_ruby_fun</code> is a boolean.</li>
              <li><code>fruits</code> is an array.</li>
              <li><code>person</code> is a hash.</li>
            </ul>

            <h2>Methods</h2>
            <p>Methods in Ruby are used to encapsulate reusable code blocks. Methods are defined using the <code>def</code> keyword followed by the method name.</p>
            <SyntaxHighlighterComponent code={methodsCode} language="ruby" />
            <p>In this example:</p>
            <ul>
              <li>The <code>say_hello</code> method prints a greeting.</li>
              <li>The <code>add</code> method takes two parameters, adds them, and returns the result.</li>
            </ul>

            <h2>Control Structures</h2>
            <p>Ruby provides various control structures for decision making and looping.</p>
            <SyntaxHighlighterComponent code={controlStructuresCode} language="perl" />
            <p>In this example:</p>
            <ul>
              <li>The <code>if-else</code> statement checks a condition and executes code based on whether the condition is true or false.</li>
              <li>The <code>while</code> loop repeats code as long as a condition is true.</li>
              <li>The <code>each</code> loop iterates over each element in an array.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_environment"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_classes"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
