import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import logo from "../../image/react2.png";



export default function ReactEnvironmentSetup() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   const code = `
   node -v
   npm -v
   `;
   const code2 = `
   npm install -g create-react-app
   `;
   const code3 = `
   npx create-react-app my-react-app
   `;
   const code4 = `
   cd my-react-app
   `;
   const code5 = `
   npm start
   `;
    return (
   <body>
     <Helmet>
        
        <title>React Environment Setup</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React Environment Setup, Install Node.js and npm, Create a New React Application, Run the Development Server,
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="Learn how setup the environment for your React Application, Install Node.js and npm, Create a New React Application, Run the Development Server,
      enhance code reusability and unlock advanced features in React development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
    <br />
        <br />
        <br />
        <br />
    
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_create-react-app"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>React Environment Setup</h1>
</header>

<Navbar />

<main>
<section>
<p>Setting up a React environment involves several steps, including installing Node.js, using npm
     (Node Package Manager), and creating a new React application.</p>
<p>Below is a detailed guide on how to set up your environment for React development:</p>
<h2> Install Node.js and npm</h2>
<p>Node.js is a JavaScript runtime that allows you to execute JavaScript on the server side.</p>
<p> npm is a package manager that comes bundled with Node.js, allowing you to easily install and 
    manage libraries and tools.</p>
    <h3>Download Node.js</h3>
    <p>Visit the official Node.js website <a href="https://nodejs.org/" rel="nofollow">https://nodejs.org/</a>
         and download the LTS (Long Term Support) version suitable for your operating system. </p>
    <h3>Verify Installation</h3>
    <p>After installation, open your terminal or command prompt and run the following commands to verify 
        that Node.js and npm are installed:</p>
        <SyntaxHighlighterComponent code={code} language="ruby" />

    <h2>Create a New React Application</h2>
    <p>
        React provides a command-line tool called create-react-app 
        that sets up a new React application with a recommended project structure and development environment.
    </p>
    <h3>Install create-react-app:</h3>
    <p>Run the following command to install the create-react-app package globally:</p>
    <SyntaxHighlighterComponent code={code2} language="ruby" />
    <h3>Create a New App:</h3>
    <p>Once create-react-app is installed, create a new React application by running:</p>
    <SyntaxHighlighterComponent code={code3} language="ruby" />

      <p>Replace "my-react-app" with your desired project name. </p>
      <p>This command will create a new directory with the app's structure and necessary files.</p>

      <h3>Navigate to the App Directory:</h3>
      <p>Move into the app's directory using:</p>
      <SyntaxHighlighterComponent code={code4} language="ruby" />

      <h2> Run the Development Server</h2>
      <p>In the app's directory, you can run the development server to see your React app in action.</p>
      <h3>Start the Development Server:</h3>
      <p>Run the following command:</p>
      <SyntaxHighlighterComponent code={code5} language="ruby" />
      
    <p>This command starts the development server and opens your app in a web browser at http://localhost:3000.</p>
      <h2> Explore the Project Structure</h2>
      <p>The create-react-app command generates a project structure for you. Here's a brief overview of some
         important files and folders:</p>
         <li>src/: This folder contains the main source code of your React application.</li>
         <li>public/: Static assets (HTML, images) go here.</li>
         <li>package.json: Configuration file that lists dependencies, scripts, and project details.</li>
         <li>and project details.
            node_modules/: This is where all your project's dependencies are installed.</li>
            <p>This is what the react page looks like</p>

            <img src={require('../../image/react2.png')} width='1200' alt='a react file' />

    <h2>Start Coding!</h2>
    <p>With your React environment set up, you're ready to start building your application.</p>
    <p>You can edit the files in the src/ directory to create components, add styles, and implement functionality.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_create-react-app"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}