import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function CSScombinators() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   
    const code = `
       selector1 selector2 {
        property:value;
      }  
  
      `;
     const code2 = `
     div p {
        font-size: 30px;
        color:blue;
      }
     `;
     const code3 = `
     selector1 > selector2 {
        property:value;
      }
     `;
     const code4 = `
     div > p {
        font-size: 30px;
        color:blue;
      }
     `;
     const code5 = `
     selector1 ~ selector2 { 
        property:value;
      }
     `;
     const code6 = `
     li.first ~ li {   
        color: yellow;
    }

     `;
     const code7 = `
     selector1 + selector2 { 
        property:value;
      }
     `;
     const code8 = `
     li.first + li {   
        color: yellow;
    }
     `;
  
    return (
   <body>
      <Helmet>     
        <title>CSS Combinators</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Combinators, Descendant Combinator, Child Combinator, General Sibling Combinator, Adjacent Sibling Combinator,
        Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="CSS provides a variety of ways to define colors for elements on a web page.
      These colors can be specified using keywords, hexadecimal values, RGB values, HSL values, and more." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
   <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">
    
<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/CSS_selectors"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_colors"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>CSS Combinators</h1>
</header>

<Navbar />

<main>
<section>
<p>A CSS combinator is used explain a relationship between selectors.In CSS we can combine selectors</p>
        <p>Multiple selectors can be contained in a single selector.In CSS there are four types of combinators.</p>
        <li>Descendant Combinator</li>
        <li>Child Combinator</li>
        <li>General Sibling Combinator</li>
        <li>Adjacent Sibling Combinator</li>

        <h2>Descendant Combinator</h2>
        <p>This combinator selects any element matching the second selector that is a descendant of an element
            matching the first selector.
        </p>
        <br />
     <h2>Syntax</h2>
     <SyntaxHighlighterComponent code={code} language="css" />

     <br />
     <h2>Example:</h2>
     <SyntaxHighlighterComponent code={code2} language="css" />
<ScrollToTopLink to="/try_css10"><Tryitout /></ScrollToTopLink>
    
     <br />
     <h2>Child Combinator</h2>
     <p>The child combinator targets elements that are children of a particular element.
    </p>
    <p>This combinator is defined by the &gt; character.</p>
      <br />
     <h2>Syntax</h2>
     <SyntaxHighlighterComponent code={code3} language="css" />
<p>The example below selects any element that is a child element of the &lt;div&gt; element.</p>
     <br />
     <SyntaxHighlighterComponent code={code4} language="css" />
<ScrollToTopLink to="/try_css11"><Tryitout /></ScrollToTopLink>

<h2>General Sibling Combinator</h2>
<p>This combinator is used to select any element matching selector2 if it follows elements that matches the
  first selector(selector1) and both of the selectors are children of the same parent element.
</p>
<p>The general sibling selector selects all elements that are next siblings of a specified element.</p>
<p>The general sibling combinator is defined by the ~ character</p>
<br />
<h2>Syntax</h2>
<SyntaxHighlighterComponent code={code5} language="css" />

    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code6} language="css" />
<ScrollToTopLink to="/try_css12"><Tryitout /></ScrollToTopLink>
<h2>Adjacent Sibling Combinator</h2>
<p>The adjacent sibling combinator is used to select an element which immediately succeeds or follows it.</p>
<p>This combinator is represented by the + sign.</p>

<br />
<h2>Syntax</h2>
<SyntaxHighlighterComponent code={code7} language="css" />
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code8} language="css" />
<ScrollToTopLink to="/try_css13"><Tryitout /></ScrollToTopLink>

</section>
</main>
<div className="head">
<ScrollToTopLink to="/CSS_selectors"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_colors"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}