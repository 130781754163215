import React from "react";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import { Helmet } from "react-helmet";
import ScrollToTopLink from "../../components/ScrollToTop";
import DemoProgress from "../../components/Tutorial Progress/DemoProgress";
import ButtonNext from "../../components/Buttonnext";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonHome from "../../components/Buttonhome";

export default function Rubyintro() {
  const code = `
         # This is a comment
        puts 'Hello, world!'  # Output text

        # Variables
        name = 'Alice'
        age = 30

         # Arrays
        numbers = [1, 2, 3, 4, 5]

         # Hashes
         person = { name: 'Alice', age: 30 }

        # Methods
        def greet(name)
          puts "Hello, #{name}!"
        end

        greet('Alice')  # Call the method`
    return (
        <div>
            <Helmet>
                <title>Ruby Tutorial</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-compatible" content="IE=edge"/>
                <meta name="keywords" content="Introduction to Ruby, What is Ruby, Ruby features, Ruby programming tutorials, Ruby exercises, Ruby tips, SEO Guide, Ruby applications" />
                <meta name="description" content="Explore Ruby programming language: its features, benefits, and practical examples. Learn Ruby step-by-step with our comprehensive tutorials and exercises. Enhance your programming skills and discover best practices." />
                <meta name="viewport" content="width=device-width,initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials">
                <ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
                <ScrollToTopLink to="/Ruby_environment"><ButtonNext /></ScrollToTopLink>
                <h1>Ruby Overview</h1>
                </header>

                <Navbar />

                <main>
                    <section>
                        <h2>What is Ruby?</h2>
                        <p>Ruby is a dynamic, reflective, object-oriented programming language developed by Yukihiro Matsumoto in Japan. It combines syntax inspired by Perl with Smalltalk-like object-oriented features and supports multiple programming paradigms.</p>
                    </section>

                    <section>
                        <h2>Features of Ruby</h2>
                        <ul>
                            <li>Ruby is a true object-oriented language.</li>
                            <li>It can be embedded into an HTML document.</li>
                            <li>Used extensively for web development, including server-side scripting.</li>
                            <li>General-purpose language with a clean and easy-to-learn syntax.</li>
                            <li>Open-source with a license (typically the Ruby License or GNU General Public License).</li>
                            <li>Provides native support for various databases like Oracle, PostgreSQL, MySQL, etc.</li>
                            <li>Supports metaprogramming and reflection.</li>
                            <li>Includes a rich set of built-in functions and libraries.</li>
                            <li>Has a vibrant community and a wealth of third-party libraries (gems).</li>
                        </ul>
                    </section>

                    <section>
                        <h2>Benefits of Learning Ruby</h2>
                        <ul>
                            <li>Ruby has a simple and readable syntax that is easy to understand, making it an excellent choice for beginners.</li>
                            <li>Ruby on Rails, a popular web application framework, is built on Ruby and is widely used in the industry.</li>
                            <li>Learning Ruby can enhance your problem-solving skills and improve your understanding of object-oriented programming.</li>
                            <li>There are numerous resources, tutorials, and communities available to support learning and development.</li>
                        </ul>
                    </section>

                    <section>
                        <h2>Getting Started with Ruby</h2>
                        <p>To start programming in Ruby, you need to install the Ruby interpreter. Follow these steps to set up your Ruby environment:</p>
                        <ol>
                            <li>Download and install Ruby from the official website:<a href="https://www.ruby-lang.org/en/downloads/" rel="nofollow">https://www.ruby-lang.org/en/downloads</a></li>
                            <li>Set up a text editor or an IDE such as Visual Studio Code, RubyMine, or Sublime Text.</li>
                            <li>Familiarize yourself with basic Ruby syntax and commands using the interactive Ruby shell (IRB).</li>
                        </ol>
                    </section>

                    <section>
                        <h2>Basic Ruby Syntax</h2>
                        <p>Here are some basic syntax rules and examples to get you started with Ruby:</p>
                      <SyntaxHighlighterComponent code={code} language='perl' />
                    </section>

                    <section>
                        <h2>Advanced Ruby Features</h2>
                        <p>As you progress, you can explore more advanced features of Ruby, such as:</p>
                        <ul>
                            <li>Metaprogramming: Writing code that writes code.</li>
                            <li>Blocks, Procs, and Lambdas: Powerful constructs for handling code as data.</li>
                            <li>Modules and Mixins: Mechanisms for sharing functionality across classes.</li>
                            <li>Concurrency: Techniques for writing concurrent and parallel programs.</li>
                        </ul>
                    </section>
                    <div className="second-div">

                   <section className="features">
                 <div className="progress-sample">

                <img src={require('../../image/beta-progress.svg').default} width={500} height={500}  alt='betathread' />
                <p style={{fontSize: '15px'}}> Our Progress Tracker is designed to help you monitor your progress through our extensive range of tutorials.</p>
                <ScrollToTopLink to="/progress-tracker"><button type="submit" className="buttonb">Track Progress</button></ScrollToTopLink>

                </div>

                <div className="progress-sampling">
               <h1 style={{fontSize: '45px'}}>Track Your Progress</h1>
               <DemoProgress />
              </div>
         </section>
          </div>
                </main>

                <div className="head">
                <ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
                <ScrollToTopLink to="/Ruby_environment"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>

            <Footertutorials />
        </div>
    );
}
