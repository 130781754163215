import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactRouter() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    npm install react-router-dom `

    const code2=`
    yarn add react-router-dom `
  
     const code3=`
     import React from 'react';
     import { BrowserRouter, Route, Link } from 'react-router-dom';
     
     const Home = () => {
       return <h1>Home</h1>;
     };
     
     const About = () => {
       return <h1>About</h1>;
     };
     
     const App = () => {
       return (
         <BrowserRouter>
           <div>
             <h1>App</h1>
             <ul>
               <li><Link to="/">Home</Link></li>
               <li><Link to="/about">About</Link></li>
             </ul>
             <Route path="/" component={Home} />
             <Route path="/about" component={About} />
           </div>
         </BrowserRouter>
       );
     };`

     const code4=`
     import React from 'react';
     import { BrowserRouter, Route, Link } from 'react-router-dom';
     
     const Home = () => {
       return <h1>Home</h1>;
     };
     
     const About = () => {
       return <h1>About</h1>;
     };
     
     const Contact = () => {
       return <h1>Contact</h1>;
     };
     
     const App = () => {
       return (
         <BrowserRouter>
           <div>
             <h1>App</h1>
             <ul>
               <li><Link to="/">Home</Link></li>
               <li><Link to="/about">About</Link></li>
               <li><Link to="/about/contact">Contact</Link></li>
             </ul>
             <Route path="/" component={Home} />
             <Route path="/about" component={About} />
             <Route path="/about/contact" component={Contact} />
           </div>
         </BrowserRouter>
       );
     };  `

     const code5=`
     import React from 'react';
     import { BrowserRouter, Route, Link } from 'react-router-dom';
     
     const User = ({ match }) => {
       return <h1>User {match.params.id}</h1>;
     };
     
     const App = () => {
       return (
         <BrowserRouter>
           <div>
             <h1>App</h1>
             <ul>
               <li><Link to="/user/1">User 1</Link></li>
               <li><Link to="/user/2">User 2</Link></li>
             </ul>
             <Route path="/user/:id" component={User} />
           </div>
         </BrowserRouter>
       );
     }; `


        
    return (
   <body>
     <Helmet>
        
        <title>React Router</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React Router, 
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="" />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_forms"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React Router</h1>
</header>

<Navbar />

<main>
<section>
   
<p>React Router is a popular library for client-side routing in React applications.</p>
<p>It allows you to navigate between views and manage application state.</p>
 <h2>Why Use React Router?</h2>
 <ul>
    <li>Easy navigation between views.</li>
    <li>Manage application state.</li>
    <li>Support for server-side rendering.</li>
    <li>Extensive community support.</li>
 </ul>
 <h2>Installing React Router DOM</h2>
 <p>To use React Router, you need to install the react-router-dom package. You can do this by running the following command in your terminal:</p>
<SyntaxHighlighterComponent code={code} language="js" /><br />
<h4 style={{marginLeft: '15px'}}><b>OR</b></h4>
<SyntaxHighlighterComponent code={code2} language="js" />


<h2>Basic Routing</h2>
<SyntaxHighlighterComponent code={code3} language="js" />

<h2>Nested Routing</h2>
<SyntaxHighlighterComponent code={code4} language="js" />

<h2> Route Parameters</h2>
<SyntaxHighlighterComponent code={code5} language="js" />

</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_forms"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}