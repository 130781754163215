import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonSetMethods() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeUnion = `
# Union of Sets
set1 = {1, 2, 3}
set2 = {3, 4, 5}
union_set = set1.union(set2)
print(union_set)  # Output: {1, 2, 3, 4, 5}
    `;

    const codeIntersection = `
# Intersection of Sets
set1 = {1, 2, 3}
set2 = {3, 4, 5}
intersection_set = set1.intersection(set2)
print(intersection_set)  # Output: {3}
    `;

    const codeDifference = `
# Difference of Sets
set1 = {1, 2, 3}
set2 = {3, 4, 5}
difference_set = set1.difference(set2)
print(difference_set)  # Output: {1, 2}
    `;

    const codeSymmetricDifference = `
# Symmetric Difference of Sets
set1 = {1, 2, 3}
set2 = {3, 4, 5}
symmetric_difference_set = set1.symmetric_difference(set2)
print(symmetric_difference_set)  # Output: {1, 2, 4, 5}
    `;

    const codeOtherMethods = `
# Other Useful Set Methods

# isdisjoint() - Returns True if two sets have a null intersection
set1 = {1, 2, 3}
set2 = {4, 5, 6}
print(set1.isdisjoint(set2))  # Output: True

# issubset() - Returns True if the set is a subset of another set
set1 = {1, 2, 3}
set2 = {1, 2, 3, 4, 5}
print(set1.issubset(set2))  # Output: True

# issuperset() - Returns True if the set is a superset of another set
print(set2.issuperset(set1))  # Output: True

# add() - Adds an element to the set
set1.add(4)
print(set1)  # Output: {1, 2, 3, 4}

# remove() - Removes an element from the set, raises KeyError if not present
set1.remove(4)
print(set1)  # Output: {1, 2, 3}

# discard() - Removes an element from the set if present, does nothing if not
set1.discard(3)
print(set1)  # Output: {1, 2}

# pop() - Removes and returns an arbitrary element from the set
popped_element = set1.pop()
print(popped_element)  # Output: 1 (or 2, sets are unordered)
print(set1)  # Output: {2} (or {1})

# clear() - Removes all elements from the set
set1.clear()
print(set1)  # Output: set()
    `;

    return (
        <body>
            <Helmet>
                <title>Python Set Methods</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Set Methods, Union, Intersection, Difference, Symmetric Difference, Subset, Superset, Disjoint, Add, Remove, Discard, Pop, Clear" />
                <meta name="description" content="Learn about various set methods in Python, including union, intersection, difference, and symmetric difference, as well as other useful methods like isdisjoint, issubset, issuperset, add, remove, discard, pop, and clear." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_sets"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_set_comprehensions"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Set Methods</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Python provides various methods to perform operations on sets. These include union, intersection, difference, and symmetric difference.</p>
                        
                        <h2>Union</h2>
                        <p>The <code>union()</code> method returns a set that contains all elements from both sets.</p>
                        <SyntaxHighlighterComponent code={codeUnion} language="python" />
                        
                        <h2>Intersection</h2>
                        <p>The <code>intersection()</code> method returns a set that contains only the elements that are present in both sets.</p>
                        <SyntaxHighlighterComponent code={codeIntersection} language="python" />
                        
                        <h2>Difference</h2>
                        <p>The <code>difference()</code> method returns a set that contains the elements that are present in the first set but not in the second set.</p>
                        <SyntaxHighlighterComponent code={codeDifference} language="python" />
                        
                        <h2>Symmetric Difference</h2>
                        <p>The <code>symmetric_difference()</code> method returns a set that contains elements that are in either of the sets, but not in both.</p>
                        <SyntaxHighlighterComponent code={codeSymmetricDifference} language="python" />
                        
                        <h2>Other Useful Set Methods</h2>
                        <p>Here are some other useful set methods:</p>
                        <SyntaxHighlighterComponent code={codeOtherMethods} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Understanding set methods allows you to perform various operations on sets, enabling efficient data manipulation and analysis in Python.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_sets"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_set_comprehensions"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
