import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLattributes() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <!-- I am an attribute -->
      <a href="#">click me</a>
  `;
  
    
    return (
   <body>
     <Helmet>    
        <title>HTML Attrubutes</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Attributes, href Attribute, src Attribute, Width & Height Attribute, style Attribute, alt Attribute, lang Attribute, id and class Attribute,
         Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
      <meta name="description" content="HTML attributes are used to provide more information to an HTML element. HTML elements can have multiple attributes.
        HTML attributes are found in HTML tags. HTML attributes only appear at the start tag. HTML attributes are comprised of name/value pairs." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">
<header className="headertutorials" style={newCode}>
   
<ScrollToTopLink to="/HTML_elements"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_attributes pt.2"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Attributes</h1>
</header>

<Navbar />

<main>
<section>
<p>HTML attributes are used to provide more information to an HTML element.</p>
<h2>Note:</h2>
<li>HTML elements can have multiple attributes.</li>
<li>HTML attributes are found in HTML tags</li>
<li>HTML attributes only appear at the start tag.</li>
<li>HTML attributes are comprised of name/value pairs.</li>
<p> HTML attributes accept two parameters</p>
<li>Name</li>
<li>Value</li>
<p>An HTML attributes is composed of:</p>
<li>an attribute name</li>
<li>an equal sign (=)</li>
<li>a value surrounded by punctuation marks "value"</li>
<p>This is how it looks like: &lt;elementName attributeName="value"&gt;</p> 
<p>The use of single quotation marks is also allowed in HTML depending on the situation especially when the value contains double quotes.</p>
<h2>Attributes are of many types:</h2>
<li>href attribute</li>
<li>src attribute</li>
<li>width & height attribute</li>
<li>style attribute</li>
<li>alt attribute</li>
<li>lang attribute</li>
<li>id & class attribute</li>

<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html6"><Tryitout /></ScrollToTopLink>
</section>

<div>
<section>
    <h2>Example Explained:</h2>
    <p>The example above consists of the following components:</p>
    <li>An elementname:&lt;a&gt;&lt;/a&gt; known as the anchor tag.</li>
    <li>An attributename:href</li>
    <li>The value assigned to the attributename:"#"</li>
    <li>The text after the opening anchor tag:click me</li>
    <p>As you can see the &lt;a&gt; tag has an attribute attached to it and this attribute carries a value which is #.</p>
    <p>The # sign in HTML is called "pound sign".</p>
    <br />
    <p>We will learn more about HTML attributes in the next chapter.</p>
</section>
 </div>
</main>
<div className="head">
<ScrollToTopLink to="/HTML_elements"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_attributes pt.2"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}