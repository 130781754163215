import React, { useState } from "react";
import "../css/my style.css";
import "../css/my style2.css";
import { useNavigate } from "react-router-dom";
import { deleteAuthStatus } from "../localstorage";


export default function Logoutbuttonlight() {
    
     const [authenticated, setAuthenticated] = useState(false);
     const navigate = useNavigate();

     const logOut = async () => {
        try {
            // Sign the user out by sending a request to the logout endpoint
            const response = await fetch("https://betathread.com/auth/logout", {
                method: "POST",
                
            });

            if (response.ok) {
                // If the sign-out request is successful, navigate the user to the login page
                const data = await response.json();
                navigate("/welcome");
                deleteAuthStatus('_id');
                console.log("Logout Successful")
            } else {
                // If the sign-out request fails, throw an error
                alert("Failed to log out")
                throw new Error("Failed to log out");
            
            }
        } catch (error) {
            // Log any errors and handle them appropriately
            console.error("Error logging out:", error);
            alert("Error logging out:", error)
            // For example, you can display an error message to the user
            // or redirect them to an error page
        }
    };



    return(
        <button className="nav-button" onClick={logOut}>LogOut</button>
    )
}