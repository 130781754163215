import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactEvent() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code0= `
    import React from 'react';

    class Button extends React.Component {
      handleClick() {
        console.log('Button clicked!');
      }
    
      render() {
        return (
          <button onClick={this.handleClick}>Click me</button>
        );
      }
    }
    
    export default Button;
    `

    const code01= `
    import React from 'react';

    class Input extends React.Component {
      handleChange(event) {
        console.log('Input value:', event.target.value);
      }
    
      render() {
        return (
          <input type="text" onChange={this.handleChange} />
        );
      }
    }
    
    export default Input;
    `

    const code02= `
    import React from 'react';

    const Button = () => {
      const handleClick = () => {
        console.log('Button clicked!');
      };
    
      return (
        <button onClick={handleClick}>
          Click me!
        </button>
      );
    };`
    const code2=`
    import React from 'react';

    const Form = () => {
      const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form submitted!');
      };
    
      return (
        <form onSubmit={handleSubmit}>
          <input type="text" name="name" />
          <button type="submit">Submit</button>
        </form>
      );
    };   `
  
     const code3=`
     import React from 'react';

     const Input = () => {
       const handleKeyPress = (event) => {
         if (event.key === 'Enter') {
           console.log('Enter key pressed!');
         }
       };
     
       return (
         <input type="text" onKeyPress={handleKeyPress} />
       );
     };    `

         const code4=`
         import React from 'react';

         const Touchable = () => {
           const handleTouchStart = () => {
             console.log('Touch started!');
           };
         
           return (
             <div onTouchStart={handleTouchStart}>
               Touch me!
             </div>
           );
         };        `
        const code5=`
        import React, { Component } from 'react';

        class Counter extends Component {
          constructor(props) {
            super(props);
            this.state = { count: 0 };
          }
        
          render() {
            return (
              <div>
                <p>Count: {this.state.count}</p>
                <button onClick={() => this.setState({ count: this.state.count + 1 })}>
                  Increment
                </button>
              </div>
            );
          }
        }
        
        const App = () => {
          return (
            <div>
              <Counter />
            </div>
          );
        };    `

        const code6=`
        import React from 'react';

        class ArrowButton extends React.Component {
          handleClick = () => {
            console.log('Button clicked!');
          }
        
          render() {
            return (
              <button onClick={this.handleClick}>Click me</button>
            );
          }
        }
        
        export default ArrowButton;
          `
        
        const code7=`
        import React from 'react';

        class Link extends React.Component {
          handleClick(event) {
            event.preventDefault();
            console.log('Link clicked!');
          }
        
          render() {
            return (
              <a href="#" onClick={this.handleClick}>Click me</a>
            );
          }
        }
        
        export default Link;
         `
        
    return (
   <body>
     <Helmet>
        
        <title>React Event Handling</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React Event Handling, What is Event Handling?, Types of Events, Handling Events, Basic Event Handling, Event Parameters, Click Event Example, Form Submission Example, Keyboard Event Example, Touch Event Example, Arrow Function Syntax, Prevent Default Behavior,
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications" />
      <meta name="description" content="Event handling is the process of responding to user interactions, such as clicks, key presses, and form submissions. In React, event handling is achieved through the use of event handler functions." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_props"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_conditional"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React Event Handling</h1>
</header>

<Navbar />

<main>
<section>
    <h1>What is Event Handling?</h1>
<p>Event handling is the process of responding to user interactions, such as clicks, key presses, and form submissions.</p>
  <p>In React, event handling is achieved through the use of event handler functions.</p>

<h2>Types of Events</h2>
<p>React supports various types of events, including:</p>
<ol type="1">
    <li>Mouse events (e.g., onClick, onMouseOver)</li>
    <li>Keyboard events (e.g., onKeyDown, onKeyPress)</li>
    <li>Keyboard events (e.g., onKeyDown, onKeyPress)</li>
    <li>Touch events (e.g., onTouchStart, onTouchEnd)</li>

</ol>

<h2>Handling Events</h2>
<p>To handle an event in React, you need to:</p>
<ol type="1">
    <li>Define a handler function</li>
    <li>Pass the handler function as a prop to the element</li>
</ol>

<h2>Basic Event Handling</h2>
<p>React event handling is similar to handling events in HTML, but with some syntactical differences.</p>
<p>Let's start with a basic example:</p>
<SyntaxHighlighterComponent code={code0} language="js" />
<p>In this example, we define a 'Button' component with a 'handleClick' method that logs a message when the button is clicked. </p>
<p>We then attach this method to the 'onClick' event of the button.</p>

<h2>Event Parameters</h2>
<p>You can also pass parameters to event handler functions:</p>
<SyntaxHighlighterComponent code={code01} language="js" />
<p>Here, the 'handleChange' method receives the 'event' object as a parameter, allowing us to access the value of the input field using 'event.target.value'.</p>


<h2>Click Event Example</h2>
 <SyntaxHighlighterComponent code={code02} language="js" />

 <h2>Form Submission Example</h2>
  <SyntaxHighlighterComponent code={code2} language="js" />

<h2>Keyboard Event Example</h2>
 <SyntaxHighlighterComponent code={code3} language="js" />

 <h2>Touch Event Example</h2>
  <SyntaxHighlighterComponent code={code4} language="js" />

 <h2>Arrow Function Syntax</h2>
  <p>You can use arrow function syntax to define event handlers inline:</p>
  <SyntaxHighlighterComponent code={code6} language="js" />

  <h2>Prevent Default Behavior</h2>
  <p>To prevent the default behavior of an event, such as form submission or link navigation, you can use the 'preventDefault' method:</p>
  <SyntaxHighlighterComponent code={code7} language="js" />
<p>Calling 'event.preventDefault()'' prevents the default action associated with the event.</p>
  

</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_props"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_conditional"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}