import Header from "../components/Header-dashboard";
import Footer from "../components/Footer";
import NavbarProtected from "../Protected/navbar-protected";
import Body from "../components/Dashboard-body";
import Topnav from "../components/topnav";
import { Helmet } from 'react-helmet';
import "../css/my style2.css";
import Topnav3 from "../components/topnav3";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthStatus, saveAuthStatus} from "../localstorage";


export default function Dashboard() {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate(); // Move useNavigate here

  useEffect(() => {
    const checkAuthentication = () => {
      const isAuthenticated = getAuthStatus('_id');
      if (!isAuthenticated) {
        navigate("/login");
      } else {
        setAuthenticated(true);
      }
    };

    checkAuthentication();
  }, [navigate]);



    return (
       
        <body>
        <Helmet>
      <meta name="Keywords" content="HTML, Python, CSS, SQL, JavaScript, How to, PHP, XML, MySQL, React, AI, How to, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, Examples, Learn to code, Coding the future, Source code, Website" />
      <meta name="description" content="Dashboard page of Beta Thread protected for only authenticated users." />
      <link  rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"  integrity="sha512-+U/zeIz2w4FG06Q4FmszSds5z7Q5zFvh3vZn5F0t4PwxhlvZksC5l5oRpLJz7wC1f4L2zTBmz05L/uM5MfKC1Q==" crossorigin="anonymous" referrerpolicy="no-referrer" />   
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      <link rel="preload" href="../css/header.png" as="image" />
      <title>Dashboard | Beta Thread</title>

        </Helmet>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Topnav />
        <Header />
        <NavbarProtected />
        <Body />
        
        <Footer />
       
     
        </body>
    )
}