import React from "react";
import Payment from "./PaymentComponent";
import "../App.css";



export default function CertificatePage() {

return (
   <div className="App">
    <header className="App-headers">
        <h1>Get your cerificate</h1>
        <Payment />
    </header>
   </div>

)


}

