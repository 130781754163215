import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLUpdate() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const updateSingleRowCode = `
-- Update a single row in Employees table
UPDATE Employees
SET Salary = 5500
WHERE Name = 'Alice';
  `;

  const updateMultipleRowsCode = `
-- Update multiple rows in Employees table
UPDATE Employees
SET Salary = 6500
WHERE Department = 'Engineering';
  `;

  return (
    <body>
      <Helmet>
        <title>SQL UPDATE Statement</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL UPDATE Statement, SQL Tutorial, UPDATE SET, WHERE clause" />
        <meta name="description" content="Learn about the SQL UPDATE statement, including syntax, examples, and updating single and multiple rows in a table." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_insert"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_delete"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL UPDATE Statement</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>The <code>UPDATE</code> statement is used to modify existing records (rows) in a table in a database.</p>

            <h2>Basic Syntax</h2>
            <p>The basic syntax of an <code>UPDATE</code> statement is:</p>
            <SyntaxHighlighterComponent code={updateSingleRowCode} language="sql" />

            <p>In this example:</p>
            <ul>
              <li><code>Employees</code> is the name of the table where the data is updated.</li>
              <li><code>SET Salary = 5500</code> updates the <code>Salary</code> column to <code>5500</code>.</li>
              <li><code>WHERE Name = 'Alice'</code> specifies which rows to update based on the condition.</li>
            </ul>

            <h2>Updating Multiple Rows</h2>
            <p>You can also update multiple rows in a table using a single <code>UPDATE</code> statement:</p>
            <SyntaxHighlighterComponent code={updateMultipleRowsCode} language="sql" />

            <p>In this example:</p>
            <ul>
              <li><code>SET Salary = 6500</code> updates the <code>Salary</code> column to <code>6500</code> for all rows where <code>Department = 'Engineering'</code>.</li>
              <li>The <code>WHERE</code> clause specifies the condition to filter rows for updating.</li>
            </ul>

            <h2>Example Table</h2>
            <p>Consider the following table named <code>Employees</code> where data is being updated:</p>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Department</th>
                  <th>Salary</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Alice</td>
                  <td>30</td>
                  <td>HR</td>
                  <td>5500</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Bob</td>
                  <td>25</td>
                  <td>Engineering</td>
                  <td>6500</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Charlie</td>
                  <td>35</td>
                  <td>Finance</td>
                  <td>7000</td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_insert"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_delete"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
