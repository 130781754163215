import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLXqueryFunctions() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
let \$doc := <books>
  <book>
    <title>Book 1</title>
    <author>Author 1</author>
  </book>
  <book>
    <title>Book 2</title>
    <author>Author 2</author>
  </book>
</books>
return upper-case(\$doc/book/title)
`;

  const example2 = `
let \$numbers := (1, 2, 3, 4, 5)
return sum(\$numbers)
`;

  const example3 = `
let \$doc := <books>
  <book>
    <title>Book 1</title>
    <author>Author 1</author>
  </book>
  <book>
    <title>Book 2</title>
    <author>Author 2</author>
  </book>
</books>
return count(\$doc/book)
`;

  return (
    <body>
      <Helmet>
        <title>XQuery Functions</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML XQuery Functions, XQuery Tutorial, XML Functions" />
        <meta name="description" content="Learn about XML XQuery Functions with multiple examples and detailed explanations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/XML_xquery_expressions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xquery_axes"><ButtonNext /></ScrollToTopLink>
          <h1>XQuery Functions</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XQuery functions are essential for manipulating and querying XML data effectively. They provide various operations such as string manipulation, numerical computations, and more.</p>

            <h2>Example: Using upper-case Function</h2>
            <SyntaxHighlighterComponent code={example1} language="xquery" />
            <p>This example uses the <code>upper-case</code> function to convert the titles of all books to uppercase.</p>

            <h2>Example: Using sum Function</h2>
            <SyntaxHighlighterComponent code={example2} language="xquery" />
            <p>This example uses the <code>sum</code> function to calculate the sum of a sequence of numbers.</p>

            <h2>Example: Using count Function</h2>
            <SyntaxHighlighterComponent code={example3} language="xquery" />
            <p>This example uses the <code>count</code> function to count the number of book elements in the XML document.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_xquery_expressions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xquery_axes"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
