import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import SQLMetatag from "../../components/SQL_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";




export default function SQLQueries() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    CREATE DATABASE yourDatabase;
    /* The Create DATABASE statement is used to create
    a database and the text after the statement specifies
    the name of the database that you are creating you can
    modify it to any name of your choice */
    `;
   
    const code2 = `
    CREATE TABLE yourTable(S/N int, first_name, last_name, age int);
    -- This query is used to create a table in your database
    `;
    const code3 = `
    INSERT INTO yourTable VALUES (1, 'Esther', 'Ada', 25);
    INSERT INTO yourTable VALUES (2, 'Mike', 'Matthews', 30);
    INSERT INTO yourTable VALUES (3, 'Zimmy', 'Chinwe', 28);
    `;
    return (
   <body>
    <Helmet>
        
        <title>SQL Queries</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="SQL queries, Step-by-step SQL programming tutorials SQL coding exercises for beginners, Advanced SQL coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       SQL programming tips for real-world applications," />
      <meta name="description" content="Learn how SQL queries streamline code organization, 
      enhance readability, and unlock advanced features in PHP development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/SQL_comments"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/SQL_select"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>SQL Queries</h1>
</header>

<Navbar />

<main>
<section>
<h1>What is a Query in SQL</h1>
<p>A query is a command written in SQL to perform different operations.</p>
<p>Queries are responsible for making certain changes in a database.</p>
<p>We can write queries to drop a database, create a database, modify a data in the database, insert tables
    into the database and so on.
</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code} language="sql" />
<p>The query above is used to create a new database.The CREATE DATABASE is the statement that creates the 
    database and the next text which follows it yourDatabase is the name of the database.
</p>
<p>Here's another example of a query.</p>
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="sql" />
<p>In the second example the query is used to create a table that can be used to store values.</p>
<p>In the table four columns will be created S/N(Serial Number), first_name, last_name and age.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code3} language="sql" />
<p>This example involves inserting values into the table which has been created.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/SQL_comments"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/SQL_select"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}