import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import CSSMetatag from "../../components/CSS_Metatag";
import ScrollToTopLink from "../../components/ScrollToTop"; // Import the ScrollToTopLink component

export default function CSSBackgrounds() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   
    const code = `
    body {
        background-color: green;
        backgroung-image: url("a monitor.jpg");
        background-repeat: no-repeat;
        background-attachment: fixed;
        backgroung-position: top-left;
       }
    `;
    const code2 = `
    body {
        background: green url("a monitor.jpg") no-repeat fixed top-left;
       }
    `;

    return (
        <body>
            <Helmet>     
        <title>CSS Background</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Backgrounds, CSS Backgrounds Example, Background-Shorthand Property, 
        Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="In CSS, the background property provides a comprehensive way to style the background of an element.
      This encompasses various background-related properties like color, image, position, size, repeat behavior, and attachment. It allows you to create visually engaging and dynamic backgrounds for your web content." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
            <br />
        <br />
        <br />
        <br />
        <br />
            <Sidenav />
       
            <div className="content">

                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/CSS_text_color"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/CSS_background_colors"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>CSS Backgrounds</h1>
                </header>

                <Navbar />

                <main>
                    <section>
                        <p>In CSS, the background property provides a comprehensive way to style the background of an element. </p>
                        <p>This encompasses various background-related properties like color, image, position, size, repeat behavior, and attachment. 
                            It allows you to create visually engaging and dynamic backgrounds for your web content.</p>
                        <p>CSS backgrounds let you style the background of an element. You can control its color, image, position,
                            and more.</p>

                        <h2>Background-Shorthand Property</h2>
                        <p>All CSS background properties can be specified in one single property using its shorthand property.</p>
                        <p>The background property is a shorthand for the following CSS properties:</p>
                        <li>background-color</li>
                        <li>backgroung-image</li>
                        <li>backgroung-position</li>
                        <li>background-repeat</li>
                        <li>background-attachment</li>
                        <br />
                        <h2>Example:</h2>
                        <SyntaxHighlighterComponent code={code} language="css" />
                        <ScrollToTopLink to="/try_css21"><Tryitout /></ScrollToTopLink>
                        <p>This can be shortened in this format:</p>
                        <br />
                        <h2>Example:</h2>
                        <SyntaxHighlighterComponent code={code2} language="css" />
                        <ScrollToTopLink to="/try_css22"><Tryitout /></ScrollToTopLink>

                        <p>Remember that CSS color values can be specified using different notations 
                            and methods. The color property applies the chosen color to the text content of the selected element.</p>

                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/CSS_text_color"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/CSS_background_colors"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>

            <Footertutorials />
        </body>
    )
}
