import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyRanges() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const rangeCreationCode = `
# Creating Ranges
range1 = 1..10   # Inclusive range
range2 = 1...10  # Exclusive range

puts range1.to_a  # Output: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
puts range2.to_a  # Output: [1, 2, 3, 4, 5, 6, 7, 8, 9]
  `;

  const rangeMethodsCode = `
# Range Methods
range = 1..5

puts range.include?(3)   # Output: true
puts range.min           # Output: 1
puts range.max           # Output: 5
puts range.size          # Output: 5
  `;

  const rangeIterationCode = `
# Iterating Over a Range
range = 1..5

range.each do |number|
  puts number
end
# Output:
# 1
# 2
# 3
# 4
# 5
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Ranges</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Ranges, Creating Ranges, Range Methods, Iterating Over a Range" />
        <meta name="description" content="Learn about ranges in Ruby, including how to create, use various range methods, and iterate over ranges." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_string_methods"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_symbols"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Ranges</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Ranges in Ruby represent a sequence of values and are created using the <code>..</code> and <code>...</code> operators.</p>

            <h2>Creating Ranges</h2>
            <p>Ranges can be inclusive or exclusive.</p>
            <SyntaxHighlighterComponent code={rangeCreationCode} language="ruby" />
            <p>In the above example, we create an inclusive range <code>1..10</code> and an exclusive range <code>1...10</code>.</p>

            <h2>Range Methods</h2>
            <p>Ruby provides several methods to work with ranges.</p>
            <SyntaxHighlighterComponent code={rangeMethodsCode} language="ruby" />
            <p>In the above example, we use methods like <code>include?</code>, <code>min</code>, <code>max</code>, and <code>size</code> to interact with the range.</p>

            <h2>Iterating Over a Range</h2>
            <p>You can iterate over the values in a range using the <code>each</code> method.</p>
            <SyntaxHighlighterComponent code={rangeIterationCode} language="ruby" />
            <p>In the above example, we use the <code>each</code> method to print each value in the range <code>1..5</code>.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_string_methods"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_symbols"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
