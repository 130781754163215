import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AITextPreprocessing() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const textPreprocessingCode = `
# Example of Text Preprocessing with NLTK
import nltk
from nltk.corpus import stopwords
from nltk.tokenize import word_tokenize
nltk.download('punkt')
nltk.download('stopwords')

# Example text
text = "Preprocessing text involves tokenization, removing stop words, and stemming."
tokens = word_tokenize(text)

# Remove stopwords
filtered_tokens = [word for word in tokens if word.lower() not in stopwords.words('english')]
  `;

  return (
    <body>
      <Helmet>
        <title>AI Text Preprocessing</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Text Preprocessing, Natural Language Processing, AI Tutorial, Text Preprocessing with NLTK" />
        <meta name="description" content="Learn about AI text preprocessing techniques, including tokenization, stopword removal, and stemming, using libraries like NLTK." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/AI_nlp"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/AI_sentiment_analysis"><ButtonNext /></ScrollToTopLink>
          <h1>AI Text Preprocessing</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Text preprocessing is an essential step in natural language processing (NLP) that prepares text data for analysis and machine learning models. It includes tasks such as tokenization, removing stopwords, and stemming.</p>

            <h2>Introduction to Text Preprocessing</h2>
            <p>Here's an example of text preprocessing using NLTK (Natural Language Toolkit), a popular library for NLP tasks in Python:</p>
            <SyntaxHighlighterComponent code={textPreprocessingCode} language="python" />
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_nlp"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_sentiment_analysis"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
