import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSsyntax() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
    var number = 15;
    let Number = 6;
    const decimal = 3.14;
`;
const code2 = `
var string = "I love JS"; //double quotes
let str = 'My name is John Doe'; //single quotes
const x = 'Beta Thread is fun'; //single quotes
`;

    return (
   <body>
       <Helmet>
    
    <title>JavaScript Syntax</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="JavaScript Syntax, JavaScript Variables, JavaScript Strings, JavaScript Keywords" />
  <meta name="description" content="In JavaScript, variables are used to store and manage data. They act as containers that hold
   different types of information, such as numbers, text, or more complex objects." />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_writing_code"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_comment"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Syntax</h1>
</header>

<Navbar />

<main>
    <section>
    <p>A syntax is a set of rules and JavaScript has it's syntax which you must follow to execute your code 
    efficiently.
</p>

    <h2>Variables</h2>
    <p>In JavaScript variables are used to hold values the same way you do in mathematics.</p>
    <p>To declare a variables we use either the var,let or const keywords and then an equal sign = to assign a
        value.
    </p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
<ScrollToTopLink to="/try_js4"><Tryitout /></ScrollToTopLink>
  <h3>NOTE:</h3>
  <p>JavaScript is case-sensitive meaning that the variables number and Number are two entirely different variables
    with different values.
  </p>
    <br />
<h2>Strings</h2>
<p>A string is simply a sequence of characters enquoted with either single or double quotes.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js5"><Tryitout /></ScrollToTopLink>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_writing_code"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_comment"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}