import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import neural from "../../image/neural-network.png";

export default function AINeuralNetworks() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const exampleCode = `
# Python example for a simple neural network using Keras
import numpy as np
from keras.models import Sequential
from keras.layers import Dense

# Generate sample data
X = np.random.rand(1000, 10)
y = np.random.randint(2, size=(1000, 1))

# Create neural network model
model = Sequential()
model.add(Dense(12, input_dim=10, activation='relu'))
model.add(Dense(8, activation='relu'))
model.add(Dense(1, activation='sigmoid'))

# Compile model
model.compile(loss='binary_crossentropy', optimizer='adam', metrics=['accuracy'])

# Fit model
model.fit(X, y, epochs=150, batch_size=10)

# Evaluate model
_, accuracy = model.evaluate(X, y)
print('Accuracy: %.2f' % (accuracy*100))
`;

  return (
    <body>
      <Helmet>
        <title>AI Neural Networks</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI, Neural Networks, Machine Learning, Keras, Deep Learning" />
        <meta name="description" content="Learn about AI Neural Networks including their structure, functionality, and practical examples." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_reinforcement_learning"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_deep_learning"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>AI Neural Networks</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Neural networks are a type of machine learning model inspired by the human brain. They consist of layers of interconnected nodes, or neurons, which process input data to produce an output.</p>
            <img src={neural} style={{height: '400px', width: '100vw'}} alt="machine learning" />
          
            <h2>Basic Structure of a Neural Network</h2>
            <ul>
              <li><strong>Input Layer</strong>: Receives the input data.</li>
              <li><strong>Hidden Layers</strong>: Perform computations and extract features from the input data.</li>
              <li><strong>Output Layer</strong>: Produces the final output.</li>
            </ul>

            <h2>Activation Functions</h2>
            <p>Activation functions introduce non-linearity into the network. Common activation functions include:</p>
            <ul>
              <li><strong>ReLU</strong>: Rectified Linear Unit, f(x) = max(0, x)</li>
              <li><strong>Sigmoid</strong>: f(x) = 1 / (1 + e^(-x))</li>
              <li><strong>Tanh</strong>: Hyperbolic Tangent, f(x) = tanh(x)</li>
            </ul>

            <h2>Basic Example of a Neural Network</h2>
            <p>Here is a simple example of using Python to create a neural network using Keras:</p>
            <SyntaxHighlighterComponent code={exampleCode} language="python" />
            <p>In this example:</p>
            <ul>
              <li>We generate sample data and create a neural network model with Keras.</li>
              <li>We compile the model and fit it to the data.</li>
              <li>We evaluate the model's accuracy.</li>
            </ul>

            <h2>Applications of Neural Networks</h2>
            <p>Neural networks are used in various applications such as:</p>
            <ul>
              <li><strong>Image Recognition</strong>: Identifying objects in images.</li>
              <li><strong>Speech Recognition</strong>: Converting spoken language into text.</li>
              <li><strong>Natural Language Processing</strong>: Understanding and generating human language.</li>
              <li><strong>Financial Forecasting</strong>: Predicting stock prices and economic trends.</li>
              <li><strong>Autonomous Vehicles</strong>: Enabling self-driving cars to navigate environments.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_reinforcement_learning"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_deep_learning"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
