import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLCTEs() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const basicCTECode = `
-- Basic CTE
WITH EmployeeCTE AS (
    SELECT employee_id, employee_name, department_id
    FROM employees
    WHERE department_id = 3
)
SELECT *
FROM EmployeeCTE;
  `;

  const recursiveCTECode = `
-- Recursive CTE
WITH RECURSIVE EmployeeHierarchy AS (
    SELECT employee_id, employee_name, manager_id, 1 AS level
    FROM employees
    WHERE manager_id IS NULL
    UNION ALL
    SELECT e.employee_id, e.employee_name, e.manager_id, eh.level + 1
    FROM employees e
    INNER JOIN EmployeeHierarchy eh ON e.manager_id = eh.employee_id
)
SELECT employee_id, employee_name, manager_id, level
FROM EmployeeHierarchy;
  `;

  return (
    <body>
      <Helmet>
        <title>SQL Common Table Expressions (CTEs)</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL Common Table Expressions, SQL CTE, SQL Tutorial, Recursive CTE Examples" />
        <meta name="description" content="Learn about SQL Common Table Expressions (CTEs), including syntax, examples, and using recursive CTEs for hierarchical data." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_correlated"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_joins"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL Common Table Expressions (CTEs)</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>SQL Common Table Expressions (CTEs) are temporary result sets that are defined within the execution scope of a single <code>SELECT</code>, <code>INSERT</code>, <code>UPDATE</code>, or <code>DELETE</code> statement. They are helpful for breaking down complex queries and improving readability.</p>

            <h2>Basic CTE</h2>
            <p>A basic CTE is defined using the <code>WITH</code> keyword, followed by the CTE name and the query that generates the CTE result set.</p>
            <SyntaxHighlighterComponent code={basicCTECode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The CTE <code>EmployeeCTE</code> selects employees from department 3.</li>
              <li>The outer query selects all columns from <code>EmployeeCTE</code>.</li>
            </ul>

            <h2>Recursive CTE</h2>
            <p>Recursive CTEs are used for hierarchical data, such as organizational structures. They are defined using the <code>WITH RECURSIVE</code> keyword.</p>
            <SyntaxHighlighterComponent code={recursiveCTECode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The CTE <code>EmployeeHierarchy</code> starts with employees who have no manager (top-level employees).</li>
              <li>The recursive part joins employees with their managers, building the hierarchy level by level.</li>
              <li>The outer query selects employee details along with their hierarchy level.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_correlated"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_joins"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
