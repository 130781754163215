import React from 'react';
import './styles.css'; // Import your styles

const Msg = ({incomingMsg, sentMsg, msg}) => {
  return (
   
    <div>
      {incomingMsg && (
        <div className='incomingMsgBox'>
          <p className='incomingMsgText'>{msg}</p>
        </div>
      )}
      {sentMsg && (
        <div className='sentMsgBox'> {/* Change to div instead of p */}
          <p className='sentMsgText'>{msg}</p>
        </div>
      )}
    </div>
   
  );
};

export default Msg;
