import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyBlocks() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const blockBasicCode = `
# Basic Block
def greet
  yield
end

greet { puts "Hello, World!" }
# Output: Hello, World!
  `;

  const blockWithParametersCode = `
# Block with Parameters
def greet
  yield("Alice")
end

greet { |name| puts "Hello, \#{name}!" }
# Output: Hello, Alice!
  `;

  const blockWithMultipleYieldsCode = `
# Block with Multiple Yields
def greet
  yield("Alice")
  yield("Bob")
end

greet { |name| puts "Hello, \#{name}!" }
# Output: Hello, Alice!
# Output: Hello, Bob!
  `;

  const blockWithOptionalBlockCode = `
# Method with Optional Block
def greet
  if block_given?
    yield
  else
    puts "Hello!"
  end
end

greet { puts "Hello, Block!" }
greet
# Output: Hello, Block!
# Output: Hello!
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Blocks</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Blocks, Block Parameters, Multiple Yields, Optional Blocks" />
        <meta name="description" content="Learn about blocks in Ruby, including basic blocks, blocks with parameters, multiple yields, and optional blocks." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_methods"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_procs-lambda"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Blocks</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Blocks in Ruby are chunks of code that can be passed to methods and executed. They are a powerful feature that allows for flexible and reusable code.</p>

            <h2>Basic Block</h2>
            <p>A block can be passed to a method and executed using the <code>yield</code> keyword.</p>
            <SyntaxHighlighterComponent code={blockBasicCode} language="ruby" />
            <p>In the above example, the block passed to the <code>greet</code> method is executed using <code>yield</code>.</p>

            <h2>Block with Parameters</h2>
            <p>Blocks can take parameters, which can be passed to the <code>yield</code> statement.</p>
            <SyntaxHighlighterComponent code={blockWithParametersCode} language="perl" />
            <p>In the above example, the block takes a parameter <code>name</code>, which is passed from the <code>yield</code> statement in the <code>greet</code> method.</p>

            <h2>Block with Multiple Yields</h2>
            <p>A method can call <code>yield</code> multiple times to execute the block multiple times.</p>
            <SyntaxHighlighterComponent code={blockWithMultipleYieldsCode} language="perl" />
            <p>In the above example, the block is executed twice, once for each <code>yield</code> statement.</p>

            <h2>Method with Optional Block</h2>
            <p>A method can check if a block is given using the <code>block_given?</code> method and execute the block conditionally.</p>
            <SyntaxHighlighterComponent code={blockWithOptionalBlockCode} language="ruby" />
            <p>In the above example, the <code>greet</code> method executes the block if it is given; otherwise, it prints a default greeting.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_methods"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_procs-lambda"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
