import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactLists() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    import React from 'react';

    class MyList extends React.Component {
      render() {
        const items = ['Apple', 'Banana', 'Orange', 'Mango'];
    
        return (
          <ul>
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        );
      }
    }
    
    export default MyList;
    `

    const code2=`
    import React from 'react';

    const data = ['Item 1', 'Item 2', 'Item 3'];
    
    const List = () => {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    }; `
  
     const code3=`
     import React from 'react';

     const users = [
       { name: 'John Doe', age: 30 },
       { name: 'Jane Doe', age: 25 },
       { name: 'Bob Smith', age: 40 },
     ];
     
     const UserList = () => {
       return (
         <ul>
           {users.map((user, index) => (
             <li key={index}>
               <h2>{user.name}</h2>
               <p>Age: {user.age}</p>
             </li>
           ))}
         </ul>
       );
     };  `

      
        
    return (
   <body>
     <Helmet>
        
        <title>React Lists</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React Lists, Types of Lists, Ordered Lists, Unordered Lists, Nested Lists, Rendering Lists, Displaying a List of Users,
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="In React, a list typically refers to rendering a collection of items from an array or another iterable data structure." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_conditional"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_keys"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React Lists</h1>
</header>

<Navbar />

<main>
<section>
   
<p>In React, a "list" typically refers to rendering a collection of items from an array or another iterable data structure.</p>
  <p> Lists are commonly used when you want to display multiple similar items in a UI, such as a list of products, messages, or users.</p>

<h2>Example</h2>
<p>Here's a basic example of how you might render a list of items using React:</p>
<SyntaxHighlighterComponent code={code} language="js" />

<h2>Types of Lists</h2>

<ol type="1">
 <li><b>Ordered Lists: </b>Used to display data in a specific order, such as a list of steps or a ranking.</li>
 <li><b>Unordered Lists: </b> Used to display data without a specific order, such as a list of options or a collection of items.</li>
 <li><b>Nested Lists: </b>Used to display data with a hierarchical structure, such as a list of categories and subcategories.</li>
 <li>Improving performance by only rendering necessary components</li>
</ol>

<h2>Rendering Lists</h2>
<p>To render a list in React, you can use the map() function to iterate over the data and return a component for each item.</p>
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="js" />

 <h2>Displaying a List of Users</h2>
 <p>Let's say we have a list of users and we want to display their names and ages.</p>
 <p>We can use the map() function to iterate over the list of users and return a component for each user.</p>
 <h2>Example</h2>
 <SyntaxHighlighterComponent code={code3} language="js" />
 <p>In this example, we define a list of users with their names and ages. We then use the map() function to iterate over the list of users and return a li component for each user.</p>
 <p>Inside the li component, we display the user's name and age using h2 and p components.</p>
 <h3><b>Best Practices</b></h3>
 <ul>
    <li>Use a key prop: To help React keep track of the items in the list and improve performance.</li>
    <li>Use a key prop: To help React keep track of the items in the list and improve performance.</li>
    <li>Use a separate component for each item: To make the code more modular and reusable.</li>
 </ul>

 <h3><b>Common Use Cases</b></h3>
 <ul>
    <li>Displaying a list of items: Such as a list of products or a list of users.</li>
    <li>Displaying a list of options: Such as a list of settings or a list of filters.</li>
    <li>Displaying a hierarchical list: Such as a list of categories and subcategories.</li>

 </ul>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_conditional"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_keys"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}