import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLAjaxResponses() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const parse =`
  const response = JSON.parse(xhttp.responseText);
  `;

  const statuscode=`
  if (xhttp.status === 404) {
  console.error("Resource not found");
}
  `;

  const securitymeasures=`
const userInput = document.getElementById("userInput").value;
if (!userInput.match(/^[a-zA-Z0-9]+$/)) {
  console.error("Invalid input");
}
  `;

  const example1 = `
<!DOCTYPE html>
<html>
<body>

<h2>Handling AJAX Responses</h2>
<div id="demo"></div>

<script>
function loadXMLDoc() {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      document.getElementById("demo").innerHTML = this.responseText;
    }
  };
  xhttp.open("GET", "ajax_info.txt", true);
  xhttp.send();
}
loadXMLDoc();
</script>

</body>
</html>
`;

  const example2 = `
<!DOCTYPE html>
<html>
<body>

<h2>Handling XML Response</h2>
<div id="content"></div>

<script>
function loadXML() {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      const xmlDoc = this.responseXML;
      const txt = xmlDoc.getElementsByTagName("message")[0].childNodes[0].nodeValue;
      document.getElementById("content").innerHTML = txt;
    }
  };
  xhttp.open("GET", "message.xml", true);
  xhttp.send();
}
loadXML();
</script>

</body>
</html>
`;

  const example3 = `
<!DOCTYPE html>
<html>
<body>

<h2>AJAX Response Handling</h2>
<div id="output"></div>

<script>
function loadData() {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      document.getElementById("output").innerHTML = this.responseText;
    }
  };
  xhttp.open("GET", "data.txt", true);
  xhttp.send();
}
loadData();
</script>

</body>
</html>
`;

  return (
    <body>
      <Helmet>
        <title>XML AJAX - Responses</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML AJAX Responses, AJAX with XML, XML Tutorial, XML AJAX Examples" />
        <meta name="description" content="Learn how to handle AJAX responses with examples and explanations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_ajax_requests"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_ajax_parsing"><ButtonNext /></ScrollToTopLink>
          <h1>XML AJAX - Responses</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Handling AJAX responses is a crucial part of using AJAX to interact with servers. You can process the server response to update parts of the web page dynamically.</p>
            <p>This tutorial will cover the basics of AJAX responses, including how to parse response data, handle response, handle different HTTP status codes, and implement security measures.</p>
            <h2>Introduction to AJAX Responses</h2>
            <p>The <code>XMLHttpRequest</code> object allows you to retrieve data from a server. Below are examples of how to handle different types of AJAX responses.</p>
            <h2>AJAX Response Methods</h2>

<p>This section covers the different methods used in AJAX responses to handle server responses. The methods include:</p>

<table>
  <thead>
    <tr>
      <th>Method</th>
      <th>Description</th>
      <th>Example</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>responseText</td>
      <td>Returns the response data as a string</td>
      <td><code>var response = xhttp.responseText;</code></td>
    </tr>
    <tr>
      <td>responseXML</td>
      <td>Returns the response data as XML</td>
      <td><code>var response = xhttp.responseXML;</code></td>
    </tr>
    <tr>
      <td>status</td>
      <td>Returns the HTTP status code</td>
      <td><code>var status = xhttp.status;</code></td>
    </tr>
    <tr>
      <td>readyState</td>
      <td>Returns the ready state of the request</td>
      <td><code>var readyState = xhttp.readyState;</code></td>
    </tr>
  </tbody>
</table>

        <h2>Parsing Response Data</h2>
        <p>You can parse response data using the responseText and responseXML properties. Here is an example of how to parse a JSON response:</p>
        <SyntaxHighlighterComponent code={parse} language="js" />
        <p>In this example, we use the JSON.parse method to convert the responseText property to a JSON object.</p>
      
        <h2>Handling HTTP Status Codes</h2>
        <p>You can handle different HTTP status codes using the status property. Here is an example of how to handle a 404 error:</p>
        <SyntaxHighlighterComponent code={statuscode} language="js" />
         <p>In this example, we check the status property to see if the response status code is 404. If it is, we log an error message to the console.</p>

        <h2>Example 1: Handling Plain Text Response</h2>
            <SyntaxHighlighterComponent code={example1} language="html" />
            <p>This example demonstrates how to handle a plain text response from an AJAX request. The <code>loadXMLDoc</code> function fetches the data and updates the web page content.</p>

            <h2>Example 2: Handling XML Response</h2>
            <SyntaxHighlighterComponent code={example2} language="html" />
            <p>In this example, an XML response is fetched and processed. The <code>loadXML</code> function extracts the content from the XML document and displays it.</p>

            <h2>Example 3: Handling JSON Response</h2>
            <SyntaxHighlighterComponent code={example3} language="html" />
            <p>This example shows how to handle a JSON response from an AJAX request. The <code>loadData</code> function processes the JSON response and updates the web page content.</p>


            <h2>Implementing Security Measures</h2>
            <p>You can implement security measures to prevent cross-site scripting (XSS) attacks. Here is an example of how to validate user input:</p>
            <SyntaxHighlighterComponent code={securitymeasures} language="js" />
            <p>In this example, we validate user input by checking if it matches a regular expression. If it doesn't, we log an error message to the console.</p>

            <h2>Benefits of Handling AJAX Responses</h2>
            <ul>
              <li>Allows for dynamic updates to web page content.</li>
              <li>Supports various data formats, such as plain text, XML, and JSON.</li>
              <li>Improves user experience by providing real-time data updates.</li>
            </ul>

            <h2>Common Use Cases</h2>
            <ul>
              <li>Displaying server response data dynamically.</li>
              <li>Updating parts of the web page without reloading.</li>
              <li>Processing and displaying structured data from the server.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_ajax_requests"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_ajax_parsing"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
