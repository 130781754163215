import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../ScrollToTop";
import { useLocation } from "react-router-dom";
import SQLSearchBar from "../Searchbar/SQL SearchBar/SQLSearchBar";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export default function Sidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);
  const location = useLocation();

  const toggle = () => { setIsOpen(!isOpen); };

  const toggleDropdown = () => { setIsDropdownOpen(!isDropdownOpen); };

  const toggleDropdown2 = () => { setIsDropdownOpen2(!isDropdownOpen2); };

  const toggleDropdown3 = () => { setIsDropdownOpen3(!isDropdownOpen3); };

  const toggleDropdown4 = () => { setIsDropdownOpen4(!isDropdownOpen4); };

  const toggleDropdown5 = () => { setIsDropdownOpen5(!isDropdownOpen5); };

  const nav = { color: "white", fontSize: "30px" };

  const newCode = { float: "right" };

  return (
    <div>
      <div className={`sidebar ${isOpen ? "responsive" : ""}`} id="mySidebar">
        <span className="close" onClick={toggle}>&times;</span>
        <h2>SQL Tutorial</h2><br />
        <span><SQLSearchBar /></span><br />
        <p className="p-sidebar">...Empowering Your Online Presence...</p><br />
        <ScrollToTopLink to="/SQL_Tutorial_intro"><a className={location.pathname === "/SQL_Tutorial_intro" ? "active" : ""}>SQL Introduction</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/SQL_comments"><a className={location.pathname === "/SQL_comments" ? "active" : ""}>SQL Comments</a></ScrollToTopLink><br />

      
          <h2>SQL Queries</h2><br /> 
          <ScrollToTopLink to="/SQL_queries"><a className={location.pathname === "/SQL_queries" ? "active" : ""}>SQL Queries</a></ScrollToTopLink><br />
         <ScrollToTopLink to="/SQL_select"><a className={location.pathname === "/SQL_select" ? "active" : ""}>SQL SELECT</a></ScrollToTopLink><br />
         <ScrollToTopLink to="/SQL_insert"><a className={location.pathname === "/SQL_insert" ? "active" : ""}>SQL INSERT</a></ScrollToTopLink><br />
         <ScrollToTopLink to="/SQL_update"><a className={location.pathname === "/SQL_update" ? "active" : ""}>SQL UPDATE</a></ScrollToTopLink><br />
         <ScrollToTopLink to="/SQL_delete"><a className={location.pathname === "/SQL_delete" ? "active" : ""}>SQL DELETE</a></ScrollToTopLink><br />
         

        <ScrollToTopLink to="/SQL_data_types"><a className={location.pathname === "/SQL_data_types" ? "active" : ""}>SQL Data Types</a></ScrollToTopLink><br />

        <div className="dropdown">
          <a className="dropdown-toggle" onClick={toggleDropdown}>SQL Functions {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}</a><br />
          {isDropdownOpen && (
            <div className="dropdown-content">
              <ScrollToTopLink to="/SQL_aggregate_functions"><a className={location.pathname === "/SQL_aggregate_functions" ? "active" : ""}>SQL Aggregate Functions</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/SQL_string_functions"><a className={location.pathname === "/SQL_string_functions" ? "active" : ""}>SQL String Functions</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/SQL_date_functions"><a style={{fontSize: '14px'}} className={location.pathname === "/SQL_date-time_functions" ? "active" : ""}>SQL Date and Time Functions</a></ScrollToTopLink><br />
            </div>
          )}
        </div>

        <div className="dropdown">
          <a className="dropdown-toggle" onClick={toggleDropdown2}>SQL Subqueries {isDropdownOpen2 ? <FaChevronUp /> : <FaChevronDown />}</a><br />
          {isDropdownOpen2 && (
            <div className="dropdown-content">
              <ScrollToTopLink to="/SQL_nested"><a className={location.pathname === "/SQL_nested" ? "active" : ""}>SQL Nested Queries</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/SQL_correlated"><a className={location.pathname === "/SQL_correlated" ? "active" : ""}>SQL Correlated Subqueries</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/SQL_ctes"><a style={{fontSize: '14px'}} className={location.pathname === "/SQL_ctes" ? "active" : "" }>SQL Common Table Expressions (CTEs)</a></ScrollToTopLink><br />
            </div>
          )}
        </div>

          <h2>SQL Joins</h2><br />
          <ScrollToTopLink to="/SQL_joins"><a className={location.pathname === "/SQL_joins" ? "active" : ""}>SQL JOINS</a></ScrollToTopLink><br />
          <ScrollToTopLink to="/SQL_inner_join"><a className={location.pathname === "/SQL_inner_join" ? "active" : ""}>SQL INNER JOIN</a></ScrollToTopLink><br />
          <ScrollToTopLink to="/SQL_left_join"><a className={location.pathname === "/SQL_left_join" ? "active" : ""}>SQL LEFT JOIN</a></ScrollToTopLink><br />
          <ScrollToTopLink to="/SQL_right_join"><a className={location.pathname === "/SQL_right_join" ? "active" : ""}>SQL RIGHT JOIN</a></ScrollToTopLink><br />
          <ScrollToTopLink to="/SQL_full_outer_join"><a className={location.pathname === "/SQL_full_outer_join" ? "active" : ""}>SQL FULL OUTER JOIN</a></ScrollToTopLink><br />
          <ScrollToTopLink to="/SQL_cross_join"><a className={location.pathname === "/SQL_cross_join" ? "active" : ""}>SQL CROSS JOIN</a></ScrollToTopLink><br />
    
          <div className="dropdown">
          <a className="dropdown-toggle" onClick={toggleDropdown3}>SQL Views {isDropdownOpen3 ? <FaChevronUp /> : <FaChevronDown />}</a><br />
          {isDropdownOpen3 && (
            <div className="dropdown-content">
              <ScrollToTopLink to="/SQL_views"><a className={location.pathname === "/SQL_views" ? "active" : ""}>SQL Views</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/SQL_creating_views"><a className={location.pathname === "/SQL_creating_views" ? "active" : ""}>SQL Creating Views</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/SQL_managing_views"><a className={location.pathname === "/SQL_managing_views" ? "active" : ""}>SQL Managing Views</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/SQL_view_optimization"><a className={location.pathname === "/SQL_view_optimization" ? "active" : ""}>SQL View Optimization</a></ScrollToTopLink><br />
            </div>
          )}
        </div>
        <ScrollToTopLink to="/SQL_indexes"><a className={location.pathname === "/SQL_indexes" ? "active" : ""}>SQL Indexes</a></ScrollToTopLink><br />
     
        </div>

      <div className="burger-icon" onClick={toggle}>
        &#9776;<p style={newCode}>Menu</p>
      </div>
    </div>
  );
}
