import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function CSSHeight() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   
    const code = `
        footer {
        height: 100px; /* Set the height to 100 pixels */
        background-color: slategray;
    }
`;
const code2 = `
   .container {
    width: 45%; /* Set the width to 25% of the containing element */
    background-color: #f00;
}

`;
const code3 = `
   .container {
    height: 10vh; /* Set the height to 10% of the viewport height */
    background-color: rgb(134, 20, 255);
}

`;


    return (
   <body>
       <Helmet>     
        <title>CSS Height</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Height, Setting the width of an element using pixels, Setting the width of an element using Percentages, Setting the width of an element using Viewport Units
        Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="The height property in CSS is used to define the vertical dimension of an element.
      It determines how tall the content area of the element should be. Like the width property, the height property can be set in various units." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
   <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/CSS_background_attachment"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>CSS Height</h1>
</header>

<Navbar />

<main>
<section>
<p>The height property in CSS is used to define the vertical dimension of an element.</p>
    <p>It determines how tall the content area of the element should be. Like the width property,
         the height property can be set in various units.</p>
    <p>The value can be set in various units, such as pixels (px), percentages (%), em units (em)
        , viewport height units (vh), and more.</p>
        <br />
        <p>In this example we will set the height of the footer to 100pixels.</p>
     <h2>Example:</h2>
     <SyntaxHighlighterComponent code={code} language="css" />
<ScrollToTopLink to="/try_css32"><Tryitout /></ScrollToTopLink>
     <br />
     <h2>Using Percentages</h2>
     <p>Percentages are a commonly used unit for both width and height. </p>
     <p>When you set the height of an element to a percentage, it's calculated relative 
        to the height of its containing element. This can be useful for creating responsive designs.</p>
        <br /> 
        <SyntaxHighlighterComponent code={code2} language="css" />
<ScrollToTopLink to="/try_css33"><Tryitout /></ScrollToTopLink>
     <br />
     <h2>Using Viewport Units</h2>
     <p>Viewport units are relative to the size of the browser window. 
        This can be particularly handy for creating layouts that adapt well to different screen sizes.</p>
        <br />
        <SyntaxHighlighterComponent code={code3} language="css" />
<Link to="/try_css34"><Tryitout /></Link>
</section>

</main>
<div className="head">
<ScrollToTopLink to="/CSS_background_attachment"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}