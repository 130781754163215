import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonDictionaries() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const code1 = `
# Creating a Dictionary
my_dict = {'name': 'Alice', 'age': 25, 'city': 'New York'}
print(my_dict)  # Output: {'name': 'Alice', 'age': 25, 'city': 'New York'}
    `;

    const code2 = `
# Accessing Items
print(my_dict['name'])  # Output: Alice
print(my_dict.get('age'))  # Output: 25
    `;

    const code3 = `
# Adding or Updating Items
my_dict['email'] = 'alice@example.com'  # Adding
my_dict['age'] = 26  # Updating
print(my_dict)  # Output: {'name': 'Alice', 'age': 26, 'city': 'New York', 'email': 'alice@example.com'}
    `;

    const code4 = `
# Removing Items
del my_dict['city']  # Remove by key
print(my_dict)  # Output: {'name': 'Alice', 'age': 26, 'email': 'alice@example.com'}

removed_value = my_dict.pop('email')  # Remove by key and return the value
print(removed_value)  # Output: alice@example.com
print(my_dict)  # Output: {'name': 'Alice', 'age': 26}

my_dict.clear()  # Remove all items
print(my_dict)  # Output: {}
    `;

    const code5 = `
# Checking if a Key Exists
my_dict = {'name': 'Alice', 'age': 25}
print('name' in my_dict)  # Output: True
print('city' in my_dict)  # Output: False
    `;

    const code6 = `
# Iterating Through a Dictionary
for key in my_dict:
    print(key, my_dict[key])
# Output:
# name Alice
# age 25

for key, value in my_dict.items():
    print(key, value)
# Output:
# name Alice
# age 25
    `;

    const code7 = `
# Getting All Keys, Values, and Items
keys = my_dict.keys()
values = my_dict.values()
items = my_dict.items()

print(keys)  # Output: dict_keys(['name', 'age'])
print(values)  # Output: dict_values(['Alice', 25])
print(items)  # Output: dict_items([('name', 'Alice'), ('age', 25)])
    `;

    const code8 = `
# Copying a Dictionary
new_dict = my_dict.copy()
print(new_dict)  # Output: {'name': 'Alice', 'age': 25}
    `;

    const code9 = `
# Creating a Dictionary with fromkeys()
keys = ('name', 'age', 'city')
default_value = None
new_dict = dict.fromkeys(keys, default_value)
print(new_dict)  # Output: {'name': None, 'age': None, 'city': None}
    `;

    const code10 = `
# Nested Dictionaries
students = {
    'Alice': {'age': 25, 'city': 'New York'},
    'Bob': {'age': 22, 'city': 'Los Angeles'}
}
print(students)
# Output: {'Alice': {'age': 25, 'city': 'New York'}, 'Bob': {'age': 22, 'city': 'Los Angeles'}}
    `;

    return (
        <body>
            <Helmet>
                <title>Python Dictionaries</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Dictionaries, Create, Access, Update, Remove, Iterate, Keys, Values, Items" />
                <meta name="description" content="Learn about Python dictionaries, including creating, accessing, updating, removing, and iterating through items, with various examples." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_set_operations"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_dictionary_methods"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Dictionaries</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Dictionaries in Python are collections of key-value pairs. They are unordered, changeable, and indexed. Each key in a dictionary must be unique, and the values can be of any data type.</p>
                        
                        <h2>Creating a Dictionary</h2>
                        <p>You can create a dictionary by placing a comma-separated sequence of key-value pairs within curly braces <code>{}</code>.</p>
                        <SyntaxHighlighterComponent code={code1} language="python" />

                        <h2>Accessing Items</h2>
                        <p>Access the value associated with a specific key using square brackets or the <code>get()</code> method.</p>
                        <SyntaxHighlighterComponent code={code2} language="python" />

                        <h2>Adding or Updating Items</h2>
                        <p>Add a new key-value pair or update the value of an existing key by using the assignment operator <code>=</code>.</p>
                        <SyntaxHighlighterComponent code={code3} language="python" />

                        <h2>Removing Items</h2>
                        <p>Remove items from a dictionary using the <code>del</code> statement or the <code>pop()</code> method. The <code>clear()</code> method removes all items.</p>
                        <SyntaxHighlighterComponent code={code4} language="python" />

                        <h2>Checking if a Key Exists</h2>
                        <p>Use the <code>in</code> keyword to check if a key exists in the dictionary.</p>
                        <SyntaxHighlighterComponent code={code5} language="python" />

                        <h2>Iterating Through a Dictionary</h2>
                        <p>Loop through a dictionary using a for loop to get keys and values.</p>
                        <SyntaxHighlighterComponent code={code6} language="python" />

                        <h2>Getting All Keys, Values, and Items</h2>
                        <p>Use the <code>keys()</code>, <code>values()</code>, and <code>items()</code> methods to get all the keys, values, and key-value pairs, respectively.</p>
                        <SyntaxHighlighterComponent code={code7} language="python" />

                        <h2>Copying a Dictionary</h2>
                        <p>Create a copy of a dictionary using the <code>copy()</code> method.</p>
                        <SyntaxHighlighterComponent code={code8} language="python" />

                        <h2>Creating a Dictionary with fromkeys()</h2>
                        <p>Use the <code>fromkeys()</code> method to create a dictionary with specified keys and a common default value.</p>
                        <SyntaxHighlighterComponent code={code9} language="python" />

                        <h2>Nested Dictionaries</h2>
                        <p>Create dictionaries within dictionaries to represent complex data structures.</p>
                        <SyntaxHighlighterComponent code={code10} language="python" />

                        <h2>Conclusion</h2>
                        <p>Dictionaries are versatile and powerful data structures in Python. Mastering their methods and operations will allow you to efficiently manage and manipulate data in your programs.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_set_operations"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_dictionary_methods"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
