import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLtextformatting() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
    <!--emphasized text-->
    <em>I am emphasized</em>
    <!--italicized text-->
    <i>I am italicized</i>
    <!--bold text-->
    <b>I am bold</b>
    <!--underlined text-->
    <u>I am underlined</u>
    <!--small text-->
    <small>I am small</small>
    <!--strong text-->
    <strong>I am strong</strong>
    <!--striked text-->
    <s>I am strike</s>
    <!--enquoted text-->
    <q>I am enquoted</q>
    <!--typewriter text text-->
    <tt>I am typewrited</tt>
    <!--keyboard text-->
    <kbd>I am a keyoard text</kbd>
    <!--marked text-->
    <mark>I am marked</mark>
    <!--code text-->
    <code>I am a code</code>
  `;
  
  const code2 = `
  <!--Mixed Formatting-->
  <em><i><b><u><q>I possess formatting.</q></u></b></i></em>
`;
     
    return (
   <body>
    <Helmet>    
        <title>HTML Text Formatting</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Text Formatting, How to format a text, How to format a text in html, Formatting Elements, Mixed Formatting Elements, <em>, <i>, <b>, <u>, <strong>, <small>, <s>, <q>, <tt>, <kbd>, <mark>, <code>,
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="When creating a web page there might be a tendency to format it in different ways.Elements used to format text are inline elements.
       They are some elements used to format text in a lot of ways." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
    
    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_line_break"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_section"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Text Formatting</h1>
</header>

<Navbar />

<main>

<section>
<p>When creating a web page there might be a tendency to format it in different ways.Elements used to format text are inline elements.</p>
<p>They are some elements used to format text in a lot of ways.</p>
<h2>Formatting Elements</h2>
<p>The various elements used to format a text include:</p>
<li>&lt;em&gt; This emphasizes a text</li>
<li>&lt;i&gt; This italicizes a text</li>
<li>&lt;b&gt; This makes a text bold</li>
<li>&lt;u&gt; This underlines a text</li>
<li>&lt;small&gt; This reduces the size of  a text</li>
<li>&lt;strong&gt; This emphasizes a text and can make it bolder</li>
<li>&lt;s&gt; This strikes a line through a text</li>
<li>&lt;q&gt; This enquotes a text</li>
<li>&lt;tt&gt; This typewrites a text</li>
<li>&lt;kbd&gt; This is a keyboard text</li>
<li>&lt;mark&gt; This marks a text</li>
<li>&lt;code&gt; This makes a text a computer code</li>

<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html36"><Tryitout /></ScrollToTopLink>

<h2>Mixed Formatting Elements</h2>
<p>In HTML it is possible to combine different elements to inorder to format a text.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html37"><Tryitout /></ScrollToTopLink>
</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_line_break"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_section"><ButtonNext /></ScrollToTopLink>
</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}