import React from "react";



export default function Formhandler() {

    return (

        <div>
        <p>Congratulations user you have successfully submitted the form.</p>
        <p><strong>Note:</strong> This is not a real form-handler doing that would require programming language
like PHP to handle the form inputs and a database which will store the user data.</p>
        </div>


    )

}