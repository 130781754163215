import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonLambda() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeLambda = `
# Lambda function to add 10 to input
add_ten = lambda x: x + 10
print(add_ten(5))  # Output: 15
    `;

    const codeLambdaMultipleArguments = `
# Lambda function with multiple arguments
multiply = lambda x, y: x * y
print(multiply(2, 3))  # Output: 6
    `;

    const codeLambdaWithMap = `
# Using lambda with map to double the values
nums = [1, 2, 3, 4]
doubled = map(lambda x: x * 2, nums)
print(list(doubled))  # Output: [2, 4, 6, 8]
    `;

    const codeLambdaWithFilter = `
# Using lambda with filter to get even numbers
nums = [1, 2, 3, 4, 5, 6]
evens = filter(lambda x: x % 2 == 0, nums)
print(list(evens))  # Output: [2, 4, 6]
    `;

    const codeLambdaWithSort = `
# Using lambda with sort to sort list of tuples by second element
pairs = [(1, 2), (3, 1), (5, 4), (2, 3)]
sorted_pairs = sorted(pairs, key=lambda x: x[1])
print(sorted_pairs)  # Output: [(3, 1), (1, 2), (2, 3), (5, 4)]
    `;

    return (
        <body>
            <Helmet>
                <title>Python Lambda Functions</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Lambda Functions, Anonymous Functions, Map, Filter, Sort" />
                <meta name="description" content="Learn about lambda functions in Python, including how to define and use them, with examples for common use cases like map, filter, and sort." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_functions"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_arrays"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Lambda Functions</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Lambda functions in Python, also known as anonymous functions, are small and simple functions that are defined using the <code>lambda</code> keyword.</p> 
                        <p>These functions are often used for short-term, throwaway tasks.</p>
                        
                        <h2>Basic Lambda Function</h2>
                        <p>A lambda function can take any number of arguments but can only have one expression. The syntax is:</p>
                        <SyntaxHighlighterComponent code={codeLambda} language="python" />
                        
                        <h2>Lambda Function with Multiple Arguments</h2>
                        <p>Lambda functions can have multiple arguments separated by commas.</p>
                        <SyntaxHighlighterComponent code={codeLambdaMultipleArguments} language="python" />
                        
                        <h2>Using Lambda with Map</h2>
                        <p>The <code>map</code> function applies a given function to all items in an input list.</p>
                        <SyntaxHighlighterComponent code={codeLambdaWithMap} language="python" />
                        
                        <h2>Using Lambda with Filter</h2>
                        <p>The <code>filter</code> function constructs a list of items from an iterable for which a function returns true.</p>
                        <SyntaxHighlighterComponent code={codeLambdaWithFilter} language="python" />
                        
                        <h2>Using Lambda with Sort</h2>
                        <p>The <code>sorted</code> function can be used with a lambda function to sort items based on a key.</p>
                        <SyntaxHighlighterComponent code={codeLambdaWithSort} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Lambda functions are useful for small, simple operations where a full function definition would be overkill. This tutorial covered defining lambda functions, using them with map, filter, and sort, and examples to illustrate these concepts.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_functions"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_arrays"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
