import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import XMLMetatag from "../../components/XML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function XMLElements() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    <book>
    <title>Harry Potter</title>
    <author>J.K. Rowling</author>
    </book>
    `;
    const code2 = `
    <book category="fantasy">
    <title>Lord of the Rings</title>
    <author>J.R.R. Tolkien</author>
    </book>

    `;
    const code3 = `
    <library>
    <book>
        <title>War and Peace</title>
        <author>Leo Tolstoy</author>
    </book>
    <book>
        <title>Pride and Prejudice</title>
        <author>Jane Austen</author>
    </book>
    </library>
    `;
    const code4 = `
    <image source="photo.jpg">
    `;
    const code5 = `
    <!-- I am a comment -->
    `;
    return (
   <body>
   <Helmet>
        
        <title>XML Elements</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="XML Elements, XML Step-by-step Python programming tutorials XML coding exercises for beginners, Advanced XML coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       XML programming tips for real-world applications," />
      <meta name="description" content="Learn how XML elements streamline code organization, 
      enhance readability, and unlock advanced features in PHP development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/XML_tree"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/XML_attributes"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>XML Elements</h1>
</header>

<Navbar />

<main>
<section>
<p>XML elements are the fundamental building blocks of an XML document.</p>
<p>They define the structure and content of the data being represented.
     Each element consists of a start tag, an end tag, and the content between them.
</p>
<p> Let's explore XML elements in more detail:</p>
<h2>Start Tag</h2>
<p>The start tag marks the beginning of an XML element. It consists of the element name enclosed
     in angle brackets (&lt; and &gt;). </p>

<p>The element name is case-sensitive and must follow naming rules, including starting with a
     letter or underscore.
</p>

<h2>Elements</h2>
<p>Elements are the building blocks of an XML document. </p>
<p>They consist of a start tag, content (which can be text, nested elements, or a combination), and an end tag.</p>
  <br />
  <h2>Example:</h2>
  <SyntaxHighlighterComponent code={code} language="xml" />
      <h2>Attributes</h2>
        <p>Attributes provide additional information about an element. </p>
        <p>They're part of the start tag and consist of a name-value pair.</p>
        
        <br />
        <h2>Example:</h2>
        <SyntaxHighlighterComponent code={code2} language="xml" />
        <br />
      
<h2>Nesting</h2>
<p>XML elements can be nested within each other, creating a hierarchical structure.  </p>
<p>This nesting is fundamental to organizing and representing complex data.</p>

<br />
<h2>Example:</h2>
  
  <SyntaxHighlighterComponent code={code3} language="xml" />
<br />
  <h2>Empty Elements</h2>
  <p>Some elements don't have content and can be self-closed using a forward slash within the start tag.</p>
    <br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code4} language="xml" />
<br />
 
</section>
</main>
<div className="head">
<ScrollToTopLink to="/XML_tree"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/XML_attributes"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}