import React from "react";
import { Link } from 'react-router-dom';
import ScrollToTopLink from "./ScrollToTop";

function Nullnav() {
    const navStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        backgroundColor: 'white',
        zIndex: '1000', // Ensure it's on top of other elements
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    };

    const betaLogo = {
        width: '100%',
        height: '70px',
        cursor: 'pointer',
    };

    return (
        <nav style={navStyle}>
            <ScrollToTopLink to="/">
                <img src={require('../image/beta thread.svg').default} title="Beta Thread Home" alt="BetaThread's Logo" style={betaLogo} />
            </ScrollToTopLink>
        </nav>
    );
}

export default Nullnav;
