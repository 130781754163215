import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLCrossJoin() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const crossJoinCode = `
-- CROSS JOIN Example
SELECT employees.employee_name, departments.department_name
FROM employees
CROSS JOIN departments;
  `;

  const employeesTable = `
CREATE TABLE employees (
    employee_id INT PRIMARY KEY,
    employee_name VARCHAR(100),
    department_id INT
);

INSERT INTO employees (employee_id, employee_name, department_id) VALUES
(1, 'John Doe', 1),
(2, 'Jane Smith', 2),
(3, 'Emily Davis', 1),
(4, 'Michael Brown', 3);
  `;

  const departmentsTable = `
CREATE TABLE departments (
    department_id INT PRIMARY KEY,
    department_name VARCHAR(100)
);

INSERT INTO departments (department_id, department_name) VALUES
(1, 'Human Resources'),
(2, 'Finance'),
(3, 'IT');
  `;

  return (
    <body>
      <Helmet>
        <title>SQL CROSS JOIN</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL CROSS JOIN, SQL JOIN Example, SQL Tutorial, CROSS JOIN Syntax" />
        <meta name="description" content="Learn about SQL CROSS JOIN, including syntax, examples, and how to use CROSS JOIN to combine data from multiple tables." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_full_outer_join"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_views"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL CROSS JOIN</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>A <code>CROSS JOIN</code> returns the Cartesian product of the two tables involved in the join. This means it will return all possible combinations of rows from the tables.</p>

            <h2>Example Tables</h2>
            <p>Consider the following tables:</p>
            <SyntaxHighlighterComponent code={employeesTable} language="sql" />
            <SyntaxHighlighterComponent code={departmentsTable} language="sql" />

            <h2>CROSS JOIN Example</h2>
            <p>To retrieve all combinations of employees and departments, we use a <code>CROSS JOIN</code>:</p>
            <SyntaxHighlighterComponent code={crossJoinCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The query selects every combination of <code>employee_name</code> and <code>department_name</code> from the <code>employees</code> and <code>departments</code> tables.</li>
            </ul>

            <h2>Result of the CROSS JOIN</h2>
            <p>If we execute the above query on the sample tables, we would get the following result:</p>
            <table>
              <thead>
                <tr>
                  <th>employee_name</th>
                  <th>department_name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>John Doe</td>
                  <td>Human Resources</td>
                </tr>
                <tr>
                  <td>John Doe</td>
                  <td>Finance</td>
                </tr>
                <tr>
                  <td>John Doe</td>
                  <td>IT</td>
                </tr>
                <tr>
                  <td>Jane Smith</td>
                  <td>Human Resources</td>
                </tr>
                <tr>
                  <td>Jane Smith</td>
                  <td>Finance</td>
                </tr>
                <tr>
                  <td>Jane Smith</td>
                  <td>IT</td>
                </tr>
                <tr>
                  <td>Emily Davis</td>
                  <td>Human Resources</td>
                </tr>
                <tr>
                  <td>Emily Davis</td>
                  <td>Finance</td>
                </tr>
                <tr>
                  <td>Emily Davis</td>
                  <td>IT</td>
                </tr>
                <tr>
                  <td>Michael Brown</td>
                  <td>Human Resources</td>
                </tr>
                <tr>
                  <td>Michael Brown</td>
                  <td>Finance</td>
                </tr>
                <tr>
                  <td>Michael Brown</td>
                  <td>IT</td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_full_outer_join"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_views"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
