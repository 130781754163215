import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyClasses() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const basicClassCode = `
# Basic Class in Ruby
class Person
  def initialize(name, age)
    @name = name
    @age = age
  end

  def display_details
    puts "Name: \#{@name}, Age: \#{@age}"
  end
end

person = Person.new("Alice", 30)
person.display_details  # Output: Name: Alice, Age: 30
  `;

  const inheritanceCode = `
# Inheritance in Ruby
class Animal
  def speak
    puts "Generic animal sound"
  end
end

class Dog < Animal
  def speak
    puts "Bark!"
  end
end

dog = Dog.new
dog.speak  # Output: Bark!
  `;

  const modulesCode = `
# Modules in Ruby
module Walkable
  def walk
    puts "I am walking"
  end
end

class Person
  include Walkable

  def initialize(name)
    @name = name
  end
end

person = Person.new("Alice")
person.walk  # Output: I am walking
  `;

  const metaprogrammingCode = `
# Metaprogramming in Ruby
class Person
  attr_accessor :name, :age

  def initialize(name, age)
    @name = name
    @age = age
  end
end

person = Person.new("Alice", 30)
puts person.name  # Output: Alice
person.name = "Bob"
puts person.name  # Output: Bob
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Classes</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Classes, Basic Classes, Inheritance, Modules, Metaprogramming" />
        <meta name="description" content="Learn about classes in Ruby, including basic classes, inheritance, modules, and metaprogramming." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_syntax"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_comments"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Classes</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>In Ruby, classes are the blueprint for objects. They encapsulate data and behavior that objects instantiated from the class can use. Let's explore the different types of classes and their syntax.</p>

            <h2>Basic Class</h2>
            <p>A basic class in Ruby is defined using the <code>class</code> keyword. Inside the class, you can define methods and variables.</p>
            <SyntaxHighlighterComponent code={basicClassCode} language="perl" />
            <p>In this example:</p>
            <ul>
              <li>The <code>Person</code> class has an <code>initialize</code> method to set up the initial state of the object.</li>
              <li>The <code>display_details</code> method prints the details of the person.</li>
            </ul>

            <h2>Inheritance</h2>
            <p>Inheritance allows a class to inherit behavior from another class. The class that is inherited from is called the superclass, and the class that inherits is called the subclass. In Ruby, inheritance is achieved using the <code>&lt;</code> symbol.</p>
            <SyntaxHighlighterComponent code={inheritanceCode} language="ruby" />
            <p>In this example:</p>
            <ul>
              <li>The <code>Animal</code> class has a method <code>speak</code>.</li>
              <li>The <code>Dog</code> class inherits from <code>Animal</code> and overrides the <code>speak</code> method.</li>
            </ul>

            <h2>Modules</h2>
            <p>Modules in Ruby are a way of grouping together methods, classes, and constants. Modules cannot be instantiated. They are used to share reusable code across multiple classes through mixins using the <code>include</code> keyword.</p>
            <SyntaxHighlighterComponent code={modulesCode} language="ruby" />
            <p>In this example:</p>
            <ul>
              <li>The <code>Walkable</code> module defines a method <code>walk</code>.</li>
              <li>The <code>Person</code> class includes the <code>Walkable</code> module, allowing instances of <code>Person</code> to use the <code>walk</code> method.</li>
            </ul>

            <h2>Metaprogramming</h2>
            <p>Metaprogramming in Ruby refers to the technique of writing code that writes code. This allows you to dynamically define methods and manipulate classes and objects. A common metaprogramming feature in Ruby is the use of <code>attr_accessor</code> to automatically create getter and setter methods for instance variables.</p>
            <SyntaxHighlighterComponent code={metaprogrammingCode} language="ruby" />
            <p>In this example:</p>
            <ul>
              <li>The <code>Person</code> class uses <code>attr_accessor</code> to create getter and setter methods for <code>name</code> and <code>age</code>.</li>
              <li>This allows you to read and modify the <code>name</code> attribute directly.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_syntax"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_comments"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
