import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function CSSsyntax() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   
    const code = `
    p {   /* This is the selector */
    font-size: 38px; /* This is the declaration block */
    color: red;     
  }
      `;
     const code2 = `
     font-size: 30px;
 /*property*/  /*value*/
     `;
  
    return (
   <body>
     <Helmet>     
        <title>CSS Syntax</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Syntax, CSS Syntax Example, CSS declaration block, CSS selector, 
        Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="Every CSS ruleset is comprised of a selector and a declaration block.
      The selector is used to target the element we want to style.The declaration blocks contains CSS declaration which is then wrapped byopening and closing curly braces.
      The declarations are composed of property and value pairs seperated by a colon." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/CSS_comments"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_selectors"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>CSS Syntax</h1>
</header>

<Navbar />

<main>
<section>
<p>Every CSS ruleset is comprised of a selector and a declaration block.</p>
    
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="css" />
<ScrollToTopLink to="/try_css6"><Tryitout /></ScrollToTopLink>
    <p>The selector is used to target the element we want to style.In the above example the selector is the &lt;p&gt;
        tag
    </p>
    <p>The declaration blocks contains CSS declaration which is then wrapped by opening and closing curly braces{}.</p>
    <p>The declarations are composed of property and value pairs seperated by a colon.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="css" />

     <br />
    <li>property: This is an identifier that specifies which stylistic feature to change.</li>
    <li>value: This is a property which indicates how the stylistic feature should be changed.</li>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/CSS_comments"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_selectors"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}