import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLjs() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
        <!DOCTYPE html>
        <html>
        <head>
        <title>Try it yourself</title>
        </head>
        <body>
        <script type="text/javascript">
         // all javascript code is meant to be here
         </script>
        </body>
        </html>
  `;
  const code2 = `
        <p id="demo">Hello World! </p>
        <button onclick="transformStyles();">ChangeStyles</button>
        <script type="text/javascript">
          function transformStyles() {
            document.getElementById("demo").style.color = "blue";
            document.getElementById("demo").style.fontSize = "30px";
          }
        </script>


`;
const code3 = `
<img src="a monitor.jpg" id="demo">
<button onclick="manipulateImage();">Manipulate Image</button>
<script type="text/javascript">
  function manipulateImage() {
    document.getElementById("demo").src = "A Ship.jpg";
  }
</script>
`;
const code4 = `
<p id="demo">I love JavaScript</p>
<button onclick="myFunction()">Click me!</button>
<script type="text/javascript">
function myFunction() {
var x = document.getElementById("demo").innerHTML = "I love Beta Thread";
document.write(x)
}
</script>
`;

const code5 = `
<div id="demo" style="height:100px; width:100px; background-color:teal; color:white;text-align:center">I love Beta Thread</div>
<button onclick="hideContent()">Hide Content</button>
<button onclick="showContent()">Show Content</button>
<script type="text/javascript">
    function hideContent() {
    document.getElementById("demo").style.display = "none";
    }
    function showContent() {
    document.getElementById("demo").style.display = "block";
    }
</script>
    
`;

    return (
   <body>
      <Helmet>    
        <title>HTML JavaScript</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML JavaScript, JavaScript in HTML, How to add javascript to html, Simple JavaScript Structure, Applying Dynamic Styles, Manipulating Images, Applying Dynamic Content, Showing and Hiding
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="In this lesson we will learn how to use HTML and JavaScript together.
        JavaScript is a scripting language which is used to make web pages more interactive." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_forms_attr2"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_input"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML JavaScript</h1>
</header>

<Navbar />

<main>

<section>
<p>In this lesson we will learn how to use HTML and JavaScript together.</p>
<p>JavaScript is a scripting language which is used to make web pages more interactive.</p>
<p>There are many uses of JS such as dynamic effects, form validation, dynamic content etc.</p>
<p>JavaScript can be used dynamically in HTML forms and input types in the next lesson we will learn how to make
    use of javascript in HTML input types.
</p>
<h2>JavaScript in HTML</h2>
<p>Inorder to add JS into your HTML code there are two ways to do that.They include:</p>
<li>Make use of the src attribute of the &lt;script&gt; tag that points to the location of another 
    JS file e.g, &lt;script src="script.js"&gt; &lt;/script&gt;.</li>
<li>Make use of the &lt;script type="text/javascript"&gt; element indicating that the content inside is
     javascript.
</li>
<h3><strong>NOTE:</strong></h3>
<p>Always remember that javascript files are saved with the .js extension for example, script.js, index.js</p>
<h2>Simple JavaScript Structure</h2>
  <br />
<SyntaxHighlighterComponent code={code1} language="html" />


<p>For most of our javascript examples we will be making use of the document.getElementById("id") to get
    an element by its id.
  </p>
<p>JavaScript can be used in different ways and we are going to display an example for each of them.</p>
  <h2>Applying Dynamic Styles</h2>
  <p>Javascript can be used to add dynamic styles to HTML elements.</p>
  
<br />
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html75"><Tryitout /></ScrollToTopLink>
</section>

<section>
<h2>Manipulating Images</h2>
<p>Javascript can be used to manipulate images.</p>
  <br />
  <SyntaxHighlighterComponent code={code3} language="html" />
  <ScrollToTopLink to="/try_html76"><Tryitout /></ScrollToTopLink>

  <h2>Applying Dynamic Content</h2>
<p>Javascript can be used to add dynamic content to HTML elements.</p>
      <br />
      <SyntaxHighlighterComponent code={code4} language="html" />
<ScrollToTopLink to="/try_html77"><Tryitout /></ScrollToTopLink>

<h2>Showing and Hiding</h2>
<p>Javascript can be used to show and hide content.</p>
<br />
    <SyntaxHighlighterComponent code={code5} language="html" />
<ScrollToTopLink to="/try_html78"><Tryitout /></ScrollToTopLink>

<br />

</section>
<div className="head">
<ScrollToTopLink to="/HTML_forms_attr2"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_input"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}