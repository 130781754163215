import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLinput2() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <input type="datetime-local" />
      <input type="submit" />
  `;
  const code2 = `
  <label for="Male">Male
  <input type="radio" name="male" value="Male" />
  </label>
  <label for="Female">Female
  <input type="radio" name="female" value="Female" />
  </label>

 
`;
const code3 = `
<input type="search" />
<input type="submit" />
`;
const code4 = `
<input type="hidden" />
<input type="submit" />
`;

const code5 = `
<input type="url" placeholder="Enter your url" />
<input type="submit" />
`;
const code6 = `
<input type="range" />
<input type="submit" />
`;

const code7 = `
<input type="week" />
<input type="submit" />
`;

const code8 = `
<input type="password" />
<input type="submit" />
`;

const code9 = `
<input type="color" />
<input type="submit" />
`;

    return (
   <body>
      <Helmet>    
        <title>HTML5 Input Types 2</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML5 Input Types 2, What is an input type, Datetime Local, Radio, search, Hidden, URL, Range, Week, Password, Color
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications." />
       <meta name="description" content="In the last lesson we mentioned some input types and explained them, now we will discuss further on some more HTML5 input types." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_input"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML5 Input Types 2</h1>
</header>

<Navbar />

<main>

<section>
<p>In the last lesson we mentioned some input types and explained them, now we will discuss further on some
    more HTML5 input types.
  </p>
 
  <h3>Datetime Local</h3>
  <p>This control allows you to select a date(month,year,day) along with your local time.</p>
  <br />
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html87"><Tryitout /></ScrollToTopLink>

<h3>Radio</h3>
    <p>This control provides muliple choices for you to select a single option.</p>
<br />
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html88"><Tryitout /></ScrollToTopLink>
</section>

<section>

<h3>search</h3>
    <p>This control provides a single-line search field.</p>
  <br />
  <SyntaxHighlighterComponent code={code3} language="html" />
  <ScrollToTopLink to="/try_html89"><Tryitout /></ScrollToTopLink>

  <h3>Hidden</h3>
    <p>This control indicates than an input field should be hidden.</p>
      <br />
      <SyntaxHighlighterComponent code={code4} language="html" />
<ScrollToTopLink to="/try_html90"><Tryitout /></ScrollToTopLink>

<h3>URL</h3>
    <p>This control displays a text field where a URL(Uniform Resource Location) can be typed or placed.</p>
<br />
    <SyntaxHighlighterComponent code={code5} language="html" />
<ScrollToTopLink to="/try_html91"><Tryitout /></ScrollToTopLink>

<h3>Range</h3>
    <p>This control displays a range this can be used when you want to select a number without
         typing its actual values.
    </p>
<br />
    <SyntaxHighlighterComponent code={code6} language="html" />
<ScrollToTopLink to="/try_html92"><Tryitout /></ScrollToTopLink>

<h3>Week</h3>
      <p>This control is used for entering a date which consists of a week number and a year.</p>
<br />
    <SyntaxHighlighterComponent code={code7} language="html" />
<ScrollToTopLink to="/try_html93"><Tryitout /></ScrollToTopLink>

<h3>Password</h3>
        <p>This indicates a text field for you to input a password in which the value is hidden or obscured.</p>
<br />
    <SyntaxHighlighterComponent code={code8} language="html" />
<ScrollToTopLink to="/try_html94"><Tryitout /></ScrollToTopLink>

<h3>Color</h3>
          <p>HTML5 introduces a color picker which returns a hexadecimal color code.</p>
<br />
    <SyntaxHighlighterComponent code={code9} language="html" />
<ScrollToTopLink to="/try_html95"><Tryitout /></ScrollToTopLink>


</section>
<div className="head">
<ScrollToTopLink to="/HTML_input"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}