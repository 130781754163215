import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ScrollToTopLink from '../../ScrollToTop';
import "../TutorialSearchBar.css";
import "../../../css/navigation.css";
import { FaSearch, FaTimes } from 'react-icons/fa';
import routes from './xmlroutes';

export default function XMLSearchBar() {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
  const searchBarRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchBarRef.current && searchBarRef.current.contains(event.target)) {
      return;
    }

  };

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    const filteredSuggestions = routes.filter((route) =>
      route.name.toLowerCase().includes(newSearchTerm.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
    setIsSuggestionsVisible(true);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion.name);
    setIsSuggestionsVisible(false);
    navigate(suggestion.path);
  };

  return (
    <div className="html-custom-search-bar" ref={searchBarRef}>
      <input
        type="search"
        placeholder="Search... e.g, xml tutorial"
        value={searchTerm}
        onChange={handleInputChange}
        className="html-search-input"
      />
      {isSuggestionsVisible && (
        <ul className="html-suggestions">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              <ScrollToTopLink to={suggestion.path} className="html-suggestion-link">
                {suggestion.name}
              </ScrollToTopLink>
            </li>
          ))}
        </ul>
      )}

      {searchTerm && (
        <i
          className="html-search-close-icon"
          title='Clear'
          onClick={() => {
            setSearchTerm('');
            setIsSuggestionsVisible(false);
          }}
        >
          <FaTimes />
        </i>
      )}
      
      <i className="html-search-bar-icon" title='Search'><FaSearch /></i>
    </div>
  );
}
