import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from "../css/styles.module.css";
import "../css/success-modal.css";
import { saveAuthStatus } from "../localstorage";
import ScrollToTopLink from "../components/ScrollToTop";
import { FaEnvelope, FaEye, FaEyeSlash, FaLock, FaTimes } from "react-icons/fa";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false); // State to manage "Remember me" checkbox
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous route
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      alert("Please fill out both email and password fields");
      return;
    }
    try {
      const response = await fetch("https://betathread.com/auth/login", {
        method: "POST",
        body: JSON.stringify({
          email,
          password,
          rememberMe
        }),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include'
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.error_message || "Login failed. Please check your credentials and try again.");
      }
  
      alert(data.message);
  
      // Save authentication status to local storage
      saveAuthStatus('_id', true);
  
      // Redirect to the dashboard
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      const errorMessage = error.message || "Login failed. Please check your credentials and try again.";
      alert(errorMessage);
    }
  };
  
  return (
    <body>
      <Helmet>
        <title>Login | Beta Thread</title>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-compatible" content="IE-edge" />
        <meta name="Keywords" content="Login Page, login in to Beta Thread, HTML, Python, CSS, SQL, JavaScript, How to, PHP, XML, MySQL, React, AI, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, Examples, Learn to code, Coding the future, Source code, Website" />
        <meta name="description" content="Login to your account on the login page of Beta Thread" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      </Helmet>

      <div className={styles.logoContainer}>
        <ScrollToTopLink to="/">
          <img src="./images/betathread-light.png" className={styles.homebtn} alt="Home Page" />
        </ScrollToTopLink>
      </div>
      <div className={styles.closeIconContainer}>
        <button className={styles.closebtn} onClick={handleGoBack}>
          <i className="material-icons"><FaTimes /></i>
        </button>
      </div>
      <div className={styles.container}>
        <div className={styles.form_container}>
          <div className={styles.left}>
            <img className={styles.img} src="./images/login.jpg" alt="login" />
          </div>

          <div className={styles.right}>
            <h2 className={styles.heading}>Welcome Back!</h2><br />
            <p className={styles.text}>
              Don't have an account? <ScrollToTopLink to="/signup">Sign up</ScrollToTopLink>
            </p>
            <form onSubmit={handleSubmit}>
              <div method="post">
                <div className="input-cnt">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    className="input"
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <i className="material-icons"><FaEnvelope /></i>
                </div>
                <div className="input-cnt">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={password}
                    className="input"
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <i
                    className="material-icons"
                    id="beta"
                    title="show or hide password"
                    onClick={handleTogglePassword}
                    style={{ cursor: 'pointer' }}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </i>
                  <i className="material-icons"><FaLock /></i>
                </div>
                <br />
           
                <label style={{ color: 'black' }}>
                  <input
                    type="checkbox"
                    name="remember"
                    checked={rememberMe} // Set checked state based on rememberMe state
                    onChange={(e) => setRememberMe(e.target.checked)} // Update rememberMe state
                    style={{ marginBottom: "15px" }}
                  />
                  Remember Password
               
                </label>
                <br />
                <span style={{ display: "grid", gridTemplateColumns: "auto auto", gridGap: "12px" }}>
               
                <button className="button"
                  style={{ backgroundColor: "darkblue", color: "white", width: "100%", borderRadius: "10px", fontSize: "14px", fontWeight: "normal", fontFamily: "inherit" }}
                  type="submit">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </button>

                <ScrollToTopLink to="/forgot-password"
                  className="button"
                  style={{ backgroundColor: "white", color: "black", width: "100%", borderRadius: "10px", border: "1px solid #aaa", fontSize: "12px", textAlign: "center", fontWeight: "normal",
                   fontFamily: "inherit" }}
                  type="submit"
                >
                  Forgot Password?
              
                </ScrollToTopLink>
                </span>
                <br />
              </div>
            </form>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Login;
