import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPfunctions() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    <?php
    // Function declaration with parameters
    function addNumbers($num1, $num2) {
        $sum = $num1 + $num2;
        return $sum;
    }
    
    // Function call
    $result = addNumbers(5, 3);
    echo "Result: " . $result; // Output: Result: 8
    ?>
       }
        `;
    const code2 = `
    <?php
    // Function declaration with parameters
    function subNumbers($num1, $num2) {
        $difference = $num1 - $num2;
        return $difference;
    }
    
    // Function call
    $result = subNumbers(10, 2);
    print "Result: " . $result; // Output: Result: 8
    ?>
       }
          `;
    const code3 = `
    <?php
    // Function declaration without parameters
    function greet() {
        echo "Hello, World!";
    }
    
    // Function call
    greet(); // Output: Hello, World!
    ?>
      `;
    const code4 = `
    $fruits = array("Apple", "Banana", "Orange");
    foreach ($fruits as $fruit) {
        echo $fruit . "<br>";
    }
       `;

    return (
   <body>
   <Helmet>
        <title>PHP Functions</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Functions, Functions in PHP, Componets of a PHP Function, PHP Function Examples,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="PHP functions are block of code that can be called and executed whenever needed." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_loops"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_functions"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Functions</h1>
</header>

<Navbar />

<main>
<section>
<p>PHP functions are block of code that can be called and executed whenever needed.</p>
<p>Functions allow you to organize your code into reusable components, making your code more modular, readable, and easier to maintain.</p>
<p>Functions can take inputs (parameters), perform some operations, and optionally return a result.</p>

<h2>Componets of a PHP Function</h2>
<p>Here's a breakdown of the components of a PHP function:</p>
<ul>
    <li>Function Declaration: This is where you define the function. It includes the function keyword, followed by the
     function name, parentheses for parameters (if any), and curly braces {} containing the code to be executed when the function is called.</li>
<br />
    <li>Parameters: Parameters are variables that are listed as part of the function declaration. They act as placeholders for values
         that are passed to the function when it is called. Parameters are optional, and a function may have none, one, or multiple parameters.</li>
<br />
    <li>Return Statement: Functions can optionally return a value back to the code that called them. This is done using the return statement.</li>
</ul>

<h2>Example</h2>
<p>Here's a basic example of a PHP function:</p>
<SyntaxHighlighterComponent code={code} language="php" />
<p>In this example:</p>
<ul>
    <li>The function 'addNumbers' takes two parameters, '$num1' and '$num2'.</li>
    <li>Inside the function, the values of '$num1' and '$num2' are added together and stored in '$sum'.</li>
    <li>The 'return' statement returns the value of '$sum' back to the code that called the function.</li>
    <li>Finally, the function is called with the values 5 and 3, and the result is echoed out.</li>
</ul>

<h2>Example</h2>
<p>Here's another example of a PHP function:</p>
<SyntaxHighlighterComponent code={code2} language="php" />
<p>In this example:</p>
<ul>
    <li>The function 'subNumbers' takes two parameters, '$num1' and '$num2'.</li>
    <li>Inside the function, the values of '$num1' and '$num2' are subtracted together and stored in '$difference'.</li>
    <li>The 'return' statement returns the value of '$differnce' back to the code that called the function.</li>
    <li>Finally, the function is called with the values 10 and 2, and the result is printed out.</li>
</ul>


<p>Here's another example without parameters:</p>
<SyntaxHighlighterComponent code={code3} language="php" />
<p>In this example, the 'greet' function doesn't accept any parameters. It simply echoes out "Hello, World!" when called.</p>
<ul>
    <li>If '$day' is 'Monday', it will output 'Wash clothes'.</li>
    <li>If '$day' is 'Tuesday', 'Wednesday', or Thursday', it will output 'Work on projects'.</li>
    <li>If '$day' is 'Friday', it will output 'Go grocery shopping'.</li>
    <li>For any other value of '$day', it will output 'Relax'.</li>
</ul>
<p>This is how the switch statement can be used for decision-making in PHP, providing a cleaner alternative to nested if-else statements in certain scenarios.</p>

</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_loops"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_functions"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}