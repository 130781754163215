import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyHashes() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const hashCreationCode = `
# Creating Hashes
empty_hash = {}
person = { name: 'Alice', age: 30, city: 'New York' }

puts empty_hash
puts person
# Output:
# {}
# {:name=>"Alice", :age=>30, :city=>"New York"}
  `;

  const hashAccessCode = `
# Accessing Hash Elements
person = { name: 'Alice', age: 30, city: 'New York' }

puts person[:name]  # Output: Alice
puts person[:age]   # Output: 30
puts person[:city]  # Output: New York
  `;

  const hashModificationCode = `
# Modifying Hashes
person = { name: 'Alice', age: 30, city: 'New York' }

# Adding a new key-value pair
person[:occupation] = 'Engineer'

# Updating an existing value
person[:age] = 31

# Deleting a key-value pair
person.delete(:city)

puts person
# Output: {:name=>"Alice", :age=>31, :occupation=>"Engineer"}
  `;

  const hashIterationCode = `
# Iterating Over Hashes
person = { name: 'Alice', age: 30, city: 'New York' }

person.each do |key, value|
  puts "\#{key}: \#{value}"
end

# Output:
# name: Alice
# age: 30
# city: New York
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Hashes</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Hashes, Creating Hashes, Accessing Hash Elements, Modifying Hashes, Iterating Over Hashes" />
        <meta name="description" content="Learn about hashes in Ruby, including how to create, access, modify, and iterate over hashes." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_array_methods"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_hash_methods"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Hashes</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Hashes in Ruby, also known as dictionaries, are collections of key-value pairs. They are similar to arrays but use keys instead of indices to access values.</p>

            <h2>Creating Hashes</h2>
            <p>Hashes can be created using the literal constructor <code>{}</code> or the <code>Hash.new</code> method.</p>
            <SyntaxHighlighterComponent code={hashCreationCode} language="ruby" />
            <p>In the above example, we create an empty hash and a hash representing a person with keys for name, age, and city.</p>

            <h2>Accessing Hash Elements</h2>
            <p>Hash elements can be accessed using their keys.</p>
            <SyntaxHighlighterComponent code={hashAccessCode} language="ruby" />
            <p>In the above example, we access the values of the hash using the keys <code>[:name]</code>, <code>[:age]</code>, and <code>[:city]</code>.</p>

            <h2>Modifying Hashes</h2>
            <p>Hashes can be modified by adding, updating, and deleting key-value pairs.</p>
            <SyntaxHighlighterComponent code={hashModificationCode} language="ruby" />
            <p>In the above example, we demonstrate adding a new key-value pair for occupation, updating the age, and deleting the city key-value pair.</p>

            <h2>Iterating Over Hashes</h2>
            <p>Ruby provides several methods to iterate over hashes. The most common method is <code>each</code>.</p>
            <SyntaxHighlighterComponent code={hashIterationCode} language="perl" />
            <p>In the above example, we use the <code>each</code> method to iterate over the key-value pairs in the hash and print each pair.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_array_methods"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_hash_methods"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
