import React from 'react';
import '../css/my style.css';
import '../css/my style2.css';
import '../css/my style6.css';


 function Header() {

const headerTermsPrivacy = {
    padding: '140px',
    textAlign: 'center',
    background: 'darkblue',
    textAlign: 'center'
}
 const headerTermsPrivacyh1 = {
    fontSize: '50px',
    color: 'white',
    textAlign: 'center'
 }
    return (
    <header style={headerTermsPrivacy}>
     <div className="header-content">
     <div className="centered-content">
   <h1 className="topple" style={headerTermsPrivacyh1}>
  
        <span>P</span>
        <span>r</span>
        <span>i</span>
        <span>v</span>
        <span>a</span>
        <span>c</span>
        <span>y</span><br />
        <span>P</span>
        <span>o</span>
        <span>l</span>
        <span>i</span>
        <span>c</span>
        <span>y</span>
  
  </h1>
   </div>
   </div>
      </header>
    );
}
export default Header;