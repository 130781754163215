import React, { useEffect, useState } from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../css/special styles.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import logo from "../../image/html.png";
import { getAuthStatus, saveAuthStatus } from "../../localstorage";
import DemoProgress from "../../components/Tutorial Progress/DemoProgress";
import ButtonHome from "../../components/Buttonhome";



export default function HTMLintro() {
   
 
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    <!DOCTYPE html>
    <html>
    <head>
    <title>My First Web Page</title>
    </head>
    <body>
    <h1>Hello World!</h1>
    </body>
    </html>
`;
    return (
   <body>
     <Helmet>
     <title>HTML Tutorial</title>
     <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Introduction to HTML, What is html ?, Why learn HTML5 ?, html Try it Out Editor, 
     Step-by-step html programming tutorials, html coding exercises for beginners, Advanced html coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
    html programming tips for real-world applications." />
   <meta name="description" content="The markup language used to create webpages and web applications is called html.
   It is a universal language to design a static web page." />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
        
    <Sidenav />
   
    <div className="content">

<header className="headertutorials">
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_editors"><ButtonNext /></ScrollToTopLink>
   <h1>HTML Tutorial - Preamble</h1>
</header>

<Navbar />

<main>
    <section>
    
<h2>What is HTML?</h2>
<p>The markup language used to create webpages and web applications is called HTML.</p>
<p>It is a universal language to design a static web page.</p>

<p>In 1989, Tim Berners Lee and his team designed the present form of HTML.</p>

<p>The latest version of HTML is HTML5 and it was released in 2014.</p>
    </section>
    
    <section>
<h2>Why learn HTML5 ?</h2>
<p>It is very essential to learn HTML if you want to build websites, you can't build one if you do not know HTML because it is one of the prerequisites in learning other languages used for web development.</p>
<h2>NOTE:</h2>
<p>HTML is not a programming language instead it is a markup language used to create web pages.</p>
</section>

<section>
    <h2>Try it Out Editor</h2>
    <p>We have setup a try it out editor inorder for you to run your code examples</p>
    <br />
    <h2>Example:</h2>
   <SyntaxHighlighterComponent code={code} language="html" />
<ScrollToTopLink to="/try_html1"><Tryitout /></ScrollToTopLink>
<p>This is how a basic HTML file looks like on the browser.</p>
<img src={require('../../image/html.png')} width='800' alt='an html file' />
</section>

<article>
    <p>Click the button below to move on to the next page</p>
</article>

<div className="second-div">
 
     <section className="features">
     <div className="progress-sample">
    
         <img src={require('../../image/beta-progress.svg').default} width={500} height={500}  alt='betathread' />
       <p style={{fontSize: '15px'}}> Our Progress Tracker is designed to help you monitor your progress through our extensive range of tutorials.</p>
       <ScrollToTopLink to="/progress-tracker"><button type="submit" className="buttonb">Track Progress</button></ScrollToTopLink>
   
     </div>

     <div className="progress-sampling">
     <h1 style={{fontSize: '45px'}}>Track Your Progress</h1>
     <DemoProgress />
     </div>
   </section>
  </div>
</main>
<div className="head">
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_editors"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}