import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonLoops() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeForLoop = `
# For loop
fruits = ["apple", "banana", "cherry"]
for fruit in fruits:
    print(fruit)
    `;

    const codeWhileLoop = `
# While loop
i = 1
while i < 6:
    print(i)
    i += 1
    `;

    const codeBreak = `
# Break statement
i = 1
while i < 6:
    print(i)
    if i == 3:
        break
    i += 1
    `;

    const codeContinue = `
# Continue statement
i = 0
while i < 6:
    i += 1
    if i == 3:
        continue
    print(i)
    `;

    const codeNestedLoop = `
# Nested loop
adj = ["red", "big", "tasty"]
fruits = ["apple", "banana", "cherry"]
for x in adj:
    for y in fruits:
        print(x, y)
    `;

    return (
        <body>
            <Helmet>
                <title>Python Loops</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Loops, For Loop, While Loop, Break Statement, Continue Statement, Nested Loop" />
                <meta name="description" content="Learn about loops in Python, including for loops, while loops, and loop control statements like break and continue." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_conditionals"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_functions"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Loops</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Loops in Python allow you to execute a block of code multiple times. This tutorial covers the basic types of loops in Python and how to use them effectively.</p>
                        
                        <h2>For Loop</h2>
                        <p>The <code>for</code> loop is used to iterate over a sequence (such as a list, tuple, or string).</p>
                        <SyntaxHighlighterComponent code={codeForLoop} language="python" />
                        
                        <h2>While Loop</h2>
                        <p>The <code>while</code> loop repeatedly executes a block of code as long as a specified condition is true.</p>
                        <SyntaxHighlighterComponent code={codeWhileLoop} language="python" />
                        
                        <h2>Break Statement</h2>
                        <p>The <code>break</code> statement is used to exit a loop prematurely.</p>
                        <SyntaxHighlighterComponent code={codeBreak} language="python" />
                        
                        <h2>Continue Statement</h2>
                        <p>The <code>continue</code> statement is used to skip the rest of the code inside the loop for the current iteration only.</p>
                        <SyntaxHighlighterComponent code={codeContinue} language="python" />
                        
                        <h2>Nested Loop</h2>
                        <p>You can nest loops inside each other to perform more complex iterations.</p>
                        <SyntaxHighlighterComponent code={codeNestedLoop} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Understanding loops is essential for writing efficient and effective Python code. This tutorial covered the basics of <code>for</code> loops, <code>while</code> loops, and loop control statements such as <code>break</code> and <code>continue</code>.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_conditionals"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_functions"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
