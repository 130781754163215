import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonSetComprehensions() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const code = `
# Basic Set Comprehension
my_set = {x for x in range(10) if x % 2 == 0}
print(my_set)  # Output: {0, 2, 4, 6, 8}

# Set Comprehension with Strings
string_set = {char for char in "hello world" if char not in "aeiou"}
print(string_set)  # Output: {'r', 'd', 'w', 'h', 'l'}
    `;

    return (
        <body>
            <Helmet>
                <title>Python Set Comprehensions</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Set Comprehensions, Create Sets, Set Comprehensions" />
                <meta name="description" content="Learn how to create sets concisely using set comprehensions in Python." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_set_methods"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_set_operations"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Set Comprehensions</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Set comprehensions provide a concise way to create sets in Python. They are similar to list comprehensions but use curly braces <code>{'{ }'}</code>.</p>
                        
                        <h2>Basic Set Comprehension</h2>
                        <p>You can create a set using a simple comprehension with a single condition.</p>
                        <SyntaxHighlighterComponent code={code} language="python" />
                        
                        <h2>Set Comprehension with Strings</h2>
                        <p>Set comprehensions can also be used with strings to create sets of characters based on certain conditions.</p>
                        <SyntaxHighlighterComponent code={code} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Set comprehensions provide a powerful and concise way to create sets in Python, making your code cleaner and more readable.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_set_methods"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_set_operations"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
