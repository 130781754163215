import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLheading() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <h1> Heading 1 </h1>
      <h2> Heading 2 </h2>
      <h3> Heading 3 </h3>
      <h4> Heading 4 </h4>
      <h5> Heading 5 </h5>
      <h6> Heading 6 </h6>
  `;
  
     const code2 = `
      <h1 style="color: red"> My color is red </h1>
      <h1 style="color: green"> My color is green </h1>
      <h1 style="color: blue"> My color is blue </h1>
  `;
      const code3 = `
      <h1 style="text-align:center"> I am aligned to the center. </h1>
      <h1 style="text-align:right"> I am aligned to the right. </h1>
      <h1 style="text-align:left"> I am aligned to the left. </h1>
  `;    
    return (
   <body>
    <Helmet>    
        <title>HTML Headings</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Headings, What is a heading, <h1>, <h2>, <h3>, <h4>, <h5>, <h6>, Heading Colors, Heading Alignment
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
       <meta name="description" content="HTML headings are block-level elements and they usually contain the main topic or title of a certain content." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">
<header className="headertutorials" style={newCode}>

<ScrollToTopLink to="/HTML_attributes pt.2"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_paragraph"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Headings</h1>
</header>

<Navbar />

<main>
<section>
<p>HTML headings are block-level elements and they usually contain the main topic or title of a certain content.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html13"><Tryitout /></ScrollToTopLink>

<p>As you can see on the example above the elements used are the &lt;h1&gt;,&lt;h2&gt;,&lt;h3&gt;,&lt;h4&gt;,&lt;h5&gt;,&lt;h6&gt;.</p>
<p>In HTML the size of a heading depends on its corresponding number after h and the lower the number the larger the font-size.</p>
<h2>Note:</h2>
<p>In HTML heading you can only use from &lt;h1&gt; to &lt;h6&gt;.</p>
</section>
 
<section>
<h2>Heading Colors</h2>
<p>To change the color of our HTML headings we use inline styling with the CSS color property and a color value.</p>
<br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html14"><Tryitout /></ScrollToTopLink>
</section>

<section>
    <h2>Heading Alignment</h2>
    <p>Headings can also be aligned to do that we use the text-align CSS property with the center,right or left values.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code3} language="html" />
<ScrollToTopLink to="/try_html15"><Tryitout /></ScrollToTopLink>
</section>
 
</main>
<div className="head">
<ScrollToTopLink to="/HTML_attributes pt.2"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_paragraph"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
   
    )
}