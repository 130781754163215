import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLstyles() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <style type="text/css">
      p {
          font-size:25px;
      }
      </style>
  `;
  
     const code2 = `
     <element style="property:value;property:value;">I am a text.</element>
  `;
     const code3 = `
     <h1 style="color:red"> My color is red. </h1>
     <h2 style="color:yellow"> My color is yellow. </h2>
     <p style="color:blue"> My color is blue. </p>
  `;
    const code4 = `
    <h1 style="text-align:center"> I am aligned to the center. </h1>
    <h1 style="text-align:right"> I am aligned to the right. </h1>
    <h1 style="text-align:left"> I am aligned to the left. </h1>
  `;
     const code5 = `
     <p style="font-size: 20px"> My size is twenty pixels. </p>
     <p style="font-size: 30px"> My size is thirty pixels. </p>
     <p style="font-size: 40px"> My size is forty pixels. </p>
  `;
  const code6 = `
  <body style="background-color: whitesmoke">
  <!-- content goes here -->
  </body>
`;
    return (
   <body>
  <Helmet>    
        <title>HTML Styles</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Styles, Internal Style Sheet, Inline Styling, Inline Styling Syntax, Text Color Example, Text Alignment Example, Text Sizing Example, Changing the Background Color, How to style a html file, how to add css to html, css in html,
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="HTML elements can be styled using HTML styles.Styling can change the default values of text color, background color, text alignment, text size." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
<br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
    
    <div className="content">
<header className="headertutorials" style={newCode}>
 
<ScrollToTopLink to="/HTML_paragraph"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_colors"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Styles</h1>
</header>

<Navbar />

<main>


<section>
<p>HTML elements can be styled using HTML styles. Styling can change the default values of text color, background color, text alignment, text size.</p>
<h2>Internal Style Sheet</h2>
<p>An internal style sheet is composed of one or more cascading style sheet,the CSS rule-set.</p>
<p>A CSS rule-set consists of a selector and a declaration block surrounded by curly braces that contains one or more CSS declarations seperated by semi colons.</p>
<p>Every declaration includes a CSS property name and a value asssigned to it,seperated by a colon.</p>
<p>The selectors and declarations are all enclosed inside the &lt;style&gt; element with its type="text/css" attribute which is included inside the head element.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html18"><Tryitout /></ScrollToTopLink>

<p>The selector in the above example is the p element and the dclaration block is everything within the curly braces{}.</p>
</section>

<section>
    <h2>Inline Styling</h2>
    <p>Inline styling is used to style elements using the style attribute with CSS declaration inside which is similar to internal styling.</p>
    <h2>Syntax</h2>
    
    <SyntaxHighlighterComponent code={code2} language="html" />
    
    <h2>Text Color Example</h2>
    <SyntaxHighlighterComponent code={code3} language="html" />
<ScrollToTopLink to="/try_html19"><Tryitout /></ScrollToTopLink>

<h2>Text Alignment Example</h2>
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code4} language="html" />
<ScrollToTopLink to="/try_html20"><Tryitout /></ScrollToTopLink>

<h2>Text Sizing Example</h2>
<SyntaxHighlighterComponent code={code5} language="html" />
<ScrollToTopLink to="/try_html21"><Tryitout /></ScrollToTopLink>

<h2>Changing the Background Color</h2>
<SyntaxHighlighterComponent code={code6} language="html" />
<ScrollToTopLink to="/try_html22"><Tryitout /></ScrollToTopLink>
</section>
 
</main>
<div className="head">
<ScrollToTopLink to="/HTML_paragraph"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_colors"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
   
    )
}