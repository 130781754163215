import React, { useEffect, useState } from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import logo from "../../image/html.png";
import { getAuthStatus, saveAuthStatus } from "../../localstorage";
import ButtonPrevious from "../../components/Buttonprevious";
import XTipsSidenav from "../../components/Sidenav/XTipSidenav";



export default function HTMLtips() {

  
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    <!DOCTYPE html>
    <html>
    <head>
    <title>My First Web Page</title>
    </head>
    <body>
    <h1>Hello World!</h1>
    </body>
    </html>
`;
    return (
   <body>
     <Helmet>
     <title>HTML Tips</title>
     <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Tutorial Tips, HTML Tutorial Tips, HTML Tips" />
   <meta name="description" content="" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
        <XTipsSidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/tips"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/css_tips"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>HTML Tips</h1>
</header>

<Navbar />

<main>

<section>
  <h2>Tip 1: Use HTML5 Semantic Elements</h2>
  <p>HTML5 introduces semantic elements that provide meaning to the structure of a web page. Use elements like <code>&lt;header&gt;</code>, <code>&lt;nav&gt;</code>, <code>&lt;main&gt;</code>, <code>&lt;section&gt;</code>, <code>&lt;article&gt;</code>, <code>&lt;aside&gt;</code>, <code>&lt;footer&gt;</code>, and others to create a well-structured and accessible web page.</p>
</section>

<section>
  <h2>Tip 2: Write Clean and Indented Code</h2>
  <p>Write HTML code that is easy to read and understand. Use indentation to nest elements and make your code look organized. This will help you and others maintain and debug your code.</p>
</section>

<section>
  <h2>Tip 3: Use ARIA Attributes for Accessibility</h2>
  <p>ARIA (Accessible Rich Internet Applications) attributes help make web pages accessible to people with disabilities. Use ARIA attributes like <code>role</code>, <code>state</code>, and <code>property</code> to provide screen readers and other assistive technologies with information about dynamic content and interactive elements.</p>
</section>

<section>
  <h2>Tip 4: Optimize Images</h2>
  <p>Optimize images by compressing them using tools like TinyPNG or ShortPixel. This will reduce the file size of your images and improve page load times.</p>
</section>

<section>
  <h2>Tip 5: Use HTML Entities</h2>
  <p>HTML entities are used to represent special characters in HTML. Use entities like <code>&amp;nbsp;</code> for non-breaking spaces, <code>&amp;copy;</code> for copyright symbols, and others to add special characters to your web page.</p>
</section>

<section>
  <h2>Tip 6: Use the <code>alt</code> Attribute for Images</h2>
  <p>The <code>alt</code> attribute provides alternative text for images. Use it to describe the content of an image for screen readers and other assistive technologies.</p>
</section>

<section>
  <h2>Tip 7: Use the <code>title</code> Attribute for Tooltips</h2>
  <p>The <code>title</code> attribute provides a tooltip for an element. Use it to provide additional information about an element, like a link or an image.</p>
</section>

<section>
  <h2>Tip 8: Use the <code>meta</code> Tag for SEO</h2>
  <p>The <code>meta</code> tag provides metadata about a web page. Use it to specify the title, description, and keywords for your web page to improve search engine optimization (SEO).</p>
</section>

<section>
  <h2>Tip 9: Use the <code>label</code> Element for Form Accessibility</h2>
  <p>The <code>label</code> element provides a text description for a form control. Use it to associate a text description with a form control, like a checkbox or radio button.</p>
</section>

<section>
  <h2>Tip 10: Use the <code>fieldset</code> and <code>legend</code> Elements for Form Organization</h2>
  <p>The <code>fieldset</code> element groups related form controls together, while the <code>legend</code> element provides a text description for the group. Use them to organize and provide context for form controls.</p>
</section>

<section>
  <h2>Tip 11: Use the <code>header</code> Element for Document Header</h2>
  <p>The <code>header</code> element represents the header of a document or section. Use it to provide a title, logo, or other introductory content.</p>
</section>

<section>
  <h2>Tip 12: Use the <code>nav</code> Element for Navigation</h2>
  <p>The <code>nav</code> element represents a section of navigation links. Use it to provide a list of links to other pages or sections of your website.</p>
</section>

<section>
  <h2>Tip 13: Use the <code>main</code> Element for Main Content</h2>
  <p>The <code>main</code> element represents the main content of a document or section. Use it to provide the primary content of your web page.</p>
</section>

<section>
  <h2>Tip 14: Use the title element wisely</h2>
  <p>Use the &lt;title&gt; element to provide a concise and descriptive title for each web page, which appears in browser tabs and search engine results.</p>
</section>

<section>
  <h2>Tip 15: Minimize the use of inline styles</h2>
  <p>Avoid using inline styles and instead use external CSS stylesheets to separate content from presentation and improve maintainability.</p>
</section>

<section>
  <h2>Tip 16: Validate your HTML code regularly</h2>
  <p>Regularly validate your HTML code to catch any errors or inconsistencies and ensure your website remains compliant with HTML standards.</p>
</section>

<section>
  <h2>Tip 17: Use meta tags for social media sharing</h2>
  <p>Include Open Graph and Twitter Card meta tags to control how your website appears when shared on social media platforms like Facebook and Twitter.</p>
</section>

<section>
  <h2>Tip 18: Optimize your website for speed</h2>
  <p>Optimize your website for speed by minimizing HTTP requests, leveraging browser caching, and using techniques like lazy loading for images and scripts.</p>
</section>

<section>
  <h2>Tip 19: Structure tables with thead, tbody, and tfoot</h2>
  <p>Structure HTML tables with &lt;thead&gt;, &lt;tbody&gt;, and &lt;tfoot&gt; elements to improve accessibility and facilitate styling and scripting.</p>
</section>

<section>
  <h2>Tip 20: Keep your HTML code clean and organized</h2>
  <p>Keep your HTML code clean and organized by using proper indentation, comments, and consistent naming conventions to enhance readability and maintainability.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/tips"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/css_tips"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}