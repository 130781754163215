import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import XMLMetatag from "../../components/XML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function XMLAttributes() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    <elementName attributeName="attributeValue">Element Content</elementName>
    `;
    const code2 = `
    <book ISBN="978-0-123456-78-9">
    <title>Introduction to XML</title>
    <author>John Doe</author>
    </book>
    `;
    const code3 = `
    <person firstName="John" lastName="Doe" age="30">
    <!-- Content goes here -->
    </person>

    `;
    const code4 = `
<!-- Valid -->
<element attribute1="value1" attribute2='value2'></element>

<!-- Invalid (mixed quotes) -->
<element attribute="value1'"></element>

    `;
    const code5 = `
    <item description="This is an &lt;item&gt;"></item>
    `;
    const code6 = `
    <item description="This is an &lt;item&gt;"></item>
    `;
    return (
   <body>
      <Helmet>
        
        <title>XML Attributes</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="XML Attributes, XML Step-by-step Python programming tutorials XML coding exercises for beginners, Advanced XML coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       XML programming tips for real-world applications," />
      <meta name="description" content="Learn how XML attributes streamline code organization, 
      enhance readability, and unlock advanced features in PHP development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/XML_elements"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/XML_declaration"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>XML Attributes</h1>
</header>

<Navbar />

<main>
<section>
<p>XML (eXtensible Markup Language) attributes are pieces of information within an XML element
 that provide additional metadata about that element. 
</p>
<p> Attributes are always specified in the opening tag of an XML element and follow the format name="value". </p>
<p>They are used to convey information about the element itself rather than its content.</p>

<h2>Syntax of XML Attributes</h2>
<p>XML attributes are defined within the opening tag of an element.</p>
<p>They consist of a name and a value enclosed in double or single quotes.</p>
  <br />
  <h2>Example:</h2>
  <SyntaxHighlighterComponent code={code} language="xml" />
<h2> Example of XML Attribute</h2>
<p>Consider a simple XML document representing a book:</p>
<SyntaxHighlighterComponent code={code2} language="xml" />
<p>In this example, ISBN is an attribute of the &lt;book&gt; element, providing additional information 
    about the book.</p>
 <br />
      
<h2>Multiple Attributes</h2>
<p>It is possible to have multiple attributes within a single XML element</p>
<br />
<h2>Example:</h2>
  
  <SyntaxHighlighterComponent code={code3} language="xml" />
<br />
  <h2>Attribute Values</h2>
  <p>Attribute values must be enclosed in either double quotes or single quotes. You can't mix them 
    within the same attribute.</p>
    <br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code4} language="xml" />
<br />
 
<h2>Special Characters</h2>
<p>If an attribute value contains characters that are reserved in XML (e.g., &lt;, &gt;, ", ', &),
 you should use XML entities to represent them. 
</p>
    <br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code5} language="xml" />
<br />
<h2> Use Cases</h2>
  <p>XML attributes are commonly used for providing metadata or configuration settings within XML elements.</p>
<p>They are also used in XML-based languages like HTML to define element properties.</p>
<p>Attributes are a fundamental part of XML and enable you to add structured data and metadata to your XML documents efficiently. </p>
<p>They are especially useful when you need to associate properties or characteristics with elements in a concise and 
    human-readable manner.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/XML_elements"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/XML_declaration"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}