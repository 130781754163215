import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIRoboticsHardware() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
# Example 1: Basic DC Motor Control with Arduino
#include <Arduino.h>

const int motorPin = 9; // Pin connected to the motor

void setup() {
  pinMode(motorPin, OUTPUT); // Set motor pin as output
}

void loop() {
  analogWrite(motorPin, 255); // Set motor speed (0-255)
  delay(2000); // Run for 2 seconds
  analogWrite(motorPin, 0); // Stop the motor
  delay(2000); // Wait for 2 seconds
}
  `;

  const example2 = `
# Example 2: Using a Servo Motor with Raspberry Pi
import RPi.GPIO as GPIO
import time

GPIO.setmode(GPIO.BOARD)
servoPin = 11
GPIO.setup(servoPin, GPIO.OUT)
pwm = GPIO.PWM(servoPin, 50)  # 50Hz frequency

pwm.start(0)  # Initialization

def setAngle(angle):
    duty = angle / 18 + 2
    GPIO.output(servoPin, True)
    pwm.ChangeDutyCycle(duty)
    time.sleep(1)
    GPIO.output(servoPin, False)
    pwm.ChangeDutyCycle(0)

setAngle(90)  # Set servo to 90 degrees
time.sleep(2)
setAngle(0)  # Set servo to 0 degrees

pwm.stop()
GPIO.cleanup()
  `;

  const example3 = `
# Example 3: Interfacing Ultrasonic Sensor with Arduino
#include <Arduino.h>

const int trigPin = 9;
const int echoPin = 10;

void setup() {
  pinMode(trigPin, OUTPUT);
  pinMode(echoPin, INPUT);
  Serial.begin(9600);
}

void loop() {
  digitalWrite(trigPin, LOW);
  delayMicroseconds(2);
  digitalWrite(trigPin, HIGH);
  delayMicroseconds(10);
  digitalWrite(trigPin, LOW);

  long duration = pulseIn(echoPin, HIGH);
  long distance = duration * 0.034 / 2;

  Serial.print("Distance: ");
  Serial.println(distance);

  delay(500);
}
  `;

  return (
    <body>
      <Helmet>
        <title>AI Robotics Hardware</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Robotics Hardware, DC Motor Control, Servo Motor, Ultrasonic Sensor, Arduino, Raspberry Pi, AI Tutorial" />
        <meta name="description" content="Learn about AI robotics hardware, including DC motor control, servo motor usage, and interfacing ultrasonic sensors with Arduino and Raspberry Pi." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_robotics"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_robotics_software"><ButtonNext /></ScrollToTopLink>
          <h1>AI Robotics Hardware</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>AI robotics hardware includes various physical components that work together to create intelligent robotic systems. This section covers essential hardware elements like motors, sensors, and controllers, which are integral to building functional robots.</p>

            <h2>Examples of Robotics Hardware</h2>

            <h3>Example 1: Basic DC Motor Control with Arduino</h3>
            <SyntaxHighlighterComponent code={example1} language="c" />

            <h3>Example 2: Using a Servo Motor with Raspberry Pi</h3>
            <SyntaxHighlighterComponent code={example2} language="python" />

            <h3>Example 3: Interfacing Ultrasonic Sensor with Arduino</h3>
            <SyntaxHighlighterComponent code={example3} language="c" />

            <p>Each example demonstrates different aspects of robotics hardware. The first example shows how to control a DC motor using an Arduino, the second example illustrates using a servo motor with a Raspberry Pi, and the third example explains how to interface an ultrasonic sensor with an Arduino for distance measurement.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_robotics"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_robotics_software"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
