import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonInput() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeBasicInput = `
# Basic input
name = input("Enter your name: ")
print("Hello, " + name + "!")
    `;

    const codeIntegerInput = `
# Input as an integer
age = int(input("Enter your age: "))
print("You are " + str(age) + " years old.")
    `;

    const codeFloatInput = `
# Input as a float
height = float(input("Enter your height in meters: "))
print("Your height is " + str(height) + " meters.")
    `;

    const codeMultipleInputs = `
# Multiple inputs in a single line
x, y = input("Enter two numbers separated by a space: ").split()
print("You entered " + x + " and " + y)
    `;

    const codeAdvancedInput = `
# Advanced input with map and split
numbers = list(map(int, input("Enter multiple numbers separated by spaces: ").split()))
print("You entered:", numbers)
    `;

    return (
        <body>
            <Helmet>
                <title>Python Input</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Input, Basic Input, Integer Input, Float Input, Multiple Inputs" />
                <meta name="description" content="Learn how to handle user input in Python, including basic input, input as integers and floats, and handling multiple inputs." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_type_conversion"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_operators"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Input</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Handling user input is a crucial aspect of programming. Python provides a built-in function <code>input()</code> for this purpose. Let's explore different ways to handle input in Python.</p>
                        
                        <h2>Basic Input</h2>
                        <p>The <code>input()</code> function reads a line from the input (usually from the user) and returns it as a string.</p>
                        <SyntaxHighlighterComponent code={codeBasicInput} language="python" />
                        
                        <h2>Input as an Integer</h2>
                        <p>To handle numeric input, convert the string returned by <code>input()</code> to an integer using the <code>int()</code> function.</p>
                        <SyntaxHighlighterComponent code={codeIntegerInput} language="python" />
                        
                        <h2>Input as a Float</h2>
                        <p>Similarly, you can convert the string to a float using the <code>float()</code> function for decimal numbers.</p>
                        <SyntaxHighlighterComponent code={codeFloatInput} language="python" />
                        
                        <h2>Multiple Inputs</h2>
                        <p>You can read multiple inputs from a single line by using the <code>split()</code> method to split the input string into multiple parts.</p>
                        <SyntaxHighlighterComponent code={codeMultipleInputs} language="python" />
                        
                        <h2>Advanced Input Handling</h2>
                        <p>For more complex input handling, you can use <code>map()</code> in combination with <code>split()</code> to process multiple inputs at once.</p>
                        <SyntaxHighlighterComponent code={codeAdvancedInput} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Understanding how to handle input in Python allows you to interact with users and create dynamic programs. This tutorial covered basic input, input as integers and floats, and handling multiple inputs effectively.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_type_conversion"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_operators"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
