import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLsection() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
    <!--Example using the span element-->
    <p>I <span style="color:blue;">love</span> <span style="color:red;">HTML.<br /></span></p>
    <br />
    <!--Example using the div element-->
    <div style="background-color:green;color:white;height:100px;width:500px;">
    <h1>Beta Learn is awesome</h1>
    </div>
  `;
  
  
    return (
   <body>
       <Helmet>    
        <title>HTML Sections</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Sections, HTML5 sections, html5 sections example, Semantic Elements for HTML5, Other HTML Elements, <header>, <footer>, <nav>, <div>, <span>,
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="HTML documents are seperated into different sections each of them having their own funtion.
       Sections are very important beacause they play an important role in organizing HTML documents.Another important function of HTML5 semantic elements is that help in search engine optimization(SEO)" />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_text_formatting"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_image"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Sections</h1>
</header>

<Navbar />

<main>

<section>
<p>HTML documents are seperated into different sections each of them having their own funtion.</p>
<p>Sections are very important beacause they play an important role in organizing HTML documents.Another important function of HTML5 semantic elements is that help in search engine optimization(SEO)</p>
<h2>Semantic Elements for HTML5</h2>
<li>&lt;header&gt; This is the header section of an HTML document and it is always at the top.</li>
<li>&lt;nav&gt; This is the navigation section of an HTML document where all the navigation links which are used to move aroud the website are found.</li>
<li>&lt;main&gt; This houses the main content of a web page.</li>
<li>&lt;footer&gt; The footer section is found at the bottom of he page and it is mostly comprised of some links and few content.</li>

<h2>Other HTML Elements</h2>
<p>There are some other HTML elements thhat work similarly with the elements mentioned above.</p>
<p>They include the following:</p>
<li>&lt;span&gt; This is an inline element and it is used to group smaller text within a paragraph and other few elements.</li>
<li>&lt;div&gt; This is a block level element used to group larger elements such as header,navigation menu,main content and the footer</li>
<p>Below is an example using both the &lt;span&gt; and &lt;div&gt; elements.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html38"><Tryitout /></ScrollToTopLink>


</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_text_formatting"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_image"><ButtonNext /></ScrollToTopLink>
</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}