import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactES6arrowfunctions() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    // Traditional function
    function greet(name) {
      return 'Hello, ' + name + '!';
    }
    
    // Arrow function
    const greet = (name) => {
      return 'Hello, ${name}!';
    };
       `

    const code2=`
    // Traditional function
    function today(weather) {
      return 'The day is ' + weather + '!';
    }
    
    // Arrow function
    const today = (weather) => {
      return 'Hello, ${weather}!';
    };
    `
  
    return (
   <body>
     <Helmet>
        
        <title>React ES6 Arrow Functions</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React ES6 Arrow Functions, ES6 Arrow Functions, Arrow Functions Examples
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="Arrow functions offer a concise syntax for writing functions in JavaScript." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_es6-modules"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-destructuring"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React ES6 Arrow Functioning</h1>
</header>

<Navbar />

<main>
<section>
<p>Arrow functions offer a concise syntax for writing functions in JavaScript.</p>
  <p>Unlike traditional function expressions, arrow functions do not bind their own this value, 
    making them particularly useful in React components.</p>
 <h2>Example</h2>
 <SyntaxHighlighterComponent code={code} language="js" />

 <p>Here's another example of ES6 arrow functions.</p>
 <SyntaxHighlighterComponent code={code2} language="js" />
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_es6-modules"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-destructuring"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}