import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLDelete() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const deleteSingleRowCode = `
-- Delete a single row from Employees table
DELETE FROM Employees
WHERE Name = 'Alice';
  `;

  const deleteMultipleRowsCode = `
-- Delete multiple rows from Employees table
DELETE FROM Employees
WHERE Department = 'Engineering';
  `;

  return (
    <body>
      <Helmet>
        <title>SQL DELETE Statement</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL DELETE Statement, SQL Tutorial, DELETE FROM, WHERE clause" />
        <meta name="description" content="Learn about the SQL DELETE statement, including syntax, examples, and deleting single and multiple rows in a table." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_update"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_data_types"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL DELETE Statement</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>The <code>DELETE</code> statement is used to remove existing records (rows) from a table in a database.</p>

            <h2>Basic Syntax</h2>
            <p>The basic syntax of a <code>DELETE</code> statement is:</p>
            <SyntaxHighlighterComponent code={deleteSingleRowCode} language="sql" />

            <p>In this example:</p>
            <ul>
              <li><code>Employees</code> is the name of the table from which data is deleted.</li>
              <li><code>WHERE Name = 'Alice'</code> specifies the condition to select the row(s) to delete.</li>
            </ul>

            <h2>Deleting Multiple Rows</h2>
            <p>You can delete multiple rows in a table using a single <code>DELETE</code> statement:</p>
            <SyntaxHighlighterComponent code={deleteMultipleRowsCode} language="sql" />

            <p>In this example:</p>
            <ul>
              <li><code>WHERE Department = 'Engineering'</code> filters rows to delete based on the specified condition.</li>
            </ul>

            <h2>Example Table</h2>
            <p>Consider the following table named <code>Employees</code> where data is being deleted:</p>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Department</th>
                  <th>Salary</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Alice</td>
                  <td>30</td>
                  <td>HR</td>
                  <td>5500</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Bob</td>
                  <td>25</td>
                  <td>Engineering</td>
                  <td>6500</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Charlie</td>
                  <td>35</td>
                  <td>Finance</td>
                  <td>7000</td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_update"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_data_types"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
