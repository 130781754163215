import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyDataTypes() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const stringTypeCode = `
# String Data Type
greeting = "Hello, World!"
name = 'Alice'
multiline_string = """This is a 
multiline string."""

puts greeting           # Output: Hello, World!
puts name               # Output: Alice
puts multiline_string   # Output: This is a 
                        # multiline string.
  `;

  const numberTypeCode = `
# Number Data Types
integer_number = 42
float_number = 3.14

puts integer_number     # Output: 42
puts float_number       # Output: 3.14
  `;

  const booleanTypeCode = `
# Boolean Data Type
is_ruby_fun = true
is_sky_blue = false

puts is_ruby_fun        # Output: true
puts is_sky_blue        # Output: false
  `;

  const arrayTypeCode = `
# Array Data Type
numbers = [1, 2, 3, 4, 5]
mixed_array = [1, "two", 3.0, :four, true]

puts numbers.inspect    # Output: [1, 2, 3, 4, 5]
puts mixed_array.inspect# Output: [1, "two", 3.0, :four, true]
  `;

  const hashTypeCode = `
# Hash Data Type
person = { "name" => "Alice", "age" => 30, "is_student" => true }

puts person["name"]     # Output: Alice
puts person["age"]      # Output: 30
puts person["is_student"] # Output: true
  `;

  const symbolTypeCode = `
# Symbol Data Type
symbol = :my_symbol

puts symbol             # Output: my_symbol
puts symbol.object_id   # Outputs a unique identifier for the symbol
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Data Types</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Data Types, String, Number, Boolean, Array, Hash, Symbol" />
        <meta name="description" content="Learn about data types in Ruby, including strings, numbers, booleans, arrays, hashes, and symbols." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_variables"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_constants"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Data Types</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Ruby has several built-in data types that can be used to store and manipulate data.</p>
            <p>Understanding these data types is essential for effective programming in Ruby.</p>
            
            <h2>String Data Type</h2>
            <p>Strings are sequences of characters enclosed in either double quotes (<code>"</code>) or single quotes (<code>'</code>).</p>
            <SyntaxHighlighterComponent code={stringTypeCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>greeting</code> is a string using double quotes.</li>
              <li><code>name</code> is a string using single quotes.</li>
              <li><code>multiline_string</code> is a string spanning multiple lines.</li>
            </ul>

            <h2>Number Data Types</h2>
            <p>Numbers in Ruby can be integers or floating-point numbers.</p>
            <SyntaxHighlighterComponent code={numberTypeCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>integer_number</code> is an integer.</li>
              <li><code>float_number</code> is a floating-point number.</li>
            </ul>

            <h2>Boolean Data Type</h2>
            <p>Booleans represent true or false values.</p>
            <SyntaxHighlighterComponent code={booleanTypeCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>is_ruby_fun</code> is a boolean with a value of <code>true</code>.</li>
              <li><code>is_sky_blue</code> is a boolean with a value of <code>false</code>.</li>
            </ul>

            <h2>Array Data Type</h2>
            <p>Arrays are ordered collections of objects, which can be of different types.</p>
            <SyntaxHighlighterComponent code={arrayTypeCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>numbers</code> is an array of integers.</li>
              <li><code>mixed_array</code> is an array containing different types of objects.</li>
            </ul>

            <h2>Hash Data Type</h2>
            <p>Hashes are collections of key-value pairs, where each key is unique.</p>
            <SyntaxHighlighterComponent code={hashTypeCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>person</code> is a hash with keys <code>"name"</code>, <code>"age"</code>, and <code>"is_student"</code>.</li>
              <li>The values can be accessed using their respective keys.</li>
            </ul>

            <h2>Symbol Data Type</h2>
            <p>Symbols are immutable, reusable constants represented with a colon (<code>:</code>) followed by a name.</p>
            <SyntaxHighlighterComponent code={symbolTypeCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>symbol</code> is a symbol with the name <code>my_symbol</code>.</li>
              <li>Symbols are unique and have the same object ID throughout the program.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_variables"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_constants"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
