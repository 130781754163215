import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import AIMetatag from "../../components/AI_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";


export default function AIhistory() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    SELECT first_name FROM users;
    `;
    
    return (
   <body>
      <Helmet>
     <title>AI History</title>
     <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Introduction to AI, Early Beginnings (1950s), Symbolic AI (1960s-1970s), First AI Winter (1970s-1980s), Connectionism and Neural Networks (1980s-1990s)
    Expert Systems and Practical AI (1990s-2000s), Second AI Winter (Late 1990s-early 2000s), Machine Learning Resurgence (2010s-Present)" />
   <meta name="description" content="The history and evolution of Artificial Intelligence (AI) is a fascinating journey
    that spans several decades. It's marked by significant breakthroughs, periods of optimism, and challenges." />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
    
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/AI_Tutorial_intro">
        <ButtonPrevious />
      </ScrollToTopLink>
      <ScrollToTopLink to="/AI_types">
        <ButtonNext />
      </ScrollToTopLink>
   <h1 style={secondCode}>AI History</h1>
</header>

<Navbar />

<main>
<section>
<p> The history and evolution of Artificial Intelligence (AI) is a fascinating journey that spans several decades. 
</p>
<p> It's marked by significant breakthroughs, periods of optimism, and challenges.</p>
<h2>Early Beginnings (1950s)</h2>
<p>The term "Artificial Intelligence" was coined in 1956 at the Dartmouth Workshop, a seminal event in AI history.</p>
<p> Early AI research focused on symbolic reasoning and logic-based systems, aiming to create machines that could simulate human 
    thought processes.</p>

<h2>Symbolic AI (1960s-1970s)</h2>
<p>During this period, researchers developed rule-based systems capable of solving complex problems using symbolic representation and logic. </p>
<p>Expert systems emerged as a prominent application, where systems imitated the decision-making of human experts in specific domains.</p>

<h2>First AI Winter (1970s-1980s)</h2>
<p>The early hype of AI led to inflated expectations, but reality fell short of these expectations due to limitations in computing
     power and the complexity of AI tasks.</p>
 <p>This period, known as the "AI winter," saw reduced funding and interest in AI research.</p>

<h2>Connectionism and Neural Networks (1980s-1990s)</h2>
<p>Researchers began exploring connectionist models, inspired by the brain's neural networks. </p>
<p>Neural networks gained traction for pattern recognition and learning tasks, leading to advances in 
    machine learning.</p>

<h2>Expert Systems and Practical AI (1990s-2000s)</h2>
<p>AI applications became more practical, including systems for speech recognition, natural language processing, and 
    recommendation systems.</p>
<p>Expert systems found success in various industries, providing valuable insights and decision support.</p>

<h2>Second AI Winter (Late 1990s-early 2000s)</h2>
<p>The high expectations for AI during the 1990s led to another period of disillusionment as certain AI promises remained unfulfilled. </p>
<p> Funding and interest decreased once again.</p>

<h2>Machine Learning Resurgence (2010s-Present)</h2>
<p>Advancements in machine learning, especially deep learning, reignited interest in AI. </p>
<p>
Deep learning algorithms demonstrated remarkable capabilities in tasks like image recognition, speech 
synthesis, and language translation.  
</p>
<p>The history of AI is marked by cycles of optimism and challenges, with each phase contributing 
    to the field's growth and development.</p>
<p>Today, AI technologies are integrated into various aspects of our lives, from voice assistants to medical diagnostics, and continue 
    to shape the future of technology and society.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/AI_Tutorial_intro">
        <ButtonPrevious />
      </ScrollToTopLink>
      <ScrollToTopLink to="/AI_types">
        <ButtonPrevious />
      </ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}