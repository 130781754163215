import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLDataTransformation() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
  <xsl:template match="/">
    <html>
      <body>
        <h2>Book List</h2>
        <table border="1">
          <tr bgcolor="#9acd32">
            <th>Title</th>
            <th>Author</th>
          </tr>
          <xsl:for-each select="catalog/book">
            <tr>
              <td><xsl:value-of select="title"/></td>
              <td><xsl:value-of select="author"/></td>
            </tr>
          </xsl:for-each>
        </table>
      </body>
    </html>
  </xsl:template>
</xsl:stylesheet>
`;

  const example2 = `
<catalog>
  <book>
    <title>Learning XML</title>
    <author>John Doe</author>
    <price>29.95</price>
  </book>
  <book>
    <title>Advanced XML</title>
    <author>Jane Smith</author>
    <price>39.95</price>
  </book>
</catalog>
`;

  const example3 = `
<xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
  <xsl:template match="/">
    <html>
      <body>
        <h2>Employee List</h2>
        <table border="1">
          <tr bgcolor="#b0e0e6">
            <th>Name</th>
            <th>Position</th>
          </tr>
          <xsl:for-each select="employees/employee">
            <tr>
              <td><xsl:value-of select="name"/></td>
              <td><xsl:value-of select="position"/></td>
            </tr>
          </xsl:for-each>
        </table>
      </body>
    </html>
  </xsl:template>
</xsl:stylesheet>
`;

  return (
    <body>
      <Helmet>
        <title>XML Data Transformation</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML Data Transformation, XSLT, XML Tutorial" />
        <meta name="description" content="Learn about XML data transformation using XSLT with examples and explanations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/XML_data_querying"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xpath"><ButtonNext /></ScrollToTopLink>
          <h1>XML Data Transformation</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XML Data Transformation involves converting XML data into other formats such as HTML, CSV, or another XML format. This is typically done using XSLT (Extensible Stylesheet Language Transformations).</p>

            <h2>Example 1: Transforming XML to HTML</h2>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This example uses XSLT to transform an XML catalog of books into an HTML table.</p>

            <h2>Example 2: XML Data Source</h2>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>This is the XML data that will be transformed in the examples.</p>

            <h2>Example 3: Transforming XML to HTML for Employee List</h2>
            <SyntaxHighlighterComponent code={example3} language="xml" />
            <p>This example uses XSLT to transform an XML list of employees into an HTML table.</p>

            <h2>Understanding XSLT</h2>
            <p>XSLT is a powerful language for transforming XML documents. It can be used to apply templates, loop through elements, and output the transformed data in various formats.</p>

            <ul>
              <li><strong>XSLT Elements:</strong> <code>&lt;xsl:stylesheet&gt;</code>, <code>&lt;xsl:template&gt;</code>, <code>&lt;xsl:for-each&gt;</code>, <code>&lt;xsl:value-of&gt;</code></li>
              <li><strong>Common Use Cases:</strong> Converting XML to HTML for web display, generating CSV files from XML data, converting XML to different XML structures.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_data_querying"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xpath"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
