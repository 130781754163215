// HTML code generation examples
export const htmlCode = [
    {
        question: 'How to create a basic HTML document?',
        answer: `
// To create a basic HTML document:
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>My Website</title>
</head>
<body>
    <h1>Hello, World!</h1>
    <p>This is a basic HTML document.</p>
</body>
</html>
        `
    },
    {
        question: 'How to create an HTML link?',
        answer: `
// To create an HTML link:
<a href="https://www.example.com">Click here</a>
        `
    },
    {
        question: 'How to add an image in HTML?',
        answer: `
// To add an image in HTML:
<img src="image.jpg" alt="Description of image">
        `
    },
    {
        question: 'How to create an unordered list in HTML?',
        answer: `
// To create an unordered list in HTML:
<ul>
    <li>Item 1</li>
    <li>Item 2</li>
    <li>Item 3</li>
</ul>
        `
    },
    {
        question: 'How to style text in HTML?',
        answer: `
// To style text in HTML:
<p style="color: blue; font-size: 16px;">Styled text</p>
        `
    },
    {
        question: 'How to create a table in HTML?',
        answer: `
// To create a table in HTML:
<table border="1">
    <tr>
        <th>Header 1</th>
        <th>Header 2</th>
    </tr>
    <tr>
        <td>Data 1</td>
        <td>Data 2</td>
    </tr>
</table>
        `
    },
    {
        question: 'How to create a form in HTML?',
        answer: `
// To create a form in HTML:
<form action="/submit-form" method="post">
    <label for="name">Name:</label>
    <input type="text" id="name" name="name"><br><br>
    <label for="email">Email:</label>
    <input type="email" id="email" name="email"><br><br>
    <input type="submit" value="Submit">
</form>
        `
    },
    {
        question: 'How to add a CSS stylesheet to an HTML document?',
        answer: `
// To add a CSS stylesheet to an HTML document:
<link rel="stylesheet" href="styles.css">
        `
    },
    {
        question: 'How to create a div element with a class in HTML?',
        answer: `
// To create a div element with a class in HTML:
<div class="container">
    <!-- Content goes here -->
</div>
        `
    },
    {
        question: 'How to embed a YouTube video in HTML?',
        answer: `
// To embed a YouTube video in HTML:
<iframe width="560" height="315" src="https://www.youtube.com/embed/video-id" frameborder="0" allowfullscreen></iframe>
        `
    },
    // Add more HTML code generation examples here...
    {
        question: 'How to create a heading in HTML?',
        answer: `
// To create a heading in HTML:
<h1>This is a Heading</h1>
        `
    },
    {
        question: 'How to create a paragraph in HTML?',
        answer: `
// To create a paragraph in HTML:
<p>This is a paragraph.</p>
        `
    },
    {
        question: 'How to create a line break in HTML?',
        answer: `
// To create a line break in HTML:
<p>This is the first line.<br>This is the second line.</p>
        `
    },
    {
        question: 'How to create a comment in HTML?',
        answer: `
// To create a comment in HTML:
<!-- This is a comment -->
        `
    },
    {
        question: 'How to create a button in HTML?',
        answer: `
// To create a button in HTML:
<button>Click me</button>
        `
    },
    {
        question: 'How to create a link that opens in a new tab?',
        answer: `
// To create a link that opens in a new tab:
<a href="https://www.example.com" target="_blank">Visit website</a>
        `
    },
    {
        question: 'How to create a text input field in HTML?',
        answer: `
// To create a text input field in HTML:
<input type="text" placeholder="Enter your name">
        `
    },
    {
        question: 'How to create a checkbox in HTML?',
        answer: `
// To create a checkbox in HTML:
<input type="checkbox" id="checkbox">
<label for="checkbox">Check me</label>
        `
    },
    {
        question: 'How to create a radio button in HTML?',
        answer: `
// To create a radio button in HTML:
<input type="radio" id="option1" name="option" value="option1">
<label for="option1">Option 1</label><br>
<input type="radio" id="option2" name="option" value="option2">
<label for="option2">Option 2</label>
        `
    },
    {
        question: 'How to create a dropdown list in HTML?',
        answer: `
// To create a dropdown list in HTML:
<select>
    <option value="option1">Option 1</option>
    <option value="option2">Option 2</option>
    <option value="option3">Option 3</option>
</select>
        `
    },
    {
        question: 'How to create a submit button in HTML?',
        answer: `
// To create a submit button in HTML:
<input type="submit" value="Submit">
        `
    },
    {
        question: 'How to create a reset button in HTML?',
        answer: `
// To create a reset button in HTML:
<input type="reset" value="Reset">
        `
    },
    {
        question: 'How to create a text area in HTML?',
        answer: `
// To create a text area in HTML:
<textarea rows="4" cols="50"></textarea>
        `
    },
    {
        question: 'How to create a bold text in HTML?',
        answer: `
// To create a bold text in HTML:
<strong>This text is bold</strong>
        `
    },
    {
        question: 'How to create an italicized text in HTML?',
        answer: `
// To create an italicized text in HTML:
<em>This text is italicized</em>
        `
    },
    {
        question: 'How to create a horizontal line in HTML?',
        answer: `
// To create a horizontal line in HTML:
<hr>
        `
    },
    {
        question: 'How to create a list item in HTML?',
        answer: `
// To create a list item in HTML:
<li>List item</li>
        `
    },
    {
        question: 'How to create a numbered list in HTML?',
        answer: `
// To create a numbered list in HTML:
<ol>
    <li>Item 1</li>
    <li>Item 2</li>
    <li>Item 3</li>
</ol>
        `
    },
    {
        question: 'How to create a definition list in HTML?',
        answer: `
// To create a definition list in HTML:
<dl>
    <dt>Term</dt>
    <dd>Definition</dd>
</dl>
        `
    },
    {
        question: 'How to create a span element in HTML?',
        answer: `
// To create a span element in HTML:
<span>This is a span</span>
        `
    },
    {
        question: 'How to create a division element in HTML?',
        answer: `
// To create a division element in HTML:
<div>This is a division</div>
        `
    },
    {
        question: 'How to create an image map in HTML?',
        answer: `
// To create an image map in HTML:
<img src="image.jpg" alt="Image" usemap="#map">
<map name="map">
    <area shape="rect" coords="0,0,50,50" href="link1.html" alt="Link 1">
    <area shape="circle" coords="100,100,50" href="link2.html" alt="Link 2">
</map>
        `
    },
    {
        question: 'How to create a meta tag in HTML?',
        answer: `
// To create a meta tag in HTML:
<meta name="description" content="Description of the page">
        `
    },
    {
        question: 'How to create a footer in HTML?',
        answer: `
// To create a footer in HTML:
<footer>This is a footer</footer>
        `
    },
    {
        question: 'How to create a header in HTML?',
        answer: `
// To create a header in HTML:
<header>This is a header</header>
        `
    },
    {
        question: 'How to create a section in HTML?',
        answer: `
// To create a section in HTML:
<section>This is a section</section>
        `
    },
    {
        question: 'How to create an article in HTML?',
        answer: `
// To create an article in HTML:
<article>This is an article</article>
        `
    },
    {
        question: 'How to create a nav element in HTML?',
        answer: `
// To create a nav element in HTML:
<nav>This is a navigation menu</nav>
        `
    },
    {
        question: 'How to create an aside element in HTML?',
        answer: `
// To create an aside element in HTML:
<aside>This is additional content</aside>
        `
    },
    {
        question: 'How to create a main element in HTML?',
        answer: `
// To create a main element in HTML:
<main>This is the main content</main>
        `
    },
    {
        question: 'How to create a time element in HTML?',
        answer: `
// To create a time element in HTML:
<time datetime="2022-12-31">December 31, 2022</time>
        `
    },
    {
        question: 'How to create a mark element in HTML?',
        answer: `
// To create a mark element in HTML:
<mark>This text is highlighted</mark>
        `
    },
    {
        question: 'How to create a meter element in HTML?',
        answer: `
// To create a meter element in HTML:
<meter value="50" min="0" max="100">50%</meter>
        `
    },
    {
        question: 'How to create a progress element in HTML?',
        answer: `
// To create a progress element in HTML:
<progress value="50" max="100">50%</progress>
        `
    },
    {
        question: 'How to create a details element in HTML?',
        answer: `
// To create a details element in HTML:
<details>
    <summary>More details</summary>
    <p>Additional information goes here</p>
</details>
        `
    },
    {
        question: 'How to create a summary element in HTML?',
        answer: `
// To create a summary element in HTML:
<details>
    <summary>More details</summary>
    <p>Additional information goes here</p>
</details>
        `
    },
    {
        question: 'How to create an audio element in HTML?',
        answer: `
// To create an audio element in HTML:
<audio controls>
    <source src="audio.mp3" type="audio/mpeg">
    Your browser does not support the audio element.
</audio>
        `
    },
    {
        question: 'How to create a video element in HTML?',
        answer: `
// To create a video element in HTML:
<video width="320" height="240" controls>
    <source src="video.mp4" type="video/mp4">
    Your browser does not support the video element.
</video>
        `
    },
    {
        question: 'How to create a canvas element in HTML?',
        answer: `
// To create a canvas element in HTML:
<canvas id="myCanvas" width="200" height="100" style="border:1px solid #000;"></canvas>
        `
    },
    {
        question: 'How to create an iframe element in HTML?',
        answer: `
// To create an iframe element in HTML:
<iframe src="https://www.example.com"></iframe>
        `
    },
    {
        question: 'How to create a script element in HTML?',
        answer: `
// To create a script element in HTML:
<script>
    alert("Hello, World!");
</script>
        `
    },
    {
        question: 'How to create a style element in HTML?',
        answer: `
// To create a style element in HTML:
<style>
    body {
        background-color: lightblue;
    }
</style>
        `
    },
    {
        question: 'How to create a header element in HTML?',
        answer: `
// To create a header element in HTML:
<header>
    <h1>My Website</h1>
</header>
        `
    },
    {
        question: 'How to create a footer element in HTML?',
        answer: `
// To create a footer element in HTML:
<footer>
    <p>Copyright &copy; 2024 My Website</p>
</footer>
        `
    },
    {
        question: 'How to create a navigation menu in HTML?',
        answer: `
// To create a navigation menu in HTML:
<nav>
    <ul>
        <li><a href="#">Home</a></li>
        <li><a href="#">About</a></li>
        <li><a href="#">Services</a></li>
        <li><a href="#">Contact</a></li>
    </ul>
</nav>
        `
    },
    {
        question: 'How to create a sidebar in HTML?',
        answer: `
// To create a sidebar in HTML:
<aside>
    <h2>Categories</h2>
    <ul>
        <li>Category 1</li>
        <li>Category 2</li>
        <li>Category 3</li>
    </ul>
</aside>
        `
    },
    {
        question: 'How to create a main content area in HTML?',
        answer: `
// To create a main content area in HTML:
<main>
    <h1>Welcome to My Website</h1>
    <p>This is the main content area.</p>
</main>
        `
    },
    {
        question: 'How to create a header, main content, and footer in HTML?',
        answer: `
// To create a header, main content, and footer in HTML:
<header>
    <h1>My Website</h1>
</header>
<main>
    <p>Welcome to My Website</p>
</main>
<footer>
    <p>Copyright &copy; 2024 My Website</p>
</footer>
        `
    },
    // Add more HTML code generation examples here...
    {
        question: 'How to create a navigation bar with dropdown menus in HTML?',
        answer: `
// To create a navigation bar with dropdown menus in HTML:
<nav>
    <ul>
        <li><a href="#">Home</a></li>
        <li><a href="#">About</a></li>
        <li class="dropdown">
            <a href="#" class="dropbtn">Services</a>
            <div class="dropdown-content">
                <a href="#">Service 1</a>
                <a href="#">Service 2</a>
                <a href="#">Service 3</a>
            </div>
        </li>
        <li><a href="#">Contact</a></li>
    </ul>
</nav>
        `
    },
    {
        question: 'How to create a responsive navigation bar in HTML?',
        answer: `
// To create a responsive navigation bar in HTML:
<nav>
    <div class="menu-icon">&#9776;</div>
    <ul class="menu">
        <li><a href="#">Home</a></li>
        <li><a href="#">About</a></li>
        <li><a href="#">Services</a></li>
        <li><a href="#">Contact</a></li>
    </ul>
</nav>
        `
    },
    {
        question: 'How to create a simple CSS grid layout in HTML?',
        answer: `
// To create a simple CSS grid layout in HTML:
<div class="grid-container">
    <div class="item1">Item 1</div>
    <div class="item2">Item 2</div>
    <div class="item3">Item 3</div>
    <div class="item4">Item 4</div>
</div>
        `
    },
    {
        question: 'How to embed a Google Map in HTML?',
        answer: `
// To embed a Google Map in HTML:
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1234.5678901234567!2dlongitude!3dlatitude!4d0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDPCsDA3JzEyLjkiTiAwwrAzMScwMS41Ilc!5e0!3m2!1sen!2sus!4v1585516780!5m2!1sen!2sus" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        `
    },
    {
        question: 'How to create a responsive image gallery in HTML?',
        answer: `
// To create a responsive image gallery in HTML:
<div class="gallery">
    <img src="img1.jpg" alt="Image 1">
    <img src="img2.jpg" alt="Image 2">
    <img src="img3.jpg" alt="Image 3">
</div>
        `
    },
    {
        question: 'How to create a countdown timer in HTML?',
        answer: `
// To create a countdown timer in HTML:
<div id="countdown"></div>
<script>
    var countDownDate = new Date("Jan 1, 2025 00:00:00").getTime();
    var x = setInterval(function() {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        document.getElementById("countdown").innerHTML = days + "d ";
    }, 1000);
</script>
        `
    },
    {
        question: 'How to create a scrolling text marquee in HTML?',
        answer: `
// To create a scrolling text marquee in HTML:
<marquee behavior="scroll" direction="left">Scrolling text goes here</marquee>
        `
    },
    {
        question: 'How to create a tooltip in HTML?',
        answer: `
// To create a tooltip in HTML:
<span class="tooltip">Hover over me<span class="tooltiptext">Tooltip text</span></span>
        `
    },
    {
        question: 'How to create a rotating banner in HTML?',
        answer: `
// To create a rotating banner in HTML:
<div class="slideshow-container">
    <div class="mySlides fade">
        <img src="img1.jpg" style="width:100%">
    </div>
    <div class="mySlides fade">
        <img src="img2.jpg" style="width:100%">
    </div>
    <div class="mySlides fade">
        <img src="img3.jpg" style="width:100%">
    </div>
</div>
<script>
    var slideIndex = 0;
    showSlides();
    function showSlides() {
        var i;
        var slides = document.getElementsByClassName("mySlides");
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slideIndex++;
        if (slideIndex > slides.length) {slideIndex = 1}
        slides[slideIndex-1].style.display = "block";
        setTimeout(showSlides, 2000); // Change image every 2 seconds
    }
</script>
        `
    },
    // Add more HTML code generation examples here...
];

