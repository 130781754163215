export const greetingData = [
    {
      question: 'hi',
      answer: 'Hello! How can I assist you today?',
    },
    {
      question: 'hello',
      answer: 'Hi there! How can I help you?',
    },
    {
      question: 'hey',
      answer: 'Hey! What can I do for you?',
    },
    {
      question: 'good morning',
      answer: 'Good morning! How may I be of service?',
    },
    {
      question: 'good afternoon',
      answer: 'Good afternoon! How can I assist you?',
    },
    {
      question: 'good evening',
      answer: 'Good evening! How can I help you today?',
    },
    {
      question: 'howdy',
      answer: 'Howdy! What can I do for you?',
    },
    {
      question: 'greetings',
      answer: 'Greetings! How may I assist you today?',
    },
    {
      question: 'hi there',
      answer: 'Hello! How can I assist you today?',
    },
    {
      question: 'hello there',
      answer: 'Hi there! How can I help you?',
    },
    {
      question: 'sup',
      answer: 'Hey! What\'s up?',
    },
    {
      question: 'what\'s up',
      answer: 'Not much, how about you?',
    },
    {
      question: 'yo',
      answer: 'Hey! What\'s happening?',
    },
    {
      question: 'how\'s it going',
      answer: 'It\'s going well, thank you. How can I assist you?',
    },
    {
      question: 'good to see you',
      answer: 'Likewise! How can I help you today?',
    },
    {
      question: 'long time no see',
      answer: 'Yes, it has been a while! How may I assist you?',
    },
    {
      question: 'nice to meet you',
      answer: 'Nice to meet you too! How can I be of service?',
    },
    {
      question: 'pleased to meet you',
      answer: 'Pleased to meet you as well! How can I assist you?',
    },
   
    {
      question: 'what\'s new',
      answer: 'Not much, just here to help you out! How can I assist you?',
    },
    {
      question: 'how\'s everything',
      answer: 'Everything is good! How can I help you today?',
    },
    {
      question: 'good day',
      answer: 'Good day to you too! How may I assist you?',
    },
    {
      question: 'how\'s your day going',
      answer: 'It\'s going well, thank you for asking. How can I assist you?',
    },
   
    {
      question: 'hello friend',
      answer: 'Hello! How can I help you today?',
    },
  
    {
      question: 'good vibes',
      answer: 'Sending good vibes your way too! How can I assist you?',
    },
    {
      question: 'good to see you',
      answer: 'Likewise! How can I help you today?',
    },
   
    {
      question: 'nice to see you',
      answer: 'Nice to see you too! How can I assist you today?',
    },
    {
      question: 'hey there',
      answer: 'Hey! How can I help you today?',
    },
    {
      question: 'what\'s happening',
      answer: 'Not much, just here to help you out! How can I assist you?',
    },
    {
      question: 'hey friend',
      answer: 'Hello! How can I assist you today?',
    },
    {
      question: 'what\'s good',
      answer: 'Not much, just here to help you out! How can I assist you?',
    },
    {
      question: 'good morning friend',
      answer: 'Good morning! How can I help you today?',
    },
    {
      question: 'good to see you friend',
      answer: 'Likewise! How can I assist you today?',
    },
    {
      question: 'nice to meet you friend',
      answer: 'Nice to meet you too! How can I be of service?',
    },
    {
      question: 'hey buddy',
      answer: 'Hey! How can I assist you today?',
    },
    {
      question: 'hello buddy',
      answer: 'Hello! How can I help you today?',
    },
    {
      question: 'good day friend',
      answer: 'Good day to you too! How may I assist you?',
    },
    {
      question: 'howdy friend',
      answer: 'Howdy! What can I do for you?',
    },
    {
      question: 'hey there friend',
      answer: 'Hey! How can I help you today?',
    },
    {
      question: 'what\'s happening friend',
      answer: 'Not much, just here to help you out! How can I assist you?',
    },
    {
      question: 'hello mate',
      answer: 'Hi! How can I assist you today?',
    },
    {
      question: 'hi mate',
      answer: 'Hello! How can I help you today?',
    },
    {
      question: 'good day mate',
      answer: 'Good day to you too! How may I assist you?',
    },
    {
      question: 'g\'day mate',
      answer: 'G\'day! How can I help you today?',
    },
    {
      question: 'howdy mate',
      answer: 'Howdy! What can I do for you?',
    },
    {
      question: 'hey mate',
      answer: 'Hey! How can I assist you today?',
    },
    // Additional informal greetings
    {
      question: 'sup',
      answer: 'Hey! What\'s up?',
    },
    {
      question: 'yo',
      answer: 'Hey! What\'s happening?',
    },
    {
      question: 'how\'s it going',
      answer: 'It\'s going well, thank you. How can I assist you?',
    },
    {
      question: 'what\'s up',
      answer: 'Not much, how about you?',
    },
    {
      question: 'how\'s tricks',
      answer: 'Tricks are good! How may I assist you?',
    },
    {
      question: 'what\'s cooking',
      answer: 'Not much, just here to help you out! How can I assist you?',
    },
 
    {
      question: 'what\'s the news',
      answer: 'Not much, just here to help you out! How can I assist you?',
    },
    {
      question: 'what\'s cracking',
      answer: 'Not much, just here to help you out! How can I assist you?',
    },
    {
      question: 'what\'s popping',
      answer: 'Not much, just here to help you out! How can I assist you?',
    },





      {
        question: "What's your favorite joke?",
        answer: "Of course! What's orange and sounds like a parrot? A carrot!"
      },
      {
        question: "Can you share a funny story?",
        answer: "Sure! Once, I accidentally wore mismatched socks to a job interview. Needless to say, it was a 'foot in mouth' situation!"
      },
      {
        question: "Do you know any good puns?",
        answer: "Why did the scarecrow win an award? Because he was outstanding in his field!"
      },
      {
        question: "Have you heard any hilarious jokes recently?",
        answer: "Yes! Why don't scientists trust atoms? Because they make up everything!"
      },
      {
        question: "How about a classic knock-knock joke?",
        answer: "Knock, knock. Who's there? Lettuce. Lettuce who? Lettuce in, it's freezing out here!"
      },
      {
        question: "Can you tell me a joke?",
        answer: "Sure! Why don't skeletons fight each other? They don't have the guts!"
      },
      {
        question: "Got any more jokes?",
        answer: "Of course! What's orange and sounds like a parrot? A carrot!"
      },
      {
        question: "Can you make me laugh?",
        answer: "Absolutely! Why did the tomato turn red? Because it saw the salad dressing!"
      },
      {
        question: "Do you have a favorite comedian?",
        answer: "Yes! I love the jokes of [Comedian's Name]. They always crack me up!"
      },
      {
        question: "Got a funny quote?",
        answer: "Sure! 'I told my wife she was drawing her eyebrows too high. She looked surprised.'"
      },
      {
        question: "Can you tell me something silly?",
        answer: "Absolutely! Did you hear about the claustrophobic astronaut? He needed space!"
      },
      {
        question: "Got any dad jokes?",
        answer: "Of course! Why don't eggs tell jokes? Because they'd crack each other up!"
      },
      {
        question: "Tell me a silly fact.",
        answer: "Did you know that a group of flamingos is called a 'flamboyance'? How silly is that!"
      },
      {
        question: "Can you share a funny meme?",
        answer: "Sure! Here's one: [insert funny meme]"
      },
      {
        question: "Got any funny animal jokes?",
        answer: "Absolutely! Why don't seagulls fly over the bay? Because then they'd be called 'bagels'!"
      },
      {
        question: "Can you tell me a one-liner?",
        answer: "Sure! I'm reading a book on anti-gravity. It's impossible to put down!"
      },
      {
        question: "Do you know any jokes about technology?",
        answer: "Yes! Why did the computer go to the doctor? Because it had a virus!"
      },
      {
        question: "Can you tell me a joke about food?",
        answer: "Of course! Why did the tomato turn red? Because it saw the salad dressing!"
      },
      {
        question: "Tell me a joke about the ocean.",
        answer: "Sure! Why did the fish blush? Because it saw the ocean's bottom!"
      },
      {
        question: "Got a funny quote about work?",
        answer: "Absolutely! 'The only time success comes before work is in the dictionary.'"
      },
      
        {
          question: "Can you tell me a joke?",
          answer: "Absolutely! Why don't skeletons fight each other? They don't have the guts!"
        },
        {
          question: "Got any jokes about food?",
          answer: "Sure! Why don't eggs tell jokes? Because they'd crack each other up!"
        },
        {
          question: "Tell me a joke about music.",
          answer: "Of course! Why did the music teacher go to jail? Because she got caught with too many sharps!"
        },
        {
          question: "Can you share a silly fact?",
          answer: "Sure! Did you know that squirrels plant thousands of new trees each year simply by forgetting where they put their acorns?"
        },
        {
          question: "Got any funny animal jokes?",
          answer: "Absolutely! Why did the cow go to outer space? To see the moooon!"
        },
        {
          question: "Can you tell me a one-liner?",
          answer: "Sure! I used to be a baker, but I couldn't make enough dough."
        },
        {
          question: "Tell me a joke about technology.",
          answer: "Of course! Why did the computer keep freezing? Because it left its Windows open!"
        },
        {
          question: "Can you share a joke about the weather?",
          answer: "Sure! Why did the stormtrooper buy an umbrella? Because he heard there would be a few showers!"
        },
        {
          question: "Do you know any jokes about space?",
          answer: "Yes! Why don't astronauts make good poker players? Because they always face a higher card!"
        },
        {
          question: "Got any puns?",
          answer: "Absolutely! I'm reading a book on anti-gravity. It's impossible to put down!"
        }
      
      
    
    
  ];
  