export const australiaData = [
    {
      question: "What is the national flower emblem of Australia?",
      answer: "Golden Wattle"
    },
    {
      question: "Which Australian state is known for its mining industry, particularly for iron ore and gold?",
      answer: "Western Australia"
    },
    {
      question: "Who was the first Prime Minister of Australia?",
      answer: "Edmund Barton"
    },
    {
      question: "What is the name of the highest mountain in Australia?",
      answer: "Mount Kosciuszko"
    },
    {
      question: "Which Australian state is known for its opal mining?",
      answer: "South Australia"
    },
    {
      question: "What is the name of the Australian indigenous people?",
      answer: "Aboriginal Australians"
    },
    {
      question: "What is the name of the famous Australian rock band formed in Sydney in 1973?",
      answer: "AC/DC"
    },
    {
      question: "Which Australian city hosted the 2000 Summer Olympics?",
      answer: "Sydney"
    },
    {
      question: "What is the name of the iconic desert in central Australia?",
      answer: "Simpson Desert"
    },
    {
      question: "Which Australian state is home to the Great Dividing Range?",
      answer: "New South Wales"
    },
    {
      question: "What is the name of the Australian territory located between South Australia and Western Australia?",
      answer: "Northern Territory"
    },
    {
      question: "Which Australian state is known as the 'Garden State'?",
      answer: "Victoria"
    },
    {
      question: "What is the name of the famous opera singer born in Melbourne, Australia, known as the 'People's Diva'?",
      answer: "Dame Joan Sutherland"
    },
    {
      question: "Which Australian state is famous for its stunning coastline known as the Great Ocean Road?",
      answer: "Victoria"
    },
    {
      question: "What is the name of the Australian currency coin with a value of one dollar?",
      answer: "Australian Dollar (AUD)"
    },
    {
      question: "Which Australian state is known for its large coal reserves and coal mining industry?",
      answer: "Queensland"
    },
    {
      question: "What is the name of the Australian marsupial known for its powerful kick?",
      answer: "Eastern Grey Kangaroo"
    },
    {
      question: "Which famous Australian cricketer is known as 'Punter'?",
      answer: "Ricky Ponting"
    },
    {
      question: "What is the name of the famous Australian dessert made with layers of cake, chocolate, and cream?",
      answer: "Lamington"
    },
    {
      question: "Which Australian city is often referred to as the 'City of Churches'?",
      answer: "Adelaide"
    },
    {
      question: "What is the name of the Australian airline founded in 1920 and now one of the largest airlines in the world?",
      answer: "Qantas"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Kangaroo Island?",
      answer: "South Australia"
    },
    {
      question: "What is the name of the Australian aboriginal instrument that is similar to a wind instrument?",
      answer: "Didgeridoo"
    },
    {
      question: "Which Australian state is known for its vast red sandy desert, known as the 'Red Centre'?",
      answer: "Northern Territory"
    },
    {
      question: "What is the name of the famous Australian landmark that consists of 12 apostles rock formations?",
      answer: "Twelve Apostles"
    },
    {
      question: "Which Australian state is known for its wine production regions such as Barossa Valley and McLaren Vale?",
      answer: "South Australia"
    },
    {
      question: "What is the name of the indigenous Australian hunting tool consisting of a wooden stick with a sharp end?",
      answer: "Boomerang"
    },
    {
      question: "Which Australian state is home to the world-famous wine region known as Margaret River?",
      answer: "Western Australia"
    },
    {
      question: "What is the name of the large coral reef system located off the northeast coast of Australia?",
      answer: "Great Barrier Reef"
    },
    {
      question: "Which Australian state is known for its unique pink lake, Lake Hillier?",
      answer: "Western Australia"
    },
    {
      question: "What is the name of the Australian desert that is the largest sand dune desert in the world?",
      answer: "Simpson Desert"
    },
    {
      question: "Which Australian state is known for its vast open plains and is often referred to as the 'Outback'?",
      answer: "Queensland"
    },
    {
      question: "What is the name of the Australian city that is home to the iconic Bondi Beach?",
      answer: "Sydney"
    },
    {
      question: "Which Australian state is known for its pink granite mountains, known as the 'Granite Belt'?",
      answer: "Queensland"
    },
    {
      question: "What is the name of the Australian indigenous instrument made of hollowed-out wood and played by tapping it?",
      answer: "Clapsticks"
    },
    {
      question: "Which Australian state is known for its large salt lake system, including Lake Eyre?",
      answer: "South Australia"
    },
    {
      question: "What is the name of the famous Australian dessert consisting of a chocolate sponge cake and cream, named after a city in Australia?",
      answer: "Pavlova"
    },
    {
      question: "Which Australian state is known for its pink granite peaks in the Grampians National Park?",
      answer: "Victoria"
    },
    {
      question: "What is the name of the Australian bird known for its distinctive laughing call?",
      answer: "Kookaburra"
    },
    {
      question: "Which Australian state is known for its unique wildlife including Tasmanian devils and wombats?",
      answer: "Tasmania"
    },
    {
      question: "What is the name of the Australian reptile that is known for its ability to change color and camouflage?",
      answer: "Chameleon Dragon"
    },
    {
      question: "Which Australian state is known for its unique pink and white sandstone formations called the 'Pinnacles'?",
      answer: "Western Australia"
    },
    {
      question: "What is the name of the Australian fruit known for its distinct smell and taste, often described as a combination of banana and mango?",
      answer: "Durian"
    },
    {
      question: "Which Australian state is known for its unique mountain range known as the 'Blue Mountains'?",
      answer: "New South Wales"
    },
    {
      question: "What is the name of the Australian lizard known for its frilled neck and ability to run on two legs?",
      answer: "Frilled Lizard"
    },
    {
      question: "Which Australian state is known for its unique pink lakes, such as Lake Eyre and Lake Hillier?",
      answer: "Western Australia"
    },
    {
      question: "What is the name of the Australian animal known for its nocturnal habits and pouch used for carrying its young?",
      answer: "Wombat"
    },
    {
      question: "Which Australian state is known for its unique rock formation called 'Wave Rock'?",
      answer: "Western Australia"
    },
    {
      question: "What is the name of the Australian bird known for its large size and distinctive laugh?",
      answer: "Laughing Kookaburra"
    },
    {
      question: "Which Australian state is known for its unique pink lake, Lake Hillier?",
      answer: "Western Australia"
    },
    {
      question: "What is the name of the Australian animal known for its spiky appearance and nocturnal habits?",
      answer: "Echidna"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Lone Pine Koala Sanctuary?",
      answer: "Queensland"
    },
    {
      question: "What is the name of the Australian animal known for its hopping gait and long powerful hind legs?",
      answer: "Kangaroo"
    },
    {
      question: "Which Australian state is known for its unique pink granite formations called 'Stirling Ranges'?",
      answer: "Western Australia"
    },
    {
      question: "What is the name of the Australian animal known for its swimming abilities and duck-like bill?",
      answer: "Platypus"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Currumbin Wildlife Sanctuary?",
      answer: "Queensland"
    },
    {
      question: "What is the name of the Australian bird known for its ability to mimic human speech?",
      answer: "Cockatoo"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Healesville Sanctuary?",
      answer: "Victoria"
    },
    {
      question: "What is the name of the Australian bird known for its distinctive laugh-like call?",
      answer: "Kookaburra"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Australian Reptile Park?",
      answer: "New South Wales"
    },
    {
      question: "What is the name of the Australian bird known for its brightly colored feathers and curved beak?",
      answer: "Rainbow Lorikeet"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Featherdale Wildlife Park?",
      answer: "New South Wales"
    },
    {
      question: "What is the name of the Australian marsupial known for its distinctive backward-facing pouch?",
      answer: "Koala"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Taronga Zoo?",
      answer: "New South Wales"
    },
    {
      question: "What is the name of the Australian bird known for its large size and powerful talons?",
      answer: "Wedged-tailed Eagle"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Australia Zoo?",
      answer: "Queensland"
    },
    {
      question: "What is the name of the Australian reptile known for its long, slender body and venomous bite?",
      answer: "Taipan Snake"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Melbourne Zoo?",
      answer: "Victoria"
    },
    {
      question: "What is the name of the Australian bird known for its ability to mimic sounds, including human speech?",
      answer: "Lyrebird"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Perth Zoo?",
      answer: "Western Australia"
    },
    {
      question: "What is the name of the Australian reptile known for its distinctive blue tongue?",
      answer: "Blue-tongued Lizard"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Adelaide Zoo?",
      answer: "South Australia"
    },
    {
      question: "What is the name of the Australian bird known for its striking black and white plumage?",
      answer: "Magpie"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Ballarat Wildlife Park?",
      answer: "Victoria"
    },
    {
      question: "What is the name of the Australian reptile known for its ability to change color?",
      answer: "Chameleon Dragon"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Canberra Walk-in Aviary?",
      answer: "Australian Capital Territory"
    },
    {
      question: "What is the name of the Australian bird known for its distinctive helmet-like casque?",
      answer: "Cassowary"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Gorge Wildlife Park?",
      answer: "South Australia"
    },
    {
      question: "What is the name of the Australian reptile known for its ability to glide through the air?",
      answer: "Flying Lizard"
    },
    {
      question: "Which Australian state is known for its unique wildlife sanctuary called Bonorong Wildlife Sanctuary?",
      answer: "Tasmania"
    }
  ];
  