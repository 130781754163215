import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLXPathExpressions() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<bookstore>
  <book category="cooking">
    <title lang="en">Everyday Nigerian</title>
    <author>Uche Anyanwu</author>
    <year>2008</year>
    <price>31.00</price>
  </book>
  <book category="children">
    <title lang="en">Harry Potter</title>
    <author>J K. Rowling</author>
    <year>2005</year>
    <price>29.99</price>
  </book>
  <book category="web">
    <title lang="en">XML Tutorial</title>
    <author>Beta Thead</author>
    <year>2023</year>
    <price>39.95</price>
  </book>
</bookstore>
`;

  const example2 = `
XPath: /bookstore/book[1]/title
Result: 
<title lang="en">Everyday Nigerian</title>
`;

  const example3 = `
XPath: /bookstore/book[last()]/title
Result: 
<title lang="en">XML Tutorial</title>
`;

  const example4 = `
XPath: /bookstore/book[position()<3]/title
Result: 
<title lang="en">Everyday Nigerian</title>
<title lang="en">Harry Potter</title>
`;

  return (
    <body>
      <Helmet>
        <title>XPath Expressions</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML XPath Expressions, XML Tutorial, XML Data Querying" />
        <meta name="description" content="Learn how to use XPath expressions for querying XML data with examples and explanations of the syntax and usage." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/XML_xpath"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xpath_functions"><ButtonNext /></ScrollToTopLink>
          <h1>XPath Expressions</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XPath expressions are used to navigate through elements and attributes in an XML document. They can be used to select nodes or node-sets in an XML document.</p>

            <h2>Example 1: Basic XML Structure</h2>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This example shows a basic XML structure with a bookstore containing books. We will use XPath expressions to query this data.</p>

            <h2>Example 2: Select the First Book Title</h2>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>This example demonstrates an XPath expression that selects the title of the first book in the bookstore.</p>

            <h2>Example 3: Select the Last Book Title</h2>
            <SyntaxHighlighterComponent code={example3} language="xml" />
            <p>This example demonstrates an XPath expression that selects the title of the last book in the bookstore.</p>

            <h2>Example 4: Select Titles of the First Two Books</h2>
            <SyntaxHighlighterComponent code={example4} language="xml" />
            <p>This example demonstrates an XPath expression that selects the titles of the first two books in the bookstore.</p>

            <h2>Understanding XPath Expressions</h2>
            <p>XPath expressions use a path-like syntax to navigate through elements and attributes. They can be used to select nodes, filter nodes based on conditions, and navigate through complex XML structures.</p>

            <ul>
              <li><strong>Basic XPath Syntax:</strong> <code>/</code> for absolute path, <code>//</code> for relative path, <code>@</code> for attributes.</li>
              <li><strong>Common Use Cases:</strong> Selecting specific elements, filtering elements based on conditions, navigating through complex XML structures.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_xpath"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xpath_functions"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
