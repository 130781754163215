import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from "../css/styles.module.css";
import "../css/my style.css";
import ScrollToTopLink from "../components/ScrollToTop";
import { saveAuthStatus } from '../localstorage'; // Import the saveAuthStatus function
import { FaEnvelope, FaEye, FaEyeSlash, FaLock, FaTimes, FaUser } from "react-icons/fa";
import Verified from './Verified'; // Import the Verified component

const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 16;

function Signup() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    uppercase: false,
    specialCharacter: false,
  });
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!username || !email || !password) {
      alert("Please fill out all fields");
      return;
    }
  
    if (!passwordRequirements.uppercase || !passwordRequirements.specialCharacter || password.length < MIN_PASSWORD_LENGTH) {
      alert("Password requirements not met");
      return;
    }
  
    try {
      const response = await fetch("https://betathread.com/auth/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, email, password }),
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage || "Signup failed. Please try again later.");
      }
  
      const data = await response.json();
      alert(data.message);  // Assuming response contains a message like "Verification email sent!"
  
      // Save the signup timestamp in local storage
      const signupTimestamp = new Date().toISOString();
      saveAuthStatus({ status: 'signup', email, signupTimestamp });
      navigate("/verify")
   
    } catch (error) {
      console.error("Signup Error:", error);
      alert("Signup failed. Please try again later.");
    }
  };
  

  const checkPasswordRequirements = (value) => {
    setPasswordRequirements({
      uppercase: /[A-Z]/.test(value),
      specialCharacter: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value),
    });
  };

  useEffect(() => {
    checkPasswordRequirements(password);
  }, [password]);

  

  return (
    <body>
      <Helmet>
        <title>Signup | Beta Thread</title>
        <meta http-equiv="X-UA-compatible" content="IE-edge"/>
        <meta name="Keywords" content="Signup Page, signup for an account on Beta Thread, create your account, How to signup on Beta Thread, HTML, Python, CSS, SQL, JavaScript, How to, PHP, XML, MySQL, React, AI, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, Examples, Learn to code, Coding the future, Source code, Website." />
        <meta name="description" content="Signup and create your account on Beta Thread" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      </Helmet>
      <div className={styles.logoContainer}>
        <ScrollToTopLink to="/">
          <img src="./images/betathread-light.png" className={styles.homebtn} alt="Home Page" />
        </ScrollToTopLink>
      </div>
      <div className={styles.closeIconContainer}>
        <button className={styles.closebtn} onClick={handleGoBack}>
          <i className="material-icons"><FaTimes /></i>
        </button>
      </div>
      <div className={styles.container}>
        <div className={styles.form_container}>
          <div className={styles.left}>      
            <img className={styles.img} src="./images/signup.jpg" alt="signup" />
          </div>
          
          <div className={styles.right}>
            <h2 className={styles.heading}>Signup</h2>
            <br />
            <p className={styles.text}>
              Already have an account? <Link to="/login">Log In</Link>
            </p>
            <form onSubmit={handleSubmit}>
              <div method='post'>
                <div className="input-cnt">
                  <input
                    type="text"
                    name="name"
                    value={username}
                    className="input"
                    placeholder="Enter your name"
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  <i className="material-icons"><FaUser /></i>
                </div>
                <div className="input-cnt">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    className="input"
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <i className="material-icons"><FaEnvelope /></i>
                </div>
                <div className="input-cnt">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={password}
                    className="input"
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <i
                    className="material-icons"
                    id="beta"
                    title="show or hide password"
                    onClick={handleTogglePassword}
                    style={{ cursor: 'pointer' }}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </i>
                  <i className="material-icons"><FaLock /></i>
                </div>
                <p className={styles.requirementText}>Note: Password must contain</p>
                <div className={styles["password-requirements"]}>
                  <div className={styles.requirement}>
                    <span className={passwordRequirements.uppercase ? styles.dotGreen : styles.dotRed}></span>
                    <p className={styles.requirementText}>At least one uppercase letter</p>
                  </div>
                  <div className={styles.requirement}>
                    <span className={passwordRequirements.specialCharacter ? styles.dotGreen : styles.dotRed}></span>
                    <p className={styles.requirementText}>At least one special character</p>
                  </div>
                  <div className={styles.requirement}>
                    <span className={password.length >= MIN_PASSWORD_LENGTH && password.length <= MAX_PASSWORD_LENGTH ? styles.dotGreen : styles.dotRed}></span>
                    <p className={styles.requirementText}>Between {MIN_PASSWORD_LENGTH} to {MAX_PASSWORD_LENGTH} characters</p>
                  </div>
                </div>
                <br />
                <label>
                  <input
                    type="checkbox"
                    name="remember"
                    style={{ marginBottom: "0px" }}
                  />
                  <span className={styles.text}>
                    By signing up you agree to our
                    <ScrollToTopLink to="/terms&privacy"><u>Terms of service</u></ScrollToTopLink>
                  </span>
                </label>
                <br />
                <button
                  className="button"
                  style={{ backgroundColor: "darkblue", color: "white", width: "80%", maxWidth: "100%", borderRadius: "12px", fontSize: "16px", fontWeight: "normal", fontFamily: "inherit" }}
                  type="submit"
                  disabled={!passwordRequirements.uppercase || !passwordRequirements.specialCharacter || password.length < MIN_PASSWORD_LENGTH || password.length > MAX_PASSWORD_LENGTH } // Disable button if requirements not met
                  
                >
                  Sign up
                </button>
                <br />
                <br />
                <br />
              </div>
            </form>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Signup;