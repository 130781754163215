import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLFullOuterJoin() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const fullOuterJoinCode = `
-- FULL OUTER JOIN Example
SELECT employees.employee_id, employees.employee_name, departments.department_name
FROM employees
FULL OUTER JOIN departments ON employees.department_id = departments.department_id;
  `;

  const employeesTable = `
CREATE TABLE employees (
    employee_id INT PRIMARY KEY,
    employee_name VARCHAR(100),
    department_id INT
);

INSERT INTO employees (employee_id, employee_name, department_id) VALUES
(1, 'John Doe', 1),
(2, 'Jane Smith', 2),
(3, 'Emily Davis', 1),
(4, 'Michael Brown', 3),
(5, 'Linda White', NULL);
  `;

  const departmentsTable = `
CREATE TABLE departments (
    department_id INT PRIMARY KEY,
    department_name VARCHAR(100)
);

INSERT INTO departments (department_id, department_name) VALUES
(1, 'Human Resources'),
(2, 'Finance'),
(3, 'IT'),
(4, 'Marketing');
  `;

  return (
    <body>
      <Helmet>
        <title>SQL FULL OUTER JOIN</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL FULL OUTER JOIN, SQL JOIN Example, SQL Tutorial, FULL OUTER JOIN Syntax" />
        <meta name="description" content="Learn about SQL FULL OUTER JOIN, including syntax, examples, and how to use FULL OUTER JOIN to combine data from multiple tables." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_right_join"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_cross_join"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL FULL OUTER JOIN</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>A <code>FULL OUTER JOIN</code> returns all records when there is a match in either left (table1) or right (table2) table records. It means that if there are records in "table1" that do not have matches in "table2", or if there are records in "table2" that do not have matches in "table1", those records will be listed as well.</p>

            <h2>Example Tables</h2>
            <p>Consider the following tables:</p>
            <SyntaxHighlighterComponent code={employeesTable} language="sql" />
            <SyntaxHighlighterComponent code={departmentsTable} language="sql" />

            <h2>FULL OUTER JOIN Example</h2>
            <p>To retrieve all employees and their corresponding department names, including those employees who do not belong to any department and those departments that do not have any employees, we use a <code>FULL OUTER JOIN</code>:</p>
            <SyntaxHighlighterComponent code={fullOuterJoinCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The query selects all employee details along with their corresponding department names where there is a match in <code>department_id</code>. If there is no match, it returns NULL for the unmatched columns.</li>
            </ul>

            <h2>Result of the FULL OUTER JOIN</h2>
            <p>If we execute the above query on the sample tables, we would get the following result:</p>
            <table>
              <thead>
                <tr>
                  <th>employee_id</th>
                  <th>employee_name</th>
                  <th>department_name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>John Doe</td>
                  <td>Human Resources</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Jane Smith</td>
                  <td>Finance</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Emily Davis</td>
                  <td>Human Resources</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Michael Brown</td>
                  <td>IT</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Linda White</td>
                  <td>NULL</td>
                </tr>
                <tr>
                  <td>NULL</td>
                  <td>NULL</td>
                  <td>Marketing</td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_right_join"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_cross_join"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}

