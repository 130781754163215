import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactComponents() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    import React from 'react';

    const Greeting = (props) => {
      return <h1>Hello, {props.name}!</h1>;
    };`

    const code2=`
    import React, { Component } from 'react';

    class Counter extends Component {
      constructor(props) {
        super(props);
        this.state = { count: 0 };
      }
    
      render() {
        return (
          <div>
            <p>Count: {this.state.count}</p>
            <button onClick={() => this.setState({ count: this.state.count + 1 })}>
              Increment
            </button>
          </div>
        );
      }
    }    `
  
     const code3=`
     import React from 'react';

     const ParentComponent = () => {
       const name = 'John Doe';
       return (
         <div>
           <ChildComponent name={name} />
         </div>
       );
     };
     
     const ChildComponent = (props) => {
       return <h1>Hello, {props.name}!</h1>;
     };    `

         const code4=`
         import React from 'react';

         const Greeting = (props) => {
           return <h1>Hello, {props.name}!</h1>;
         };
         
         const App = () => {
           return (
             <div>
               <Greeting name="John Doe" />
             </div>
           );
         };         `
        const code5=`
        import React, { Component } from 'react';

        class Counter extends Component {
          constructor(props) {
            super(props);
            this.state = { count: 0 };
          }
        
          render() {
            return (
              <div>
                <p>Count: {this.state.count}</p>
                <button onClick={() => this.setState({ count: this.state.count + 1 })}>
                  Increment
                </button>
              </div>
            );
          }
        }
        
        const App = () => {
          return (
            <div>
              <Counter />
            </div>
          );
        };        `
        
    return (
   <body>
     <Helmet>
        
        <title>React Components</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React Components, What are React Components?, Types of React Components, Functional Components, Class Components, Component Lifecycle Methods, Props, Functional Component Example, Class Component Example,
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="React components are the building blocks of a React application. They are reusable pieces of code that represent a part of your user interface." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_jsx"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_state"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React Components</h1>
</header>

<Navbar />

<main>
<section>
    <h1>What are React Components?</h1>
<p>React components are the building blocks of a React application.</p>
  <p>They are reusable pieces of code that represent a part of your user interface.</p>

  <p>Components can contain other components, and they can also contain JavaScript code and markup.</p>
<h2>Types of React Components</h2>
<p>There are two main types of React components:</p>
<ol type="1">
    <li><b>Functional Components:</b> These are pure functions that take in props and return JSX elements. They are simple and easy to use, but they don't have access to lifecycle methods or state.</li>
    <li><b>Class Components: </b> These are classes that extend the React.Component class. They have access to lifecycle methods and state, but they are more complex and require more boilerplate code.</li>
</ol>

<h2>Functional Components</h2>
<p>Functional components are the simplest type of React component. They are pure functions that take in props and return JSX elements.</p>
<p>Here is an example of a functional component:</p>
  <h2>Example</h2>
 <SyntaxHighlighterComponent code={code} language="js" />
<p>In this example, the Greeting component takes in a name prop and returns an '&lt;h1&gt;' element with the greeting message.</p>

 <h2>Class Components</h2>
<p>Class components are more complex than functional components. They have access to lifecycle methods and state, but they require more boilerplate code.</p>
<p>Here is an example of a class component:</p>
 <SyntaxHighlighterComponent code={code2} language="js" />
<p>In this example, the Counter component has a count state property and a render method that returns the UI for the component.</p>

 <h2>Component Lifecycle Methods</h2>
 <ol type="1">
    <li>constructor: This method is called when the component is created.</li>
    <li>render: This method is called when the component is rendered.</li>
    <li>componentDidMount: This method is called after the component has been mounted.</li>
    <li>componentDidUpdate: This method is called after the component has been updated.</li>
    <li>componentWillUnmount: This method is called before the component is unmounted.</li>
 </ol>

 <h2>Props</h2>
 <p>Props are short for "properties" and are how you pass data from a parent component to a child component. </p>
 <p>Props are read-only and cannot be changed by the child component.</p>
 <p>Here is an example of how to pass props from a parent component to a child component:</p>
 <SyntaxHighlighterComponent code={code3} language="js" />
 <p>In this example, the ParentComponent passes the name prop to the ChildComponent using the name=&#123;name&#125; syntax. 
 The ChildComponent then accesses the name prop using the props.name syntax.</p>

 <h2>Functional Component Example</h2>
 <SyntaxHighlighterComponent code={code4} language="js" />

 <h2>Class Component Example</h2>
 <SyntaxHighlighterComponent code={code5} language="js" />

</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_jsx"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_state"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}