import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLsuperscript() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <p>Learning <sup>is</sup> fun</p>
      <p>2<sup>3</sup> + 2<sup>3</sup> = 4<sup>2</sup></p>
  `;

 
  

  
    return (
   <body>
     <Helmet>    
        <title>HTML Superscript</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Superscript, html superscript, html superscript example, What is a superscript, html <sup> element/tag
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="In the previous lesson you learnt how to use subscripts now you will learn superscripts.
        A text or number can be displayed as a superscript using the <sup> tag." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_subscript"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_figures"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Superscript</h1>
</header>

<Navbar />

<main>

<section>
<p>In the previous lesson you learnt how to use subscripts now you will learn superscripts.</p>
<p>A text or number can be displayed as a superscript using the &lt;sup&gt; tag.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html46"><Tryitout /></ScrollToTopLink>
</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_subscript"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_figures"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}