import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from "../css/styles.module.css";
import { FaTimes } from "react-icons/fa";
import { getAuthStatus, saveAuthStatus } from '../localstorage'; // Ensure this path is correct
import ScrollToTopLink from "../components/ScrollToTop";

 const Verified = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook



  const handleResendLink = async () => {
    const email = localStorage.getItem('email'); // Assuming email is stored in local storage
    try {
      const response = await fetch('https://betathread.com/auth/resend-verification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const result = await response.json();
      if (response.ok) {
        alert("Verification link sent successfully.");
      } else {
        alert(result.error || "Error resending verification link. Please try again.");
      }
    } catch (error) {
      alert("Error resending verification link. Please try again.");
    }
  };


  const headingStyle = {
    fontSize: '24px',
    marginBottom: '20px',
  };

  const textStyle = {
    fontSize: '16px',
    marginBottom: '10px',
  };

  const linkStyle = {
    color: '#007BFF',
    cursor: 'pointer',
    textDecoration: 'underline',
  };

  return (
    <div>
     
      <div className={styles.logoContainer}>   
          <img src="./images/betathread-light.png" className={styles.homebtn} alt="Home Page" />
      </div>
   
      <div className={styles.container}>
        <div className={styles.form_container}>
          <div className={styles.left}>
            <img className={styles.img} src="./images/signup.jpg" alt="signup" />
          </div>
          <div className={styles.right}>
            <h2 style={headingStyle}>Verify Your Email</h2>
            <p style={textStyle}>Please check your email for the verification link.</p>
            <p style={textStyle}>If you do not see the email, please check your spam folder.</p>
            <p style={textStyle}>Didn't receive the email? <span onClick={handleResendLink} style={linkStyle}>Resend</span></p>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verified
