import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import toggle from "../responsivetopnav";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../ScrollToTop";
import { useLocation } from 'react-router-dom';
import XTIPSSearchBar from "../Searchbar/XTIPS SearchBar/XTIPSSearchBar";

export default function XTipsSidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  var nav = {
    color: 'white',
    fontSize: '30px'
}
var newCode = {
  float: 'right'
}
    return (
      <div>
      <div className={`sidebar ${isOpen ? 'responsive' : ''}`} id="mySidebar">
        <span className="close" onClick={toggle}>&times;</span>
    <h2>Tutorial Tips</h2><br />
    <span><XTIPSSearchBar /></span><br />
    <p>...Empowering Your Online Presence...</p><br />
    <ScrollToTopLink to="/tips"><a className={location.pathname === '/tips' ? 'active' : ''}>Tips Home</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/html_tips"><a className={location.pathname === '/html_tips' ? 'active' : ''}>HTML Tips</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/css_tips"><a className={location.pathname === '/css_tips' ? 'active' : ''}>CSS Tips</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/js_tips"><a className={location.pathname === '/js_tips' ? 'active' : ''}>JavaScript Tips</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/php_tips"><a className={location.pathname === '/php_tips' ? 'active' : ''}>PHP Tips</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/python_tips"><a className={location.pathname === '/python_tips' ? 'active' : ''}>Python Tips</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/sql_tips"><a className={location.pathname === '/sql_tips' ? 'active' : ''}>SQL Tips</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/ruby_tips"><a className={location.pathname === '/ruby_tips' ? 'active' : ''}>Ruby Tips</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/xml_tips"><a className={location.pathname === '/xml_tips' ? 'active' : ''}>XML Tips</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/react_tips"><a className={location.pathname === '/react_tips' ? 'active' : ''}>React Tips</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/ai_tips"><a className={location.pathname === '/ai_tips' ? 'active' : ''}>AI Tips</a></ScrollToTopLink><br />
</div>
  
  
  <div className="burger-icon" onClick={toggle}>
    &#9776;
    <p style={newCode}>Menu</p>
  </div>
</div>
    )
}