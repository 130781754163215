import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyConditionals() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const person = { name: 'Alice', age: 30 };  // Define your object here

  const ifStatementCode = `
# If Statement
x = 10
if x > 5
  puts "x is greater than 5"
end
# Output: x is greater than 5
  `;

  const elseStatementCode = `
# If-Else Statement
x = 3
if x > 5
  puts "x is greater than 5"
else
  puts "x is not greater than 5"
end
# Output: x is not greater than 5
  `;

  const elsifStatementCode = `
# If-Elsif-Else Statement
x = 7
if x > 10
  puts "x is greater than 10"
elsif x > 5
  puts "x is greater than 5 but not greater than 10"
else
  puts "x is 5 or less"
end
# Output: x is greater than 5 but not greater than 10
  `;

  const unlessStatementCode = `
# Unless Statement
x = 3
unless x > 5
  puts "x is not greater than 5"
end
# Output: x is not greater than 5
  `;

  const caseStatementCode = `
# Case Statement
grade = "B"
case grade
when "A"
  puts "Excellent"
when "B"
  puts "Good"
when "C"
  puts "Fair"
when "D"
  puts "Poor"
when "E"
  puts "Very Poor"
else
  puts "Fail"
end
# Output: Good
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Conditional Statements</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Conditional Statements, If Statement, If-Else Statement, Elsif Statement, Unless Statement, Case Statement" />
        <meta name="description" content="Learn about conditionals in Ruby, including if, if-else, elsif, unless, and case statements." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_operators"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_loops"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Conditional Statements</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Conditionals in Ruby allow you to execute code based on certain conditions. The most common conditional statements in Ruby are <code>if</code>, <code>if-else</code>, <code>elsif</code>, <code>unless</code>, and <code>case</code>.</p>

            <h2>If Statement</h2>
            <p>The <code>if</code> statement executes code if a condition is true.</p>
            <SyntaxHighlighterComponent code={ifStatementCode} language="ruby" />
            <p>In the above example, the code inside the <code>if</code> block runs because <code>x</code> is greater than 5.</p>

            <h2>If-Else Statement</h2>
            <p>The <code>if-else</code> statement executes one block of code if a condition is true, and another block if it is false.</p>
            <SyntaxHighlighterComponent code={elseStatementCode} language="ruby" />
            <p>In the above example, the code inside the <code>else</code> block runs because <code>x</code> is not greater than 5.</p>

            <h2>Elsif Statement</h2>
            <p>The <code>elsif</code> statement is used to specify a new condition if the previous <code>if</code> condition is false.</p>
            <SyntaxHighlighterComponent code={elsifStatementCode} language="ruby" />
            <p>In the above example, the code inside the <code>elsif</code> block runs because <code>x</code> is greater than 5 but not greater than 10.</p>

            <h2>Unless Statement</h2>
            <p>The <code>unless</code> statement executes code if a condition is false.</p>
            <SyntaxHighlighterComponent code={unlessStatementCode} language="ruby" />
            <p>In the above example, the code inside the <code>unless</code> block runs because <code>x</code> is not greater than 5.</p>

            <h2>Case Statement</h2>
            <p>The <code>case</code> statement tests a value against a series of conditions and executes the corresponding block of code.</p>
            <SyntaxHighlighterComponent code={caseStatementCode} language="ruby" />
            <p>In the above example, the code inside the <code>when "B"</code> block runs because <code>grade</code> is "B".</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_operators"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_loops"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
