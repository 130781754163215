import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactForms() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    import React, { useState } from 'react';

    const SimpleForm = () => {
      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
    
      const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form submitted:', { name, email });
      };
    
      return (
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" value={name} onChange={(event) => setName(event.target.value)} />
          </label>
          <label>
            Email:
            <input type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
          </label>
          <button type="submit">Submit</button>
        </form>
      );
    }; `

    const code2=`
    import React, { useState } from 'react';

    const FormWithValidation = () => {
      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [error, setError] = useState(null);
    
      const handleSubmit = (event) => {
        event.preventDefault();
        if (!name || !email) {
          setError('Please fill out all fields');
        } else {
          console.log('Form submitted:', { name, email });
        }
      };
    
      return (
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" value={name} onChange={(event) => setName(event.target.value)} />
          </label>
          <label>
            Email:
            <input type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
          </label>
          {error && <div style={{ color: 'red' }}>{error}</div>}
          <button type="submit">Submit</button>
        </form>
      );
    }; `
  
     const code3=`
     import React, { useState } from 'react';

     const Form = () => {
       const [name, setName] = useState('');
       const [email, setEmail] = useState('');
       const [error, setError] = useState(null);
     
       const handleSubmit = (event) => {
         event.preventDefault();
         if (!name || !email) {
           setError('Please fill out all fields');
         } else {
           console.log('Form submitted:', { name, email });
         }
       };
     
       const formFields = [
         {
           label: 'Name',
           type: 'text',
           value: name,
           onChange: (event) => setName(event.target.value),
         },
         {
           label: 'Email',
           type: 'email',
           value: email,
           onChange: (event) => setEmail(event.target.value),
         },
       ];
     
       return (
         <form onSubmit={handleSubmit}>
           {formFields.map((field, index) => (
             <label key={index}>
               {field.label}:
               <input type={field.type} value={field.value} onChange={field.onChange} />
             </label>
           ))}
           {error && <div style={{ color: 'red' }}>{error}</div>}
           <button type="submit">Submit</button>
         </form>
       );
     }; `

      
        
    return (
   <body>
     <Helmet>
        
        <title>React Forms</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React Forms, Why Use React Forms?, Simple Form, Form with Validation, Form with Reusable Form Logic,
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="React Forms are a way to handle user input in React applications. They allow you to create forms, handle form submissions, and validate user input." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_keys"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_router"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React Forms</h1>
</header>

<Navbar />

<main>
<section>
   
<p>React Forms are a way to handle user input in React applications.</p>
<p>They allow you to create forms, handle form submissions, and validate user input.</p>
 <h2>Why Use React Forms?</h2>
 <ul>
    <li>Handle user input in a declarative way</li>
    <li>Validate user input with built-in validation features</li>
    <li>Easily handle form submissions and errors</li>
    <li>Reuse form logic across multiple forms</li>
 </ul>
 <h2>Simple Form</h2>
 <p>Here's a simple form example in React.</p>
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="js" />
<p>This example demonstrates a basic form in React. We use the useState hook to store the form data in the component's state. </p>
<p>The handleSubmit function is called when the form is submitted, and it prevents the default form submission behavior by calling event.preventDefault(). </p>
<p>Then, it logs the form data to the console.</p>

<h2>Form with Validation</h2>
 <p>Here's a form example with proper validation.</p>
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="js" />
<p>This example demonstrates a form with validation. We add an error state to store any error messages</p>
<p>The handleSubmit function checks if the name and email fields are empty, and if so, sets an error message. </p>
<p> If the fields are valid, it logs the form data to the console.</p>


<h2>Form with Reusable Form Logic</h2>
 <p>Below is a simple form example in React</p>
<h2>Example</h2>
<SyntaxHighlighterComponent code={code3} language="js" />
<p>This example demonstrates a form with reusable form logic. We define an array of form fields, each with a label, type, value, and onChange handler.</p>
<p>We then map over this array to render each form field. </p>
<p>This allows us to easily add or remove form fields without having to duplicate code.</p>
<p><b>Note: </b>In this example, we also use the key prop to assign a unique key to each form field, which helps React keep track of the fields and improve performance.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_keys"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_router"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}