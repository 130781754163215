import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLlinebreak() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <!--Text with line breaks-->
      I am a text.<br />
      I am a text.<br />
      I am a text.<br />
      I am a text.<br />
      <!--Text without a line break-->
      I am a text.
      I am a text.
      I am a text.
      I am a text.
  `;
  
     
    return (
   <body>
     <Helmet>    
        <title>HTML Line Break</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Line Break, What is a line break, html line break example, how to add a line break to a html file, <br /> element, <br /> tag,
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="HTML line break is used to add a line break to a text.The <br /> element is used to add a line break.
       The <br /< element is an empty element and it is used to move a text to the next line." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
 
    <div className="content">
<header className="headertutorials" style={newCode}>
   
<ScrollToTopLink to="/HTML_horizontal_rule"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_text_formatting"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Line Break</h1>
</header>

<Navbar />

<main>

<section>
<p>HTML line break is used to add a line break to a text.The &lt;br /&gt; element is used to add a line break.</p>
<p>The &lt;br /&lt; element is an empty element and it is used to move a text to the next line.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html35"><Tryitout /></ScrollToTopLink>

</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_horizontal_rule"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_text_formatting"><ButtonNext /></ScrollToTopLink>
</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}