import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonStrings() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const creationCode = `
# Single quotes
single_quotes = 'Hello, World!'

# Double quotes
double_quotes = "Python Programming"

# Triple quotes
triple_quotes = '''Triple quotes allow
multiline strings'''
    `;

    const concatenationCode = `
# Using the + operator
str1 = "Hello"
str2 = "World"
concatenated_string = str1 + ", " + str2 + "!"

# Using the join() method
words = ["Python", "is", "awesome"]
sentence = " ".join(words)
    `;

    const slicingCode = `
text = "Python Programming"
substring = text[7:18]  # Extracts "Programming"
    `;

    const formattingCode = `
name = "Alice"
age = 30
formatted_string = "My name is {name} and I am {age} years old."

city = "New York"
population = 8_500_000
template = "The population of {} is approximately {} million.".format(city, population // 1_000_000)
    `;

    const stringMethodsCode = `
    # Common String Methods in Python
    string = "Python is fun"
    print(len(string))                # Output: 13
    print(string.lower())             # Output: python is fun
    print(string.upper())             # Output: PYTHON IS FUN
    print(string.replace("fun", "awesome"))  # Output: Python is awesome
    print(string.split())             # Output: ['Python', 'is', 'fun']
        `;

    return (
        <body>
            <Helmet>
                <title>Python Strings</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Strings, String Creation, String Concatenation, String Slicing, String Formatting" />
                <meta name="description" content="Learn about Python strings, including creation, concatenation, slicing, and formatting." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                <ScrollToTopLink to="/Python_dictionary_comprehensions"><ButtonPrevious /></ScrollToTopLink>
                <ScrollToTopLink to="/Python_date-time"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Strings</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Python strings are sequences of characters, represented using single quotes (''), double quotes ("") or triple quotes (''' or """).</p>
                        
                        <h2>Creating Strings</h2>
                        <p>Strings can be created by enclosing characters within quotes:</p>
                        <SyntaxHighlighterComponent code={creationCode} language="python" />
                        
                        <h2>String Concatenation</h2>
                        <p>Strings can be combined using the + operator or the join() method:</p>
                        <SyntaxHighlighterComponent code={concatenationCode} language="python" />
                        
                        <h2>String Slicing</h2>
                        <p>String slicing extracts substrings based on indices:</p>
                        <SyntaxHighlighterComponent code={slicingCode} language="python" />
                        
                        <h2>String Formatting</h2>
                        <p>String formatting allows inserting values into a string template:</p>
                        <SyntaxHighlighterComponent code={formattingCode} language="python" />

                        <h2>Common String Methods</h2>
                        <p>Python provides many built-in methods for string manipulation.</p>
                        <SyntaxHighlighterComponent code={stringMethodsCode} language="python" />
            
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_dictionary_comprehensions"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_date-time"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
