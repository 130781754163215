import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLAjaxParsing() {

    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
      };


  const code1 = `
const xhttp = new XMLHttpRequest();
xhttp.onreadystatechange = function() {
  if (this.readyState === 4 && this.status === 200) {
    console.log(this.responseText);
  }
};
xhttp.open("GET", "path_to_your_xml_file.xml", true);
xhttp.send();`;

  const code2 = `
<books>
  <book>
    <title>Learning XML</title>
    <author>John Doe</author>
    <year>2024</year>
  </book>
  <book>
    <title>Advanced XML</title>
    <author>Jane Smith</author>
    <year>2023</year>
  </book>
</books>`;

  const code3 = `
const parser = new DOMParser();
const xmlDoc = parser.parseFromString(responseText, "application/xml");
const books = xmlDoc.getElementsByTagName("book");
for (let i = 0; i < books.length; i++) {
  const title = books[i].getElementsByTagName("title")[0].childNodes[0].nodeValue;
  const author = books[i].getElementsByTagName("author")[0].childNodes[0].nodeValue;
  const year = books[i].getElementsByTagName("year")[0].childNodes[0].nodeValue;
  console.log("Title: " + title + ", Author: " + author + ", Year: " + year);
}`;

  return (
    <body>
      <Helmet>
        <title>XML AJAX - Parsing</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML AJAX Parsing, AJAX with XML, XML Tutorial" />
        <meta name="description" content="Learn how to parse XML data using AJAX in a React application." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br /><br /><br /><br /><br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/XML_ajax_responses"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_ajax_php"><ButtonNext /></ScrollToTopLink>
          <h1>XML AJAX - Parsing</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XML AJAX parsing involves fetching XML data from a server using AJAX and then parsing it within your application to extract and display relevant information. This technique is useful for dynamically updating parts of a web page without reloading the entire page.</p>

            <h2>Steps to Fetch and Parse XML Data</h2>
            <table>
              <thead>
                <tr>
                  <th>Step</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1. Fetch XML Data</td>
                  <td>Use the <code>XMLHttpRequest</code> object to make an asynchronous request to the server. The <code>onreadystatechange</code> event handler is used to monitor the state of the request.</td>
                </tr>
                <tr>
                  <td>2. Parse XML Data</td>
                  <td>Once the XML data is retrieved, it needs to be parsed into an XML document object using the <code>DOMParser</code>. This step converts the XML string into a structured document that JavaScript can manipulate.</td>
                </tr>
                <tr>
                  <td>3. Extract Data</td>
                  <td>With the parsed XML document, you can navigate through its structure using methods like <code>getElementsByTagName</code> to access specific elements and retrieve their content.</td>
                </tr>
              </tbody>
            </table>
            <SyntaxHighlighterComponent code={code1} language="javascript" />

            <h2>Example XML Data</h2>
            <p>Here is an example of XML data that you might fetch:</p>
            <SyntaxHighlighterComponent code={code2} language="xml" />

            <h2>Rendering Parsed XML Data</h2>
            <p>After parsing the XML data, you can extract and display it within your application:</p>
            <SyntaxHighlighterComponent code={code3} language="javascript" />

            <h2>Benefits of XML AJAX Parsing</h2>
            <ul>
              <li>Enables dynamic updates of web content without page reloads.</li>
              <li>Facilitates integration with XML-based APIs and services.</li>
              <li>Improves user experience by providing real-time data updates.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_ajax_responses"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_ajax_php"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
