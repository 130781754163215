import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonListComprehensions() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeBasic = `
# Basic list comprehension
squares = [x**2 for x in range(10)]
print(squares)  # Output: [0, 1, 4, 9, 16, 25, 36, 49, 64, 81]
    `;

    const codeConditional = `
# List comprehension with a condition
evens = [x for x in range(10) if x % 2 == 0]
print(evens)  # Output: [0, 2, 4, 6, 8]
    `;

    const codeNested = `
# Nested list comprehension
matrix = [[j for j in range(5)] for i in range(3)]
print(matrix)  # Output: [[0, 1, 2, 3, 4], [0, 1, 2, 3, 4], [0, 1, 2, 3, 4]]
    `;

    const codeWithFunction = `
# List comprehension with a function
def square(x):
    return x**2

squares = [square(x) for x in range(10)]
print(squares)  # Output: [0, 1, 4, 9, 16, 25, 36, 49, 64, 81]
    `;

    const codeWithMultipleConditions = `
# List comprehension with multiple conditions
numbers = [x for x in range(20) if x % 2 == 0 if x % 3 == 0]
print(numbers)  # Output: [0, 6, 12, 18]
    `;

    return (
        <body>
            <Helmet>
                <title>Python List Comprehensions</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python List Comprehensions, Basic List Comprehension, Conditional List Comprehension, Nested List Comprehension, List Comprehension with Function, Multiple Conditions" />
                <meta name="description" content="Learn about Python list comprehensions, including basic comprehensions, conditional comprehensions, nested comprehensions, comprehensions with functions, and multiple conditions." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_list_methods"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_tuples"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python List Comprehensions</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>List comprehensions provide a concise way to create lists in Python. They consist of brackets containing an expression followed by a for clause, and can include optional if clauses.</p>
                        
                        <h2>Basic List Comprehension</h2>
                        <p>A basic list comprehension generates a list by evaluating an expression for each element in an iterable.</p>
                        <SyntaxHighlighterComponent code={codeBasic} language="python" />
                        
                        <h2>Conditional List Comprehension</h2>
                        <p>List comprehensions can include a condition to filter elements.</p>
                        <SyntaxHighlighterComponent code={codeConditional} language="python" />
                        
                        <h2>Nested List Comprehension</h2>
                        <p>Nested list comprehensions can create lists of lists.</p>
                        <SyntaxHighlighterComponent code={codeNested} language="python" />
                        
                        <h2>List Comprehension with Function</h2>
                        <p>Functions can be used within list comprehensions to apply more complex logic.</p>
                        <SyntaxHighlighterComponent code={codeWithFunction} language="python" />
                        
                        <h2>List Comprehension with Multiple Conditions</h2>
                        <p>Multiple conditions can be used to filter elements more precisely.</p>
                        <SyntaxHighlighterComponent code={codeWithMultipleConditions} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>List comprehensions are a powerful feature in Python for generating lists. They offer a concise and readable way to create and manipulate lists based on existing iterables and conditions.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_list_methods"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_tuples"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
