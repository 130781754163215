import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function CSSTextColor() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   
    const code = `
    .example_1 {
        color: red; /* Set text color to red */
    }
    .example_2 {
        color: #00ff00; /* Set text color to green */
    }
    .example_3 {
        color: rgb(255, 0, 0); /* Set text color to red */
    }
    .example_4 {
        color: hsl(120, 100%, 50%); /* Set text color to green */
    }
    .example_5 {
        color: rgba(0, 0, 255, 0.5); /* Set semi-transparent blue text */
    }
`;


    return (
   <body>
  <Helmet>     
        <title>CSS Text Color</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Text Color, CSS Text Color Example, CSS Color Property, 
        Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="CSS color property is used to set the color of text within an element.
      The color property applies the chosen color to the text content of the selected element." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
<br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/CSS_colors"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_backgrounds"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>CSS Text Color</h1>
</header>

<Navbar />

<main>
<section>
<p>CSS color property is used to set the color of text within an element.</p>
        <br />
     <h2>Example:</h2>
     <SyntaxHighlighterComponent code={code} language="css" />
<ScrollToTopLink to="/try_css20"><Tryitout /></ScrollToTopLink>
<p>Remember that CSS color values can be specified using different notations 
    and methods. The color property applies the chosen color to the text content of the selected element.</p>

</section>

</main>
<div className="head">
<ScrollToTopLink to="/CSS_colors"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_backgrounds"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}