import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPConditional() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    $age = 20;
    if ($age >= 18) {
        echo "You are an adult.";
    }
       `;
    const code2 = `
    <?php
    $grade = 75;
  
    if ($grade >= 80) {
      echo "Excellent!";
    } else {
      echo "Good, but you can do better.";
    }
    ?>
  
    `;
    const code3 = `
    $grade = 85;
    if ($grade >= 90) {
        echo "A";
    } elseif ($grade >= 80) {
        echo "B";
    } elseif ($grade >= 70) {
        echo "C";
    } else {
        echo "D";
    }
       `;
    const code4 = `
    <?php
    $day = "Monday";
    switch ($day) {
        case "Monday":
            echo "It's Monday!";
            break;
        case "Tuesday":
            echo "It's Tuesday!";
            break;
        default:
            echo "It's neither Monday nor Tuesday.";
    }
    
    ?>
  
    `;
    const code5 = `
    $count = 1;
    while ($count <= 5) {
        echo "Count: $count <br>";
        $count++;
    }
       `;
    const code6 = `
    <?php
    $age = 20;
    $message = ($age >= 18) ? "You are an adult." : "You are not an adult.";
    print $message;
    
    ?>
  
    `;
   
    return (
   <body>
  <Helmet>
        
        <title>PHP Conditional Statement</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Conditional Statement, if Statement, else Statement, elseif Statement, switch Statement, while Loop
        Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="Learn how PHP Control structures streamline code organization, 
      control the flow of a script, and allow you to execute different blocks of code based on conditions. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_array"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_loops"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Conditional Statements</h1>
</header>

<Navbar />

<main>
<section>
<p>Conditional statements in PHP are used to execute different blocks of code depending on whether a condition is true or false.</p>
<p>There are several types of conditional statements in PHP:</p>

<h2>if Statement</h2>
<p>The 'if' statement is the fundamental building block of conditional programming. It allows you to execute a block of code if a specified condition evaluates to true.</p>
<br />
<SyntaxHighlighterComponent code={code} language="php" />

<p>In this example, the code checks if the variable '$age' is greater than or equal to 18. If the condition is true, the message "You are an adult." is echoed.</p>

<h2>else Statement</h2>
<p>The 'else' statement is used in conjunction with an 'if' statement to execute a block of code when the condition specified in the 'if' statement evaluates to false.</p>
<p>It provides an alternative action to take if the condition is not met.</p>
<br />
<SyntaxHighlighterComponent code={code2} language="php" />


<h2>elseif Statement</h2>
<p>The elseif statement is used in conjunction with if statements to add additional conditions to check. </p>
<p>It allows you to check multiple conditions sequentially.</p>
<br />
<SyntaxHighlighterComponent code={code3} language="php" />

   
<h2>switch Statement</h2>
<p>The 'switch' statement is used to perform different actions based on different conditions. </p>
<p>It's particularly useful when you have multiple conditions to check against a single variable.</p>
<br />
<SyntaxHighlighterComponent code={code4} language="php" />


<h2>while Loop</h2>
<p>The 'while' loop executes a block of code as long as a specified condition is true. </p>
<p>It repeatedly executes the block of code until the condition becomes false.</p>
<br />
<SyntaxHighlighterComponent code={code5} language="php" />
<p>In this example, the loop continues to execute and echo the value of $count until it reaches 5.</p>


<h2>Ternary Operator</h2>
<p>The ternary operator (? :) provides a concise way to write if-else statements in a single line.</p>
<p>It's often used for simple conditional assignments.</p>
<br />
<SyntaxHighlighterComponent code={code6} language="php" />
<p>This example assigns the message "You are an adult." to the variable '$message' if the condition '$age' &gt;= 18 is true; otherwise, it assigns "You are not an adult.".</p>
</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_array"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_loops"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}