// ProgressContext.js
import React, { createContext, useState, useEffect } from 'react';

export const ProgressContext = createContext();

export const ProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState(() => {
    const storedProgress = localStorage.getItem('tutorialProgress');
    return storedProgress ? JSON.parse(storedProgress) : {};
  });

  useEffect(() => {
    localStorage.setItem('tutorialProgress', JSON.stringify(progress));
  }, [progress]);

  const markAsComplete = (tutorial) => {
    setProgress((prevProgress) => ({
      ...prevProgress,
      [tutorial]: true,
    }));
  };

  return (
    <ProgressContext.Provider value={{ progress, markAsComplete }}>
      {children}
    </ProgressContext.Provider>
  );
};
