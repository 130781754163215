import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIObjectDetection() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const objectDetectionExample = `
# Example of Object Detection with YOLOv5 and PyTorch
import torch
import cv2
from PIL import Image
import numpy as np

# Load the YOLOv5 model
model = torch.hub.load('ultralytics/yolov5', 'yolov5s')

# Load an image
img = cv2.imread('example.jpg')

# Convert the image to PIL format
img_pil = Image.fromarray(cv2.cvtColor(img, cv2.COLOR_BGR2RGB))

# Perform object detection
results = model(img_pil)

# Render the results
results.render()

# Convert the results to OpenCV format
for img in results.imgs:
    img_base64 = Image.fromarray(img)
    img_base64 = cv2.cvtColor(np.array(img_base64), cv2.COLOR_RGB2BGR)
    cv2.imshow('Object Detection', img_base64)
    cv2.waitKey(0)

cv2.destroyAllWindows()
  `;

  return (
    <body>
      <Helmet>
        <title>AI Object Detection</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Object Detection, YOLOv5, PyTorch, AI Tutorial" />
        <meta name="description" content="Learn about AI object detection, its applications, and practical examples using YOLOv5 and PyTorch." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/AI_image_rocessing"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/AI_image_segmentation"><ButtonNext /></ScrollToTopLink>
          <h1>AI Object Detection</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Object detection is a computer vision technique that allows us to identify and locate objects within an image or video. It is widely used in applications such as autonomous driving, security surveillance, and medical diagnostics.</p>

            <h2>Introduction to Object Detection</h2>
            <p>Object detection involves both image classification and object localization. Popular models for object detection include YOLO (You Only Look Once), SSD (Single Shot Multibox Detector), and Faster R-CNN (Region-based Convolutional Neural Networks).</p>

            <h2>Example: Object Detection with YOLOv5 and PyTorch</h2>
            <SyntaxHighlighterComponent code={objectDetectionExample} language="python" />
            <p>In this example, we use the YOLOv5 model, which is implemented in PyTorch, to perform object detection on an image. YOLOv5 is known for its speed and accuracy in real-time object detection.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_image_rocessing"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_image_segmentation"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
