import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLDeclaration() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `<?xml version="1.0" encoding="UTF-8" ?>`;

  const example2 = `<?xml version="1.0" encoding="ISO-8859-1" ?>`;

  const example3 = `<?xml version="1.0" encoding="UTF-8" standalone="yes" ?>`;

  const example4 = `<?xml version="1.1" encoding="UTF-8" ?>`;

  return (
    <body>
      <Helmet>
        <title>XML Declaration</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML Declaration, XML Tutorial, XML Version, XML Encoding, XML Standalone" />
        <meta name="description" content="Learn about XML declarations, including version, encoding, and standalone attributes with examples and explanations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/XML_syntax"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_comments"><ButtonNext /></ScrollToTopLink>
          <h1>XML Declaration</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>The XML declaration is an optional component that defines the XML version, character encoding, and whether the XML document is standalone. It appears at the top of an XML document.</p>

            <h2>Syntax</h2>
            <p>The XML declaration has the following syntax:</p>
            <SyntaxHighlighterComponent code={`<?xml version="1.0" encoding="UTF-8" standalone="yes" ?>`} language="xml" />

            <h2>Attributes</h2>
            <p>The XML declaration can contain up to three attributes:</p>
            <ul>
              <li><strong>version</strong> (required): Specifies the XML version. Commonly used values are "1.0" and "1.1".</li>
              <li><strong>encoding</strong> (optional): Defines the character encoding used in the document. Common encodings include "UTF-8" and "ISO-8859-1".</li>
              <li><strong>standalone</strong> (optional): Indicates whether the document relies on external DTD (Document Type Definition). The values can be "yes" or "no".</li>
            </ul>

            <h2>Examples</h2>
            <h3>Basic XML Declaration</h3>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This declaration specifies that the XML document uses version 1.0 and UTF-8 encoding.</p>

            <h3>XML Declaration with ISO-8859-1 Encoding</h3>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>This declaration specifies that the XML document uses version 1.0 and ISO-8859-1 encoding.</p>

            <h3>Standalone XML Declaration</h3>
            <SyntaxHighlighterComponent code={example3} language="xml" />
            <p>This declaration specifies that the XML document uses version 1.0, UTF-8 encoding, and is standalone.</p>

            <h3>XML Declaration with Version 1.1</h3>
            <SyntaxHighlighterComponent code={example4} language="xml" />
            <p>This declaration specifies that the XML document uses version 1.1 and UTF-8 encoding.</p>

            <h2>When to Use an XML Declaration</h2>
            <p>The XML declaration is not mandatory, but it is recommended to use it for the following reasons:</p>
            <ul>
              <li>Ensuring compatibility with XML parsers that require the version and encoding information.</li>
              <li>Specifying the character encoding to prevent encoding-related errors.</li>
              <li>Indicating whether the document is standalone or depends on an external DTD.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_syntax"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_comments"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
