import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactState() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    import React, { useState } from 'react';

    const Counter = () => {
      const [count, setCount] = useState(0);
    
      return (
        <div>
          <p>Count: {count}</p>
          <button onClick={() => setCount(count + 1)}>Increment</button>
        </div>
      );
    };`

    const code2=`
    import React, { useState, useEffect } from 'react';
    import { createStore, combineReducers } from 'redux';
    
    const store = createStore(combineReducers({ count: 0 }));
    
    const Counter = () => {
      const [count, setCount] = useState(store.getState().count);
    
      useEffect(() => {
        store.subscribe(() => {
          setCount(store.getState().count);
        });
      }, []);
    
      return (
        <div>
          <p>Count: {count}</p>
          <button onClick={() => store.dispatch({ type: 'INCREMENT' })}>
            Increment
          </button>
        </div>
      );
    };    `
  
     const code3=`
     import React from 'react';

     const ParentComponent = () => {
       const name = 'John Doe';
       return (
         <div>
           <ChildComponent name={name} />
         </div>
       );
     };
     
     const ChildComponent = (props) => {
       return <h1>Hello, {props.name}!</h1>;
     };    `

         const code4=`
         import React from 'react';

         const Greeting = (props) => {
           return <h1>Hello, {props.name}!</h1>;
         };
         
         const App = () => {
           return (
             <div>
               <Greeting name="John Doe" />
             </div>
           );
         };         `
        const code5=`
        import React, { Component } from 'react';

        class Counter extends Component {
          constructor(props) {
            super(props);
            this.state = { count: 0 };
          }
        
          render() {
            return (
              <div>
                <p>Count: {this.state.count}</p>
                <button onClick={() => this.setState({ count: this.state.count + 1 })}>
                  Increment
                </button>
              </div>
            );
          }
        }
        
        const App = () => {
          return (
            <div>
              <Counter />
            </div>
          );
        };        `
        
    return (
   <body>
     <Helmet>
        
        <title>React State Management</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React State Management, What is State Management?, Why Do We Need State Management?, Types of State Management, Local State, Local State Example, Global State, Global State Example, Conclusion,
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="State management is the process of managing the state of your application over time. State is the data that changes in your application, such as user input or fetched data." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_components"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_props"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React State Management</h1>
</header>

<Navbar />

<main>
<section>
    <h1>What is State Management?</h1>
<p>State management is the process of managing the state of your application over time.</p>
  <p>State is the data that changes in your application, such as user input or fetched data.</p>

<h2>Why Do We Need State Management?</h2>
  <p>We need state management because React components only re-render when their props or state change.</p>
  <p>If we don't manage state properly, our application will not update correctly.</p>

<h2>Types of State Management</h2>
<p>There are two main types of state management in React:</p>
<ol type="1">
    <li><b>Local State:</b>This is the state that is stored in a component's state object. It is local to the component and is not shared with other components.</li>
    <li><b>Global State:</b>This is the state that is shared between multiple components. It is typically stored in a central location, such as a Redux store.</li>
</ol>

<h2>Local State Example</h2>
 <SyntaxHighlighterComponent code={code} language="js" />
<p>In this example, the Counter component has a local state property called count, which is initialized to 0. The setCount function is used to update the state.</p>

 <h2>Global State Example</h2>
 <SyntaxHighlighterComponent code={code2} language="js" />
<p>In this example, the Counter component uses the store to access and update the global state.</p>
<p>The useEffect hook is used to subscribe to the store and update the local state when the global state changes.</p>

<h2>Conclusion</h2>
<p>State management is a crucial part of building a React application.</p>
<p>By using local and global state management techniques, you can keep your application's state organized and up-to-date.</p>
<p>Remember to use the useState hook for local state and a Redux store for global state.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_components"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_props"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}