import React from 'react';
import '../css/my style.css';
import '../css/my style2.css';
import '../css/my style6.css';


 function Header() {

const headerAbout = {
    padding: '140px',
    textAlign: 'center',
    background: 'darkblue',
    textAlign: 'center',
}
 const headerAbouth1 = {
    fontSize: '50px',
    color: 'white',
    textAlign: 'center'
 }
    return (
    <header style={headerAbout}>
       <div className="header-content">
     <div className="centered-content">
   <h1 className="topple" style={headerAbouth1}>
   <span>A</span>
    <span>b</span>
    <span>o</span>
    <span>u</span>
    <span>t</span><br />
    <span>U</span>
    <span>s</span>
  
  
  </h1>
  </div>
  </div>
      </header>
    );
}
export default Header;