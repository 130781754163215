import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSDataTypes() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
    let age = 25; // integers
    let price = 10.99; // floating-point   
`;
const code2 = `
let name = "Alice";
let message = 'Hello world!';
var sentence = name + message;
alert(sentence);     
`;

const code3 = `
var x = 8;
var y = 6;      
var compare = x == y      
alert(compare);  //false 
`;
const code4 = `
let emptyValue = null;
alert(emptyValue);
`;
const code5 = `
let undefinedValue;
alert(undefinedValue);
`;
const code6 = `
const idSymbol = Symbol("id");
alert(idSymbol);
`;
const code7 = `
let applicant = {
    firstName: "John",
    lastName: "Doe",
    age: 25
  };

`;
const code8 = `
let numbers = [1, 2, 3, 4, 5];
alert(numbers[0]);
`;


    return (
   <body>
     <Helmet>
    
    <title>JavaScript Data Types</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="JavaScript Data Types, Step-by-step JavaScript programming tutorials, JavaScript coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
   JavaScript programming tips for real-world applications," />
  <meta name="description" content="Learn how JavaScript Data Types, streamline code organization, 
  enhance readability, and unlock advanced features in JavaScript development.In JavaScript, there are several fundamental data types that help you 
  represent different kinds of values. These data types can be categorized into two main groups: primitive data types and reference data types." />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
 
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_variables"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_operators"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Data Types</h1>
</header>

<Navbar />

<main>
    <section>
    <p>In JavaScript, there are several fundamental data types that help you represent different kinds of values. 
</p>
<p>
    These data types can be categorized into two main groups: primitive data types and reference data types.
</p>
<h2>Primitive Data Types</h2>
<p>The primitive data types are explained below</p>
<h2>Number</h2>
<p>This data type represents numeric values, including integers and floating-point numbers.</p>
    <br />
    <h2>Example:</h2>
    <p>Let's say you're building a simple program that calculates the area of a rectangle.You want to store
         the width and height of the rectangle in variables and then calculate the area using those variables.</p>
         <SyntaxHighlighterComponent code={code} language="javascript" />

 <h2>String</h2>
 <p>This data type represents sequences of characters (text) enclosed in single or double quotes.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js16"><Tryitout /></ScrollToTopLink>
    <h2>Boolean</h2>
    <p>The boolean data type represents a binary value which can either be true or false.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code3} language="javascript" />
<ScrollToTopLink to="/try_js17"><Tryitout /></ScrollToTopLink>
    <h2>Null</h2>
    <p>This data type represents the intentional absence of any value or object.</p>
    <brn/>
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code4} language="javascript" />
<ScrollToTopLink to="/try_js18"><Tryitout /></ScrollToTopLink>
    <h2>Undefined</h2>
    <p>This data type represents an uninitialized or unassigned value.An undefined variable does not have a
        value assigned to it therefore it is empty.
    </p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code5} language="javascript" />
<ScrollToTopLink to="/try_js19"><Tryitout /></ScrollToTopLink>
    <h2>Undefined</h2>
    <h2>Symbol</h2>
    <p>Introduced in ECMAScript 6, symbols are unique and immutable values, often used as property keys in
         objects.
    </p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code6} language="javascript" />
<ScrollToTopLink to="/try_js20"><Tryitout /></ScrollToTopLink>

    <h2>Reference Data Types</h2>
    <p>The reference data types include:
    </p>
    <h2>Object</h2>
    <p>Object represents a collection of key-value pairs, where keys are strings (or symbols) and values can be of any 
        data type.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code7} language="javascript" />
<ScrollToTopLink to="/try_js21"><Tryitout /></ScrollToTopLink>

    <h2>Array</h2>
    <p>This dta type represents an ordered list of values, accessible by their index.In JavaScript counting starts
        from zero so inorder to access the values of the array you place the name of the variable and the index inside
        a pair of square brackets [] of 
        the value you want to display.
    </p>
    <p>For you to understand better take a look at this example, here we will display the first value which is
        1 with it's index which is 0.
    </p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code8} language="javascript" />
<ScrollToTopLink to="/try_js22"><Tryitout /></ScrollToTopLink>
    <p>There are many other reference data types but they will not be explained now.Going forward you will learn
        about them and how they can be used.
    </p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_variables"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_operators"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}