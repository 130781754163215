import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyStringMethods() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const stringQueryMethodsCode = `
# String Query Methods
str = "Hello, world!"

puts str.start_with?("Hello")  # Output: true
puts str.end_with?("world!")   # Output: true
puts str.include?("world")     # Output: true
puts str.empty?                # Output: false
puts str.length                # Output: 13
  `;

  const stringModificationMethodsCode = `
# String Modification Methods
str = "Hello, world!"

str.upcase!
puts str  # Output: HELLO, WORLD!

str.downcase!
puts str  # Output: hello, world!

str.gsub!("world", "Ruby")
puts str  # Output: hello, Ruby!
  `;

  const stringTransformationMethodsCode = `
# String Transformation Methods
str = "  Hello, world!  "

puts str.strip    # Output: "Hello, world!"
puts str.split    # Output: ["Hello,", "world!"]
puts str.split(",") # Output: ["  Hello", " world!  "]
puts str.chars    # Output: [" ", " ", "H", "e", "l", "l", "o", ",", " ", "w", "o", "r", "l", "d", "!", " ", " "]
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby String Methods</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby String Methods, String Query Methods, String Modification Methods, String Transformation Methods" />
        <meta name="description" content="Learn about various methods available for strings in Ruby, including querying, modifying, and transforming strings." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_strings"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_ranges"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby String Methods</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Ruby provides a wide range of methods for working with strings. These methods can be used to query, modify, and transform strings.</p>

            <h2>String Query Methods</h2>
            <p>Query methods allow you to check certain conditions or retrieve information about a string.</p>
            <SyntaxHighlighterComponent code={stringQueryMethodsCode} language="ruby" />
            <p>In the above example, we use methods like <code>start_with?</code>, <code>end_with?</code>, <code>include?</code>, <code>empty?</code>, and <code>length</code> to query the string.</p>

            <h2>String Modification Methods</h2>
            <p>Modification methods allow you to change the content of a string. Methods with <code>!</code> modify the string in place.</p>
            <SyntaxHighlighterComponent code={stringModificationMethodsCode} language="ruby" />
            <p>In the above example, we use methods like <code>upcase!</code>, <code>downcase!</code>, and <code>gsub!</code> to modify the string.</p>

            <h2>String Transformation Methods</h2>
            <p>Transformation methods allow you to transform a string into a different format or structure.</p>
            <SyntaxHighlighterComponent code={stringTransformationMethodsCode} language="ruby" />
            <p>In the above example, we use methods like <code>strip</code>, <code>split</code>, and <code>chars</code> to transform the string.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_strings"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_ranges"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
