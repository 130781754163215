import React, { Component } from 'react';
import "./../css/my style.css";
import { Link } from 'react-router-dom';

class CodingQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: 0,
      score: 0,
     questions:[
  {
    question: 'What do you understand by the term "programming"?',
    options: ['Using a computer to work', 'Writing programs that tell a computer an action to perform', 'Developing single-player games'],
    correctAnswer: 'Writing programs that tell a computer an action to perform',
  },
  {
    question: 'What is the full meaning of HTML?',
    options: ['High Tech and Management Technology', 'Hyper Text Managed Language', 'Hyper Text Markup Language'],
    correctAnswer: 'Hyper Text Markup Language',
  },
  {
    question: 'What does "CPU" stand for in the context of computers?',
    options: ['Central Processing Unit', 'Computer Programming Utility', 'Common Peripheral Unit'],
    correctAnswer: 'Central Processing Unit',
  },
  {
    question: 'Which programming language is known for its use in web development and is often called the "scripting language for the web"?',
    options: ['Python', 'Java', 'JavaScript'],
    correctAnswer: 'JavaScript',
  },
  {
    question: 'What is the main function of an operating system on a computer?',
    options: ['Playing games', 'Managing hardware resources and providing services to software', 'Sending emails'],
    correctAnswer: 'Managing hardware resources and providing services to software',
  },
  {
    question: 'What is a URL?',
    options: ['Uniform Resource Locator', 'Universal Record Locator', 'Unified Resource Link'],
    correctAnswer: 'Uniform Resource Locator',
  },
  {
    question: 'What is the purpose of RAM in a computer?',
    options: ['To store long-term data', 'To temporarily store data that the CPU is currently working on', 'To display images on the screen'],
    correctAnswer: 'To temporarily store data that the CPU is currently working on',
  },
  {
    question: 'What does the acronym "HTTP" stand for in the context of web addresses?',
    options: ['Hypertext Transfer Protocol', 'High Tech Transmission Protocol', 'Hyperlink Text Transfer Process'],
    correctAnswer: 'Hypertext Transfer Protocol',
  },
  {
    question: 'What is the primary function of a web browser?',
    options: ['To make phone calls', 'To play video games', 'To display web pages and access information on the internet'],
    correctAnswer: 'To display web pages and access information on the internet',
  },
  {
    question: 'What is a firewall in the context of computer security?',
    options: ['A physical barrier used to protect computers from dust', 'A software or hardware security system that monitors and controls incoming and outgoing network traffic', 'A device for making grilled sandwiches'],
    correctAnswer: 'A software or hardware security system that monitors and controls incoming and outgoing network traffic',
  },
  // Add more questions with options and correct answers here
],

      
    };
  }

  handleAnswerClick = (selectedAnswer) => {
    const { currentQuestion, questions } = this.state;
  
    if (selectedAnswer === questions[currentQuestion].correctAnswer) {
      this.setState((prevState) => ({
        score: prevState.score + 1,
      }));
    }
  
    this.setState((prevState) => ({
      currentQuestion: prevState.currentQuestion + 1,
    }));
  };

  render() {
    const { currentQuestion, questions, score } = this.state;

    if (currentQuestion < questions.length) {
      return (
        <div style={{backgroundColor: "darkblue", height: '100vh'}}>
        <body className='codes2'>
          <h1 className='code3'>Coding Quiz</h1>
          <p className='code3'>Question {currentQuestion + 1}:</p>
          <p className='code3'>{questions[currentQuestion].question}</p>
          <ol type="a">
            {questions[currentQuestion].options.map((option, index) => (
            
              <li key={index} onClick={() => this.handleAnswerClick(option)} className='codes'>
                {option}
              </li>
    
            ))}
          </ol>
        </body>
        </div>
      );
    } else {
      // Display results and appropriate feedback
      let feedbackMessage = '';
      if (score >= 7) {
        feedbackMessage = 'Congratulations! You did an excellent job!';
      } else if (score >= 4) {
        feedbackMessage = 'You did well! Keep practicing to improve.';
      } else {
        feedbackMessage = 'Don\'t worry! You can always learn more and try again.';
      }

      return (
        <div style={{backgroundColor: "darkblue", height: '100vh'}}>
        <body className='codes2'>
          <h1 className='code3'>Quiz Results</h1>
          <p className='code3'>Your Score: {score}/10</p>
          <p className='code3'>{feedbackMessage}</p>
          <Link to="/"> <button>Exit</button></Link>
        </body>
        </div>
      );
    }
  }
}

export default CodingQuiz;
