export const northamericaData = [
    {
      question: "What is the capital of the United States?",
      answer: "Washington, D.C."
    },
    {
      question: "Who was the first President of the United States?",
      answer: "George Washington"
    },
    {
      question: "Which country in North America is known as the Great White North?",
      answer: "Canada"
    },
    {
      question: "Which North American country has the largest land area?",
      answer: "Canada"
    },
    {
      question: "What is the national sport of Canada?",
      answer: "Ice hockey"
    },
    {
      question: "Which city is the capital of Mexico?",
      answer: "Mexico City"
    },
    {
      question: "Who was the famous Mexican painter known for his works such as 'The Two Fridas' and 'The Persistence of Memory'?",
      answer: "Frida Kahlo"
    },
    {
      question: "What is the official language of Belize?",
      answer: "English"
    },
    {
      question: "Which Caribbean country is the largest by population?",
      answer: "Haiti"
    },
    {
      question: "What is the longest river in North America?",
      answer: "Mississippi River"
    },
    {
      question: "Which U.S. state is known as the 'Sunshine State'?",
      answer: "Florida"
    },
    {
      question: "What is the highest mountain peak in North America?",
      answer: "Denali (formerly known as Mount McKinley)"
    }
  ];
  