import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLimage() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <img src="A ship.jpg" />
  `;

  const code2 = `
  <img src="A ship123.jpg" alt="picture of a ship" />
`;
  
  const code3 = `
  <!--Example using width and height attributes-->
  <img src="a monitor.jpg" width="300" height="300" />
  <!--Example using the style attribute-->
  <img src="a monitor.jpg" style="width:300px;height:300px;" />
`;

  const code4 = `
  <a href="fileinthesamefolder.html">
  <img src="A ship.jpg" />
  </a>
`;
  
    return (
   <body>
        <Helmet>    
        <title>HTML Images</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Images, html Images, How to embed an image in a HTML file, <img>, <img> element/tag, src Attribute, alt Attribute, Sizing an Image, Image as a Link
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications." />
       <meta name="description" content="Images are an important of every website and virtually every website contains an image.
       Images make web pages beautiful and it attracts visitors.A website without a single image will look plain and vivitors will obviously not browse such a website." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
    <ScrollToTopLink to="/HTML_section"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_block_inline"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Images</h1>
</header>

<Navbar />

<main>

<section>
<p>Images are an important of every website and virtually every website contains an image.</p>
<p>Images make web pages beautiful and it attracts visitors.A website without a single image will look plain and vivitors will obviously not browse such a website.</p>
<p>Inorder to display an image on a web page we use the &lt;img /&gt; element which is an empty element together with the src attribute which defines the location ou URL of the image.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html39"><Tryitout /></ScrollToTopLink>

<h2>src Attribute</h2>
    <p>The src attribute species the URL or file path of the image</p>
    <p>In the above example the URL of the image is A ship.jpg</p>

    <h2>alt Attribute</h2>
    <p>The alt attribute comes into play in cases whereby an image did not load on the browser due to slow internet connection or placing a wrong URL.</p>
    <p>The alt attribute provides an alternate text to be show when this happens.</p>
    <br />

    <SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html40"><Tryitout /></ScrollToTopLink>

<h2>Sizing an Image</h2>
<p>Images can be resized to do that we use the width and height attribute to change the image width and height respectively.</p>
<p>Alternatively we can use the style attribute together with the width and height properties.Below is an example</p>
<br />
<SyntaxHighlighterComponent code={code3} language="html" />
<ScrollToTopLink to="/try_html41"><Tryitout /></ScrollToTopLink>

<h2>Image as a Link</h2>
<p>Images can also be used as links to achieve this we enclose the image within the &lt;a&gt; element and its href attribute.</p>
<br />
<SyntaxHighlighterComponent code={code4} language="html" />
<ScrollToTopLink to="/try_html42"><Tryitout /></ScrollToTopLink>
</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_section"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_block_inline"><ButtonNext /></ScrollToTopLink>
</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}