import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonSetOperations() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const code1 = `
# Creating a Set
my_set = {1, 2, 3, 4, 5}
print(my_set)  # Output: {1, 2, 3, 4, 5}

# Creating an Empty Set
empty_set = set()
print(empty_set)  # Output: set()
    `;

    const code2 = `
# Adding Elements
my_set.add(6)
print(my_set)  # Output: {1, 2, 3, 4, 5, 6}

# Adding Multiple Elements
my_set.update([7, 8])
print(my_set)  # Output: {1, 2, 3, 4, 5, 6, 7, 8}
    `;

    const code3 = `
# Removing Elements
my_set.remove(8)  # Raises KeyError if the element is not present
print(my_set)  # Output: {1, 2, 3, 4, 5, 6, 7}

my_set.discard(7)  # Does not raise an error if the element is not present
print(my_set)  # Output: {1, 2, 3, 4, 5, 6}

my_set.pop()  # Removes and returns an arbitrary element
print(my_set)  # Output: {2, 3, 4, 5, 6}

my_set.clear()  # Removes all elements
print(my_set)  # Output: set()
    `;

    const code4 = `
# Set Operations
a = {1, 2, 3, 4}
b = {3, 4, 5, 6}

# Union
print(a | b)  # Output: {1, 2, 3, 4, 5, 6}

# Intersection
print(a & b)  # Output: {3, 4}

# Difference
print(a - b)  # Output: {1, 2}
print(b - a)  # Output: {5, 6}

# Symmetric Difference
print(a ^ b)  # Output: {1, 2, 5, 6}
    `;

    const code5 = `
# Subset and Superset
a = {1, 2, 3}
b = {1, 2, 3, 4, 5}

# Subset
print(a <= b)  # Output: True
print(a.issubset(b))  # Output: True

# Superset
print(b >= a)  # Output: True
print(b.issuperset(a))  # Output: True
    `;

    const code6 = `
# Set Comprehensions
squared_set = {x**2 for x in range(6)}
print(squared_set)  # Output: {0, 1, 4, 9, 16, 25}

even_squared_set = {x**2 for x in range(6) if x % 2 == 0}
print(even_squared_set)  # Output: {0, 4, 16}
    `;

    const code7 = `
# Set Membership
my_set = {1, 2, 3, 4, 5}
print(3 in my_set)  # Output: True
print(6 in my_set)  # Output: False
    `;

    return (
        <body>
            <Helmet>
                <title>Python Set Operations</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Set Operations, Create, Add, Remove, Union, Intersection, Difference, Subset, Superset" />
                <meta name="description" content="Learn about Python set operations, including creating sets, adding and removing elements, and performing union, intersection, and difference operations, with various examples." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                <ScrollToTopLink to="/Python_set_comprehensions"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_dictionaries"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Set Operations</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Sets in Python are collections of unordered and unindexed elements. They are mutable and do not allow duplicate elements. Python provides a set of built-in operations for performing various tasks on sets.</p>

                        <h2>Creating a Set</h2>
                        <p>Create a set by placing all the items (elements) inside curly braces <code>{}</code>, separated by commas, or by using the <code>set()</code> function.</p>
                        <SyntaxHighlighterComponent code={code1} language="python" />

                        <h2>Adding Elements</h2>
                        <p>Use the <code>add()</code> method to add a single element to a set and the <code>update()</code> method to add multiple elements.</p>
                        <SyntaxHighlighterComponent code={code2} language="python" />

                        <h2>Removing Elements</h2>
                        <p>Remove elements from a set using the <code>remove()</code>, <code>discard()</code>, <code>pop()</code>, and <code>clear()</code> methods.</p>
                        <SyntaxHighlighterComponent code={code3} language="python" />

                        <h2>Set Operations</h2>
                        <p>Perform mathematical operations like union, intersection, difference, and symmetric difference on sets.</p>
                        <SyntaxHighlighterComponent code={code4} language="python" />

                        <h2>Subset and Superset</h2>
                        <p>Check if a set is a subset or superset of another set using the <code>issubset()</code> and <code>issuperset()</code> methods or the <code>&lt;=</code> and <code>&gt;=</code> operators.</p>
                        <SyntaxHighlighterComponent code={code5} language="python" />

                        <h2>Set Comprehensions</h2>
                        <p>Create sets using comprehensions, similar to list comprehensions.</p>
                        <SyntaxHighlighterComponent code={code6} language="python" />

                        <h2>Set Membership</h2>
                        <p>Check if an element is present in a set using the <code>in</code> keyword.</p>
                        <SyntaxHighlighterComponent code={code7} language="python" />

                        <h2>Conclusion</h2>
                        <p>Sets are a powerful data structure in Python that offer a range of operations for managing collections of unique elements. Understanding these operations will help you effectively use sets in your Python programs.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_set_comprehensions"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_dictionaries"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
