import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLnbsp() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <!-- sentence with nbsp -->
      <p>Learning&nbsp;&nbsp;&nbsp; is fun.</p>
      <!-- sentence without nbsp -->
      <p>Learning is fun</p>
  `;

  const code2 = `
  <!-- sentences with nbsp -->
  <p>Beta Thread&nbsp;&nbsp;&nbsp; is awesome.</p>
  <p>I love&nbsp;&nbsp;&nbsp; HTML.</p>
  <p>Coding&nbsp;&nbsp;&nbsp; is fun.</p>
  <!-- sentences without nbsp -->
  <p>Beta Thread is awesome.</p>
  <p>I love HTML.</p>
  <p>Coding is fun.</p>
`;

  
    return (
   <body>
    <Helmet>    
        <title>HTML nbsp</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML nbsp, What is nbsp, html nbsp example, nbsp character entity, 
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="The nbsp in HTML is a character entity which represents a non breaking space between content in a sentence.
       It is used to prevent two or more words from breaking into a new line." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_figures"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_entities"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML nbsp</h1>
</header>

<Navbar />

<main>

<section>
<p>The nbsp in HTML is a character entity which represents a non breaking space between content in a sentence.</p>
<p>It is used to prevent two or more words from breaking into a new line</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html49"><Tryitout /></ScrollToTopLink>

<p>The &amp;nbsp; is also used to prevent browsers from removing spaces unecessarily.</p>
<p>Here's another example making use of the &amp;nbsp character entity</p><br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html50"><Tryitout /></ScrollToTopLink>
</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_figures"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_entities"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}