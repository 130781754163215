import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactES6template() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    const name = 'John';
    const greeting = 'Hello, ${name}!';
       `

    const code2=`
    const weather = 'Sunny';
    const today = 'The day is ${weather}!';
       `
  
    return (
   <body>
     <Helmet>
        
        <title>React ES6 Template Literals</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React ES6 Template Literals, ES6 Template Literals, ES6 Template Literals Examples,
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="Template literals provide a more expressive way to concatenate strings and include variables within them." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_es6-destructuring"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-classes"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React ES6 Template Literals</h1>
</header>

<Navbar />

<main>
<section>
<p>Template literals provide a more expressive way to concatenate strings and include variables within them.</p>
  <p>They use backticks (`) instead of single or double quotes.</p>
  <h2>Example</h2>
 <SyntaxHighlighterComponent code={code} language="js" />

 <p>Here's another example:</p>
 <SyntaxHighlighterComponent code={code2} language="js" />
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_es6-destructuring"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-classes"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}