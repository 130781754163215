import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLcomments() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <p>Sentence shown on the browser</p> <!-- I am a single-line comment -->
      <p>Sentence should on the browser</p>
      <!-- I am a multi-line comment
           I am a multi-line comment
           I am a multi-line comment
           I am a multi-line comment
      -->
      <p>Sentence should on the browser</p>
  `;
      const code2 = `
      <p>
      I will be displayed
      <!-- 
      I will not be displayed
      I will not be displayed
      I will not be displayed
      I will not be displayed
      -->
      </p>
      `;
    return (
   <body>
     <Helmet>    
        <title>HTML Comments</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Comments, single-line comment , multi-line comment,
         Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
      <meta name="description" content="HTML comments are text,phrases or sentences inside an HTML file.
        They are added to the code of HTML to provide a short description of the code." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_editors"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_elements"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Comments</h1>
</header>

<Navbar />

<main>
<section>
<p>HTML comments are text,phrases or sentences inside an HTML file.</p>
<p>They are added to the code of HTML to provide a short description of the code.</p>
<p>Comments are not visible on the web page but is kept development purposes as it plays a
   very important role not just for the web developer but also for those who are new to HTML</p>
<p>A comment will help you remember the meaning or purpose of an HTML element for those who easily forget things.</p> 
<p>The syntax for HTML comments is &lt;!--&nbsp;&nbsp;--&gt;</p>
<p>There are two types of HTML comments single-line and multi-line comments both will be displayed in the 
    example below.
</p>
<br/>
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html2"><Tryitout /></ScrollToTopLink>
</section>

<section>
    <p>Comments can also be used to disable a block of codes</p>
    <p>Below is an example</p>
    <h2>Example</h2>
    <SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html3"><Tryitout /></ScrollToTopLink>
</section>

<section>
    <h2>Explanation:</h2>
    <p>As you can see the first sentence did not show because it has been nested by a comment</p>
    <p>The second sentence will be run by the editor because it is not disbled by the comment</p>
</section>
 
</main>
<div className="head">
<ScrollToTopLink to="/HTML_editors"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_elements"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}