import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSVariables() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
    function totalArea() {
    // Declare variables to store width and height
    var width = 10;  // You can also use 'const' instead of 'var' or 'let'
    let height = 5;
    
    // Calculate the area of the rectangle
    var area = width * height;
    
    // Output the result
    console.log("The area of the rectangle is: " + area);
    }
`;
const code2 = `
// Using let for a variable that can change
let currentTemperature = 25;
currentTemperature = 30;  // This is allowed

// Using const for a constant variable
const pi = 3.14159;
// pi = 3.14;  // This would result in an error because 'pi' is a constant

`;


    return (
   <body>
        <Helmet>
    
    <title>JavaScript Variables</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="JavaScript Variables, JavaScript Keywords, var keyword, let keyword, const keyword." />
  <meta name="description" content="In JavaScript, variables are used to store and manage data. They act as containers that hold 
  different types of information, such as numbers, text, or more complex objects." />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
        <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_method"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_data_types"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Variables</h1>
</header>

<Navbar />

<main>
    <section>
    <p>In JavaScript, variables are used to store and manage data. They act as containers that hold different types
     of information, such as numbers, text, or more complex objects.
</p>
<p>Variables provide a way to reference and manipulate data throughout your code.</p>

    <br />
    <h2>Example:</h2>
    <p>Let's say you're building a simple program that calculates the area of a rectangle.You want to store
         the width and height of the rectangle in variables and then calculate the area using those variables.</p>
         <SyntaxHighlighterComponent code={code} language="javascript" />
<ScrollToTopLink to="/try_js15"><Tryitout /></ScrollToTopLink>
    <ol type="i">
        <li>var width = 10; declares a variable named width and assigns the value 10 to it.</li>
        <li>var height = 5; declares a variable named height and assigns the value 5 to it.</li>
        <li>var area = width * height; calculates the area by multiplying the width and height variables.</li>
        <li>document.write(...) outputs the calculated area along with a message.</li>
    </ol>
    <h2>Keywords</h2>
    <p>There are three keyword in javascript used to declare variables, they are:</p>
    <li>var: It is used to declare a variable but it does not have a block scope.</li>
    <li>let: It is used to declare a block variable,it has a block block scope.</li>
    <li>const: It is often used to declare objects and arrays.</li>
    <p>The difference between let and const is how they handle</p>
    <p>Here's an example using let and const:</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
  <p>In summary, variables in JavaScript are containers for storing and manipulating data. </p>
  <p>They are essential for building dynamic and interactive applications by allowing you to work 
    with different types of information throughout your code.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_method"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_data_types"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}