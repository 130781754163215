import React, { useEffect, useState } from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../css/special styles.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import { getAuthStatus, saveAuthStatus } from "../../localstorage";
import DemoProgress from "../../components/Tutorial Progress/DemoProgress";
import ButtonHome from "../../components/Buttonhome";



export default function PHPintro() {

    
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    <html>
     <head>
       <title>My First PHP Scipt</title>
     </head>

     <body>
       <?php echo "This is my first PHP script"; ?>
     </body>

    </html>
    `;
   
    return (
   <body>
    <Helmet>
     <title>PHP Tutorial</title>
     <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Introduction to PHP, What is PHP, Uses of PHP, PHP Script,
     Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       Python programming tips for real-world applications." />
   <meta name="description" content="Explore the power of PHP on our PHP tutorial page. Learn how to use PHP to streamline code organization, enhance code reusability,
    and unlock advanced features in PHP development. Discover practical examples and best practices to elevate your programming skills. Dive into the world of PHP with our concise and informative guide" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_environment_setup"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Tutorial - Preamble</h1>
</header>

<Navbar />

<main>
<section>
<h2>What is PHP?</h2>
<p>PHP stands for "Hypertext Preprocessor".It is a server side scripting language used to manage dynamic content, 
    session tracking, databases and many other functions.
</p>
<p>PHP was first unleashed by a man named Rasmus Lerdorf in 1994 and since then it has evolved.</p>
<p>PHP has a syntax which similar to the programming language C.</p>
<p>PHP can be embedded into your HTML code.Also external PHP files can be created just save your filename the 
    .php extension e.g, filename.php.
</p>
<h2>Uses of PHP</h2>
<li>PHP can be used to connect a different types of databases such as Sybase, Oracle, PostgreSQL, MySQL and some 
    other databases.
</li>
<li>PHP can handle and validate forms.</li>
<li>PHP can be used to set cookies.</li>
<li>It can be used to perform different functions on files.</li>
<li>It can be used to encrypt data.</li>

<br />
<h2>PHP Script</h2>
<p>We mentioned that can be embedded into HTML code and we will display this with an example</p>
<SyntaxHighlighterComponent code={code} language="php" />
<p>The output of the above code will be <br/>
<code>This is my first PHP script</code></p>
<p>If you take a good look at the output above you will notice that two things</p>
<li>All PHP present in the web page is stripped and processed from the page</li>
<li>The PHP code is not present in the file sent by the server to the web browser</li>
</section>

<div className="second-div">
 
     <section className="features">
     <div className="progress-sample">
    
         <img src={require('../../image/beta-progress.svg').default} width={500} height={500}  alt='betathread' />
       <p style={{fontSize: '15px'}}> Our Progress Tracker is designed to help you monitor your progress through our extensive range of tutorials.</p>
       <ScrollToTopLink to="/progress-tracker"><button type="submit" className="buttonb">Track Progress</button></ScrollToTopLink>
   
     </div>

     <div className="progress-sampling">
     <h1 style={{fontSize: '45px'}}>Track Your Progress</h1>
     <DemoProgress />
     </div>
   </section>
  </div>
</main>
<div className="head">
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_environment_setup"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}