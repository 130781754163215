import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLJoins() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const innerJoinCode = `
-- INNER JOIN
SELECT employees.employee_id, employees.employee_name, departments.department_name
FROM employees
INNER JOIN departments ON employees.department_id = departments.department_id;
  `;

  const leftJoinCode = `
-- LEFT JOIN
SELECT employees.employee_id, employees.employee_name, departments.department_name
FROM employees
LEFT JOIN departments ON employees.department_id = departments.department_id;
  `;

  const rightJoinCode = `
-- RIGHT JOIN
SELECT employees.employee_id, employees.employee_name, departments.department_name
FROM employees
RIGHT JOIN departments ON employees.department_id = departments.department_id;
  `;

  const fullJoinCode = `
-- FULL OUTER JOIN
SELECT employees.employee_id, employees.employee_name, departments.department_name
FROM employees
FULL OUTER JOIN departments ON employees.department_id = departments.department_id;
  `;

  const crossJoinCode = `
  -- CROSS JOIN Example
  SELECT employees.employee_name, departments.department_name
  FROM employees
  CROSS JOIN departments;
    `;

  return (
    <body>
      <Helmet>
        <title>SQL Joins</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL Joins, SQL INNER JOIN, SQL LEFT JOIN, SQL RIGHT JOIN, SQL FULL OUTER JOIN, SQL CROSS JOIN, SQL Tutorial" />
        <meta name="description" content="Learn about SQL joins, including INNER JOIN, LEFT JOIN, RIGHT JOIN, and FULL OUTER JOIN with examples and explanations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_ctes"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_inner_join"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL Joins</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>SQL joins are used to combine rows from two or more tables based on a related column between them. There are several types of joins:</p>

            <h2>INNER JOIN</h2>
            <p>An <code>INNER JOIN</code> returns records that have matching values in both tables.</p>
            <SyntaxHighlighterComponent code={innerJoinCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The query selects employee details along with their corresponding department names where there is a match in <code>department_id</code>.</li>
            </ul>

            <h2>LEFT JOIN</h2>
            <p>A <code>LEFT JOIN</code> (or <code>LEFT OUTER JOIN</code>) returns all records from the left table and the matched records from the right table. If no match is found, NULL values are returned for columns from the right table.</p>
            <SyntaxHighlighterComponent code={leftJoinCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The query selects all employees and their corresponding department names. If an employee does not belong to a department, NULL is returned for the department name.</li>
            </ul>

            <h2>RIGHT JOIN</h2>
            <p>A <code>RIGHT JOIN</code> (or <code>RIGHT OUTER JOIN</code>) returns all records from the right table and the matched records from the left table. If no match is found, NULL values are returned for columns from the left table.</p>
            <SyntaxHighlighterComponent code={rightJoinCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The query selects all departments and their corresponding employee details. If a department has no employees, NULL is returned for the employee details.</li>
            </ul>

            <h2>FULL OUTER JOIN</h2>
            <p>A <code>FULL OUTER JOIN</code> returns all records when there is a match in either left or right table records. If no match is found, NULL values are returned for columns from the table that lacks a matching row.</p>
            <SyntaxHighlighterComponent code={fullJoinCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The query selects all employees and all departments, showing their corresponding details. If there is no match, NULL values are returned for the missing side.</li>
            </ul>

            <h2>CROSS JOIN</h2>
            <p>A <code>CROSS JOIN</code> returns the Cartesian product of the two tables, meaning it will combine all rows from the first table with all rows from the second table.</p>
            <SyntaxHighlighterComponent code={crossJoinCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The query selects all combinations of employees and departments, producing a Cartesian product.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_ctes"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_inner_join"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
