import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function ReactCreateReactApp() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   const code = `
   npm install -g create-react-app
   `;
   const code2 = `
   npx create-react-app my-react-app
   `;
   const code3 = `
   npx create-react-app my-react-app
   `;
   const code4 = `
   cd my-react-app
   `;
   const code5 = `
   npm start
   `;
    return (
   <body>
     <Helmet>
        
        <title>React create-react-app</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React Create React App, Key Features and Benefits of Create React App, Creating a React App using Create React App, 
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="Learn how Create React App streamlines code organization, 
      enhance Zero Configuration, enhance Standardized Setup, enhance Development Server, enhance code reusability and unlock advanced features in Ruby development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_environment_setup"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>React create-react-app</h1>
</header>

<Navbar />

<main>
<section>
<p>"Create React App" (CRA) is a command-line tool developed by 
  Facebook that provides a streamlined and standardized way to set up and build React applications. </p>
<p>It abstracts away the complex configuration and build process, allowing developers to focus on writing 
  code and building user interfaces</p>

  <h2>Key Features and Benefits of Create React App</h2>
  <ol type="1">
    <li><b>Zero Configuration:</b><br /> Create React App abstracts away the need to configure build tools 
      like Webpack, Babel, and ESLint. This is especially helpful for beginners who might find the configuration process overwhelming.</li>
    <br />
      <li><b>Standardized Setup:</b><br /> Create React App sets up a standardized project structure and best practices for organizing your React 
      application. This ensures consistency across different projects and teams.</li>
    <br />
    <li><b>Optimized Build Process:</b><br /> CRA optimizes the production build by minifying and bundling your code, generating source maps for
       debugging, and applying other optimizations to improve performance.</li>
       <br />
      <li><b>Development Server:</b><br /> The development server provided by CRA supports hot module reloading, allowing you to see your 
        changes instantly without having to manually refresh the browser.</li>
      <br />
      <li><b>Builtin ESLint Configuration:</b><br /> Create React App includes ESLint configuration to ensure consistent code quality.
         It follows a set of recommended rules and alerts you to potential issues in your code.</li>
         <br />
      <li><b>Easy Deployment:</b><br /> The build output of a CRA application is optimized for deployment. You get a set of static files
           that can be hosted on various platforms, including web servers, content delivery networks (CDNs), and platforms like Netlify or Vercel.</li>
    </ol>
    <h2>Creating a React App using Create React App</h2>
    <p>To create a new React app using Create React App, follow these steps:</p>
    <h2>Step1: Install Create React App:</h2>
    <p>Open your terminal or command prompt and run the following command to install Create React App globally:</p>
    <SyntaxHighlighterComponent code={code} language="ruby" />

    <h2>Step2: Create a New React App</h2>
    <p>
      Once Create React App is installed, you can create a new React app by running:
    </p>
    <SyntaxHighlighterComponent code={code2} language="ruby" />
    <p>Replace "my-react-app" with your preferred project name.</p>

   
      <h2>Step3: Navigate to the App Directory</h2>
      <p>Move into the newly created app's directory using:</p>
      <SyntaxHighlighterComponent code={code4} language="ruby" />

      <h2> Step4: Start the Development Server</h2>
      <p>Inside the app's directory, start the development server with the following command:</p>
      <SyntaxHighlighterComponent code={code5} language="ruby" />

    <p>This will open your app in a web browser at http://localhost:3000.</p>
      <p>You're now ready to start building your React app! Create React App handles
         the build process, development server, and other configurations, allowing you to focus on 
         writing React components and implementing features.</p>

   
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_environment_setup"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}