export const antarcticaData = [
    {
      question: "What is the history of Antarctica?",
      answer: "Antarctica's history involves early exploration by various expeditions, including those led by explorers like James Cook and Ernest Shackleton. It wasn't until the 20th century that territorial claims were made by several nations, leading to the Antarctic Treaty System in 1959."
    },
    {
      question: "How many countries have territorial claims in Antarctica?",
      answer: "Several countries have territorial claims in Antarctica, including Argentina, Australia, Chile, France, New Zealand, Norway, and the United Kingdom."
    },
    {
      question: "Who is the current president or leader of Antarctica?",
      answer: "Antarctica does not have a president or leader, as it is governed by international agreements under the Antarctic Treaty System. Instead, various countries with territorial claims or scientific research stations in Antarctica manage their respective activities in the region."
    },
    {
      question: "What are the states or regions of Antarctica?",
      answer: "Antarctica does not have traditional states or regions. However, it is divided into sectors based on the Antarctic Treaty System, which establishes territorial claims and regulates scientific research and environmental protection efforts."
    },
    {
      question: "What is the capital of Antarctica?",
      answer: "Antarctica does not have a capital city, as it is a continent dedicated to scientific research and is not governed by a single political entity."
    },
    {
      question: "Where is Antarctica located?",
      answer: "Antarctica is located at the southernmost part of the Earth, surrounded by the Southern Ocean. It is situated around the South Pole and is predominantly covered by ice."
    },
    {
      question: "What are some important regions in Antarctica?",
      answer: "Some important regions in Antarctica include the Antarctic Peninsula, East Antarctica, West Antarctica, and the Ross Ice Shelf."
    },
    {
      question: "What is the climate like in Antarctica?",
      answer: "Antarctica has an extremely cold and dry climate, with temperatures often dropping below -60°C (-76°F) in winter. Most of the continent is covered by ice, and it is the coldest and windiest place on Earth."
    },
    {
      question: "How many research stations are there in Antarctica?",
      answer: "There are numerous research stations in Antarctica, operated by various countries for scientific studies in fields such as meteorology, geology, biology, and climate change research."
    },
    {
      question: "What is the largest glacier in Antarctica?",
      answer: "The largest glacier in Antarctica is the Lambert Glacier, located in East Antarctica. It is the world's largest glacier by volume."
    },
    {
      question: "What is the population of Antarctica?",
      answer: "Antarctica has no permanent population. The population consists of researchers and support staff who reside temporarily at research stations, which vary in population size throughout the year."
    },
    {
      question: "When was the Antarctic Treaty signed?",
      answer: "The Antarctic Treaty was signed on December 1, 1959. It came into force on June 23, 1961, and serves as the primary international agreement regulating activities in Antarctica."
    },
    {
      question: "What is the purpose of the Antarctic Treaty?",
      answer: "The Antarctic Treaty aims to promote scientific research, preserve the continent's environment, prohibit military activity, and establish international cooperation among signatory nations."
    },
    {
      question: "What is the largest ice shelf in Antarctica?",
      answer: "The largest ice shelf in Antarctica is the Ross Ice Shelf, which covers an area of about 487,000 square kilometers (188,000 square miles). It is located in West Antarctica."
    },
    {
      question: "What is the significance of Lake Vostok in Antarctica?",
      answer: "Lake Vostok is one of the largest subglacial lakes in Antarctica. It is of great interest to scientists because it has been isolated from the outside world for millions of years and may contain unique forms of life."
    },
    {
      question: "What is the coldest temperature ever recorded in Antarctica?",
      answer: "The coldest temperature ever recorded in Antarctica, and on Earth, was -89.2°C (-128.6°F) at the Soviet Union's Vostok Station on July 21, 1983."
    },
    {
      question: "How thick is the ice sheet covering Antarctica?",
      answer: "The ice sheet covering Antarctica is, on average, about 1.6 kilometers (1 mile) thick. However, in some areas, it can be several kilometers thick."
    },
    {
      question: "What is the southernmost point of Antarctica?",
      answer: "The southernmost point of Antarctica is the geographic South Pole, located at 90 degrees south latitude."
    },
    {
      question: "What is the wildlife like in Antarctica?",
      answer: "Antarctica is home to various species of wildlife, including penguins, seals, whales, and numerous bird species. These animals have adapted to the extreme cold and harsh conditions of the continent."
    },
    {
      question: "What is the purpose of the Antarctic Conservation Act?",
      answer: "The Antarctic Conservation Act is a U.S. law enacted in 1978 to protect the Antarctic environment and its ecosystems. It regulates U.S. activities in Antarctica and supports the principles of the Antarctic Treaty."
    },
    {
      question: "What are some famous explorers who have journeyed to Antarctica?",
      answer: "Some famous explorers who have journeyed to Antarctica include Roald Amundsen, Robert Falcon Scott, Ernest Shackleton, and Richard E. Byrd."
    },
    {
      question: "How many time zones are there in Antarctica?",
      answer: "Antarctica is primarily covered by the time zones of the countries that operate research stations there. Therefore, it spans multiple time zones depending on the location of the stations."
    },
    {
      question: "What are some of the challenges faced by researchers in Antarctica?",
      answer: "Researchers in Antarctica face challenges such as extreme cold, isolation, logistical difficulties, and the need for specialized equipment and supplies to conduct scientific studies in such a remote and harsh environment."
    },
    {
      question: "What is the aurora australis?",
      answer: "The aurora australis, also known as the southern lights, is a natural light display in the Earth's sky, predominantly seen in the high-latitude regions of the Southern Hemisphere, including Antarctica."
    },
    {
      question: "What is the significance of the McMurdo Dry Valleys in Antarctica?",
      answer: "The McMurdo Dry Valleys are a series of snow-free valleys in Antarctica, known for their extreme dryness and resemblance to Mars. They are of interest to scientists studying astrobiology and climate change."
    },
    {
      question: "What is the ozone hole over Antarctica?",
      answer: "The ozone hole over Antarctica is an area of severely depleted ozone concentrations in the stratosphere, primarily occurring during the Southern Hemisphere spring. It is caused by human-produced chemicals known as chlorofluorocarbons (CFCs) and has led to concerns about increased ultraviolet radiation reaching the Earth's surface."
    },
    {
      question: "What are some of the international research programs conducted in Antarctica?",
      answer: "There are several international research programs conducted in Antarctica, including the United States Antarctic Program (USAP), the British Antarctic Survey (BAS), and the Australian Antarctic Program (AAP). These programs focus on various scientific disciplines, including climate change, geology, biology, and oceanography."
    },
    {
      question: "What is the Southern Ocean?",
      answer: "The Southern Ocean is the body of water that surrounds Antarctica. It is characterized by strong currents, cold temperatures, and diverse marine life, including whales, seals, and krill."
    },
    {
      question: "What are some of the indigenous peoples associated with Antarctica?",
      answer: "There are no indigenous human populations native to Antarctica. However, indigenous peoples from neighboring regions, such as the Inuit of the Arctic, have cultural connections to polar environments."
    },
    {
      question: "What is the significance of the Antarctic Circumpolar Current?",
      answer: "The Antarctic Circumpolar Current is the strongest ocean current on Earth, flowing clockwise around Antarctica. It plays a crucial role in regulating global ocean circulation and climate."
    },
    {
      question: "What is the Antarctic convergence?",
      answer: "The Antarctic convergence, also known as the Antarctic Polar Front, is an oceanographic boundary where cold Antarctic waters meet warmer subantarctic waters. It is characterized by a sharp temperature gradient and a high concentration of marine life."
    },
    {
      question: "What is the role of krill in the Antarctic ecosystem?",
      answer: "Krill are small, shrimp-like crustaceans that form a vital component of the Antarctic food web. They are a primary food source for many marine animals, including whales, seals, and penguins."
    },
    {
      question: "What is the significance of the South Pole Telescope?",
      answer: "The South Pole Telescope is a large telescope located at the Amundsen-Scott South Pole Station in Antarctica. It is used to study cosmic microwave background radiation and conduct research in astrophysics and cosmology."
    },
    {
      question: "What is the Transantarctic Mountain Range?",
      answer: "The Transantarctic Mountain Range is a mountain range that divides East Antarctica from West Antarctica. It extends for over 3,500 kilometers (2,175 miles) and contains several prominent peaks and glaciers."
    },
    {
      question: "What are some of the environmental threats facing Antarctica?",
      answer: "Environmental threats facing Antarctica include climate change, pollution, overfishing, invasive species, and potential oil and mineral extraction. These threats endanger the delicate ecosystems of the continent and its surrounding waters."
    },
    {
      question: "What is the Antarctic Treaty Consultative Meeting (ATCM)?",
      answer: "The Antarctic Treaty Consultative Meeting (ATCM) is an annual gathering of Antarctic Treaty consultative parties to discuss matters related to the implementation and operation of the Antarctic Treaty System."
    },
    {
      question: "What is the significance of the Amundsen-Scott South Pole Station?",
      answer: "The Amundsen-Scott South Pole Station is a scientific research station located at the geographic South Pole. It conducts research in various fields, including astronomy, atmospheric science, and glaciology."
    },
    {
      question: "What is the Weddell Sea known for?",
      answer: "The Weddell Sea is known for its extensive sea ice cover, diverse marine life, and historical significance in Antarctic exploration. It is named after British navigator James Weddell, who explored the area in the early 19th century."
    },
    {
      question: "What is the significance of the Antarctic Peninsula?",
      answer: "The Antarctic Peninsula is a region of Antarctica extending towards South America. It is known for its stunning landscapes, diverse wildlife, and rapid warming due to climate change."
    },
    {
      question: "What is the difference between the North Pole and the South Pole?",
      answer: "The North Pole is the northernmost point on Earth, located in the Arctic Ocean, while the South Pole is the southernmost point on Earth, located on the continent of Antarctica. They represent opposite ends of the Earth's axis."
    },
    {
      question: "What is the role of ice shelves in Antarctica?",
      answer: "Ice shelves in Antarctica act as barriers, holding back the flow of glaciers into the ocean. They also provide stability to the ice sheets and play a role in regulating sea levels."
    },
    {
      question: "What is the significance of the Discovery Investigations?",
      answer: "The Discovery Investigations were a series of British scientific expeditions to Antarctica in the early 20th century. They contributed valuable knowledge about the continent's geography, wildlife, and climate."
    },
    {
      question: "What is the Treaty of Washington (1922)?",
      answer: "The Treaty of Washington, signed in 1922, resolved territorial claims in Antarctica made by Britain, Chile, and Argentina. It established a modus vivendi (living arrangement) for the administration of overlapping claims."
    },
    {
      question: "What is the significance of Cape Adare in Antarctica?",
      answer: "Cape Adare is a prominent headland on the northeastern coast of the Antarctic Peninsula. It is known for its historic huts built by early Antarctic explorers, including the first wintering party led by Carsten Borchgrevink in 1899."
    },
    {
      question: "What is the impact of climate change on Antarctica?",
      answer: "Climate change is having significant impacts on Antarctica, including melting ice shelves, retreating glaciers, and changes in wildlife populations. These changes have global implications for sea level rise and ocean circulation."
    },
    {
      question: "What is the significance of the Antarctic ozone hole?",
      answer: "The Antarctic ozone hole, first discovered in the 1980s, raised global awareness about the dangers of ozone depletion caused by human-produced chemicals. It led to international efforts to phase out ozone-depleting substances."
    },
    {
      question: "What is the importance of the Ross Sea in Antarctica?",
      answer: "The Ross Sea is one of the most pristine marine ecosystems in the world, known for its high biodiversity and unique geological features. It is home to a rich array of marine life, including whales, seals, and penguins."
    },
    {
      question: "What is the significance of the International Geophysical Year (IGY)?",
      answer: "The International Geophysical Year (IGY), celebrated in 1957-1958, marked a period of intensive scientific research and exploration, including expeditions to Antarctica. It laid the groundwork for international cooperation in polar science."
    },
    {
      question: "What is the importance of the Antarctic ice sheet?",
      answer: "The Antarctic ice sheet is the largest ice mass on Earth, containing about 60% of the world's fresh water. It plays a crucial role in regulating global climate and sea levels."
    },
    {
      question: "What is the significance of the Shackleton Expedition?",
      answer: "The Shackleton Expedition, led by Sir Ernest Shackleton in 1914-1917, is renowned for its remarkable tale of survival after the ship Endurance became trapped in ice. Despite adversity, Shackleton and his crew managed to safely navigate back to civilization."
    },
    {
      question: "What is the role of penguins in the Antarctic ecosystem?",
      answer: "Penguins are iconic Antarctic species that play a vital role in the marine ecosystem. They are key indicators of environmental health and help regulate prey populations, such as krill and fish."
    },
    {
      question: "What is the significance of the South Pole Traverse?",
      answer: "The South Pole Traverse is an overland route used to transport fuel and supplies from McMurdo Station to the Amundsen-Scott South Pole Station. It helps sustain scientific research activities in Antarctica."
    },
    {
      question: "What is the importance of marine protected areas in Antarctica?",
      answer: "Marine protected areas in Antarctica help conserve the region's unique marine biodiversity and ecosystems. They provide refuge for vulnerable species and support sustainable fisheries management."
    },
    {
      question: "What is the significance of the Dry Valleys in Antarctica?",
      answer: "The Dry Valleys are a unique polar desert ecosystem in Antarctica, devoid of snow and ice. They are of interest to scientists studying extremophiles and the limits of life on Earth."
    },
    {
      question: "What is the impact of tourism on Antarctica?",
      answer: "Tourism in Antarctica has increased in recent decades, raising concerns about its environmental impact, including pollution, disturbance to wildlife, and the risk of introducing invasive species. Responsible tourism practices are essential for minimizing these impacts."
    },
    {
      question: "What is the significance of the Amery Ice Shelf in Antarctica?",
      answer: "The Amery Ice Shelf is one of the largest ice shelves in Antarctica, covering an area of about 60,000 square kilometers (23,000 square miles). It plays a role in stabilizing the East Antarctic Ice Sheet."
    },
    {
      question: "What is the importance of the Southern Hemisphere westerly winds?",
      answer: "The Southern Hemisphere westerly winds play a crucial role in shaping Antarctic climate and ocean circulation. They influence sea ice distribution, nutrient transport, and the Southern Ocean carbon cycle."
    },
    {
      question: "What is the significance of the Gamburtsev Mountains in Antarctica?",
      answer: "The Gamburtsev Mountains are a subglacial mountain range in East Antarctica, buried beneath the Antarctic ice sheet. They are of interest to scientists studying tectonics, climate history, and ice dynamics."
    },
    {
      question: "What is the impact of fishing on Antarctic marine ecosystems?",
      answer: "Fishing in Antarctica, particularly for krill and fish species, can have significant impacts on marine ecosystems and food webs. Sustainable management practices are essential for maintaining the health and resilience of Antarctic ecosystems."
    },
    {
      question: "What is the significance of the Antarctic Ice Marathon?",
      answer: "The Antarctic Ice Marathon is an annual marathon race held near the geographic South Pole. It is one of the world's most extreme sporting events, attracting participants from around the globe."
    },
    {
      question: "What is the importance of ice cores in Antarctic research?",
      answer: "Ice cores drilled from Antarctic ice sheets contain valuable information about past climate conditions, atmospheric composition, and environmental changes over thousands of years. They provide insights into Earth's climate history and future climate projections."
    },
    {
      question: "What is the significance of the Larsen Ice Shelf in Antarctica?",
      answer: "The Larsen Ice Shelf is a series of ice shelves along the eastern Antarctic Peninsula. It has experienced significant ice shelf collapse in recent decades, highlighting the impact of climate change on Antarctica's ice dynamics."
    },
    {
      question: "What is the role of international cooperation in Antarctic research?",
      answer: "International cooperation is essential for conducting scientific research in Antarctica, sharing resources, expertise, and data among nations. Collaborative efforts promote efficient use of resources and advance our understanding of the continent and its environment."
    },
    {
      question: "What is the significance of the Antarctic Treaty Secretariat?",
      answer: "The Antarctic Treaty Secretariat is an international organization based in Buenos Aires, Argentina, responsible for providing administrative support to the Antarctic Treaty System and promoting cooperation among Antarctic Treaty parties."
    },
    {
      question: "What is the impact of seismic activity on Antarctica?",
      answer: "Seismic activity in Antarctica, including earthquakes and volcanic eruptions, can have various effects on the continent's ice dynamics, landscape, and marine environment. Monitoring and research efforts are essential for understanding these impacts."
    },
    {
      question: "What is the importance of the Southern Ocean Carbon and Climate Observations and Modeling (SOCCOM) program?",
      answer: "The SOCCOM program is a research initiative aimed at understanding the role of the Southern Ocean in the global carbon cycle and climate system. It involves deploying robotic floats equipped with sensors to monitor ocean properties and processes."
    },
    {
      question: "What is the significance of Antarctic toothfish?",
      answer: "Antarctic toothfish, also known as Chilean sea bass, is a commercially valuable species found in Antarctic waters. Sustainable management practices are necessary to prevent overfishing and protect the species and its ecosystem."
    },
    {
      question: "What is the impact of ocean acidification on Antarctic marine life?",
      answer: "Ocean acidification, caused by the absorption of carbon dioxide by seawater, can have detrimental effects on Antarctic marine life, including shell-forming organisms like krill and plankton. Monitoring and research are needed to understand and mitigate these impacts."
    },
    {
      question: "What is the significance of the Antarctic Peninsula Ice Sheet?",
      answer: "The Antarctic Peninsula Ice Sheet is a major contributor to sea level rise, with accelerating ice loss in recent decades due to climate change. Understanding its dynamics is crucial for predicting future sea level changes and coastal impacts."
    },
    {
      question: "What is the importance of the Southern Ocean Observing System (SOOS)?",
      answer: "SOOS is an international initiative aimed at improving our understanding of the Southern Ocean through sustained observations and data collection. It provides essential information for monitoring climate variability, ecosystem health, and ocean circulation."
    },
    {
      question: "What is the significance of the Antarctic Convergence Front?",
      answer: "The Antarctic Convergence Front is a dynamic oceanographic boundary where cold Antarctic waters meet warmer subantarctic waters. It plays a vital role in shaping marine ecosystems, nutrient cycling, and ocean circulation in the Southern Ocean."
    },
    {
      question: "What is the impact of glacial retreat on Antarctic coastal ecosystems?",
      answer: "Glacial retreat along the Antarctic coast can lead to changes in coastal ecosystems, including habitat loss, altered sedimentation patterns, and shifts in biodiversity. Understanding these impacts is essential for conservation and management efforts."
    },
    {
      question: "What is the significance of the Southern Annular Mode (SAM) in Antarctic climate?",
      answer: "The SAM is a climate phenomenon that influences atmospheric circulation patterns over the Southern Hemisphere, including Antarctica. Changes in the SAM can affect temperature, precipitation, and sea ice extent in the region."
    },
    {
      question: "What is the importance of Antarctic icebergs?",
      answer: "Antarctic icebergs play a significant role in shaping marine ecosystems, ocean circulation, and climate processes. They provide habitat for marine organisms, transport nutrients, and contribute to the global freshwater cycle."
    },
    {
      question: "What is the significance of the Antarctic Treaty Environmental Protocol?",
      answer: "The Antarctic Treaty Environmental Protocol, adopted in 1991, strengthens environmental protection measures in Antarctica, including waste management, conservation of flora and fauna, and prevention of marine pollution. It reflects the commitment of Antarctic Treaty parties to preserve the continent's unique environment."
    },
    {
      question: "What is the impact of ultraviolet radiation on Antarctic ecosystems?",
      answer: "Ultraviolet (UV) radiation, particularly during the austral spring and summer, can have harmful effects on Antarctic ecosystems, including damage to DNA, photosynthetic processes, and reproductive success of marine organisms. Monitoring and research are necessary to assess and mitigate these impacts."
    },
    {
      question: "What is the significance of the Antarctic Bottom Water?",
      answer: "Antarctic Bottom Water is a dense, cold water mass that forms in the Southern Ocean near Antarctica and plays a critical role in global ocean circulation. It helps drive the global thermohaline circulation and regulates heat and carbon storage in the deep ocean."
    },
    {
      question: "What is the importance of Antarctic marine protected areas?",
      answer: "Antarctic marine protected areas (MPAs) help conserve the region's unique marine biodiversity and ecosystems, including vulnerable species such as krill, penguins, and seals. They provide refuge for wildlife, support sustainable fisheries management, and contribute to global efforts to protect the Southern Ocean."
    }
  ];
  