import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonTupleMethods() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeCount = `
# Count occurrences of a value
my_tuple = (1, 2, 2, 3, 4, 2)
count = my_tuple.count(2)
print(count)  # Output: 3
    `;

    const codeIndex = `
# Find the index of a value
my_tuple = (1, 2, 3, 4, 5)
index = my_tuple.index(3)
print(index)  # Output: 2
    `;

    const codeMethods = `
# Available tuple methods
my_tuple = (1, 2, 3)
methods = dir(my_tuple)
print(methods)
    `;

    return (
        <body>
            <Helmet>
                <title>Python Tuple Methods</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Tuple Methods, Tuple Count, Tuple Index, Tuple Methods" />
                <meta name="description" content="Learn about tuple methods in Python, including counting occurrences, finding indices, and available methods." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_tuples"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_tuple_unpacking"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Tuple Methods</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Python provides several built-in methods for working with tuples. This tutorial covers some common tuple methods.</p>
                        
                        <h2>Count</h2>
                        <p>The <code>count()</code> method returns the number of occurrences of a specified value in a tuple.</p>
                        <SyntaxHighlighterComponent code={codeCount} language="python" />
                        
                        <h2>Index</h2>
                        <p>The <code>index()</code> method returns the index of the first occurrence of a specified value in a tuple.</p>
                        <SyntaxHighlighterComponent code={codeIndex} language="python" />
                        
                        <h2>Available Methods</h2>
                        <p>You can use the <code>dir()</code> function to list all available methods for a tuple.</p>
                        <SyntaxHighlighterComponent code={codeMethods} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Understanding tuple methods allows you to perform various operations on tuples efficiently. Explore the available methods to make the most out of tuples in Python.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_tuples"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_tuple_unpacking"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
