import React from 'react';
import '../css/my style.css';
import '../css/my style2.css';
import '../css/my style6.css';
import SearchBar from './Searchbar/SearchBar-dashboard';



 function Header() {

const headerDashboard = {
    padding: '140px',
    textAlign: 'center',
    background: 'darkblue'
}
 const headerDashboardh1 = {
    fontSize: '70px',
    color: 'white',
   
 }
    return (
    <header style={headerDashboard}>
           <div className="header-content">
     <div className="centered-content">
   <h1 className="topple" style={headerDashboardh1}>
        <span>D</span>
        <span>a</span>
        <span>s</span>
        <span>h</span>
        <span>b</span>
        <span>o</span>
        <span>a</span>
        <span>r</span>
        <span>d</span>
  
  </h1><br />
  <SearchBar />
  </div>
  </div>
      </header>
    );
}
export default Header;