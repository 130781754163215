import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function CSSinserting() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   
    const code = `
    <!DOCTYPE html>
    <html>
    <head>
    <title>My First Web Page</title>
    </head>
    <body>
    <p style="color: dodgerblue;text-align: center;">Beta Thread is awesome</p>
    </body>
    </html>
  
      `;
      const code2 = `
    <style type="text/css">
    h4 {
        background-color: #00f;
        color: #fff;
    }
    </style>
    
`;
      const code3 = `
      h2{
        color: #0f0;
      }
      p {
        color: red;
      }
       
    
`;
const code4 = `
    <!DOCTYPE html>
    <html>
    <head>
    <title>My First Web Page</title>
    <link rel="stylesheet" href="firststyle.css" type="text/css">
    </head>
    <body>
    <h2>Hello World!</h2>
    <p>Learning is fun.</p>
    </body>
    </html>

`;

    return (
   <body>
     <Helmet>     
        <title>CSS Inserting</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Inserting, Inline Styling, Internal Style Sheet, External Style Sheet
        Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="To apply or introduce CSS into an HTML document there are three ways, Inline Styling,
      Internal Style Sheet, External Style Sheet" />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/CSS_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_comments"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>CSS Inserting</h1>
</header>

<Navbar />

<main>
<section>

<p>To apply or introduce CSS into an HTML document there are three ways</p>
<li>Inline Styling</li>
<li>Internal Style Sheet</li>
<li>External Style Sheet</li>
<p>In this tutorial we will be making use of mainly internal and inline styling.</p>
<h2>Inline Styling</h2>
<p>Inline styling is carried out using the style attribute.It is a good choice for styling a single element
    instead multiple documents.
</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code} language="html" />
<ScrollToTopLink to="/try_css2"><Tryitout /></ScrollToTopLink>
    </section>
    
    <section>
<h2>Internal Style Sheet</h2>
<p>The internal style sheet is a good choice when you want to style a single HTML document thus making it
    unique.
</p>
<p>All the CSS rulesets should be placed inside the &lt;style&gt; element.The &lt;style&gt; element 
    should be enclosed.
</p>
<h3>Type Attribute</h3>
<p>This attribute defines the styling language as a MIME type (charset should not be specified).</p>
<p>This attribute is optional and defaults to text/css if it is not specified.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="css" />
<ScrollToTopLink to="/try_css3"><Tryitout /></ScrollToTopLink>
</section>

<section>
    <h2>External Style Sheet</h2>
    <p>An external style sheet is an excellent choice when you want to style multiple pages of a website
        with the same design.
    </p>
    <p>To do this you will have to create an external CSS file and save it with the .css extension e.g.file.css
        after that we will use te &lt;link&gt; element to include the external CSS file in our HTML document.
    </p>
    <h2>NOTE:</h2>
    <p>In the external file do not place the &lt;style&gt; element just write your CSS code directly.</p>
    <br />
    <p>This is the code in the external CSS file named firststyle.css</p>
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code3} language="css" />
    <p>This is the full example containing the linked CSS file and the HTML document.</p>
<br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code4} language="css" />
<ScrollToTopLink to="/try_css4"><Tryitout /></ScrollToTopLink>

</section>
</main>
<div className="head">
<ScrollToTopLink to="/CSS_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_comments"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}