import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function CSSselectors() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   
    const code = `
    <style type="text/css">
    h3 {      
       font-size:50px;
       color: green;
   }
   </style>  
  }
      `;
     const code2 = `
     <style type="text/css">
     .example {       /* Class Selector */
         font-size:40px;
         color: red;
     }
     </style>
     `;
     const code3 = `
     <style type="text/css">
     #example {       /* ID Selector */
         font-size:40px;
         color: blue;
     }
     </style>
     `;
  
    return (
   <body>
      <Helmet>     
        <title>CSS Selectors</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Selectors, Element Selectors, Class Selector, ID Selector
        Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="The element selector provides an easy way to target elements we want to style. The class selector provides a way
       for CSS and JavaScript to select and access specific elements via the class selectors(CSS) or functions(JavaScript).The ID selector that defines a unique identifier(ID) which must be unique in the whole document." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
   <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/CSS_syntax"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_combinators"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>CSS Selectors</h1>
</header>

<Navbar />

<main>
<section>
<p>In this lesson you will learn about CSS selectors and how they can be used.</p>
     <p>There are three types of selectors in CSS, they are:</p>
     <li>Element Selectors</li>
     <li>Class Selector</li>
     <li>ID Selector</li>
     <h2>Element Selector</h2>
     <p>This provides an easy way to target elements we want to style.</p>
     <br />
     <h2>Example:</h2>
     <SyntaxHighlighterComponent code={code} language="css" />
<ScrollToTopLink to="/try_css7"><Tryitout /></ScrollToTopLink>
     <h2>Class Selector</h2>
     <p>The class selector provides a way for CSS and JavaScript to select and access specific elements 
        via the class selectors(CSS) or functions(JavaScript).</p>
    <p>A class selector is indicated by adding the fullstop(.) sign in front of the class name in the CSS file.
        e.g,.className.
    </p>
    <p>The class name can be accessed by using the class selector in this format &lt;h1 class="className"&gt;
        I love CSS&lt;/h1&gt;
    </p>
    <h3>NOTE:</h3>
<p>Multiple elements can make use of the same class in an HTML document.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="css" />
<ScrollToTopLink to="/try_css8"><Tryitout /></ScrollToTopLink>

<h2>ID Selector</h2>
<p>The ID selector that defines a unique identifier(ID) which must be unique in the whole document.This means
    that there can only be a single ID in a document and multiple elements cannot make use of it only a single one
</p>
<p>The purpose is to identify the element when linking (using a fragment identifier), scripting,
     or styling (with CSS).</p>
     <p>An ID selector is indicated by adding the pound sign(#) in front of the id name in the CSS file.
        e.g,#IDName.
    </p>
    <p>The ID name can be accessed by using the id selector in this format &lt;h1 id="IDName"&gt;
        I love CSS&lt;/h1&gt;
    </p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code3} language="css" />
<ScrollToTopLink to="/try_css9"><Tryitout /></ScrollToTopLink>


</section>
</main>
<div className="head">
<ScrollToTopLink to="/CSS_syntax"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_combinators"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}