import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonTupleUnpacking() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const code = `
# Tuple Unpacking
my_tuple = (1, 2, 3)
x, y, z = my_tuple
print(x)  # Output: 1
print(y)  # Output: 2
print(z)  # Output: 3
    `;

    return (
        <body>
            <Helmet>
                <title>Tuple Unpacking</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Tuple Unpacking, Unpacking Tuples" />
                <meta name="description" content="Learn about unpacking tuples in Python, a powerful feature for assigning multiple values at once." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_tuple_methods"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_tuple_concatenation"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Tuple Unpacking</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Tuple unpacking allows you to assign the elements of a tuple to multiple variables in a single statement.</p>
                        
                        <h2>Example</h2>
                        <p>Let's see an example of tuple unpacking:</p>
                        <SyntaxHighlighterComponent code={code} language="python" />
                        
                        <p>In this example, the tuple <code>my_tuple</code> contains three elements. By unpacking it into variables <code>x</code>, <code>y</code>, and <code>z</code>, we assign each element of the tuple to a separate variable.</p>
                        
                        <h2>Use Cases</h2>
                        <p>Tuple unpacking is commonly used to iterate over the elements of a tuple or to assign multiple return values from a function to individual variables.</p>
                        
                        <h2>Conclusion</h2>
                        <p>Understanding tuple unpacking is essential for efficiently working with tuples in Python. It provides a concise and readable way to assign multiple values at once.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_tuple_methods"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_tuple_concatenation"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
