import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonConditionals() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeIf = `
# If statement
a = 10
b = 20
if a < b:
    print("a is less than b")
    `;

    const codeIfElse = `
# If-Else statement
a = 10
b = 20
if a > b:
    print("a is greater than b")
else:
    print("a is less than or equal to b")
    `;

    const codeElif = `
# Elif statement
a = 10
b = 20
if a > b:
    print("a is greater than b")
elif a < b:
    print("a is less than b")
else:
    print("a is equal to b")
    `;

    const codeNestedIf = `
# Nested If statement
a = 10
b = 20
c = 30
if a < b:
    if b < c:
        print("a is less than b and b is less than c")
    `;

    const codeTernary = `
# Ternary (Conditional) Operator
a = 10
b = 20
print("a is greater") if a > b else print("b is greater")
    `;

    return (
        <body>
            <Helmet>
                <title>Python Conditional Statements</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Conditional Statements, If Statement, If-Else Statement, Elif Statement, Nested If Statement, Ternary Operator" />
                <meta name="description" content="Comprehensive guide to Python conditional statements, including if, if-else, elif, nested if, and ternary operators." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_operators"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_loops"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Conditional Statements</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Conditional statements in Python allow you to execute certain pieces of code based on specific conditions. They are fundamental to controlling the flow of your program.</p>
                        
                        <h2>If Statement</h2>
                        <p>The <code>if</code> statement executes a block of code if a specified condition is true.</p>
                        <SyntaxHighlighterComponent code={codeIf} language="python" />
                        
                        <h2>If-Else Statement</h2>
                        <p>The <code>if-else</code> statement provides an alternative block of code to execute if the condition is false.</p>
                        <SyntaxHighlighterComponent code={codeIfElse} language="python" />
                        
                        <h2>Elif Statement</h2>
                        <p>The <code>elif</code> statement allows you to check multiple expressions for true and execute a block of code as soon as one of the conditions is true.</p>
                        <SyntaxHighlighterComponent code={codeElif} language="python" />
                        
                        <h2>Nested If Statement</h2>
                        <p>You can nest <code>if</code> statements within other <code>if</code> statements to create more complex conditions.</p>
                        <SyntaxHighlighterComponent code={codeNestedIf} language="python" />
                        
                        <h2>Ternary (Conditional) Operator</h2>
                        <p>The ternary operator is a one-line shorthand for an <code>if-else</code> statement.</p>
                        <SyntaxHighlighterComponent code={codeTernary} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Understanding conditional statements is crucial for controlling the flow of your Python programs. This tutorial covered the basics of <code>if</code>, <code>if-else</code>, <code>elif</code>, nested <code>if</code>, and the ternary operator.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_operators"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_loops"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
