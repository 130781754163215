import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";
import ButtonNext from "../../components/Buttonnext";

export default function RubyDateTime() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const dateTimeExampleCode = `
# Date and Time Example
require 'date'

# Get current date and time
current_date = Date.today
current_time = Time.now

# Output current date and time
puts "Current Date: #{current_date}"
puts "Current Time: #{current_time}"
  `;

  const dateTimeFormattingCode = `
# Date and Time Formatting
require 'date'

# Get current time
current_time = Time.now

# Format date and time
puts current_time.strftime("Today is %A, %B %d, %Y")
puts current_time.strftime("The time is %I:%M %p")
  `;

  const dateTimeManipulationCode = `
# Date and Time Manipulation
require 'date'

# Create a specific date
date = Date.new(2023, 8, 15)

# Add 7 days to the date
new_date = date + 7

puts "Original Date: #{date}"
puts "New Date after adding 7 days: #{new_date}"
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Date and Time</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Date and Time, Date, Time, Date Formatting, Time Formatting, Date Manipulation" />
        <meta name="description" content="Learn about date and time handling in Ruby, including how to work with dates, times, format them, and perform manipulations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_symbols"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Date and Time</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Ruby provides robust support for handling date and time through built-in classes and modules.</p>

            <h2>Example: Fetching Current Date and Time</h2>
            <p>Retrieve and display the current date and time using Ruby.</p>
            <SyntaxHighlighterComponent code={dateTimeExampleCode} language="perl" />
            <p>In this example:</p>
            <ul>
              <li>We require the 'date' library to work with date-related functionalities.</li>
              <li>We fetch the current date with <code>Date.today</code> and the current time with <code>Time.now</code>.</li>
              <li>We output these values using <code>puts</code>.</li>
            </ul>

            <h2>Date and Time Formatting</h2>
            <p>Format date and time objects into strings using <code>strftime</code>.</p>
            <SyntaxHighlighterComponent code={dateTimeFormattingCode} language="ruby" />
            <p>In this example:</p>
            <ul>
              <li>We use <code>strftime</code> to format the current time in two different formats:</li>
              <li><code>"Today is %A, %B %d, %Y"</code> formats the date as "Today is 'Day' of 'Week', Month Day, Year".</li>
              <li><code>"The time is %I:%M %p"</code> formats the time as "The time is Hour:Minute AM/PM".</li>
            </ul>

            <h2>Date and Time Manipulation</h2>
            <p>Perform operations like addition or subtraction on date objects.</p>
            <SyntaxHighlighterComponent code={dateTimeManipulationCode} language="perl" />
            <p>In this example:</p>
            <ul>
              <li>We create a specific date using <code>Date.new(year, month, day)</code>.</li>
              <li>We add 7 days to the date using the <code>+</code> operator.</li>
              <li>We display the original date and the date after adding 7 days.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_symbols"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
