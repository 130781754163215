import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../ScrollToTop";
import { useLocation } from "react-router-dom";
import SQLSearchBar from "../Searchbar/AI SearchBar/AISearchBar";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export default function Sidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  const nav = {
    color: "white",
    fontSize: "30px",
  };

  const newCode = {
    float: "right",
  };

  return (
    <div>
      <div className={`sidebar ${isOpen ? "responsive" : ""}`} id="mySidebar">
        <span className="close" onClick={toggle}>&times;</span>
        <h2>AI Tutorial</h2><br />
        <span><SQLSearchBar /></span><br />
        <p className="p-sidebar">...Empowering Your Online Presence...</p><br />
        <ScrollToTopLink to="/AI_Tutorial_intro"><a className={location.pathname === "/AI_Tutorial_intro" ? "active" : ""}>AI Introduction</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_history"><a className={location.pathname === "/AI_history" ? "active" : ""}>AI History</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_types"><a className={location.pathname === "/AI_types" ? "active" : ""}>AI Types</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_problem_solving"><a className={location.pathname === "/AI_problem_solving" ? "active" : ""}>AI Problem Solving</a></ScrollToTopLink><br />
        <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown}>
          AI Machine Learning {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen && (
          <div className="dropdown-content">
        <ScrollToTopLink to="/AI_machine_learning"><a className={location.pathname === "/AI_machine_learning" ? "active" : ""}>AI Machine Learning</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_supervised_learning"><a className={location.pathname === "/AI_supervised_learning" ? "active" : ""}>AI Supervised Learning</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_unsupervised_learning"><a className={location.pathname === "/AI_unsupervised_learning" ? "active" : ""}>AI Unsupervised Learning</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_reinforcement_learning"><a className={location.pathname === "/AI_reinforcement_learning" ? "active" : ""}>AI Reinforcement Learning</a></ScrollToTopLink><br />
  </div>
        )}
      </div>
         <ScrollToTopLink to="/AI_neural_networks"><a className={location.pathname === "/AI_neural_networks" ? "active" : ""}>AI Neural Networks</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_deep_learning"><a className={location.pathname === "/AI_deep_learning" ? "active" : ""}>AI Deep Learning</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_cnns"><a className={location.pathname === "/AI_cnns" ? "active" : ""}>AI CNNs</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_rnns"><a className={location.pathname === "/AI_rnns" ? "active" : ""}>AI RNNs</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_lstmns"><a className={location.pathname === "/AI_lstmns" ? "active" : ""}>AI LSTM Networks</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_gans"><a className={location.pathname === "/AI_gans" ? "active" : ""}>AI GANs</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_nlp"><a className={location.pathname === "/AI_nlp" ? "active" : ""}>AI NLP</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_text_preprocessing"><a className={location.pathname === "/AI_text_preprocessing" ? "active" : ""}>AI Text Preprocessing</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_sentiment_analysis"><a className={location.pathname === "/AI_sentiment_analysis" ? "active" : ""}>AI Sentiment Analysis</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_ner"><a className={location.pathname === "/AI_ner" ? "active" : ""}>AI NER</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_language_models"><a className={location.pathname === "/AI_language_models" ? "active" : ""}>AI Language Models</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_computer_vision"><a className={location.pathname === "/AI_computer_vision" ? "active" : ""}>AI Computer Vision</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_image_processing"><a className={location.pathname === "/AI_image_processing" ? "active" : ""}>AI Image Processing</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_object_detection"><a className={location.pathname === "/AI_object_detection" ? "active" : ""}>AI Object Detection</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_image_segmentation"><a className={location.pathname === "/AI_image_segmentation" ? "active" : ""}>AI Image Segmentation</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_facial_recognition"><a className={location.pathname === "/AI_facial_recognition" ? "active" : ""}>AI Facial Recognition</a></ScrollToTopLink><br />
        <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown2}>
          AI Robotics {isDropdownOpen2 ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen2 && (
          <div className="dropdown-content">
        <ScrollToTopLink to="/AI_robotics"><a className={location.pathname === "/AI_robotics" ? "active" : ""}>AI Robotics</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_robotics_hardware"><a className={location.pathname === "/AI_robotics_hardware" ? "active" : ""}>AI Robotics Hardware</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_robotics_software"><a className={location.pathname === "/AI_robotics_software" ? "active" : ""}>AI Robotics Software</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/AI_robotics_applications"><a className={location.pathname === "/AI_robotics_applications" ? "active" : ""}>AI Robotics Applications</a></ScrollToTopLink><br />
     </div>
        )}
      </div>
        <ScrollToTopLink to="/AI_ethics"><a className={location.pathname === "/AI_ethics" ? "active" : ""}>AI Ethics</a></ScrollToTopLink><br />
      </div>

      <div className="burger-icon" onClick={toggle}>
        &#9776;<p style={newCode}>Menu</p>
      </div>
    </div>
  );
}
