import React from "react";
import { Helmet } from "react-helmet";


export default function ComingSoonTips() {
    const message = {
        textAlign: 'center',
        backgroundColor: 'darkblue',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
    }
    var body = {
    margin: 0,
    padding: 0,
    fontFamily: 'Arial, sans-serif',
    backgroundColor: 'darkblue',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
    }
    const heading = {
    marginBottom: '10px',
    color: "white"
    }
    const paragraph = {
    fontSize: '18px',
    lineHeight: '1.5',
    color: "white"
    }
    return (
        <div style={body}>
    <Helmet>
     <title>Tips Coming Soon | Beta Thread</title>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Tutorial Tips, HTML, Python, CSS, SQL, JavaScript, How to, PHP, Java, C, C++, C#, jQuery, Bootstrap, Colors, W3.CSS, XML, MySQL, Icons, NodeJS, React, Graphics, Angular, R, AI, Git, Data Science, Code Game, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, References, Examples, Learn to code, Source code, Demos, Tips, Website, Coding tutorials and guides Best coding courses,Front-end and back-end development,Step-by-step Python programming tutorials Java coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
    C++ programming tips for real-world applications," />
    <meta name="description" content="We're working hard to bring you more tips. Stay tuned for exciting content!" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />
    </Helmet>
    
    <div style={message}>
        <h1 style={heading}>Other Tips Coming Soon</h1>
        <p style={paragraph}>We're working hard to bring you more tips. Stay tuned for exciting content!</p>
    </div>
        </div>
    )
}