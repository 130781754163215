import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function ReactES6() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
  
    return (
   <body>
     <Helmet>
        
        <title>React ES6</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React ES6, ES6 Modules, ES6 Arrow Functioning, ES6 Class, ES6 Template Literals, ES6 Destructive Assignments, ES6 Spread and Rst Operators
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="ES6 is also known as ECMAScript 6. ECMAScript 2015 (ES6) revolutionized JavaScript by introducing new syntax and features." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_create-react-app"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-modules"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>React ES6</h1>
</header>

<Navbar />

<main>
<section>
<h1>What is ES6?</h1>
<p>ES6 is also known as ECMAScript 6.</p>
<p>ECMAScript 2015 was first introduced in 2015 and is the first version.</p>
<p>ECMAScript 2015 (ES6) revolutionized JavaScript by introducing new syntax and features.</p>
<p>In the context of React development, ES6 plays a crucial role in enhancing code readability and maintainability.</p>
  <p>ES6 has some new features such as:</p>
  <ul>
    <li>Modules</li>
    <li>Arrow Functioning</li>
    <li>Destructuring Assignment</li>
    <li>Template Literals</li>
    <li>Classes</li>
    <li>Spread and Rest Operators</li>
  </ul>
  <p>We'll learn more about ES6 features in the nest chapter</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_create-react-app"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-modules"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}