import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSLC_Operators() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
    let sunny = true;
    let warm = false;
    
    let isGoodDay = sunny && warm;    // false (both not true)
    let isAnyConditionMet = sunny || warm; // true (one is true)
    let isNotSunny = !sunny;          // false (inverted)
  
`;
const code2 = `
6==6 && 4==5; // returns false
10 &lt; 12 && 12 &gt; 10; // returns true
!(true); // returns false
!(2==3); // returns true
`;

const code3 = `
condition ? firstvalue : secondvalue
`;

const code4 = `
var amount = 5000;
var totalAmount = (amount > 3500) ? "sold" : "not sold";
document.getElementById('demo').innerHTML = totalAmount;
`;
const code5 = `
if (100 > 99 && 10 &lt; 11) {
    document.write("Hello World");
  }
  if (5==5 && 5 > 6) {
    document.write("I am learning JavaScript");
  } else {
    document.write("I am learning HTML");
  }
`;
    return (
   <body>
          <Helmet>
    
    <title>JavaScript Logical & Conditional Operators</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="JavaScript Logical & Conditional Operators, Step-by-step JavaScript programming tutorials, JavaScript coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
   JavaScript programming tips for real-world applications," />
  <meta name="description" content="Conditional Statements can be wriiten wwith the use of logical operators.
  The ternary operator is a concise way to write conditional statements." />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_arithmetic_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_comparison_operators"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Logical & Conditional Operators</h1>
</header>

<Navbar />

<main>
    <section>
    <h2>Logical Operators</h2>
<li>&& (Logical AND): Returns true if both operands are true.</li>
<li>|| (Logical OR): Returns true if at least one operand is true.</li>
<li>! (Logical NOT): Inverts or gives the opposite of the boolean value.</li>
<p>Here is an example making use of the logical operators</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
<ScrollToTopLink to="/try_js35"><Tryitout /></ScrollToTopLink>  

    <p>This is another example of the logical operators.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js36"><Tryitout /></ScrollToTopLink>  

    <h2>Conditional Operators</h2>
    <p>The ternary operator is a concise way to write conditional statements.</p>
    <p>A condition is something which must happen or be done for another action to take place.It is the only
        operator that takes three operands
    </p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code3} language="javascript" />

 <p>If the condition is true the operator returns the first value, otherwise it returns the second value.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code4} language="javascript" />
<ScrollToTopLink to="/try_js37"><Tryitout /></ScrollToTopLink>

<h2>Logical Operators and Conditional Statements</h2>
<p>Conditional Statements can be wriiten wwith the use of logical operators.There will be a seperate Lesson
    for conditional statements.
</p>
<p>We will make use of the if and else conditional statement in the example below.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code5} language="javascript" />
<ScrollToTopLink to="/try_js38"><Tryitout /></ScrollToTopLink>

</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_arithmetic_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_comparison_operators"><ButtonNext /></ScrollToTopLink>
</div>


</div>

<Footertutorials />
   </body>
    )
}