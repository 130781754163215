import { Link } from "react-router-dom";
import Nullnav from "../nullnav";
import ScrollToTopLink from "../ScrollToTop";
import { Helmet } from "react-helmet";
import NotFoundImage from "../../image/nothing.png"; // Assuming this is your image file

const Null = () => {
    const containerStyle = {
        textAlign: "center",
        padding: "20px",
    };

    const contentContainerStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        margin: "20px auto",
        maxWidth: "800px",
    };

    const imageStyle = {
        maxWidth: "50%",
        height: "auto",
        marginRight: "20px",
    };

    const textContainerStyle = {
        flex: "1",
        textAlign: "left",
    };

    const titleStyle = {
        fontSize: "4rem",
        marginBottom: "20px",
        color: "darkblue"
    };

    const subtitleStyle = {
        fontSize: "2rem",
        marginBottom: "10px",
        color: "darkblue"
    };

    const buttonContainerStyle = {
        marginTop: "20px",
    };

    var paragraphstyles = {
       color: "darkblue"
    }

    return (
        <>
            <Helmet>
                <meta name="description" content="Sorry! The page you have requested for cannot be found." />
                <title>404 - Page not found | Beta Thread</title>
            </Helmet>
            <Nullnav />

            <section style={containerStyle}>
                <div style={contentContainerStyle}>
                    <img src={NotFoundImage} alt="Nothing Found" style={imageStyle} />
                    <div style={textContainerStyle}>
                      <br />  <br />  <br />  <br />
                        <h1 style={titleStyle}>404</h1>
                        <p style={subtitleStyle}>Sorry!</p>
                        <p style={paragraphstyles}>The page you have requested for cannot be found.</p>
                        <p style={paragraphstyles}>Please make sure that the website address is spelled correctly.</p>
                        <div style={buttonContainerStyle}>
                          <ScrollToTopLink to="/"><button  style={{ backgroundColor: "darkblue", color: "white" }}>Back to the home page</button></ScrollToTopLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Null;
