const routes = [
  { path: '/', name: 'Home' },
  { path: '/ai', name: 'Beta AI' },
  { path: '/dashboard', name: 'Dashboard' },
  { path: '/welcome', name: 'Welcome' },
  { path: '/needawebsite', name: 'Need a Website' },
  { path: '/about', name: 'About Beta Thread' },
  { path: '/contact', name: 'Contact us' },
  { path: '/services', name: 'Services' },
  { path: '/projects', name: 'Project Starter Kit' },
  { path: '/portfolio_project', name: 'Portfolio Project' },
  { path: '/todolist_project', name: 'Todo List Project' },
  { path: '/calculator_project', name: 'Calculator Project' },
  { path: '/flexbox_project', name: 'Flexbox Project' },
  { path: '/landingpage_project', name: 'Landing Project' },
  { path: '/terms&privacy', name: 'Terms & Privacy' },
  { path: '/svgeditor', name: 'SVG Editor' },
  { path: '/progress-tracker', name: 'Progress Tracker' },
  { path: '/signup', name: 'Signup' },
  { path: '/login', name: 'Login' },
  { path: '/take_quiz', name: 'Coding Quiz' },
  
  
  { path: '/html-progress-tracker', name: 'HTML Progress Tracker' },
  { path: '/css-progress-tracker', name: 'CSS Progress Tracker' },
  { path: '/js-progress-tracker', name: 'JS Progress Tracker' },
  { path: '/php-progress-tracker', name: 'PHP Progress Tracker' },
  { path: '/python-progress-tracker', name: 'Python Progress Tracker' },
  { path: '/ruby-progress-tracker', name: 'Ruby Progress Tracker' },
  { path: '/sql-progress-tracker', name: 'SQL Progress Tracker' },
  { path: '/xml-progress-tracker', name: 'XML Progress Tracker' },
  { path: '/react-progress-tracker', name: 'React Progress Tracker' },
  { path: '/ai-progress-tracker', name: 'AI Progress Tracker' },
  
  
  { path: '/tips', name: 'Tutorial Tips' },
  { path: '/html_tips', name: 'HTML Tips' },
  { path: '/css_tips', name: 'CSS Tips' },
  { path: '/js_tips', name: 'JS Tips' },
  { path: '/php_tips', name: 'PHP Tips' },
  { path: '/python_tips', name: 'Python Tips' },
  { path: '/sql_tips', name: 'SQL Tips' },
  { path: '/ruby_tips', name: 'Ruby Tips' },
  { path: '/xml_tips', name: 'XML Tips' },
  { path: '/react_tips', name: 'React Tips' },
  { path: '/ai_tips', name: 'AI Tips' },
  
  
  { path: '/HTML_Tutorial_intro', name: 'HTML Tutorial' },
  { path: '/HTML_editors', name: 'HTML Editors' },
  { path: '/HTML_comments', name: 'HTML Comments' },
  { path: '/HTML_elements', name: 'HTML Elements' },
  { path: '/HTML_attributes', name: 'HTML Attributes' },
  { path: '/HTML_attributes pt.2', name: 'HTML Attributes pt.2' },
  { path: '/HTML_heading', name: 'HTML Heading' },
  { path: '/HTML_paragraph', name: 'HTML Paragraph' },
  { path: '/HTML_styles', name: 'HTML Styles' },
  { path: '/HTML_colors', name: 'HTML Colors' },
  { path: '/HTML_links', name: 'HTML Links' },
  { path: '/HTML_horizontal_rule', name: 'HTML Horizontal Rule' },
  { path: '/HTML_line_break', name: 'HTML Line Break' },
  { path: '/HTML_text_formatting', name: 'HTML Text Formatting' },
  { path: '/HTML_section', name: 'HTML Section' },
  { path: '/HTML_image', name: 'HTML Image' },
  { path: '/HTML_block_inline', name: 'HTML Block Inline' },
  { path: '/HTML_subscript', name: 'HTML Subscript' },
  { path: '/HTML_superscript', name: 'HTML Superscript' },
  { path: '/HTML_figures', name: 'HTML Figures' },
  { path: '/HTML_nbsp', name: 'HTML Non-Breaking Space' },
  { path: '/HTML_entities', name: 'HTML Character Entities' },
  { path: '/HTML_pre', name: 'HTML Preformatted Text' },
  { path: '/HTML_lists', name: 'HTML Lists' },
  { path: '/HTML_unordered', name: 'HTML Unordered List' },
  { path: '/HTML_ordered', name: 'HTML Ordered List' },
  { path: '/HTML_description', name: 'HTML Description' },
  { path: '/HTML_forms', name: 'HTML Forms' },
  { path: '/HTML_forms_attr', name: 'HTML Forms Attributes' },
  { path: '/HTML_forms_attr2', name: 'HTML Forms Attributes pt.2' },
  { path: '/HTML_js', name: 'HTML JavaScript' },
  { path: '/HTML_input', name: 'HTML Input' },
  { path: '/HTML_input2', name: 'HTML Input pt.2' },
  { path: '/try_html1', name: 'HTML Try It Out' },
  
  { path: '/CSS_Tutorial_intro', name: 'CSS Tutorial' },
  { path: '/CSS_inserting', name: 'CSS Inserting' },
  { path: '/CSS_comments', name: 'CSS Comments' },
  { path: '/CSS_syntax', name: 'CSS Syntax' },
  { path: '/CSS_selectors', name: 'CSS Selectors' },
  { path: '/CSS_combinators', name: 'CSS Combinators' },
  { path: '/CSS_colors', name: 'CSS Colors' },
  { path: '/CSS_text_color', name: 'CSS Text Color' },
  { path: '/CSS_backgrounds', name: 'CSS Backgrounds' },
  { path: '/CSS_background_colors', name: 'CSS Background Colors' },
  { path: '/CSS_background_images', name: 'CSS Background Images' },
  { path: '/CSS_background_attachment', name: 'CSS Background Attachment' },
  { path: '/CSS_width', name: 'CSS Width' },
  { path: '/CSS_height', name: 'CSS Height' },
  { path: '/try_css1', name: 'CSS Try It Out' },
  
  
  { path: '/JavaScript_Tutorial_intro', name: 'JavaScript Tutorial' },
  { path: '/JS_writing_code', name: 'JavaScript Writing Code' },
  { path: '/JS_syntax', name: 'JavaScript Syntax' },
  { path: '/JS_comment', name: 'JavaScript Comments' },
  { path: '/JS_environment', name: 'JavaScript Environment' },
  { path: '/JS_display_output', name: 'JavaScript Display Output' },
  { path: '/JS_method', name: 'JavaScript Methods' },
  { path: '/JS_variables', name: 'JavaScript Variables' },
  { path: '/JS_data_types', name: 'JavaScript Data Types' },
  { path: '/JS_operators', name: 'JavaScript Operators' },
  { path: '/JS_arithmetic_operators', name: 'JavaScript Arithmetic Operators' },
  { path: '/JS_assignment_operators', name: 'JavaScript Assignment Operators' },
  { path: '/JS_logical_conditional_operators', name: 'JavaScript Logical Conditional Operators' },
  { path: '/JS_comparison_operators', name: 'JavaScript Comparison Operators' },
  { path: '/JS_typeof_operators', name: 'JavaScript Typeof Operators' },
  { path: '/try_js1', name: 'JavaScript Try It Out' },
  
  
  { path: '/Ruby_Tutorial_intro', name: 'Ruby Tutorial' },
  { path: '/Ruby_environment', name: 'Ruby Environment' },
  { path: '/Ruby_syntax', name: 'Ruby Syntax' },
  { path: '/Ruby_classes', name: 'Ruby Classes' },
  { path: '/Ruby_comments', name: 'Ruby Comments' },
  
  
  { path: '/React_Tutorial_intro', name: 'React Tutorial' },
  { path: '/React_environment_setup', name: 'React Environment Setup' },
  { path: '/React_create-react-app', name: 'React Create React App' },
  { path: '/React_es6', name: 'React ES6' },
  { path: '/React_es6-modules', name: 'React ES6 Modules' },
  { path: '/React_es6-arrow-functioning', name: 'React ES6 Arrow Functions' },
  { path: '/React_es6-destructuring', name: 'React ES6 Destructuring' },
  { path: '/React_es6-template-literals', name: 'React ES6 Template Literals' },
  { path: '/React_es6-classes', name: 'React ES6 Classes' },
  { path: '/React_es6-spread', name: 'React ES6 Spread' },
  { path: '/React_es6-rest', name: 'React ES6 Rest' },
  { path: '/React_jsx', name: 'React JSX' },
  { path: '/React_components', name: 'React Components' },
  { path: '/React_state', name: 'React State' },
  { path: '/React_props', name: 'React Props' },
  { path: '/React_event', name: 'React Event' },
  { path: '/React_conditional', name: 'React Conditional' },
  { path: '/React_lists', name: 'React Lists' },
  { path: '/React_keys', name: 'React Keys' },
  { path: '/React_forms', name: 'React Forms' },
  { path: '/React_router', name: 'React Router' },
  
  
  { path: '/python-progress-tracker', name: 'Python Progress Tracker' },
  { path: '/Python_Tutorial_intro', name: 'Python Tutorial' },
  { path: '/Python_installing', name: 'Python Installing' },
  { path: '/Python_writing_code', name: 'Python Writing Code' },
  { path: '/Python_display_output', name: 'Python Display Output' },
  { path: '/Python_syntax', name: 'Python Syntax' },
  { path: '/Python_comments', name: 'Python Comments' },
  { path: '/Python_variables', name: 'Python Variables' },
  { path: '/Python_variables_naming', name: 'Python Variables Naming' },
  { path: '/Python_variables_assigning', name: 'Python Variables Assigning' },
  { path: '/Python_variables_types', name: 'Python Variables Types' },
  { path: '/Python_variables_conversion', name: 'Python Variables Conversion' },
  { path: '/Python_data_types', name: 'Python Data Types' },
  { path: '/Python_type_conversion', name: 'Python Type Conversion' },
  { path: '/Python_input', name: 'Python Input' },
  { path: '/Python_operators', name: 'Python Operators' },
  { path: '/Python_conditionals', name: 'Python Conditionals' },
  { path: '/Python_loops', name: 'Python Loops' },
  { path: '/Python_functions', name: 'Python Functions' },
  { path: '/Python_lambda', name: 'Python Lambda' },
  { path: '/Python_arrays', name: 'Python Arrays' },
  { path: '/Python_lists', name: 'Python Lists' },
  { path: '/Python_list_methods', name: 'Python List Methods' },
  { path: '/Python_list_comprehensions', name: 'Python List Comprehensions' },
  { path: '/Python_tuples', name: 'Python Tuples' },
  { path: '/Python_tuple_methods', name: 'Python Tuple Methods' },
  { path: '/Python_tuple_unpacking', name: 'Python Tuple Unpacking' },
  { path: '/Python_tuple_concatenation', name: 'Python Tuple Concatenation' },
  { path: '/Python_sets', name: 'Python Sets' },
  { path: '/Python_set_methods', name: 'Python Set Methods' },
  { path: '/Python_set_comprehensions', name: 'Python Set Comprehensions' },
  { path: '/Python_set_operations', name: 'Python Set Operations' },
  { path: '/Python_dictionaries', name: 'Python Dictionaries' },
  { path: '/Python_dictionary_methods', name: 'Python Dictionary Methods' },
  { path: '/Python_dictionary_comprehensions', name: 'Python Dictionary Comprehensions' },
  { path: '/Python_strings', name: 'Python Strings' },
  { path: '/Python_date-time', name: 'Python Date & Time' },
  
  
  
  { path: '/XML_Tutorial_intro', name: 'XML Tutorial' },
  { path: '/XML_syntax', name: 'XML Syntax' },
  { path: '/XML_elements', name: 'XML Elements' },
  { path: '/XML_attributes', name: 'XML Attributes' },
  
  
  
  { path: '/SQL_Tutorial_intro', name: 'SQL Tutorial' },
  { path: '/SQL_queries', name: 'SQL Queries' },
  { path: '/SQL_comments', name: 'SQL Comments' },
  
  
  
  { path: '/AI_Tutorial_intro', name: 'AI Tutorial' },
  { path: '/AI_history', name: 'AI History' },
  { path: '/AI_types', name: 'AI Types' },
  { path: '/AI_problem_solving', name: 'AI Problem Solving' },
  
  
  
  { path: '/PHP_Tutorial_intro', name: 'PHP Tutorial' },
  { path: '/PHP_environment_setup', name: 'PHP Environment Setup' },
  { path: '/PHP_syntax', name: 'PHP Syntax' },
  { path: '/PHP_comments', name: 'PHP Comments' },
  { path: '/PHP_variables', name: 'PHP Variables' },
  { path: '/PHP_datatypes', name: 'PHP Data Types' },
  { path: '/PHP_echo', name: 'PHP Echo' },
  { path: '/PHP_print', name: 'PHP Print' },
  { path: '/PHP_operators', name: 'PHP Operators' },
  { path: '/PHP_arithmetic', name: 'PHP Arithmetic' },
  { path: '/PHP_assignment', name: 'PHP Assignment' },
  { path: '/PHP_comparison', name: 'PHP Comparison' },
  { path: '/PHP_logical', name: 'PHP Logical' },
  { path: '/PHP_increment', name: 'PHP Increment' },
  { path: '/PHP_decrement', name: 'PHP Decrement' },
  { path: '/PHP_string', name: 'PHP String' },
  { path: '/PHP_array', name: 'PHP Array' },
  { path: '/PHP_conditional', name: 'PHP Conditional' },
  { path: '/PHP_loops', name: 'PHP Loops' },
  { path: '/PHP_switch', name: 'PHP Switch' },
  { path: '/PHP_functions', name: 'PHP Functions' },
  
    ];
    
    export default routes;
    