import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonListMethods() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeAppend = `
# Append an element to a list
fruits = ["apple", "banana", "cherry"]
fruits.append("orange")
print(fruits)  # Output: ["apple", "banana", "cherry", "orange"]
    `;

    const codeExtend = `
# Extend a list with another list
fruits = ["apple", "banana", "cherry"]
more_fruits = ["orange", "mango"]
fruits.extend(more_fruits)
print(fruits)  # Output: ["apple", "banana", "cherry", "orange", "mango"]
    `;

    const codeInsert = `
# Insert an element at a specific position
fruits = ["apple", "banana", "cherry"]
fruits.insert(1, "orange")
print(fruits)  # Output: ["apple", "orange", "banana", "cherry"]
    `;

    const codeRemove = `
# Remove a specific element from a list
fruits = ["apple", "banana", "cherry"]
fruits.remove("banana")
print(fruits)  # Output: ["apple", "cherry"]
    `;

    const codePop = `
# Pop an element from a list
fruits = ["apple", "banana", "cherry"]
fruits.pop(1)
print(fruits)  # Output: ["apple", "cherry"]
    `;

    const codeIndex = `
# Get the index of an element
fruits = ["apple", "banana", "cherry"]
index = fruits.index("banana")
print(index)  # Output: 1
    `;

    const codeCount = `
# Count occurrences of an element
fruits = ["apple", "banana", "cherry", "banana"]
count = fruits.count("banana")
print(count)  # Output: 2
    `;

    const codeSort = `
# Sort a list
fruits = ["banana", "apple", "cherry"]
fruits.sort()
print(fruits)  # Output: ["apple", "banana", "cherry"]
    `;

    const codeReverse = `
# Reverse a list
fruits = ["banana", "apple", "cherry"]
fruits.reverse()
print(fruits)  # Output: ["cherry", "apple", "banana"]
    `;

    const codeClear = `
# Clear all elements from a list
fruits = ["banana", "apple", "cherry"]
fruits.clear()
print(fruits)  # Output: []
    `;

    return (
        <body>
            <Helmet>
                <title>Python List Methods</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python List Methods, Append, Extend, Insert, Remove, Pop, Index, Count, Sort, Reverse, Clear" />
                <meta name="description" content="Learn about various list methods in Python, including append, extend, insert, remove, pop, index, count, sort, reverse, and clear." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_lists"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_list_comprehensions"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python List Methods</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Lists in Python come with several built-in methods to facilitate operations like adding, removing, and modifying elements. Here, we cover some of the most commonly used list methods.</p>
                        
                        <h2>Append</h2>
                        <p>The <code>append()</code> method adds an element to the end of the list.</p>
                        <SyntaxHighlighterComponent code={codeAppend} language="python" />
                        
                        <h2>Extend</h2>
                        <p>The <code>extend()</code> method adds elements from another list to the end of the current list.</p>
                        <SyntaxHighlighterComponent code={codeExtend} language="python" />
                        
                        <h2>Insert</h2>
                        <p>The <code>insert()</code> method inserts an element at a specified position.</p>
                        <SyntaxHighlighterComponent code={codeInsert} language="python" />
                        
                        <h2>Remove</h2>
                        <p>The <code>remove()</code> method removes the first occurrence of a specified element.</p>
                        <SyntaxHighlighterComponent code={codeRemove} language="python" />
                        
                        <h2>Pop</h2>
                        <p>The <code>pop()</code> method removes the element at a specified position and returns it.</p>
                        <SyntaxHighlighterComponent code={codePop} language="python" />
                        
                        <h2>Index</h2>
                        <p>The <code>index()</code> method returns the index of the first occurrence of a specified element.</p>
                        <SyntaxHighlighterComponent code={codeIndex} language="python" />
                        
                        <h2>Count</h2>
                        <p>The <code>count()</code> method returns the number of occurrences of a specified element in the list.</p>
                        <SyntaxHighlighterComponent code={codeCount} language="python" />
                        
                        <h2>Sort</h2>
                        <p>The <code>sort()</code> method sorts the list in ascending order by default.</p>
                        <SyntaxHighlighterComponent code={codeSort} language="python" />
                        
                        <h2>Reverse</h2>
                        <p>The <code>reverse()</code> method reverses the order of elements in the list.</p>
                        <SyntaxHighlighterComponent code={codeReverse} language="python" />
                        
                        <h2>Clear</h2>
                        <p>The <code>clear()</code> method removes all elements from the list.</p>
                        <SyntaxHighlighterComponent code={codeClear} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Python provides a variety of list methods to handle common operations efficiently. This tutorial covered append, extend, insert, remove, pop, index, count, sort, reverse, and clear methods.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_lists"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_list_comprehensions"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
