import React, { useState } from "react";
import "../css/my style.css";
import "../css/my style2.css";


export default function Forumbuttonlight() {

    return(
        <button className="nav-button">Forum</button>
    )
}