import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

const XMLEncoding = () => {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<?xml version="1.0" encoding="UTF-8"?>
<note>
  <to>User</to>
  <from>Admin</from>
  <heading>Reminder</heading>
  <body>Don't forget to check your email!</body>
</note>
`;

  const example2 = `
<?xml version="1.0" encoding="ISO-8859-1"?>
<book>
  <title>Learning XML</title>
  <author>John Doe</author>
  <year>2024</year>
  <publisher>Example Publisher</publisher>
</book>
`;

  return (
    <body>
      <Helmet>
        <title>XML Encoding</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML Encoding, XML Tutorial, XML Encoding Syntax" />
        <meta name="description" content="Learn how to specify XML encoding and understand its importance in XML documents." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_versioning"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_data_manipulation"><ButtonNext /></ScrollToTopLink>
          <h1>XML Encoding</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XML encoding specifies the character encoding scheme used in an XML document. It ensures that special characters are interpreted correctly by XML parsers.</p>

            <h2>Syntax</h2>
            <p>The XML encoding declaration is part of the XML version declaration:</p>
            <SyntaxHighlighterComponent code={example1} language="xml" />

            <h2>Examples</h2>

            <h3>UTF-8 Encoding</h3>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This example specifies UTF-8 encoding, which supports a wide range of characters.</p>

            <h3>ISO-8859-1 Encoding</h3>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>This example specifies ISO-8859-1 encoding, suitable for Western European languages.</p>

            <h2>Usage</h2>
            <p>Specify the XML encoding declaration according to the character set used in your XML document to ensure proper interpretation of characters.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_versioning"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_data_manipulation"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}

export default XMLEncoding;
