import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactES6classes() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    import React, { Component } from 'react';

    class MyComponent extends Component {
      render() {
        return <div>Hello, World!</div>;
      }
    }
    
    export default MyComponent;
          `

    const code2=`
    import React, { Component } from 'react';

    class Counter extends Component {
      constructor(props) {
        super(props);
        this.state = {
          count: 0
        };
        // Binding this to handleIncrement method
        this.handleIncrement = this.handleIncrement.bind(this);
      }
    
      handleIncrement() {
        this.setState({ count: this.state.count + 1 });
      }
    
      render() {
        return (
          <div>
            <p>Count: {this.state.count}</p>
            <button onClick={this.handleIncrement}>Increment</button>
          </div>
        );
      }
    }
    
    export default Counter;
          `
  
     const code3=`
          import React, { Component } from 'react';

          class ButtonComponent extends Component {
            handleClick() {
              alert('Button clicked!');
            }
          
            render() {
              return (
                <button onClick={this.handleClick}>Click me</button>
              );
            }
          }
          
          export default ButtonComponent;
         `

         const code4=`
         import React, { Component } from 'react';

         class LifecycleComponent extends Component {
           constructor(props) {
             super(props);
             this.state = {
               message: ''
             };
           }
         
           componentDidMount() {
             // Fetch some data when component is mounted
             fetch('https://api.example.com/data')
               .then(response => response.json())
               .then(data => this.setState({ message: data.message }));
           }
         
           render() {
             return <div>{this.state.message}</div>;
           }
         }
         
         export default LifecycleComponent;
           `

           const code5=`
           import React, { Component } from 'react';

           class Greeting extends Component {
             render() {
               return <h1>Hello, {this.props.name}!</h1>;
             }
           }
           
           export default Greeting;
              `     

    return (
   <body>
     <Helmet>
        
        <title>React ES6 Classes</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React ES6 Classes, Constructor and State Initialization, Event Handling, Lifecycle Methods, Using Props,
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="In ES6, classes are created using the 'class' keyword. React components are JavaScript classes that extend from 'React.Component' class." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_es6-template-literals"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-spread"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React ES6 Classes</h1>
</header>

<Navbar />

<main>
<section>
<p>In ES6, classes are created using the 'class' keyword.</p>
  <p>React components are JavaScript classes that extend from 'React.Component' class.</p>
  <p>This provides them with functionality for managing component state, lifecycle methods, and more.</p>
  <h2>Example</h2>
 <SyntaxHighlighterComponent code={code} language="js" />
 <p>In this example:</p>
 <ul>
    <li>We import 'React' and 'Component' from the 'react' library.</li>
    <li>We define a class named 'MyComponent' that extends 'Component'.</li>
    <li>The 'render()' method returns the JSX to be rendered.</li>
 </ul>

 <h2>Constructor and State Initialization</h2>
 <p>You can initialize state and bind methods in the constructor of the class.</p>
 <SyntaxHighlighterComponent code={code2} language="js" />

 <h2>Event Handling</h2>
 <p>You can define event handlers within the class and refer to them directly in JSX.</p>
 <SyntaxHighlighterComponent code={code3} language="js" />

 <h2>Lifecycle Methods</h2>
 <p>React provides several lifecycle methods that you can override to perform actions at different stages of a component's lifecycle, such as mounting, updating, and unmounting.</p>
 <SyntaxHighlighterComponent code={code4} language="js" />

 <h2>Using Props</h2>
 <p>Props are passed to the component as parameters to the constructor and can be accessed using 'this.props'.</p>
 <SyntaxHighlighterComponent code={code5} language="js" />
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_es6-template-literals"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-spread"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}