import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLhorizontalrule() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <p>I am an HTML paragraph.</p>
      <hr />
      <p>I am an HTML paragraph.</p>
  `;
  
     const code2 = `
        <p>I am an HTML paragraph.</p>
        <!--styling using color-->
        <hr color="red" />
        <!--styling using width-->
        <hr width="70%" />
        <!--styling using height-->
        <hr height="70px" />
        <p>I am an HTML paragraph.</p>
  `;
    
    return (
   <body>
     <Helmet>    
        <title>HTML Horizontal Rule</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Horizontal Rule, What is an HTML Horizontal Rule, What is an Horizontal Rule, Styling the <hr> Element, Styling thr Horizontal Rule,
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
       <meta name="description" content="In HTML we can create a horizontal rule or horizontal line below or above an HTML element.
       The <hr /> element is used to create horizontal rules.The <hr /> is an empty element." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_links"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_line_break"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Horizontal Rule</h1>
</header>

<Navbar />

<main>

<section>
<p>In HTML we can create a horizontal rule or horizontal line below or above an HTML element.</p>
<p>The &lt;hr /&gt; element is used to create horizontal rules.The &lt;hr /&gt; is an empty element.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html33"><Tryitout /></ScrollToTopLink>

<h2>Styling the &lt;hr&gt; Element</h2>
<p>The &lt;hr&gt; element can be styled in different ways.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html34"><Tryitout /></ScrollToTopLink>


</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_links"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_line_break"><ButtonNext /></ScrollToTopLink>
</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}