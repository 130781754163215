import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIRNNs() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const rnnExampleCode = `
import tensorflow as tf
from tensorflow.keras import layers, models

model = models.Sequential()
model.add(layers.Embedding(input_dim=10000, output_dim=64))
model.add(layers.SimpleRNN(128))
model.add(layers.Dense(10, activation='softmax'))

model.compile(optimizer='adam', loss='sparse_categorical_crossentropy', metrics=['accuracy'])

# Display the model's architecture
model.summary()
  `;

  const rnnTrainingCode = `
# Assume X_train, y_train, X_test, and y_test are already defined and preprocessed
history = model.fit(X_train, y_train, epochs=10, validation_data=(X_test, y_test))

# Evaluate the model
test_loss, test_acc = model.evaluate(X_test, y_test)
print(f'Test accuracy: {test_acc}')
  `;

  return (
    <body>
      <Helmet>
        <title>AI Recurrent Neural Networks</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="RNNs, AI Recurrent Neural Networks, Deep Learning, AI Tutorial" />
        <meta name="description" content="Learn about Recurrent Neural Networks (RNNs), their architecture, and how to implement them using TensorFlow and Keras with practical examples." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/AI_cnns"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/AI_lstmns"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>AI Recurrent Neural Networks</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Recurrent Neural Networks (RNNs) are a class of neural networks that are powerful for modeling sequence data such as time series or natural language. They use their internal state (memory) to process sequences of inputs.</p>

            <h2>Architecture of RNNs</h2>
            <p>RNNs have loops that allow information to be carried across nodes in the network. This makes them suitable for tasks where context is important, such as language modeling or time series prediction.</p>

            <h2>Example: Creating an RNN with TensorFlow and Keras</h2>
            <SyntaxHighlighterComponent code={rnnExampleCode} language="python" />
            <p>This code defines a simple RNN using TensorFlow and Keras. The model includes an embedding layer, an RNN layer, and a dense output layer.</p>

            <h2>Training the RNN</h2>
            <SyntaxHighlighterComponent code={rnnTrainingCode} language="python" />
            <p>This code demonstrates how to train the RNN model on a dataset. It assumes that <code>X_train</code>, <code>y_train</code>, <code>X_test</code>, and <code>y_test</code> are already defined and preprocessed.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_cnns"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_lstmns"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
