import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPArithmetic() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    $num1 = 5;
    $num2 = 3;
    $result = $num1 + $num2;
    echo $result; // Outputs: 8
       `;
    const code2 = `
    $num1 = 10;
    $num2 = 4;
    $result = $num1 - $num2;
    echo $result; // Outputs: 6
       `;
    const code3 = `
    $num1 = 6;
    $num2 = 3;
    $result = $num1 * $num2;
    print $result; // Outputs: 18
       `;

    const code4 = `
    $num1 = 20;
    $num2 = 5;
    $result = $num1 / $num2;
    print $result; // Outputs: 4
       `;

    const code5 = `
    $num1 = 10;
    $num2 = 3;
    $result = $num1 % $num2;
    echo $result; // Outputs: 1 (because 10 ÷ 3 = 3 with a remainder of 1)
           `;

    const code6 = `
    $string1 = "Hello";
    $string2 = " World!";
    
    // Concatenation
    $resultString = $string1 . $string2; // $resultString will be "Hello World!"
       `;

  
    return (
   <body>
      <Helmet>
        <title>PHP Arithmetic Operators</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Operators, PHP Arithmetic Operator, Addition Operator, Subtraction Operator, Multiplication Operator, Division Operator, Modulus Operator, Exponentiation Operator,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="PHP provides a set of arithmetic operators that allow you to perform basic mathematical operations on numeric values." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_assignment"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Arithmetic Operators</h1>
</header>

<Navbar />

<main>
<section>
<p>PHP provides a set of arithmetic operators that allow you to perform basic mathematical operations on numeric values.</p>
<p>These operators are essential for performing calculations within your PHP code. Let's dive into each one:</p>

<h2>Addition (+)</h2>
<p>The addition operator is represented by the plus sign (+) and is used to add two numeric values together.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="php" />

<h2>Subtraction (-)</h2>
<p>The subtraction operator is represented by the minus sign (-) and is used to subtract one numeric value from another.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="php" />


<h2>Multiplication (*)</h2>
<p>The multiplication operator is represented by the asterisk (*) and is used to multiply two numeric values.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code3} language="php" />


<h2>Division (/)</h2>
<p>The division operator is represented by the forward slash (/) and is used to divide one numeric value by another.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code4} language="php" />


<h2>Modulus (%)</h2>
<p>The modulus operator is represented by the percent sign (%) and returns the remainder of dividing the first operand by the second.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code5} language="php" />


<h2>Exponentiation () (PHP 5.6 and later)</h2>
<p>The exponentiation operator is represented by two asterisks () and raises the first operand to the power of the second operand.</p>
<p>The exponentiation operator is only available for PHP 5.6 and later.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code6} language="php" />

<p>These arithmetic operators can be used in combination with variables, constants, or literal values to perform various calculations within your PHP scripts. </p>

<p>They are fundamental for any kind of numerical processing or mathematical operations you may need to perform in your applications.</p>
</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_assignment"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}