import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLDataTypes() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const numericDataTypesCode = `
-- Numeric Data Types
CREATE TABLE Students (
  StudentID INT,
  Name VARCHAR(50),
  Age INT,
  GPA DECIMAL(3, 2)
);
  `;

  const characterDataTypesCode = `
-- Character Data Types
CREATE TABLE Employees (
  EmployeeID INT,
  FirstName VARCHAR(50),
  LastName VARCHAR(50),
  Email VARCHAR(100)
);
  `;

  const dateAndTimeDataTypesCode = `
-- Date and Time Data Types
CREATE TABLE Orders (
  OrderID INT,
  OrderDate DATE,
  ShipDate DATE,
  DeliveryTime TIME
);
  `;

  const booleanDataTypeCode = `
-- Boolean Data Type
CREATE TABLE Users (
  UserID INT,
  UserName VARCHAR(50),
  IsActive BIT
);
  `;

  return (
    <body>
      <Helmet>
        <title>SQL Data Types</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL Data Types, Numeric, Character, Date and Time, Boolean, SQL Tutorial" />
        <meta name="description" content="Learn about SQL data types, including numeric, character, date and time, and boolean data types, with examples and usage in SQL CREATE TABLE statements." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/SQL_delete"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/SQL_aggregate_functions"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL Data Types</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>SQL data types specify the type of data that a column can hold in a database table. Each column in a database table is required to have a data type, which defines the kind of data it can store.</p>

            <h2>Numeric Data Types</h2>
            <p>Numeric data types are used to store numeric values.</p>
            <SyntaxHighlighterComponent code={numericDataTypesCode} language="sql" />

            <h2>Character Data Types</h2>
            <p>Character data types are used to store strings of text.</p>
            <SyntaxHighlighterComponent code={characterDataTypesCode} language="sql" />

            <h2>Date and Time Data Types</h2>
            <p>Date and time data types are used to store date and time values.</p>
            <SyntaxHighlighterComponent code={dateAndTimeDataTypesCode} language="sql" />

            <h2>Boolean Data Type</h2>
            <p>The boolean data type is used to store true/false or 1/0 values.</p>
            <SyntaxHighlighterComponent code={booleanDataTypeCode} language="sql" />

            <h2>Example Tables</h2>
            <p>Here are examples of SQL CREATE TABLE statements using different data types:</p>

            <table className="sql-table">
              <thead>
                <tr>
                  <th>Table</th>
                  <th>Column</th>
                  <th>Data Type</th>
                  <th>Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Students</td>
                  <td>StudentID</td>
                  <td>INT</td>
                  <td>1, 2, 3, ...</td>
                </tr>
                <tr>
                  <td>Students</td>
                  <td>Name</td>
                  <td>VARCHAR(50)</td>
                  <td>'John Doe', 'Alice Smith', ...</td>
                </tr>
                <tr>
                  <td>Students</td>
                  <td>Age</td>
                  <td>INT</td>
                  <td>18, 21, 25, ...</td>
                </tr>
                <tr>
                  <td>Students</td>
                  <td>GPA</td>
                  <td>DECIMAL(3, 2)</td>
                  <td>3.50, 2.75, ...</td>
                </tr>
                <tr>
                  <td>Employees</td>
                  <td>EmployeeID</td>
                  <td>INT</td>
                  <td>101, 102, 103, ...</td>
                </tr>
                <tr>
                  <td>Employees</td>
                  <td>FirstName</td>
                  <td>VARCHAR(50)</td>
                  <td>'Michael', 'Sarah', ...</td>
                </tr>
                <tr>
                  <td>Employees</td>
                  <td>LastName</td>
                  <td>VARCHAR(50)</td>
                  <td>'Johnson', 'Brown', ...</td>
                </tr>
                <tr>
                  <td>Employees</td>
                  <td>Email</td>
                  <td>VARCHAR(100)</td>
                  <td>'michael@example.com', 'sarah@example.com', ...</td>
                </tr>
                <tr>
                  <td>Orders</td>
                  <td>OrderID</td>
                  <td>INT</td>
                  <td>1001, 1002, 1003, ...</td>
                </tr>
                <tr>
                  <td>Orders</td>
                  <td>OrderDate</td>
                  <td>DATE</td>
                  <td>'2023-06-15', '2023-06-16', ...</td>
                </tr>
                <tr>
                  <td>Orders</td>
                  <td>ShipDate</td>
                  <td>DATE</td>
                  <td>'2023-06-18', '2023-06-19', ...</td>
                </tr>
                <tr>
                  <td>Orders</td>
                  <td>DeliveryTime</td>
                  <td>TIME</td>
                  <td>'10:00:00', '14:30:00', ...</td>
                </tr>
                <tr>
                  <td>Users</td>
                  <td>UserID</td>
                  <td>INT</td>
                  <td>201, 202, 203, ...</td>
                </tr>
                <tr>
                  <td>Users</td>
                  <td>UserName</td>
                  <td>VARCHAR(50)</td>
                  <td>'user1', 'user2', ...</td>
                </tr>
                <tr>
                  <td>Users</td>
                  <td>IsActive</td>
                  <td>BIT</td>
                  <td>1, 0</td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_delete"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_aggregate_functions"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
