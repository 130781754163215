import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLSerialization() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
import java.io.StringWriter;
import javax.xml.bind.JAXBContext;
import javax.xml.bind.JAXBException;
import javax.xml.bind.Marshaller;

public class SerializeExample {
    public static void main(String[] args) throws JAXBException {
        Book book = new Book("Learning XML", "John Doe");
        JAXBContext context = JAXBContext.newInstance(Book.class);
        Marshaller marshaller = context.createMarshaller();
        marshaller.setProperty(Marshaller.JAXB_FORMATTED_OUTPUT, Boolean.TRUE);

        StringWriter sw = new StringWriter();
        marshaller.marshal(book, sw);
        String xmlString = sw.toString();
        System.out.println(xmlString);
    }
}
`;

  const example2 = `
import java.beans.XMLEncoder;
import java.io.ByteArrayOutputStream;

public class SerializePerson {
    public static void main(String[] args) {
        Person person = new Person("Jane Doe", 30);
        ByteArrayOutputStream baos = new ByteArrayOutputStream();
        XMLEncoder encoder = new XMLEncoder(baos);
        encoder.writeObject(person);
        encoder.close();
        String xml = new String(baos.toByteArray());
        System.out.println(xml);
    }
}
`;

  const example3 = `
import com.fasterxml.jackson.dataformat.xml.XmlMapper;

public class SerializeEmployee {
    public static void main(String[] args) throws Exception {
        Employee employee = new Employee(12345, "Sales");
        XmlMapper xmlMapper = new XmlMapper();
        String xmlString = xmlMapper.writeValueAsString(employee);
        System.out.println(xmlString);
    }
}
`;

  return (
    <body>
      <Helmet>
        <title>XML Serialization</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML Serialization, XML Tutorial, Java XML Serialization, XML Examples" />
        <meta name="description" content="Learn about XML Serialization with examples and explanations of how to serialize objects to XML." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_data_binding"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_deserialization"><ButtonNext /></ScrollToTopLink>
          <h1>XML Serialization</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XML Serialization is the process of converting an object's state to XML format so that it can be stored, transmitted, and reconstructed later. This technique is widely used in data exchange between different systems, configuration management, and more.</p>

            <h2>Introduction to XML Serialization</h2>
            <p>Serialization allows objects to be converted into a format that can be easily transported and stored. XML is a popular format for serialization due to its readability and interoperability.</p>

            <h2>Example 1: JAXB Serialization in Java</h2>
            <SyntaxHighlighterComponent code={example1} language="java" />
            <p>In this example, we use JAXB (Java Architecture for XML Binding) to serialize a <code>Book</code> object to XML format. JAXB provides a convenient way to bind Java objects to XML data.</p>

            <h2>Example 2: XMLEncoder Serialization in Java</h2>
            <SyntaxHighlighterComponent code={example2} language="java" />
            <p>This example demonstrates how to serialize a <code>Person</code> object using Java's <code>XMLEncoder</code>. This method is simple and built into the Java standard library.</p>

            <h2>Example 3: Jackson XML Serialization in Java</h2>
            <SyntaxHighlighterComponent code={example3} language="java" />
            <p>In this example, we use the Jackson library's <code>XmlMapper</code> to serialize an <code>Employee</code> object to XML. Jackson is a versatile library that supports both JSON and XML serialization.</p>

            <h2>Benefits of XML Serialization</h2>
            <ul>
              <li>Enables easy data storage and transmission.</li>
              <li>Facilitates data exchange between different systems and platforms.</li>
              <li>Ensures data is in a readable and structured format.</li>
            </ul>

            <h2>Common Use Cases</h2>
            <ul>
              <li>Data exchange in web services.</li>
              <li>Configuration management for applications.</li>
              <li>Persistence of object states for later retrieval.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_data_binding"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_deserialization"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
