import React from "react";
import "../../../css/my style.css";
import "../../../css/my style3.css";
import "../../../components/my script";
import Sidenav from "../../../components/Sidenav/PythonSidenav";
import Navbar from "../../../components/navbar";
import Footertutorials from "../../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../../components/Buttonnext";
import Tryitout from "../../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../../components/Buttonprevious";
import ScrollToTopLink from "../../../components/ScrollToTop";

export default function PythonVariablesTypes() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const code = `
x = 10
y = "Hello"
z = 3.14
    `;

    const code2 = `
a, b, c = 1, 2, "Three"
    `;

    const code3 = `
is_student = True
points = None
    `;

    const code4 = `
print(type(10))          # <class 'int'>
print(type("Hello"))     # <class 'str'>
print(type(3.14))        # <class 'float'>
print(type(True))        # <class 'bool'>
print(type(None))        # <class 'NoneType'>
    `;

    return (
        <body>
            <Helmet>
                <title>Python Variable Types</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Variable Types, Multiple Assignment, Boolean and None Types, Determining Variable Types" />
                <meta name="description" content="Learn about different variable types in Python, including numbers, strings, and more." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_variables_assigning"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_variables_conversion"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Variable Types</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Python variables can store data of different types, and different types can do different things. This section will cover the most commonly used variable types in Python.</p>
                        
                        <h2>Basic Variable Types</h2>
                        <p>Here are some basic variable types you will use frequently:</p>
                        <SyntaxHighlighterComponent code={code} language="python" />
                        <ul>
                            <li><code>x</code> is an integer.</li>
                            <li><code>y</code> is a string.</li>
                            <li><code>z</code> is a float.</li>
                        </ul>

                        <h2>Multiple Assignment</h2>
                        <p>You can assign multiple variables at once, which can be useful for initializing several variables simultaneously.</p>
                        <SyntaxHighlighterComponent code={code2} language="python" />
                        <p>In the example above, <code>a</code>, <code>b</code>, and <code>c</code> are assigned the values <code>1</code>, <code>2</code>, and <code>"Three"</code> respectively in a single line.</p>

                        <h2>Boolean and None Types</h2>
                        <p>Python also supports boolean and None types:</p>
                        <SyntaxHighlighterComponent code={code3} language="python" />
                        <ul>
                            <li><code>is_student</code> is a boolean variable.</li>
                            <li><code>points</code> is set to <code>None</code>, representing the absence of a value.</li>
                        </ul>

                        <h2>Determining Variable Types</h2>
                        <p>To find out the type of a variable or value, use the built-in <code>type()</code> function:</p>
                        <SyntaxHighlighterComponent code={code4} language="python" />
                        <p>This will output the type of each variable or value, helping you understand what kind of data you are working with.</p>

                        <h2>Conclusion</h2>
                        <p>Understanding different variable types in Python is crucial for effective programming. This knowledge allows you to choose the right type for your data and use Python's features to their full potential.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_variables_assigning"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_variables_conversion"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
