import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "../src/css/my style.css";
import "../src/css/my style3.css";
import "../src/css/special styles.css";
import {Navigate, useLocation} from 'react-router-dom';
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProgressTracker from './components/Tutorial Progress/ProgressTracker';
import TrackProgress from './components/Tutorial Progress/TrackProgress';
import { ProgressProvider, ProgressContext } from './components/Tutorial Progress/ProgressContext';
import CertificatePage from './Payment/CertificatePage';
import ProtectedRoute from './Protected/ProtectedRoute';
import Formhandler from './components/TestForm/formhandler';
import Home from './pages/Home';
import About from './pages/About';  
import Contact from './pages/Contact';
import Services from './pages/Services';
import TermsPrivacy from './pages/TermsPrivacy';
import ProjectStarterKit from './pages/Projects';
import BetaAI from './pages/AI';
import Signup from './pages/signup';
import Login from './pages/login';
import ForgotPassword from './pages/XXRESET/ForgotPassword';
import ResetPassword from './pages/XXRESET/ResetPassword';
import EmailSent from './pages/XXRESET/EmailSent';
import ComingSoon from './components/coming_soon';
import ComingSoonTips from './components/coming_soontips';
import File from './pages/HTML Tutorial/HTML Try it out/fileinthesamefolder';
import CodingQuiz from './components/CodingQuiz';
import HTMLintro from './pages/HTML Tutorial/HTML_introduction';
import HTMLeditors from './pages/HTML Tutorial/HTML_editors';
import HTMLcomments from './pages/HTML Tutorial/HTML_comments';
import HTMLelements from './pages/HTML Tutorial/HTML_elements';
import HTMLattributes from './pages/HTML Tutorial/HTML_attributes';
import HTMLattributespt2 from './pages/HTML Tutorial/HTML_attributes pt.2';
import HTMLheading from './pages/HTML Tutorial/HTML_heading';
import HTMLparagraph from './pages/HTML Tutorial/HTML_paragraph';
import HTMLstyles from './pages/HTML Tutorial/HTML_styles';
import HTMLcolors from './pages/HTML Tutorial/HTML_colors';
import HTMLlinks from './pages/HTML Tutorial/HTML_link';
import HTMLhorizontalrule from './pages/HTML Tutorial/HTML_horizontal rule';
import HTMLlinebreak from './pages/HTML Tutorial/HTML_line break';
import HTMLtextformatting from './pages/HTML Tutorial/HTML_text formatting';
import HTMLsection from './pages/HTML Tutorial/HTML_section';
import HTMLimage from './pages/HTML Tutorial/HTML_image';
import HTMLblock_inline from './pages/HTML Tutorial/HTML_block and inline';
import HTMLsubscript from './pages/HTML Tutorial/HTML_subscript';
import HTMLsuperscript from './pages/HTML Tutorial/HTML_superscript';
import HTMLfigures from './pages/HTML Tutorial/HTML_figures';
import HTMLnbsp from './pages/HTML Tutorial/HTML_nbsp';
import HTMLcharacter_entities from './pages/HTML Tutorial/HTML_entities';
import HTMLpre from './pages/HTML Tutorial/HTML_pre';
import HTMLlists from './pages/HTML Tutorial/HTML_lists';
import HTMLunorderedlist from './pages/HTML Tutorial/HTML_unordered';
import HTMLordered from './pages/HTML Tutorial/HTML_ordered';
import HTMLdescription from './pages/HTML Tutorial/HTML_description list';
import HTMLforms from './pages/HTML Tutorial/HTML_forms';
import HTMLformselementattr from './pages/HTML Tutorial/HTML_form element attributes';
import HTMLformselementattr2 from './pages/HTML Tutorial/HTML_forms element attibutes 2';
import HTMLjs from './pages/HTML Tutorial/HTML_javascript';
import HTMLinput from './pages/HTML Tutorial/HTML_input types';
import HTMLinput2 from './pages/HTML Tutorial/HTML_input types pt.2';




import HTMLTry01 from './pages/HTML Tutorial/HTML Try it out/try0.1';
import HTMLTry02 from './pages/HTML Tutorial/HTML Try it out/try0.2';
import HTMLTry1 from './pages/HTML Tutorial/HTML Try it out/try1';
import HTMLTry2 from './pages/HTML Tutorial/HTML Try it out/try2';
import HTMLTry3 from './pages/HTML Tutorial/HTML Try it out/try3';
import HTMLTry4 from './pages/HTML Tutorial/HTML Try it out/try4';
import HTMLTry5 from './pages/HTML Tutorial/HTML Try it out/try5';
import HTMLTry6 from './pages/HTML Tutorial/HTML Try it out/try6';
import HTMLTry7 from './pages/HTML Tutorial/HTML Try it out/try7';
import HTMLTry8 from './pages/HTML Tutorial/HTML Try it out/try8';
import HTMLTry9 from './pages/HTML Tutorial/HTML Try it out/try9';
import HTMLTry10 from './pages/HTML Tutorial/HTML Try it out/try10';
import HTMLTry11 from './pages/HTML Tutorial/HTML Try it out/try11';
import HTMLTry12 from './pages/HTML Tutorial/HTML Try it out/try12';
import HTMLTry13 from './pages/HTML Tutorial/HTML Try it out/try13';
import HTMLTry14 from './pages/HTML Tutorial/HTML Try it out/try14';
import HTMLTry15 from './pages/HTML Tutorial/HTML Try it out/try15';
import HTMLTry16 from './pages/HTML Tutorial/HTML Try it out/try16';
import HTMLTry17 from './pages/HTML Tutorial/HTML Try it out/try17';
import HTMLTry18 from './pages/HTML Tutorial/HTML Try it out/try18';
import HTMLTry19 from './pages/HTML Tutorial/HTML Try it out/try19';
import HTMLTry20 from './pages/HTML Tutorial/HTML Try it out/try20';
import HTMLTry21 from './pages/HTML Tutorial/HTML Try it out/try21';
import HTMLTry22 from './pages/HTML Tutorial/HTML Try it out/try22';
import HTMLTry23 from './pages/HTML Tutorial/HTML Try it out/try23';
import HTMLTry24 from './pages/HTML Tutorial/HTML Try it out/try24';
import HTMLTry25 from './pages/HTML Tutorial/HTML Try it out/try25';
import HTMLTry26 from './pages/HTML Tutorial/HTML Try it out/try26';
import HTMLTry27 from './pages/HTML Tutorial/HTML Try it out/try27';
import HTMLTry28 from './pages/HTML Tutorial/HTML Try it out/try28';
import HTMLTry29 from './pages/HTML Tutorial/HTML Try it out/try29';
import HTMLTry30 from './pages/HTML Tutorial/HTML Try it out/try30';
import HTMLTry31 from './pages/HTML Tutorial/HTML Try it out/try31';
import HTMLTry32 from './pages/HTML Tutorial/HTML Try it out/try32';
import HTMLTry33 from './pages/HTML Tutorial/HTML Try it out/try33';
import HTMLTry34 from './pages/HTML Tutorial/HTML Try it out/try34';
import HTMLTry35 from './pages/HTML Tutorial/HTML Try it out/try35';
import HTMLTry36 from './pages/HTML Tutorial/HTML Try it out/try36';
import HTMLTry37 from './pages/HTML Tutorial/HTML Try it out/try37';
import HTMLTry38 from './pages/HTML Tutorial/HTML Try it out/try38';
import HTMLTry39 from './pages/HTML Tutorial/HTML Try it out/try39';
import HTMLTry40 from './pages/HTML Tutorial/HTML Try it out/try40';
import HTMLTry41 from './pages/HTML Tutorial/HTML Try it out/try41';
import HTMLTry42 from './pages/HTML Tutorial/HTML Try it out/try42';
import HTMLTry43 from './pages/HTML Tutorial/HTML Try it out/try43';
import HTMLTry44 from './pages/HTML Tutorial/HTML Try it out/try44';
import HTMLTry45 from './pages/HTML Tutorial/HTML Try it out/try45';
import HTMLTry46 from './pages/HTML Tutorial/HTML Try it out/try46';
import HTMLTry47 from './pages/HTML Tutorial/HTML Try it out/try47';
import HTMLTry48 from './pages/HTML Tutorial/HTML Try it out/try48';
import HTMLTry49 from './pages/HTML Tutorial/HTML Try it out/try49';
import HTMLTry50 from './pages/HTML Tutorial/HTML Try it out/try50';
import HTMLTry51 from './pages/HTML Tutorial/HTML Try it out/try51';
import HTMLTry52 from './pages/HTML Tutorial/HTML Try it out/try52';
import HTMLTry53 from './pages/HTML Tutorial/HTML Try it out/try53';
import HTMLTry54 from './pages/HTML Tutorial/HTML Try it out/try54';
import HTMLTry55 from './pages/HTML Tutorial/HTML Try it out/try55';
import HTMLTry56 from './pages/HTML Tutorial/HTML Try it out/try56';
import HTMLTry57 from './pages/HTML Tutorial/HTML Try it out/try57';
import HTMLTry58 from './pages/HTML Tutorial/HTML Try it out/try58';
import HTMLTry59 from './pages/HTML Tutorial/HTML Try it out/try59';
import HTMLTry60 from './pages/HTML Tutorial/HTML Try it out/try60';
import HTMLTry61 from './pages/HTML Tutorial/HTML Try it out/try61';
import HTMLTry62 from './pages/HTML Tutorial/HTML Try it out/try62';
import HTMLTry63 from './pages/HTML Tutorial/HTML Try it out/try63';
import HTMLTry64 from './pages/HTML Tutorial/HTML Try it out/try64';
import HTMLTry65 from './pages/HTML Tutorial/HTML Try it out/try65';
import HTMLTry66 from './pages/HTML Tutorial/HTML Try it out/try66';
import HTMLTry67 from './pages/HTML Tutorial/HTML Try it out/try67';
import HTMLTry68 from './pages/HTML Tutorial/HTML Try it out/try68';
import HTMLTry69 from './pages/HTML Tutorial/HTML Try it out/try69';
import HTMLTry70 from './pages/HTML Tutorial/HTML Try it out/try70';
import HTMLTry71 from './pages/HTML Tutorial/HTML Try it out/try71';
import HTMLTry72 from './pages/HTML Tutorial/HTML Try it out/try72';
import HTMLTry73 from './pages/HTML Tutorial/HTML Try it out/try73';
import HTMLTry74 from './pages/HTML Tutorial/HTML Try it out/try74';
import HTMLTry75 from './pages/HTML Tutorial/HTML Try it out/try75';
import HTMLTry76 from './pages/HTML Tutorial/HTML Try it out/try76';
import HTMLTry77 from './pages/HTML Tutorial/HTML Try it out/try77';
import HTMLTry78 from './pages/HTML Tutorial/HTML Try it out/try78';
import HTMLTry79 from './pages/HTML Tutorial/HTML Try it out/try79';
import HTMLTry80 from './pages/HTML Tutorial/HTML Try it out/try80';
import HTMLTry81 from './pages/HTML Tutorial/HTML Try it out/try81';
import HTMLTry82 from './pages/HTML Tutorial/HTML Try it out/try82';
import HTMLTry83 from './pages/HTML Tutorial/HTML Try it out/try83';
import HTMLTry84 from './pages/HTML Tutorial/HTML Try it out/try84';
import HTMLTry85 from './pages/HTML Tutorial/HTML Try it out/try85';
import HTMLTry86 from './pages/HTML Tutorial/HTML Try it out/try86';
import HTMLTry87 from './pages/HTML Tutorial/HTML Try it out/try87';
import HTMLTry88 from './pages/HTML Tutorial/HTML Try it out/try88';
import HTMLTry89 from './pages/HTML Tutorial/HTML Try it out/try89';
import HTMLTry90 from './pages/HTML Tutorial/HTML Try it out/try90';
import HTMLTry91 from './pages/HTML Tutorial/HTML Try it out/try91';
import HTMLTry92 from './pages/HTML Tutorial/HTML Try it out/try92';
import HTMLTry93 from './pages/HTML Tutorial/HTML Try it out/try93';
import HTMLTry94 from './pages/HTML Tutorial/HTML Try it out/try94';
import HTMLTry95 from './pages/HTML Tutorial/HTML Try it out/try95';
import HTMLTry96 from './pages/HTML Tutorial/HTML Try it out/try96';
import HTMLTry97 from './pages/HTML Tutorial/HTML Try it out/try97';
import HTMLTry98 from './pages/HTML Tutorial/HTML Try it out/try98';
import HTMLTry99 from './pages/HTML Tutorial/HTML Try it out/try99';
import HTMLTry100 from './pages/HTML Tutorial/HTML Try it out/try100';



import SpecialOutput1 from './JS/special scripts/output_1';
import SpecialOutput2 from './JS/special scripts/output_2';
import SpecialOutput3 from './JS/special scripts/output_3';
import SpecialOutput4 from './JS/special scripts/output_4';





import CSSintro from './pages/CSS Tutorial/CSS_introduction';
import CSSinserting from './pages/CSS Tutorial/CSS_inserting';
import CSScomments from './pages/CSS Tutorial/CSS_comments';
import CSSsyntax from './pages/CSS Tutorial/CSS_syntax';
import CSSselectors from './pages/CSS Tutorial/CSS_selectors';
import CSScombinators from './pages/CSS Tutorial/CSS_combinators';
import CSScolors from './pages/CSS Tutorial/CSS_colors';
import CSSTextColor from './pages/CSS Tutorial/CSS_text_color';
import CSSBackgrounds from './pages/CSS Tutorial/CSS_backgrounds';
import CSSBackgroundColors from './pages/CSS Tutorial/CSS_background_colors';
import CSSBackgroundImage from './pages/CSS Tutorial/CSS_background_images';
import CSSBackgroundAttachment from './pages/CSS Tutorial/CSS_background_attachment';
import CSSWidth from './pages/CSS Tutorial/CSS_width';
import CSSHeight from './pages/CSS Tutorial/CSS_Height';

import CSSTry1 from './pages/CSS Tutorial/CSS Try it out/trycss1';
import CSSTry2 from './pages/CSS Tutorial/CSS Try it out/trycss2';
import CSSTry3 from './pages/CSS Tutorial/CSS Try it out/trycss3';
import CSSTry4 from './pages/CSS Tutorial/CSS Try it out/trycss4';
import CSSTry5 from './pages/CSS Tutorial/CSS Try it out/trycss5';
import CSSTry6 from './pages/CSS Tutorial/CSS Try it out/trycss6';
import CSSTry7 from './pages/CSS Tutorial/CSS Try it out/trycss7';
import CSSTry8 from './pages/CSS Tutorial/CSS Try it out/trycss8';
import CSSTry9 from './pages/CSS Tutorial/CSS Try it out/trycss9';
import CSSTry10 from './pages/CSS Tutorial/CSS Try it out/trycss10';
import CSSTry11 from './pages/CSS Tutorial/CSS Try it out/trycss11';
import CSSTry12 from './pages/CSS Tutorial/CSS Try it out/trycss12';
import CSSTry13 from './pages/CSS Tutorial/CSS Try it out/trycss13';
import CSSTry14 from './pages/CSS Tutorial/CSS Try it out/trycss14';
import CSSTry15 from './pages/CSS Tutorial/CSS Try it out/trycss15';
import CSSTry16 from './pages/CSS Tutorial/CSS Try it out/trycss16';
import CSSTry17 from './pages/CSS Tutorial/CSS Try it out/trycss17';
import CSSTry18 from './pages/CSS Tutorial/CSS Try it out/trycss18';
import CSSTry19 from './pages/CSS Tutorial/CSS Try it out/trycss19';
import CSSTry20 from './pages/CSS Tutorial/CSS Try it out/trycss20';
import CSSTry21 from './pages/CSS Tutorial/CSS Try it out/trycss21';
import CSSTry22 from './pages/CSS Tutorial/CSS Try it out/trycss22';
import CSSTry23 from './pages/CSS Tutorial/CSS Try it out/trycss23';
import CSSTry24 from './pages/CSS Tutorial/CSS Try it out/trycss24';
import CSSTry25 from './pages/CSS Tutorial/CSS Try it out/trycss25';
import CSSTry26 from './pages/CSS Tutorial/CSS Try it out/trycss26';
import CSSTry27 from './pages/CSS Tutorial/CSS Try it out/trycss27';
import CSSTry28 from './pages/CSS Tutorial/CSS Try it out/trycss28';
import CSSTry29 from './pages/CSS Tutorial/CSS Try it out/trycss29';
import CSSTry30 from './pages/CSS Tutorial/CSS Try it out/trycss30';
import CSSTry31 from './pages/CSS Tutorial/CSS Try it out/trycss31';
import CSSTry32 from './pages/CSS Tutorial/CSS Try it out/trycss32';
import CSSTry33 from './pages/CSS Tutorial/CSS Try it out/trycss33';
import CSSTry34 from './pages/CSS Tutorial/CSS Try it out/trycss34';

import JSintro from './pages/JavaScript Tutorial/JS_introduction';
import JSwritingcode from './pages/JavaScript Tutorial/JS_writing_code';
import JSsyntax from './pages/JavaScript Tutorial/JS_syntax';
import JSComments from './pages/JavaScript Tutorial/JS_comment';
import JSEnvironment from './pages/JavaScript Tutorial/JS_environment';
import JSDisplayOutput from './pages/JavaScript Tutorial/JS_display_output';
import JSMethods from './pages/JavaScript Tutorial/JS_method';
import JSVariables from './pages/JavaScript Tutorial/JS_variables';
import JSDataTypes from './pages/JavaScript Tutorial/JS_data_types';
import JSOperators from './pages/JavaScript Tutorial/JS_operators';
import JSArithmeticOperators from './pages/JavaScript Tutorial/JS_arithmetic_operators';
import JSAssignmentOperators from './pages/JavaScript Tutorial/JS_assignment_operators';
import JSLC_Operators from './pages/JavaScript Tutorial/JS_logical & conditional_operators';
import JSComparisonOperators from './pages/JavaScript Tutorial/JS_comparison_operators';
import JSTypeofOperator from './pages/JavaScript Tutorial/JS_typeof_operators';


import JSTry1 from './pages/JavaScript Tutorial/JS Try it out/tryjs1';
import JSTry2 from './pages/JavaScript Tutorial/JS Try it out/tryjs2';
import JSTry3 from './pages/JavaScript Tutorial/JS Try it out/tryjs3';
import JSTry4 from './pages/JavaScript Tutorial/JS Try it out/tryjs4';
import JSTry5 from './pages/JavaScript Tutorial/JS Try it out/tryjs5';
import JSTry6 from './pages/JavaScript Tutorial/JS Try it out/tryjs6';
import JSTry7 from './pages/JavaScript Tutorial/JS Try it out/tryjs7';
import JSTry8 from './pages/JavaScript Tutorial/JS Try it out/tryjs8';
import JSTry9 from './pages/JavaScript Tutorial/JS Try it out/tryjs9';
import JSTry10 from './pages/JavaScript Tutorial/JS Try it out/tryjs10';
import JSTry11 from './pages/JavaScript Tutorial/JS Try it out/tryjs11';
import JSTry12 from './pages/JavaScript Tutorial/JS Try it out/tryjs12';
import JSTry13 from './pages/JavaScript Tutorial/JS Try it out/tryjs13';
import JSTry14 from './pages/JavaScript Tutorial/JS Try it out/tryjs14';
import JSTry15 from './pages/JavaScript Tutorial/JS Try it out/tryjs15';
import JSTry16 from './pages/JavaScript Tutorial/JS Try it out/tryjs16';
import JSTry17 from './pages/JavaScript Tutorial/JS Try it out/tryjs17';
import JSTry18 from './pages/JavaScript Tutorial/JS Try it out/tryjs18';
import JSTry19 from './pages/JavaScript Tutorial/JS Try it out/tryjs19';
import JSTry20 from './pages/JavaScript Tutorial/JS Try it out/tryjs20';
import JSTry21 from './pages/JavaScript Tutorial/JS Try it out/tryjs21';
import JSTry22 from './pages/JavaScript Tutorial/JS Try it out/tryjs22';
import JSTry23 from './pages/JavaScript Tutorial/JS Try it out/tryjs23';
import JSTry24 from './pages/JavaScript Tutorial/JS Try it out/tryjs24';
import JSTry25 from './pages/JavaScript Tutorial/JS Try it out/tryjs25';
import JSTry26 from './pages/JavaScript Tutorial/JS Try it out/tryjs26';
import JSTry27 from './pages/JavaScript Tutorial/JS Try it out/tryjs27';
import JSTry28 from './pages/JavaScript Tutorial/JS Try it out/tryjs28';
import JSTry29 from './pages/JavaScript Tutorial/JS Try it out/tryjs29';
import JSTry30 from './pages/JavaScript Tutorial/JS Try it out/tryjs30';
import JSTry31 from './pages/JavaScript Tutorial/JS Try it out/tryjs31';
import JSTry32 from './pages/JavaScript Tutorial/JS Try it out/tryjs32';
import JSTry33 from './pages/JavaScript Tutorial/JS Try it out/tryjs33';
import JSTry34 from './pages/JavaScript Tutorial/JS Try it out/tryjs34';
import JSTry35 from './pages/JavaScript Tutorial/JS Try it out/tryjs35';
import JSTry36 from './pages/JavaScript Tutorial/JS Try it out/tryjs36';
import JSTry37 from './pages/JavaScript Tutorial/JS Try it out/tryjs37';
import JSTry38 from './pages/JavaScript Tutorial/JS Try it out/tryjs38';
import JSTry39 from './pages/JavaScript Tutorial/JS Try it out/tryjs39';
import JSTry40 from './pages/JavaScript Tutorial/JS Try it out/tryjs40';
import JSTry41 from './pages/JavaScript Tutorial/JS Try it out/tryjs41';
import JSTry42 from './pages/JavaScript Tutorial/JS Try it out/tryjs42';
import JSTry43 from './pages/JavaScript Tutorial/JS Try it out/tryjs43';


import JSoutput1 from './pages/JavaScript Tutorial/JS Output/outputjs1';
import JSoutput2 from './pages/JavaScript Tutorial/JS Output/outputjs2';
import JSoutput3 from './pages/JavaScript Tutorial/JS Output/outputjs3';
import JSoutput4 from './pages/JavaScript Tutorial/JS Output/outputjs4';
import JSoutput5 from './pages/JavaScript Tutorial/JS Output/outputjs5';
import JSoutput6 from './pages/JavaScript Tutorial/JS Output/outputjs6';
import JSoutput7 from './pages/JavaScript Tutorial/JS Output/outputjs7';
import JSoutput8 from './pages/JavaScript Tutorial/JS Output/outputjs8';
import JSoutput9 from './pages/JavaScript Tutorial/JS Output/outputjs9';
import JSoutput10 from './pages/JavaScript Tutorial/JS Output/outputjs10';
import JSoutput11 from './pages/JavaScript Tutorial/JS Output/outputjs11';
import JSoutput12 from './pages/JavaScript Tutorial/JS Output/outputjs12';
import JSoutput13 from './pages/JavaScript Tutorial/JS Output/outputjs13';
import JSoutput14 from './pages/JavaScript Tutorial/JS Output/outputjs14';
import JSoutput15 from './pages/JavaScript Tutorial/JS Output/outputjs15';
import JSoutput16 from './pages/JavaScript Tutorial/JS Output/outputjs16';
import JSoutput17 from './pages/JavaScript Tutorial/JS Output/outputjs17';
import JSoutput18 from './pages/JavaScript Tutorial/JS Output/outputjs18';
import JSoutput19 from './pages/JavaScript Tutorial/JS Output/outputjs19';
import JSoutput20 from './pages/JavaScript Tutorial/JS Output/outputjs20';
import JSoutput21 from './pages/JavaScript Tutorial/JS Output/outputjs21';
import JSoutput22 from './pages/JavaScript Tutorial/JS Output/outputjs22';
import JSoutput23 from './pages/JavaScript Tutorial/JS Output/outputjs23';
import JSoutput24 from './pages/JavaScript Tutorial/JS Output/outputjs24';
import JSoutput25 from './pages/JavaScript Tutorial/JS Output/outputjs25';
import JSoutput26 from './pages/JavaScript Tutorial/JS Output/outputjs26';
import JSoutput27 from './pages/JavaScript Tutorial/JS Output/outputjs27';
import JSoutput28 from './pages/JavaScript Tutorial/JS Output/outputjs28';
import JSoutput29 from './pages/JavaScript Tutorial/JS Output/outputjs29';
import JSoutput30 from './pages/JavaScript Tutorial/JS Output/outputjs30';
import JSoutput31 from './pages/JavaScript Tutorial/JS Output/outputjs31';
import JSoutput32 from './pages/JavaScript Tutorial/JS Output/outputjs32';
import JSoutput33 from './pages/JavaScript Tutorial/JS Output/outputjs33';
import JSoutput34 from './pages/JavaScript Tutorial/JS Output/outputjs34';
import JSoutput35 from './pages/JavaScript Tutorial/JS Output/outputjs35';
import JSoutput36 from './pages/JavaScript Tutorial/JS Output/outputjs36';
import JSoutput37 from './pages/JavaScript Tutorial/JS Output/outputjs37';
import JSoutput38 from './pages/JavaScript Tutorial/JS Output/outputjs38';
import JSoutput39 from './pages/JavaScript Tutorial/JS Output/outputjs39';
import JSoutput40 from './pages/JavaScript Tutorial/JS Output/outputjs40';
import JSoutput41 from './pages/JavaScript Tutorial/JS Output/outputjs41';
import JSoutput42 from './pages/JavaScript Tutorial/JS Output/outputjs42';
import JSoutput43 from './pages/JavaScript Tutorial/JS Output/outputjs43';





import Rubyintro from './pages/Ruby Tutorial/Ruby_introduction';
import Rubyenvironment from './pages/Ruby Tutorial/Ruby_environment';
import Rubysyntax from './pages/Ruby Tutorial/Ruby_syntax';
import Rubyclasses from './pages/Ruby Tutorial/Ruby_classes';
import Rubycomments from './pages/Ruby Tutorial/Ruby_comments';
import RubyVariables from './pages/Ruby Tutorial/Ruby_variables';
import RubyDataTypes from './pages/Ruby Tutorial/Ruby_data_types';
import RubyConstants from './pages/Ruby Tutorial/Ruby_constants';
import RubyOperators from './pages/Ruby Tutorial/Ruby_operators';
import RubyConditionals from './pages/Ruby Tutorial/Ruby_conditionals';
import RubyLoops from './pages/Ruby Tutorial/Ruby_loops';
import RubyMethods from './pages/Ruby Tutorial/Ruby_methods';
import RubyBlocks from './pages/Ruby Tutorial/Ruby_blocks';
import RubyProcsLambda from './pages/Ruby Tutorial/Ruby_procs-lambda';
import RubyArrays from './pages/Ruby Tutorial/Ruby_arrays';
import RubyArrayMethods from './pages/Ruby Tutorial/Ruby_array_methods';
import RubyHashMethods from './pages/Ruby Tutorial/Ruby_hash_methods';
import RubyHashes from './pages/Ruby Tutorial/Ruby_hashes';
import RubyStrings from './pages/Ruby Tutorial/Ruby_strings';
import RubyStringMethods from './pages/Ruby Tutorial/Ruby_string_methods';
import RubyRanges from './pages/Ruby Tutorial/Ruby_ranges';
import RubySymbols from './pages/Ruby Tutorial/Ruby_symbols';
import RubyDateTime from './pages/Ruby Tutorial/Ruby_date-time';




import Reactintro from './pages/React Tutorial/React_intro';
import ReactEnvironmentSetup from './pages/React Tutorial/React_environment_setup';
import ReactCreateReactApp from './pages/React Tutorial/React_create-react-app';





import Pythonintro from './pages/Python Tutorial/Python_introduction';
import Pythoninstalling from './pages/Python Tutorial/Python_installing';
import PythonWritingCode from './pages/Python Tutorial/Python_writing_code';
import PythonDisplayOutput from './pages/Python Tutorial/Python_display_output';
import PythonSyntax from './pages/Python Tutorial/Python_syntax';
import PythonComments from './pages/Python Tutorial/Python_comments';
import PythonVariables from './pages/Python Tutorial/Python Variables/variable_intro';
import PythonVariablesNaming from './pages/Python Tutorial/Python Variables/variable_naming';
import PythonVariablesAssigning from './pages/Python Tutorial/Python Variables/variable_assigning';
import PythonVariablesTypes from './pages/Python Tutorial/Python Variables/variable_types';
import PythonVariablesConversion from './pages/Python Tutorial/Python Variables/variable_conversion';
import PythonDataTypes from './pages/Python Tutorial/Python_data_types';
import PythonTypeConversion from './pages/Python Tutorial/Python_type_conversion';
import PythonInput from './pages/Python Tutorial/Python_input';
import PythonOperators from './pages/Python Tutorial/Python_operators';
import PythonConditionals from './pages/Python Tutorial/Python_conditional';
import PythonLoops from './pages/Python Tutorial/Python_loops';
import PythonFunctions from './pages/Python Tutorial/Python_functions';
import PythonLambda from './pages/Python Tutorial/Python_lambda';
import PythonArrays from './pages/Python Tutorial/Python_arrays';
import PythonLists from './pages/Python Tutorial/Python_lists';
import PythonListMethods from './pages/Python Tutorial/Python_lists_methods';
import PythonListComprehensions from './pages/Python Tutorial/Python_list_comprehensions';
import PythonTuples from './pages/Python Tutorial/Python_tuples';
import PythonTupleMethods from './pages/Python Tutorial/Python_tuple_methods';
import PythonTupleUnpacking from './pages/Python Tutorial/Python_tuple_unpacking';
import PythonTupleConcatenation from './pages/Python Tutorial/Python_tuple_concatenation';
import PythonSets from './pages/Python Tutorial/Python_sets';
import PythonSetMethods from './pages/Python Tutorial/Python_set_methods';
import PythonSetComprehensions from './pages/Python Tutorial/Python_set_comprehensions';
import PythonSetOperations from './pages/Python Tutorial/Python_set_operations';
import PythonDictionaries from './pages/Python Tutorial/Python_dictionaries';
import PythonDictionaryMethods from './pages/Python Tutorial/Python_dictionary_methods';
import PythonDictionaryComprehensions from './pages/Python Tutorial/Python_dictionary_comprehensions';
import PythonStrings from './pages/Python Tutorial/Python_strings';
import PythonDateTime from './pages/Python Tutorial/Python_date-time';




import XMLintro from './pages/XML Tutorial/XML_introduction';
import XMLSyntax from './pages/XML Tutorial/XML_syntax';
import XMLTree from './pages/XML Tutorial/XML_tree';
import XMLElements from './pages/XML Tutorial/XML_elements';
import XMLAttributes from './pages/XML Tutorial/XML_attributes';
import XMLDeclaration from './pages/XML Tutorial/XML_declaration';
import XMLComments from './pages/XML Tutorial/XML_comments';
import XMLVersioning from './pages/XML Tutorial/XML_versioning';
import XMLEncoding from './pages/XML Tutorial/XML_encoding';
import XMLDataManipulation from './pages/XML Tutorial/XML_data_manipulation';
import XMLDataInsertion from './pages/XML Tutorial/XML_data_insertion';
import XMLDataModification from './pages/XML Tutorial/XML_modification';
import XMLDataDeletion from './pages/XML Tutorial/XML_data_deletion';
import XMLDataQuerying from './pages/XML Tutorial/XML_data_querying';
import XMLDataTransformation from './pages/XML Tutorial/XML_data_transformation';
import XMLXPath from './pages/XML Tutorial/XML_xpath';
import XMLXPathExpressions from './pages/XML Tutorial/XML_xpath_expressions';
import XMLXPathFunctions from './pages/XML Tutorial/XML_xpath_functions';
import XMLXPathAxes from './pages/XML Tutorial/XML_xpath_axes';
import XMLXQuery from './pages/XML Tutorial/XML_xquery';
import XMLXQueryExpressions from './pages/XML Tutorial/XML_xquery_expressions';
import XMLXqueryFunctions from './pages/XML Tutorial/XML_xquery_functions';
import XMLXqueryAxes from './pages/XML Tutorial/XML_xquery_axes';
import XMLDataBinding from './pages/XML Tutorial/XML_data_bindng';
import XMLSerialization from './pages/XML Tutorial/XML_serialization';
import XMLDeserialization from './pages/XML Tutorial/XML_deserialization';
import XMLAjax from './pages/XML Tutorial/XML_ajax';
import XMLAjaxXMLHttp from './pages/XML Tutorial/XML_ajax_xmlhttp';
import XMLAjaxRequests from './pages/XML Tutorial/XML_ajax_requests';
import XMLAjaxResponses from './pages/XML Tutorial/XML_ajax_responses';
import XMLAjaxParsing from './pages/XML Tutorial/XML_ajax_parsing';
import XMLAjaxPHP from './pages/XML Tutorial/XML_ajax_php';
import XMLAjaxApplications from './pages/XML Tutorial/XML_ajax_applications';
import XMLDOM from './pages/XML Tutorial/XML_dom';
import XMLHTML5 from './pages/XML Tutorial/XML_html5';




import SQLintro from './pages/SQL Tutorial/SQL_introduction';
import SQLQueries from './pages/SQL Tutorial/SQL_queries';
import SQLComments from './pages/SQL Tutorial/SQL_comments';
import SQLSelect from './pages/SQL Tutorial/SQL_select';
import SQLInsert from './pages/SQL Tutorial/SQL_insert';
import SQLUpdate from './pages/SQL Tutorial/SQL_update';
import SQLDelete from './pages/SQL Tutorial/SQL_delete';
import SQLDataTypes from './pages/SQL Tutorial/SQL_data-types';
import SQLAggregateFunctions from './pages/SQL Tutorial/SQL_aggregate';
import SQLStringFunctions from './pages/SQL Tutorial/SQL_string';
import SQLDateTimeFunctions from './pages/SQL Tutorial/SQL_date-time';
import SQLNestedQueries from './pages/SQL Tutorial/SQL_nested';
import SQLCorrelatedQueries from './pages/SQL Tutorial/SQL_correlated';
import SQLCTEs from './pages/SQL Tutorial/SQL_ctes';
import SQLJoins from './pages/SQL Tutorial/SQL_joins';
import SQLInnerJoin from './pages/SQL Tutorial/SQL_inner_join';
import SQLLeftJoin from './pages/SQL Tutorial/SQL_left_join';
import SQLRightJoin from './pages/SQL Tutorial/SQL_right_join';
import SQLFullOuterJoin from './pages/SQL Tutorial/SQL_full_outer';
import SQLCrossJoin from './pages/SQL Tutorial/SQL_cross_join';
import SQLViews from './pages/SQL Tutorial/SQL_views';
import SQLCreatingViews from './pages/SQL Tutorial/SQL_creating_views';
import SQLManagingViews from './pages/SQL Tutorial/SQL_managing_views';
import SQLViewOptimization from './pages/SQL Tutorial/SQL_View_optimization';
import SQLIndexes from './pages/SQL Tutorial/SQL_indexes';





import AIintro from './pages/AI Tutorial/AI_introduction';
import AIhistory from './pages/AI Tutorial/AI_history';
import AITypes from './pages/AI Tutorial/AI_types';
import AIProlemSolving from './pages/AI Tutorial/AI_problem_solving';
import AIMachineLearning from './pages/AI Tutorial/AI_machine_learning';
import AISupervisedLearning from './pages/AI Tutorial/AI_supervised_learning';
import AIUnsupervisedLearning from './pages/AI Tutorial/AI_unsupervised_learning';
import AIReinforcementLearning from './pages/AI Tutorial/AI_reinforcement_learning';
import AINeuralNetworks from './pages/AI Tutorial/AI_neural_networks';
import AIDeepLearning from './pages/AI Tutorial/AI_deep_learning';
import AICNNs from './pages/AI Tutorial/AI_cnns';
import AIRNNs from './pages/AI Tutorial/AI_rnns';
import AILSTMNs from './pages/AI Tutorial/AI_lstm_networks';
import AIGANs from './pages/AI Tutorial/AI_gans';
import AINLP from './pages/AI Tutorial/AI_nlp';
import AITextPreprocessing from './pages/AI Tutorial/AI_text_preprocessing';
import AISentimentAnalysis from './pages/AI Tutorial/AI_sentiment_analysis';
import AINamedEntityRecognition from './pages/AI Tutorial/AI_ner';
import AIlanguageModels from './pages/AI Tutorial/AI_language_models';
import AIComputerVision from './pages/AI Tutorial/AI_computer_vision';
import AIObjectDetection from './pages/AI Tutorial/AI_object_detection';
import AIImageSegmentation from './pages/AI Tutorial/AI_image_segmentation';
import AIFacialRecognition from './pages/AI Tutorial/AI_facial_recognition';
import AIRobotics from './pages/AI Tutorial/AI_robotics';
import AIRoboticsHardware from './pages/AI Tutorial/AI_robotics_hardware';
import AIRoboticsSoftware from './pages/AI Tutorial/AI_robotics_software';
import AIRoboticsApplications from './pages/AI Tutorial/AI_robotics_applications';
import AIEthics from './pages/AI Tutorial/AI_ethics';





import PHPintro from './pages/PHP Tutorial/PHP_introduction';
import PHPEnvironmentSetup from './pages/PHP Tutorial/PHP_environment_setup';
import PHPSyntax from './pages/PHP Tutorial/PHP_syntax';
import PHPComments from './pages/PHP Tutorial/PHP_comments';
import PHPVariables from './pages/PHP Tutorial/PHP_variables';
import PHPDataTypes from './pages/PHP Tutorial/PHP_datatypes';
import PHPEcho from './pages/PHP Tutorial/PHP_echo';
import PHPPrint from './pages/PHP Tutorial/PHP_print';
import PHPOperators from './pages/PHP Tutorial/PHP_operators';
import PHPArithmetic from './pages/PHP Tutorial/PHP_arithmetic';
import PHPAssignment from './pages/PHP Tutorial/PHP_assignment';
import PHPComparison from './pages/PHP Tutorial/PHP_comparison';
import PHPLogical from './pages/PHP Tutorial/PHP_logical';
import PHPIncrement from './pages/PHP Tutorial/PHP_increment';
import PHPDecrement from './pages/PHP Tutorial/PHP_decrement';
import PHPString from './pages/PHP Tutorial/PHP_string';
import PHPArray from './pages/PHP Tutorial/PHP_array';
import PHPConditional from './pages/PHP Tutorial/PHP_conditional';
import PHPLoops from './pages/PHP Tutorial/PHP_loops';
import PHPSwitch from './pages/PHP Tutorial/PHP_switch';
import PHPfunctions from './pages/PHP Tutorial/PHP_functions';


import ReactES6 from './pages/React Tutorial/React_es6';
import ReactES6modules from './pages/React Tutorial/React_es6-modules';
import ReactES6arrowfunctions from './pages/React Tutorial/React_es6-arrow-functions';
import ReactES6destructuring from './pages/React Tutorial/React_es6-destructuring';
import ReactES6template from './pages/React Tutorial/React_es6-template-literals';
import ReactES6classes from './pages/React Tutorial/React_es6-classes';
import ReactES6spread from './pages/React Tutorial/React_es6-spread';
import ReactES6rest from './pages/React Tutorial/React_es6-rest';
import ReactJSX from './pages/React Tutorial/React_jsx';
import ReactComponents from './pages/React Tutorial/React_components';


import Portfolio from './components/Projects/portfolio';
import TodoList from './components/Projects/todolist';
import Calculator from './components/Projects/calculator';
import Flexbox from './components/Projects/flexbox';
import Landing from './components/Projects/landing';
import Dashboard from './pages/Dashboard';
import LandingPage from './pages/Welcome';
import NeedWebsitePage from './pages/NeedaWebsite';


import XTips from './pages/Tips';
import HTMLtips from './pages/XTIPS/HTML_tips';
import CSStips from './pages/XTIPS/CSS_tips';
import JSTips from './pages/XTIPS/JS_tips';
import PHPtips from './pages/XTIPS/PHP_tips';
import Pythontips from './pages/XTIPS/Python_tips';
import SQLtips from './pages/XTIPS/SQL_tips';
import Rubytips from './pages/XTIPS/Ruby_tips';
import XMLtips from './pages/XTIPS/XML_tips';
import Reacttips from './pages/XTIPS/React_tips';
import AItips from './pages/XTIPS/AI_tips';
import SVGEditor from './pages/SVGEditor';
import ReactState from './pages/React Tutorial/React_state';
import ReactProps from './pages/React Tutorial/React_props';
import ReactEvent from './pages/React Tutorial/React_event';
import ReactConditional from './pages/React Tutorial/React_conditional';
import ReactLists from './pages/React Tutorial/React_lists';
import ReactKeys from './pages/React Tutorial/React_keys';
import ReactForms from './pages/React Tutorial/React_forms';
import ReactRouter from './pages/React Tutorial/React_router';




import HTMLProgress from './components/Tutorial Progress/Progress Data/htmlprogress';
import CSSProgress from './components/Tutorial Progress/Progress Data/cssprogress';
import JSProgress from './components/Tutorial Progress/Progress Data/jsprogress';
import PHpProgress from './components/Tutorial Progress/Progress Data/phpprogress';
import PythonProgress from './components/Tutorial Progress/Progress Data/pythonprogress';
import RubyProgress from './components/Tutorial Progress/Progress Data/rubyprogress';
import SQLProgress from './components/Tutorial Progress/Progress Data/sqlprogress';
import XMLProgress from './components/Tutorial Progress/Progress Data/xmlprogress';
import ReactProgress from './components/Tutorial Progress/Progress Data/reactprogress';
import AIProgress from './components/Tutorial Progress/Progress Data/aiprogress';





import Null from './components/404/Null';
import Verified from './pages/Verified';




export default function App() {
  useEffect(() => {
  
    const contentElement = document.querySelector('.content');
    const highlighterElement = document.querySelector('.highlighter');
    const divElement = document.querySelector('.divsample');
    const xamplingElement = document.querySelector('.xampling');
    const headerElement = document.querySelector('.headertutorials');
    const mainthemeElement = document.querySelector('.main-theme');
    const navButtonElement = document.querySelector('.nav-button');
    const messageElement = document.querySelector('.message-container');
    const incomingMsgElement = document.querySelector('.incomingMsgText');
    const typeMsgElement = document.querySelector('.typeMsgText');

    if (contentElement) {
      contentElement.classList.add('light-theme');
    }

    if (highlighterElement) {
      highlighterElement.classList.add('light-theme');
    }


    if (divElement) {
      divElement.classList.add('light-theme');
    }

    if (xamplingElement) {
      xamplingElement.classList.add('light-theme');
    }

    if (mainthemeElement) {
      mainthemeElement.classList.add('light-theme');
    }


    if (headerElement) {
      headerElement.classList.add('light-theme');
    }

    if (navButtonElement) {
      navButtonElement.classList.add('light-theme');
    }

    if (messageElement) {
      messageElement.classList.add('light-theme');
    }
    
    if (incomingMsgElement) {
      messageElement.classList.add('light-theme');
    }
    
    if (typeMsgElement) {
      messageElement.classList.add('light-theme');
    }
  }, []);

  document.addEventListener("DOMContentLoaded", () => {
    const rowElements = document.querySelectorAll('.row');
    
    rowElements.forEach(rowElement => {
      const parentElement = rowElement.parentElement;
      if (parentElement) {
        parentElement.classList.add('parent-highlight');
      }
    });
  });

  return (
 
    <ProgressProvider>
    <Router>
     <TrackProgress>
    <Routes>
  
    <Route exact path="/" element={<Home />} />
    <Route exact path="/ai" element={<BetaAI />} /> 
      <Route path='/certificate' element={<CertificatePage />} />
      <Route path='/profile' element={<ProtectedRoute />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/welcome' element={<LandingPage />} />
      <Route path="/needawebsite" element={<NeedWebsitePage />} />
      <Route path='/about' element={<About />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/services' element={<Services />} />
      <Route path='/projects' element={<ProjectStarterKit />} />
      <Route path='/portfolio_project' element={<Portfolio />} />
      <Route path='/todolist_project' element={<TodoList />} />
      <Route path='/calculator_project' element={<Calculator />} />
      <Route path='/flexbox_project' element={<Flexbox />} />
      <Route path='/landingpage_project' element={<Landing />} />
      <Route path='/terms&privacy' element={<TermsPrivacy />} />
      <Route path="/svgeditor" element={<SVGEditor />} />
      <Route path="/progress-tracker" element={<ProgressTracker />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify" element={<Verified />} />
      <Route path="/forgot-password" element={<ForgotPassword/>} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/email-sent" element={<EmailSent />} />
        
      <Route path='/coming_soon' element={<ComingSoon />} />
      <Route path='/tips_coming_soon' element={<ComingSoonTips />} />
      <Route path='/fileinthesamefolder.html' element={<File />} />
      <Route path='/take_quiz' element={<CodingQuiz />} />
      <Route path='/form-handler.html' element={<Formhandler />} />
      <Route path='/so_1' element={<SpecialOutput1 />} />
      <Route path='/so_2' element={<SpecialOutput2 />} />
      <Route path='/so_3' element={<SpecialOutput3 />} />
      <Route path='/so_4' element={<SpecialOutput4 />} />


      <Route path="/html-progress-tracker" element={<HTMLProgress />} />
      <Route path="/css-progress-tracker" element={<CSSProgress />} />
      <Route path="/js-progress-tracker" element={<JSProgress />} />
      <Route path="/php-progress-tracker" element={<PHpProgress />} />
      <Route path="/python-progress-tracker" element={<PythonProgress />} />
      <Route path="/ruby-progress-tracker" element={<RubyProgress />} />
      <Route path="/sql-progress-tracker" element={<SQLProgress />} />
      <Route path="/xml-progress-tracker" element={<XMLProgress />} />
      <Route path="/react-progress-tracker" element={<ReactProgress />} />
      <Route path="/ai-progress-tracker" element={<AIProgress />} />


      <Route path="/tips" element={<XTips />} />
      <Route path="/html_tips" element={<HTMLtips />} />
      <Route path="/css_tips" element={<CSStips />} />
      <Route path="/js_tips" element={<JSTips />} />
      <Route path="/php_tips" element={<PHPtips />} />
      <Route path="/python_tips" element={<Pythontips />} />
      <Route path="/sql_tips" element={<SQLtips />} />
      <Route path="/ruby_tips" element={<Rubytips />} />
      <Route path="/xml_tips" element={<XMLtips />} />
      <Route path="/react_tips" element={<Reacttips />} />
      <Route path="/ai_tips" element={<AItips />} />
    

      <Route path="/HTML_Tutorial_intro" element={<HTMLintro />} />
      <Route path='/HTML_editors' element={<HTMLeditors />} />
      <Route path='/HTML_comments' element={<HTMLcomments />} />
      <Route path='/HTML_elements' element={<HTMLelements />} />
      <Route path='/HTML_attributes' element={<HTMLattributes />} />
      <Route path='/HTML_attributes pt.2' element={<HTMLattributespt2/>} />
      <Route path='/HTML_heading' element={<HTMLheading/>} />
      <Route path='/HTML_paragraph' element={<HTMLparagraph/>} />
      <Route path='/HTML_styles' element={<HTMLstyles/>} />
      <Route path='/HTML_colors' element={<HTMLcolors/>} />
      <Route path='/HTML_links' element={<HTMLlinks/>} />
      <Route path='/HTML_horizontal_rule' element={<HTMLhorizontalrule/>} />
      <Route path='/HTML_line_break' element={<HTMLlinebreak/>} />
      <Route path='/HTML_text_formatting' element={<HTMLtextformatting/>} />
      <Route path='/HTML_section' element={<HTMLsection/>} />
      <Route path='/HTML_image' element={<HTMLimage/>} />
      <Route path='/HTML_block_inline' element={<HTMLblock_inline/>} />
      <Route path='/HTML_subscript' element={<HTMLsubscript/>} />
      <Route path='/HTML_superscript' element={<HTMLsuperscript/>} />
      <Route path='/HTML_figures' element={<HTMLfigures/>} />
      <Route path='/HTML_nbsp' element={<HTMLnbsp/>} />
      <Route path='/HTML_entities' element={<HTMLcharacter_entities/>} />
      <Route path='/HTML_pre' element={<HTMLpre/>} />
      <Route path='/HTML_lists' element={<HTMLlists/>} />
      <Route path='/HTML_unordered' element={<HTMLunorderedlist/>} />
      <Route path='/HTML_ordered' element={<HTMLordered/>} />
      <Route path='/HTML_description' element={<HTMLdescription/>} />
      <Route path='/HTML_forms' element={<HTMLforms/>} />
      <Route path='/HTML_forms_attr' element={<HTMLformselementattr/>} />
      <Route path='/HTML_forms_attr2' element={<HTMLformselementattr2/>} />
      <Route path='/HTML_js' element={<HTMLjs/>} />
      <Route path='/HTML_input' element={<HTMLinput/>} />
      <Route path='/HTML_input2' element={<HTMLinput2/>} />



    <Route path='/try_html0.1' element={<HTMLTry01 />} />
    <Route path='/try_html0.2' element={<HTMLTry02 />} />
    <Route path='/try_html1' element={<HTMLTry1 />} />
    <Route path='/try_html2' element={<HTMLTry2 />} />
    <Route path='/try_html3' element={<HTMLTry3 />} />
    <Route path='/try_html4' element={<HTMLTry4 />} />
    <Route path='/try_html5' element={<HTMLTry5 />} />
    <Route path='/try_html6' element={<HTMLTry6 />} />
    <Route path='/try_html7' element={<HTMLTry7 />} />
    <Route path='/try_html8' element={<HTMLTry8 />} />
    <Route path='/try_html9' element={<HTMLTry9 />} />
    <Route path='/try_html10' element={<HTMLTry10 />} />
    <Route path='/try_html11' element={<HTMLTry11 />} />
    <Route path='/try_html12' element={<HTMLTry12 />} />
    <Route path='/try_html13' element={<HTMLTry13 />} />
    <Route path='/try_html14' element={<HTMLTry14 />} />
    <Route path='/try_html15' element={<HTMLTry15 />} />
    <Route path='/try_html16' element={<HTMLTry16 />} />
    <Route path='/try_html17' element={<HTMLTry17 />} />
    <Route path='/try_html18' element={<HTMLTry18 />} />
    <Route path='/try_html19' element={<HTMLTry19 />} />
    <Route path='/try_html20' element={<HTMLTry20 />} />
    <Route path='/try_html21' element={<HTMLTry21 />} />
    <Route path='/try_html22' element={<HTMLTry22 />} />
    <Route path='/try_html23' element={<HTMLTry23 />} />
    <Route path='/try_html24' element={<HTMLTry24 />} />
    <Route path='/try_html25' element={<HTMLTry25 />} />
    <Route path='/try_html26' element={<HTMLTry26 />} />
    <Route path='/try_html27' element={<HTMLTry27 />} />
    <Route path='/try_html28' element={<HTMLTry28 />} />
    <Route path='/try_html29' element={<HTMLTry29 />} />
    <Route path='/try_html30' element={<HTMLTry30 />} />
    <Route path='/try_html31' element={<HTMLTry31 />} />
    <Route path='/try_html32' element={<HTMLTry32 />} />
    <Route path='/try_html33' element={<HTMLTry33 />} />
    <Route path='/try_html34' element={<HTMLTry34 />} />
    <Route path='/try_html35' element={<HTMLTry35 />} />
    <Route path='/try_html36' element={<HTMLTry36 />} />
    <Route path='/try_html37' element={<HTMLTry37 />} />
    <Route path='/try_html38' element={<HTMLTry38 />} />
<Route path='/try_html39' element={<HTMLTry39 />} />
<Route path='/try_html40' element={<HTMLTry40 />} />
<Route path='/try_html41' element={<HTMLTry41 />} />
<Route path='/try_html42' element={<HTMLTry42 />} />
<Route path='/try_html43' element={<HTMLTry43 />} />
<Route path='/try_html44' element={<HTMLTry44 />} />
<Route path='/try_html45' element={<HTMLTry45 />} />
<Route path='/try_html46' element={<HTMLTry46 />} />
<Route path='/try_html47' element={<HTMLTry47 />} />
<Route path='/try_html48' element={<HTMLTry48 />} />
<Route path='/try_html49' element={<HTMLTry49 />} />
<Route path='/try_html50' element={<HTMLTry50 />} />
<Route path='/try_html51' element={<HTMLTry51 />} />
<Route path='/try_html52' element={<HTMLTry52 />} />
<Route path='/try_html53' element={<HTMLTry53 />} />
<Route path='/try_html54' element={<HTMLTry54 />} />
<Route path='/try_html55' element={<HTMLTry55 />} />
<Route path='/try_html56' element={<HTMLTry56 />} />
<Route path='/try_html57' element={<HTMLTry57 />} />
<Route path='/try_html58' element={<HTMLTry58 />} />
<Route path='/try_html59' element={<HTMLTry59 />} />
<Route path='/try_html60' element={<HTMLTry60 />} />
<Route path='/try_html61' element={<HTMLTry61 />} />
<Route path='/try_html62' element={<HTMLTry62 />} />
<Route path='/try_html63' element={<HTMLTry63 />} />
<Route path='/try_html64' element={<HTMLTry64 />} />
<Route path='/try_html65' element={<HTMLTry65 />} />
<Route path='/try_html66' element={<HTMLTry66 />} />
<Route path='/try_html67' element={<HTMLTry67 />} />
<Route path='/try_html68' element={<HTMLTry68 />} />
<Route path='/try_html69' element={<HTMLTry69 />} />
<Route path='/try_html70' element={<HTMLTry70 />} />
<Route path='/try_html71' element={<HTMLTry71 />} />
<Route path='/try_html72' element={<HTMLTry72 />} />
<Route path='/try_html73' element={<HTMLTry73 />} />
<Route path='/try_html74' element={<HTMLTry74 />} />
<Route path='/try_html75' element={<HTMLTry75 />} />
<Route path='/try_html76' element={<HTMLTry76 />} />
<Route path='/try_html77' element={<HTMLTry77 />} />
<Route path='/try_html78' element={<HTMLTry78 />} />
<Route path='/try_html79' element={<HTMLTry79 />} />
<Route path='/try_html80' element={<HTMLTry80 />} />
<Route path='/try_html81' element={<HTMLTry81 />} />
<Route path='/try_html82' element={<HTMLTry82 />} />
<Route path='/try_html83' element={<HTMLTry83 />} />
<Route path='/try_html84' element={<HTMLTry84 />} />
<Route path='/try_html85' element={<HTMLTry85 />} />
<Route path='/try_html86' element={<HTMLTry86 />} />
<Route path='/try_html87' element={<HTMLTry87 />} />
<Route path='/try_html88' element={<HTMLTry88 />} />
<Route path='/try_html89' element={<HTMLTry89 />} />
<Route path='/try_html90' element={<HTMLTry90 />} />
<Route path='/try_html91' element={<HTMLTry91 />} />
<Route path='/try_html92' element={<HTMLTry92 />} />
<Route path='/try_html93' element={<HTMLTry93 />} />
<Route path='/try_html94' element={<HTMLTry94 />} />
<Route path='/try_html95' element={<HTMLTry95 />} />
<Route path='/try_html96' element={<HTMLTry96 />} />
<Route path='/try_html97' element={<HTMLTry97 />} />
<Route path='/try_html98' element={<HTMLTry98 />} />
<Route path='/try_html99' element={<HTMLTry99 />} />
<Route path='/try_html100' element={<HTMLTry100 />} />

    
  



    <Route path='/CSS_Tutorial_intro' element={<CSSintro />} />
    <Route path='/CSS_inserting' element={<CSSinserting/>} />
    <Route path='/CSS_comments' element={<CSScomments/>} />
    <Route path='/CSS_syntax' element={<CSSsyntax/>} />
    <Route path='/CSS_selectors' element={<CSSselectors/>} />
    <Route path='/CSS_combinators' element={<CSScombinators/>} />
    <Route path='/CSS_colors' element={<CSScolors/>} />
    <Route path='/CSS_text_color' element={<CSSTextColor/>} />
    <Route path='/CSS_backgrounds' element={<CSSBackgrounds/>} />
    <Route path='/CSS_background_colors' element={<CSSBackgroundColors/>} />
    <Route path='/CSS_background_images' element={<CSSBackgroundImage/>} />
    <Route path='/CSS_background_attachment' element={<CSSBackgroundAttachment/>} />
    <Route path='/CSS_width' element={<CSSWidth/>} />
    <Route path='/CSS_height' element={<CSSHeight/>} />
      
    <Route path='/try_css1' element={<CSSTry1 />} />
    <Route path='/try_css2' element={<CSSTry2 />} />
    <Route path='/try_css3' element={<CSSTry3 />} />
    <Route path='/try_css4' element={<CSSTry4 />} />
    <Route path='/try_css5' element={<CSSTry5 />} />
    <Route path='/try_css6' element={<CSSTry6 />} />
    <Route path='/try_css7' element={<CSSTry7 />} />
    <Route path='/try_css8' element={<CSSTry8 />} />
    <Route path='/try_css9' element={<CSSTry9 />} />
    <Route path='/try_css10' element={<CSSTry10 />} />
    <Route path='/try_css11' element={<CSSTry11 />} />
    <Route path='/try_css12' element={<CSSTry12 />} />
    <Route path='/try_css13' element={<CSSTry13 />} />
    <Route path='/try_css14' element={<CSSTry14 />} />
    <Route path='/try_css15' element={<CSSTry15 />} />
    <Route path='/try_css16' element={<CSSTry16 />} />
    <Route path='/try_css17' element={<CSSTry17 />} />
    <Route path='/try_css18' element={<CSSTry18 />} />
    <Route path='/try_css19' element={<CSSTry19 />} />
    <Route path='/try_css20' element={<CSSTry20 />} />
    <Route path='/try_css21' element={<CSSTry21 />} />
    <Route path='/try_css22' element={<CSSTry22 />} />
    <Route path='/try_css23' element={<CSSTry23 />} />
    <Route path='/try_css24' element={<CSSTry24 />} />
    <Route path='/try_css25' element={<CSSTry25 />} />
    <Route path='/try_css26' element={<CSSTry26 />} />
    <Route path='/try_css27' element={<CSSTry27 />} />
    <Route path='/try_css28' element={<CSSTry28 />} />
    <Route path='/try_css29' element={<CSSTry29 />} />
    <Route path='/try_css30' element={<CSSTry30 />} />
    <Route path='/try_css31' element={<CSSTry31 />} />
    <Route path='/try_css32' element={<CSSTry32 />} />
    <Route path='/try_css33' element={<CSSTry33 />} />
    <Route path='/try_css34' element={<CSSTry34 />} />


    <Route path='/JavaScript_Tutorial_intro' element={<JSintro />} />
    <Route path='/JS_writing_code' element={<JSwritingcode />} />
    <Route path='/JS_syntax' element={<JSsyntax />} />
    <Route path='/JS_comment' element={<JSComments />} />
    <Route path='/JS_environment' element={<JSEnvironment />} />
    <Route path='/JS_display_output' element={<JSDisplayOutput />} />
    <Route path='/JS_method' element={<JSMethods />} />
    <Route path='/JS_variables' element={<JSVariables />} />
    <Route path='/JS_data_types' element={<JSDataTypes />} />
    <Route path='/JS_operators' element={<JSOperators />} />
    <Route path='/JS_arithmetic_operators' element={<JSArithmeticOperators />} />
    <Route path='/JS_assignment_operators' element={<JSAssignmentOperators />} />
    <Route path='/JS_logical_conditional_operators' element={<JSLC_Operators />} />
    <Route path='/JS_comparison_operators' element={<JSComparisonOperators />} />
    <Route path='/JS_typeof_operators' element={<JSTypeofOperator />} />

    <Route path='/try_js1' element={<JSTry1 />} />
    <Route path='/try_js2' element={<JSTry2 />} />
    <Route path='/try_js3' element={<JSTry3 />} />
    <Route path='/try_js4' element={<JSTry4 />} />
    <Route path='/try_js5' element={<JSTry5 />} />
    <Route path='/try_js6' element={<JSTry6 />} />
    <Route path='/try_js7' element={<JSTry7 />} />
    <Route path='/try_js8' element={<JSTry8 />} />
    <Route path='/try_js9' element={<JSTry9 />} />
    <Route path='/try_js10' element={<JSTry10 />} />
    <Route path='/try_js11' element={<JSTry11 />} />
    <Route path='/try_js12' element={<JSTry12 />} />
    <Route path='/try_js13' element={<JSTry13 />} />
    <Route path='/try_js14' element={<JSTry14 />} />
    <Route path='/try_js15' element={<JSTry15 />} />
    <Route path='/try_js16' element={<JSTry16 />} />
    <Route path='/try_js17' element={<JSTry17 />} />
    <Route path='/try_js18' element={<JSTry18 />} />
    <Route path='/try_js19' element={<JSTry19 />} />
    <Route path='/try_js20' element={<JSTry20 />} />
    <Route path='/try_js21' element={<JSTry21 />} />
    <Route path='/try_js22' element={<JSTry22 />} />
    <Route path='/try_js23' element={<JSTry23 />} />
    <Route path='/try_js24' element={<JSTry24 />} />
    <Route path='/try_js25' element={<JSTry25 />} />
    <Route path='/try_js26' element={<JSTry26 />} />
    <Route path='/try_js27' element={<JSTry27 />} />
    <Route path='/try_js28' element={<JSTry28 />} />
    <Route path='/try_js29' element={<JSTry29 />} />
    <Route path='/try_js30' element={<JSTry30 />} />
    <Route path='/try_js31' element={<JSTry31 />} />
    <Route path='/try_js32' element={<JSTry32 />} />
    <Route path='/try_js33' element={<JSTry33 />} />
    <Route path='/try_js34' element={<JSTry34 />} />
    <Route path='/try_js35' element={<JSTry35 />} />
    <Route path='/try_js36' element={<JSTry36 />} />
    <Route path='/try_js37' element={<JSTry37 />} />
    <Route path='/try_js38' element={<JSTry38 />} />
    <Route path='/try_js39' element={<JSTry39 />} />
    <Route path='/try_js40' element={<JSTry40 />} />
    <Route path='/try_js41' element={<JSTry41 />} />
    <Route path='/try_js42' element={<JSTry42 />} />
    <Route path='/try_js43' element={<JSTry43 />} />



    <Route path='/output_js1' element={<JSoutput1 />} />
    <Route path='/output_js2' element={<JSoutput2 />} />
    <Route path='/output_js3' element={<JSoutput3 />} />
    <Route path='/output_js4' element={<JSoutput4 />} />
    <Route path='/output_js5' element={<JSoutput5 />} />
    <Route path='/output_js6' element={<JSoutput6 />} />
    <Route path='/output_js7' element={<JSoutput7 />} />
    <Route path='/output_js8' element={<JSoutput8 />} />
    <Route path='/output_js9' element={<JSoutput9 />} />
    <Route path='/output_js10' element={<JSoutput10 />} />
    <Route path='/output_js11' element={<JSoutput11 />} />
    <Route path='/output_js12' element={<JSoutput12 />} />
    <Route path='/output_js13' element={<JSoutput13 />} />
    <Route path='/output_js14' element={<JSoutput14 />} />
    <Route path='/output_js15' element={<JSoutput15 />} />
    <Route path='/output_js16' element={<JSoutput16 />} />
    <Route path='/output_js17' element={<JSoutput17 />} />
    <Route path='/output_js18' element={<JSoutput18 />} />
    <Route path='/output_js19' element={<JSoutput19 />} />
    <Route path='/output_js20' element={<JSoutput20 />} />
    <Route path='/output_js21' element={<JSoutput21 />} />
    <Route path='/output_js22' element={<JSoutput22 />} />
    <Route path='/output_js23' element={<JSoutput23 />} />
    <Route path='/output_js24' element={<JSoutput24 />} />
    <Route path='/output_js25' element={<JSoutput25 />} />
    <Route path='/output_js26' element={<JSoutput26 />} />
    <Route path='/output_js27' element={<JSoutput27 />} />
    <Route path='/output_js28' element={<JSoutput28 />} />
    <Route path='/output_js29' element={<JSoutput29 />} />
    <Route path='/output_js30' element={<JSoutput30 />} />
    <Route path='/output_js31' element={<JSoutput31 />} />
    <Route path='/output_js32' element={<JSoutput32 />} />
    <Route path='/output_js33' element={<JSoutput33 />} />
    <Route path='/output_js34' element={<JSoutput34 />} />
    <Route path='/output_js35' element={<JSoutput35 />} />
    <Route path='/output_js36' element={<JSoutput36 />} />
    <Route path='/output_js37' element={<JSoutput37 />} />
    <Route path='/output_js38' element={<JSoutput38 />} />
    <Route path='/output_js39' element={<JSoutput39 />} />
    <Route path='/output_js40' element={<JSoutput40 />} />
    <Route path='/output_js41' element={<JSoutput41 />} />
    <Route path='/output_js42' element={<JSoutput42 />} />
    <Route path='/output_js43' element={<JSoutput43 />} />





    <Route path='/Ruby_Tutorial_intro' element={<Rubyintro />} />
    <Route path='/Ruby_environment' element={<Rubyenvironment/>} />
    <Route path='/Ruby_syntax' element={<Rubysyntax/>} />
    <Route path='/Ruby_classes' element={<Rubyclasses/>} />
    <Route path='/Ruby_comments' element={<Rubycomments/>} />
    <Route path='/Ruby_variables' element={<RubyVariables/>} />
    <Route path='/Ruby_data_types' element={<RubyDataTypes/>} />
    <Route path='/Ruby_constants' element={<RubyConstants/>} />
    <Route path='/Ruby_operators' element={<RubyOperators/>} />
    <Route path='/Ruby_conditionals' element={<RubyConditionals/>} />
    <Route path='/Ruby_loops' element={<RubyLoops/>} />
    <Route path='/Ruby_methods' element={<RubyMethods/>} />
    <Route path='/Ruby_blocks' element={<RubyBlocks/>} />
    <Route path='/Ruby_procs-lambda' element={<RubyProcsLambda/>} />
    <Route path='/Ruby_arrays' element={<RubyArrays/>} />
    <Route path='/Ruby_array_methods' element={<RubyArrayMethods/>} />
    <Route path='/Ruby_hashes' element={<RubyHashes/>} />
    <Route path='/Ruby_hash_methods' element={<RubyHashMethods/>} />
    <Route path='/Ruby_strings' element={<RubyStrings/>} />
    <Route path='/Ruby_string_methods' element={<RubyStringMethods/>} />
    <Route path='/Ruby_ranges' element={<RubyRanges/>} />
    <Route path='/Ruby_symbols' element={<RubySymbols/>} />
    <Route path='/Ruby_date-time' element={<RubyDateTime/>} />




    <Route path='/React_Tutorial_intro' element={<Reactintro />} />
    <Route path='/React_environment_setup' element={<ReactEnvironmentSetup/>} />
    <Route path='/React_create-react-app' element={<ReactCreateReactApp/>} />
    <Route path='/React_es6' element={<ReactES6/>} />
    <Route path='/React_es6-modules' element={<ReactES6modules/>} />
    <Route path='/React_es6-arrow-functioning' element={<ReactES6arrowfunctions/>} />
    <Route path='/React_es6-destructuring' element={<ReactES6destructuring/>} />
    <Route path='/React_es6-template-literals' element={<ReactES6template/>} />
    <Route path='/React_es6-classes' element={<ReactES6classes/>} />
    <Route path='/React_es6-spread' element={<ReactES6spread/>} />
    <Route path='/React_es6-rest' element={<ReactES6rest/>} />
    <Route path='/React_jsx' element={<ReactJSX/>} />
    <Route path='/React_components' element={<ReactComponents/>} />
    <Route path='/React_state' element={<ReactState/>} />
    <Route path='/React_props' element={<ReactProps/>} />
    <Route path='/React_event' element={<ReactEvent/>} />
    <Route path='/React_conditional' element={<ReactConditional/>} />
    <Route path='/React_lists' element={<ReactLists/>} />
    <Route path='/React_keys' element={<ReactKeys/>} />
    <Route path='/React_forms' element={<ReactForms/>} />
    <Route path='/React_router' element={<ReactRouter/>} />



    <Route path='/Python_Tutorial_intro' element={<Pythonintro />} />
    <Route path='/Python_installing' element={<Pythoninstalling/>} />
    <Route path='/Python_writing_code' element={<PythonWritingCode/>} />
    <Route path='/Python_display_output' element={<PythonDisplayOutput/>} />
    <Route path='/Python_syntax' element={<PythonSyntax/>} />
    <Route path='/Python_comments' element={<PythonComments/>} />
    <Route path='/Python_variables' element={<PythonVariables/>} />
    <Route path='/Python_variables_naming' element={<PythonVariablesNaming/>} />
    <Route path='/Python_variables_assigning' element={<PythonVariablesAssigning/>} />
    <Route path='/Python_variables_types' element={<PythonVariablesTypes/>} />
    <Route path='/Python_variables_conversion' element={<PythonVariablesConversion/>} />
    <Route path='/Python_data_types' element={<PythonDataTypes/>} />
    <Route path='/Python_type_conversion' element={<PythonTypeConversion/>} />
    <Route path='/Python_input' element={<PythonInput/>} />
    <Route path='/Python_operators' element={<PythonOperators/>} />
    <Route path='/Python_conditionals' element={<PythonConditionals/>} />
    <Route path='/Python_loops' element={<PythonLoops/>} />
    <Route path='/Python_functions' element={<PythonFunctions/>} />
    <Route path='/Python_lambda' element={<PythonLambda/>} />
    <Route path='/Python_arrays' element={<PythonArrays/>} />
    <Route path='/Python_lists' element={<PythonLists/>} />
    <Route path='/Python_list_methods' element={<PythonListMethods/>} />
    <Route path='/Python_list_comprehensions' element={<PythonListComprehensions/>} />
    <Route path='/Python_tuples' element={<PythonTuples/>} />
    <Route path='/Python_tuple_methods' element={<PythonTupleMethods/>} />
    <Route path='/Python_tuple_unpacking' element={<PythonTupleUnpacking/>} />
    <Route path='/Python_tuple_concatenation' element={<PythonTupleConcatenation/>} />
    <Route path='/Python_sets' element={<PythonSets/>} />
    <Route path='/Python_set_methods' element={<PythonSetMethods/>} />
    <Route path='/Python_set_comprehensions' element={<PythonSetComprehensions/>} />
    <Route path='/Python_set_operations' element={<PythonSetOperations/>} />
    <Route path='/Python_set_methods' element={<PythonSetMethods/>} />
    <Route path='/Python_set_operations' element={<PythonSetOperations/>} />
    <Route path='/Python_dictionaries' element={<PythonDictionaries/>} />
    <Route path='/Python_dictionary_methods' element={<PythonDictionaryMethods/>} />
    <Route path='/Python_dictionary_comprehensions' element={<PythonDictionaryComprehensions/>} />
    <Route path='/Python_strings' element={<PythonStrings/>} />
    <Route path='/Python_date-time' element={<PythonDateTime/>} />



    <Route path='/XML_Tutorial_intro' element={<XMLintro />} />
    <Route path='/XML_syntax' element={<XMLSyntax/>} />
    <Route path='/XML_tree' element={<XMLTree/>} />
    <Route path='/XML_elements' element={<XMLElements/>} />
    <Route path='/XML_attributes' element={<XMLAttributes/>} />
    <Route path='/XML_declaration' element={<XMLDeclaration/>} />
    <Route path='/XML_comments' element={<XMLComments/>} />
    <Route path='/XML_versioning' element={<XMLVersioning/>} />
    <Route path='/XML_encoding' element={<XMLEncoding/>} />
    <Route path='/XML_data_manipulation' element={<XMLDataManipulation/>} />
    <Route path='/XML_data_insertion' element={<XMLDataInsertion/>} />
    <Route path='/XML_data_modification' element={<XMLDataModification/>} />
    <Route path='/XML_data_deletion' element={<XMLDataDeletion/>} />
    <Route path='/XML_data_querying' element={<XMLDataQuerying/>} />
    <Route path='/XML_data_transformation' element={<XMLDataTransformation/>} />
    <Route path='/XML_xpath' element={<XMLXPath/>} />
    <Route path='/XML_xpath_expressions' element={<XMLXPathExpressions/>} />
    <Route path='/XML_xpath_functions' element={<XMLXPathFunctions/>} />
    <Route path='/XML_xpath_axes' element={<XMLXPathAxes/>} />
    <Route path='/XML_xquery' element={<XMLXQuery/>} />
    <Route path='/XML_xquery_expressions' element={<XMLXQueryExpressions/>} />
    <Route path='/XML_xquery_functions' element={<XMLXqueryFunctions/>} />
    <Route path='/XML_xquery_axes' element={<XMLXqueryAxes/>} />
    <Route path='/XML_data_binding' element={<XMLDataBinding/>} />
    <Route path='/XML_serialization' element={<XMLSerialization/>} />
    <Route path='/XML_deserialization' element={<XMLDeserialization/>} />
    <Route path='/XML_ajax' element={<XMLAjax/>} />
    <Route path='/XML_ajax_xmlhttp' element={<XMLAjaxXMLHttp/>} />
    <Route path='/XML_ajax_requests' element={<XMLAjaxRequests/>} />
    <Route path='/XML_ajax_responses' element={<XMLAjaxResponses/>} />
    <Route path='/XML_ajax_parsing' element={<XMLAjaxParsing/>} />
    <Route path='/XML_ajax_php' element={<XMLAjaxPHP/>} />
    <Route path='/XML_ajax_applications' element={<XMLAjaxApplications/>} />
    <Route path='/XML_dom' element={<XMLDOM/>} />
    <Route path='/XML_html5' element={<XMLHTML5/>} />





    <Route path='/SQL_Tutorial_intro' element={<SQLintro />} />
    <Route path='/SQL_queries' element={<SQLQueries/>} />
    <Route path='/SQL_comments' element={<SQLComments/>} />
    <Route path='/SQL_select' element={<SQLSelect/>} />
    <Route path='/SQL_insert' element={<SQLInsert/>} />
    <Route path='/SQL_update' element={<SQLUpdate/>} />
    <Route path='/SQL_delete' element={<SQLDelete/>} />
    <Route path='/SQL_data_types' element={<SQLDataTypes/>} />
    <Route path='/SQL_aggregate_functions' element={<SQLAggregateFunctions/>} />
    <Route path='/SQL_string_functions' element={<SQLStringFunctions/>} />
    <Route path='/SQL_date-time_functions' element={<SQLDateTimeFunctions/>} />
    <Route path='/SQL_nested' element={<SQLNestedQueries/>} />
    <Route path='/SQL_correlated' element={<SQLCorrelatedQueries/>} />
    <Route path='/SQL_ctes' element={<SQLCTEs/>} />
    <Route path='/SQL_joins' element={<SQLJoins/>} />
    <Route path='/SQL_inner_join' element={<SQLInnerJoin/>} />
    <Route path='/SQL_left_join' element={<SQLLeftJoin/>} />
    <Route path='/SQL_right_join' element={<SQLRightJoin/>} />
    <Route path='/SQL_full_outer_join' element={<SQLFullOuterJoin/>} />
    <Route path='/SQL_cross_join' element={<SQLCrossJoin/>} />
    <Route path='/SQL_views' element={<SQLViews/>} />
    <Route path='/SQL_creating_views' element={<SQLCreatingViews/>} />
    <Route path='/SQL_managing_views' element={<SQLManagingViews/>} />
    <Route path='/SQL_view_optimization' element={<SQLViewOptimization/>} />
    <Route path='/SQL_indexes' element={<SQLIndexes/>} />





    <Route path='/AI_Tutorial_intro' element={<AIintro />} />
    <Route path='/AI_history' element={<AIhistory/>} />
    <Route path='/AI_types' element={<AITypes/>} />
    <Route path='/AI_problem_solving' element={<AIProlemSolving/>} />
    <Route path='/AI_machine_learning' element={<AIMachineLearning/>} />
    <Route path='/AI_supervised_learning' element={<AISupervisedLearning/>} />
    <Route path='/AI_unsupervised_learning' element={<AIUnsupervisedLearning/>} />
    <Route path='/AI_reinforcement_learning' element={<AIReinforcementLearning/>} />
    <Route path='/AI_neural_networks' element={<AINeuralNetworks/>} />
    <Route path='/AI_deep_learning' element={<AIDeepLearning/>} />
    <Route path='/AI_cnns' element={<AICNNs/>} />
    <Route path='/AI_rnns' element={<AIRNNs/>} />
    <Route path='/AI_lstmns' element={<AILSTMNs/>} />
    <Route path='/AI_gans' element={<AIGANs/>} />
    <Route path='/AI_nlp' element={<AINLP/>} />
    <Route path='/AI_text_preprocessing' element={<AITextPreprocessing/>} />
    <Route path='/AI_sentiment_analysis' element={<AISentimentAnalysis/>} />
    <Route path='/AI_ner' element={<AINamedEntityRecognition/>} />
    <Route path='/AI_language_models' element={<AIlanguageModels/>} />
    <Route path='/AI_computer_vision' element={<AIComputerVision/>} />
    <Route path='/AI_image_processing' element={<AIlanguageModels/>} />
    <Route path='/AI_object_detection' element={<AIObjectDetection/>} />
    <Route path='/AI_image_segmentation' element={<AIImageSegmentation/>} />
    <Route path='/AI_facial_recognition' element={<AIFacialRecognition/>} />
    <Route path='/AI_robotics' element={<AIRobotics/>} />
    <Route path='/AI_robotics_hardware' element={<AIRoboticsHardware/>} />
    <Route path='/AI_robotics_software' element={<AIRoboticsSoftware/>} />
    <Route path='/AI_robotics_applications' element={<AIRoboticsApplications/>} />
    <Route path='/AI_ethics' element={<AIEthics/>} />





    <Route path='/PHP_Tutorial_intro' element={<PHPintro />} />
    <Route path='/PHP_environment_setup' element={<PHPEnvironmentSetup/>} />
    <Route path='/PHP_syntax' element={<PHPSyntax/>} />
    <Route path='/PHP_comments' element={<PHPComments/>} />
    <Route path='/PHP_variables' element={<PHPVariables/>} />
    <Route path='/PHP_datatypes' element={<PHPDataTypes/>} />
    <Route path='/PHP_echo' element={<PHPEcho/>} />
    <Route path='/PHP_print' element={<PHPPrint/>} />
    <Route path='/PHP_operators' element={<PHPOperators/>} />
    <Route path='/PHP_arithmetic' element={<PHPArithmetic/>} />
    <Route path='/PHP_assignment' element={<PHPAssignment/>} />
    <Route path='/PHP_comparison' element={<PHPComparison/>} />
    <Route path='/PHP_logical' element={<PHPLogical/>} />
    <Route path='/PHP_increment' element={<PHPIncrement/>} />
    <Route path='/PHP_decrement' element={<PHPDecrement/>} />
    <Route path='/PHP_string' element={<PHPString/>} />
    <Route path='/PHP_array' element={<PHPArray/>} />
    <Route path='/PHP_conditional' element={<PHPConditional />} />
    <Route path='/PHP_loops' element={<PHPLoops/>} />
    <Route path='/PHP_switch' element={<PHPSwitch/>} />
    <Route path='/PHP_functions' element={<PHPfunctions/>} />


    <Route path='/manifest.json' element={<Null/>} />
    <Route path='/sitemap.xml' element={<Null/>} />
    <Route path='*' element={<Null/>} />
    </Routes>
    </TrackProgress>
    </Router>
    </ProgressProvider>
  );
}