import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuthStatus } from '../localstorage';
import ScrollToTopLink from './ScrollToTop';
import Logoutbuttonlight from './Logoutbuttonlight';
import Signupbuttonlight from './Signupbuttonlight';
import Loginbuttonlight from './Loginbuttonlight';
import TechNewsTicker from './TechNewsTicker';
import { FaMoon, FaSun } from 'react-icons/fa';
import '../css/my style.css';
import '../css/my style3.css';
import '../css/special styles.css';
import Forumbuttonlight from './Forumbuttonlight';

function Navbar() {
  // Check authentication status
  const isAuthenticated = getAuthStatus();

  // State for theme toggle
  const [darkTheme, setDarkTheme] = useState(false);

  // Function to toggle theme
  const toggleTheme = () => {
    const newTheme = !darkTheme;
    setDarkTheme(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
    applyTheme(newTheme);
  };

  // Function to apply theme
  const applyTheme = (isDarkTheme) => {
  
    const contentElement = document.querySelector('.content');
    const divElement = document.querySelector('.divsample');
    const highlighterElement = document.querySelector('.sidebar');
    const headerElement = document.querySelector('.headertutorials');
    const xamplingElement = document.querySelector('.xampling');
    const mainthemeElement = document.querySelector('.main-theme');
    const navButtonElement = document.querySelector('.nav-button');
    const messageElement = document.querySelector('.message-container');
    const incomingMsgElement = document.querySelector('.incomingMsgText');
    const typeMsgElement = document.querySelector('.typeMsgText');

    if (contentElement) {
      contentElement.classList.toggle('dark-theme', isDarkTheme);
      contentElement.classList.toggle('light-theme', !isDarkTheme);
    }

    if (xamplingElement) {
      xamplingElement.classList.toggle('dark-theme', isDarkTheme);
      xamplingElement.classList.toggle('light-theme', !isDarkTheme);
    }

    if (mainthemeElement) {
      mainthemeElement.classList.toggle('dark-theme', isDarkTheme);
      mainthemeElement.classList.toggle('light-theme', !isDarkTheme);
    }


    if (divElement) {
      divElement.classList.toggle('dark-theme', isDarkTheme);
      divElement.classList.toggle('light-theme', !isDarkTheme);
    }

    if (highlighterElement) {
      highlighterElement.classList.toggle('dark-theme', isDarkTheme);
      highlighterElement.classList.toggle('light-theme', !isDarkTheme);
    }

    if (headerElement) {
      headerElement.classList.toggle('dark-theme', isDarkTheme);
      headerElement.classList.toggle('light-theme', !isDarkTheme);
    }

    if (navButtonElement) {
      navButtonElement.classList.toggle('dark-theme', isDarkTheme);
      navButtonElement.classList.toggle('light-theme', !isDarkTheme);
    }

    if (messageElement) {
      messageElement.classList.toggle('dark-theme', isDarkTheme);
      messageElement.classList.toggle('light-theme', !isDarkTheme);
    }
    if (incomingMsgElement) {
      incomingMsgElement.classList.toggle('dark-theme', isDarkTheme);
      incomingMsgElement.classList.toggle('light-theme', !isDarkTheme);
    }
    if (typeMsgElement) {
      typeMsgElement.classList.toggle('dark-theme', isDarkTheme);
      typeMsgElement.classList.toggle('light-theme', !isDarkTheme);
    }
  };

  // Effect to initialize theme
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    const initialTheme = savedTheme === 'dark';
    setDarkTheme(initialTheme);
    applyTheme(initialTheme);
  }, []);

  const betaLogo = {
    maxWidth: '100%',
    height: '90px',
    cursor: 'pointer',
  };

  return (
    <nav>
      <ul>
        <li>
          <ScrollToTopLink to="/">
            <img src={require('../image/beta thread.svg').default} title="Home" alt="BetaThread's Logo" style={betaLogo} />
          </ScrollToTopLink>
        </li>

        {/* Theme Toggle Button */}
        <li>
          <button onClick={toggleTheme} title='Change Theme' style={{ padding: '16px', margin: '20.5px', height: '40px', background: 'none', border: 'none', cursor: 'pointer', color: 'white' }}>
            {darkTheme ? <FaSun /> : <FaMoon />}
          </button>
        </li>

        <li>
            <a href='https://forum.betathread.com' target='_blank'>
              <Forumbuttonlight />
            </a>
          </li>

        {/* Conditionally render Logout or Login/Signup buttons */}
        {isAuthenticated ? (
          <li>
            <ScrollToTopLink>
              <Logoutbuttonlight />
            </ScrollToTopLink>
          </li>
        ) : (
          <>
            <li>
              <ScrollToTopLink to="/login">
                <Loginbuttonlight />
              </ScrollToTopLink>
            </li>
          </>
        )}

        <li>
          <ScrollToTopLink to="/signup">
            <Signupbuttonlight />
          </ScrollToTopLink>
        </li>

        
      </ul>
    </nav>
  );
}

export default Navbar;
