import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPDecrement() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    $counter = 5;
    echo $counter++; // Output: 5 (original value of $counter)
    echo $counter;   // Output: 6 (incremented value)
          `;
    const code2 = `
    $counter = 5;
    echo ++$counter; // Output: 6 (incremented value)
    echo $counter;   // Output: 6 (incremented value)
         `;
 
  
    return (
   <body>
      <Helmet>
        <title>PHP Decrement Operators</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Operators, PHP Decrement Operators, Post-decrement Operator, Pre-decrement Operator,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="PHP decrement operators are used to decrease the value of a variable by one. It makes use of the '--' character at the beginning or the end of the variable." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_increment"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_string"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Decrement Operators</h1>
</header>

<Navbar />

<main>
<section>
<p>PHP decrement operators are used to decrease the value of a variable by one.</p>
<p>It makes use of the '--' character at the beginning or the end of the variable.</p>
<p>These operators are quite handy in scenarios where you need to perform such operations on a variable quickly.</p>
<p>There are two types of PHP increment operators:</p>
<ul>
    <li>Post-decrement ('$variable--')</li>
    <li>Pre-decrement ('--$variable')</li>
</ul>
<h2>Post-decrement ($variable--)</h2>
<p>The post-decrement operator '--' decreases the value of the variable by one after the current value is used in the expression.</p>
<p>It is called 'post' because it appears after the variable.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="php" />

<h2> Pre-decrement (--$variable)</h2>
<p>The pre-decrement operator '--$variable' decreases the value of the variable by one before the current value is used in the expression.</p>
<p>It is called 'pre' because it appears before the variable.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="php" />

<p>These operators can be particularly useful in loops or when you need to perform iterative operations on variables.</p>
</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_increment"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_string"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}