import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPArray() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    $array1 = ['a' => 1, 'b' => 2];
    $array2 = ['b' => 3, 'c' => 4];
    
    $result = $array1 + $array2;
    print_r($result); // Output: Array ( [a] => 1 [b] => 2 [c] => 4 )
                `;
    const code2 = `
    $array1 = ['a' => 1, 'b' => 2];
    $array2 = ['b' => 2, 'a' => 1];
    
    if ($array1 == $array2) {
        echo "Arrays are equal";
    } else {
        echo "Arrays are not equal";
    }
    // Output: Arrays are equal
               `;
    const code3 = `
    $array1 = ['a' => 1, 'b' => 2];
    $array2 = ['a' => 1, 'b' => 2];
    
    if ($array1 === $array2) {
        echo "Arrays are identical";
    } else {
        echo "Arrays are not identical";
    }
    // Output: Arrays are identical
               `;     
    const code4 = `
    $array1 = ['a' => 1, 'b' => 2];
    $array2 = ['b' => 2, 'a' => 1];
    
    if ($array1 != $array2) {
        echo "Arrays are not equal";
    } else {
        echo "Arrays are equal";
    }
    // Output: Arrays are not equal
               `;
    const code5 = `
    $array1 = ['a' => 1, 'b' => 2];
    $array2 = ['a' => 1, 'b' => 2];
    
    if ($array1 !== $array2) {
        echo "Arrays are not identical";
    } else {
        echo "Arrays are identical";
    }
    // Output: Arrays are not identical
               `;
 
   
    return (
   <body>
      <Helmet>
        <title>PHP Array Operators</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Operators, PHP Array Operators, Union Operator, Equality Operator, Identity Operator, Inequality Operator, Non-Identity Operator,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="PHP decrement operators are used to decrease the value of a variable by one. It makes use of the '--' character at the beginning or the end of the variable." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_string"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_conditional"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Array Operators</h1>
</header>

<Navbar />

<main>
<section>
<p>In PHP, arrays are a fundamental data structure that allows you to store multiple values under a single variable name.</p>
<p> PHP provides several operators to manipulate arrays efficiently.</p>
<p> Let's explore each of these operators along with code examples:</p>

<h2>Union Operator ( + )</h2>
<p>The union operator combines two arrays into one, preserving the keys of the original arrays.</p>
<p>If there are duplicate keys, the values from the left operand will be preserved.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="php" />

<h2>Equality Operator ( == )</h2>
<p>The equality operator compares two arrays to check if they have the same key/value pairs.</p>
<p>It disregards the order of elements in the arrays.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="php" />

<h2>Identity Operator ( === )</h2>
<p>The identity operator checks if two arrays are identical, meaning they have the same key/value pairs in the same order.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code3} language="php" />

<h2>Inequality Operator ( != )</h2>
<p>The inequality operator checks if two arrays are not equal, disregarding the order of elements.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code4} language="php" />

<h2>Non-Identity Operator ( !== )</h2>
<p>The non-identity operator checks if two arrays are not identical, meaning they do not have the same key/value pairs in the same order.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code5} language="php" />

<p>These operators provide flexibility in comparing and manipulating arrays in PHP, allowing you to perform various tasks efficiently.</p>
<p>Understanding their differences is crucial for writing robust and reliable PHP code.</p>
</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_string"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_conditional"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}