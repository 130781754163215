 export const badWords = [
    
    "ass",
    "bastard",
    "bitch",
    "bloody",
    "bugger",
    "cock",
    "damn",
    "dick",
    "f**k", // Added "f**k" (or "fuck")
    "hell ",
    "shit",
    "slut",
    "twat",
    "whore",
    "wanker",
    "prick",
    "mugu ",
    "olodo ",
    "idiot",
    "transgender",
    "asshole",
    "bollocks",
    "bitchy",
    "blowjob",
    "bones",
    "boner",
    "boob",
    "boobies",
    "booze",
    "bukkake",
    "bullshit",
    "butt",
    "buttocks",
    "c**t", // Added "c**t" (or "cunt")
    "cameltoe",
    "cocksucker",
    "cocktail",
    "cooch",
    "crack",
    "crap",
    "cunt", // Added "cunt"
    "damnation",
    "dickhead",
    "dildo",
    "donkey",
    "douchebag",
    "dummy",
    "fag",
    "faggot",
    "fanny",
    "fart",
    "farting",
    "fellatio",
    "felching",
    "fisting",
    "flasher",
    "flirt",
    "fornicate",
    "fuck", // Added "fuck"
    "fucker",
    "fuckwit",
    "freak",
    "sucker",
    "fudgepacker",
    "fudgepacking",
    "gangbang",
    "gbola",
    "genital",
    "genitals",
    "golden shower",
    "groin",
    "handjob",
    "hardcore",
    "henhouse",
    "yourfather",
    "yourmother",
    "yourchildren",
    "yourpikin",
    "yourpapa",
    "yourmama",
    "your father",
    "your mother",
    "your papa",
    "your mama",
    "your pikin",
    "your children",
    "mugu",
    "idiot",
    "fool",
    "useless",
    "mad",
    "stupid",
    "thunder destroy you",
    "thunder fire you",
    "hoe ",
    "homo ",
    "homosexual",
    "hump",
    "humping",
    "jackoff",
    "jerkoff",
    "jism",
    "jizz",
    "kike",
    "kissing",
    "knee-jerk",
    "lady parts",
    "lady garden",
    "lady bits",
    "lady bits",
    "assholey",
    "balls",
    "ballsack",
    "ballsacking",
    "bareback",
    "barebacking",
    "bitchiness",
    "blow",
    "blowjobbing",
    "bollocking",
    "bonnet",
    "boobage",
    "boozehound",
    "bukkaker",
    "bull",
    "bullcrap",
    "bullshit",
    "bullshitter",
    "bullshitting",
    "bunghole",
    "bungholing",
    "buttfuck",
    "buttfucker",
    "buttfucking",
    "butt-naked",
    "butt-fuck",
    "butt-fucker",
    "butt-fucking",
    "butt-fucked",
    "butt-fucking",
    "camel toe",
    "candy ass",
    "carpet munching",
    "cocks",
    "cock-sucker",
    "cock-sucking",
    "cock-sucked",
    "cock-suckers",
    "cock-sucking",
    
];