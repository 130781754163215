import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIlanguageModels() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const languageModelExample = `
# Example of Using a Pre-trained Language Model with Hugging Face Transformers
from transformers import pipeline

# Initialize the language model pipeline
generator = pipeline("text-generation", model="gpt-2")

# Generate text
result = generator("The future of AI is", max_length=50)

# Output generated text
print(result)
  `;

  return (
    <body>
      <Helmet>
        <title>AI Language Models</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Language Models, Natural Language Processing, AI Tutorial, Language Models with Transformers" />
        <meta name="description" content="Learn about AI language models, their applications, and practical examples using frameworks like Hugging Face Transformers." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/AI_ner"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/AI_computer_vision"><ButtonNext /></ScrollToTopLink>
          <h1>AI Language Models</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Language models are a type of artificial intelligence model designed to understand and generate human language. These models are fundamental to many natural language processing (NLP) applications, including text generation, translation, summarization, and more.</p>

            <h2>Introduction to Language Models</h2>
            <p>Language models work by predicting the next word in a sentence given the previous words, learning patterns and structures from large amounts of text data. Popular language models include GPT (Generative Pre-trained Transformer) by OpenAI, BERT (Bidirectional Encoder Representations from Transformers) by Google, and T5 (Text-to-Text Transfer Transformer).</p>

            <h2>Example: Using a Pre-trained Language Model with Hugging Face Transformers</h2>
            <SyntaxHighlighterComponent code={languageModelExample} language="python" />
            <p>In this example, we use the Hugging Face Transformers library to generate text with a pre-trained language model (GPT-2). Hugging Face provides a wide range of pre-trained models for various NLP tasks.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_ner"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_computer_vision"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
