import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactProps() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    import React from 'react';

    const Greeting = (props) => {
      return <h1>Hello, {props.name}!</h1>;
    };
    
    const App = () => {
      return (
        <div>
          <Greeting name="John Doe" />
        </div>
      );
    };`

    const code2=`
    import React from 'react';
    import PropTypes from 'prop-types';
    
    const Greeting = (props) => {
      return <h1>Hello, {props.name}!</h1>;
    };
    
    Greeting.propTypes = {
      name: PropTypes.string.isRequired
    };    `
  
     const code3=`
     import React from 'react';

     const ParentComponent = () => {
       const name = 'John Doe';
       return (
         <div>
           <ChildComponent name={name} />
         </div>
       );
     };
     
     const ChildComponent = (props) => {
       return <h1>Hello, {props.name}!</h1>;
     };    `

         const code4=`
         import React from 'react';

         const Greeting = (props) => {
           return <h1>Hello, {props.name}!</h1>;
         };
         
         const App = () => {
           return (
             <div>
               <Greeting name="John Doe" />
             </div>
           );
         };         `
        const code5=`
        import React, { Component } from 'react';

        class Counter extends Component {
          constructor(props) {
            super(props);
            this.state = { count: 0 };
          }
        
          render() {
            return (
              <div>
                <p>Count: {this.state.count}</p>
                <button onClick={() => this.setState({ count: this.state.count + 1 })}>
                  Increment
                </button>
              </div>
            );
          }
        }
        
        const App = () => {
          return (
            <div>
              <Counter />
            </div>
          );
        };        `
        
    return (
   <body>
     <Helmet>
        
        <title>React Props</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React Props, What are Props?, Why Do We Need Props?, Types of Props, Required Props, Optional Props, Props Validation, Conclusion,
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="Props are short for properties and are how you pass data from a parent component to a child component." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_state"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_event"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React Props</h1>
</header>

<Navbar />

<main>
<section>
    <h1>What are Props?</h1>
<p>Props are short for "properties" and are how you pass data from a parent component to a child component.</p>
  <p>Props are read-only and cannot be changed by the child component.</p>

<h2>Why Do We Need Props?</h2>
  <p>We need props because React components are reusable pieces of code, and we want to be able to customize them for different situations.</p>
  <p> Props allow us to pass data to a component and have it render differently based on that data.</p>

<h2>Types of Props</h2>
<p>There are two main types of props:</p>
<ol type="1">
    <li><b>Required Props:</b>These are props that must be passed to a component in order for it to render correctly.</li>
    <li><b>Optional Props:</b>These are props that can be passed to a component, but are not required for it to render correctly.</li>
</ol>

<h2>Example</h2>
 <SyntaxHighlighterComponent code={code} language="js" />
<p>In this example, the Greeting component expects a name prop, which is passed to it by the App component.</p>

 <h2>Props Validation</h2>
 <p>React provides a way to validate props using the PropTypes library. This ensures that the props passed to a component are of the correct type and format.</p>
 <SyntaxHighlighterComponent code={code2} language="js" />
<p>In this example, the Greeting component expects a name prop that is a string and is required.</p>

<h2>Conclusion</h2>
<p>Props are a fundamental part of React and allow us to pass data from a parent component to a child component. </p>
<p>By using props, we can make our components reusable and customizable.</p>
<p>Remember to use PropTypes to validate your props and ensure that they are of the correct type and format.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_state"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_event"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}