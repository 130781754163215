import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactKeys() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    import React from 'react';

    const items = [
      { id: 1, name: 'Item 1' },
      { id: 2, name: 'Item 2' },
      { id: 3, name: 'Item 3' },
    ];
    
    constItemList = () => {
      return (
        <ul>
          {items.map((item) => (
            <li key={items.id}>{item.name}</li>
          ))}
        </ul>
      );
    }; `

    const code2=`
    import React from 'react';

    const users = [
      { id: 1, name: 'John Doe', age: 30 },
      { id: 2, name: 'Jane Doe', age: 25 },
      { id: 3, name: 'Bob Smith', age: 40 },
    ];
    
    constUserList = () => {
      return (
        <ul>
          {users.map((user) => (
            <li key={items.id}>
              <h2>{user.name}</h2>
              <p>Age: {user.age}</p>
            </li>
          ))}
        </ul>
      );
    }; `
  
     const code3=`
     import React from 'react';

     const users = [
       { name: 'John Doe', age: 30 },
       { name: 'Jane Doe', age: 25 },
       { name: 'Bob Smith', age: 40 },
     ];
     
     const UserList = () => {
       return (
         <ul>
           {users.map((user, index) => (
             <li key={index}>
               <h2>{user.name}</h2>
               <p>Age: {user.age}</p>
             </li>
           ))}
         </ul>
       );
     };  `

      
        
    return (
   <body>
     <Helmet>
        
        <title>React Keys</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React Keys, Why Use React Keys?, Displaying a List of Items, Displaying a List of Users
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="React Keys are a unique identifier for each item in a list. They help React keep track of the items and improve performance." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_lists"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_forms"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React Keys</h1>
</header>

<Navbar />

<main>
<section>
   
<p>React Keys are a unique identifier for each item in a list. They help React keep track of the items and improve performance.</p>
 <h2>Why Use React Keys?</h2>
 <ul>
    <li>Improve performance by reducing unnecessary re-renders</li>
    <li>Prevent errors caused by duplicate keys</li>
 </ul>
 <h2>Displaying a List of Items</h2>
 <p>Suppose we have a list of items and we want to display them in a list. We can use React Keys to identify each item uniquely.</p>
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="js" />
<p>In this example, we use the id property of each item as the React Key.</p>



<h2>Displaying a List of Users</h2>
<p>Suppose we have a list of users and we want to display their names and ages. We can use React Keys to identify each user uniquely.</p>
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="js" />
<p>In this example, we use the id property of each user as the React Key.</p>
 
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_lists"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_forms"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}