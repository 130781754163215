import React, { useEffect, useState } from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../css/special styles.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import { getAuthStatus, saveAuthStatus } from "../../localstorage";
import DemoProgress from "../../components/Tutorial Progress/DemoProgress";
import ButtonHome from "../../components/Buttonhome";



export default function Pythonintro() {

   
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    x = 10
    y = 20
    add = 10 + 20
    print(add)
    `;
   
    return (
   <body>
    <Helmet>
     <title>Python Tutorial</title>
     <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Introduction to Python, What is Python, Python Requirement, Python Latest Version
     Step-by-step Python programming tutorials, Python coding exercises for beginners, Advanced Python coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       Python programming tips for real-world applications." />
   <meta name="description" content="Explore the power of Python on our Python tutorial page. Learn how to use Python to streamline code organization, enhance code reusability,
    and unlock advanced features in Python development. Discover practical examples and best practices to elevate your programming skills. Dive into the world of Python with our concise and informative guide" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/Python_installing"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>Python Tutorial - Preamble</h1>
</header>

<Navbar />

<main>
<section>
<h2>What is Python?</h2>
<p>Python is a versatile and readable programming language.</p>
<p>It is one of the most popular programming languages.</p>
<p>Python was created by Guido van Rossum and the first time it was implemented was in 1989.</p>
 <p>Python can be used in various areas such as data science, sever-side scripting, web 
    development, software engineering, machine learning and so much more.
 </p>
 <h2>Python Requirement</h2>
 <p>There are some requirements you have to meet before learning python, they include:</p>
 <li>knowledge of installing a software</li>
 <li>A python compiler</li>
 <li>Basic computer literacy</li>
<h2>Python Latest Version</h2>
<p>The latest version of python is Python 3. and what will be taught in this tutorial will be based on it.</p>

<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code} language="python" />

</section>

<div className="second-div">
 
     <section className="features">
     <div className="progress-sample">
    
         <img src={require('../../image/beta-progress.svg').default} width={500} height={500}  alt='betathread' />
       <p style={{fontSize: '15px'}}> Our Progress Tracker is designed to help you monitor your progress through our extensive range of tutorials.</p>
       <ScrollToTopLink to="/progress-tracker"><button type="submit" className="buttonb">Track Progress</button></ScrollToTopLink>
   
     </div>

     <div className="progress-sampling">
     <h1 style={{fontSize: '45px'}}>Track Your Progress</h1>
     <DemoProgress />
     </div>
   </section>
  </div>
</main>
<div className="head">
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/Python_installing"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}