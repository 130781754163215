import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPComparison() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    $a = 5;
    $b = "5";
    
    if ($a == $b) {
        echo "Equal";
    } else {
        echo "Not Equal";
    }
    // Output: Equal
    
    `;
    const code2 = `
    $a = 5;
    $b = "5";
    
    if ($a === $b) {
        echo "Identical";
    } else {
        echo "Not Identical";
    }
    // Output: Not Identical
             `;
    const code3 = `
    $a = 5;
    $b = 10;
    
    if ($a != $b) {
        echo "Not Equal";
    } else {
        echo "Equal";
    }
    // Output: Not Equal
             `;

    const code4 = `
    $a = 5;
    $b = "5";
    
    if ($a !== $b) {
        echo "Not Identical";
    } else {
        echo "Identical";
    }
    // Output: Identical
             `;

    const code5 = `
    $a = 10;
    $b = 5;
    
    if ($a > $b) {
        echo "$a is greater than $b";
    } else {
        echo "$a is not greater than $b";
    }
    // Output: 10 is greater than 5
                 `;

    const code6 = `
    $a = 5;
    $b = 10;
    
    if ($a < $b) {
        echo "$a is less than $b";
    } else {
        echo "$a is not less than $b";
    }
    // Output: 5 is less than 10
             `;

    const code7 = `
    $a = 10;
    $b = 10;
    
    if ($a >= $b) {
        echo "$a is greater than or equal to $b";
    } else {
        echo "$a is less than $b";
    }
    // Output: 10 is greater than or equal to 10
             `;

             const code8 = `
             $a = 5;
             $b = 10;
             
             if ($a <= $b) {
                 echo "$a is less than or equal to $b";
             } else {
                 echo "$a is greater than $b";
             }
             // Output: 5 is less than or equal to 10
                         `;

             
  
    return (
   <body>
      <Helmet>
        <title>PHP Comparison Operators</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Operators, PHP Comparison Operator, Equal, Identical, Not Equal, Non identical, Greater than, Less than, Greater than or equal to, Less than or equal to,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="PHP offers a variety of comparison operators that allow you to compare values and determine the relationship between them." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_assignment"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_logical"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Comparison Operators</h1>
</header>

<Navbar />

<main>
<section>
<p> PHP offers a variety of comparison operators that allow you to compare values and determine the relationship between them. </p>
<p> Let's go through each operator and provide examples to illustrate their usage:</p>

<h2>Equal (==)</h2>
<p>This operator checks if two values are equal, regardless of their data types.</p>
<p>It returns 'true' if both values are equal, otherwise 'false'.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="php" />

<h2>Identical (===)</h2>
<p>Similar to the equal operator, but it also checks if the data types of the two values are the same.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="php" />


<h2>Not equal (!=)</h2>
<p>This operator checks if two values are not equal to each other. It returns 'true' if the values are not equal, otherwise 'false'.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code3} language="php" />


<h2>Not identical (!==)</h2>
<p>Similar to the not equal operator, but it also checks if the data types of the two values are not the same.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code4} language="php" />


<h2>Greater than (&gt;)</h2>
<p>This operator checks if the left operand is greater than the right operand.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code5} language="php" />


<h2>Less than (&lt;)</h2>
<p>This operator checks if the left operand is less than the right operand.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code6} language="php" />

<h2>Greater than or equal to (&gt;=)</h2>
<p>This operator checks if the left operand is greater than or equal to the right operand.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code7} language="php" />

<h2>Less than or equal to (&lt;=)</h2>
<p>This operator checks if the left operand is less than or equal to the right operand.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code8} language="php" />

<p>These comparison operators are fundamental in programming as they allow you to make decisions based on the relationships between values in your code.</p>
</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_assignment"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_logical"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}