import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLAjaxPHP() {

    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
      };

  const code1 = `
<?php
header("Content-Type: application/xml; charset=UTF-8");
echo '<?xml version="1.0" encoding="UTF-8"?>';
echo '<response>';
echo '<message>Hello, this is an XML response from PHP!</message>';
echo '</response>';
?>`;

  const code2 = `
const xhttp = new XMLHttpRequest();
xhttp.onreadystatechange = function() {
  if (this.readyState === 4 && this.status === 200) {
    console.log(this.responseText);
  }
};
xhttp.open("GET", "path_to_your_php_script.php", true);
xhttp.send();`;

  const code3 = `
const parser = new DOMParser();
const xmlDoc = parser.parseFromString(responseText, "application/xml");
const message = xmlDoc.getElementsByTagName("message")[0].childNodes[0].nodeValue;
console.log("Message: " + message);`;

  return (
    <body>
      <Helmet>
        <title>XML AJAX - PHP</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML AJAX PHP, AJAX with XML, XML Tutorial, PHP AJAX Example" />
        <meta name="description" content="Learn how to handle XML AJAX requests using PHP and display XML responses in a React application." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br /><br /><br /><br /><br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/XML_ajax_parsing"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_ajax_applications"><ButtonNext /></ScrollToTopLink>
          <h1>XML AJAX - PHP</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XML AJAX with PHP involves using PHP to generate XML responses that are then fetched and parsed using AJAX in the client-side application. This approach is useful for dynamically updating web content based on server-side data.</p>

            <h2>PHP Script for Generating XML</h2>
            <p>The following PHP script generates an XML response:</p>
            <SyntaxHighlighterComponent code={code1} language="php" />

            <h2>AJAX Request to Fetch XML</h2>
            <p>Use JavaScript to send an AJAX request and handle the XML response:</p>
            <SyntaxHighlighterComponent code={code2} language="javascript" />

            <h2>Parsing the XML Response</h2>
            <p>Parse the XML response and extract data:</p>
            <SyntaxHighlighterComponent code={code3} language="javascript" />

            <h2>Explanation and Usage</h2>
            <p>XML AJAX PHP combines PHP server-side scripting with AJAX client-side requests to handle XML data interchange. Here’s how each part works:</p>

            <h3>PHP Script for XML Response</h3>
            <p>The PHP script sets the <code>Content-Type</code> header to specify XML. It then echoes XML-formatted data, such as messages or structured information, which the client-side JavaScript can fetch.</p>

            <h3>Client-side AJAX Request</h3>
            <p>JavaScript initiates an AJAX request using <code>XMLHttpRequest</code> (XHR). The <code>onreadystatechange</code> event listens for changes in the request state, such as when the data is fully loaded (<code>readyState === 4</code>) and the server status is OK (<code>status === 200</code>).</p>

            <h3>XML Parsing with DOMParser</h3>
            <p>Once the XML data is received, <code>DOMParser</code> in JavaScript parses the XML string into an XML document object. From there, you can navigate the XML structure using methods like <code>getElementsByTagName</code> to access specific elements and their content.</p>

            <h2>Benefits of XML AJAX with PHP</h2>
            <ul>
              <li>Facilitates real-time data updates without page refresh.</li>
              <li>Supports integration with XML-based APIs and services.</li>
              <li>Enables efficient handling of structured data from servers.</li>
            </ul>
          </section>
        </main>
        <div className="head">
        <ScrollToTopLink to="/XML_ajax_parsing"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_ajax_applications"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
