import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import toggle from "../responsivetopnav";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../ScrollToTop";
import { useLocation } from 'react-router-dom';
import PythonSearchBar from "../Searchbar/Python SearchBar/PythonSearchBar";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export default function Sidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  const toggleDropdown3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
  };

  const toggleDropdown4 = () => {
    setIsDropdownOpen4(!isDropdownOpen4);
  };

  const toggleDropdown5 = () => {
    setIsDropdownOpen5(!isDropdownOpen5);
  };
  var nav = {
    color: 'white',
    fontSize: '30px'
}
var newCode = {
  float: 'right'
}
return (
  <div>
    <div className={`sidebar ${isOpen ? 'responsive' : ''}`} id="mySidebar">
      <span className="close" onClick={toggle}>&times;</span>
      <h2>Python Tutorial</h2><br />
      <span><PythonSearchBar /></span><br />
      <p className="p-sidebar">...Empowering Your Online Presence...</p><br />
      <ScrollToTopLink to="/Python_Tutorial_intro"><a className={location.pathname === '/Python_Tutorial_intro' ? 'active' : ''}>Python Introduction</a></ScrollToTopLink><br />
      <ScrollToTopLink to="/Python_installing"><a className={location.pathname === '/Python_installing' ? 'active' : ''}>Python Installing</a></ScrollToTopLink><br />
      <ScrollToTopLink to="/Python_writing_code"><a className={location.pathname === '/Python_writing_code' ? 'active' : ''}>Python Writing Code</a></ScrollToTopLink><br />
      <ScrollToTopLink to="/Python_display_output"><a className={location.pathname === '/Python_display_output' ? 'active' : ''}>Python Display Output</a></ScrollToTopLink><br />
      <ScrollToTopLink to="/Python_syntax"><a className={location.pathname === '/Python_syntax' ? 'active' : ''}>Python Syntax</a></ScrollToTopLink><br />
      <ScrollToTopLink to="/Python_comments"><a className={location.pathname === '/Python_comments' ? 'active' : ''}>Python Comments</a></ScrollToTopLink><br />
      
      <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown}>
          Python Variables {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen && (
          <div className="dropdown-content">
            <ScrollToTopLink to="/Python_variables"><a className={location.pathname === '/Python_variables' ? 'active' : ''}>Python Variables</a></ScrollToTopLink><br />
            <ScrollToTopLink to="/Python_variables_naming"><a className={location.pathname === '/Python_variables_naming' ? 'active' : ''}>Variable Naming Rules</a></ScrollToTopLink><br />
            <ScrollToTopLink to="/Python_variables_assigning"><a className={location.pathname === '/Python_variables_assigning' ? 'active' : ''}>Assigning Values</a></ScrollToTopLink><br />
            <ScrollToTopLink to="/Python_variables_types"><a className={location.pathname === '/Python_variables_types' ? 'active' : ''}>Variable Types</a></ScrollToTopLink><br />
            <ScrollToTopLink to="/Python_variables_conversion"><a className={location.pathname === '/Python_variables_conversion' ? 'active' : ''}>Type Conversion</a></ScrollToTopLink><br />
          </div>
        )}
      </div>
      <ScrollToTopLink to="/Python_data_types"><a className={location.pathname === '/Python_data_types' ? 'active' : ''}>Python Data Types</a></ScrollToTopLink><br />
      <ScrollToTopLink to="/Python_type_conversion"><a className={location.pathname === '/Python_type_conversion' ? 'active' : ''}>Python Type Conversion</a></ScrollToTopLink><br />    
      <ScrollToTopLink to="/Python_input"><a className={location.pathname === '/Python_input' ? 'active' : ''}>Python Input</a></ScrollToTopLink><br />    
      <ScrollToTopLink to="/Python_operators"><a className={location.pathname === '/Python_operators' ? 'active' : ''}>Python Operators</a></ScrollToTopLink><br />    
      <ScrollToTopLink to="/Python_conditionals"><a className={location.pathname === '/Python_conditionals' ? 'active' : ''}>Python Conditional Statements</a></ScrollToTopLink><br />    
      <ScrollToTopLink to="/Python_loops"><a className={location.pathname === '/Python_loops' ? 'active' : ''}>Python Loops</a></ScrollToTopLink><br />    
      <ScrollToTopLink to="/Python_functions"><a className={location.pathname === '/Python_functions' ? 'active' : ''}>Python Functions</a></ScrollToTopLink><br />    
      <ScrollToTopLink to="/Python_lambda"><a className={location.pathname === '/Python_lambda' ? 'active' : ''}>Python Lambda Functions</a></ScrollToTopLink><br />    
      <ScrollToTopLink to="/Python_arrays"><a className={location.pathname === '/Python_arrays' ? 'active' : ''}>Python Arrays</a></ScrollToTopLink><br />    
      
      <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown2}>
          Python Lists {isDropdownOpen2 ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen2 && (
          <div className="dropdown-content">
             <ScrollToTopLink to="/Python_lists"><a className={location.pathname === '/Python_lists' ? 'active' : ''}>Python Lists</a></ScrollToTopLink><br />    
             <ScrollToTopLink to="/Python_list_methods"><a className={location.pathname === '/Python_list_methods' ? 'active' : ''}>List Methods</a></ScrollToTopLink><br />    
             <ScrollToTopLink to="/Python_list_comprehensions"><a className={location.pathname === '/Python_list_comprehensions' ? 'active' : ''}>List Comprehensions</a></ScrollToTopLink><br />    
      
            </div>
        )}
      </div>


      <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown3}>
          Python Tuples {isDropdownOpen3 ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen3 && (
          <div className="dropdown-content">
             <ScrollToTopLink to="/Python_tuples"><a className={location.pathname === '/Python_tuples' ? 'active' : ''}>Python Tuples</a></ScrollToTopLink><br />    
             <ScrollToTopLink to="/Python_tuple_methods"><a className={location.pathname === '/Python_tuple_methods' ? 'active' : ''}>Tuple Methods</a></ScrollToTopLink><br />    
             <ScrollToTopLink to="/Python_tuple_unpacking"><a className={location.pathname === '/Python_tuple_unpacking' ? 'active' : ''}>Tuple Unpacking</a></ScrollToTopLink><br />    
             <ScrollToTopLink to="/Python_tuple_concatenation"><a className={location.pathname === '/Python_tuple_concatenation' ? 'active' : ''}>Tuple Concatenation</a></ScrollToTopLink><br />    
     
            </div>
        )}
      </div>
      
      <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown4}>
          Python Sets {isDropdownOpen4 ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen4 && (
          <div className="dropdown-content">
             <ScrollToTopLink to="/Python_sets"><a className={location.pathname === '/Python_sets' ? 'active' : ''}>Python Sets</a></ScrollToTopLink><br />    
             <ScrollToTopLink to="/Python_set_methods"><a className={location.pathname === '/Python_set_methods' ? 'active' : ''}>Set Methods</a></ScrollToTopLink><br />    
             <ScrollToTopLink to="/Python_set_comprehensions"><a className={location.pathname === '/Python_set_comprehensions' ? 'active' : ''}>Set Comprehensions</a></ScrollToTopLink><br />    
             <ScrollToTopLink to="/Python_set_operations"><a className={location.pathname === '/Python_set_operations' ? 'active' : ''}>Set Operations</a></ScrollToTopLink><br />    
         
            </div>
        )}
      </div>

      <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown5}>
          Python Dictionaries {isDropdownOpen5 ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen5 && (
          <div className="dropdown-content">
             <ScrollToTopLink to="/Python_dictionaries"><a className={location.pathname === '/Python_dictionaries' ? 'active' : ''}>Python Dictionaries</a></ScrollToTopLink><br />    
             <ScrollToTopLink to="/Python_dictionary_methods"><a className={location.pathname === '/Python_dictionary_methods' ? 'active' : ''}>Dictionary Methods</a></ScrollToTopLink><br />    
             <ScrollToTopLink to="/Python_dictionary_comprehensions"><a className={location.pathname === '/Python_dictionary_comprehensions' ? 'active' : ''}>Dictionary Comprehensions</a></ScrollToTopLink><br />    
            
            </div>
        )}
      </div>
      <ScrollToTopLink to="/Python_strings"><a className={location.pathname === '/Python_strings' ? 'active' : ''}>Python Strings</a></ScrollToTopLink><br />    
      <ScrollToTopLink to="/Python_date-time"><a className={location.pathname === '/Python_date-time' ? 'active' : ''}>Python Date & Time</a></ScrollToTopLink><br />    
         

    </div>

    <div className="burger-icon" onClick={toggle}>
      &#9776;
      <p style={newCode}>Menu</p>
    </div>
  </div>
);
}