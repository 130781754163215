import React, { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { FaCopy } from 'react-icons/fa';
import { nightOwl, coy, prism } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import "../css/betathread.css";
import "../css/my style.css";
import "../css/special styles.css";

const AlertComponent = ({ theme }) => {
  return (
    <div
    style={{
      position: 'fixed',
      top: '30%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme === 'dark-theme' ? '#011627' : '#f5f2f0',
      color: theme === 'dark-theme' ? '#f5f2f0' : '#011627',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0px 0px 10px rgba(0,0,0,0.5)'
    }}
  >
      Copied to Clipboard! <span style={{ cursor: 'pointer', fontSize: '34px' }}>&times;</span>
    </div>
  );
};

const SyntaxHighlighterComponent = ({ code, language }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [theme, setTheme] = useState('light-theme');

  useEffect(() => {
    const contentElement = document.querySelector('.divsample');
    if (contentElement) {
      const currentTheme = contentElement.classList.contains('dark-theme') ? 'dark-theme' : 'light-theme';
      setTheme(currentTheme);
    }
  }, []);

  useEffect(() => {
    const contentElement = document.querySelector('.divsample');
    if (contentElement) {
      const observer = new MutationObserver(() => {
        const currentTheme = contentElement.classList.contains('dark-theme') ? 'dark-theme' : 'light-theme';
        setTheme(currentTheme);
      });

      observer.observe(contentElement, { attributes: true, attributeFilter: ['class'] });

      return () => observer.disconnect();
    }
  }, []);

  useEffect(() => {
    const highlighterElement = document.querySelector('.highlighter');
    if (highlighterElement) {
      const currentTheme = highlighterElement.classList.contains('dark-theme') ? 'dark-theme' : 'light-theme';
      setTheme(currentTheme);
    }
  }, []);

  useEffect(() => {
    const highlighterElement = document.querySelector('.highlighter');
    if (highlighterElement) {
      const observer = new MutationObserver(() => {
        const currentTheme = highlighterElement.classList.contains('dark-theme') ? 'dark-theme' : 'light-theme';
        setTheme(currentTheme);
      });

      observer.observe(highlighterElement, { attributes: true, attributeFilter: ['class'] });

      return () => observer.disconnect();
    }
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000); // Hide the alert after 1 second
  };

  return (
    <>
      {showAlert && <AlertComponent />}
      <div className={theme === 'light-theme' ? 'pre-container-light' : 'pre-container'}>
      <button
          style={{
            backgroundColor: theme === 'dark-theme' ? '#011627' : '#f5f2f0',
            border: 'none',
            outline: 'none',
            float: 'right',
            cursor: 'pointer',
            userSelect: 'none',
            color: theme === 'dark-theme' ? '#f5f2f0' : '#000000', // Icon color based on theme
          }}
          title="Copy content to Clipboard"
          onClick={handleCopy}
        >
          <FaCopy />
        </button>
        <SyntaxHighlighter language={language} style={theme === 'light-theme' ? prism : nightOwl}>
          {code}
        </SyntaxHighlighter>
      </div>
    </>
  );
};

export default SyntaxHighlighterComponent;
