import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function CSScomments() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   
    const code = `
        /* I am a single comment*/
        p {
            font-size:30px;
        }
        /* I am not displayed on the browser */
        /*I am a multi line
        comment and this is 
        how i am written */
  
      `;
    
  
    return (
   <body>
    <Helmet>     
        <title>CSS Comments</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Comments, Single-line comments, Multi-line comments,
        Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="CSS comments are text or sentences added to added to a CSS code inorder to provide a short description of the code." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
    
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/CSS_inserting"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_syntax"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>CSS Comments</h1>
</header>

<Navbar />

<main>
<section>
<p>CSS comments are text or sentences added to added to a CSS code inorder to provide a short description of the code.
        </p>
        <p>Comments are not visible on the web page but is kept development purposes as it plays a very 
            important role not just for the web developer but also for those who are new to CSS.</p>

<p>There are two types of comments in CSS,they are:</p>
<li>Single-line comments</li>
<li>Multi-line comments</li>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="css" />
<ScrollToTopLink to="/try_css5"><Tryitout /></ScrollToTopLink>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/CSS_inserting"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_syntax"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}