import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function CSSWidth() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   
    const code = `
    .container {
        width: 300px; /* Set the width of the container to 300 pixels */
        background-color: blue;
    }
`;
const code2 = `
   .container {
    width: 80%; /* Set the width of the container to 300 pixels */
    background-color: blue;
}
`;



    return (
   <body>
     <Helmet>     
        <title>CSS Width</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Width, Setting the width of an element using pixels, Setting the width of an element using Percentages
        Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
        CSS programming tips for real-world applications," />
       <meta name="description" content="CSS width property is used to control the dimensions of elements on a web page.It specifies how wide (horizontal dimension) an element should be
        The value can be set in various units, such as pixels (px), percentages (%), em units (em), viewport width units (vw), and more." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
    
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/CSS_background_attachment"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_height"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>CSS Width</h1>
</header>

<Navbar />

<main>
<section>
<p>CSS width property is used to control the dimensions of elements on a web page. </p>
    <p>It specifies how wide (horizontal dimension) an element should be</p>
      <p>The value can be set in various units, such as pixels (px), percentages (%),
         em units (em), viewport width units (vw), and more.</p>
        <br />
     <h2>Example:</h2>
     <SyntaxHighlighterComponent code={code} language="css" />
<ScrollToTopLink to="/try_css30"><Tryitout /></ScrollToTopLink>

     <br />
     <h2>Using Percentages</h2>
     When you set the width of an element to a percentage, it's calculated relative 
     to the width of its containing element. This can be useful for creating responsive designs.
     <SyntaxHighlighterComponent code={code2} language="css" />
<ScrollToTopLink to="/try_css31"><Tryitout /></ScrollToTopLink>
</section>

</main>
<div className="head">
<ScrollToTopLink to="/CSS_background_attachment"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_height"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}