import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";

export default function AISupervisedLearning() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const exampleCode = `
# Python example for supervised learning using Decision Tree
from sklearn.datasets import load_iris
from sklearn.tree import DecisionTreeClassifier
from sklearn.model_selection import train_test_split
from sklearn.metrics import accuracy_score

# Load dataset
iris = load_iris()
X, y = iris.data, iris.target

# Split data into training and testing sets
X_train, X_test, y_train, y_test = train_test_split(X, y, test_size=0.3, random_state=42)

# Create and train model
model = DecisionTreeClassifier().fit(X_train, y_train)

# Make predictions
predictions = model.predict(X_test)

# Evaluate accuracy
accuracy = accuracy_score(y_test, predictions)
print(f"Accuracy: {accuracy}")
`;

  return (
    <body>
      <Helmet>
        <title>AI Supervised Learning</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI, Supervised Learning, Machine Learning, Classification, Regression" />
        <meta name="description" content="Learn about AI Supervised Learning techniques including classification, regression, and practical examples." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_machine_learning"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_unsupervised_learning"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>AI Supervised Learning</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Supervised learning is a type of machine learning where the model is trained on labeled data. The goal is to learn a mapping from inputs to outputs and make predictions on new, unseen data.</p>

            <h2>Types of Supervised Learning</h2>
            <p>There are two main types of supervised learning:</p>
            <ul>
              <li><strong>Classification</strong>: The goal is to predict a discrete label (e.g., spam or not spam).</li>
              <li><strong>Regression</strong>: The goal is to predict a continuous value (e.g., house prices).</li>
            </ul>

            <h2>Basic Example of Supervised Learning</h2>
            <p>Here is a simple example of using Python to perform classification using a decision tree:</p>
            <SyntaxHighlighterComponent code={exampleCode} language="python" />
            <p>In this example:</p>
            <ul>
              <li>We load the Iris dataset and split it into training and testing sets.</li>
              <li>We create a decision tree classifier and train it on the training data.</li>
              <li>We make predictions on the testing data and evaluate the model's accuracy.</li>
            </ul>

            <h2>Applications of Supervised Learning</h2>
            <p>Supervised learning is used in various applications such as:</p>
            <ul>
              <li><strong>Email Filtering</strong>: Classifying emails as spam or not spam.</li>
              <li><strong>Credit Scoring</strong>: Predicting the likelihood of a customer defaulting on a loan.</li>
              <li><strong>Medical Diagnosis</strong>: Predicting the presence of a disease based on patient data.</li>
              <li><strong>Speech Recognition</strong>: Transcribing spoken language into text.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_machine_learning"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_unsupervised_learning"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
