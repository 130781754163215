import React, { useEffect, useState } from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import "../../css/special styles.css";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import { getAuthStatus, saveAuthStatus } from "../../localstorage";
import DemoProgress from "../../components/Tutorial Progress/DemoProgress";
import ButtonHome from "../../components/Buttonhome";



export default function AIintro() {

   

    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    SELECT first_name FROM users;
    `;
    
    return (
   <body>
     <Helmet>
     <title>AI Tutorial</title>
     <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Introduction to AI, Intelligence Simulation, Learning and Adaptation, Problem Solving, Human-like Behavior,
    Key Concepts of AI, Goals of AI." />
   <meta name="description" content="Explore the power of AI on our AI tutorial page. Learn how to use AI to Achieving human-like behavior, automate routine tasks, solve complex problems and  tackle tasks that require reasoning
    and unlock advanced features in AI development. Discover practical examples and best practices to elevate your programming skills. Dive into the world of PHP with our concise and informative guide" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
    <br /><br /><br /><br /><br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/AI_history"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>AI Introduction - Preamble</h1>
</header>

<Navbar />

<main>
<section>
<p> AI(Artificial Intelligence) refers to the creation of machines or systems that can simulate human-like intelligence and behavior
</p>
<p>This enables them to perform tasks that typically require human intelligence. </p>
<h2>Key Concepts of AI</h2>
<li><b>Intelligence Simulation</b></li>
<p>AI aims to mimic human intelligence by creating systems that can perceive their environment, reason about 
    it, make decisions, and take actions accordingly.</p>
    <li><b>Learning and Adaptation</b></li>
<p>AI systems are designed to learn from data and improve their performance over time. </p>
<p>This is achieved through techniques like Machine Learning, where algorithms are trained 
    on data to make predictions or decisions.</p>
    <li><b>Problem Solving</b></li>
    <p>AI is used to solve complex problems that might be too time-consuming or challenging for humans
         to solve manually. </p>
    <p>It employs algorithms and logic to find optimal solutions.</p>
    <li><b>Autonomy and Automation</b></li>
    <p>AI systems can operate autonomously, making decisions and taking actions without 
        continuous human intervention.</p>
    <p>This enables automation in various domains.</p>
<br />
<h2>Goals of AI</h2>
<li><b>Solve Complex Tasks</b></li>
<p>AI aims to tackle tasks that require reasoning, decision-making, and pattern recognition, such as medical 
    diagnosis, financial analysis, and language translation.
</p>
<li><b>Human-like Behavior</b></li>
<p>Achieving human-like behavior, such as understanding natural language, recognizing images, and playing strategic 
    games, is a significant goal of AI.</p>

<li><b>Enhance Efficiency</b></li>
<p>
AI can automate routine tasks, optimize processes, and enhance efficiency in industries like manufacturing, 
logistics, and customer service.
</p>

<li><b>Predictive Insights</b></li>
<p>AI can analyze data to make predictions and provide valuable insights, aiding in areas like predicting stock market trends, weather 
    forecasts, and disease outbreaks.</p>
</section>

<div className="second-div">
 
     <section className="features">
     <div className="progress-sample">
    
         <img src={require('../../image/beta-progress.svg').default} width={500} height={500}  alt='betathread' />
       <p style={{fontSize: '15px'}}> Our Progress Tracker is designed to help you monitor your progress through our extensive range of tutorials.</p>
       <ScrollToTopLink to="/progress-tracker"><button type="submit" className="buttonb">Track Progress</button></ScrollToTopLink>
   
     </div>

     <div className="progress-sampling">
     <h1 style={{fontSize: '45px'}}>Track Your Progress</h1>
     <DemoProgress />
     </div>
   </section>
  </div>
</main>
<div className="head">
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/AI_history"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}