import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSComparisonOperators() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
        var p = 10;
        var q = "10";
            
        var x = p === q;   // false (different types)
        var y = p !== q;   // true (different types)
        var a = p == q;    // true (value equality, type coercion)
        var b = p >= 10;   // true (greater or equal)
            
`;
const code2 = `
let w = 8;
let x = 8;
let y = "hello";
var a = x !== y;   
var b = w === x;
var c = w == x;
var d = w >= x;
`;


    return (
   <body>
     <Helmet>
    
    <title>JavaScript Comparison Operators</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="JavaScript Comparison Operators, Step-by-step JavaScript programming tutorials, JavaScript coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
   JavaScript programming tips for real-world applications," />
  <meta name="description" content="Learn how JavaScript Comparison Operators, streamline code organization, 
  enhance readability, and unlock advanced features in JavaScript development. Comparison operators compare values and return a boolean result." />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
       <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_logical_conditional_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_typeof_operators"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Comparison Operators</h1>
</header>

<Navbar />

<main>
    <section>
    <p>Comparison operators compare values and return a boolean result.</p>
 <li>=== (Strict Equality): This is the identity operator and it checks if two values are equal in value and type.</li>
 <li>!== (Strict Inequality): This is the non-identity operator and it checks if two values are not equal in value or type.</li>
 <li>&gt;, &lt;, &gt;=, &lt;= (Greater, Less, Greater or Equal, Less or Equal): Compares numeric values.</li>
 <li>==, != (Equality, Inequality): Checks for value equality (type coercion may occur).</li>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
<ScrollToTopLink to="/try_js39"><Tryitout /></ScrollToTopLink>   
   <p>This is another example of the comparison operators.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js40"><Tryitout /></ScrollToTopLink>   

</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_logical & conditional_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_typeof_operators"><ButtonNext /></ScrollToTopLink>
</div>


</div>

<Footertutorials />
   </body>
    )
}