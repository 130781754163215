import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPVariables() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    <?
     $integer = 20; // This is a variable with a number assigned to it
     $text = "Hello World"; // This is a variable with a string assigned to it
     $decimal = 2.9876; // This is a variable with a decimal assigned to it
     $decimal = 2.9876; // This is a variable with a decimal assigned to it
    ?>
    `;
    const code2 = `
    <script language = "php">
     /* I am a multi-line comment 
        I am a multi-line comment 
        I am a multi-line comment 
        I am a multi-line comment  
     */
    </script>
    `;

    return (
   <body>
      <Helmet>
        <title>PHP Variables</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Syntax, Escaping to PHP, Canonical PHP Tags, HTML Script Tags, Short-Open Tags(SGML-style), ASP-style Tags, Case Sensitive, Whitespaces,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="Learn how PHP variables are used to store information, streamline code organization, 
      enhance readability, and unlock advanced features in PHP development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_comments"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_datatypes"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Variables</h1>
</header>

<Navbar />

<main>
<section>
<p>Variables are used to store information.</p>
<p>Here is a list of things you need to know about PHP variables.</p>
<li>The dollar sign ($) is used to denote all variables in PHP.</li>
<li>Variables are assigned with the equal to (=) operator with the variable on the left-hand side and it's 
    value on the right-hand side.</li>
<li>PHP variables are used to store numbers or a string of characters.</li>
<li>Variables can be declared before they are assigned but there is no need to do that.</li>
<li>There is no limit for the size of PHP variables.</li>
<li>Variables in PHP are similar to that of Perl</li>
<p>This is what a PHP variable looks like</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="php" />

<h2>Variable Naming</h2>
<p>A PHP variable must begin with either a letter or an underscore e.g, $str, $_str, $str_var.</p>
<p>PHP variable names can contain numbers, letters, underscores but usage of arithmetic characters such as +,-,
    % and other characters such as &, (.),(,) etc
</p>


</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_comments"><ButtonNext /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_datatypes"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}