import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPEcho() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    echo "Hello, World!";

    `;
    const code2 = `
    <?php
     echo "Hello, World!";
    ?>

    `;
    const code3 = `
    Hello, World!
    
    `;

    const code4 = `
    <?php
    $name = "John";
    echo "Hello, $name!";
    ?>

    `;

    const code5 = `
    Hello, John!

    `;

    const code6 = `
    <?php
    $greeting = "Hello,";
    $name = "Jane";
    echo $greeting . " " . $name . "!";
    ?>

    `;

    const code7 = `
    Hello, Jane!

    `;

    const code8 = `
    <?php
    echo "<h1>Welcome to my Website</h1>";
    ?>

    `;

    const code9 = `
    Welcome to my Website

    `;

    const code10 = `
    <?php
    $imageUrl = "image.jpg";
    echo "<img src='$imageUrl' alt='My Image'>";
    ?>    

    `;

    const code11 = `
    <img src='image.jpg' alt='My Image'>   

    `;
    return (
   <body>
      <Helmet>
        <title>PHP Echo Statement</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Echo Statement, Echo Statement Syntax, Outputting Variables, Concatenating Strings, Outputting HTML Attributes, Output HTML Elements, Outputting HTML Attributes,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="In PHP, the 'echo' statement is commonly used to output strings or variables. It is one of the most fundamental
     constructs for displaying content in web pages generated by PHP scripts. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_datatypes"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_print"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Echo Statement</h1>
</header>

<Navbar />

<main>
<section>
<p>In PHP, the 'echo' statement is commonly used to output strings or variables. It is one of the most fundamental
     constructs for displaying content in web pages generated by PHP scripts.</p>
<p>The echo statement in PHP is a versatile tool for generating dynamic content within web pages. </p>
<p>It can output plain text, variables, HTML code, and more, making it essential for building dynamic and 
    interactive web applications. </p>
<br />
<h2>Basic Syntax</h2>
<p>The basic syntax of the 'echo' statement is:</p>
<SyntaxHighlighterComponent code={code} language="php" />

<h2>Explanation with Examples:</h2>
<p><b>Outputting Strings:</b> </p>
<SyntaxHighlighterComponent code={code2} language="php" />


<h2>Output</h2>
<SyntaxHighlighterComponent code={code3} language="php" />
<p>In this example, the 'echo' statement is used to output the string "Hello, World!".</p>
<br />

<p><b>Outputting Variables:</b> </p>
<SyntaxHighlighterComponent code={code4} language="php" />

<h2>Output</h2>
<SyntaxHighlighterComponent code={code5} language="php" />
<p>Here, the echo statement outputs the value stored in the variable $name, which is "John".</p>


<p>< b>Concatenating Strings:</b></p>
<SyntaxHighlighterComponent code={code6} language="php" />

<h2>Output:</h2>
<SyntaxHighlighterComponent code={code7} language="php" />
<p>In this example, we concatenate the strings stored in variables '$greeting', " ", and '$name' using the '.' operator within the 'echo' statement.</p>


<p><b>Output HTML Elements:</b></p>
<SyntaxHighlighterComponent code={code8} language="php" />

<h2>Output:</h2>
<SyntaxHighlighterComponent code={code9} language="php" />
<p>Here, the 'echo' statement is used to output HTML code. This is useful when generating HTML dynamically within PHP scripts.</p>


<p><b>Outputting HTML Attributes:</b></p>
<SyntaxHighlighterComponent code={code10} language="php" />

<h2>Output:</h2>
<SyntaxHighlighterComponent code={code11} language="php" />
<p>In this example, the 'echo' statement outputs an HTML 'img' tag with the src attribute populated dynamically from the '$imageUrl' variable.</p>

</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_datatypes"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_print"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}