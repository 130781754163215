import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLAggregateFunctions() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const countFunctionCode = `
-- COUNT Function: Counts the number of rows in a table.
SELECT COUNT(*) AS TotalOrders
FROM Orders;
-- Returns: TotalOrders
  `;

  const sumFunctionCode = `
-- SUM Function: Calculates the sum of a numeric column.
SELECT SUM(Quantity) AS TotalQuantity
FROM OrderDetails;
-- Returns: TotalQuantity
  `;

  const avgFunctionCode = `
-- AVG Function: Calculates the average value of a numeric column.
SELECT AVG(Price) AS AvgPrice
FROM Products;
-- Returns: AvgPrice
  `;

  const minMaxFunctionCode = `
-- MIN and MAX Functions: Retrieve the minimum and maximum values of a column.
SELECT MIN(Price) AS MinPrice, MAX(Price) AS MaxPrice
FROM Products;
-- Returns: MinPrice, MaxPrice
  `;

  return (
    <body>
      <Helmet>
        <title>SQL Aggregate Functions</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL Aggregate Functions, COUNT, SUM, AVG, MIN, MAX, SQL Tutorial" />
        <meta name="description" content="Learn about SQL aggregate functions, including COUNT, SUM, AVG, MIN, and MAX with examples and usage in SQL queries." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_data_types"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_string_functions"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL Aggregate Functions</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>SQL aggregate functions perform calculations on groups of rows and return a single value. They are used with the <code>SELECT</code> statement.</p>

            <h2>COUNT Function</h2>
            <p>The <code>COUNT</code> function returns the number of rows that match the specified condition or all rows if no condition is provided.</p>
            <SyntaxHighlighterComponent code={countFunctionCode} language="sql" />

            <h2>SUM Function</h2>
            <p>The <code>SUM</code> function calculates the sum of numeric values in a column, useful for calculating total quantities or amounts.</p>
            <SyntaxHighlighterComponent code={sumFunctionCode} language="sql" />

            <h2>AVG Function</h2>
            <p>The <code>AVG</code> function computes the average value of a numeric column, providing insights into the average price, rating, etc.</p>
            <SyntaxHighlighterComponent code={avgFunctionCode} language="sql" />

            <h2>MIN and MAX Functions</h2>
            <p>The <code>MIN</code> and <code>MAX</code> functions retrieve the smallest and largest values in a column respectively, useful for finding price ranges, date ranges, etc.</p>
            <SyntaxHighlighterComponent code={minMaxFunctionCode} language="sql" />
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_data_types"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_string_functions"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
