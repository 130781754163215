import React, { useEffect, useState } from 'react';
import HeaderProjects from '../components/Header-projects';
import Footer from "../components/Footer";
import Navbar from '../components/navbar';
import Topnav from '../components/topnav';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from "react-router-dom";
import { getAuthStatus, saveAuthStatus } from '../localstorage';
import ScrollToTopLink from '../components/ScrollToTop';

export default function ProjectStarterKit() {

  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate(); // Move useNavigate here

  useEffect(() => {
    const checkAuthentication = () => {
      const isAuthenticated = getAuthStatus('_id');
      if (!isAuthenticated) {
        navigate("/login");
      } else {
        setAuthenticated(true);
      }
    };

    checkAuthentication();
  }, [navigate]);

    // Define an array of projects with details
    const projects = [
        {
            id: 1,
            name: "Personal Portfolio",
            description: "Build a simple personal portfolio website to showcase your projects and skills.",
            link: "/portfolio_project"
        },
        {
            id: 2,
            name: "Simple Calculator",
            description: "Create a basic calculator application using React JS.",
            link: "/calculator_project"
        },
        {
            id: 3,
            name: "CSS Flexbox Layout",
            description: "Build a simple webpage layout using CSS Flexbox.",
            link: "/flexbox_project"
        },
        {
            id: 4,
            name: "Responsive Landing Page",
            description: "Create a responsive landing page using HTML and CSS.",
            link: "/landingpage_project"
        },
        {
            id: 5,
            name: "Todo List Application",
            description: "Develop a todo list application with add, edit, and delete functionality.",
            link: "/todolist_project"
        }
    ];

    return (
        <div style={{ fontFamily: 'Arial, sans-serif' }} className="main-theme">
            <Helmet>
                <title>Project Starter Kit | Beta Thread</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-compatible" content="IE-edge"/>
                <meta name="Keywords" content="Project Starter Kit, Coding Resources, Code Samples, Coding Challenges, Coding Practice, Programming Languages, Software Development, Coding Bootcamp, HTML, Python, CSS, SQL, JavaScript, How to, PHP, XML, MySQL, React, AI, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, Examples, Learn to code, Coding the future, Source code, Website" />
                <meta name="description" content="Discover your next coding project with Beta Thread's Project Starter Kit. Whether you're a novice or seasoned developer, explore a curated collection of beginner-friendly coding projects, tutorials, and resources to kickstart your coding journey. From building your personal portfolio to creating interactive applications, find inspiration and guidance to enhance your coding skills and unleash your creativity." />
                <meta name="viewport" content="width=device-width,initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Topnav />
            <HeaderProjects />
            <Navbar />
            
            <section style={{ marginTop: '20px' }}>
                <article>
                    <h1>Welcome to the Project Starter Kit!</h1>
                    <p>Embark on your coding journey with our Project Starter Kit. Whether you're a beginner looking for your first project or an experienced developer seeking inspiration, we've got you covered.</p>
                </article>

                {/* List of starter projects */}
                <div style={{ padding: '20px', borderRadius: '5px' }}>
                    <h2>Featured Projects</h2>
                    {projects.map(project => (
                        <div style={{ marginBottom: '20px' }} key={project.id}>
                            <h3 style={{ marginBottom: '5px', color: 'teal' }}>{project.name}</h3>
                            <p style={{ marginBottom: '10px' }}>{project.description}</p>
                            <ScrollToTopLink to={project.link} style={{ color: 'teal', textDecoration: 'none' }}><button className='btn'>Learn More</button></ScrollToTopLink>
                        </div>
                    ))}
                </div>

               
              
            </section>

            <Footer />
        </div>
    );
}
