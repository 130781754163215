import React, { useEffect, useState } from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../css/special styles.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import { getAuthStatus, saveAuthStatus } from "../../localstorage";
import DemoProgress from "../../components/Tutorial Progress/DemoProgress";
import ButtonHome from "../../components/Buttonhome";



export default function CSSintro() {

   
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    h1 {
        color: green;
    }
    p {
        text-align: center;
    }
`;
    return (
   <body>
       <Helmet>
     <title>CSS Tutorial</title>
     <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Introduction to CSS, What is CSS, CSS Requirement, Why learn CSS, CSS Example,
     Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications." />
   <meta name="description" content="Explore the power of CSS on our CSS tutorial page. Learn how to use CSS to design we pages, produce responsive design across all web pages,
    and unlock advanced features in CSS development. Discover practical examples and best practices to elevate your programming skills. Dive into the world of CSS with our concise and informative guide" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
    
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_inserting"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>CSS Tutorial - Preamble</h1>
</header>

<Navbar />

<main>
<section>
<h2>What is CSS?</h2>
<p>Every website you see online has been styled one way or the other to make it beautiful and responsive
    to do this we make use of CSS(Cascading Stylesheet).
</p>
<p>CSS is a stylesheet language used to illustrate the presentation of an HTML document</p>
<h2>CSS Requirement</h2>
<p>Before you begin with CSS you must have a basic knowledge of HTMLif you do go back to our home page and begin your
    HTML tutorial
</p>

    </section>
    
    <section>
<h2>Why learn CSS</h2>
<p>CSS is a very powerful and important technology when it comes to designing web pages with responsive design
    across all devices.
</p>
<p>The latest version of CSS is CSS3.</p>
<p> CSS files are always saved with the .css extension</p>
<h3>NOTE:</h3>
<p>CSS is not a programming language instead it is a stylesheet language used to create design pages.</p>
</section>

<section>
    <h2>CSS Example:</h2>
    <p>This is a simple example of how CSS can be used.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="css" />
<ScrollToTopLink to="/try_css1"><Tryitout /></ScrollToTopLink>
</section>

<div className="second-div">
 
     <section className="features">
     <div className="progress-sample">
    
         <img src={require('../../image/beta-progress.svg').default} width={500} height={500}  alt='betathread' />
       <p style={{fontSize: '15px'}}> Our Progress Tracker is designed to help you monitor your progress through our extensive range of tutorials.</p>
       <ScrollToTopLink to="/progress-tracker"><button type="submit" className="buttonb">Track Progress</button></ScrollToTopLink>
   
     </div>

     <div className="progress-sampling">
     <h1 style={{fontSize: '45px'}}>Track Your Progress</h1>
     <DemoProgress />
     </div>
   </section>
  </div>
</main>
<div className="head">
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/CSS_inserting"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}