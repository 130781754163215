import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLpre() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <!-- sentence with the pre element -->
      <pre>Hello! World learning is fun</pre>
      <!-- sentence without the pre element -->
       Hello! World learning if fun
        
  `;

  const code2 = `
  <h1>Hello! World<pre>learning is fun</pre></h1>
  <h2>Hello! World<pre>learning is fun</pre></h2>
  <p>Hello! World<pre>learning is fun</pre></p>
`;


    return (
   <body>
     <Helmet>    
        <title>HTML Pre Element</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Pre Element, html pre, html pre element example, <pre>, html preformatted text, What is a preformatted text, What is the pre tag
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="The <pre> element in HTML represents a block of preformatted text.
       Here the structure is represented by typographical conventions rather than by elements." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_entities"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_lists"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Pre Element</h1>
</header>

<Navbar />

<main>

<section>
<p>The &lt;pre&gt; element in HTML represents a block of preformatted text.</p>
<p>Here the structure is represented by typographical conventions rather than by elements.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html54"><Tryitout /></ScrollToTopLink>
<p>You can also preformat text within a block-level element like the &lt;h1&gt;&lt;p&gt;.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html55"><Tryitout /></ScrollToTopLink>
</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_entities"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_lists"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}