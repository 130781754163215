import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPOperators() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    $x = 10;
    $y = 5;
    
    // Addition
    $sum = $x + $y; // $sum will be 15
    
    // Subtraction
    $difference = $x - $y; // $difference will be 5
    
    // Multiplication
    $product = $x * $y; // $product will be 50
    
    // Division
    $quotient = $x / $y; // $quotient will be 2
    
    // Modulus (Remainder)
    $remainder = $x % $y; // $remainder will be 0
    
    `;
    const code2 = `
$x = 10;
$y = 5;

// Assign value of $x + $y to $z
$z = $x + $y; // $z will be 15

// Increment
$x += 2; // Equivalent to $x = $x + 2, $x will be 12

// Decrement
$y -= 1; // Equivalent to $y = $y - 1, $y will be 4

    `;
    const code3 = `
$x = 10;
$y = 5;

// Equal to
$isEqual = ($x == $y); // $isEqual will be false

// Not equal to
$isNotEqual = ($x != $y); // $isNotEqual will be true

// Greater than
$isGreaterThan = ($x > $y); // $isGreaterThan will be true

// Less than
$isLessThan = ($x < $y); // $isLessThan will be false

// Greater than or equal to
$isGreaterThanOrEqual = ($x >= $y); // $isGreaterThanOrEqual will be true

// Less than or equal to
$isLessThanOrEqual = ($x <= $y); // $isLessThanOrEqual will be false

    `;

    const code4 = `
$x = 10;
$y = 5;

// AND
$resultAnd = ($x > 5 && $y < 10); // $resultAnd will be true

// OR
$resultOr = ($x == 10 || $y == 5); // $resultOr will be true

// NOT
$resultNot = !($x == 5); // $resultNot will be true

    `;

    const code5 = `
    $x = 10;

    // Increment
    $x++; // Equivalent to $x = $x + 1, $x will be 11
    
    // Decrement
    $x--; // Equivalent to $x = $x - 1, $x will be 10
        `;

    const code6 = `
    $string1 = "Hello";
    $string2 = " World!";
    
    // Concatenation
    $resultString = $string1 . $string2; // $resultString will be "Hello World!"
       `;

    const code7 = `
    $array1 = array(1, 2, 3);
    $array2 = array(4, 5, 6);
    
    // Union
    $unionArray = $array1 + $array2; // $unionArray will be [1, 2, 3, 4, 5, 6]
    
    // Equality
    $isEqualArray = ($array1 == $array2); // $isEqualArray will be false
       `;

  
    return (
   <body>
      <Helmet>
        <title>PHP Operators</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Operators, Arithmetic Operator, Assignment Operator, Comparison Operator, Logical Operators, Increment/Decrement Operator, String Operator, Array Operator,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="In PHP, operators are symbols that perform operations on variables and values. They allow you to manipulate data, compare values, perform arithmetic calculations, and more. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_print"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_arithmetic"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Operators</h1>
</header>

<Navbar />

<main>
<section>
<p>In PHP, operators are symbols that perform operations on variables and values. </p>
<p>They allow you to manipulate data, compare values, perform arithmetic calculations, and more. </p>
<p> Understanding PHP operators is essential for writing effective and efficient code.</p>
<p>There are seven operators in PHP but here we will have a brief overview for all of them and in the next page we will learn more about them.</p>

<h2>Arithmetic Operators</h2>
<p>Arithmetic operators are used to perform mathematical operations like addition, subtraction, multiplication, division, and modulus (remainder).</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="php" />

<h2>Assignment Operators</h2>
<p>Assignment operators are used to assign values to variables. </p>
<p>They also include compound assignment operators like +=, -=, *=, etc., which perform arithmetic operations while assigning values.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="php" />


<h2>Comparison Operators</h2>
<p>Comparison operators are used to compare values. They return true or false based on the comparison result. </p>
<p>These operators include equal to, not equal to, greater than, less than, greater than or equal to, and less than or equal to.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code3} language="php" />


<h2>Logical Operators</h2>
<p>Logical operators are used to combine conditional statements.</p>
<p> They include AND, OR, and NOT operators, which help in creating complex conditions.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code4} language="php" />


<h2>Increment/Decrement Operators</h2>
<p>Increment and decrement operators are used to increase or decrease the value of a variable by one.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code5} language="php" />


<h2>String Operators</h2>
<p>String operators are used to concatenate strings. They help in combining multiple strings into one.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code6} language="php" />

<h2>Array Operators</h2>
<p>Array operators are used to compare or merge arrays.</p>
<p>They include operators for union and equality comparisons between arrays.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code7} language="php" />

</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_print"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_arithmetic"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}