import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLforms() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <form>
      <!-- All form elements are to be in between opening and closing form tag -->
      </form>
  `;
  const code2 = `
   <!-- Label element without the input element -->
   <form>
   <label for="fullname">Fullname</label>
   <label for="age">Age</label>
   </form>
`;
const code3 = `
<form action="action_page.php" method="get">
<input type="text" name="fname" placeholder="Enter your first name" />
<input type="text" name="lname" placeholder="Enter your last name" />
<input type="text" name="age" placeholder="Enter your age" />
<input type="password" name="password" placeholder="Enter your password" />
<input type="submit" />
</form>
`;
const code4 = `
<form action="form-handler.html" method="get" autocomplete="off">
<input type="text" name="fname" placeholder="Enter your first name" />
<input type="text" name="lname" placeholder="Enter your last name" />
<input type="text" name="age" placeholder="Enter your age" />
<input type="password" name="password" placeholder="Enter your password" />
<input type="submit" />
</form>
`;

const code5 = `
<form action="form-handler.html" method="post" autocomplete="on">
<input type="text" name="fname" placeholder="Enter your first name" />
<input type="text" name="lname" placeholder="Enter your last name" />
<input type="text" name="age" placeholder="Enter your age" />
<input type="password" name="password" placeholder="Enter your password" />
<input type="submit" />
</form>
`;


    return (
   <body>
    <Helmet>    
        <title>HTML5 Forms</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML5 Forms, What is an HTML form, Form Labels, Form Attributes, action attribute, method attribute, GET method, POST method, novalidate, accept-charset, enctype, autocomplete, target, class, id, name, onSubmit
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications." />
       <meta name="description" content="HTML Forms are used to get details and data from users who visit websites.
        HTML only supports designing the layout of forms it cannot process the information and store it in a database." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_description"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_forms_attr"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Forms</h1>
</header>

<Navbar />

<main>

<section>
<p>HTML Forms are used to get details and data from users who visit websites.</p>
<p>HTML only supports designing the layout of forms it cannot process the information and store it in a database.</p>
<p>Programming and storing the input is carried out by a form-handler known as PHP(Hypertext Processor)
 or other programming languages such as Python, ASP, VBscript or JavaScript.</p>
<p>The &lt;form&gt;&lt;/form&gt; tag is used to create an HTML Form.</p>
<br />
<SyntaxHighlighterComponent code={code1} language="html" />


<p>There are different types some of which include contact forms,signup forms,login forms etc.</p>
<h2>Form Labels</h2><label></label>
<p>The &lt;label&gt; element represents a caption in a user interface.It makes use of the <strong>for</strong> attribute.</p>
<p>The &lt;label&gt; also make use of the input element but we have not yet learnt how to use the input element.</p>
<br />
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html60"><Tryitout /></ScrollToTopLink>
</section>

<section>
  
  <h2>Form Attributes</h2>
  <p>Here are the attributes that can be found in an HTML Form</p>
  <h3>action</h3>
  <p>The action attribute is used to direct the form inputs to a location i.e,a form-handler page usually
    saved with the .php extension where the data is processed.
  </p>
  <br />
  <SyntaxHighlighterComponent code={code3} language="html" />

  <h3>method</h3>
    <p>This defines the HTTP method used to submit the form.The method attribute introduces two methods.
    </p>
    <li>GET method</li>
    <li>POST method</li>
    <p>The two methods above are further described below.</p>
    <li>GET: In the get method the form data are appended to the action attribute URL with '?' as a seperator 
      and the resulting URL is sent to the server.This method should not be used to send sensitive and personal information.
    </li>
    <li>POST: In the post method the form data are included in the body of the form and sent to the server.
      This method can be used to send personal information.
    </li>
    <h2>Difference between GET and POST methods</h2>
   
    <table>
      <thead>
        <tr>
          <th>GET</th>
          <th>POST</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>less secure</td>
          <td>more secure</td>
        </tr>
        <tr>
          <td>more prone to hacking</td>
          <td>less prone to hacking</td>
        </tr>
        <tr>
          <td>it is the default value</td>
          <td>not the default value</td>
        </tr>
        <tr>
          <td>should not be used to submit personal data</td>
          <td>should be used to submit personal data</td>
        </tr>
        <tr>
          <td>data is written in the URL address</td>
          <td>data is not written in the URL address</td>
        </tr>
      </tbody>
    </table>
    <h3>novalidate</h3>
    <p>This attribute specifies that the form should not be validated on submission if this attribute is not
      specified then the form will be validated on submission.
    </p>
    
    <h3>accept-charset</h3>
    <p>Tis attribute defines the character encoding of the HTML Form.It also defines the charset of the form</p>
    
    <h3>enctype</h3>
    <p>When the value of the method attribute is post, 
      enctype is the MIME type of content that is used to submit the form to the server</p>
    
      <h3>autocomplete</h3>
      <p>This indicates whether input elements can by default have their values automatically 
        completed by the browser.This attribute introduces two values</p>
        <li>on: specifies that the browser can automatically autocomplete values entered into a form based
      on their previous activity on the form,for example in a login form whereby the user has logged in on different other occassions.</li>
        <li>off: specifies that the user must insert the values on their own</li>
    
      <h3>target</h3>
      <p>This is used to define where to display the response of the form.
        it is also a name/keyword for a browsing context or window (for example, tab, window, or inline frame).</p>
      <p>The keywords are explained below.</p>
     <li> _self: Load the response into the same frame or browsing context as the current one.
       This value is the default if the attribute is not specified.</li>
     <li> _blank: Load the response into a new unnamed window or browsing context.</li>
     <li> _parent: Load the response into the frameset parent of the current frame, parent browsing context
       of the current one. If there is no parent, this option behaves the same way as _self.</li>
     <li> Load the response into the full original window, and cancel all other frames.</li>
    
     <h3>class, id, name</h3>
     <p>They are used for identifying the form for styling or scripting but they are individually explained below.</p>
     <li>class: Classes allows CSS and JavaScript 
      to select and access specific elements via the class selectors for CSS or functions for JavaScript</li>
      <li>id: This defines a unique identifier (ID) which must be unique in the whole document. Its purpose
         is to identify the element when linking , scripting, or styling (with CSS) just like that of classes.</li>
    <li>name: This identifies the name of the form. In HTML 4, its use is deprecated meaning it is outdated 
      (id should be used instead). It must be unique among the forms in a document and not just an empty string in HTML 5.</li>
     
     <h3>onSubmit</h3>
     <p>This attribute is used to call a function for example inorder to validate the form after submission.</p>
      <br />
      <p>In the example below an imaginary form-handler page with the .html extension will be introduced inside 
        the action attribute because we are only learning HTML for now.
      </p>
      <br />
      <SyntaxHighlighterComponent code={code4} language="html" />
<ScrollToTopLink to="/try_html61"><Tryitout /></ScrollToTopLink>
<br />
<p>Below is another example of a form with the method post and autocomplete on</p>
    <br />
    <SyntaxHighlighterComponent code={code5} language="html" />
<ScrollToTopLink to="/try_html62"><Tryitout /></ScrollToTopLink>
</section>
<div className="head">
<ScrollToTopLink to="/HTML_description"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_forms_attr"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}