import React, { useEffect, useState } from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../css/special styles.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import Code from "../../JS/code";
import ChangeColor from "../../JS/special scripts/output_5";
import { getAuthStatus, saveAuthStatus } from "../../localstorage";
import DemoProgress from "../../components/Tutorial Progress/DemoProgress";
import ButtonHome from "../../components/Buttonhome";




export default function JSintro() {

   

    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    function changeColor(tag) {
        var color = tag.style.backgroundColor;
       if(color == "blue") {
        tag.style.backgroundColor = "yellow";
       } else {
        tag.style.backgroundColor = "blue";
       }
      }
    const code = `
    <!DOCTYPE html>
    <html>
    <head>
    <title>My First Web Page</title>
    </head>
    <body>
    <div onclick="changeColor(this)" style="background-color: blue;height: 300px;width: 1020px;
    text-align: center;color: #fff;font-size: 50px;" class="sample">Click me!</div>
    function changeColor(tag) {
        var color = tag.style.backgroundColor;
       if(color == "blue") {
        tag.style.backgroundColor = "yellow";
       } else {
        tag.style.backgroundColor = "blue";
       }
      }
    </body>
    </html>
`;
    return (
   <body>
     <Helmet>
     <title>JavaScript Tutorial</title>
     <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Introduction to JavaScript, What is JavaScript, JavaScript Requirement, Looking Back: HTML, CSS, and JavaScript in Real-Life Scenarios, Real-Life Scenario
     Step-by-step JavaScript programming tutorials, JavaScript coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
    JavaScript programming tips for real-world applications." />
   <meta name="description" content="JavaScript (often abbreviated as JS) is a versatile and widely-used programming language primarily used for adding interactivity, dynamic behavior, and functionality to websites and web applications." />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/JS_writing_code"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Tutorial - Preamble</h1>
</header>

<Navbar />

<main>
    <section>
    <h2>What is JavaScript?</h2>
<p>JavaScript (often abbreviated as JS) is a versatile and widely-used programming language primarily used for 
    adding interactivity, dynamic behavior, and functionality to websites and web applications. </p>

<p>It plays a key role in modern web development, allowing developers to create interactive user
     experiences and build complex web applications.</p>

     <h2>JavaScript Requirement</h2>
     <p>Before you begin your JavaScript tutoial make sure you have gone through our HTML and CSS tutorials
        respectively because you will find it difficult to learn JavaScript without a basic knowledge of HTML 
        and CSS.
     </p>
     <h2>Looking Back: HTML, CSS, and JavaScript in Real-Life Scenarios:</h2>
     <p>In real-life scenarios, the combination of HTML, CSS, and JavaScript powers the modern web. 
        Here's a glimpse of how these technologies work together:</p>
        <ol type="1">
        <li>HTML (Hypertext Markup Language): HTML provides the structure and content of a web page. It defines the layout,
             headings, paragraphs, images, links, and other elements that make up the page's structure.</li>

        <li>CSS (Cascading Style Sheets): CSS is responsible for styling and presentation. It controls how 
            the content defined by HTML looks, including aspects like colors, fonts, spacing, and positioning.</li>

        <li>JavaScript: JavaScript adds interactivity and functionality to web pages. It enables dynamic behavior, 
            such as responding to user actions, updating content without page reloads, and handling form submissions.</li>
        </ol>
        </section>
    
    <section>
<h2>Real-Life Scenario:</h2>
<p>Imagine you're building a weather website. Here's how these technologies collaborate:</p>
<ol type="1">
    <li>HTML: You use HTML to structure the page with sections for weather information,
         location search, and a forecast display.</li>
    <li>CSS: CSS styles the weather information with appropriate fonts, colors, and spacing. It arranges the 
        search bar and forecast display in an appealing layout.</li>
    <li>JavaScript: With JavaScript, you make the search bar interactive. Users can type a location, and JavaScript 
        fetches weather data from an API (Application Programming Interface). 
        The fetched data is then displayed in the forecast section without needing to reload the entire page.</li>
</ol>
</section>

<section>
  
    <h2>Here is an example of how JS works</h2>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
    <br />
  
</section>

<div className="second-div">
 
     <section className="features">
     <div className="progress-sample">
    
         <img src={require('../../image/beta-progress.svg').default} width={500} height={500}  alt='betathread' />
       <p style={{fontSize: '15px'}}> Our Progress Tracker is designed to help you monitor your progress through our extensive range of tutorials.</p>
       <ScrollToTopLink to="/progress-tracker"><button type="submit" className="buttonb">Track Progress</button></ScrollToTopLink>
   
     </div>

     <div className="progress-sampling">
     <h1 style={{fontSize: '45px'}}>Track Your Progress</h1>
     <DemoProgress />
     </div>
   </section>
  </div>
</main>
<div className="head">
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/JS_writing_code"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}