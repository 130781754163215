import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonOperators() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeArithmetic = `
# Arithmetic Operators
a = 10
b = 5

print(a + b)  # Addition
print(a - b)  # Subtraction
print(a * b)  # Multiplication
print(a / b)  # Division
print(a % b)  # Modulus
print(a ** b) # Exponentiation
print(a // b) # Floor Division
    `;

    const codeComparison = `
# Comparison Operators
a = 10
b = 5

print(a == b)  # Equal to
print(a != b)  # Not equal to
print(a > b)   # Greater than
print(a < b)   # Less than
print(a >= b)  # Greater than or equal to
print(a <= b)  # Less than or equal to
    `;

    const codeLogical = `
# Logical Operators
a = True
b = False

print(a and b)  # Logical AND
print(a or b)   # Logical OR
print(not a)    # Logical NOT
    `;

    const codeBitwise = `
# Bitwise Operators
a = 10  # 1010 in binary
b = 4   # 0100 in binary

print(a & b)  # Bitwise AND
print(a | b)  # Bitwise OR
print(a ^ b)  # Bitwise XOR
print(~a)     # Bitwise NOT
print(a << 2) # Bitwise left shift
print(a >> 2) # Bitwise right shift
    `;

    const codeAssignment = `
# Assignment Operators
a = 10

a += 5  # a = a + 5
print(a)

a -= 3  # a = a - 3
print(a)

a *= 2  # a = a * 2
print(a)

a /= 4  # a = a / 4
print(a)

a %= 3  # a = a % 3
print(a)

a **= 2 # a = a ** 2
print(a)

a //= 2 # a = a // 2
print(a)
    `;

    const codeMembership = `
# Membership Operators
x = ["apple", "banana"]

print("banana" in x)   # True
print("cherry" not in x) # True
    `;

    const codeIdentity = `
# Identity Operators
a = ["apple", "banana"]
b = ["apple", "banana"]
c = a

print(a is c)      # True because c is the same object as a
print(a is b)      # False because a and b are different objects
print(a == b)      # True because a and b have the same content
    `;

    return (
        <body>
            <Helmet>
                <title>Python Operators</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Operators, Arithmetic Operators, Comparison Operators, Logical Operators, Bitwise Operators, Assignment Operators, Membership Operators, Identity Operators" />
                <meta name="description" content="Comprehensive guide to Python operators, including arithmetic, comparison, logical, bitwise, assignment, membership, and identity operators." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_inputs"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_conditionals"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Operators</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Python provides a variety of operators for different operations. These operators are categorized based on the operation they perform.</p>
                        
                        <h2>Arithmetic Operators</h2>
                        <p>Arithmetic operators are used to perform mathematical operations like addition, subtraction, multiplication, and division.</p>
                        <SyntaxHighlighterComponent code={codeArithmetic} language="python" />
                        
                        <h2>Comparison Operators</h2>
                        <p>Comparison operators compare two values and return a boolean result.</p>
                        <SyntaxHighlighterComponent code={codeComparison} language="python" />
                        
                        <h2>Logical Operators</h2>
                        <p>Logical operators are used to combine conditional statements.</p>
                        <SyntaxHighlighterComponent code={codeLogical} language="python" />
                        
                        <h2>Bitwise Operators</h2>
                        <p>Bitwise operators perform operations on binary representations of numbers.</p>
                        <SyntaxHighlighterComponent code={codeBitwise} language="python" />
                        
                        <h2>Assignment Operators</h2>
                        <p>Assignment operators are used to assign values to variables. They include basic assignment, as well as operations combined with assignment.</p>
                        <SyntaxHighlighterComponent code={codeAssignment} language="python" />
                        
                        <h2>Membership Operators</h2>
                        <p>Membership operators test whether a value or variable is found in a sequence (such as a list, string, or tuple).</p>
                        <SyntaxHighlighterComponent code={codeMembership} language="python" />
                        
                        <h2>Identity Operators</h2>
                        <p>Identity operators compare the memory locations of two objects.</p>
                        <SyntaxHighlighterComponent code={codeIdentity} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Understanding the various operators in Python is essential for performing different operations in your programs. This tutorial covered arithmetic, comparison, logical, bitwise, assignment, membership, and identity operators.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_inputs"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_conditionals"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
