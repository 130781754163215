import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIRoboticsApplications() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
# Example 1: Industrial Robots for Assembly Lines
import time

class RobotArm:
    def __init__(self, id):
        self.id = id

    def pick_part(self):
        print(f"Robot {self.id} picking part")
        time.sleep(1)

    def place_part(self):
        print(f"Robot {self.id} placing part")
        time.sleep(1)

robot = RobotArm(1)
robot.pick_part()
robot.place_part()
  `;

  const example2 = `
# Example 2: Medical Robots for Surgery
class SurgicalRobot:
    def __init__(self, patient):
        self.patient = patient

    def perform_surgery(self):
        print(f"Performing surgery on {self.patient}")
        # Detailed surgical steps would go here

robot = SurgicalRobot("John Doe")
robot.perform_surgery()
  `;

  const example3 = `
# Example 3: Service Robots for Hospitality
class ServiceRobot:
    def __init__(self, room_number):
        self.room_number = room_number

    def deliver_items(self, items):
        print(f"Delivering {items} to room {self.room_number}")
        # Code to navigate and deliver items

robot = ServiceRobot(101)
robot.deliver_items(["Towels", "Water"])
  `;

  const example4 = `
# Example 4: Autonomous Delivery Robots
class DeliveryRobot:
    def __init__(self, destination):
        self.destination = destination

    def deliver_package(self, package):
        print(f"Delivering {package} to {self.destination}")
        # Code to navigate to destination and deliver package

robot = DeliveryRobot("123 Main St")
robot.deliver_package("Pizza")
  `;

  return (
    <body>
      <Helmet>
        <title>AI Robotics Applications</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Robotics Applications, Industrial Robots, Medical Robots, Service Robots, Delivery Robots, AI Tutorial" />
        <meta name="description" content="Learn about AI robotics applications, including industrial robots for assembly lines, medical robots for surgery, service robots for hospitality, and autonomous delivery robots." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_robotics_software"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_ethics"><ButtonNext /></ScrollToTopLink>
          <h1>AI Robotics Applications</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>AI robotics applications span a wide range of fields, from manufacturing and healthcare to hospitality and logistics. This section covers various examples of how robots are used in different industries.</p>

            <h2>Examples of Robotics Applications</h2>

            <h3>Example 1: Industrial Robots for Assembly Lines</h3>
            <SyntaxHighlighterComponent code={example1} language="python" />
            <p>Industrial robots are used extensively in manufacturing and assembly lines to automate repetitive tasks, increase precision, and improve efficiency.</p>

            <h3>Example 2: Medical Robots for Surgery</h3>
            <SyntaxHighlighterComponent code={example2} language="python" />
            <p>Medical robots assist surgeons in performing complex procedures with greater accuracy and control, leading to better patient outcomes.</p>

            <h3>Example 3: Service Robots for Hospitality</h3>
            <SyntaxHighlighterComponent code={example3} language="python" />
            <p>Service robots are used in the hospitality industry to deliver items to guests, provide information, and enhance the overall customer experience.</p>

            <h3>Example 4: Autonomous Delivery Robots</h3>
            <SyntaxHighlighterComponent code={example4} language="python" />
            <p>Autonomous delivery robots are employed for last-mile deliveries, ensuring fast and efficient delivery of goods to customers.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_robotics_software"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_ethics"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
