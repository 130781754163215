import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLCorrelatedQueries() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const correlatedQueryCode = `
-- Basic Correlated Query
SELECT e1.employee_id, e1.employee_name
FROM employees e1
WHERE e1.salary > (
    SELECT AVG(e2.salary)
    FROM employees e2
    WHERE e2.department_id = e1.department_id
);
  `;

  const correlatedWithExistsCode = `
-- Correlated Query with EXISTS
SELECT employee_id, employee_name
FROM employees e
WHERE EXISTS (
    SELECT 1
    FROM departments d
    WHERE d.department_id = e.department_id
    AND d.location_id = 1700
);
  `;

  return (
    <body>
      <Helmet>
        <title>SQL Correlated Queries</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL Correlated Queries, SQL Subqueries, SQL Tutorial, Correlated Query Examples" />
        <meta name="description" content="Learn about SQL correlated queries, including syntax, examples, and using correlated queries with EXISTS." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_nested"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_ctes"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL Correlated Queries</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>SQL correlated queries are subqueries that reference columns from the outer query. They are evaluated once for each row processed by the outer query.</p>

            <h2>Basic Correlated Query</h2>
            <p>A correlated query uses columns from the outer query to produce results.</p>
            <SyntaxHighlighterComponent code={correlatedQueryCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The inner query calculates the average salary for each department using <code>e1.department_id</code>.</li>
              <li>The outer query selects employees whose salaries are above the average salary of their respective departments.</li>
            </ul>

            <h2>Correlated Query with EXISTS</h2>
            <p>The <code>EXISTS</code> clause checks for the existence of rows that meet certain criteria.</p>
            <SyntaxHighlighterComponent code={correlatedWithExistsCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The inner query checks if a department with the same <code>department_id</code> and <code>location_id</code> 1700 exists.</li>
              <li>The outer query selects employees who belong to these departments.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_nested"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_ctes"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
