import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactES6rest() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    function sum(...args) {
        return args.reduce((acc, val) => acc + val, 0);
      }
      
      console.log(sum(1, 2, 3, 4)); // Output: 10
            `

    const code2=`
    function multiply(multiplier, ...numbers) {
        return numbers.map(num => num * multiplier);
      }
      
      console.log(multiply(2, 1, 2, 3)); // Output: [2, 4, 6]
          `
  
     const code3=`
     const [first, ...rest] = [1, 2, 3, 4, 5];
     console.log(first); // Output: 1
     console.log(rest); // Output: [2, 3, 4, 5]
       `

         const code4=`
         const arr1 = [1, 2, 3];
         const arr2 = [4, 5, 6];
         const combined = [...arr1, ...arr2]; // Combine arr1 and arr2
        `

    return (
   <body>
     <Helmet>
        
        <title>React ES6 Rest Operator</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React ES6 Rest Operator, Use Cases, Concatenating Arrays, Copying Arrays, Merging Objects
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="The rest operator is used to represent an indefinite number of arguments as an array." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_es6-spread"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_jsx"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React ES6 Rest Operator</h1>
</header>

<Navbar />

<main>
<section>
<p>The rest operator (`...`) is used to represent an indefinite number of arguments as an array. </p>
  <p>It collects multiple elements into a single array parameter.</p>
  <h2>Syntax</h2>
 <SyntaxHighlighterComponent code={code} language="js" />

 <h2>Use Cases</h2>
 <h3>Function Parameters:</h3>
 <SyntaxHighlighterComponent code={code2} language="js" />

 <h3>Destructuring Assignments:</h3>
 <SyntaxHighlighterComponent code={code3} language="js" />

 <h3>Array Concatenation:</h3>
 <SyntaxHighlighterComponent code={code4} language="js" />

 <h2>Key Differences:</h2>
<ul>
    <li>Spread Operator: Used to split elements (e.g., arrays, objects) into individual parts.</li>
    <li>Rest Operator: Used to merge multiple elements into a single array parameter.</li>
</ul>
<p>While the spread operator spreads elements, the rest operator gathers them into an array.</p>
<p>They both provide powerful features for manipulating data structures in JavaScript and are widely used in modern JavaScript development.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_es6-spread"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_jsx"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}