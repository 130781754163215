import React from 'react';

export default function Portfolio() {
    const portfolioStyles = {
        backgroundColor: '#f9f9f9',
        padding: '20px',
        borderRadius: '5px',
    };

    return (
        <div>
            <h1>Personal Portfolio</h1>
            <div style={portfolioStyles}>
                <h2>My Projects</h2>
                <ul>
                    <li>Project 1</li>
                    <li>Project 2</li>
                    
                </ul>
            </div>
            <div>
                <h2>Code Snippet (HTML):</h2>
                <pre>{`
                <div class="portfolioStyles">
                    <h2>My Projects</h2>
                    <ul>
                        <li>Project 1</li>
                        <li>Project 2</li>
                        <!-- Add more projects as needed -->
                    </ul>
                </div>
                `}</pre>
            </div>
            <div>
                <h2>Code Snippet (CSS):</h2>
                <pre>{`
                  portfolioStyles {
                    background-color: #f9f9f9;
                    padding: 20px;
                    border-radius: 5px;
                }
                `}</pre>
            </div>
        </div>
    );
}
