import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import ScrollToTopLink from "../../components/ScrollToTop"; // Import the ScrollToTopLink component

export default function CSSBackgroundColors() {
  var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  }
  var secondCode = {
    color: 'black'
  }
  var thirdCode = {
    padding: '20px'
  }

  const code = `
    .example {
        background-color: #ff9900; /* Set background color to orange */
    }
  `;
  const code2 = `
    #example {
        background-color: #4285f4; /* Set paragraph background color to Google blue */
    }
  `;

  return (
    <body>
        <Helmet>     
        <title>CSS Background Colors</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Background Colors, How to set a background color, Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="The background-color property sets the color of an element's background. It's used to give a solid color to the background." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
      <br />
        <br />
        <br />
        <br />
        <br />
      <Sidenav />
      
      <div className="content">

        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/CSS_backgrounds"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/CSS_background_images"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>CSS Background Colors</h1>
        </header>

        <Navbar />

        <main>
          <section>
            <p>The background-color property sets the color of an element's background. It's used to give a solid 
              color to the background.</p>
            <p> It's an effective way to provide a solid and consistent background color to elements such as divs,
              sections, or entire web pages.</p>
            <br />
            <h2>Example:</h2>
            <SyntaxHighlighterComponent code={code} language="css" />
            <ScrollToTopLink to="/try_css23"><Tryitout /></ScrollToTopLink>
            <p>This can be shortened in this format:</p>
            <br />
            <h2>Example:</h2>
            <SyntaxHighlighterComponent code={code2} language="css" />
            <ScrollToTopLink to="/try_css24"><Tryitout /></ScrollToTopLink>
          </section>

        </main>
        <div className="head">
          <ScrollToTopLink to="/CSS_backgrounds"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/CSS_background_images"><ButtonNext /></ScrollToTopLink>
        </div>

      </div>

      <Footertutorials />
    </body>
  )
}
