import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PythonMetatag from "../../components/Python_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PythonWritingCode() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    greeting = "Hello World!"   #click enter
    print(greeting)   #output "Hello World!"
    `;
   
    const code2 = `
    python page.py
    `;
   
    return (
   <body>
      <Helmet>
        <title>Python Writing Code</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="Python Writing Code, Python Console, Python Files, Step-by-step Python programming tutorials, Python coding exercises for beginners, Advanced Python coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       Python programming tips for real-world applications," />
      <meta name="description" content="Learn how to write and exacute python on the python console streamline code organization, 
      save python files, and unlock advanced features in Python development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
        
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/Python_installing"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_display_output"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>Python Writing Code</h1>
</header>

<Navbar />

<main>
<section>
<p>Executing code in python is essential if you want to learn the language.</p>
<p>In this lesson you will learn how to write python code and how to execute it.</p>

<h2>Python Console</h2>
<p>The python console allows you to execute python code line by line.</p>
<p>If you have successfully installed python on your PC, you can have access to the python console by opening
    the command prompt
</p>
<p>Here is an example code you can copy and paste in the console, for
     every line of code you write remember to click enter.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code} language="python" />

<h2>Python Files</h2>
<p>All python files should be saved with the .py extension.</p>
<p>For example page.py just make sure to save the files.To run your python file on a PC , navigate to the
    folder where you created the .py file using the command prompt.
</p>
<p>Ensure that you run the following command</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="python" />
<p>The output displayed will be whatever python code you inputed into the file.</p>

</section>
</main>
<div className="head">
<ScrollToTopLink to="/Python_installing"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_display_output"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}