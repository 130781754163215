import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLAjaxRequests() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<!DOCTYPE html>
<html>
<body>

<h2>Basic AJAX Request</h2>
<div id="demo"></div>

<script>
function sendRequest() {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      document.getElementById("demo").innerHTML = this.responseText;
    }
  };
  xhttp.open("GET", "example.txt", true);
  xhttp.send();
}
sendRequest();
</script>

</body>
</html>
`;

  const example2 = `
<!DOCTYPE html>
<html>
<body>

<h2>AJAX Request with XML Data</h2>
<div id="content"></div>

<script>
function sendXMLRequest() {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      const xmlDoc = this.responseXML;
      const txt = xmlDoc.getElementsByTagName("message")[0].childNodes[0].nodeValue;
      document.getElementById("content").innerHTML = txt;
    }
  };
  xhttp.open("GET", "data.xml", true);
  xhttp.send();
}
sendXMLRequest();
</script>

</body>
</html>
`;

  const example3 = `
<!DOCTYPE html>
<html>
<body>

<h2>AJAX POST Request</h2>
<div id="result"></div>

<script>
function sendPostRequest() {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      document.getElementById("result").innerHTML = this.responseText;
    }
  };
  xhttp.open("POST", "submit.php", true);
  xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhttp.send("name=John&age=30");
}
sendPostRequest();
</script>

</body>
</html>
`;

const example4 =`
xhttp.setRequestHeader("Content-Type", "application/json");
`;

const example5 =`
const data = { name: "John", age: 30 };
xhttp.send(JSON.stringify(data));
`

const example6 =`
xhttp.onerror = function() {
  console.error("Request failed");
};
`

  return (
    <body>
      <Helmet>
        <title>XML AJAX - Requests</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML AJAX Requests, AJAX with XML, XML Tutorial, XML AJAX Examples" />
        <meta name="description" content="Learn how to use AJAX to send requests and handle responses with examples and explanations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_ajax_xmlhttp"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_ajax_responses"><ButtonNext /></ScrollToTopLink>
          <h1>XML AJAX - Requests</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>AJAX (Asynchronous JavaScript and XML) allows you to send and receive data asynchronously without reloading the entire page. AJAX requests are commonly used to fetch or submit data to a server.</p>

            <h2>Introduction to AJAX Requests</h2>
            <p>The <code>XMLHttpRequest</code> object is used to exchange data with a server. Below are examples of how to send different types of AJAX requests.</p>

            <h2>AJAX Request Methods</h2>

<p>This section covers the different methods used in AJAX requests to interact with servers. The methods include GET, POST, PUT, and DELETE, which are used to retrieve, submit, update, and delete data respectively.</p>

<table>
  <thead>
    <tr>
      <th>Method</th>
      <th>Description</th>
      <th>Example</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>GET</td>
      <td>Sends a request to retrieve data</td>
      <td><code>xhttp.open("GET", "example.txt", true);</code></td>
    </tr>
    <tr>
      <td>POST</td>
      <td>Sends a request to submit data</td>
      <td><code>xhttp.open("POST", "submit.php", true);</code></td>
    </tr>
    <tr>
      <td>onreadystatechange</td>
      <td>Handles the response data</td>
      <td><code>xhttp.onreadystatechange = function() &#123;...&#125;;</code></td>
    </tr>
    <tr>
      <td>PUT</td>
      <td>Sends a request to update data</td>
      <td><code>xhttp.open("PUT", "update.php", true);</code></td>
    </tr>
    <tr>
      <td>DELETE</td>
      <td>Sends a request to delete data</td>
      <td><code>xhttp.open("DELETE", "delete.php", true);</code></td>
    </tr>
  </tbody>
</table>
         <h2>Setting Request Headers</h2>
             <p>You can set request headers using the setRequestHeader method. Here is an example of how to set the Content-Type header:</p>
             <SyntaxHighlighterComponent code={example4} language="js" />
           <p>In this example, we set the Content-Type header to application/json, indicating that the request body contains JSON data.</p>

             <h2>Specifying Request Data</h2>
             <p>You can specify request data using the send method. Here is an example of how to send a JSON object:</p>
             <SyntaxHighlighterComponent code={example5} language="js" />
           <p>In this example, we define a JSON object containing the name and age properties.</p>
           <p>We then use the JSON.stringify method to convert the object to a string and pass it to the send method.</p>
           
            <h2>Example 1: Basic AJAX GET Request</h2>
            <SyntaxHighlighterComponent code={example1} language="html" />
            <p>This example demonstrates a basic AJAX GET request. The <code>sendRequest</code> function sends a request to the server and displays the response.</p>

            <h2>Example 2: AJAX Request with XML Data</h2>
            <SyntaxHighlighterComponent code={example2} language="html" />
            <p>In this example, an AJAX request is sent to fetch XML data. The <code>sendXMLRequest</code> function processes the XML response and displays the content.</p>

            <h2>Example 3: AJAX POST Request</h2>
            <SyntaxHighlighterComponent code={example3} language="html" />
            <p>This example shows how to send an AJAX POST request. The <code>sendPostRequest</code> function sends data to the server using the POST method and displays the response.</p>

           <h2>Handling Errors and Exceptions</h2>
             <p>You can handle errors and exceptions using the onerror and onreadystatechange events. Here is an example of how to handle a request error:</p>
             <SyntaxHighlighterComponent code={example6} language="js" />
           <p>In this example, we define an error handler function that will be called if the request fails. The function logs an error message to the console.</p>
           
            <h2>Benefits of Using AJAX Requests</h2>
            <ul>
              <li>Allows for asynchronous communication with the server.</li>
              <li>Improves user experience by updating parts of the page without reloading.</li>
              <li>Supports various HTTP methods, such as GET and POST.</li>
            </ul>

            <h2>Common Use Cases</h2>
            <ul>
              <li>Fetching dynamic content from the server.</li>
              <li>Submitting form data without reloading the page.</li>
              <li>Real-time updates and interactions with the server.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_ajax_xmlhttp"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_ajax_responses"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
