import React from 'react';
import './ClickableQuestions.css'; // Import CSS for styling

const ClickableQuestions = ({ questions, onClick }) => {
    const scrollLeft = () => {
        const container = document.querySelector('.clickable-questions-scroll-container');
        container.scrollLeft -= 100; // Adjust scroll amount as needed
      };
      
      const scrollRight = () => {
        const container = document.querySelector('.clickable-questions-scroll-container');
        container.scrollLeft += 100; // Adjust scroll amount as needed
      };
      
    return (
        <div className='clickable-questions-scroll-container'>
        
          <div className='clickable-questions-container'>
            {questions.map((question, index) => (
              <div
                key={index}
                className='clickable-question'
                onClick={() => onClick(question)}
              >
                {question}
              </div>
            ))}
          </div>
         
        </div>
      );
      
    };      
export default ClickableQuestions;
