import React from "react";
import "../../../css/my style.css";
import "../../../css/my style3.css";
import "../../../components/my script";
import Sidenav from "../../../components/Sidenav/PythonSidenav";
import Navbar from "../../../components/navbar";
import Footertutorials from "../../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../../components/Buttonnext";
import Tryitout from "../../../components/Tryitoutbutton";
import PythonMetatag from "../../../components/Python_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../../components/Buttonprevious";
import ScrollToTopLink from "../../../components/ScrollToTop";



export default function PythonVariablesNaming() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    my_var = 10
    _myVar = 20
    myVar2 = 30
     `;
   
    const code2 = `
    2myVar = 10   # Cannot start with a number
    my-var = 20   # Hyphens are not allowed
    my var = 30   # Spaces are not allowed
     `;

    const code3 = `
    x = 10
    counter = 10
    user_name = "Alice"
     `;

    const code4 = `
    total_score = 100
    `;

    const code5 = `
    # Good examples
    student_name = "John"
    total_amount = 150.75
    
    # Bad examples
    s = "John"          # Not descriptive
    ta = 150.75         # Not descriptive
    studentName = "John"  # Avoid camelCase, prefer snake_case
     `;
 
    return (
   <body>
     <Helmet>
        
        <title>Python Variable Naming</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="Python Variables Naming, Naming Rules, Valid Variable Names, Invalid Variable Names, Naming Conventions, Use descriptive names, Use snake_case for multi-word variables, Changing Values, Type Conversion, Examples" />
      <meta name="description" content="When naming variables in Python, follow these rules and conventions to ensure your code is readable and error-free." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/Python_variables"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_variables_assigning"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>Python Variable Naming</h1>
</header>

<Navbar />

<main>
<section>
<p>When naming variables in Python, follow these rules and conventions to ensure your code is readable and error-free.</p>
   <h2>Naming Rules</h2>
<ol type="1">
    <li>Start with a letter or underscore: Variable names must begin with a letter (a-z, A-Z) or an underscore (_).</li>
     <li>Use letters, numbers, and underscores: The rest of the variable name can include letters, numbers, or underscores.</li>
     <li><b>Case-sensitive:</b> Variable names are case-sensitive, meaning 'myVar' and 'myvar' are different.</li>
</ol>

<h2>Valid Variable Names</h2>
<SyntaxHighlighterComponent code={code} language="python" />

<h2>Invalid Variable Names</h2>
<p>These examples will cause errors:</p>
<SyntaxHighlighterComponent code={code2} language="python" />

<h2>Naming Conventions</h2>
<p>While not enforced by Python, these conventions help maintain readability:</p>
<ol>
    <li><b>Use descriptive names:</b> Choose names that describe the variable’s purpose.</li><br />
    <SyntaxHighlighterComponent code={code3} language="python" /><br />

    <li><b>Use snake_case for multi-word variables:</b> Separate words with underscores.</li><br />
    <SyntaxHighlighterComponent code={code4} language="python" />
</ol>

<h2>Changing Values</h2>
<p>Variables can change type dynamically:</p>
<SyntaxHighlighterComponent code={code3} language="python" />

<h2>Type Conversion</h2>
<p>Convert between types using built-in functions:</p>
<SyntaxHighlighterComponent code={code4} language="python" />

<h2>Examples</h2>
<SyntaxHighlighterComponent code={code5} language="python" />

<h2>Conclusion</h2>
<p>Adhering to naming rules and conventions makes your code more understandable and maintainable. </p>
<p>Good variable names help communicate the purpose of your variables clearly.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/Python_variables"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_variables_assigning"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}