import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLcolors() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <h1 style="color:blue"> My color is blue. </h1>
      <h1 style="background-color:blue"> My background-color is blue. </h1>
  `;
  
     const code2 = `
     <p style="color:#000000">I am black</p>
     <p style="color:#ffffff">I am white</p>
     <p style="color:#ff0000">I am red</p>
     <p style="color:#00ff00">I am green</p>
     <p style="color:#0000ff">I am blue/p>
     <p style="color:#ff00ff">I am purple</p>
     <p style="color:#ffff00">I am yellow</p>
  `;
     const code3 = `
     <p style="color:#000">I am black</p>
     <p style="color:#fff">I am white</p>
     <p style="color:#f00">I am red</p>
     <p style="color:#0f0">I am green</p>
     <p style="color:#00f">I am blue</p>
     <p style="color:#f0f">I am purple>/p>
     <p style="color:#ff0">I am yellow</p>
  `;
    const code4 = `
    <p style="color:rgb(0,0,0)">I am black</p>
    <p style="color:rgb(255,255,255)">I am white</p>
    <p style="color:rgb(255,0,0)">I am red</p>
    <p style="color:rgb(0,255,0)">I am green</p>
    <p style="color:rgb(0,0,255)">I am blue</p>
    <p style="color:rgb(255,0,255)">I am purple</p>
    <p style="color:rgb(255,255,0)">I am yellow</p>
  `;
     const code5 = `
     <p style="color:hsl(0,0%,0%)">I am black</p>
     <p style="color:hsl(240,50%,50%)">I am blue</p>
     <p style="color:hsl(120,60%,60%)">I am green</p>
  `;
  const code6 = `
  <!--Half opaque and Half Transparent-->
  <p style="color:rgba(255,0,0,0.5)">I am red</p>
  <!--Fully opaque-->
  <p style="color:rgba(255,0,255,1)">I am purple</p>
  <!--Fully Transparent-->
  <p style="color:hsla(200,50%,50%,0)">I am blue</p>
  <!--Fully opaque-->
  <p style="color:hsla(360,50%,50%,1)">I am blue</p>
  
`;
    return (
   <body>
    <Helmet>    
        <title>HTML Colors</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Colors, Types of HTML Colors, Color name, hexadecimal colors(hex)red green and blue(rgb), hue saturation and lightness(hsl)
         Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
      <meta name="description" content="In this lesson we will use inline styling with CSS properties background-color and color to change the background-color and color of HTML elements.
      " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
<br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">
<header className="headertutorials" style={newCode}>
 
<ScrollToTopLink to="/HTML_styles"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_links"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Colors</h1>
</header>

<Navbar />

<main>


<section>
<p>In this lesson we will use inline styling with CSS properties background-color and color to change the background-color and color of HTML elements.</p>
<h3>Types of HTML Colors</h3>
<li>Color name</li>
<li>hexadecimal colors(hex)</li>
<li>red green and blue(rgb)</li>
<li>hue saturation and lightness(hsl)</li>
<h2>Color Name</h2>
<p>It defines any color valueby its color name</p>
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html23"><Tryitout /></ScrollToTopLink>

<h2>Hexadecimal Colors (hex)</h2>
<h3>Important things to note</h3>
<li>hex colors introduces a pound sign #.</li>
<li>It contains six characters e.g #000000.</li>
<li>The first two characters for red the next two for green and the last two for blue.</li>
<li>hex color range starts from 0 which is the lowest intensity of light and it's black and f being the highest intensity of light which is white.</li>
<li>hex colors runs from 0-9 and a-f ie 123456789abcdef</li>
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html24"><Tryitout /></ScrollToTopLink>

<h2>Short Hand Example</h2>
<SyntaxHighlighterComponent code={code3} language="html" />
<ScrollToTopLink to="/try_html25"><Tryitout /></ScrollToTopLink>

</section>


<section>
    <h2>RGB Colors</h2>
    <p>It defines a color using this format "rgb(number, number, number)" e.g rgb(255,255,255).</p>
    <p>The numbers represent the intensity of red,green and blue in that order respectively</p>
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code4} language="html" />
<ScrollToTopLink to="/try_html26"><Tryitout /></ScrollToTopLink>


<h2>HSL(Hue, Saturation, Lightness) Colors</h2>
<h3>Hue</h3>
<p>This represents the type of color, essentially the angle on the color wheel.</p>
<p>It ranges from 0 to 360 degrees, where 0° and 360° are red, 120° is green, and 240° is blue, with
 other colors in between.</p>
 <h2>Saturation</h2>
 <p>This represents the intensity or purity of the color. </p>
 <p> A saturation of 100% means the color is fully intense, while a saturation of 0% results in
     a shade of gray.</p>
    <h2>Lightness</h2>
    <p>This represents how light or dark the color is. </p>
    <p>A lightness of 0% results in black, while a lightness of 100% results in white.
         A lightness of 50% is the "normal" color.</p>
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code5} language="html" />
<ScrollToTopLink to="/try_html27"><Tryitout /></ScrollToTopLink>

<h2>Alpha Channel</h2>
<p>The alpha channel determines the opacity or transparency of the color.The range for the alpha channel is from 0.0 to 1.0.</p>
<p>The alpha channel can only be applied to the rgb and hsl color values e.g rgba and hsla.</p>
<p>By default the opacity of HTML colors is 1</p>
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code6} language="html" />
<ScrollToTopLink to="/try_html28"><Tryitout /></ScrollToTopLink>
</section>
 
</main>
<div className="head">
<ScrollToTopLink to="/HTML_styles"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_links"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
   
    )
}