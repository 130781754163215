import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import RubyMetatag from "../../components/Ruby_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function Rubyenvironment() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    sudo apt-get update
    sudo apt-get install ruby-full
`;
const code2 = `
brew install ruby
`;
const code3 = `
ruby -v
`;       
const code4 = `
gem -v
`;
const code5 = `
gem update --system
`;
const code6 = `
gem install bundler
`;
const code7 = `
# Gemfile
source 'https://rubygems.org'

gem 'sinatra'
gem 'rails'
# Add more gems as needed
`;
const code8 = `
bundle install
`;
const code9 = `
\curl -sSL https://get.rvm.io | bash -s stable
`;
const code10 = `
rvm install ruby
`;
const code11 = `
rvm use ruby-2.7.4
`;

    return (
   <body>
      <Helmet>
        
        <title>Ruby Environment Setup</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="Ruby environment setup, Install Ruby, Verify Ruby installation, Install Text Editor, Install Bundler, Create a Gemfile, Install Gems
     Step-by-step Ruby programming tutorials Ruby coding exercises for beginners, Advanced Ruby coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       Ruby programming tips for real-world applications," />
      <meta name="description" content="Learn how Ruby classes streamline code organization, 
      enhance readability, and unlock advanced features in Ruby development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/Ruby_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Ruby_syntax"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>Ruby Environment</h1>
</header>

<Navbar />

<main>
<section>
<p>Setting up the Ruby environment involves installing Ruby, managing gems (Ruby libraries), and potentially
   using a version manager. </p>
<p> Here's a step-by-step guide to set up your Ruby environment:</p>
<h2>Install Ruby</h2>
<p>You can download and install Ruby from the official website or use a package manager.</p>
<h3>Using Package Manager (Linux/macOS):</h3>
<p>On Linux and macOS, you can use a package manager like apt (for Ubuntu/Debian) or brew (for macOS).</p>
<br />
<p><b>Ubuntu/Debian:</b></p>
<SyntaxHighlighterComponent code={code} language="ruby" />

<p><b>macOS (with Homebrew):</b></p>
<SyntaxHighlighterComponent code={code2} language="ruby" />

<h3>Using Ruby Installer (Windows):</h3>
<p>On Windows, download the RubyInstaller from the official website and run the installer executable.</p>

<h2>Verify Ruby Installation</h2>
<p>Open a terminal and run:</p>
<SyntaxHighlighterComponent code={code3} language="ruby" />

<p>You should see the installed Ruby version.</p>

<h2>Install a Text Editor</h2>
<p>Choose a text editor for writing Ruby code. Options include Visual Studio Code, Sublime Text, Atom, and more.</p>

<h2>Using RubyGems</h2>
<p>RubyGems is Ruby's package manager for installing libraries. It's typically included with Ruby 
  installations.</p>
  <h3>Check RubyGems:</h3>
  <p>Run this command to ensure RubyGems is installed:</p>
  <SyntaxHighlighterComponent code={code4} language="ruby" />
  <h3>Update RubyGems:</h3>
  <p>Update RubyGems to the latest version:</p>
  <SyntaxHighlighterComponent code={code5} language="ruby" />

  <h2>Install Bundler</h2>
  <p>Bundler is a tool for managing gems and their dependencies in your projects.</p>
  <h3>Install Bundler:</h3>
  <p>Run this command to install Bundler:</p>
  <SyntaxHighlighterComponent code={code6} language="ruby" />

  <h2>Create a Gemfile</h2>
  <p>In your project directory, create a Gemfile using a text editor.
     This file will list the gems your project needs.</p>
     <SyntaxHighlighterComponent code={code7} language="ruby" />

    <h2>Install Gems</h2>
    <p>In your project directory, run Bundler to install the gems listed in the Gemfile:</p>
    <SyntaxHighlighterComponent code={code8} language="ruby" />

    <p>Bundler will install the gems and manage their versions.</p>
    <h2>Using a Version Manager (Optional)</h2>
    <p>A version manager lets you switch between different versions of Ruby easily. One popular option is RVM 
      (Ruby Version Manager).</p>
      <h3>Install RVM:</h3>
      <SyntaxHighlighterComponent code={code9} language="ruby" />

    <h3>Install Ruby with RVM:</h3>
    <SyntaxHighlighterComponent code={code10} language="ruby" />

    <h3>Use a Specific Ruby Version:</h3>
    <SyntaxHighlighterComponent code={code11} language="ruby" />
    
    <p>One thing to remember is that the environment setup can vary depending on your operating system and 
      requirements.</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/Ruby_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Ruby_syntax"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}