import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLAjaxXMLHttp() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<!DOCTYPE html>
<html>
<body>

<h2>Basic AJAX XMLHttpRequest Example</h2>
<div id="demo"></div>

<script>
function loadXML() {
  const xhttp = new XMLHttpRequest();
  xhttp.onload = function() {
    document.getElementById("demo").innerHTML = this.responseText;
  }
  xhttp.open("GET", "example.xml", true);
  xhttp.send();
}
loadXML();
</script>

</body>
</html>
`;

  const example2 = `
<!DOCTYPE html>
<html>
<body>

<h2>AJAX XMLHttpRequest to Load XML Data</h2>
<div id="output"></div>

<script>
function loadXMLDoc() {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      const xmlDoc = this.responseXML;
      const txt = xmlDoc.getElementsByTagName("title")[0].childNodes[0].nodeValue;
      document.getElementById("output").innerHTML = txt;
    }
  };
  xhttp.open("GET", "data.xml", true);
  xhttp.send();
}
loadXMLDoc();
</script>

</body>
</html>
`;

  const example3 = `
<!DOCTYPE html>
<html>
<body>

<h2>Using XMLHttpRequest to Fetch and Display XML Data</h2>
<div id="content"></div>

<script>
function fetchXMLData() {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      const xmlDoc = this.responseXML;
      const titles = xmlDoc.getElementsByTagName("title");
      let html = "";
      for (let i = 0; i < titles.length; i++) {
        html += "<p>" + titles[i].childNodes[0].nodeValue + "</p>";
      }
      document.getElementById("content").innerHTML = html;
    }
  };
  xhttp.open("GET", "books.xml", true);
  xhttp.send();
}
fetchXMLData();
</script>

</body>
</html>
`;

  return (
    <body>
      <Helmet>
        <title>XML AJAX - XMLHttpRequest</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML AJAX XMLHttpRequest, AJAX with XMLHttpRequest, XML Tutorial, XML AJAX Examples" />
        <meta name="description" content="Learn how to use XMLHttpRequest with XML data with examples and explanations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_ajax"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_ajax_requests"><ButtonNext /></ScrollToTopLink>
          <h1>XML AJAX - XMLHttpRequest</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>The <code>XMLHttpRequest</code> object is used to interact with servers. You can retrieve data from a URL without having to do a full page refresh. This enables a web page to update just part of a page without disrupting what the user is doing.</p>

            <h2>Introduction to XMLHttpRequest</h2>
            <p>The <code>XMLHttpRequest</code> object is used to exchange data with a server behind the scenes. This means that it is possible to update parts of a web page, without reloading the whole page. Below are examples of how to use <code>XMLHttpRequest</code> to fetch and display XML data.</p>

            <h2>Example 1: Basic XMLHttpRequest</h2>
            <SyntaxHighlighterComponent code={example1} language="html" />
            <p>This example shows a basic use of <code>XMLHttpRequest</code> to load and display XML data. The <code>loadXML</code> function sends a request to the server and processes the response XML.</p>

            <h2>Example 2: Loading and Parsing XML Data</h2>
            <SyntaxHighlighterComponent code={example2} language="html" />
            <p>In this example, we fetch an XML file and display its content. The <code>loadXMLDoc</code> function handles the AJAX request and processes the XML response to extract data.</p>

            <h2>Example 3: Fetching and Displaying XML Content</h2>
            <SyntaxHighlighterComponent code={example3} language="html" />
            <p>This example demonstrates how to fetch XML data and display multiple elements from the XML document. The <code>fetchXMLData</code> function processes the XML response and updates the web page accordingly.</p>

            <h2>Benefits of Using XMLHttpRequest with XML</h2>
            <ul>
              <li>Allows asynchronous communication with the server.</li>
              <li>Updates parts of the web page without reloading.</li>
              <li>Supports structured data exchange using XML format.</li>
            </ul>

            <h2>Common Use Cases</h2>
            <ul>
              <li>Loading dynamic content without refreshing the entire page.</li>
              <li>Fetching configuration or metadata for web applications.</li>
              <li>Implementing real-time data updates and interactions.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_ajax"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_ajax_requests"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
