import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIComputerVision() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const computerVisionExample = `
# Example of Image Classification with TensorFlow and Keras
import tensorflow as tf
from tensorflow.keras.models import Sequential
from tensorflow.keras.layers import Dense, Conv2D, Flatten
from tensorflow.keras.datasets import mnist
from tensorflow.keras.utils import to_categorical

# Load the dataset
(x_train, y_train), (x_test, y_test) = mnist.load_data()

# Preprocess the data
x_train = x_train.reshape((x_train.shape[0], 28, 28, 1))
x_test = x_test.reshape((x_test.shape[0], 28, 28, 1))
x_train, x_test = x_train / 255.0, x_test / 255.0

# One-hot encode the labels
y_train = to_categorical(y_train)
y_test = to_categorical(y_test)

# Build the model
model = Sequential([
    Conv2D(32, kernel_size=(3, 3), activation='relu', input_shape=(28, 28, 1)),
    Flatten(),
    Dense(128, activation='relu'),
    Dense(10, activation='softmax')
])

# Compile the model
model.compile(optimizer='adam', loss='categorical_crossentropy', metrics=['accuracy'])

# Train the model
model.fit(x_train, y_train, validation_data=(x_test, y_test), epochs=10)

# Evaluate the model
score = model.evaluate(x_test, y_test, verbose=0)
print('Test loss:', score[0])
print('Test accuracy:', score[1])
  `;

  return (
    <body>
      <Helmet>
        <title>AI Computer Vision</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Computer Vision, Image Classification, TensorFlow, Keras, AI Tutorial" />
        <meta name="description" content="Learn about AI computer vision, its applications, and practical examples using frameworks like TensorFlow and Keras." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/AI_language_models"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/AI_image_processing"><ButtonNext /></ScrollToTopLink>
          <h1>AI Computer Vision</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Computer vision is a field of artificial intelligence that trains computers to interpret and understand the visual world. Using digital images from cameras and videos and deep learning models, machines can accurately identify and classify objects, and then react to what they “see.”</p>

            <h2>Introduction to Computer Vision</h2>
            <p>Computer vision has a wide range of applications, from facial recognition and autonomous driving to medical image analysis and augmented reality. Popular frameworks for developing computer vision models include TensorFlow, PyTorch, and OpenCV.</p>

            <h2>Example: Image Classification with TensorFlow and Keras</h2>
            <SyntaxHighlighterComponent code={computerVisionExample} language="python" />
            <p>In this example, we use TensorFlow and Keras to build and train a convolutional neural network (CNN) for image classification on the MNIST dataset. The MNIST dataset contains handwritten digits, and the goal is to classify each digit correctly.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_language_models"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_image_processing"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
