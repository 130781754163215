import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSComments() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
    // This function dynamically changes the content of the h1
    function sayHello() {
      var note = "Hello World! how are you?";
      document.getElementById("example").innerHTML = note;
    }
    /* Alternative format */
`;
const code2 = `
        var a = 3;
        var b = 7; 
        var multiply = a * b;
         /* the first variable has a
         value which is 3 and the second
         variable has a value which is 7
         the third variable multiplies
         both the first and the second 
         variable to give an output of 21 */
`;

    return (
   <body>
    <Helmet>
    
    <title>JavaScript Comments</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="JavaScript Comments, Step-by-step JavaScript programming tutorials, JavaScript coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
   JavaScript programming tips for real-world applications," />
  <meta name="description" content="Learn how JavaScript Comments streamline code organization, 
  enhance readability, and unlock advanced features in JavaScript development." />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_syntax"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_environment"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Comments</h1>
</header>

<Navbar />

<main>
    <section>
    <p>JavaScript comments are used to give a short description,hints,suggestions for a piece of code.They are used to make programming
    easier and fun.
</p>
<p>In JavaScript there are two types of comments,they are:</p>
<li>single-line comments</li>
<li>multi-line comments</li>

    <h2>Single-line Comments</h2>
    <p>Single-line comments are used when your comment spans only a single line.To add a single-line comment 
        you make use of two forward slashes //.Alternatively another more flexible way to add a comment is to
        make use of the /* */ where the comment goes in between the asterisks.
    </p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
<ScrollToTopLink to="/try_js6"><Tryitout /></ScrollToTopLink>
  
    <br />
<h2>Multi-Line Comments</h2>
<p>TO make use of multi-line comments make use of the /* */ but in this format.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js7"><Tryitout /></ScrollToTopLink>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_syntax"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_environment"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}