import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPSwitch() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    switch (expression) {
        case value1:
            // Code to be executed if expression matches value1
            break;
        case value2:
            // Code to be executed if expression matches value2
            break;
        // You can have multiple cases
        default:
            // Code to be executed if none of the above cases match
    }
        `;
    const code2 = `
    $day = "Monday";

    switch ($day) {
        case "Monday":
            echo "Wash clothes";
            break;
        case "Tuesday":
        case "Wednesday":
        case "Thursday":
            echo "Work on projects";
            break;
        case "Friday":
            echo "Go grocery shopping";
            break;
        default:
            echo "Relax";
    }
          `;
    const code3 = `
    $i = 0;
    do {
        echo "Iteration $i <br>";
        $i++;
    } while ($i < 5);
       `;
    const code4 = `
    $fruits = array("Apple", "Banana", "Orange");
    foreach ($fruits as $fruit) {
        echo $fruit . "<br>";
    }
       `;

    return (
   <body>
   <Helmet>
        <title>PHP Loops</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Switch Stetement, 
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="" />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_loops"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_function"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Switch Statement</h1>
</header>

<Navbar />

<main>
<section>
<p>The 'switch' statement in PHP is a type of control structure used for decision-making.</p>
<p>It allows you to execute different blocks of code depending on the value of a variable or an expression. </p>
<p>It is particularly useful when you have multiple conditions to check against a single variable.</p>
<p>Here's a breakdown of how the switch statement works:</p>

<h2>Syntax</h2>
<p>The basic syntax of a switch statement in PHP looks like this:</p>
<br />
<SyntaxHighlighterComponent code={code} language="php" />
<h3>How it works:</h3>
<ul>
    <li>The expression inside the 'switch' parentheses is evaluated once and its value is compared with the values in each 'case' statement.</li>
    <li>If a 'case' value matches the value of the 'expression', the code block associated with that 'case' is executed.</li>
    <li>The 'break' statement is used to exit the switch block. If omitted, execution will continue to the next 'case' block, which can lead to unexpected behavior.</li>
</ul>

<h2>Example</h2>
<p>Let's say you have a variable '$day' representing the day of the week and you want to execute different actions based on its value:</p>
<br />
<SyntaxHighlighterComponent code={code2} language="php" />
<h3>In this example:</h3>
<ul>
    <li>If '$day' is 'Monday', it will output 'Wash clothes'.</li>
    <li>If '$day' is 'Tuesday', 'Wednesday', or Thursday', it will output 'Work on projects'.</li>
    <li>If '$day' is 'Friday', it will output 'Go grocery shopping'.</li>
    <li>For any other value of '$day', it will output 'Relax'.</li>
</ul>
<p>This is how the switch statement can be used for decision-making in PHP, providing a cleaner alternative to nested if-else statements in certain scenarios.</p>

</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_loops"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_function"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}