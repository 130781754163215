import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ProgressContext } from './ProgressContext';

const TrackProgress = ({ children }) => {
  const location = useLocation();
  const { markAsComplete } = useContext(ProgressContext);

  useEffect(() => {
    const tutorialRoutes = [
        '/HTML_Tutorial_intro',
        '/HTML_editors',
        '/HTML_comments',
        '/HTML_elements',
        '/HTML_attributes',
        '/HTML_attributes pt.2',
        '/HTML_heading',
        '/HTML_paragraph',
        '/HTML_styles',
        '/HTML_colors',
        '/HTML_links',
        '/HTML_horizontal_rule',
        '/HTML_line_break',
        '/HTML_text_formatting',
        '/HTML_section',
        '/HTML_image',
        '/HTML_block_inline',
        '/HTML_subscript',
        '/HTML_superscript',
        '/HTML_figures',
        '/HTML_nbsp',
        '/HTML_entities',
        '/HTML_pre',
        '/HTML_lists',
        '/HTML_unordered',
        '/HTML_ordered',
        '/HTML_description',
        '/HTML_forms',
        '/HTML_forms_attr',
        '/HTML_forms_attr2',
        '/HTML_js',
        '/HTML_input',
        '/HTML_input2',
    
       '/CSS_Tutorial_intro',
       '/CSS_inserting',
       '/CSS_comments',
       '/CSS_syntax',
       '/CSS_selectors',
       '/CSS_combinators',
       '/CSS_colors',
       '/CSS_text_color',
       '/CSS_backgrounds',
       '/CSS_background_colors',
       '/CSS_background_images',
       '/CSS_background_attachment',
       '/CSS_width',
       '/CSS_height',
    
       '/JavaScript_Tutorial_intro',
       '/JS_writing_code',
       '/JS_syntax',
       '/JS_comment',
       '/JS_environment',
       '/JS_display_output',
       '/JS_method',
       '/JS_variables',
       '/JS_data_types',
       '/JS_operators',
       '/JS_arithmetic_operators',
       '/JS_assignment_operators',
       '/JS_logical_conditional_operators',
       '/JS_comparison_operators',
       '/JS_typeof_operators',
    
       '/PHP_Tutorial_intro',
       '/PHP_environment_setup',
       '/PHP_syntax',
       '/PHP_comments',
       '/PHP_variables',
       '/PHP_datatypes',
       '/PHP_echo',
       '/PHP_print',
       '/PHP_operators',
       '/PHP_arithmetic',
       '/PHP_assignment',
       '/PHP_comparison',
       '/PHP_logical',
       '/PHP_increment',
       '/PHP_decrement',
       '/PHP_string',
       '/PHP_array',
       '/PHP_conditional',
       '/PHP_loops',
       '/PHP_switch',
       '/PHP_functions',
    
       '/Python_Tutorial_intro',
       '/Python_installing',
       '/Python_writing_code',
       '/Python_display_output',
       '/Python_syntax',
       '/Python_comments',
    
       '/Ruby_Tutorial_intro',
       '/Ruby_environment',
       '/Ruby_syntax',
       '/Ruby_classes',
       '/Ruby_comments',
    
       '/XML_Tutorial_intro',
       '/XML_syntax',
       '/XML_elements',
       '/XML_attributes',
    
       '/React_Tutorial_intro',
       '/React_environment_setup',
       '/React_create-react-app',
       '/React_es6',
       '/React_es6-modules',
       '/React_es6-arrow-functioning',
       '/React_es6-destructuring',
       '/React_es6-template-literals',
       '/React_es6-classes',
       '/React_es6-spread',
       '/React_es6-rest',
       '/React_jsx',
       '/React_components',
       '/React_state',
       '/React_props',
       '/React_event',
       '/React_conditional',
       '/React_lists',
       '/React_keys',
       '/React_forms',
       '/React_router',
    
       '/SQL_Tutorial_intro',
       '/SQL_queries',
       '/SQL_comments',
       '/AI_Tutorial_intro',
       '/AI_history',
       '/AI_types',
       '/AI_problem_solving'
    
    ];

    if (tutorialRoutes.includes(location.pathname)) {
      markAsComplete(location.pathname);
    }
  }, [location, markAsComplete]);

  return children;
};

export default TrackProgress;
