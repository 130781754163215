import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIGANs() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const ganIntroductionCode = `
# GAN Example in PyTorch
import torch
import torch.nn as nn

# Define Generator and Discriminator
class Generator(nn.Module):
    def __init__(self):
        super(Generator, self).__init__()
        # Define layers

class Discriminator(nn.Module):
    def __init__(self):
        super(Discriminator, self).__init__()
        # Define layers

# Initialize Generator and Discriminator
generator = Generator()
discriminator = Discriminator()
  `;

  const ganTrainingCode = `
# Training GAN
# Define loss function and optimizer
loss_function = nn.BCELoss()
optimizer_G = torch.optim.Adam(generator.parameters(), lr=0.0002, betas=(0.5, 0.999))
optimizer_D = torch.optim.Adam(discriminator.parameters(), lr=0.0002, betas=(0.5, 0.999))

# Training loop
for epoch in range(num_epochs):
    for i, (images, _) in enumerate(dataloader):
        # Train Discriminator

        # Train Generator
  `;

  return (
    <body>
      <Helmet>
        <title>AI Generative Adversarial Networks</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI GANs, AI Generative Adversarial Networks, AI Tutorial, GANs in PyTorch" />
        <meta name="description" content="Explore AI GANs (Generative Adversarial Networks), their architecture, and implementation using frameworks like PyTorch with practical examples." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/AI_lstmns"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/AI_nlp"><ButtonNext /></ScrollToTopLink>
          <h1>AI Generative Adversarial Networks</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Generative Adversarial Networks (GANs) are a type of AI model framework used in unsupervised machine learning, introduced by Ian Goodfellow and his colleagues in 2014. GANs are composed of two neural networks, a generator and a discriminator, which compete against each other.</p>

            <h2>Introduction to GANs</h2>
            <p>GANs consist of a generator network that generates new instances of data, and a discriminator network that evaluates the generated samples for authenticity. Here's a basic example of a GAN in PyTorch:</p>
            <SyntaxHighlighterComponent code={ganIntroductionCode} language="python" />

            <h2>Training a GAN</h2>
            <p>Training a GAN involves optimizing both the generator and discriminator networks concurrently. Here's how you can train a GAN:</p>
            <SyntaxHighlighterComponent code={ganTrainingCode} language="python" />
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_lstmns"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_nlp"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
