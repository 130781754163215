import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyHashMethods() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const keysValuesCode = `
# Retrieving Keys and Values
person = { name: 'Alice', age: 30, city: 'New York' }

puts person.keys   # Output: [:name, :age, :city]
puts person.values # Output: ["Alice", 30, "New York"]
  `;

  const mergeCode = `
# Merging Hashes
person = { name: 'Alice', age: 30 }
contact = { email: 'alice@example.com', phone: '123-456-7890' }

full_info = person.merge(contact)

puts full_info
# Output: {:name=>"Alice", :age=>30, :email=>"alice@example.com", :phone=>"123-456-7890"}
  `;

  const selectRejectCode = `
# Filtering Hashes
person = { name: 'Alice', age: 30, city: 'New York', occupation: 'Engineer' }

adults = person.select { |key, value| key == :age && value >= 18 }
non_adults = person.reject { |key, value| key == :age && value >= 18 }

puts adults
puts non_adults
# Output: {:age=>30}
# Output: {:name=>"Alice", :city=>"New York", :occupation=>"Engineer"}
  `;

  const transformCode = `
# Transforming Hashes
person = { name: 'Alice', age: 30, city: 'New York' }

uppercase_keys = person.transform_keys { |key| key.to_s.upcase }
uppercase_values = person.transform_values { |value| value.to_s.upcase }

puts uppercase_keys
puts uppercase_values
# Output: {"NAME"=>"Alice", "AGE"=>30, "CITY"=>"New York"}
# Output: {:name=>"ALICE", :age=>"30", :city=>"NEW YORK"}
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Hash Methods</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Hash Methods, Retrieving Keys, Retrieving Values, Merging Hashes, Filtering Hashes, Transforming Hashes" />
        <meta name="description" content="Learn about various methods available for hashes in Ruby, including retrieving keys and values, merging hashes, filtering, and transforming hashes." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_hashes"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_strings"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Hash Methods</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Ruby provides a variety of methods for working with hashes, allowing you to retrieve keys and values, merge hashes, filter, and transform hashes efficiently.</p>

            <h2>Retrieving Keys and Values</h2>
            <p>Use <code>keys</code> to retrieve all the keys and <code>values</code> to retrieve all the values in a hash.</p>
            <SyntaxHighlighterComponent code={keysValuesCode} language="ruby" />
            <p>In the above example, we retrieve the keys and values of a hash representing a person.</p>

            <h2>Merging Hashes</h2>
            <p>Use <code>merge</code> to combine two hashes into a new hash.</p>
            <SyntaxHighlighterComponent code={mergeCode} language="ruby" />
            <p>In the above example, we merge a hash with personal information and a hash with contact information into a new hash.</p>

            <h2>Filtering Hashes</h2>
            <p>Use <code>select</code> to filter key-value pairs based on a condition and <code>reject</code> to exclude key-value pairs based on a condition.</p>
            <SyntaxHighlighterComponent code={selectRejectCode} language="ruby" />
            <p>In the above example, we filter the hash to include only adults and exclude adults using <code>select</code> and <code>reject</code> respectively.</p>

            <h2>Transforming Hashes</h2>
            <p>Use <code>transform_keys</code> and <code>transform_values</code> to transform the keys and values of a hash respectively.</p>
            <SyntaxHighlighterComponent code={transformCode} language="ruby" />
            <p>In the above example, we transform the keys to uppercase and the values to uppercase using <code>transform_keys</code> and <code>transform_values</code>.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_hashes"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_strings"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
