import React from 'react';
import '../css/my style.css';
import '../css/my style2.css';
import '../css/my style6.css';


 function Header() {

const headerTutorials = {
    padding: '140px',
    textAlign: 'center',
    background: 'darkblue',
    textAlign: 'center'
}
 const headerTutorialsh1 = {
    fontSize: '50px',
    color: 'white',
    textAlign: 'center'
 }
    return (
    <header style={headerTutorials}>
     <div className="header-content">
     <div className="centered-content">
   <h1 className="topple" style={headerTutorialsh1}>
        <span>P</span>
        <span>r</span>
        <span>o</span>
        <span>j</span>
        <span>e</span>
        <span>c</span>
        <span>t</span>
        <span>s</span>
       
  
  </h1>
  </div>
  </div>
      </header>
    );
}
export default Header;