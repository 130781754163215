import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIImageSegmentation() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const unetExample = `
# Example of Image Segmentation with U-Net and Keras
import tensorflow as tf
from tensorflow.keras.models import Model
from tensorflow.keras.layers import Input, Conv2D, MaxPooling2D, UpSampling2D, Concatenate
from tensorflow.keras.preprocessing.image import img_to_array, load_img
import numpy as np
import matplotlib.pyplot as plt

# Function to build the U-Net model
def unet_model(input_size=(128, 128, 3)):
    inputs = Input(input_size)
    conv1 = Conv2D(64, (3, 3), activation='relu', padding='same')(inputs)
    pool1 = MaxPooling2D(pool_size=(2, 2))(conv1)

    conv2 = Conv2D(128, (3, 3), activation='relu', padding='same')(pool1)
    pool2 = MaxPooling2D(pool_size=(2, 2))(conv2)

    conv3 = Conv2D(256, (3, 3), activation='relu', padding='same')(pool2)

    up4 = UpSampling2D(size=(2, 2))(conv3)
    merge4 = Concatenate()([conv2, up4])
    conv4 = Conv2D(128, (3, 3), activation='relu', padding='same')(merge4)

    up5 = UpSampling2D(size=(2, 2))(conv4)
    merge5 = Concatenate()([conv1, up5])
    conv5 = Conv2D(64, (3, 3), activation='relu', padding='same')(merge5)

    conv6 = Conv2D(1, (1, 1), activation='sigmoid')(conv5)

    model = Model(inputs, conv6)
    return model

# Load an example image
image = load_img('example.jpg', target_size=(128, 128))
image = img_to_array(image)
image = np.expand_dims(image, axis=0) / 255.0

# Build and compile the model
model = unet_model()
model.compile(optimizer='adam', loss='binary_crossentropy', metrics=['accuracy'])

# Predict the segmentation mask
mask = model.predict(image)[0]

# Display the original image and the segmentation mask
plt.figure(figsize=(10, 5))
plt.subplot(1, 2, 1)
plt.title('Original Image')
plt.imshow(image[0])

plt.subplot(1, 2, 2)
plt.title('Segmentation Mask')
plt.imshow(mask, cmap='gray')

plt.show()
  `;

  const realLifeScenario = `
# Real-Life Scenario: Medical Image Segmentation
import tensorflow as tf
from tensorflow.keras.models import Model
from tensorflow.keras.layers import Input, Conv2D, MaxPooling2D, UpSampling2D, Concatenate
from tensorflow.keras.preprocessing.image import img_to_array, load_img
import numpy as np
import matplotlib.pyplot as plt

# Function to build the U-Net model
def unet_model(input_size=(256, 256, 3)):
    inputs = Input(input_size)
    conv1 = Conv2D(64, (3, 3), activation='relu', padding='same')(inputs)
    pool1 = MaxPooling2D(pool_size=(2, 2))(conv1)

    conv2 = Conv2D(128, (3, 3), activation='relu', padding='same')(pool1)
    pool2 = MaxPooling2D(pool_size=(2, 2))(conv2)

    conv3 = Conv2D(256, (3, 3), activation='relu', padding='same')(pool2)

    up4 = UpSampling2D(size=(2, 2))(conv3)
    merge4 = Concatenate()([conv2, up4])
    conv4 = Conv2D(128, (3, 3), activation='relu', padding='same')(merge4)

    up5 = UpSampling2D(size=(2, 2))(conv4)
    merge5 = Concatenate()([conv1, up5])
    conv5 = Conv2D(64, (3, 3), activation='relu', padding='same')(merge5)

    conv6 = Conv2D(1, (1, 1), activation='sigmoid')(conv5)

    model = Model(inputs, conv6)
    return model

# Load a medical image for segmentation
image = load_img('medical_image.jpg', target_size=(256, 256))
image = img_to_array(image)
image = np.expand_dims(image, axis=0) / 255.0

# Build and compile the model
model = unet_model()
model.compile(optimizer='adam', loss='binary_crossentropy', metrics=['accuracy'])

# Predict the segmentation mask
mask = model.predict(image)[0]

# Display the original image and the segmentation mask
plt.figure(figsize=(10, 5))
plt.subplot(1, 2, 1)
plt.title('Original Medical Image')
plt.imshow(image[0])

plt.subplot(1, 2, 2)
plt.title('Segmentation Mask')
plt.imshow(mask, cmap='gray')

plt.show()
  `;

  return (
    <body>
      <Helmet>
        <title>AI Image Segmentation</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Image Segmentation, U-Net, Keras, AI Tutorial" />
        <meta name="description" content="Learn about AI image segmentation, its applications, and practical examples using U-Net and Keras." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_image_processing"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_facial_recognition"><ButtonNext /></ScrollToTopLink>
          <h1>AI Image Segmentation</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Image segmentation is a crucial task in computer vision that involves partitioning an image into multiple segments to simplify its representation or make it more meaningful for analysis. This technique is widely used in medical imaging, autonomous driving, and object detection.</p>

            <h2>Introduction to Image Segmentation</h2>
            <p>Image segmentation aims to locate objects and boundaries within images. One popular method for image segmentation is the U-Net architecture, which has been successful in various applications.</p>

            <h2>Example 1: Image Segmentation with U-Net and Keras</h2>
            <SyntaxHighlighterComponent code={unetExample} language="python" />
            <p>In this example, we use TensorFlow and Keras to implement a U-Net model for image segmentation. The model is trained on the MNIST dataset for simplicity, demonstrating how to predict segmentation masks.</p>

            <h2>Example 2: Real-Life Scenario - Medical Image Segmentation</h2>
            <SyntaxHighlighterComponent code={realLifeScenario} language="python" />
            <p>Medical image segmentation is critical for diagnosing diseases and analyzing patient scans. The U-Net model is applied to a medical image dataset, showcasing its capability in identifying and segmenting medical conditions.</p>
          </section>
        </main>
        <div className="head">
        <ScrollToTopLink to="/AI_image_processing"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/AI_facial_recognition"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
