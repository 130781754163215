import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";

export default function AINamedEntityRecognition() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const nerExampleSpaCy = `
# Example of Named Entity Recognition with spaCy
import spacy

# Load the English model
nlp = spacy.load("en_core_web_sm")

# Example text
text = "Apple is looking at buying U.K. startup for $1 billion"

# Process the text
doc = nlp(text)

# Extract named entities
for ent in doc.ents:
    print(ent.text, ent.label_)
  `;

  const nerExampleTransformers = `
# Example of Named Entity Recognition with Transformers (Hugging Face)
from transformers import pipeline

# Initialize the NER pipeline
nlp = pipeline("ner")

# Example text
text = "Google is planning to acquire a London-based AI startup."

# Perform named entity recognition
result = nlp(text)

# Output named entities
for entity in result:
    print(entity)
  `;

  return (
    <body>
      <Helmet>
        <title>AI Named Entity Recognition (NER)</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI NER, Named Entity Recognition, Natural Language Processing, AI Tutorial, NER with spaCy, NER with Transformers" />
        <meta name="description" content="Learn about AI named entity recognition (NER), its applications, and practical examples using frameworks like spaCy and Hugging Face Transformers." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_sentiment_analysis"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_language_models"><ButtonNext /></ScrollToTopLink>
          <h1>AI Named Entity Recognition (NER)</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Named Entity Recognition (NER) is a subtask of information extraction that identifies named entities such as persons, locations, organizations, and more within text. NER is widely used in various applications, including search engines, customer service, and content recommendation systems.</p>

            <h2>Introduction to Named Entity Recognition</h2>
            <p>There are different libraries and tools available for performing named entity recognition. Let's look at a few examples using spaCy and Hugging Face Transformers.</p>

            <h2>Example: Named Entity Recognition with spaCy</h2>
            <SyntaxHighlighterComponent code={nerExampleSpaCy} language="python" />
            <p>In this example, we use spaCy to analyze a given text and extract named entities. spaCy is an open-source software library for advanced natural language processing in Python.</p>

            <h2>Example: Named Entity Recognition with Transformers (Hugging Face)</h2>
            <SyntaxHighlighterComponent code={nerExampleTransformers} language="python" />
            <p>Hugging Face's Transformers library provides a variety of pre-trained models for various NLP tasks, including named entity recognition. In this example, we use a pre-trained NER model to analyze a given text and extract named entities.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_sentiment_analysis"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_language_models"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
