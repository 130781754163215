import React from 'react';
import { Helmet } from 'react-helmet';
import './ProgressTracker.css';
import Sidenav from '../Sidenav/ProgressSidenav';
import Navbar from '../navbar';
import Footertutorials from '../Footer-tutorials';
import ButtonNext from '../Buttonnext';
import ScrollToTopLink from '../ScrollToTop';
import ButtonHome from '../Buttonhome';

const ProgressTracker = () => {

    const headerStyles = {
        position: 'relative',
        marginBottom: '0',
        backgroundColor: 'darkblue',
    };

    const imgStyles = {
        width: '100%',
        height: '450px',
    };

  return (
    <>
      <Helmet>
        <title>Progress Tracker</title>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE-edge"/>
        <meta name="keywords" content="Progress Tracker, Beta Thread Progress Tracker" />
        <meta name="description" content="This is the Progress Tracker where you track your progress on the various tutorials we offer at betathread.com" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      </Helmet>
      <br /><br /><br /><br /><br />
      <Sidenav />

      <div className='content'>
       
        <Navbar />
      
        <div className="welcome-message">
          
<ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
<ScrollToTopLink to="/html-progress-tracker"><ButtonNext /></ScrollToTopLink>

          <h1>Welcome to the Progress Tracker</h1>
          <p>
            Welcome to Beta Thread's Progress Tracker. This platform is designed to help you keep track of your learning journey through our comprehensive tutorials.
            Whether you are diving into HTML, CSS, JavaScript, PHP, Python, Ruby, SQL, XML, React, or AI, our progress tracker will provide you with an efficient way 
            to monitor your advancements and stay motivated.
          </p>
          <p>
            The progress tracker is an exclusive feature available only to authenticated users. By logging in, you gain access to personalized progress tracking, 
            which helps you stay on top of your learning goals and ensures you make consistent progress. Our system allows you to mark completed sections, review 
            your progress, and even set milestones to achieve your objectives effectively.
          </p>
          <p>
            At Beta Thread, we understand the importance of structured learning. That's why our progress tracker is designed to be user-friendly and highly functional. 
            With features such as detailed progress reports, and interactive learning modules, we aim to make your learning experience both 
            enjoyable and productive.
          </p>
          <p>
            Here's what you can expect from our Progress Tracker:
            <ul>
              <li><strong>Comprehensive Tracking:</strong> Monitor your progress across multiple tutorials, keeping all your learning in one place.</li>
              <li><strong>Interactive Modules:</strong> Engage with interactive modules that provide hands-on experience and immediate feedback.</li>
              <li><strong>Milestone Setting:</strong> Set and track your learning milestones to ensure steady progress and achievement.</li>
              <li><strong>Personalized Dashboard:</strong> Access a personalized dashboard that summarizes your progress and upcoming learning goals.</li>
              <li><strong>Resource Links:</strong> Access additional resources and reading materials to supplement your learning.</li>
            </ul>
          </p>
          <p>
            We are committed to providing you with the best tools and resources to enhance your learning experience. The Progress Tracker is continually updated to include 
            new features and improvements based on user feedback. Your success is our priority, and we are here to support you every step of the way.
          </p>
          <p>
            Join our community of learners today and take control of your educational journey with Beta Thread's Progress Tracker. Happy learning!
          </p>
        </div>
      </div>
      <Footertutorials />
    </>
  );
};

export default ProgressTracker;
