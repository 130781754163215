import React, { useState } from 'react';

export default function Calculator() {
    const [result, setResult] = useState('');
    const [expression, setExpression] = useState('');

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            calculateResult();
        }
    };

    const calculateResult = () => {
        try {
            const res = eval(expression);
            setResult(res);
        } catch (error) {
            setResult('Error');
        }
    };

    return (
        <div>
            <h1>Simple Calculator</h1>
            <div>
                <input
                    type="text"
                    value={expression}
                    onChange={e => setExpression(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <button onClick={calculateResult}>Calculate</button>
            </div>
            <div>
                <h2>Result: {result}</h2>
            </div>
            <div>
                <h2>Code Snippet: (JSX)</h2>
                <pre>{`
                import React, { useState } from 'react';

                export default function Calculator() {
                   const [result, setResult] = useState('');
                   const [expression, setExpression] = useState('');
               
                   const handleKeyPress = event => {
                       if (event.key === 'Enter') {
                           calculateResult();
                       }
                   };
               
                   const calculateResult = () => {
                       try {
                           const res = eval(expression);
                           setResult(res);
                       } catch (error) {
                           setResult('Error');
                       }
                   };
               
                   return (
                    <div>
                    <h1>Simple Calculator</h1>
                    <div>
                        <input
                            type="text"
                            value={expression}
                            onChange={e => setExpression(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                        <button onClick={calculateResult}>Calculate</button>
                    </div>
                    <div>
                        <h2>Result: {result}</h2>
                    </div>
                    <div>
                   );
                }
                `}</pre>
            </div>
        </div>
    );
}
