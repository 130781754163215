import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AINLP() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const nlpIntroductionCode = `
# Example of NLP with spaCy
import spacy

# Load English tokenizer, tagger, parser, NER, and word vectors
nlp = spacy.load("en_core_web_sm")

# Process some text
text = "Apple is looking at buying U.K. startup for $1 billion"
doc = nlp(text)

# Analyze syntax
print("Noun phrases:", [chunk.text for chunk in doc.noun_chunks])
print("Verbs:", [token.lemma_ for token in doc if token.pos_ == "VERB"])
  `;

  const nlpExampleCode = `
# Example of NLP Text Classification with TensorFlow/Keras
from tensorflow.keras.models import Sequential
from tensorflow.keras.layers import Embedding, LSTM, Dense
from tensorflow.keras.preprocessing.text import Tokenizer
from tensorflow.keras.preprocessing.sequence import pad_sequences

# Define a simple LSTM-based text classification model
model = Sequential([
    Embedding(input_dim=num_words, output_dim=32),
    LSTM(64),
    Dense(1, activation='sigmoid')
])
  `;

  return (
    <body>
      <Helmet>
        <title>AI Natural Language Processing</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI NLP, Natural Language Processing, AI Tutorial, NLP with spaCy, Text Classification with TensorFlow" />
        <meta name="description" content="Learn about AI NLP (Natural Language Processing), its applications, and practical examples using frameworks like spaCy and TensorFlow." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/AI_gans"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_text_preprocessing"><ButtonNext /></ScrollToTopLink>
          <h1>AI Natural Language Processing</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Natural Language Processing (NLP) is a field of artificial intelligence that enables computers to analyze, understand, and generate human language. It has applications in sentiment analysis, language translation, text summarization, and more.</p>

            <h2>Introduction to NLP</h2>
            <p>NLP involves various tasks such as tokenization, part-of-speech tagging, named entity recognition (NER), and syntactic parsing. Here's an example of NLP using spaCy:</p>
            <SyntaxHighlighterComponent code={nlpIntroductionCode} language="python" />

            <h2>Example of NLP Text Classification</h2>
            <p>Text classification is a common NLP task that categorizes text into predefined classes. Here's a simple example of text classification using TensorFlow/Keras:</p>
            <SyntaxHighlighterComponent code={nlpExampleCode} language="python" />
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_gans"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_text_preprocessing"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
