import React from 'react';
import { FlutterWaveButton } from 'flutterwave-react-v3'


export default function Payment() {
  const config = {
    public_key: process.env.REACT_APP_FLWPUBK_TEST,
    tx_ref: Date.now(),
    amount: 1000,
    currency: 'USD',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: 'user@gmail.com',
      phone_number: '08102909304',
      name: 'test user',
    },

    customizations: {
      title: 'Beta Thread HTML Certificate',
      description: 'Payment for HTML certificate',
      logo: 'https://www.betathread.com/beta_thread.png',
    },

 
  };
  

  const fwConfig = {
    ...config,
    text: 'Get your certificate',
    callback: (response) => {
      console.log(response);
   
    },
    onClose: () => {
      console.log("You close me ooo")
    },
    
  };

  return (
    <div className="App">
     
      <FlutterWaveButton {...fwConfig} />
    </div>
  );
}