import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import toggle from "../responsivetopnav";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../ScrollToTop";
import { useLocation } from 'react-router-dom';
import RubySearchBar from "../Searchbar/Ruby SearchBar/RubySearchBar";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export default function Sidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  const toggleDropdown3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
  };

  const toggleDropdown4 = () => {
    setIsDropdownOpen4(!isDropdownOpen4);
  };

  const toggleDropdown5 = () => {
    setIsDropdownOpen5(!isDropdownOpen5);
  };
  var nav = {
    color: 'white',
    fontSize: '30px'
}
var newCode = {
  float: 'right'
}
    return (
      <div>
      <div className={`sidebar ${isOpen ? 'responsive' : ''}`} id="mySidebar">
        <span className="close" onClick={toggle}>&times;</span>
    <h2>Ruby Tutorial</h2><br />
    <span><RubySearchBar /></span><br />
    <p className="p-sidebar">...Empowering Your Online Presence...</p><br />
    <ScrollToTopLink to="/Ruby_Tutorial_intro"><a className={location.pathname === '/Ruby_Tutorial_intro' ? 'active' : ''}>Ruby Overview</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_environment"><a className={location.pathname === '/Ruby_environment' ? 'active' : ''}>Ruby Environment</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_syntax"><a className={location.pathname === '/Ruby_syntax' ? 'active' : ''}>Ruby Syntax</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_classes"><a className={location.pathname === '/Ruby_classes' ? 'active' : ''}>Ruby Classes</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_comments"><a className={location.pathname === '/Ruby_comments' ? 'active' : ''}>Ruby Comments</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_variables"><a className={location.pathname === '/Ruby_variables' ? 'active' : ''}>Ruby Variables</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_data_types"><a className={location.pathname === '/Ruby_data_types' ? 'active' : ''}>Ruby Data Types</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_constants"><a className={location.pathname === '/Ruby_constants' ? 'active' : ''}>Ruby Constants</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_operators"><a className={location.pathname === '/Ruby_operators' ? 'active' : ''}>Ruby Operators</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_conditionals"><a className={location.pathname === '/Ruby_conditionals' ? 'active' : ''}>Ruby Conditional Statements</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_loops"><a className={location.pathname === '/Ruby_loops' ? 'active' : ''}>Ruby Loops</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_methods"><a className={location.pathname === '/Ruby_methods' ? 'active' : ''}>Ruby Methods</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_blocks"><a className={location.pathname === '/Ruby_blocks' ? 'active' : ''}>Ruby Blocks</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/Ruby_procs-lambda"><a className={location.pathname === '/Ruby_procs-lambda' ? 'active' : ''}>Ruby Procs and Lambda</a></ScrollToTopLink><br />
  
    <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown}>
          Ruby Arrays {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen && (
          <div className="dropdown-content">
            <ScrollToTopLink to="/Ruby_arrays"><a className={location.pathname === '/Python_arrays' ? 'active' : ''}>Python Arrays</a></ScrollToTopLink><br />
            <ScrollToTopLink to="/Ruby_array_methods"><a className={location.pathname === '/Python_array_methods' ? 'active' : ''}>Python Array Methods</a></ScrollToTopLink><br />
           </div>
        )}
      </div>

      <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown2}>
          Ruby Hashes {isDropdownOpen2 ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen2 && (
          <div className="dropdown-content">
            <ScrollToTopLink to="/Ruby_hashes"><a className={location.pathname === '/Python_hashes' ? 'active' : ''}>Python Hashes</a></ScrollToTopLink><br />
            <ScrollToTopLink to="/Ruby_hash_methods"><a className={location.pathname === '/Python_hash_methods' ? 'active' : ''}>Python Hash Methods</a></ScrollToTopLink><br />
           </div>
        )}
      </div>

      <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown3}>
          Ruby Strings {isDropdownOpen3 ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen3 && (
          <div className="dropdown-content">
            <ScrollToTopLink to="/Ruby_strings"><a className={location.pathname === '/Ruby_strings' ? 'active' : ''}>Python Strings</a></ScrollToTopLink><br />
            <ScrollToTopLink to="/Ruby_string_methods"><a className={location.pathname === '/Ruby_string_methods' ? 'active' : ''}>Python String Methods</a></ScrollToTopLink><br />
           </div>
        )}
      </div>

      <ScrollToTopLink to="/Ruby_ranges"><a className={location.pathname === '/Ruby_ranges' ? 'active' : ''}>Ruby Ranges</a></ScrollToTopLink><br />
      <ScrollToTopLink to="/Ruby_symbols"><a className={location.pathname === '/Ruby_symbols' ? 'active' : ''}>Ruby Symols</a></ScrollToTopLink><br />
      <ScrollToTopLink to="/Ruby_date-time"><a className={location.pathname === '/Ruby_date-time' ? 'active' : ''}>Ruby Date & Time</a></ScrollToTopLink><br />
      
    </div>
  
  <div className="burger-icon" onClick={toggle}>
    &#9776;
    <p style={newCode}>Menu</p>
  </div>
</div>
    )
}