import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLTree() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const code1 = `
<bookstore>
  <book>
    <title>Learning XML</title>
    <author>John Doe</author>
    <year>2023</year>
  </book>
</bookstore>`;

  const code2 = `
<company>
  <department name="HR">
    <employee>
      <name>Jane Smith</name>
      <position>Manager</position>
    </employee>
    <employee>
      <name>John Johnson</name>
      <position>Recruiter</position>
    </employee>
  </department>
</company>`;

  const code3 = `
<library>
  <shelf>
    <book>
      <title>XML Basics</title>
      <author>Alice Brown</author>
    </book>
    <book>
      <title>Advanced XML</title>
      <author>Bob White</author>
    </book>
  </shelf>
</library>`;

  return (
    <body>
      <Helmet>
        <title>XML Tree</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML Tree, XML Tutorial, XML Structure" />
        <meta name="description" content="Learn about the structure of XML Trees, including examples and explanations of how to organize data in XML documents." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_syntax"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_elements"><ButtonNext /></ScrollToTopLink>
          <h1>XML Tree</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>An XML tree starts at a root element and branches from the root to child elements. All elements can have sub-elements (child elements). This hierarchical structure is called an XML tree and plays a crucial role in defining the relationships within an XML document.</p>

            <h2>Understanding XML Tree Structure</h2>
            <p>In an XML tree:</p>
            <ul>
              <li>The topmost element is known as the root element.</li>
              <li>Each element can contain sub-elements, creating a parent-child relationship.</li>
              <li>Elements can contain attributes that provide additional information about the elements.</li>
            </ul>

            <h2>Example 1: Simple XML Tree</h2>
            <p>This example demonstrates a basic XML tree structure with a bookstore containing one book.</p>
            <SyntaxHighlighterComponent code={code1} language="xml" />
            <p>Here, <code>&lt;bookstore&gt;</code> is the root element, and <code>&lt;book&gt;</code> is a child element with further sub-elements.</p>

            <h2>Example 2: XML Tree with Multiple Levels</h2>
            <p>This example shows a company structure with departments and employees, illustrating multiple levels of hierarchy.</p>
            <SyntaxHighlighterComponent code={code2} language="xml" />
            <p>In this example, <code>&lt;company&gt;</code> is the root element, <code>&lt;department&gt;</code> is a child element, and <code>&lt;employee&gt;</code> is a sub-element of <code>&lt;department&gt;</code>.</p>

            <h2>Example 3: Nested Elements in an XML Tree</h2>
            <p>This example illustrates a library system with shelves and books, demonstrating nested elements.</p>
            <SyntaxHighlighterComponent code={code3} language="xml" />
            <p>Here, <code>&lt;library&gt;</code> is the root element, <code>&lt;shelf&gt;</code> is a child element, and <code>&lt;book&gt;</code> is a sub-element of <code>&lt;shelf&gt;</code>.</p>

            <h2>Benefits of Using XML Trees</h2>
            <ul>
              <li><strong>Organized Data:</strong> XML trees help organize data in a structured and hierarchical manner.</li>
              <li><strong>Readability:</strong> The tree structure makes XML documents easy to read and understand.</li>
              <li><strong>Data Relationships:</strong> Parent-child relationships in XML trees clearly define the connections between data elements.</li>
            </ul>

            <h2>Key Points to Remember</h2>
            <table>
              <thead>
                <tr>
                  <th>Concept</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Root Element</td>
                  <td>The topmost element in an XML document. All other elements are contained within it.</td>
                </tr>
                <tr>
                  <td>Child Element</td>
                  <td>An element that is contained within another element (parent element).</td>
                </tr>
                <tr>
                  <td>Attribute</td>
                  <td>Additional information provided within an element's start tag, enhancing the element's data.</td>
                </tr>
                <tr>
                  <td>Parent Element</td>
                  <td>An element that contains one or more child elements.</td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_syntax"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_elements"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
