import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLdescription() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
        <dl>
        <dt>HTML</dt>
        <dd>A markup language used to create web pages.</dd>
        <dt>JavaScript</dt>
        <dd>A programming language used to make web pages interactive.</dd>
        </dl>
  `;

    return (
   <body>
    <Helmet>    
        <title>HTML Definition Lists</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Definition Lists, definition term, defintion description,
         Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
      <meta name="description" content="Defintion list is an association list consisting of zero or more name-value groups.
       A name group consists of one or more names these names are represented with the <dt> tag which means definition term." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_ordered"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_forms"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Definition Lists</h1>
</header>

<Navbar />

<main>

<section>
<p>Defintion list is an association list consisting of zero or more name-value groups.</p>
<p>A name group consists of one or more names these names are represented with the &lt;dt&gt; tag which means definition term.</p>
<p>The &lt;dt&gt; tag is then followed by one or more &lt;dd&gt; tags which means definition description.</p>
<h3>NOTE:</h3>
<li>&lt;dl&gt;(defintion list) comes first</li>
<li>&lt;dt&gt;(definition term) comes second</li>
<li>&lt;dd&gt;(definition description) comes last</li>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html59"><Tryitout /></ScrollToTopLink>

</section>
 
<div className="head">
<ScrollToTopLink to="/HTML_ordered"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_forms"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}