import React, { useContext, useEffect, useState } from 'react';
import { ProgressContext } from '../ProgressContext'; 
import { Helmet } from 'react-helmet';
import Sidenav from '../../Sidenav/ProgressSidenav';
import Navbar from '../../navbar';
import "../../../css/navigation.css";
import "../ProgressTracker.css"; // Import the new CSS file
import Footertutorials from '../../Footer-tutorials';
import { useNavigate } from 'react-router-dom';
import { getAuthStatus } from '../../../localstorage';

const aiTutorials = [
  '/AI_Tutorial_intro', '/AI_history', '/AI_types', '/AI_problem_solving'
];

const AIProgress = () => {
  const { progress } = useContext(ProgressContext);
  const [percentage, setPercentage] = useState(0);

  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate(); // Move useNavigate here

  useEffect(() => {
    const checkAuthentication = () => {
      const isAuthenticated = getAuthStatus('_id');
      if (!isAuthenticated) {
        navigate("/login");
      } else {
        setAuthenticated(true);
      }
    };

    checkAuthentication();
  }, [navigate]);

  useEffect(() => {
    const completed = aiTutorials.filter(tutorial => progress[tutorial]).length;
    setPercentage(Math.round((completed / aiTutorials.length) * 100));
  }, [progress]);

  return (
    <>
      <Helmet>
        <title>AI Progress Tracker</title>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE-edge" />
        <meta name="keywords" content="AI Progress Tracker, Beta Thread Progress Tracker" />
        <meta name="description" content="This is the AI Progress Tracker where you track your progress on the AI tutorials we offer at betathread.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br /><br /><br /><br /><br />
      <Sidenav />
      <div className="content">
        <div className="container">
          <Navbar />
          <div className="progress">
            <h2 style={{ fontSize: '30px' }}>AI Tutorial Progress</h2>
            <svg width="300" height="300" viewBox="0 0 36 36">
              <path style={{ fill: 'none', stroke: '#e0e0e0', strokeWidth: '3.8' }}
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
              <path style={{ fill: 'none', stroke: '#00008b', strokeWidth: '2.8', strokeLinecap: 'round', transition: 'stroke-dashoffset 0.3s' }}
                strokeDasharray="100, 100"
                strokeDashoffset={`${100 - percentage}`}
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
              <text x="18" y="20.35" style={{ fill: '#333', fontSize: '5px', textAnchor: 'middle' }}>
                {`${percentage}%`}
              </text>
            </svg>
          </div>
          <div className="text">
            <h3>Track Your Learning Progress</h3>
            <p>
              Welcome to the AI Progress Tracker! This tool helps you monitor your progress through the various AI tutorials we offer. As you complete each section, your progress percentage will increase, giving you a clear view of your learning journey.
            </p>
            <h4>Why Track Your Progress?</h4>
            <ul>
              <li><b>Motivation:</b> Seeing your progress grow can motivate you to keep learning and achieve your goals.</li>
              <li><b>Accountability:</b> Tracking your progress helps you stay accountable to your learning schedule.</li>
              <li><b>Goal Setting:</b> Use the progress tracker to set and achieve your learning goals more effectively.</li>
              <li><b>Review:</b> Easily identify which topics you've completed and which ones you still need to review.</li>
            </ul>
            <p>
              Keep up the great work! The more you learn, the more proficient you'll become in AI. Happy coding!
            </p>
          </div>
        </div>
      </div>
      <Footertutorials />
    </>
  );
};

export default AIProgress;
