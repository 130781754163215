import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonTypeConversion() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const code = `
# Integer to Float
x = 10
y = float(x)
print(y)  # Output: 10.0
    `;

    const code2 = `
# Float to Integer
x = 3.14
y = int(x)
print(y)  # Output: 3
    `;

    const code3 = `
# String to Integer
x = "100"
y = int(x)
print(y)  # Output: 100
    `;

    const code4 = `
# String to Float
x = "3.14"
y = float(x)
print(y)  # Output: 3.14
    `;

    const code5 = `
# Integer to String
x = 100
y = str(x)
print(y)  # Output: "100"
    `;

    const code6 = `
# Float to String
x = 3.14
y = str(x)
print(y)  # Output: "3.14"
    `;

    return (
        <body>
            <Helmet>
                <title>Python Type Conversion</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Type Conversion, Integer to Float, Float to Integer, String to Integer, String to Float, Integer to String, Float to String" />
                <meta name="description" content="Learn how to convert between different data types in Python, including integers, floats, and strings." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_data_types"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_input"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Type Conversion</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Python provides various built-in functions for converting one data type to another. Understanding how to perform these conversions is essential for handling data in Python effectively.</p>
                        
                        <h2>Integer to Float</h2>
                        <p>Use the <code>float()</code> function to convert an integer to a float.</p>
                        <SyntaxHighlighterComponent code={code} language="python" />
                        
                        <h2>Float to Integer</h2>
                        <p>Use the <code>int()</code> function to convert a float to an integer. Note that this conversion will truncate the decimal part.</p>
                        <SyntaxHighlighterComponent code={code2} language="python" />
                        
                        <h2>String to Integer</h2>
                        <p>Use the <code>int()</code> function to convert a string containing digits to an integer. The string must represent a valid integer.</p>
                        <SyntaxHighlighterComponent code={code3} language="python" />
                        
                        <h2>String to Float</h2>
                        <p>Use the <code>float()</code> function to convert a string containing digits to a float. The string must represent a valid float.</p>
                        <SyntaxHighlighterComponent code={code4} language="python" />
                        
                        <h2>Integer to String</h2>
                        <p>Use the <code>str()</code> function to convert an integer to a string.</p>
                        <SyntaxHighlighterComponent code={code5} language="python" />
                        
                        <h2>Float to String</h2>
                        <p>Use the <code>str()</code> function to convert a float to a string.</p>
                        <SyntaxHighlighterComponent code={code6} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Type conversion is a fundamental concept in Python that allows you to convert data from one type to another. This tutorial covered converting integers to floats, floats to integers, strings to integers, strings to floats, integers to strings, and floats to strings.</p>
                    </section>
                </main>
                <div className="head">
                <ScrollToTopLink to="/Python_data_types"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_input"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
