import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPComments() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    <?php
     $number = 14; # I am a single-line comment
     $text = "Hello World"; # I am a single-line comment

     $que = "How is the weather"; // I am a single-line comment
     $ans = "The weather is fine"; # I am a single-line comment
     ?>
    `;
    const code2 = `
    <script language = "php">
     /* I am a multi-line comment 
        I am a multi-line comment 
        I am a multi-line comment 
        I am a multi-line comment  
     */
    </script>
    `;

    return (
   <body>
     <Helmet>
        
        <title>PHP Comments</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Comments, Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="Learn how PHP Comments streamline code organization, 
      enhance readability, and unlock advanced features in PHP development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_syntax"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_variables"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Comments</h1>
</header>

<Navbar />

<main>
<section>
<p>A comment is used to describe the purpose or function of a piece of code.</p>
<p>PHP comments are not rendered when the output is dispplayed and is stripped from the program before the output is displayed.</p>
<p>Sometimes comments help the programmer to remember what the purpose of te program is.</p>
<p>Comments also allow other programmers to understand your code especially when you work with a team.</p>
<p>There are two ways of writing comments in a program, they are:</p>
<li>Single-line comments</li>
<li>Multi-line comments</li>

<h2>Single-line Comments</h2>
<p>Single-line comments are used when the program to comment is not much in most cases a single line.</p>
<p>There are two ways of writing single line comments,they are:</p>
<li>//... (two forward slashes followed by the comment)</li>
<li>#...(the hash sign also known as pound sign followed by the comment)</li>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="php" />

<h2>Multi-line Comments</h2>
<p>A multi line comment is used when the program to comment is very long and cannot be written in a single line.</p>
<p>The syntax of a multi-line comment is a forward slash followed by an asterisk and at the end another asterisk 
    and forward i.e, /* the comment */
</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="php" />
</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_syntax"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_variables"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}