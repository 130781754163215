import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLStringFunctions() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const concatFunctionCode = `
-- CONCAT Function: Concatenates two or more strings together.
SELECT CONCAT(first_name, ' ', last_name) AS full_name
FROM employees;
-- Returns: full_name
  `;

  const upperLowerFunctionCode = `
-- UPPER and LOWER Functions: Converts a string to uppercase or lowercase.
SELECT UPPER(product_name) AS ProductUpper, LOWER(product_name) AS ProductLower
FROM products;
-- Returns: ProductUpper, ProductLower
  `;

  const substringFunctionCode = `
-- SUBSTRING Function: Extracts a substring from a string.
SELECT SUBSTRING(product_name, 1, 3) AS ShortName
FROM products;
-- Returns: ShortName
  `;

  const replaceFunctionCode = `
-- REPLACE Function: Replaces occurrences of a substring within a string.
SELECT REPLACE('Hello, world!', 'world', 'friend') AS Greeting
-- Returns: Greeting
  `;

  return (
    <body>
      <Helmet>
        <title>SQL String Functions</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL String Functions, CONCAT, UPPER, LOWER, SUBSTRING, REPLACE, SQL Tutorial" />
        <meta name="description" content="Learn about SQL string functions, including CONCAT, UPPER, LOWER, SUBSTRING, and REPLACE with examples and usage in SQL queries." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_aggregate_functions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_date-time_functions"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL String Functions</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>SQL string functions manipulate string data stored in columns or literals within SQL queries.</p>

            <h2>CONCAT Function</h2>
            <p>The <code>CONCAT</code> function concatenates two or more strings together.</p>
            <SyntaxHighlighterComponent code={concatFunctionCode} language="sql" />

            <h2>UPPER and LOWER Functions</h2>
            <p>The <code>UPPER</code> and <code>LOWER</code> functions convert a string to uppercase or lowercase, respectively.</p>
            <SyntaxHighlighterComponent code={upperLowerFunctionCode} language="sql" />

            <h2>SUBSTRING Function</h2>
            <p>The <code>SUBSTRING</code> function extracts a substring from a string based on starting position and length.</p>
            <SyntaxHighlighterComponent code={substringFunctionCode} language="sql" />

            <h2>REPLACE Function</h2>
            <p>The <code>REPLACE</code> function replaces occurrences of a substring within a string.</p>
            <SyntaxHighlighterComponent code={replaceFunctionCode} language="sql" />
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_aggregate_functions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_date-time_functions"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
