import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLXQueryExpressions() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<bookstore>
  <book category="cooking">
    <title lang="en">Everyday Italian</title>
    <author>Giada De Laurentiis</author>
    <year>2005</year>
    <price>30.00</price>
  </book>
  <book category="children">
    <title lang="en">Harry Potter</title>
    <author>J K. Rowling</author>
    <year>2005</year>
    <price>29.99</price>
  </book>
  <book category="web">
    <title lang="en">Learning XML</title>
    <author>Erik T. Ray</author>
    <year>2003</year>
    <price>39.95</price>
  </book>
</bookstore>
`;

  const example2 = `
XQuery:
for $book in doc("bookstore.xml")/bookstore/book
where $book/price>30
return $book/title

Result:
<title lang="en">Learning XML</title>
`;

  const example3 = `
XQuery:
let $x := <book><title>Learning XML</title></book>
return $x/title

Result:
<title>Learning XML</title>
`;

  const example4 = `
XQuery:
for $x in (1 to 10)
return $x

Result:
1 2 3 4 5 6 7 8 9 10
`;

  return (
    <body>
      <Helmet>
        <title>XQuery Expressions</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML XQuery Expressions, XML Tutorial, XML Data Querying" />
        <meta name="description" content="Learn how to use XQuery expressions for querying XML data with examples and explanations of the syntax and usage." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/XML_xquery"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xquery_functions"><ButtonNext /></ScrollToTopLink>
          <h1>XQuery Expressions</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XQuery expressions allow for querying and manipulating XML data. They provide a powerful way to extract and transform data from XML documents.</p>

            <h2>Example 1: Basic XML Structure</h2>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This example shows a basic XML structure with a bookstore containing books. We will use XQuery expressions to query this data.</p>

            <h2>Example 2: Querying Books with Price Greater than 30</h2>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>This example demonstrates an XQuery expression that selects the titles of books with a price greater than 30.</p>

            <h2>Example 3: Using the let Clause</h2>
            <SyntaxHighlighterComponent code={example3} language="xml" />
            <p>This example demonstrates the use of the <code>let</code> clause to assign a value to a variable and return the title element from it.</p>

            <h2>Example 4: Iterating Over a Sequence</h2>
            <SyntaxHighlighterComponent code={example4} language="xml" />
            <p>This example demonstrates how to iterate over a sequence of numbers from 1 to 10 using the <code>for</code> clause in XQuery.</p>

            <h2>Understanding XQuery Expressions</h2>
            <p>XQuery expressions are the building blocks of XQuery. They can be combined to perform complex queries and transformations. Key concepts include:</p>

            <ul>
              <li><strong>FLWOR Expressions:</strong> FLWOR stands for For, Let, Where, Order by, and Return. These expressions are used to iterate over sequences, bind variables, filter results, sort results, and return the final output.</li>
              <li><strong>Path Expressions:</strong> Similar to XPath, path expressions navigate through the XML document to select nodes.</li>
              <li><strong>Conditional Expressions:</strong> These expressions allow for conditional logic using <code>if</code> statements.</li>
              <li><strong>Arithmetic and Comparison Operators:</strong> XQuery supports various operators for arithmetic calculations and comparisons.</li>
              <li><strong>Functions:</strong> XQuery includes a rich set of built-in functions for manipulating strings, numbers, dates, and more.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_xquery"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xquery_functions"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
