export const africaData = [
    {
      question: "What is the largest country in Africa by land area?",
      answer: "The largest country in Africa by land area is Algeria."
    },
    {
      question: "Which country is known as the 'Rainbow Nation'?",
      answer: "South Africa is often referred to as the 'Rainbow Nation' due to its diverse multicultural society."
    },
    {
      question: "Who was the first female president in Africa?",
      answer: "Ellen Johnson Sirleaf of Liberia was the first female president in Africa, serving from 2006 to 2018."
    },
    {
      question: "Which African country is famous for its ancient pyramids?",
      answer: "Egypt is famous for its ancient pyramids, including the Great Pyramid of Giza."
    },
    {
      question: "What is the capital of Nigeria?",
      answer: "The capital city of Nigeria is Abuja."
    },
    {
      question: "Which African country is known as the 'Pearl of Africa'?",
      answer: "Uganda is often referred to as the 'Pearl of Africa' due to its natural beauty."
    },
    {
      question: "Which African country is home to the Maasai Mara National Reserve?",
      answer: "The Maasai Mara National Reserve is located in Kenya."
    },
    {
      question: "What is the highest mountain in Africa?",
      answer: "Mount Kilimanjaro, located in Tanzania, is the highest mountain in Africa."
    },
    {
      question: "Which African country is famous for its ancient city of Carthage?",
      answer: "Tunisia is famous for its ancient city of Carthage, which was a major power in the ancient Mediterranean."
    },
    {
      question: "What is the most populous country in Africa?",
      answer: "Nigeria is the most populous country in Africa, with over 200 million people."
    },
    {
      question: "What is the largest desert in Africa?",
      answer: "The largest desert in Africa is the Sahara Desert, covering much of North Africa."
    },
    {
      question: "Which African country is known as the 'Giant of Africa'?",
      answer: "Nigeria is often referred to as the 'Giant of Africa' due to its large population and economy."
    },
    {
      question: "What is the official language of Angola?",
      answer: "The official language of Angola is Portuguese."
    },
    {
      question: "Which African country is known for its annual Wildebeest Migration?",
      answer: "The annual Wildebeest Migration is a spectacle that takes place in Tanzania and Kenya."
    },
    {
      question: "What is the currency of South Africa?",
      answer: "The currency of South Africa is the South African Rand."
    },
    {
      question: "Which African country is located on the Horn of Africa?",
      answer: "Ethiopia is located on the Horn of Africa."
    },
    {
      question: "Who was the first African-American president of the United States?",
      answer: "Barack Obama was the first African-American president of the United States, serving from 2009 to 2017."
    },
    {
      question: "Which African country was formerly known as Abyssinia?",
      answer: "Ethiopia was formerly known as Abyssinia."
    },
    {
      question: "What is the largest lake in Africa by surface area?",
      answer: "Lake Victoria is the largest lake in Africa by surface area."
    },
    {
      question: "Which African country is known as the 'Land of a Thousand Hills'?",
      answer: "Rwanda is often referred to as the 'Land of a Thousand Hills' due to its hilly landscape."
    },
    {
      question: "What is the national animal of Botswana?",
      answer: "The national animal of Botswana is the zebra."
    },
    {
      question: "Which African country is home to the famous Timbuktu?",
      answer: "Mali is home to the famous city of Timbuktu."
    },
    {
      question: "What is the official language of Kenya?",
      answer: "The official languages of Kenya are English and Swahili."
    },
    {
      question: "Which African country is known as the 'Cradle of Humankind'?",
      answer: "South Africa is often referred to as the 'Cradle of Humankind' due to the significant discoveries of early hominid fossils."
    },
    {
      question: "Who is the current president of Ghana?",
      answer: "The current president of Ghana is Nana Akufo-Addo."
    },
    {
      question: "What is the main river in West Africa?",
      answer: "The main river in West Africa is the Niger River."
    },
    {
      question: "Which African country is known as the 'Horn of Africa'?",
      answer: "Somalia is often referred to as the 'Horn of Africa.'"
    },
    {
      question: "What is the official language of Tanzania?",
      answer: "The official language of Tanzania is Swahili."
    },
    {
      question: "Which African country is known as the 'Garden of Eden'?",
      answer: "Seychelles is often referred to as the 'Garden of Eden' due to its natural beauty."
    },
    {
      question: "What is the largest island in Africa?",
      answer: "The largest island in Africa is Madagascar."
    },
    {
      question: "Which African country is known as the 'Land of Gold'?",
      answer: "Ghana is often referred to as the 'Land of Gold' due to its historical association with gold mining."
    },
    {
      question: "What is the capital city of Ethiopia?",
      answer: "The capital city of Ethiopia is Addis Ababa."
    },
    {
      question: "Which African country is known for its production of diamonds?",
      answer: "Botswana is known for its production of diamonds."
    },
    {
      question: "What is the national bird of Uganda?",
      answer: "The national bird of Uganda is the Grey Crowned Crane."
    },
    {
      question: "Which African country is known as the 'Land of Pharaohs'?",
      answer: "Egypt is often referred to as the 'Land of Pharaohs' due to its ancient history."
    },
    {
      question: "What is the currency of Egypt?",
      answer: "The currency of Egypt is the Egyptian Pound."
    },
    {
      question: "Which African country is known as the 'Warm Heart of Africa'?",
      answer: "Malawi is often referred to as the 'Warm Heart of Africa' due to the friendliness of its people."
    },
    {
      question: "What is the official language of Mozambique?",
      answer: "The official language of Mozambique is Portuguese."
    },
    {
      question: "Which African country is known as the 'Gateway to Africa'?",
      answer: "Morocco is often referred to as the 'Gateway to Africa' due to its strategic location."
    },
    {
      question: "What is the highest waterfall in Africa?",
      answer: "The highest waterfall in Africa is Tugela Falls in South Africa."
    },
    {
      question: "Which African country is known as the 'Smiling Coast of Africa'?",
      answer: "The Gambia is often referred to as the 'Smiling Coast of Africa' due to the friendliness of its people."
    },
    {
      question: "What is the official language of Algeria?",
      answer: "The official language of Algeria is Arabic."
    },
    {
      question: "Which African country is known for its famous rock-hewn churches?",
      answer: "Ethiopia is known for its famous rock-hewn churches, particularly in Lalibela."
    },
    {
      question: "What is the capital city of Somalia?",
      answer: "The capital city of Somalia is Mogadishu."
    },
    {
      question: "Which African country is known for its production of cocoa?",
      answer: "Côte d'Ivoire (Ivory Coast) is known for its production of cocoa."
    },
    {
      question: "What is the largest city in Africa by population?",
      answer: "The largest city in Africa by population is Lagos, Nigeria."
    },
    {
      question: "Which African country is known as the 'Isle of Spice'?",
      answer: "Zanzibar, which is part of Tanzania, is often referred to as the 'Isle of Spice' due to its historic association with the spice trade."
    },
    {
      question: "What is the official language of Zambia?",
      answer: "The official language of Zambia is English."
    },
    {
      question: "Which African country is known as the 'Land of a Million Elephants'?",
      answer: "Laos, a country in Southeast Asia, is often referred to as the 'Land of a Million Elephants.'"
    },
    {
      question: "What is the capital city of Zimbabwe?",
      answer: "The capital city of Zimbabwe is Harare."
    },
    {
      question: "Which African country is known for its production of coffee?",
      answer: "Ethiopia is known for its production of coffee."
    },
    {
      question: "What is the official language of Cameroon?",
      answer: "The official languages of Cameroon are English and French."
    },
    {
      question: "Which African country is known as the 'Kingdom in the Sky'?",
      answer: "Lesotho is often referred to as the 'Kingdom in the Sky' due to its high altitude."
    },
    {
      question: "What is the currency of Morocco?",
      answer: "The currency of Morocco is the Moroccan Dirham."
    },
    {
      question: "Which African country is known as the 'Dark Continent'?",
      answer: "Africa is sometimes referred to as the 'Dark Continent,' though this term is considered outdated and problematic due to its negative connotations."
    },
    {
      question: "What is the official language of Ghana?",
      answer: "The official language of Ghana is English."
    },
    {
      question: "Which African country is known as the 'Pearl of the Indian Ocean'?",
      answer: "Madagascar is often referred to as the 'Pearl of the Indian Ocean' due to its natural beauty."
    },
    {
      question: "What is the largest national park in Africa?",
      answer: "The largest national park in Africa is the Namib-Naukluft National Park in Namibia."
    },
    {
      question: "Which African country is known as the 'Land of Contrasts'?",
      answer: "Botswana is often referred to as the 'Land of Contrasts' due to its diverse landscapes."
    },
    {
      question: "What is the official language of Liberia?",
      answer: "The official language of Liberia is English."
    },
    {
      question: "Which African country is known as the 'Land of Lakes'?",
      answer: "Malawi is often referred to as the 'Land of Lakes' due to its numerous lakes."
    },
    {
      question: "What is the currency of Kenya?",
      answer: "The currency of Kenya is the Kenyan Shilling."
    },
    {
      question: "Which African country is known as the 'Roof of Africa'?",
      answer: "Lesotho is often referred to as the 'Roof of Africa' due to its high elevation."
    },
    {
      question: "What is the national dish of Egypt?",
      answer: "Koshari is considered the national dish of Egypt."
    },
    {
      question: "Which African country is known as the 'Land of Hospitality'?",
      answer: "Senegal is often referred to as the 'Land of Hospitality' due to the warmth and friendliness of its people."
    },
    {
      question: "What is the capital city of Algeria?",
      answer: "The capital city of Algeria is Algiers."
    },
    {
      question: "Which African country is known as the 'Land of Lakes and Mountains'?",
      answer: "Uganda is often referred to as the 'Land of Lakes and Mountains' due to its varied geography."
    },
    {
      question: "What is the official language of Zimbabwe?",
      answer: "The official languages of Zimbabwe are English, Shona, and Ndebele."
    },
    {
      question: "Which African country is known as the 'Spice Island'?",
      answer: "Zanzibar, which is part of Tanzania, is often referred to as the 'Spice Island' due to its historic association with the spice trade."
    },
    {
      question: "What is the national bird of South Africa?",
      answer: "The national bird of South Africa is the Blue Crane."
    },
    {
      question: "Which African country is known as the 'Land of Poets'?",
      answer: "Mauritania is often referred to as the 'Land of Poets' due to its rich poetic tradition."
    },
    {
      question: "What is the largest city in Egypt?",
      answer: "The largest city in Egypt is Cairo."
    },
    {
      question: "Which African country is known as the 'Pearl of the Indian Ocean'?",
      answer: "Seychelles is often referred to as the 'Pearl of the Indian Ocean' due to its natural beauty."
    },
    {
      question: "What is the official language of Sudan?",
      answer: "The official language of Sudan is Arabic."
    },
    {
      question: "Which African country is known as the 'Land of Punt'?",
      answer: "The ancient Land of Punt is believed to have been located in the region that is now Somalia."
    },
    {
      question: "What is the currency of Nigeria?",
      answer: "The currency of Nigeria is the Nigerian Naira."
    },
    {
      question: "Which African country is known as the 'Land of Rivers'?",
      answer: "Nigeria is often referred to as the 'Land of Rivers' due to its extensive river networks."
    },
    {
      question: "What is the official language of Namibia?",
      answer: "The official language of Namibia is English."
    },
    {
      question: "Which African country is known as the 'Land of Gold and Cocoa'?",
      answer: "Ghana is often referred to as the 'Land of Gold and Cocoa' due to its historical reliance on these commodities."
    },
    {
        question: "What is the capital city of Nigeria?",
        answer: "Abuja"
      },
      {
        question: "Who is the current president of Nigeria?",
        answer: "As of 2024 the president of Nigeria is Bola Ahmed Tinubu."
      },
      {
        question: "Where is Nigeria located geographically?",
        answer: "Nigeria is located in West Africa, bordered by Niger to the north, Chad to the northeast, Cameroon to the east, and Benin to the west. It also has a coastline along the Gulf of Guinea in the south."
      },
      {
        question: "How many states are there in Nigeria?",
        answer: "Nigeria is divided into 36 states and one Federal Capital Territory (FCT), which is Abuja."
      },
      {
        question: "Who was the first president of Nigeria?",
        answer: "The first president of Nigeria was Dr. Nnamdi Azikiwe, who served as the President of Nigeria from 1963 to 1966."
      },
      {
        question: "What is the population of Nigeria?",
        answer: "As of the latest estimates, Nigeria has a population of over 200 million people, making it the most populous country in Africa and the seventh most populous country in the world."
      },
      {
        question: "What is the primary language spoken in Nigeria?",
        answer: "The primary language spoken in Nigeria is English, which serves as the official language. However, Nigeria is linguistically diverse, with over 500 ethnic groups, each with its own language."
      },
      {
        question: "What is the main ethnic group in Nigeria?",
        answer: "The three largest ethnic groups in Nigeria are the Hausa-Fulani, Yoruba, and Igbo."
      },
      {
        question: "Who is the founder of modern-day Nigeria?",
        answer: "The founder of modern-day Nigeria is considered to be Sir Ahmadu Bello, the Sardauna of Sokoto, who played a significant role in the country's independence movement and later became the Premier of the Northern Region."
      },
      {
        question: "What is the main economic activity in Nigeria?",
        answer: "The main economic activity in Nigeria is petroleum production and export, which contributes significantly to the country's GDP."
      },
      {
        question: "What is the literacy rate in Nigeria?",
        answer: "As of the latest data, the literacy rate in Nigeria is estimated to be around 59.6%."
      },
      {
        question: "What is the life expectancy in Nigeria?",
        answer: "The life expectancy in Nigeria is approximately 54 years for males and 56 years for females."
      },
      {
        question: "What is the traditional attire of the Igbo people?",
        answer: "The traditional attire of the Igbo people includes clothing such as the 'Isiagu' for men and the 'George' wrapper for women, often complemented with accessories like coral beads."
      },
      {
        question: "Who was the first Nigerian to win the Nobel Prize?",
        answer: "The first Nigerian to win the Nobel Prize was Wole Soyinka, who won the Nobel Prize in Literature in 1986."
      },
      {
        question: "What is the main source of electricity in Nigeria?",
        answer: "The main sources of electricity in Nigeria are hydroelectric power, natural gas, and to a lesser extent, coal and oil."
      },
    
     
      {
        question: "What is the largest desert in Africa?",
        answer: "The Sahara Desert is the largest desert in Africa and the largest hot desert in the world."
      },
      {
        question: "Which African country was formerly known as Abyssinia?",
        answer: "Ethiopia was formerly known as Abyssinia."
      },

      {
        question: "What is the longest river in Africa?",
        answer: "The Nile River is the longest river in Africa and the world, stretching over 6,650 kilometers (4,130 miles) in length."
      },
      {
        question: "What is the highest mountain in Africa?",
        answer: "Mount Kilimanjaro, located in Tanzania, is the highest mountain in Africa, standing at approximately 5,895 meters (19,341 feet) above sea level."
      },
      {
        question: "Which African country is known as the 'Pearl of Africa'?",
        answer: "Uganda is often referred to as the 'Pearl of Africa' due to its natural beauty, diverse landscapes, and abundant wildlife."
      },
      {
        question: "What is the largest country in Africa by land area?",
        answer: "Algeria is the largest country in Africa by land area, covering approximately 2.38 million square kilometers (919,595 square miles)."
      },
      {
        question: "Which African country was formerly known as Tanganyika?",
        answer: "Tanzania was formerly known as Tanganyika before merging with Zanzibar to form the United Republic of Tanzania."
      },
      {
        question: "What is the capital city of South Africa?",
        answer: "Pretoria is one of the three capital cities of South Africa, serving as the administrative capital."
      },
      {
        question: "Which African country is famous for its ancient pyramids?",
        answer: "Egypt is famous for its ancient pyramids, including the Great Pyramid of Giza, one of the Seven Wonders of the Ancient World."
      },
      {
        question: "What is the currency of Kenya?",
        answer: "The currency of Kenya is the Kenyan Shilling."
      },
      {
        question: "Which African country is known as the 'Rainbow Nation'?",
        answer: "South Africa is often referred to as the 'Rainbow Nation' due to its diverse population and multiculturalism."
      },
      {
        question: "What is the primary religion in Morocco?",
        answer: "Islam is the primary religion in Morocco, with the majority of the population adhering to the Sunni branch."
      },
      {
        question: "Which African country is home to the Maasai people?",
        answer: "Kenya and Tanzania are home to the Maasai people, known for their distinctive culture, language, and attire."
      },
      {
        question: "What is the largest city in Africa by population?",
        answer: "Lagos, located in Nigeria, is the largest city in Africa by population, with over 21 million inhabitants."
      },
      {
        question: "Which African country is known as the 'Giant of Africa'?",
        answer: "Nigeria is often referred to as the 'Giant of Africa' due to its large population and economy."
      },
      // Add 70 more questions about Africa...


      {
        question: "What is the national symbol of Nigeria?",
        answer: "The national symbol of Nigeria is the Coat of Arms, which features an eagle, two horses, a black shield, and green and white bands."
      },
      {
        question: "Who was Nigeria's first female president?",
        answer: "Nigeria has not yet had a female president."
      },
      {
        question: "What is the nickname for the Nigerian national football team?",
        answer: "The Nigerian national football team is nicknamed the 'Super Eagles.'"
      },
      {
        question: "Which Nigerian musician is known as the 'King of Afrobeat'?",
        answer: "Fela Kuti is known as the 'King of Afrobeat' for his pioneering contributions to the music genre."
      },
      {
        question: "What is the largest city in Nigeria by land area?",
        answer: "Port Harcourt is the largest city in Nigeria by land area, covering approximately 360 square kilometers."
      },
      {
        question: "What is the most populous city in Nigeria?",
        answer: "Lagos is the most populous city in Nigeria, with over 21 million inhabitants."
      },
      {
        question: "Which Nigerian city is famous for its film industry, Nollywood?",
        answer: "Lagos is famous for its film industry, Nollywood, which is one of the largest film industries in the world in terms of output."
      },
      {
        question: "What is the largest ethnic group in Nigeria?",
        answer: "The Hausa-Fulani ethnic group is the largest ethnic group in Nigeria."
      },
      {
        question: "Who was the first prime minister of Nigeria?",
        answer: "Sir Abubakar Tafawa Balewa was the first prime minister of Nigeria, serving from 1957 to 1966."
      },
      {
        question: "Which Nigerian city is known as the 'Centre of Excellence'?",
        answer: "Lagos is often referred to as the 'Centre of Excellence' due to its economic importance and cultural vibrancy."
      },
      {
        question: "What is the highest point in Nigeria?",
        answer: "The highest point in Nigeria is Chappal Waddi, also known as Gangirwal, located in the Taraba State, with an elevation of 2,419 meters (7,936 feet)."
      },
      {
        question: "What is the largest market in West Africa?",
        answer: "The Onitsha Market in Nigeria is considered the largest market in West Africa in terms of geographical size and volume of goods traded."
      },
      {
        question: "Who was the first military ruler of Nigeria?",
        answer: "Major General Johnson Aguiyi-Ironsi was the first military ruler of Nigeria, assuming power after a military coup in 1966."
      },
      {
        question: "What is the main export of Nigeria?",
        answer: "The main export of Nigeria is petroleum and petroleum products."
      },
      {
        question: "What is the currency of Nigeria?",
        answer: "The currency of Nigeria is the Nigerian Naira (NGN)."
      },
    
      // Additional Questions about Africa (Continued)
      {
        question: "What is the largest lake in Africa?",
        answer: "Lake Victoria, located in East Africa, is the largest lake in Africa by surface area."
      },
      {
        question: "Which African country is known as the 'Land of a Thousand Hills'?",
        answer: "Rwanda is known as the 'Land of a Thousand Hills' due to its hilly and mountainous terrain."
      },
      {
        question: "What is the national flower of Egypt?",
        answer: "The national flower of Egypt is the Egyptian Lotus, also known as the Blue Lotus (Nymphaea caerulea)."
      },
      {
        question: "Which African country is famous for its rock-hewn churches in Lalibela?",
        answer: "Ethiopia is famous for its rock-hewn churches in Lalibela, which are a UNESCO World Heritage Site."
      },
      {
        question: "What is the official language of Angola?",
        answer: "Portuguese is the official language of Angola, a legacy of its colonial history."
      },
      {
        question: "Which African country is known as the 'Pearl of the Indian Ocean'?",
        answer: "Madagascar is known as the 'Pearl of the Indian Ocean' due to its stunning natural beauty and biodiversity."
      },
      {
        question: "What is the largest city in Algeria?",
        answer: "Algiers is the largest city in Algeria and serves as its capital."
      },
      {
        question: "Which African country is known for its annual Wildebeest Migration?",
        answer: "Tanzania and Kenya are known for the annual Wildebeest Migration, one of the most spectacular wildlife events in the world."
      },
      {
        question: "What is the primary religion in Burkina Faso?",
        answer: "Islam and Christianity are the primary religions in Burkina Faso, with Islam being the majority religion."
      },
      {
        question: "What is the most spoken language in Cameroon?",
        answer: "French and English are the official languages of Cameroon, with French being the most spoken."
      },
      {
        question: "Which African country is known as the 'Smiling Coast of Africa'?",
        answer: "The Gambia is known as the 'Smiling Coast of Africa' due to the friendliness of its people and its beautiful coastline."
      },
      {
        question: "What is the largest island country in Africa?",
        answer: "Madagascar is the largest island country in Africa, located off the southeastern coast of the continent."
      },
      {
        question: "Which African country is known as the 'Horn of Africa'?",
        answer: "The region known as the 'Horn of Africa' includes countries such as Somalia, Ethiopia, Eritrea, and Djibouti."
      },
      {
        question: "What is the main export of Ghana?",
        answer: "Ghana is known for its exports of gold, cocoa, and oil."
      },
      {
        question: "Which African country is known for its traditional music genre called 'Mbalax'?",
        answer: "Senegal is known for its traditional music genre called 'Mbalax,' popularized by artists like Youssou N'Dour."
      },
      // Add 55 more questions about Africa...
    
  ];
  