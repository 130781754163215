import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLcharacter_entities() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <!-- Making use of Entity Name-->
      &gt;
      &lt;
      &amp;
      &copy;
      &reg;
      &euro;
      &cent;
      &nbsp;
        
  `;

  const code2 = `
  <!-- Making use of Entity Number-->
  &#62;
  &#60;
  &#38;
  &#169;
  &#174;
  &#8364;
  &#162;
  &#160;
`;

const code3 = `
&#x003E;
&#x003C;
&#x0026;
&#x00A9;
&#x00AE;
&#x20AC;
&#x00A2;
&#x00A0;
`;
  
    return (
   <body>
      <Helmet>    
        <title>HTML Character Entities</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Character Entities, What is a character entity, Examples of some HTML Character Entities, Entity Name, Entity Number, Entity Hexadecimal, 
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
       <meta name="description" content="Character Entities are used in some situations whereby the browser might mix some tags with specials symbols and signs you want to be displayed on your webpage." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_nbsp"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_pre"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Character Entities</h1>
</header>

<Navbar />

<main>

<section>
<p>Character Entities are used in some situations whereby the browser might mix some tags with specials symbols and signs you want to be displayed on your webpage.</p>
<p>HTML Character Entities can be shown or displayed in three ways.</p>
<li>&amp;#entity-number: This makes use of a decimal encoding.</li>
<li>&entity-name: This makes use of a name encoding.</li>
<li>&amp;#xentity-hexadecimal: This makes use of an hexadecimal encoding.</li>
<h2>Examples of some HTML Character Entities</h2>
<table>
    <thead>
        <tr>
            <th>Entity</th>
            <th>Description</th> 
            <th>HTML Entity Name</th>
            <th>HTML Entity Number</th>
            <th>HTML Entity Hexadecimal</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td> &gt; </td>
            <td> greater than </td>
            <td> &amp;gt; </td>
            <td> &amp;#62; </td>
            <td> &amp;#x003E;</td>
        </tr>

        <tr>
            <td> &lt; </td>
            <td> less than </td>
            <td> &amp;lt; </td>
            <td> &amp;#60; </td>
            <td> &amp;#x003C;</td>
        </tr>

        <tr>
            <td> &amp; </td>
            <td> ampersand </td>
            <td> &amp;amp; </td>
            <td> &amp;#38; </td>
            <td> &amp;#x0026;</td>
        </tr>

        <tr>
            <td> &copy; </td>
            <td> copyright </td>
            <td> &amp;copy; </td>
            <td> &amp;#169; </td>
            <td> &amp;#x00A9;</td>
        </tr>

        <tr>
            <td> &reg; </td>
            <td> registered trademark </td>
            <td> &amp;reg; </td>
            <td> &amp;#174; </td>
            <td> &amp;#x00AE;</td>
        </tr>

        <tr>
            <td> &nbsp; </td>
            <td> non-breaking space </td>
            <td> &amp;nbsp; </td>
            <td> &amp;#160; </td>
            <td> &amp;#x00A0;</td>
        </tr>


        <tr>
            <td> &euro; </td>
            <td> euro </td>
            <td> &amp;euro; </td>
            <td> &amp;#8364; </td>
            <td> &amp;#x20AC;</td>
        </tr>

        <tr>
            <td> &pound; </td>
            <td> pound </td>
            <td> &amp;pound; </td>
            <td> &amp;#163; </td>
            <td> &amp;#x00A3;</td>
        </tr>

        <tr>
            <td> &cent; </td>
            <td> cent </td>
            <td> &amp;cent; </td>
            <td> &amp;#162; </td>
            <td> &amp;#x00A2;</td>
        </tr>

        <tr>
            <td> &yen; </td>
            <td> yen </td>
            <td> &amp;yen; </td>
            <td> &amp;#165; </td>
            <td> &amp;#x00A5;</td>
        </tr>
    </tbody>
</table>
<p>These are only a few of the character and there are many more for you to discover.</p>
</section>


<section>
<p>Here are some examples with our try it out editor.</p>

<h2>Entity Name</h2>
<h3>Example:</h3>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html51"><Tryitout /></ScrollToTopLink>

<h2>Entity Number</h2>
<h3>Example:</h3>
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html52"><Tryitout /></ScrollToTopLink>

<h2>Entity Hexadecimal</h2>
<h3>Example:</h3>
<SyntaxHighlighterComponent code={code3} language="html" />
<ScrollToTopLink to="/try_html53"><Tryitout /></ScrollToTopLink>
</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_nbsp"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_pre"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}