import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonArrays() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeArrayImport = `
# Importing array module
import array as arr
    `;

    const codeArrayCreate = `
# Creating an array
numbers = arr.array('i', [1, 2, 3, 4, 5])
print(numbers)  # Output: array('i', [1, 2, 3, 4, 5])
    `;

    const codeArrayAccess = `
# Accessing array elements
print(numbers[0])  # Output: 1
print(numbers[2])  # Output: 3
    `;

    const codeArrayAppend = `
# Appending elements to array
numbers.append(6)
print(numbers)  # Output: array('i', [1, 2, 3, 4, 5, 6])
    `;

    const codeArrayInsert = `
# Inserting element to array
numbers.insert(0, 0)
print(numbers)  # Output: array('i', [0, 1, 2, 3, 4, 5, 6])
    `;

    const codeArrayRemove = `
# Removing element from array
numbers.remove(3)
print(numbers)  # Output: array('i', [0, 1, 2, 4, 5, 6])
    `;

    const codeArrayPop = `
# Popping element from array
numbers.pop(1)
print(numbers)  # Output: array('i', [0, 2, 4, 5, 6])
    `;

    return (
        <body>
            <Helmet>
                <title>Python Arrays</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Arrays, Array Module, Create Array, Access Array Elements, Append Array, Insert Array, Remove Array, Pop Array" />
                <meta name="description" content="Learn about arrays in Python, including how to create, access, modify, and remove elements using the array module." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_lambda"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_lists"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Arrays</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Arrays in Python are used to store multiple values of the same type. This tutorial covers the array module, including creating arrays, accessing elements, and modifying arrays.</p>
                        
                        <h2>Importing the Array Module</h2>
                        <p>Use the <code>array</code> module to work with arrays in Python.</p>
                        <SyntaxHighlighterComponent code={codeArrayImport} language="python" />
                        
                        <h2>Creating an Array</h2>
                        <p>Create an array by specifying the type code and initial values.</p>
                        <SyntaxHighlighterComponent code={codeArrayCreate} language="python" />
                        
                        <h2>Accessing Array Elements</h2>
                        <p>Access elements in an array using their index.</p>
                        <SyntaxHighlighterComponent code={codeArrayAccess} language="python" />
                        
                        <h2>Appending Elements to an Array</h2>
                        <p>Use the <code>append()</code> method to add elements to the end of an array.</p>
                        <SyntaxHighlighterComponent code={codeArrayAppend} language="python" />
                        
                        <h2>Inserting Elements into an Array</h2>
                        <p>Use the <code>insert()</code> method to add elements at a specific position.</p>
                        <SyntaxHighlighterComponent code={codeArrayInsert} language="python" />
                        
                        <h2>Removing Elements from an Array</h2>
                        <p>Use the <code>remove()</code> method to delete specific elements.</p>
                        <SyntaxHighlighterComponent code={codeArrayRemove} language="python" />
                        
                        <h2>Popping Elements from an Array</h2>
                        <p>Use the <code>pop()</code> method to remove elements by index.</p>
                        <SyntaxHighlighterComponent code={codeArrayPop} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Arrays are useful for storing multiple values of the same type in Python. This tutorial covered creating arrays, accessing elements, appending elements, inserting elements, removing elements, and popping elements from arrays.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_lambda"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_lists"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
