import React, { useEffect, useState } from 'react';
import { Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoutes = ({ routes }) => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const userId = Cookies.get('_id');
      if (!userId) {
        setAuthenticated(false);
      } else {
        setAuthenticated(true);
      }
    };
    checkAuthentication();
  }, []);

  return (
    <>
      {routes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          element={authenticated ? route.element : <Navigate to="/login" replace />}
        />
      ))}
    </>
  );
};

export default ProtectedRoutes;
