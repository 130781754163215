export const southamericaData = [
    {
      question: "What is the largest country in South America by land area?",
      answer: "Brazil is the largest country in South America by land area."
    },
    {
      question: "Which country in South America has the highest population?",
      answer: "Brazil has the highest population in South America."
    },
    {
      question: "Who was the liberator of many South American countries, including Venezuela, Colombia, Ecuador, Peru, and Bolivia?",
      answer: "Simón Bolívar is known as the liberator of many South American countries."
    },
    {
      question: "Which South American country has the highest capital city in terms of altitude?",
      answer: "Bolivia's capital city, La Paz, is the highest capital city in terms of altitude."
    },
    {
      question: "What is the official language of Brazil?",
      answer: "Portuguese is the official language of Brazil."
    },
    {
      question: "Which South American country has a coastline on both the Atlantic and Pacific Oceans?",
      answer: "Chile has a coastline on both the Atlantic and Pacific Oceans."
    },
    {
      question: "What is the capital of Argentina?",
      answer: "Buenos Aires is the capital of Argentina."
    },
    {
      question: "What is the national sport of Colombia?",
      answer: "Tejo is the national sport of Colombia."
    },
    {
      question: "Which South American country is known for its large biodiversity and the Amazon Rainforest?",
      answer: "Brazil is known for its large biodiversity and the Amazon Rainforest."
    },
    {
      question: "Who was the first female president of Brazil?",
      answer: "Dilma Rousseff was the first female president of Brazil."
    },
    {
        question: "What is the currency of Peru?",
        answer: "The currency of Peru is the Peruvian Sol."
      },
      {
        question: "Which South American country is famous for its tango dance?",
        answer: "Argentina is famous for its tango dance."
      },
      {
        question: "What is the name of the highest peak in South America?",
        answer: "The highest peak in South America is Mount Aconcagua."
      },
      {
        question: "Which South American country is the world's largest producer of coffee?",
        answer: "Brazil is the world's largest producer of coffee."
      },
      {
        question: "What is the national dish of Brazil?",
        answer: "Feijoada is the national dish of Brazil."
      },
      {
        question: "What is the name of the famous Inca ruins located in Peru?",
        answer: "Machu Picchu is the famous Inca ruins located in Peru."
      },
      {
        question: "Which South American country is known for its Galapagos Islands?",
        answer: "Ecuador is known for its Galapagos Islands."
      },
      {
        question: "Who was the first indigenous president of Bolivia?",
        answer: "Evo Morales was the first indigenous president of Bolivia."
      },
      {
        question: "What is the national flower of Chile?",
        answer: "The national flower of Chile is the Copihue."
      },
      {
        question: "Which South American country is known for its carnival celebrations?",
        answer: "Brazil is known for its carnival celebrations."
      },
      {
        question: "What is the capital of Venezuela?",
        answer: "Caracas is the capital of Venezuela."
      },
      {
        question: "What is the name of the famous waterfall located on the border of Brazil and Argentina?",
        answer: "Iguazu Falls is the famous waterfall located on the border of Brazil and Argentina."
      },
      {
        question: "Which South American country is named after an Italian city?",
        answer: "Venezuela is named after the Italian city of Venice."
      },
      {
        question: "What is the official language of Uruguay?",
        answer: "The official language of Uruguay is Spanish."
      },
      {
        question: "Which South American country has the highest capital city in terms of population?",
        answer: "Peru's capital city, Lima, has the highest population in South America."
      },
      {
        question: "What is the name of the mountain range that runs along the western coast of South America?",
        answer: "The Andes is the mountain range that runs along the western coast of South America."
      },
      {
        question: "Which South American country is known for its production of emeralds?",
        answer: "Colombia is known for its production of emeralds."
      },
      {
        question: "Who was the first female president of Chile?",
        answer: "Michelle Bachelet was the first female president of Chile."
      },
      {
        question: "What is the name of the currency used in Argentina?",
        answer: "The currency used in Argentina is the Argentine Peso."
      },
      {
        question: "Which South American country is famous for its Carnival of Oruro celebration?",
        answer: "Bolivia is famous for its Carnival of Oruro celebration."
      },
      {
        question: "What is the official language of Suriname?",
        answer: "The official language of Suriname is Dutch."
      },
      {
        question: "Which South American country is the world's leading exporter of beef?",
        answer: "Uruguay is the world's leading exporter of beef."
      },
      {
        question: "What is the name of the strait that separates the mainland of South America from Tierra del Fuego?",
        answer: "The Strait of Magellan separates the mainland of South America from Tierra del Fuego."
      },
      {
        question: "Which South American country has the largest area of protected rainforest?",
        answer: "Brazil has the largest area of protected rainforest in South America."
      },
      {
        question: "What is the name of the famous ancient Nazca Lines located in Peru?",
        answer: "The Nazca Lines are the famous ancient lines located in Peru."
      },
      {
        question: "Which South American country is known for its Pampas region?",
        answer: "Argentina is known for its Pampas region."
      },
      {
        question: "What is the capital of Guyana?",
        answer: "The capital of Guyana is Georgetown."
      },
      {
        question: "Which South American country is known for its Angel Falls, the world's highest uninterrupted waterfall?",
        answer: "Venezuela is known for its Angel Falls."
      },
      {
        question: "What is the name of the desert located in Chile?",
        answer: "The Atacama Desert is located in Chile."
      },
      {
        question: "Which South American country is the largest producer of copper?",
        answer: "Chile is the largest producer of copper in South America."
      },
      {
        question: "What is the official language of Paraguay?",
        answer: "The official languages of Paraguay are Spanish and Guarani."
      },
      {
        question: "Which South American country is known for its production of cocaine?",
        answer: "Colombia is known for its production of cocaine."
      },
      {
        question: "What is the name of the indigenous people of the Andes region?",
        answer: "The Quechua people are indigenous to the Andes region."
      },
      {
        question: "Which South American country is known for its Easter Island?",
        answer: "Chile is known for its Easter Island."
      },
      {
        question: "What is the capital of Bolivia?",
        answer: "The capital of Bolivia is Sucre, while its seat of government is La Paz."
      },
      {
        question: "Which South American country is known for its Guarani language and culture?",
        answer: "Paraguay is known for its Guarani language and culture."
      },
      {
        question: "What is the national animal of Brazil?",
        answer: "The national animal of Brazil is the Jaguar."
      },
      {
        question: "Which South American country is known for its Lake Titicaca, the world's highest navigable lake?",
        answer: "Peru and Bolivia are known for Lake Titicaca."
      },
      {
        question: "What is the name of the mountain that overlooks the city of Rio de Janeiro?",
        answer: "Sugarloaf Mountain overlooks the city of Rio de Janeiro."
      },
      {
        question: "Which South American country is known for its Mate tea?",
        answer: "Argentina is known for its Mate tea."
      },
      {
        question: "What is the capital of Uruguay?",
        answer: "The capital of Uruguay is Montevideo."
      },
      {
        question: "Which South American country is known for its Carnival of Barranquilla celebration?",
        answer: "Colombia is known for its Carnival of Barranquilla celebration."
      },
      {
        question: "What is the official language of Bolivia?",
        answer: "The official languages of Bolivia are Spanish, Quechua, and Aymara."
      },
      {
        question: "Which South American country is known for its soccer prowess?",
        answer: "Brazil is known for its soccer prowess."
      },
      {
        question: "What is the name of the indigenous people of the Amazon Rainforest?",
        answer: "The Yanomami people are indigenous to the Amazon Rainforest."
      },
      {
        question: "Which South American country is known for its Falkland Islands?",
        answer: "Argentina is known for its Falkland Islands."
      },
      {
        question: "What is the capital of Ecuador?",
        answer: "The capital of Ecuador is Quito."
      },
      {
        question: "Which South American country is known for its Lake Maracaibo, one of the oldest lakes in the world?",
        answer: "Venezuela is known for Lake Maracaibo."
      },
      {
        question: "What is the name of the indigenous people of the Brazilian Amazon?",
        answer: "The Kayapo people are indigenous to the Brazilian Amazon."
      },
      {
        question: "Which South American country is known for its Carnival of Rio de Janeiro celebration?",
        answer: "Brazil is known for its Carnival of Rio de Janeiro celebration."
      },
      {
        question: "What is the official language of Ecuador?",
        answer: "The official language of Ecuador is Spanish."
      },
      {
        question: "Which South American country is known for its Lake Poopó, the second-largest lake in Bolivia?",
        answer: "Bolivia is known for Lake Poopó."
      },
      {
        question: "What is the capital of Suriname?",
        answer: "The capital of Suriname is Paramaribo."
      },
      {
        question: "Which South American country is known for its Llanos region?",
        answer: "Venezuela is known for its Llanos region."
      },
      {
        question: "What is the name of the indigenous people of the Amazon River Basin?",
        answer: "The Tikuna people are indigenous to the Amazon River Basin."
      },
      {
        question: "Which South American country is known for its Patagonia region?",
        answer: "Argentina and Chile are known for their Patagonia region."
      },
      {
        question: "What is the capital of Paraguay?",
        answer: "The capital of Paraguay is Asunción."
      },
      {
        question: "Which South American country is known for its Carnival of Montevideo celebration?",
        answer: "Uruguay is known for its Carnival of Montevideo celebration."
      },
      {
        question: "What is the official language of Guyana?",
        answer: "The official language of Guyana is English."
      },
      {
        question: "Which South American country is known for its Pantanal region?",
        answer: "Brazil is known for its Pantanal region."
      },
      {
        question: "What is the name of the strait that separates South America from Antarctica?",
        answer: "The Drake Passage separates South America from Antarctica."
      },
      {
        question: "Which South American country is known for its Churrasco, a grilled meat dish?",
        answer: "Brazil is known for its Churrasco."
      },
      {
        question: "What is the capital of French Guiana?",
        answer: "The capital of French Guiana is Cayenne."
      },
      {
        question: "Which South American country is known for its gauchos, skilled horsemen?",
        answer: "Argentina is known for its gauchos."
      },
      {
        question: "What is the name of the indigenous people of the Gran Chaco region?",
        answer: "The Guarani people are indigenous to the Gran Chaco region."
      },
      {
        question: "Which South American country is known for its Samba dance?",
        answer: "Brazil is known for its Samba dance."
      },
      {
        question: "What is the capital of French Guiana?",
        answer: "The capital of French Guiana is Cayenne."
      },
      {
        question: "Which South American country is known for its Yerba Mate, a traditional drink?",
        answer: "Uruguay is known for its Yerba Mate."
      },
      {
        question: "What is the name of the river that forms the border between Argentina and Uruguay?",
        answer: "The Uruguay River forms the border between Argentina and Uruguay."
      },
      {
        question: "Which South American country is known for its Carnaval de Negros y Blancos celebration?",
        answer: "Colombia is known for its Carnaval de Negros y Blancos celebration."
      },
      {
        question: "What is the official language of French Guiana?",
        answer: "The official language of French Guiana is French."
      },
      {
        question: "Which South American country is known for its penguins in Punta Tombo?",
        answer: "Argentina is known for its penguins in Punta Tombo."
      },
      {
        question: "What is the name of the indigenous people of the Chaco region?",
        answer: "The Toba people are indigenous to the Chaco region."
      },
      {
        question: "Which South American country is known for its Chicha, a fermented maize drink?",
        answer: "Peru is known for its Chicha."
      }
  ];
  