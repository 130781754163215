import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonDictionaryComprehensions() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const code1 = `
# Basic Dictionary Comprehension
squares = {x: x * x for x in range(6)}
print(squares)  # Output: {0: 0, 1: 1, 2: 4, 3: 9, 4: 16, 5: 25}
    `;

    const code2 = `
# Dictionary Comprehension with Condition
even_squares = {x: x * x for x in range(10) if x % 2 == 0}
print(even_squares)  # Output: {0: 0, 2: 4, 4: 16, 6: 36, 8: 64}
    `;

    const code3 = `
# Dictionary Comprehension from a List of Tuples
pairs = [('one', 1), ('two', 2), ('three', 3)]
numbers_dict = {k: v for k, v in pairs}
print(numbers_dict)  # Output: {'one': 1, 'two': 2, 'three': 3}
    `;

    const code4 = `
# Dictionary Comprehension with String Methods
words = ['apple', 'banana', 'cherry']
lengths = {word: len(word) for word in words}
print(lengths)  # Output: {'apple': 5, 'banana': 6, 'cherry': 6}
    `;

    const code5 = `
# Dictionary Comprehension with Nested Loops
nested_dict = {(x, y): x * y for x in range(3) for y in range(3)}
print(nested_dict)
# Output: {(0, 0): 0, (0, 1): 0, (0, 2): 0, (1, 0): 0, (1, 1): 1, (1, 2): 2, (2, 0): 0, (2, 1): 2, (2, 2): 4}
    `;

    const code6 = `
# Dictionary Comprehension with Existing Dictionary
original_dict = {'a': 1, 'b': 2, 'c': 3}
squared_values = {k: v**2 for k, v in original_dict.items()}
print(squared_values)  # Output: {'a': 1, 'b': 4, 'c': 9}
    `;

    const code7 = `
# Dictionary Comprehension with a Function
def square(x):
    return x * x

squares = {x: square(x) for x in range(6)}
print(squares)  # Output: {0: 0, 1: 1, 2: 4, 3: 9, 4: 16, 5: 25}
    `;

    return (
        <body>
            <Helmet>
                <title>Python Dictionary Comprehensions</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Dictionary Comprehensions, Examples, Conditions, Nested Loops, Functions" />
                <meta name="description" content="Learn about Python dictionary comprehensions with various examples including conditions, nested loops, and functions." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_dictionary_methods"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_strings"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Dictionary Comprehensions</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Dictionary comprehensions provide a concise way to create dictionaries. They are similar to list comprehensions but allow you to create dictionaries instead of lists.</p> 
                        <p>Here are some examples to help you understand how to use them effectively.</p>

                        <h2>Basic Dictionary Comprehension</h2>
                        <p>Create a dictionary where keys are numbers and values are their squares.</p>
                        <SyntaxHighlighterComponent code={code1} language="python" />

                        <h2>Dictionary Comprehension with Condition</h2>
                        <p>Create a dictionary with only even numbers and their squares.</p>
                        <SyntaxHighlighterComponent code={code2} language="python" />

                        <h2>Dictionary Comprehension from a List of Tuples</h2>
                        <p>Create a dictionary from a list of key-value pairs.</p>
                        <SyntaxHighlighterComponent code={code3} language="python" />

                        <h2>Dictionary Comprehension with String Methods</h2>
                        <p>Create a dictionary where keys are words and values are their lengths.</p>
                        <SyntaxHighlighterComponent code={code4} language="python" />

                        <h2>Dictionary Comprehension with Nested Loops</h2>
                        <p>Create a dictionary from the Cartesian product of two ranges.</p>
                        <SyntaxHighlighterComponent code={code5} language="python" />

                        <h2>Dictionary Comprehension with Existing Dictionary</h2>
                        <p>Create a dictionary where values are squared from an existing dictionary.</p>
                        <SyntaxHighlighterComponent code={code6} language="python" />

                        <h2>Dictionary Comprehension with a Function</h2>
                        <p>Create a dictionary where values are generated by a function.</p>
                        <SyntaxHighlighterComponent code={code7} language="python" />

                        <h2>Conclusion</h2>
                        <p>Dictionary comprehensions are a powerful tool in Python for creating dictionaries in a concise and readable manner. By mastering them, you can write cleaner and more efficient code.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_dictionary_methods"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_strings"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
