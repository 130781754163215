import React from 'react';
import '../css/my style2.css';
import '../css/my style.css';
import { Link } from 'react-router-dom';
import '../css/footer.css';
import './my script';
import "../css/navigation.css";
import ScrollToTopLink from './ScrollToTop';
import betadark from "../image/beta_dark.png";

function Footertutorials() {
    const code = {
        color: 'white'
    }
    return (
        <div className='footer-content'>
     <footer>
        <div className="footer-body">
          <div>
            <img src={betadark} style={{ height: "90px" }} alt='BetaThreads Logo' />
            <h3>Discover Resources</h3>
          </div>
          <ul className='footerul'>
                <li><ScrollToTopLink to="/about">About Us</ScrollToTopLink></li>
                <li><ScrollToTopLink to="/contact">Contact Us</ScrollToTopLink></li>
                <li><ScrollToTopLink to="/terms&privacy">Privacy Policy</ScrollToTopLink></li>
                <li><a href='https://forum.betathread.com' target='_blank'>Forum</a></li>
              </ul>
          <div className="footer-columns">
            <div className="footer-column">
            <ul>
                <h2>Our Top Tutorials</h2>
                <li><ScrollToTopLink to="/HTML_Tutorial_intro">HTML Tutorial</ScrollToTopLink></li>
                <li><ScrollToTopLink to="/CSS_Tutorial_intro">CSS Tutorial</ScrollToTopLink></li>
                <li><ScrollToTopLink to="/JavaScript_Tutorial_intro">JavaScript Tutorial</ScrollToTopLink></li>
                <li><ScrollToTopLink to="/PHP_Tutorial_intro">PHP Tutorial</ScrollToTopLink></li>
                <li><ScrollToTopLink to="/Python_Tutorial_intro">Python Tutorial</ScrollToTopLink></li>
                <li><ScrollToTopLink to="/SQL_Tutorial_intro">SQL Tutorial</ScrollToTopLink></li>
                <li><ScrollToTopLink to="/Ruby_Tutorial_intro">Ruby Tutorial</ScrollToTopLink></li>
                <li><ScrollToTopLink to="/XML_Tutorial_intro">XML Tutorial</ScrollToTopLink></li>
                <li><ScrollToTopLink to="/React_Tutorial_intro">React Tutorial</ScrollToTopLink></li>
                <li><ScrollToTopLink to="/AI_Tutorial_intro">AI Tutorial</ScrollToTopLink></li>
    
              </ul>

            </div>

            <div className="progress-column">
            <ul>
    <h2>Progress Trackers</h2>
    <li><ScrollToTopLink to="/html-progress-tracker">HTML Progress</ScrollToTopLink></li>
    <li><ScrollToTopLink to="/css-progress-tracker">CSS Progress</ScrollToTopLink></li>
    <li><ScrollToTopLink to="/js-progress-tracker">JavaScript Progress</ScrollToTopLink></li>
    <li><ScrollToTopLink to="/php-progress-tracker">PHP Progress</ScrollToTopLink></li>
    <li><ScrollToTopLink to="/python-progress-tracker">Python Progress</ScrollToTopLink></li>
    <li><ScrollToTopLink to="/sql-progress-tracker">SQL Progress</ScrollToTopLink></li>
    <li><ScrollToTopLink to="/ruby-progress-tracker">Ruby Progress</ScrollToTopLink></li>
    <li><ScrollToTopLink to="/xml-progress-tracker">XML Progress</ScrollToTopLink></li>
    <li><ScrollToTopLink to="/react-progress-tracker">React Progress</ScrollToTopLink></li>
    <li><ScrollToTopLink to="/ai-progress-tracker">AI Progress</ScrollToTopLink></li>
</ul>

            </div>

            <div className="tutorial-column">
           
              <ul>
           <h2>Tutorial Tips</h2>
            <li><ScrollToTopLink to="/html_tips">HTML Tips</ScrollToTopLink></li>
            <li><ScrollToTopLink to="/css_tips">CSS Tips</ScrollToTopLink></li>
            <li><ScrollToTopLink to="/js_tips">JavaScript Tips</ScrollToTopLink></li>
            <li><ScrollToTopLink to="/php_tips">PHP Tips</ScrollToTopLink></li>
            <li><ScrollToTopLink to="/python_tips">Python Tips</ScrollToTopLink></li>
            <li><ScrollToTopLink to="/sql_tips">SQL Tips</ScrollToTopLink></li>
            <li><ScrollToTopLink to="/ruby_tips">Ruby Tips</ScrollToTopLink></li>
            <li><ScrollToTopLink to="/xml_tips">XML Tips</ScrollToTopLink></li>
            <li><ScrollToTopLink to="/react_tips">React Tips</ScrollToTopLink></li>
            <li><ScrollToTopLink to="/ai_tips">AI Tips</ScrollToTopLink></li>
           </ul>     
            </div>
          </div>
       <h2 style={{textAlign: "center"}}>Follow us on</h2>
        <div className="contained">
       <span className="bt-tooltip">
       <a href="https://web.facebook.com/officialbetathread" title="Beta Thread on facebook" target="_blank" rel="noopener noreferrer"><img src={require('../image/icons8-facebook-53.png')} alt='facebook'/></a>
      </span>
       <span className="bt-tooltip"> 
       <a href="https://twitter.com/beta_thread" title="Beta Thread on X" target="_blank" rel="noopener noreferrer"><img src={require('../image/xsvg.svg').default} alt='X'/></a>
       </span>

       <span class="bt-tooltip">
       <a href="https://www.instagram.com/beta_thread/" title="Beta Thread on instagram" target="_blank" rel="noopener noreferrer"><img src={require('../image/myinstagram.png')} alt="instagram" /></a>       
       </span>

       <span className="bt-tooltip">
       <a href="https://www.youtube.com/channel/UCPwzko2gd-m5zbK5k93q_aA" title="Beta Thread on youtube" target="_blank" rel="noopener noreferrer"><img src={require('../image/icons8-youtube-53.png')} alt='youtube'/></a>
       </span>

       <span className="bt-tooltip">
       <a href="https://whatsapp.com/channel/0029Va8jjicGehEOckJt1a47" title="Join our channel on whatsapp" target="_blank" rel="noopener noreferrer"><img src={require('../image/mywhatsapp.png')} alt='whatsapp'/></a>
      </span>

        </div>
        <div>
       </div>
    </div><br /><br /><br />

<div class="footer-copyright">
  &copy; 2024 Beta Thread. All rights reserved.

</div>

        </footer>
        </div>
    );
}

export default Footertutorials;