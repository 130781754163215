export const saveAuthStatus = (status) => {
  localStorage.setItem('authStatus', JSON.stringify(status));
};

export const getAuthStatus = () => {
  const status = localStorage.getItem('authStatus');
  return status ? JSON.parse(status) : null;
};

export const deleteAuthStatus = () => {
  localStorage.removeItem('authStatus');
};

