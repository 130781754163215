import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import ScrollToTopLink from "../../components/ScrollToTop"; // Import the ScrollToTopLink component

export default function CSSBackgroundImage() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
   
    const code = `
    selector {
        background-image:  url(image_path.jpg); /* example image */
    }
    `;
    const code2 = `
    .hero-section {
        background-image:  url(A Ship.jpg); /* Set hero section background image */
    }
    `;
    const code3 = `
    .hero-section {
        background-image:  url(A Ship.jpg); 
        background-repeat: repeat;
    }
    `;
    const code4 = `
    .hero-section {
        background-image:  url(A Ship.jpg); 
        background-repeat: no-repeat;
    }
    `;

    return (
        <body>
        <Helmet>     
        <title>CSS Background Images</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Background Images, How to set the background image of an element, CSS Background Image Syntax, Image Repeat, Image No Repeat,
        Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="The background-color property sets the color of an element's background. It's used to give a solid color to the background." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
            <br />
        <br />
        <br />
        <br />
        <br />
            <Sidenav />
        
            <div className="content">

                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/CSS_background_colors"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/CSS_background_attachment"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>CSS Background Images</h1>
                </header>

                <Navbar />

                <main>
                    <section>
                        <p>With the background-image property, you can adorn an element's background with an image of your choice.</p>
                        <p>This can add depth and character to your design. The value can be a URL to an image file,
                            enabling you to seamlessly integrate images into your backgrounds.</p>
                        <br />
                        <h2>Syntax</h2>
                        <SyntaxHighlighterComponent code={code} language="css" />
                        <br />
                        <h2>Example:</h2>
                        <SyntaxHighlighterComponent code={code2} language="css" />
                        <ScrollToTopLink to="/try_css25"><Tryitout /></ScrollToTopLink>

                        <h2>Image Repeat</h2>
                        <p>We can allow images to be repeated over and over again by setting the background-repeat property to repeat.</p>
                        <br />
                        <h2>Example:</h2>
                        <SyntaxHighlighterComponent code={code3} language="css" />
                        <ScrollToTopLink to="/try_css26"><Tryitout /></ScrollToTopLink>

                        <h2>Image No Repeat</h2>
                        <p>We can prevent images from being repeated over and over again by setting the background-repeat property
                            to no repeat.
                        </p>
                        <br />
                        <h2>Example:</h2>
                        <SyntaxHighlighterComponent code={code4} language="css" />
                        <ScrollToTopLink to="/try_css27"><Tryitout /></ScrollToTopLink>
                    </section>

                </main>
                <div className="head">
                    <ScrollToTopLink to="/CSS_background_colors"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/CSS_background_attachment"><ButtonNext /></ScrollToTopLink>
                </div>

            </div>

            <Footertutorials />
        </body>
    )
}
