import React, { useEffect, useState } from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../css/special styles.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import { getAuthStatus, saveAuthStatus } from "../../localstorage";
import Cookies from 'js-cookie';
import DemoProgress from "../../components/Tutorial Progress/DemoProgress";
import ButtonHome from "../../components/Buttonhome";



export default function XMLintro() {

    

   
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    <book>
    <title>Harry Potter</title>
    <author>J.K. Rowling</author>
    </book>
    `;
    const code2 = `
    <book category="fantasy">
    <title>Harry Potter</title>
    <author>J.K. Rowling</author>
    </book>

    `;
    const code3 = `
    <description>This is a classic fantasy novel.</description>
    `;
    return (
   <body>
      <Helmet>
     <title>XML Tutorial</title>
     <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Introduction to XML, Key Concepts of XML, XML Document Structure, Advantages of XML, XML Step-by-step XML programming tutorials, XML coding exercises for beginners, Advanced XML coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       XML programming tips for real-world applications." />
   <meta name="description" content="Explore the power of XML on our XML tutorial page. Learn how to use XML to streamline code organization, enhance readability, and unlock advanced features in XML development. Discover practical examples and best practices to elevate your programming skills. Dive into the world of XML with our concise and informative guide" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/"><ButtonHome/></ScrollToTopLink>
<ScrollToTopLink to="/XML_syntax"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>XML Introduction</h1>
</header>

<Navbar />

<main>
<section>
<p> XML (eXtensible Markup Language) is a versatile and widely-used markup language designed to store, structure,
     and transport data </p>
<p> It's often used for representing and exchanging information between different systems, making it a 
    fundamental technology in the world of data interchange and communication.
</p>
<h2>Key Concepts of XML</h2>
<li><b>Markup Language:</b><br />
     XML is a markup language, which means it uses tags to define elements and 
    their relationships. These tags provide a way to structure and annotate data within a text document.</li>
   <br />
<li><b>Self-Descriptive:</b><br />
     XML documents are self-descriptive. This means that they include information
     about the structure and content of the data they contain. The tags used in XML provide context and meaning to the data.</li>
  <br />
<li><b>Hierarchical Structure:</b><br />
     XML documents have a hierarchical structure composed of elements. Elements can contain
     other elements and form a tree-like structure, allowing for nesting and organization.</li>
  <br />
  <li><b>Data and Metadata:</b><br />
     XML is versatile in that it can represent both actual data and metadata (information about the data).
     This makes it useful for describing the content and characteristics of information.</li>
<li><b>Platform and Language Agnostic:</b><br />
    XML is not tied to any specific programming language or platform. It's universally understood and can be used across different 
    systems and technologies.</li>
  </section>

<section>
  <h2>XML Document Structure</h2>
 <p>An XML document is made up of elements, attributes, and text. Here's a basic breakdown of these components:</p>
<li><b>Elements:</b><br />
  <p>Elements are the building blocks of an XML document. They're enclosed in angle brackets and can have a start tag, an end tag, 
    and content between them.</p>
    <p> Elements can be nested within other elements, forming a hierarchical structure.</p></li>
  <br />
  <h2>Example:</h2>
  <SyntaxHighlighterComponent code={code} language="xml" />
      <br />
<li><b>Attributes:</b><br />
<p>Attributes provide additional information about an element. </p>
<p>They're part of the start tag and consist of a name-value pair.</p>
</li>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="xml" />
<br />
    <li><b>Text Content:</b><br />
    <p>Text content appears between the start and end tags of an element.</p>
    </li>
    <br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code3} language="xml" />
<br />
<h2>Advantages of XML</h2>
<li>Structured Data Exchange</li>
<li>Extensibility</li>
<li>Interoperability</li>
<li>Human-Readability</li>

</section>

<div className="second-div">
 
     <section className="features">
     <div className="progress-sample">
    
         <img src={require('../../image/beta-progress.svg').default} width={500} height={500}  alt='betathread' />
       <p style={{fontSize: '15px'}}> Our Progress Tracker is designed to help you monitor your progress through our extensive range of tutorials.</p>
       <ScrollToTopLink to="/progress-tracker"><button type="submit" className="buttonb">Track Progress</button></ScrollToTopLink>
   
     </div>

     <div className="progress-sampling">
     <h1 style={{fontSize: '45px'}}>Track Your Progress</h1>
     <DemoProgress />
     </div>
   </section>
  </div>
</main>
<div className="head">
<ScrollToTopLink to="/"><ButtonHome/></ScrollToTopLink>
<ScrollToTopLink to="/XML_syntax"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}