import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLXQuery() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<bookstore>
  <book category="cooking">
    <title lang="en">Everyday Italian</title>
    <author>Giada De Laurentiis</author>
    <year>2005</year>
    <price>30.00</price>
  </book>
  <book category="children">
    <title lang="en">Harry Potter</title>
    <author>J K. Rowling</author>
    <year>2005</year>
    <price>29.99</price>
  </book>
  <book category="web">
    <title lang="en">Learning XML</title>
    <author>Erik T. Ray</author>
    <year>2003</year>
    <price>39.95</price>
  </book>
</bookstore>
`;

  const example2 = `
XQuery:
for $book in doc("bookstore.xml")/bookstore/book
where $book/price>30
return $book/title

Result:
<title lang="en">Learning XML</title>
`;

  const example3 = `
XQuery:
for $book in doc("bookstore.xml")/bookstore/book
order by $book/title
return $book/title

Result:
<title lang="en">Everyday Italian</title>
<title lang="en">Harry Potter</title>
<title lang="en">Learning XML</title>
`;

  const example4 = `
XQuery:
for $book in doc("bookstore.xml")/bookstore/book
let $discount := $book/price * 0.1
return <book>
  <title>{$book/title/text()}</title>
  <discount>{$discount}</discount>
</book>

Result:
<book>
  <title>Everyday Italian</title>
  <discount>3.00</discount>
</book>
<book>
  <title>Harry Potter</title>
  <discount>2.99</discount>
</book>
<book>
  <title>Learning XML</title>
  <discount>3.995</discount>
</book>
`;

  return (
    <body>
      <Helmet>
        <title>XQuery Tutorial</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML XQuery, XML Tutorial, XML Data Querying" />
        <meta name="description" content="Learn how to use XQuery for querying XML data with examples and explanations of the syntax and usage." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_xpath_axes"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_xquery_expressions"><ButtonNext /></ScrollToTopLink>
          <h1>XQuery Tutorial</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XQuery is a powerful and flexible language designed to query and manipulate XML data. It is similar to SQL for databases but is specifically designed for XML data.</p>

            <h2>Example 1: Basic XML Structure</h2>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This example shows a basic XML structure with a bookstore containing books. We will use XQuery to query this data.</p>

            <h2>Example 2: Querying Books with Price Greater than 30</h2>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>This example demonstrates an XQuery expression that selects the titles of books with a price greater than 30.</p>

            <h2>Example 3: Ordering Books by Title</h2>
            <SyntaxHighlighterComponent code={example3} language="xml" />
            <p>This example demonstrates an XQuery expression that orders the books by their title.</p>

            <h2>Example 4: Calculating Discounts</h2>
            <SyntaxHighlighterComponent code={example4} language="xml" />
            <p>This example demonstrates an XQuery expression that calculates a 10% discount for each book and returns the title and discount amount.</p>

            <h2>Understanding XQuery</h2>
            <p>XQuery is designed to meet the needs of querying XML data. It allows for complex data retrieval and transformation operations. Some key features include:</p>

            <ul>
              <li>FLWOR expressions (For, Let, Where, Order by, Return)</li>
              <li>Path expressions to navigate through XML data</li>
              <li>Conditional expressions and looping</li>
              <li>Functions and operators for manipulating XML data</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_xpath_axes"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xquery_expressions"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
