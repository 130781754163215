import React from 'react';
import { Helmet } from 'react-helmet';
import styles from "../../css/styles.module.css";

function EmailSent() {
  return (
    <body>
      <Helmet>
      <meta name="robots" content="noindex" />
      </Helmet>

      <div className={styles.container}>
        <div className={styles.form_container}>
        <div className={styles.left}>
            <img className={styles.img} src="./images/login.jpg" alt="forgot password" />
          </div>
        <div className={styles.right}>
          <h2 className={styles.heading}>Email Sent</h2><br /><br /><br />
          <p>
            If an account with that email exists, you will receive an email with instructions to reset your password shortly.
            Please check your inbox and follow the instructions provided.
          </p>
          <p><b>Note: </b>The reset link will expire 1 hour after it is sent.</p>
        </div>
      </div>
      </div>
    </body>
  );
}

export default EmailSent;
