import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPLoops() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    for ($i = 0; $i < 5; $i++) {
      echo "Iteration $i <br>";
   }
     `;
    const code2 = `
    $i = 0;
    while ($i < 5) {
        echo "Iteration $i <br>";
        $i++;
    }
       `;
    const code3 = `
    $i = 0;
    do {
        echo "Iteration $i <br>";
        $i++;
    } while ($i < 5);
       `;
    const code4 = `
    $fruits = array("Apple", "Banana", "Orange");
    foreach ($fruits as $fruit) {
        echo $fruit . "<br>";
    }
       `;

    return (
   <body>
   <Helmet>
        <title>PHP Loops</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Loops, for loop, while loop, do-while loop, foreach loop, switch statement,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="Loops are a fundamental part of control structures, enabling you to iterate over arrays, perform repetitive tasks, and execute code multiple times." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_conditional"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_switch"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Loops</h1>
</header>

<Navbar />

<main>
<section>
<p>In PHP, control structures allow you to execute certain blocks of code based on specified conditions or to repeatedly execute blocks of code.</p>
<p> Loops are a fundamental part of control structures, enabling you to iterate over arrays, perform repetitive tasks, and execute code multiple times.</p>
<p>There are four main types of loops in PHP:</p>

<h2>for loop</h2>
<p>The 'for loop' is used when you know exactly how many times you want to execute a block of code.</p>
<br />
<SyntaxHighlighterComponent code={code} language="php" />
<h3>In this example:</h3>
<ul>
  <li>'$i = 0;' initializes the loop control variable.</li>
  <li>'$i &lt; 5;' is the condition. The loop will continue executing as long as this condition is true.</li>
  <li>'$i++' is the increment operation. It increases the value of '$i by 1 in each iteration.</li>
</ul>

<h2>while loop</h2>
<p>The 'while loop' is used when you want to execute a block of code repeatedly as long as a specified condition is true.</p>
<br />
<SyntaxHighlighterComponent code={code2} language="php" />
<h3>In this example:</h3>
<ul>
  <li>'$i = 0;' initializes the loop control variable.</li>
  <li>'$i &lt; 5;' is the condition.</li>
  <li>'$i++' is the increment operation.</li>
</ul>

<h2>do-while loop</h2>
<p>The 'do-while loop' is similar to a while loop, but it executes the block of code at least once, then checks the condition.</p>
<br />
<SyntaxHighlighterComponent code={code3} language="php" />
<h3>In this example:</h3>
<ul>
  <li>The block of code is executed at least once, even if the condition is false initially.</li>
</ul>

<h2>foreach loop</h2>
<p>The 'foreach loop' is specifically designed to iterate over arrays and objects.</p>
<br />
<SyntaxHighlighterComponent code={code4} language="php" />
<h3>In this example:</h3>
<ul>
  <li>'$fruits' is an array to iterate over.</li>
  <li>'$fruit' is the value of the current element in each iteration.</li>
</ul>
<p>In summary, PHP loops under control structures allow you to execute code repeatedly based on certain conditions or iterate over arrays.</p>
<p>Understanding these loops and their syntax, conditions, and incrementing/decrementing operations is essential for effective programming in PHP.</p>
</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_conditional"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_switch"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}