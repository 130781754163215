import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonTuples() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeCreation = `
# Create a tuple
my_tuple = ("apple", "banana", "cherry")
print(my_tuple)  # Output: ("apple", "banana", "cherry")
    `;

    const codeAccess = `
# Access tuple elements
my_tuple = ("apple", "banana", "cherry")
print(my_tuple[1])  # Output: "banana"
    `;

    const codeImmutable = `
# Tuples are immutable
my_tuple = ("apple", "banana", "cherry")
my_tuple[1] = "orange"  # TypeError: 'tuple' object does not support item assignment
    `;

    const codeLength = `
# Get the length of a tuple
my_tuple = ("apple", "banana", "cherry")
print(len(my_tuple))  # Output: 3
    `;

    const codeConcatenation = `
# Concatenate tuples
tuple1 = ("a", "b", "c")
tuple2 = (1, 2, 3)
result = tuple1 + tuple2
print(result)  # Output: ("a", "b", "c", 1, 2, 3)
    `;

    const codeMultiplication = `
# Multiply tuples
my_tuple = ("hello",)
result = my_tuple * 3
print(result)  # Output: ("hello", "hello", "hello")
    `;

    return (
        <body>
            <Helmet>
                <title>Python Tuples</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Tuples, Tuple Creation, Accessing Tuple Elements, Tuple Length, Tuple Concatenation, Tuple Multiplication" />
                <meta name="description" content="Learn about tuples in Python, including creation, accessing elements, immutability, length, concatenation, and multiplication." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                <ScrollToTopLink to="/Python_list_comprehensions"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_tuple_methods"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Introduction to Tuples</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>A tuple in Python is a collection of ordered, immutable elements. This tutorial covers the basics of tuples and their common operations.</p>
                        
                        <h2>Tuple Creation</h2>
                        <p>To create a tuple, enclose the elements within parentheses.</p>
                        <SyntaxHighlighterComponent code={codeCreation} language="python" />
                        
                        <h2>Accessing Tuple Elements</h2>
                        <p>You can access elements of a tuple using indexing.</p>
                        <SyntaxHighlighterComponent code={codeAccess} language="python" />
                        
                        <h2>Immutability</h2>
                        <p>Tuples are immutable, meaning their elements cannot be changed after creation.</p>
                        <SyntaxHighlighterComponent code={codeImmutable} language="python" />
                        
                        <h2>Tuple Length</h2>
                        <p>Use the <code>len()</code> function to get the length of a tuple.</p>
                        <SyntaxHighlighterComponent code={codeLength} language="python" />
                        
                        <h2>Tuple Concatenation</h2>
                        <p>You can concatenate two tuples using the <code>+</code> operator.</p>
                        <SyntaxHighlighterComponent code={codeConcatenation} language="python" />
                        
                        <h2>Tuple Multiplication</h2>
                        <p>You can multiply a tuple by an integer to repeat its elements.</p>
                        <SyntaxHighlighterComponent code={codeMultiplication} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Tuples are versatile data structures in Python, offering a convenient way to store and manipulate data. Understanding tuple basics is essential for efficient Python programming.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_list_comprehensions"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_tuple_methods"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
