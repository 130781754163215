import React from "react";

const runcode = () => {
  
  var codes = document.getElementById("editor").innerText;
  var output = document.getElementById("output");
  
  // Exclude the alert from being executed as code
  var alertIndex = codes.indexOf("Copied to Clipboard!");
  if (alertIndex !== -1) {
    codes = codes.substring(0, alertIndex);
  }
  
  output.innerHTML = codes;
}

export default runcode;