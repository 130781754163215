import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIEthics() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
# Example: Fairness in AI - Addressing Bias in Data
def remove_bias(data):
    unbiased_data = []
    for record in data:
        if not is_biased(record):
            unbiased_data.append(record)
    return unbiased_data

data = load_data()
clean_data = remove_bias(data)
save_data(clean_data)
  `;

  const example2 = `
# Example: Transparency in AI - Explainable AI Models
from sklearn.tree import DecisionTreeClassifier
from sklearn.datasets import load_iris
from sklearn import tree
import matplotlib.pyplot as plt

iris = load_iris()
X, y = iris.data, iris.target

clf = DecisionTreeClassifier()
clf = clf.fit(X, y)

plt.figure(figsize=(12, 8))
tree.plot_tree(clf, filled=True)
plt.show()
  `;

  return (
    <body>
      <Helmet>
        <title>AI Ethics</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Ethics, Fairness in AI, Transparency in AI, Privacy in AI, Accountability in AI, AI Tutorial" />
        <meta name="description" content="Learn about AI ethics, including fairness, transparency, privacy, and accountability in AI systems with practical examples and implementations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/AI_robotics_applications"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
          <h1>AI Ethics</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>AI ethics is a crucial field that addresses the moral implications of artificial intelligence technologies. As AI systems become more integrated into our daily lives, it is essential to ensure that they operate fairly, transparently, and responsibly. The following sections explore various aspects of AI ethics, including fairness, transparency, privacy, and accountability.</p>

            <h2>Fairness in AI</h2>
            <p>Ensuring fairness in AI involves addressing biases in data and algorithms to prevent discrimination and ensure equal treatment. AI systems should be designed to treat all individuals and groups fairly, without perpetuating existing biases. Techniques such as bias mitigation and fairness-aware algorithms are essential for achieving this goal.</p>
            <SyntaxHighlighterComponent code={example1} language="python" />
            <p>In the example above, we demonstrate a simple method for removing bias from a dataset. The function checks each record for bias and only includes unbiased records in the final dataset.</p>

            <h2>Transparency in AI</h2>
            <p>Transparency in AI can be achieved through explainable AI models, which help users understand how decisions are made by the AI system. This is particularly important in high-stakes applications such as healthcare, finance, and criminal justice, where decisions can have significant consequences for individuals.</p>
            <SyntaxHighlighterComponent code={example2} language="python" />
            <p>In this example, we use a decision tree classifier and visualize the decision-making process. The tree plot provides a clear, interpretable representation of how the model makes decisions based on the input features.</p>

            <h2>Privacy in AI</h2>
            <p>Privacy is a fundamental ethical concern in AI, as systems often process large amounts of personal data. Ensuring privacy involves implementing techniques such as differential privacy, which allows data analysis while protecting individual data points. Organizations must also comply with data protection regulations, such as GDPR, to safeguard users' privacy rights.</p>

            <h2>Accountability in AI</h2>
            <p>Accountability in AI systems means ensuring that there is a clear understanding of who is responsible for the actions and decisions made by AI. This includes creating audit trails, maintaining logs of AI system activities, and establishing protocols for addressing issues when they arise. Ensuring accountability helps build trust in AI technologies and provides a means for recourse in case of errors or misuse.</p>

            <h2>Conclusion</h2>
            <p>Addressing ethical concerns in AI is essential for building trustworthy and responsible AI systems. By focusing on fairness, transparency, privacy, and accountability, we can develop AI technologies that benefit society while minimizing potential harms. As AI continues to evolve, ongoing efforts to address these ethical considerations will be crucial for ensuring that AI serves the greater good.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_robotics_applications"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
