import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import toggle from "../responsivetopnav";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../ScrollToTop";
import { useLocation } from 'react-router-dom';
import PHPSearchBar from "../Searchbar/PHP SearchBar/PHPSearchBar";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export default function Sidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  const toggleDropdown3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
  };

  const toggleDropdown4 = () => {
    setIsDropdownOpen4(!isDropdownOpen4);
  };

  const toggleDropdown5 = () => {
    setIsDropdownOpen5(!isDropdownOpen5);
  };
  var nav = {
    color: 'white',
    fontSize: '30px'
}
var newCode = {
  float: 'right'
}
    return (
      <div>
      <div className={`sidebar ${isOpen ? 'responsive' : ''}`} id="mySidebar">
        <span className="close" onClick={toggle}>&times;</span>
    <h2>PHP Tutorial</h2><br />
    <span><PHPSearchBar /></span><br />
    <p className="p-sidebar">...Empowering Your Online Presence...</p><br />
    <ScrollToTopLink to="/PHP_Tutorial_intro"><a className={location.pathname === '/PHP_Tutorial_intro' ? 'active' : ''}>PHP Introduction</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_environment_setup"><a className={location.pathname === '/PHP_environment_setup' ? 'active' : ''}>PHP Environment Setup</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_Syntax"><a className={location.pathname === '/PHP_Syntax' ? 'active' : ''}>PHP Syntax</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_Comments"><a className={location.pathname === '/PHP_Comments' ? 'active' : ''}>PHP Comments</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_variables"><a className={location.pathname === '/PHP_variables' ? 'active' : ''}>PHP Variables</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_datatypes"><a className={location.pathname === '/PHP_datatypes' ? 'active' : ''}>PHP Data Types</a></ScrollToTopLink><br />
    <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown}>
          PHP Statements {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen && (
          <div className="dropdown-content">
    <ScrollToTopLink to="/PHP_echo"><a className={location.pathname === '/PHP_echo' ? 'active' : ''}>PHP Echo</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_print"><a className={location.pathname === '/PHP_print' ? 'active' : ''}>PHP Print</a></ScrollToTopLink><br />
         </div>
        )}
      </div>
 
      <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown2}>
          PHP Operators {isDropdownOpen2 ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen2 && (
          <div className="dropdown-content">
    <ScrollToTopLink to="/PHP_operators"><a className={location.pathname === '/PHP_operators' ? 'active' : ''}>PHP Operators</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_arithmetic"><a className={location.pathname === '/PHP_arithmetic' ? 'active' : ''}>PHP Arithmetic Operators</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_assignment"><a className={location.pathname === '/PHP_assignment' ? 'active' : ''}>PHP Assignment Operators</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_comparison"><a className={location.pathname === '/PHP_comparison' ? 'active' : ''}>PHP Comparison Operators</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_logical"><a className={location.pathname === '/PHP_logical' ? 'active' : ''}>PHP Logical Operators</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_increment"><a className={location.pathname === '/PHP_increment' ? 'active' : ''}>PHP Increment Operators</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_decrement"><a className={location.pathname === '/PHP_decrement' ? 'active' : ''}>PHP Decrement Operators</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_string"><a className={location.pathname === '/PHP_string' ? 'active' : ''}>PHP String Operators</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/PHP_array"><a className={location.pathname === '/PHP_array' ? 'active' : ''}>PHP Array Operators</a></ScrollToTopLink><br />
     
            </div>
        )}
      </div>

    <h2>PHP Control Structures</h2>
    <ScrollToTopLink to="/PHP_conditional"><a className={location.pathname === '/PHP_conditional' ? 'active' : ''}>PHP Conditional Statements</a></ScrollToTopLink><br />
   <ScrollToTopLink to="/PHP_loops"><a className={location.pathname === '/PHP_loops' ? 'active' : ''}>PHP Loops</a></ScrollToTopLink><br />
   <ScrollToTopLink to="/PHP_switch"><a className={location.pathname === '/PHP_switch' ? 'active' : ''}>PHP Switch</a></ScrollToTopLink><br />
   <ScrollToTopLink to="/PHP_functions"><a className={location.pathname === '/PHP_functions' ? 'active' : ''}>PHP Functions</a></ScrollToTopLink><br />
   </div>
  
  
  <div class="burger-icon" onClick={toggle}>
    &#9776;
    <p style={newCode}>Menu</p>
  </div>
</div>
    )
}