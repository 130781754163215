import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyConstants() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const constantsCode = `
# Defining Constants
PI = 3.14159
USERNAME = "admin"

# Using Constants
puts PI        # Output: 3.14159
puts USERNAME  # Output: admin

# Attempting to change a constant will generate a warning
PI = 3.14      # Warning: already initialized constant PI
puts PI        # Output: 3.14
  `;

  const classConstantsCode = `
# Constants in Classes
class Circle
  PI = 3.14159
  
  def initialize(radius)
    @radius = radius
  end
  
  def area
    PI * @radius ** 2
  end
end

circle = Circle.new(5)
puts circle.area  # Output: 78.53975
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Constants</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Constants, Defining Constants, Class Constants" />
        <meta name="description" content="Learn about constants in Ruby, how to define and use them, and their behavior within classes." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_data_types"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_operators"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Constants</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Constants in Ruby are used to store values that are not intended to change. They are written in all uppercase letters and can be defined both globally and within classes.</p>
            
            <h2>Defining Constants</h2>
            <p>Constants are defined by assigning a value to a name that starts with an uppercase letter. While you can technically change the value of a constant, Ruby will issue a warning.</p>
            <SyntaxHighlighterComponent code={constantsCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>PI</code> and <code>USERNAME</code> are constants.</li>
              <li>Changing the value of <code>PI</code> generates a warning, but Ruby will still allow the change.</li>
            </ul>

            <h2>Constants in Classes</h2>
            <p>Constants can also be defined within classes and modules. These constants can be accessed using the scope resolution operator (<code>::</code>).</p>
            <SyntaxHighlighterComponent code={classConstantsCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>PI</code> is a constant within the <code>Circle</code> class.</li>
              <li>The <code>area</code> method uses the <code>PI</code> constant to calculate the area of a circle.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_data_types"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_operators"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
