import React, { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { FaCopy } from 'react-icons/fa';
import { nightOwl, coy, nord, prism } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import "../css/betathread.css";
import "../css/my style.css";
import { a11yLight, kimbieLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { darcula } from 'react-syntax-highlighter/dist/cjs/styles/prism';

const AlertComponent = ({ theme }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme === 'dark-theme' ? '#f5f2f0' : '#011627',
        color: theme === 'dark-theme' ? '#011627' : '#f5f2f0',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.5)'
      }}
    >
      Copied to Clipboard! <span style={{ cursor: 'pointer', fontSize: '34px' }}>&times;</span>
    </div>
  );
};

const SyntaxHighlighterComponent = ({ code, language }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [theme, setTheme] = useState('light-theme');

  useEffect(() => {
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      const currentTheme = contentElement.classList.contains('dark-theme') ? 'dark-theme' : 'light-theme';
      setTheme(currentTheme);
    }
  }, []);

  useEffect(() => {
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      const observer = new MutationObserver(() => {
        const currentTheme = contentElement.classList.contains('dark-theme') ? 'dark-theme' : 'light-theme';
        setTheme(currentTheme);
      });

      observer.observe(contentElement, { attributes: true, attributeFilter: ['class'] });

      return () => observer.disconnect();
    }
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000); // Hide the alert after 1 second
  };

  return (
    <>
      {showAlert && <AlertComponent theme={theme} />}
      <div className={theme === 'light-theme' ? 'pre-container' : 'pre-container-light'}>
        <button
          style={{
            backgroundColor: theme === 'dark-theme' ? '#f5f2f0' : '#011627',
            border: 'none',
            outline: 'none',
            float: 'right',
            cursor: 'pointer',
            userSelect: 'none',
            color: theme === 'dark-theme' ? '#000000' : '#f5f2f0', // Icon color based on theme
          }}
          title="Copy content to Clipboard"
          onClick={handleCopy}
        >
          <FaCopy />
        </button>
        <SyntaxHighlighter language={language} style={theme === 'light-theme' ? nightOwl : prism}>
          {code}
        </SyntaxHighlighter>
      </div>
    </>
  );
};

export default SyntaxHighlighterComponent;
