import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPLogical() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    $x = 10;
    $y = 5;
    if ($x > 5 && $y < 8) {
        echo "Both conditions are true";
    } else {
        echo "At least one condition is false";
    }
       `;
    const code2 = `
    $x = 10;
    $y = 5;
    if ($x < 5 || $y > 8) {
        print "At least one condition is true";
    } else {
        print "Both conditions are false";
    }
                `;
    const code3 = `
    $x = true;
    if (!$x) {
        echo "Value of x is false";
    } else {
        echo "Value of x is true";
    }
                `;

   
  
    return (
   <body>
      <Helmet>
        <title>PHP Logical Operators</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Operators, PHP Logical Operator, AND Operator, OR Operator, NOT Operator,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="Logical operators in PHP are used to perform logical operations on expressions. These operators allow you to make decisions based on multiple conditions, and they return either true or false based on the evaluation of these conditions." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_comparison"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_increment"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Logical Operators</h1>
</header>

<Navbar />

<main>
<section>
<p>Logical operators in PHP are used to perform logical operations on expressions. </p>
<p>These operators allow you to make decisions based on multiple conditions, and they return either true or false based on the evaluation of these conditions.</p>
<p>Here are the logical operators in PHP:</p>

<h2>AND (&&)</h2>
<p>The '&&' operator returns true if both operands are 'true'; otherwise, it returns 'false'.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="php" />
<p>In this example, since both $x is greater than 5 and $y is less than 8, the condition evaluates to true, so "Both conditions are true" will be echoed.</p>

<h2>OR (||)</h2>
<p>The '||' operator returns 'true' if at least one of the operands is 'true'; otherwise, it returns 'false'.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="php" />
<p>Here, $x is not less than 5, but $y is greater than 8. Since at least one condition is true, "At least one condition is true" will be printed.</p>


<h2>NOT (!)</h2>
<p>The '!' operator negates the value of its operand. If the operand is 'true', '!' returns 'false'; if the operand is 'false', ! returns 'true'.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code3} language="php" />
<p>In this case, '$x' is 'true', but '!$x' is 'false', so "Value of x is false" will be echoed.</p>

<p>These logical operators are often used in conditional statements ('if', 'elseif', 'else') to control the flow of program execution based on certain conditions.</p>
<p>They are essential for writing dynamic and flexible code that can handle various scenarios and make decisions based on multiple factors.</p>
</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_comparison"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_increment"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}