import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonDictionaryMethods() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const code1 = `
# Adding an item to a dictionary
my_dict = {'name': 'Alice', 'age': 25}
my_dict['city'] = 'New York'
print(my_dict)  # Output: {'name': 'Alice', 'age': 25, 'city': 'New York'}
    `;

    const code2 = `
# Updating an item in a dictionary
my_dict = {'name': 'Alice', 'age': 25}
my_dict['age'] = 26
print(my_dict)  # Output: {'name': 'Alice', 'age': 26}
    `;

    const code3 = `
# Removing an item from a dictionary
my_dict = {'name': 'Alice', 'age': 25}
del my_dict['age']
print(my_dict)  # Output: {'name': 'Alice'}
    `;

    const code4 = `
# Checking if a key exists in a dictionary
my_dict = {'name': 'Alice', 'age': 25}
print('name' in my_dict)  # Output: True
print('city' in my_dict)  # Output: False
    `;

    const code5 = `
# Iterating over a dictionary
my_dict = {'name': 'Alice', 'age': 25}
for key, value in my_dict.items():
    print(f'{key}: {value}')
# Output:
# name: Alice
# age: 25
    `;

    const code6 = `
# Getting a list of keys
my_dict = {'name': 'Alice', 'age': 25}
keys = my_dict.keys()
print(keys)  # Output: dict_keys(['name', 'age'])
    `;

    const code7 = `
# Getting a list of values
my_dict = {'name': 'Alice', 'age': 25}
values = my_dict.values()
print(values)  # Output: dict_values(['Alice', 25])
    `;

    const code8 = `
# Getting a list of key-value pairs
my_dict = {'name': 'Alice', 'age': 25}
items = my_dict.items()
print(items)  # Output: dict_items([('name', 'Alice'), ('age', 25)])
    `;

    return (
        <body>
            <Helmet>
                <title>Python Dictionary Methods</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Dictionary Methods, Add, Update, Remove, Iterate, Keys, Values, Items" />
                <meta name="description" content="Learn about the various methods to manipulate dictionaries in Python, including adding, updating, removing, and iterating over items." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_dictionaries"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_dictionary_comprehensions"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Dictionary Methods</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Dictionaries in Python come with a variety of built-in methods that make it easy to add, update, remove, and manipulate their contents. Understanding these methods will help you work with dictionaries more effectively.</p>
                        
                        <h2>Adding an Item</h2>
                        <p>You can add an item to a dictionary by specifying a new key and assigning it a value.</p>
                        <SyntaxHighlighterComponent code={code1} language="python" />

                        <h2>Updating an Item</h2>
                        <p>Updating an item in a dictionary is similar to adding an item; you simply assign a new value to an existing key.</p>
                        <SyntaxHighlighterComponent code={code2} language="python" />

                        <h2>Removing an Item</h2>
                        <p>To remove an item from a dictionary, use the <code>del</code> statement.</p>
                        <SyntaxHighlighterComponent code={code3} language="python" />

                        <h2>Checking for a Key</h2>
                        <p>To check if a key exists in a dictionary, use the <code>in</code> keyword.</p>
                        <SyntaxHighlighterComponent code={code4} language="python" />

                        <h2>Iterating Over a Dictionary</h2>
                        <p>Use a for loop to iterate over the key-value pairs in a dictionary.</p>
                        <SyntaxHighlighterComponent code={code5} language="python" />

                        <h2>Getting Keys</h2>
                        <p>Use the <code>keys()</code> method to get a list of keys in the dictionary.</p>
                        <SyntaxHighlighterComponent code={code6} language="python" />

                        <h2>Getting Values</h2>
                        <p>Use the <code>values()</code> method to get a list of values in the dictionary.</p>
                        <SyntaxHighlighterComponent code={code7} language="python" />

                        <h2>Getting Items</h2>
                        <p>Use the <code>items()</code> method to get a list of key-value pairs in the dictionary.</p>
                        <SyntaxHighlighterComponent code={code8} language="python" />

                        <h2>Conclusion</h2>
                        <p>By utilizing these dictionary methods, you can effectively manage and manipulate dictionary data in Python.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_dictionaries"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_dictionary_comprehensions"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
