export const subjectData = [
  // Physics
  {
    question: "What is the formula for calculating velocity?",
    answer: "Velocity = Displacement / Time"
  },
  {
    question: "What is Newton's first law of motion?",
    answer: "An object at rest stays at rest and an object in motion stays in motion with the same speed and in the same direction unless acted upon by an unbalanced force."
  },
  {
    question: "What is Newton's second law of motion?",
    answer: "The acceleration of an object is directly proportional to the net force acting on it and inversely proportional to its mass."
  },
  {
    question: "What is the concept of work in physics?",
    answer: "Work is done when a force applied to an object moves that object."
  },
  {
    question: "What is the principle of conservation of energy?",
    answer: "The principle of conservation of energy states that energy cannot be created or destroyed, only transformed from one form to another."
  },
  {
    question: "What are the SI units of force?",
    answer: "The SI unit of force is the newton (N)."
  },
  {
    question: "Explain Hooke's Law.",
    answer: "Hooke's Law states that the force needed to extend or compress a spring by some distance is proportional to that distance."
  },
  {
    question: "What is the difference between speed and velocity?",
    answer: "Speed is a scalar quantity that measures the rate at which an object covers distance, whereas velocity is a vector quantity that measures the rate at which an object changes its position."
  },
  // Chemistry
  {
    question: "What is the atomic number of carbon?",
    answer: "The atomic number of carbon is 6."
  },
  {
    question: "Define an element in chemistry.",
    answer: "An element is a substance made up of atoms with the same atomic number; that is, all atoms in an element have the same number of protons in their nuclei."
  },
  {
    question: "What is a chemical bond?",
    answer: "A chemical bond is a lasting attraction between atoms that enables the formation of chemical compounds."
  },
  {
    question: "What is the Law of Conservation of Mass?",
    answer: "The Law of Conservation of Mass states that in a chemical reaction, matter is neither created nor destroyed."
  },
  {
    question: "What is the difference between an acid and a base?",
    answer: "Acids release hydrogen ions when dissolved in water, whereas bases release hydroxide ions."
  },
  {
    question: "What is Avogadro's number?",
    answer: "Avogadro's number is 6.022 x 10^23, representing the number of atoms, ions, or molecules in one mole of a substance."
  },
  {
    question: "What is a chemical reaction?",
    answer: "A chemical reaction is a process that leads to the transformation of one set of chemical substances to another."
  },
  {
    question: "Define oxidation and reduction in chemistry.",
    answer: "Oxidation is the loss of electrons by a molecule, atom, or ion, while reduction is the gain of electrons by a molecule, atom, or ion."
  },
  // Biology
  {
    question: "What is the function of the mitochondria?",
    answer: "The mitochondria are known as the powerhouse of the cell. They generate energy (ATP) for the cell through cellular respiration."
  },
  {
    question: "What is photosynthesis?",
    answer: "Photosynthesis is the process by which green plants and some other organisms use sunlight to synthesize foods with the help of chlorophyll, carbon dioxide, and water."
  },
  {
    question: "What is the difference between mitosis and meiosis?",
    answer: "Mitosis is a type of cell division that results in two daughter cells each having the same number and kind of chromosomes as the parent nucleus, while meiosis is a type of cell division that results in four daughter cells, each with half the number of chromosomes of the parent cell."
  },
  {
    question: "What is the function of DNA?",
    answer: "DNA contains the instructions needed for an organism to develop, survive, and reproduce. It carries genetic information and codes for the synthesis of proteins."
  },
  {
    question: "What is the role of enzymes in biological systems?",
    answer: "Enzymes are biological catalysts that speed up chemical reactions in living organisms without being consumed in the process."
  },
  {
    question: "What is the respiratory system responsible for?",
    answer: "The respiratory system is responsible for the exchange of gases (oxygen and carbon dioxide) between an organism and its environment."
  },
  {
    question: "What is an ecosystem?",
    answer: "An ecosystem is a community of living organisms (plants, animals, and microbes) together with the nonliving components of their environment (such as air, water, and mineral soil), interacting as a system."
  },
  {
    question: "Explain the process of evolution.",
    answer: "Evolution is the process by which populations of organisms change over successive generations through the inheritance of traits, with variations that increase the organism's chances of survival and reproduction becoming more common."
  },
  // Mathematics
  {
    question: "Solve for x: 2x + 5 = 15",
    answer: "x = 5"
  },
  {
    question: "What is the Pythagorean theorem?",
    answer: "The Pythagorean theorem states that in a right-angled triangle, the square of the length of the hypotenuse (the side opposite the right angle) is equal to the sum of the squares of the lengths of the other two sides."
  },
  {
    question: "What is calculus?",
    answer: "Calculus is a branch of mathematics that deals with rates of change and accumulation of quantities. It has two main branches: differential calculus and integral calculus."
  },
  {
    question: "Define a logarithm.",
    answer: "A logarithm is the inverse operation to exponentiation. It is used to solve equations involving exponential functions."
  },
  {
    question: "What is the difference between permutations and combinations?",
    answer: "Permutations are arrangements of objects in a specific order, while combinations are selections of objects without considering the order."
  },
  {
    question: "What are the properties of a parallelogram?",
    answer: "The properties of a parallelogram include opposite sides being equal and parallel, opposite angles being equal, and consecutive angles being supplementary."
  },
  {
    question: "What is the value of π (pi)?",
    answer: "The value of π (pi) is approximately 3.14159."
  },
  {
    question: "What is the Fibonacci sequence?",
    answer: "The Fibonacci sequence is a series of numbers in which each number is the sum of the two preceding ones, usually starting with 0 and 1."
  },
  // English
  {
    question: "What is a simile?",
    answer: "A simile is a figure of speech that directly compares two different things using the words 'like' or 'as'."
  },
  {
    question: "Define protagonist.",
    answer: "The protagonist is the main character or leading figure in a story, novel, or any other narrative."
  },
  {
    question: "What is the purpose of foreshadowing in literature?",
    answer: "Foreshadowing is a literary device used to give a hint or indication of events that will occur later in a story."
  },
  {
    question: "What is the difference between a metaphor and a simile?",
    answer: "A metaphor is a figure of speech that directly refers to one thing by mentioning another, while a simile makes a comparison between two different things using 'like' or 'as'."
  },
  {
    question: "Define alliteration.",
    answer: "Alliteration is the repetition of the same consonant sounds at the beginning of adjacent or closely connected words."
  },
  {
    question: "What is the theme of a literary work?",
    answer: "The theme of a literary work is the central idea or message that the author wants to convey to the reader."
  },
  {
    question: "What is dramatic irony?",
    answer: "Dramatic irony occurs when the audience or reader knows something that the characters in the story do not."
  },
  {
    question: "What is a sonnet?",
    answer: "A sonnet is a poem consisting of 14 lines, typically in iambic pentameter, with a specific rhyme scheme."
  },
  // Art
  {
    question: "Who painted the Mona Lisa?",
    answer: "Leonardo da Vinci painted the Mona Lisa."
  },
  {
    question: "What is pointillism?",
    answer: "Pointillism is a technique of painting in which small, distinct dots of color are applied in patterns to form an image."
  },
  {
    question: "What is surrealism?",
    answer: "Surrealism is a cultural movement that began in the early 1920s, characterized by artworks that feature unexpected juxtapositions and non sequitur elements."
  },
  {
    question: "Define chiaroscuro.",
    answer: "Chiaroscuro is a technique used in visual arts, particularly painting, to create contrasts between light and dark to give the illusion of three-dimensional volume."
  },
  {
    question: "Who sculpted 'David'?",
    answer: "Michelangelo sculpted 'David'."
  },
  {
    question: "What is abstract art?",
    answer: "Abstract art is art that does not attempt to represent an accurate depiction of visual reality, but instead uses shapes, colors, forms, and gestural marks to achieve its effect."
  },
  {
    question: "What is the Renaissance?",
    answer: "The Renaissance was a period of European cultural, artistic, political, and economic rebirth following the Middle Ages, characterized by renewed interest in classical art, literature, and learning."
  },
  {
    question: "What is the purpose of art criticism?",
    answer: "Art criticism is the analysis, evaluation, interpretation, and judgment of works of art. Its purpose is to understand and appreciate art and its cultural significance."
  },
  // Economics
  {
    question: "What is inflation?",
    answer: "Inflation is the rate at which the general level of prices for goods and services is rising, leading to a decrease in the purchasing power of money."
  },
  {
    question: "Define supply and demand.",
    answer: "Supply refers to the quantity of a good or service that producers are willing to offer for sale at a given price, while demand refers to the quantity of a good or service that consumers are willing and able to buy at a given price."
  },
  {
    question: "What is a monopoly?",
    answer: "A monopoly is a situation in which a single company or group owns all or nearly all of the market for a given type of product or service."
  },
  {
    question: "What is GDP?",
    answer: "Gross Domestic Product (GDP) is the total value of all goods and services produced within a country's borders in a specific period of time, usually a year or a quarter."
  },
  {
    question: "Explain the law of diminishing marginal utility.",
    answer: "The law of diminishing marginal utility states that as a person consumes more units of a good or service, the additional satisfaction or utility derived from each additional unit decreases."
  },
  {
    question: "What is fiscal policy?",
    answer: "Fiscal policy is the use of government spending and taxation to influence the economy."
  },
  {
    question: "What is a stock market?",
    answer: "A stock market is a place where stocks, bonds, and other securities are bought and sold."
  },
  {
    question: "What is microeconomics?",
    answer: "Microeconomics is the branch of economics that studies the behavior of individuals and firms in making decisions regarding the allocation of limited resources."
  },
  // Other Subjects
  {
    question: "What is psychology?",
    answer: "Psychology is the scientific study of the mind and behavior."
  },
  {
    question: "Who wrote 'To Kill a Mockingbird'?",
    answer: "Harper Lee wrote 'To Kill a Mockingbird'."
  },
  {
    question: "What is the difference between weather and climate?",
    answer: "Weather refers to short-term atmospheric conditions in a specific place at a specific time, while climate refers to long-term patterns of temperature, precipitation, and other weather variables in a particular region."
  },
  {
    question: "What is the greenhouse effect?",
    answer: "The greenhouse effect is the process by which the Earth's atmosphere traps heat from the sun, leading to an increase in the Earth's surface temperature."
  },
  {
    question: "Who discovered penicillin?",
    answer: "Alexander Fleming discovered penicillin."
  },
  {
    question: "What is the function of the nervous system?",
    answer: "The nervous system is responsible for transmitting signals between different parts of the body and coordinating the body's responses to internal and external stimuli."
  },
  {
    question: "What is geology?",
    answer: "Geology is the study of the Earth's structure, composition, and processes, including the study of rocks, minerals, and fossils."
  },
  {
    question: "What is the function of the kidneys?",
    answer: "The kidneys filter waste products and excess substances from the blood to form urine, which is then excreted from the body."
  },
  // More Science Questions
  {
    question: "What is the Law of Universal Gravitation?",
    answer: "The Law of Universal Gravitation states that every point mass attracts every other point mass in the universe with a force that is directly proportional to the product of their masses and inversely proportional to the square of the distance between their centers."
  },
  {
    question: "What is the electromagnetic spectrum?",
    answer: "The electromagnetic spectrum is the range of all types of electromagnetic radiation, from the longest wavelengths (radio waves) to the shortest wavelengths (gamma rays)."
  },
  {
    question: "Explain the process of nuclear fusion?.",
    answer: "Nuclear fusion is the process by which two light atomic nuclei combine to form a heavier nucleus, releasing a large amount of energy in the process."
  },
  {
    question: "What is the structure of an atom?",
    answer: "An atom consists of a nucleus containing protons and neutrons, surrounded by electrons in orbitals."
  },
  {
    question: "What is the difference between a compound and a mixture?",
    answer: "A compound is a substance made up of two or more different elements chemically bonded together, while a mixture is a combination of two or more substances that are not chemically combined."
  },
  {
    question: "What is the role of the ozone layer?",
    answer: "The ozone layer protects the Earth from the harmful effects of ultraviolet radiation from the sun."
  },
  {
    question: "What is Boyle's Law?",
    answer: "Boyle's Law states that at constant temperature, the volume of a given mass of gas is inversely proportional to its pressure."
  },
  {
    question: "Explain the process of cellular respiration.",
    answer: "Cellular respiration is the process by which cells break down glucose and other organic molecules to produce ATP, the molecule used by cells for energy."
  },
  // More Mathematics Questions
  {
    question: "What is the difference between a median and a mode?",
    answer: "The median of a set of numbers is the middle value when the numbers are arranged in ascending order, while the mode is the number that appears most frequently."
  },
  {
    question: "What is the fundamental theorem of calculus?",
    answer: "The fundamental theorem of calculus states that differentiation and integration are inverse operations: if a function is first integrated and then differentiated, the result is the original function (up to a constant)."
  },
  {
    question: "Define a quadratic equation.",
    answer: "A quadratic equation is a polynomial equation of the second degree, meaning it contains at least one term that is squared."
  },
  {
    question: "What is the law of sines?",
    answer: "The law of sines relates the sides of a triangle to the sines of its angles: a / sin(A) = b / sin(B) = c / sin(C), where a, b, and c are the lengths of the sides, and A, B, and C are the measures of the angles opposite those sides."
  },
  {
    question: "What is the purpose of statistical analysis?",
    answer: "Statistical analysis is used to interpret data, identify trends and relationships, and make predictions based on probability."
  },
  {
    question: "What is the Fibonacci sequence?",
    answer: "The Fibonacci sequence is a series of numbers in which each number is the sum of the two preceding ones, usually starting with 0 and 1."
  },
  {
    question: "What is the difference between a fraction and a decimal?",
    answer: "A fraction is a numerical quantity that represents a part of a whole, expressed as one number divided by another, while a decimal is a number expressed in the base-10 numbering system using digits and a decimal point."
  },
  {
    question: "What is the mean of a set of numbers?",
    answer: "The mean of a set of numbers is the sum of all the numbers divided by the count of numbers in the set."
  },
  // More English Questions
  {
    question: "What is the purpose of a thesis statement?",
    answer: "A thesis statement presents the main point or argument of an essay or other type of academic writing, and it provides direction for the reader in terms of what to expect from the rest of the work."
  },
  {
    question: "What is dramatic irony?",
    answer: "Dramatic irony occurs when the audience or reader knows something that the characters in the story do not."
  },
  {
    question: "What is the difference between a protagonist and an antagonist?",
    answer: "The protagonist is the main character or leading figure in a story, while the antagonist is the character or force that opposes the protagonist."
  },
  {
    question: "Define symbolism in literature.",
    answer: "Symbolism is the use of symbols to represent ideas or qualities, often giving deeper meaning to objects, characters, or events in a literary work."
  },
  {
    question: "What is the purpose of imagery in poetry?",
    answer: "Imagery in poetry helps to create vivid mental images for the reader by appealing to the senses of sight, sound, taste, touch, and smell."
  },
  {
    question: "What is the difference between a metaphor and a simile?",
    answer: "A metaphor is a figure of speech that directly refers to one thing by mentioning another, while a simile makes a comparison between two different things using 'like' or 'as'."
  },
  {
    question: "What is the climax of a story?",
    answer: "The climax is the point of highest tension or drama in a narrative, often the turning point at which the main character faces a critical decision or confrontation."
  },
  {
    question: "What is the purpose of dialogue in a play?",
    answer: "Dialogue in a play is used to develop characters, advance the plot, and convey information or themes to the audience."
  },
  // More Art Questions
  {
    question: "What is abstract art?",
    answer: "Abstract art is art that does not attempt to represent an accurate depiction of visual reality, but instead uses shapes, colors, forms, and gestural marks to achieve its effect."
  },
  {
    question: "Who painted 'Starry Night'?",
    answer: "Vincent van Gogh painted 'Starry Night'."
  },
  {
    question: "What is the purpose of chiaroscuro?",
    answer: "Chiaroscuro is a technique used in visual arts, particularly painting, to create contrasts between light and dark to give the illusion of three-dimensional volume."
  },
  {
    question: "Who designed the Eiffel Tower?",
    answer: "Gustave Eiffel designed the Eiffel Tower."
  },
  {
    question: "What is the Renaissance?",
    answer: "The Renaissance was a period of European cultural, artistic, political, and economic rebirth following the Middle Ages, characterized by renewed interest in classical art, literature, and learning."
  },
  {
    question: "What is the purpose of art criticism?",
    answer: "Art criticism is the analysis, evaluation, interpretation, and judgment of works of art. Its purpose is to understand and appreciate art and its cultural significance."
  },
  {
    question: "What is the significance of the Mona Lisa?",
    answer: "The Mona Lisa is one of the most famous and iconic works of art in the world, known for its enigmatic smile and masterful technique. It was painted by Leonardo da Vinci during the Italian Renaissance."
  },
  {
    question: "What is pointillism?",
    answer: "Pointillism is a technique of painting in which small, distinct dots of color are applied in patterns to form an image."
  },
  // More Economics Questions
  {
    question: "What is globalization?",
    answer: "Globalization is the process of increased interconnectedness and interdependence among countries, economies, and cultures around the world."
  },
  {
    question: "What is the difference between a recession and a depression?",
    answer: "A recession is a period of economic decline characterized by a decrease in GDP and rising unemployment, while a depression is a severe and prolonged downturn in economic activity, often marked by widespread unemployment, deflation, and financial crises."
  },
  {
    question: "What is a free market economy?",
    answer: "A free market economy is an economic system in which prices for goods and services are determined by the open market and consumers, with little or no government intervention."
  },
  {
    question: "What is the role of the Federal Reserve?",
    answer: "The Federal Reserve, often referred to as the Fed, is the central banking system of the United States. Its main functions include conducting monetary policy, supervising and regulating banks, and providing financial services to depository institutions, the U.S. government, and foreign official institutions."
  },
  {
    question: "What is the concept of scarcity?",
    answer: "Scarcity is the fundamental economic problem of having seemingly unlimited human wants and needs in a world of limited resources."
  },
  {
    question: "What is the difference between a tariff and a quota?",
    answer: "A tariff is a tax imposed on imported goods and services, while a quota is a restriction on the quantity of a particular good that can be imported or exported."
  },
  {
    question: "What is the Gross National Product (GNP)?",
    answer: "Gross National Product (GNP) is the total value of all goods and services produced by a country's residents, regardless of where the production takes place, in a specific period of time, usually a year or a quarter."
  },
  {
    question: "What is a budget deficit?",
    answer: "A budget deficit occurs when a government spends more money than it receives in revenue during a specific period of time, typically a fiscal year."
  },
  // More Miscellaneous Questions
  {
    question: "What is psychology?",
    answer: "Psychology is the scientific study of the mind and behavior."
  },
  {
    question: "Who wrote 'To Kill a Mockingbird'?",
    answer: "Harper Lee wrote 'To Kill a Mockingbird'."
  },
  {
    question: "What is the difference between weather and climate?",
    answer: "Weather refers to short-term atmospheric conditions in a specific place at a specific time, while climate refers to long-term patterns of temperature, precipitation, and other weather variables in a particular region."
  },
  {
    question: "What is the greenhouse effect?",
    answer: "The greenhouse effect is the process by which the Earth's atmosphere traps heat from the sun, leading to an increase in the Earth's surface temperature."
  },
  {
    question: "Who discovered penicillin?",
    answer: "Alexander Fleming discovered penicillin."
  },
  {
    question: "What is the function of the nervous system?",
    answer: "The nervous system is responsible for transmitting signals between different parts of the body and coordinating the body's responses to internal and external stimuli."
  },
  {
    question: "What is geology?",
    answer: "Geology is the study of the Earth's structure, composition, and processes, including the study of rocks, minerals, and fossils."
  },
  {
    question: "What is the function of the kidneys?",
    answer: "The kidneys filter waste products and excess substances from the blood to form urine, which is then excreted from the body."
  },
  {
    question: "What is the chemical formula for water?",
    answer: "The chemical formula for water is H2O."
  },
  {
    question: "Who is the author of 'To Kill a Mockingbird'?",
    answer: "The author of 'To Kill a Mockingbird' is Harper Lee."
  },
  {
    question: "What is the capital of France?",
    answer: "The capital of France is Paris."
  },
  {
    question: "Who discovered the theory of relativity?",
    answer: "The theory of relativity was discovered by Albert Einstein."
  },
  {
    question: "What is the formula to calculate the area of a rectangle?",
    answer: "The formula to calculate the area of a rectangle is length multiplied by width."
  },
  {
    question: "Who painted the Mona Lisa?",
    answer: "The Mona Lisa was painted by Leonardo da Vinci."
  },
  {
    question: "What is the powerhouse of the cell?",
    answer: "The powerhouse of the cell is the mitochondria."
  },
  {
    question: "What is the chemical symbol for gold?",
    answer: "The chemical symbol for gold is Au."
  },
  {
    question: "Who wrote 'Hamlet'?",
    answer: "'Hamlet' was written by William Shakespeare."
  },
  {
    question: "What is the formula for Force?",
    answer: "The formula for Force is F = ma (force equals mass times acceleration)."
  },
  {
    question: "What is the capital of Japan?",
    answer: "The capital of Japan is Tokyo."
  },
  {
    question: "Who wrote 'The Great Gatsby'?",
    answer: "'The Great Gatsby' was written by F. Scott Fitzgerald."
  },
  {
    question: "What is the chemical symbol for oxygen?",
    answer: "The chemical symbol for oxygen is O."
  },
  {
    question: "What is the formula for the area of a circle?",
    answer: "The formula for the area of a circle is πr^2, where r is the radius."
  },
  {
    question: "Who discovered penicillin?",
    answer: "Penicillin was discovered by Alexander Fleming."
  },
  {
    question: "What is the capital of Australia?",
    answer: "The capital of Australia is Canberra."
  },
  {
    question: "What is the atomic number of carbon?",
    answer: "The atomic number of carbon is 6."
  },
  {
    question: "Who painted 'Starry Night'?",
    answer: "'Starry Night' was painted by Vincent van Gogh."
  },
  {
    question: "What is the chemical formula for carbon dioxide?",
    answer: "The chemical formula for carbon dioxide is CO2."
  },
  {
    question: "What is the capital of Brazil?",
    answer: "The capital of Brazil is Brasília."
  },
  {
    question: "Who developed the theory of evolution by natural selection?",
    answer: "The theory of evolution by natural selection was developed by Charles Darwin."
  },
  {
    question: "What is the formula for the Pythagorean theorem?",
    answer: "The formula for the Pythagorean theorem is a^2 + b^2 = c^2, where 'a' and 'b' are the lengths of the two shorter sides of a right triangle and 'c' is the length of the hypotenuse."
  },
  {
    question: "Who composed the 'Moonlight Sonata'?",
    answer: "The 'Moonlight Sonata' was composed by Ludwig van Beethoven."
  },
  {
    question: "What is the chemical symbol for sodium?",
    answer: "The chemical symbol for sodium is Na."
  },
  {
    question: "What is the formula for the circumference of a circle?",
    answer: "The formula for the circumference of a circle is 2πr, where 'r' is the radius."
  },
  {
    question: "Who wrote '1984'?",
    answer: "'1984' was written by George Orwell."
  },
  {
    question: "What is the capital of Russia?",
    answer: "The capital of Russia is Moscow."
  },
  {
    question: "What is the chemical formula for methane?",
    answer: "The chemical formula for methane is CH4."
  },
  {
    question: "Who painted 'The Last Supper'?",
    answer: "'The Last Supper' was painted by Leonardo da Vinci."
  },
  {
    question: "What is the chemical symbol for iron?",
    answer: "The chemical symbol for iron is Fe."
  },
  {
    question: "Who formulated the theory of general relativity?",
    answer: "The theory of general relativity was formulated by Albert Einstein."
  },
  {
    question: "What is the chemical formula for table salt?",
    answer: "The chemical formula for table salt is NaCl (sodium chloride)."
  },
  {
    question: "Who painted the ceiling of the Sistine Chapel?",
    answer: "The ceiling of the Sistine Chapel was painted by Michelangelo."
  },
  {
    question: "What is the capital of China?",
    answer: "The capital of China is Beijing."
  },
  {
    question: "What is the formula for the volume of a sphere?",
    answer: "The formula for the volume of a sphere is (4/3)πr^3, where 'r' is the radius."
  },
  {
    question: "Who wrote 'Pride and Prejudice'?",
    answer: "'Pride and Prejudice' was written by Jane Austen."
  },
  {
    question: "What is the chemical symbol for hydrogen?",
    answer: "The chemical symbol for hydrogen is H."
  },
  {
    question: "What is the capital of India?",
    answer: "The capital of India is New Delhi."
  },
  {
    question: "What is the formula for the area of a triangle?",
    answer: "The formula for the area of a triangle is (1/2)base × height."
  },
  {
    question: "Who discovered the laws of motion?",
    answer: "The laws of motion were discovered by Sir Isaac Newton."
  },
  {
    question: "What is the capital of South Africa?",
    answer: "The capital of South Africa is Pretoria (executive), Bloemfontein (judicial), and Cape Town (legislative)."
  },
  {
    question: "Who discovered the electron?",
    answer: "The electron was discovered by J.J. Thomson."
  },
  {
    question: "Who painted 'The Starry Night'?",
    answer: "'The Starry Night' was painted by Vincent van Gogh."
  },
  {
    question: "What is the chemical formula for sulfuric acid?",
    answer: "The chemical formula for sulfuric acid is H2SO4."
  },
  {
    question: "What is the capital of Argentina?",
    answer: "The capital of Argentina is Buenos Aires."
  },
  {
    question: "What is the formula for the area of a trapezoid?",
    answer: "The formula for the area of a trapezoid is ((b1 + b2) / 2) × h, where 'b1' and 'b2' are the lengths of the two parallel bases and 'h' is the height."
  },
  {
    question: "Who wrote 'The Catcher in the Rye'?",
    answer: "'The Catcher in the Rye' was written by J.D. Salinger."
  },
  {
    question: "What is the chemical symbol for silver?",
    answer: "The chemical symbol for silver is Ag."
  },
  {
    question: "What is the capital of Egypt?",
    answer: "The capital of Egypt is Cairo."
  },
  {
    question: "What is the chemical formula for ammonia?",
    answer: "The chemical formula for ammonia is NH3."
  },
  {
    question: "Who is credited with the discovery of the structure of DNA?",
    answer: "The discovery of the structure of DNA is credited to James Watson and Francis Crick, with contributions from Rosalind Franklin and Maurice Wilkins."
  },
  {
    question: "What is the chemical formula for carbon monoxide?",
    answer: "The chemical formula for carbon monoxide is CO."
  },
  {
    question: "Who painted 'Guernica'?",
    answer: "'Guernica' was painted by Pablo Picasso."
  },
  {
    question: "What is the capital of Canada?",
    answer: "The capital of Canada is Ottawa."
  },
  {
    question: "What is the formula for the area of a parallelogram?",
    answer: "The formula for the area of a parallelogram is base × height."
  },
  {
    question: "Who wrote 'The Lord of the Rings'?",
    answer: "'The Lord of the Rings' was written by J.R.R. Tolkien."
  },
  {
    question: "What is the chemical symbol for potassium?",
    answer: "The chemical symbol for potassium is K."
  },
  {
    question: "What is the capital of Germany?",
    answer: "The capital of Germany is Berlin."
  },
  {
    question: "What is the chemical formula for carbonic acid?",
    answer: "The chemical formula for carbonic acid is H2CO3."
  },
  {
    question: "Who composed 'Symphony No. 9'?",
    answer: "'Symphony No. 9' was composed by Ludwig van Beethoven."
  },
  {
    question: "Who proposed the theory of special relativity?",
    answer: "The theory of special relativity was proposed by Albert Einstein."
  },
  {
    question: "What is the chemical formula for hydrochloric acid?",
    answer: "The chemical formula for hydrochloric acid is HCl."
  },
  {
    question: "Who painted 'The Persistence of Memory'?",
    answer: "'The Persistence of Memory' was painted by Salvador Dalí."
  },
  {
    question: "What is the capital of Italy?",
    answer: "The capital of Italy is Rome."
  },
  {
    question: "What is the formula for the volume of a cylinder?",
    answer: "The formula for the volume of a cylinder is πr^2h, where 'r' is the radius and 'h' is the height."
  },
  {
    question: "Who wrote 'The Odyssey'?",
    answer: "'The Odyssey' was written by Homer."
  },
  {
    question: "What is the chemical symbol for calcium?",
    answer: "The chemical symbol for calcium is Ca."
  },
  {
    question: "What is the capital of Spain?",
    answer: "The capital of spain is Madrid",
  }
];
