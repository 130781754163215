import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIDeepLearning() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const cnnCode = `
import tensorflow as tf
from tensorflow.keras import layers, models

model = models.Sequential()
model.add(layers.Conv2D(32, (3, 3), activation='relu', input_shape=(28, 28, 1)))
model.add(layers.MaxPooling2D((2, 2)))
model.add(layers.Conv2D(64, (3, 3), activation='relu'))
model.add(layers.MaxPooling2D((2, 2)))
model.add(layers.Conv2D(64, (3, 3), activation='relu'))

model.add(layers.Flatten())
model.add(layers.Dense(64, activation='relu'))
model.add(layers.Dense(10, activation='softmax'))

model.compile(optimizer='adam', loss='sparse_categorical_crossentropy', metrics=['accuracy'])
  `;

  const rnnCode = `
import tensorflow as tf
from tensorflow.keras import layers, models

model = models.Sequential()
model.add(layers.Embedding(input_dim=10000, output_dim=64))
model.add(layers.SimpleRNN(128))
model.add(layers.Dense(10, activation='softmax'))

model.compile(optimizer='adam', loss='sparse_categorical_crossentropy', metrics=['accuracy'])
  `;

  const lstmCode = `
import tensorflow as tf
from tensorflow.keras import layers, models

model = models.Sequential()
model.add(layers.Embedding(input_dim=10000, output_dim=64))
model.add(layers.LSTM(128))
model.add(layers.Dense(10, activation='softmax'))

model.compile(optimizer='adam', loss='sparse_categorical_crossentropy', metrics=['accuracy'])
  `;

  return (
    <body>
      <Helmet>
        <title>AI Deep Learning</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Deep Learning, AI Tutorial, CNN, RNN, LSTM, Neural Network Implementation" />
        <meta name="description" content="Learn about Deep Learning in AI, including Convolutional Neural Networks (CNNs), Recurrent Neural Networks (RNNs), and Long Short-Term Memory Networks (LSTMs) with practical examples." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_neural_networks"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_cnns"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>AI Deep Learning</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Deep learning is a subset of machine learning where neural networks with three or more layers are used. These neural networks attempt to simulate the behavior of the human brain to "learn" from large amounts of data.</p>

            <h2>Convolutional Neural Networks (CNNs)</h2>
            <p>CNNs are a class of deep neural networks that are most commonly applied to analyzing visual imagery. They are designed to automatically and adaptively learn spatial hierarchies of features from input images.</p>
            <SyntaxHighlighterComponent code={cnnCode} language="python" />
            <p>In this example, we define a simple CNN using TensorFlow and Keras. The model consists of several convolutional layers, pooling layers, and dense layers.</p>

            <h2>Recurrent Neural Networks (RNNs)</h2>
            <p>RNNs are a class of neural networks that is powerful for modeling sequence data such as time series or natural language. They use their internal state (memory) to process sequences of inputs.</p>
            <SyntaxHighlighterComponent code={rnnCode} language="python" />
            <p>In this example, we define a simple RNN using TensorFlow and Keras. The model includes an embedding layer and a SimpleRNN layer followed by a dense output layer.</p>

            <h2>Long Short-Term Memory Networks (LSTMs)</h2>
            <p>LSTMs are a special kind of RNN, capable of learning long-term dependencies. They were introduced by Hochreiter & Schmidhuber (1997), and were refined and popularized by many people in subsequent work.</p>
            <SyntaxHighlighterComponent code={lstmCode} language="python" />
            <p>This code defines a simple LSTM using TensorFlow and Keras. The model includes an embedding layer and an LSTM layer followed by a dense output layer.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_neural_networks"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_cnns"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
