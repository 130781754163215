import React from "react";
import "../../../css/my style.css";
import "../../../css/my style2.css";
import { Helmet } from "react-helmet";
import GoBack from "../../../components/Gobackbutton";
import { Link } from "react-router-dom";

export default function JSoutput30() {
    function myFunc() {
        var x = 17;
        var y = 10;
        
        var addition = x + y;         // 27
        var subtraction = x - y;      // 7
        var multiplication = x * y;   // 170
        var division = x / y;         // 1.7
        var remainder = x % y;        // 7
        var exponentiation = x ** y;  // 2,015,993,900,449        
        document.getElementById("demo1").innerHTML = addition;
        document.getElementById("demo2").innerHTML = subtraction;
        document.getElementById("demo3").innerHTML = multiplication;
        document.getElementById("demo4").innerHTML = division;
        document.getElementById("demo5").innerHTML = remainder;
        document.getElementById("demo6").innerHTML = exponentiation;
           }
    return (
    <div>
    <Helmet>
     <title>Beta Thread Try it out Editor</title>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="HTML, Python, CSS, SQL, JavaScript, How to, PHP, Java, C, C++, C#, jQuery, Bootstrap, Colors, W3.CSS, XML, MySQL, Icons, NodeJS, React, Graphics, Angular, R, AI, Git, Data Science, Code Game, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, References, Examples, Learn to code, Source code, Demos, Tips, Website, Coding tutorials and guides Best coding courses,Front-end and back-end development,Step-by-step Python programming tutorials Java coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
    C++ programming tips for real-world applications," />
   <meta name="description" content="Beta Thread try it out editor for HTML,CSS,JavaScript with syntax highlighting,a button to change the theme and other features" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
    <p id="demo1"></p>
    <p id="demo2"></p>
    <p id="demo3"></p>
    <p id="demo4"></p>
    <p id="demo5"></p>
    <p id="demo6"></p>
    <button onClick={myFunc}>Click me!</button>
<br /><br /><br /><br />
<Link to="/try_js30"><GoBack /></Link>

</div>

)
}