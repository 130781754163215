import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonTupleConcatenation() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const code = `
# Tuple Concatenation
tuple1 = (1, 2, 3)
tuple2 = ('a', 'b', 'c')
concatenated_tuple = tuple1 + tuple2
print(concatenated_tuple)  # Output: (1, 2, 3, 'a', 'b', 'c')
    `;

    return (
        <body>
            <Helmet>
                <title>Tuple Concatenation</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Tuple Concatenation, Concatenating Tuples" />
                <meta name="description" content="Learn how to concatenate tuples in Python to combine multiple tuples into a single tuple." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_tuple_unpacking"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_sets"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Tuple Concatenation</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Tuple concatenation allows you to combine multiple tuples into a single tuple by adding their elements together.</p>
                        
                        <h2>Example</h2>
                        <p>Let's see an example of tuple concatenation:</p>
                        <SyntaxHighlighterComponent code={code} language="python" />
                        
                        <p>In this example, <code>tuple1</code> contains the elements <code>(1, 2, 3)</code>, and <code>tuple2</code> contains the elements <code>('a', 'b', 'c')</code>. By concatenating them using the <code>+</code> operator, we create a new tuple <code>concatenated_tuple</code> containing all the elements of both tuples.</p>
                        
                        <h2>Use Cases</h2>
                        <p>Tuple concatenation is useful when you need to combine the contents of multiple tuples into a single tuple, such as when merging data from different sources or creating composite data structures.</p>
                        
                        <h2>Conclusion</h2>
                        <p>Understanding tuple concatenation allows you to efficiently combine tuples in Python, enabling you to work with composite data structures and perform various operations on tuples.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_tuple_unpacking"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_sets"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
