export const asiaData = [
    {
      question: "What is the longest river in Asia?",
      answer: "The longest river in Asia is the Yangtze River."
    },
    {
      question: "Who was the first President of Indonesia?",
      answer: "Sukarno was the first President of Indonesia."
    },
    {
      question: "Which Asian country is known as the 'Land of Smiles'?",
      answer: "Thailand is known as the 'Land of Smiles'."
    },
    {
      question: "What is the capital city of Vietnam?",
      answer: "The capital city of Vietnam is Hanoi."
    },
    {
      question: "Which Asian country is famous for its tea ceremonies and cherry blossoms?",
      answer: "Japan is famous for its tea ceremonies and cherry blossoms."
    },
    {
      question: "What is the largest mountain range in Asia?",
      answer: "The largest mountain range in Asia is the Himalayas."
    },
    {
      question: "Which country in Asia has the largest population?",
      answer: "China has the largest population in Asia."
    },
    {
      question: "Who is considered the 'Father of Modern Turkey'?",
      answer: "Mustafa Kemal Atatürk is considered the 'Father of Modern Turkey'."
    },
    {
      question: "What is the official language of Iran?",
      answer: "The official language of Iran is Persian."
    },
    {
      question: "Which Asian country is located on the island of Borneo and is known for its orangutans?",
      answer: "Indonesia is located on the island of Borneo and is known for its orangutans."
    },
    {
      question: "What is the currency of the United Arab Emirates?",
      answer: "The currency of the United Arab Emirates is the UAE dirham."
    },
    {
      question: "Which Asian country is known for the historical city of Petra?",
      answer: "Jordan is known for the historical city of Petra."
    },
    {
      question: "Who is the current President of the Philippines?",
      answer: "As of 2024, the current President of the Philippines is Rodrigo Duterte."
    },
    {
      question: "What is the highest peak in Asia?",
      answer: "The highest peak in Asia is Mount Everest."
    },
    {
      question: "Which Asian country is famous for its iconic dish, sushi?",
      answer: "Japan is famous for its iconic dish, sushi."
    },
    {
      question: "What is the official language of Pakistan?",
      answer: "The official language of Pakistan is Urdu."
    },
    {
      question: "Which Asian country is known for its ancient ruins of Persepolis?",
      answer: "Iran is known for its ancient ruins of Persepolis."
    },
    {
      question: "Who is the current Prime Minister of India?",
      answer: "As of 2024, the current Prime Minister of India is Narendra Modi."
    },
    {
      question: "What is the capital city of Thailand?",
      answer: "The capital city of Thailand is Bangkok."
    },
    {
      question: "Which Asian country is famous for its terracotta army?",
      answer: "China is famous for its terracotta army."
    },
    {
      question: "What is the official language of Bangladesh?",
      answer: "The official language of Bangladesh is Bengali."
    },
    {
      question: "Which Asian country is known as the 'Land of the Thunder Dragon'?",
      answer: "Bhutan is known as the 'Land of the Thunder Dragon'."
    },
    {
      question: "Who is the current President of Russia?",
      answer: "As of 2024, the current President of Russia is Vladimir Putin."
    },
    {
      question: "What is the largest island in Asia?",
      answer: "The largest island in Asia is Borneo."
    },
    {
      question: "Which Asian country is famous for its Taj Mahal?",
      answer: "India is famous for its Taj Mahal."
    },
    {
      question: "What is the capital city of Saudi Arabia?",
      answer: "The capital city of Saudi Arabia is Riyadh."
    },
    {
      question: "Which Asian country is known for its ancient city of Ayutthaya?",
      answer: "Thailand is known for its ancient city of Ayutthaya."
    },
    {
      question: "What is the official language of Japan?",
      answer: "The official language of Japan is Japanese."
    },
    {
      question: "Which Asian country is famous for its Mount Fuji?",
      answer: "Japan is famous for its Mount Fuji."
    },
    {
      question: "Who is the current Prime Minister of China?",
      answer: "As of 2024, the current Prime Minister of China is Li Keqiang."
    },
    {
      question: "What is the currency of Japan?",
      answer: "The currency of Japan is the Japanese yen."
    },
    {
      question: "Which Asian country is known as the 'Land of the Morning Calm'?",
      answer: "South Korea is known as the 'Land of the Morning Calm'."
    },
    {
      question: "What is the official language of Turkey?",
      answer: "The official language of Turkey is Turkish."
    },
    {
      question: "Which Asian country is famous for its ancient city of Babylon?",
      answer: "Iraq is famous for its ancient city of Babylon."
    },
    {
      question: "Who is the current Prime Minister of Pakistan?",
      answer: "As of 2024, the current Prime Minister of Pakistan is Imran Khan."
    },
    {
      question: "What is the capital city of South Korea?",
      answer: "The capital city of South Korea is Seoul."
    },
    {
      question: "Which Asian country is known for its ancient city of Petra?",
      answer: "Jordan is known for its ancient city of Petra."
    },
    {
      question: "What is the official language of Afghanistan?",
      answer: "The official languages of Afghanistan are Pashto and Dari."
    },
    {
      question: "Which Asian country is famous for its Al-Aqsa Mosque?",
      answer: "Palestine is famous for its Al-Aqsa Mosque."
    },
    {
      question: "Who is the current Prime Minister of Bangladesh?",
      answer: "As of 2024, the current Prime Minister of Bangladesh is Sheikh Hasina."
    },
    {
      question: "What is the capital city of Iran?",
      answer: "The capital city of Iran is Tehran."
    },
    {
      question: "Which Asian country is known as the 'Land of the Rising Sun'?",
      answer: "Japan is known as the 'Land of the Rising Sun'."
    },
    {
      question: "What is the official language of Nepal?",
      answer: "The official language of Nepal is Nepali."
    },
    {
      question: "Which Asian country is famous for its Komodo dragons?",
      answer: "Indonesia is famous for its Komodo dragons."
    },
    {
      question: "Who is the current President of South Korea?",
      answer: "As of 2024, the current President of South Korea is Yoon Suk-yeol."
    },
    {
      question: "What is the currency of Afghanistan?",
      answer: "The currency of Afghanistan is the Afghan afghani."
    },
    {
      question: "Which Asian country is known for its Angkor Wat temple complex?",
      answer: "Cambodia is known for its Angkor Wat temple complex."
    },
    {
      question: "What is the capital city of Uzbekistan?",
      answer: "The capital city of Uzbekistan is Tashkent."
    },
    {
      question: "Which Asian country is famous for its karst landscape and Li River?",
      answer: "China is famous for its karst landscape and Li River."
    },
    {
      question: "Who is the current Prime Minister of Nepal?",
      answer: "As of 2024, the current Prime Minister of Nepal is Sher Bahadur Deuba."
    },
    {
      question: "What is the official language of Saudi Arabia?",
      answer: "The official language of Saudi Arabia is Arabic."
    },
    {
      question: "Which Asian country is known as the 'Land of Fire'?",
      answer: "Azerbaijan is known as the 'Land of Fire'."
    },
    {
      question: "What is the capital city of Malaysia?",
      answer: "The capital city of Malaysia is Kuala Lumpur."
    },
    {
      question: "Which Asian country is famous for its ancient city of Ephesus?",
      answer: "Turkey is famous for its ancient city of Ephesus."
    },
    {
      question: "Who is the current President of Indonesia?",
      answer: "As of 2024, the current President of Indonesia is Joko Widodo."
    },
    {
      question: "What is the official language of the United Arab Emirates?",
      answer: "The official language of the United Arab Emirates is Arabic."
    },
    {
      question: "Which Asian country is known for its Mausoleum of the First Qin Emperor?",
      answer: "China is known for its Mausoleum of the First Qin Emperor."
    },
    {
      question: "What is the capital city of Iraq?",
      answer: "The capital city of Iraq is Baghdad."
    },
    {
      question: "Which Asian country is famous for its Phuket Island?",
      answer: "Thailand is famous for its Phuket Island."
    },
    {
      question: "Who is the current Prime Minister of Malaysia?",
      answer: "As of 2024, the current Prime Minister of Malaysia is Ismail Sabri Yaakob."
    },
    {
      question: "What is the official language of Vietnam?",
      answer: "The official language of Vietnam is Vietnamese."
    },
    {
      question: "Which Asian country is known as the 'Pearl of the Indian Ocean'?",
      answer: "Sri Lanka is known as the 'Pearl of the Indian Ocean'."
    },
    {
      question: "What is the capital city of Afghanistan?",
      answer: "The capital city of Afghanistan is Kabul."
    },
    {
      question: "Which Asian country is famous for its Borobudur temple?",
      answer: "Indonesia is famous for its Borobudur temple."
    },
    {
      question: "Who is the current President of Turkey?",
      answer: "As of 2024, the current President of Turkey is Recep Tayyip Erdoğan."
    },
    {
      question: "What is the currency of South Korea?",
      answer: "The currency of South Korea is the South Korean won."
    },
    {
      question: "Which Asian country is known for its Shwedagon Pagoda?",
      answer: "Myanmar is known for its Shwedagon Pagoda."
    },
    {
      question: "What is the official language of Sri Lanka?",
      answer: "The official languages of Sri Lanka are Sinhala and Tamil."
    },
    {
      question: "Which Asian country is famous for its Halong Bay?",
      answer: "Vietnam is famous for its Halong Bay."
    },
    {
      question: "Who is the current Prime Minister of Iraq?",
      answer: "As of 2024, the current Prime Minister of Iraq is Mustafa Al-Kadhimi."
    },
    {
      question: "What is the capital city of Jordan?",
      answer: "The capital city of Jordan is Amman."
    },
    {
      question: "Which Asian country is known for its Dilmun civilization?",
      answer: "Bahrain is known for its Dilmun civilization."
    },
    {
      question: "What is the official language of Kazakhstan?",
      answer: "The official language of Kazakhstan is Kazakh."
    },
    {
      question: "Which Asian country is famous for its tea plantations in Nuwara Eliya?",
      answer: "Sri Lanka is famous for its tea plantations in Nuwara Eliya."
    },
    {
      question: "Who is the current President of Uzbekistan?",
      answer: "As of 2024, the current President of Uzbekistan is Shavkat Mirziyoyev."
    },
    {
      question: "What is the currency of Indonesia?",
      answer: "The currency of Indonesia is the Indonesian rupiah."
    },
    {
      question: "Which Asian country is known for its Mount Kinabalu?",
      answer: "Malaysia is known for its Mount Kinabalu."
    },
    {
      question: "What is the capital city of Kazakhstan?",
      answer: "The capital city of Kazakhstan is Nur-Sultan."
    },
    {
      question: "Which Asian country is famous for its Jeju Island?",
      answer: "South Korea is famous for its Jeju Island."
    },
    {
      question: "Who is the current Prime Minister of Iran?",
      answer: "As of 2024, the current Prime Minister of Iran is Ebrahim Raisi."
    }
  ];
  