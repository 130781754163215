import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AIRobotics() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
# Example 1: Robot Path Planning with ROS (Robot Operating System)
import rospy
from geometry_msgs.msg import Twist

# Initialize ROS node
rospy.init_node('robot_path_planning')

# Define a publisher for velocity commands
velocity_publisher = rospy.Publisher('/cmd_vel', Twist, queue_size=10)

# Example of setting linear and angular velocities
velocity_msg = Twist()
velocity_msg.linear.x = 0.5
velocity_msg.angular.z = 0.2

# Publish the velocity message
velocity_publisher.publish(velocity_msg)
  `;

  const example2 = `
# Example 2: Robot Arm Control with Arduino and Python
import serial
import time

# Initialize serial communication with Arduino
arduino = serial.Serial('/dev/ttyACM0', baudrate=9600, timeout=1)

# Example of controlling robot arm movements
arduino.write(b'1')  # Example command to move robot arm
time.sleep(2)
arduino.write(b'0')  # Stop robot arm movement
  `;

  const example3 = `
# Example 3: Robot Perception using OpenCV and TensorFlow
import cv2
import tensorflow as tf

# Load pre-trained models for object detection
model = tf.keras.applications.MobileNetV2(weights='imagenet')

# Example of using camera feed for object detection
cap = cv2.VideoCapture(0)
while True:
    ret, frame = cap.read()
    resized_frame = cv2.resize(frame, (224, 224))
    pred = model.predict(tf.expand_dims(resized_frame, axis=0))
    # Process prediction results
    # Display output
    cv2.imshow('Robot Perception', frame)
    if cv2.waitKey(1) & 0xFF == ord('q'):
        break
cap.release()
cv2.destroyAllWindows()
  `;

  return (
    <body>
      <Helmet>
        <title>AI Robotics</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI Robotics, Robot Path Planning, Robot Arm Control, Robot Perception, ROS, Arduino, OpenCV, TensorFlow" />
        <meta name="description" content="Learn about AI robotics, its applications, and practical examples using frameworks like ROS, Arduino, OpenCV, and TensorFlow." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_facial_recognition"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_robotics_hardware"><ButtonNext /></ScrollToTopLink>
          <h1>AI Robotics</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>AI robotics combines artificial intelligence with robotics to create intelligent machines capable of performing tasks autonomously. This field encompasses robot path planning, control systems, perception, and more.</p>

            <h2>Examples of AI Robotics</h2>

            <h3>Example 1: Robot Path Planning with ROS</h3>
            <SyntaxHighlighterComponent code={example1} language="python" />

            <h3>Example 2: Robot Arm Control with Arduino and Python</h3>
            <SyntaxHighlighterComponent code={example2} language="python" />

            <h3>Example 3: Robot Perception using OpenCV and TensorFlow</h3>
            <SyntaxHighlighterComponent code={example3} language="python" />
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_facial_recognition"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_robotics_hardware"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
