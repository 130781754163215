import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/ReactSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import ReactMetatag from "../../components/React_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import name from "../../JS/code2";
import weather from "../../JS/code2"



export default function ReactES6spread() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }

    const code= `
    // Spread operator with arrays
    const arr1 = [1, 2, 3];
    const arr2 = [...arr1, 4, 5]; // Spread arr1 into individual elements
    
    // Spread operator with objects
    const obj1 = { foo: 'bar' };
    const obj2 = { ...obj1, baz: 'qux' }; // Spread obj1 into obj2
          `

    const code2=`
    const arr1 = [1, 2, 3];
    const arr2 = [4, 5, 6];
    const combined = [...arr1, ...arr2]; // Output: [1, 2, 3, 4, 5, 6]
        `
  
     const code3=`
     const original = [1, 2, 3];
     const copy = [...original]; // Make a copy of original array
          `

         const code4=`
         const obj1 = { foo: 'bar' };
         const obj2 = { baz: 'qux' };
         const merged = { ...obj1, ...obj2 }; // Merge obj1 and obj2
                `

    return (
   <body>
     <Helmet>
        
        <title>React ES6 Spread Operator</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="React ES6 Spread Operator, Use Cases, Concatenating Arrays, Copying Arrays, Merging Objects
         Step-by-step React programming tutorials React coding exercises for beginners, Advanced React coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       React programming tips for real-world applications," />
      <meta name="description" content="The spread operator is used to expand elements of an iterable (like arrays, strings, or objects) into individual elements." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/React_es6-classes"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-rest"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>React ES6 Spread Operator</h1>
</header>

<Navbar />

<main>
<section>
<p>The spread operator (`...`) and the rest operator (`...`) are both JavaScript operators introduced in ECMAScript 6 (ES6).</p>
<p>They may look similar but they serve different purposes and are used in different contexts. Let's begin with the spread operator.</p>

  <p>The spread operator is used to expand elements of an iterable (like arrays, strings, or objects) into individual elements.</p>
  <p>It allows you to manipulate arrays and objects in a concise and readable way.</p>
  <h2>Example</h2>
 <SyntaxHighlighterComponent code={code} language="js" />

 <h2>Use Cases</h2>
 <h3>Concatenating Arrays:</h3>
 <SyntaxHighlighterComponent code={code2} language="js" />

 <h3>Copying Arrays:</h3>
 <SyntaxHighlighterComponent code={code3} language="js" />

 <h3>Merging Objects:</h3>
 <SyntaxHighlighterComponent code={code4} language="js" />


</section>
</main>
<div className="head">
<ScrollToTopLink to="/React_es6-classes"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/React_es6-rest"><ButtonNext /></ScrollToTopLink>

</div>

</div>

<Footertutorials />
   </body>
    )
}