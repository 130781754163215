import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import toggle from "../responsivetopnav";
import ScrollToTopLink from "../ScrollToTop"; // Assuming ScrollToTopLink is defined in ScrollToTopLink.js
import { useLocation } from 'react-router-dom';
import HTMLSearchBar from "../Searchbar/HTML SearchBar/HTMLSearchBar";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export default function Sidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  const toggleDropdown3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
  };

  const toggleDropdown4 = () => {
    setIsDropdownOpen4(!isDropdownOpen4);
  };

  const toggleDropdown5 = () => {
    setIsDropdownOpen5(!isDropdownOpen5);
  };
  var nav = {
    color: 'white',
    fontSize: '30px'
  }
  var newCode = {
    float: 'right'
  }
  return (
    <div>
      <div className={`sidebar ${isOpen ? 'responsive' : ''}`} id="mySidebar">
        <span className="close" onClick={toggle}>&times;</span>
        <h2>HTML Tutorial</h2><br />
        <span><HTMLSearchBar /></span>
        <p className="p-sidebar">...Empowering Your Online Presence...</p><br />
        <ScrollToTopLink to="/HTML_Tutorial_intro"><a className={location.pathname === '/HTML_Tutorial_intro' ? 'active' : ''}>HTML Introduction</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_editors"><a className={location.pathname === '/HTML_editors' ? 'active' : ''}>HTML Editors</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_comments"><a className={location.pathname === '/HTML_comments' ? 'active' : ''}>HTML Comments</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_elements"><a className={location.pathname === '/HTML_elements' ? 'active' : ''}>HTML Elements</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_attributes"><a className={location.pathname === '/HTML_attributes' ? 'active' : ''}>HTML Attributes</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_attributes pt.2"><a className={location.pathname === '/HTML_attributes pt.2' ? 'active' : ''}>HTML Attributes pt.2</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_heading"><a className={location.pathname === '/HTML_heading' ? 'active' : ''}>HTML Headings</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_paragraph"><a className={location.pathname === '/HTML_paragraph' ? 'active' : ''}>HTML Paragraphs</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_styles"><a className={location.pathname === '/HTML_styles' ? 'active' : ''}>HTML Styles</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_colors"><a className={location.pathname === '/HTML_colors' ? 'active' : ''}>HTML Colors</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_links"><a className={location.pathname === '/HTML_links' ? 'active' : ''}>HTML Links</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_horizontal_rule"><a className={location.pathname === '/HTML_horizontal_rule' ? 'active' : ''}>HTML Horizontal Rule</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_line_break"><a className={location.pathname === '/HTML_line_break' ? 'active' : ''}>HTML Line Break</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_text_formatting"><a className={location.pathname === '/HTML_text_formatting' ? 'active' : ''}>HTML Text Formatting</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_section"><a className={location.pathname === '/HTML_section' ? 'active' : ''}>HTML5 Sections</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_image"><a className={location.pathname === '/HTML_image' ? 'active' : ''}>HTML Images</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_block_inline"><a className={location.pathname === '/HTML_block_inline' ? 'active' : ''}>HTML Block-Level and Inline Elements</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_subscript"><a className={location.pathname === '/HTML_subscript' ? 'active' : ''}>HTML Subscript</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_superscript"><a className={location.pathname === '/HTML_superscript' ? 'active' : ''}>HTML Superscript</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_figures"><a className={location.pathname === '/HTML_figures' ? 'active' : ''}>HTML Figures</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_nbsp"><a className={location.pathname === '/HTML_nbsp' ? 'active' : ''}>HTML nbsp</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_entities"><a className={location.pathname === '/HTML_entities' ? 'active' : ''}>HTML Character Entities</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_pre"><a className={location.pathname === '/HTML_pre' ? 'active' : ''}>HTML Pre Element</a></ScrollToTopLink><br />
        <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown}>
          HTML Lists {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen && (
          <div className="dropdown-content">
        <ScrollToTopLink to="/HTML_lists"><a className={location.pathname === '/HTML_lists' ? 'active' : ''}>HTML Lists</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_unordered"><a className={location.pathname === '/HTML_unordered' ? 'active' : ''}>HTML Unordered List</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_ordered"><a className={location.pathname === '/HTML_ordered' ? 'active' : ''}>HTML Ordered List</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_description"><a className={location.pathname === '/HTML_description' ? 'active' : ''}>HTML Definition Lists</a></ScrollToTopLink><br />
            </div>
        )}
      </div>
       <h2>HTML Forms</h2><br />
        <ScrollToTopLink to="/HTML_forms"><a className={location.pathname === '/HTML_forms' ? 'active' : ''}>HTML Forms</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_forms_attr"><a className={location.pathname === '/HTML_forms_attr' ? 'active' : ''}>HTML5 Form Element Attributes</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_forms_attr2"><a className={location.pathname === '/HTML_forms_attr2' ? 'active' : ''}>HTML5 Form Element Attributes pt.2</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_js"><a className={location.pathname === '/HTML_js' ? 'active' : ''}>HTML JavaScript</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_input"><a className={location.pathname === '/HTML_input' ? 'active' : ''}>HTML Input Types</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/HTML_input2"><a className={location.pathname === '/HTML_input2' ? 'active' : ''}>HTML Input Types pt.2</a></ScrollToTopLink><br />
      </div>

      <div className="burger-icon" onClick={toggle}>
        &#9776;
        <p style={newCode}>Menu</p>
      </div>
    </div>
  );
}