import React from 'react';
import { Helmet } from 'react-helmet';
import Sidenav from '../Sidenav/ProgressSidenav';
import Navbar from '../navbar';
import "../../css/navigation.css";
import "./ProgressTracker.css"; // Import the new CSS file

const DemoProgress = () => {
  const percentage = 50; // Set a fixed percentage for demonstration

  return (
    <>
     
    
      
            <svg width="250" height="250" viewBox="0 0 36 36">
              <path style={{ fill: 'none', stroke: '#e0e0e0', strokeWidth: '3.8' }}
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
              <path style={{ fill: 'none', stroke: '#00008b', strokeWidth: '2.8', strokeLinecap: 'round', transition: 'stroke-dashoffset 0.3s' }}
                strokeDasharray="100, 100"
                strokeDashoffset={`${100 - percentage}`}
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
              <text x="18" y="20.35" style={{ fill: '#fff', fontSize: '5px', textAnchor: 'middle' }}>
                {`${percentage}%`}
              </text>
            </svg>
    
    </>
  );
};

export default DemoProgress;
