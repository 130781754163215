import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPSyntax() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    <?php...?>
    `;
    const code2 = `
    <script language = "php">
    // PHP code should be in here
    </script>
    `;
    const code3 = `
    <?...?>
    `;
    const code4 = `
    <%...%>
    `;
    const code5 = `
    <html>
      <head>
        <title>PHP Example</title>
      </head>
    <body>
      <?php
        $greeting = "Good Day";
        $Greeting = "Good Morning";
        $GReeTInG = "Good Night";
      print(greeting);  # the output will be Good Day
      ?>
    </body>
    </html>
    
    `;
    const code6 = `
    $sum = 23 + 12; //outputs 35
    $SUM = 23     +     12; //outputs 35
    $Sum = 23 + 
    12; //outputs 35
    `;

    return (
   <body>
    <Helmet>
        <title>PHP Syntax</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Syntax, Escaping to PHP, Canonical PHP Tags, HTML Script Tags, Short-Open Tags(SGML-style), ASP-style Tags, Case Sensitive, Whitespaces,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="Learn how PHP Syntax streamline code organization, 
      enhance readability, and unlock advanced features in PHP development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_environment_setup"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_comments"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Syntax</h1>
</header>

<Navbar />

<main>
<section>
<p>In this chapter of the PHP tutorial you will have an idea of PHP syntax.</p>
<h2>Escaping to PHP</h2>
<p>A need arises to differentiate PHP code from other elements or tags.</p>
<p>The mechanism for doing this is known as 'escaping to PHP'.To do this there are four methods,they are:</p>
<h2>Canonical PHP Tags</h2>
<p>The canonical method is the most effective PHP tag style.</p><br />
<SyntaxHighlighterComponent code={code} language="php" />

<h2>HTML Script Tags</h2>
<p>This method involves using the &lt;script&gt;&lt;/script&gt; tag with it's language attribute set to 'php'</p>
<SyntaxHighlighterComponent code={code2} language="php" />

<h2>Short-Open Tags(SGML-style)</h2>
<p>As the name implies short-open tags are the shortest way to display PHP code but you nedd to configure it first.</p>
<p>These are the steps to configure the short-open tags in your web server.</p>
<li>When you are building your PHP make sure to select the --enable-short-tags configuration option.</li>
<li>Make sure to set the short_open_tag setting in yout php.ini file on.</li>
<p>It is not compulsory to enable your web server for the short-open tags as the other methods will also work.</p>
<p>This is how the short-open tags look like.</p>
<SyntaxHighlighterComponent code={code3} language="php" />

<h2>ASP-style Tags</h2>
<p>This method copies the tags used by Active Server Pages to delineate code blocks.</p>
<p>To make use of ASP-style tags you will have to configure it in your php.ini file.</p>
<p>This is what ASP-style tags look like</p>
<SyntaxHighlighterComponent code={code4} language="php" />
<br />
<h2>Case Sensitive</h2>
<p>PHP is a case sensitive programming language meaning $code and $Code are to different variables.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code5} language="php" />

<h2>Whitespaces</h2>
<p>Whitespaces are tabs or spaces typed on the screen.PHP is insensitive to whitespaces meaning that most of the
    time it does not matter the number of spaces you type.
</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code6} language="php" />
<p>In the example above there are three variables, each of them displays the same output despite the space between them.</p>
</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_environment_setup"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_comments"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}