import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link, ScrollRestoration } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSEnvironment() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
    <script>
    /* JavaScript Code */
    </script>
`;
const code2 = `
<script type="text/javascript">
/* JavaScript Code */
</script>
`;

    return (
   <body>
        <Helmet>
    
    <title>JavaScript Environment Setup</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="JavaScript Environment Setup, Step-by-step JavaScript programming tutorials, JavaScript coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
   JavaScript programming tips for real-world applications," />
  <meta name="description" content="A JavaScript (JS) environment refers to the environment in which JavaScript code is executed. It encompasses all the resources, tools, and runtime components necessary for executing JavaScript code.
  Learn how to setup a JavaScript environment," />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_comment"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_display_output"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Environment</h1>
</header>

<Navbar />

<main>
    <section>
    <p>A JavaScript (JS) environment refers to the environment in which JavaScript code is executed. It encompasses
     all the resources, tools, and runtime components necessary for executing JavaScript code. 
</p>
<p>
    JavaScript is a versatile programming language commonly used for building dynamic and interactive
     web applications, but it can also be used in other contexts like server-side scripting (Node.js)
      and even mobile app development (React Native).
</p>
    <p> A JavaScript environment is like the stage where your website's JavaScript code performs.
        It includes the browser as the host, tools for interacting with the webpage and browser, ways
         to handle user interactions, and more. 
    </p>
    <p> All these components work together to create dynamic and interactive web experiences.</p>
    <p>Javascript code is written inside the &lt;script&gt; tag and optionally you can place the type 
        attribute like you do in CSS as text/javascript e.g, &lt;script type="text/javascript"&gt;
    </p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
 
 
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />

</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_comment"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_display_output"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}