import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

const XMLVersioning = () => {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<?xml version="1.0" encoding="UTF-8"?>
<note>
  <to>User</to>
  <from>Admin</from>
  <heading>Reminder</heading>
  <body>Don't forget to check your email!</body>
</note>
`;

  const example2 = `
<?xml version="1.1"?>
<book>
  <title>Learning XML</title>
  <author>John Doe</author>
  <year>2024</year>
  <publisher>Example Publisher</publisher>
</book>
`;

  return (
    <body>
      <Helmet>
        <title>XML Versioning</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML Versioning, XML Tutorial, XML Version Syntax" />
        <meta name="description" content="Learn about XML versioning and how to specify XML version in documents." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_comments"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_encoding"><ButtonNext /></ScrollToTopLink>
          <h1>XML Versioning</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XML versioning is used to specify the version of XML in use within a document. It helps maintain compatibility and ensures proper interpretation of XML documents.</p>

            <h2>Syntax</h2>
            <p>The XML version declaration is placed at the beginning of an XML document:</p>
            <SyntaxHighlighterComponent code={example1} language="xml" />

            <h2>Examples</h2>

            <h3>XML 1.0 Version Declaration</h3>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This example specifies XML version 1.0 with UTF-8 encoding.</p>

            <h3>XML 1.1 Version Declaration</h3>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>This example specifies XML version 1.1 without specifying encoding.</p>

            <h2>Usage</h2>
            <p>Specify the XML version declaration at the beginning of your XML document to ensure compatibility and proper parsing.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_comments"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_encoding"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}

export default XMLVersioning;
