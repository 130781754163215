import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSArithmeticOperators() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
    let a = 10;
    let b = 3;
    
    let addition = a + b;         // 13
    let subtraction = a - b;      // 7
    let multiplication = a * b;   // 30
    let division = a / b;         // 3.333...
    let remainder = a % b;        // 1
    let exponentiation = a ** b;  // 1000    
            
`;
const code2 = `
var x = 17;
var y = 10;

var addition = x + y;         // 27
var subtraction = x - y;      // 7
var multiplication = x * y;   // 170
var division = x / y;         // 1.7
var remainder = x % y;        // 7
var exponentiation = x ** y;  // 2,015,993,900,449

`;

const code3 = `
var sequence = 7 - 3 * 2; // returns 1
   alert(sequence);
`;

    return (
   <body>
    <Helmet>
        
        <title>JavaScript Arithmetic Operators</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="JavaScript Arithmetic Operators, Step-by-step JavaScript programming tutorials, JavaScript coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       JavaScript programming tips for real-world applications," />
      <meta name="description" content="Learn how JavaScript Arithmetic Operators streamline code organization, 
      enhance readability, and unlock advanced features in JavaScript development. Arithmetic operators perform basic mathematical calculations on numeric values." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_assignment_operators"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Arithmetic Operators</h1>
</header>

<Navbar />

<main>
    <section>
    <p>Arithmetic operators perform basic mathematical calculations on numeric values.
</p>
<p>Assignment operators are used to assign values to variables.There are many types of arithmetic operators they
    include:
</p>
<li>+ (Addition): Adds two values.</li>
<li>- (Subtraction): Subtracts the second value from the first.</li>
<li>* (Multiplication): Multiplies two values.</li>
<li>/ (Division): Divides the first value by the second.</li>
<li>% (Modulus): Returns the remainder of division.</li>
<li>** (Exponentiation): Raises the first value to the power of the second.</li>
<p>All the listed operators above will be explained in seperate lessons.</p>
<p>Here is a brief defintion of them</p>
 <h2>Arithmetic Operators</h2>
 <p>The arithmetic operator perfors simple mathematical operations</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
<ScrollToTopLink to="/try_js29"><Tryitout /></ScrollToTopLink> 
    
    
    <p>This is another example of the arithmetic operators.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js30"><Tryitout /></ScrollToTopLink> 

    <h2>Operator Sequence</h2>
<p>This describes the order of performed operations in an arithmetic expression.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code3} language="javascript" />
<ScrollToTopLink to="/try_js31"><Tryitout /></ScrollToTopLink> 

<p>The example above returns 1 because operator sequence in JavaScript follows the MDAS pattern.</p>
<p>The full meaning of MDAS is multiplication, division, addition and subtraction so multiplication comes
    before subtraction in the example
</p>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_assignment_operators"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}