import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";

export default function AIUnsupervisedLearning() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const exampleCode = `
# Python example for unsupervised learning using KMeans
import numpy as np
from sklearn.cluster import KMeans
import matplotlib.pyplot as plt

# Generate sample data
X = np.random.rand(100, 2)

# Create KMeans model
kmeans = KMeans(n_clusters=3, random_state=42).fit(X)

# Predict cluster labels
labels = kmeans.predict(X)

# Plot results
plt.scatter(X[:, 0], X[:, 1], c=labels, cmap='viridis')
plt.show()
`;

  return (
    <body>
      <Helmet>
        <title>AI Unsupervised Learning</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="AI, Unsupervised Learning, Machine Learning, Clustering, Dimensionality Reduction" />
        <meta name="description" content="Learn about AI Unsupervised Learning techniques including clustering, dimensionality reduction, and practical examples." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_supervised_learning"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_reinforcement_learning"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>AI Unsupervised Learning</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Unsupervised learning is a type of machine learning where the model is trained on unlabeled data. The goal is to find hidden patterns or intrinsic structures in the input data.</p>

            <h2>Types of Unsupervised Learning</h2>
            <p>There are two main types of unsupervised learning:</p>
            <ul>
              <li><strong>Clustering</strong>: The goal is to group similar data points together. Examples include KMeans and hierarchical clustering.</li>
              <li><strong>Dimensionality Reduction</strong>: The goal is to reduce the number of features in the data while preserving its essential structure. Examples include PCA and t-SNE.</li>
            </ul>

            <h2>Basic Example of Unsupervised Learning</h2>
            <p>Here is a simple example of using Python to perform clustering using KMeans:</p>
            <SyntaxHighlighterComponent code={exampleCode} language="python" />
            <p>In this example:</p>
            <ul>
              <li>We generate sample data and create a KMeans model.</li>
              <li>We fit the model to the data and predict cluster labels.</li>
              <li>We plot the results using matplotlib.</li>
            </ul>

            <h2>Applications of Unsupervised Learning</h2>
            <p>Unsupervised learning is used in various applications such as:</p>
            <ul>
              <li><strong>Customer Segmentation</strong>: Grouping customers based on purchasing behavior.</li>
              <li><strong>Anomaly Detection</strong>: Identifying unusual data points or outliers.</li>
              <li><strong>Market Basket Analysis</strong>: Finding associations between products in transaction data.</li>
              <li><strong>Image Compression</strong>: Reducing the size of image files while maintaining quality.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_supervised_learning"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_reinforcement_learning"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
