import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/CSS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import CSSMetatag from "../../components/CSS_Metatag";
import { Helmet } from "react-helmet";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function CSSBackgroundAttachment() {
  var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  }
  var secondCode = {
    color: 'black'
  }
  var thirdCode = {
    padding: '20px'
  }

  const code = `
  .parallax-section_1 {
      background-image: url(a monitor.jpg);
      background-attachment: fixed; /* Keep the image fixed */
  }

  `;
  const code2 = `
  #parallax-section_1 {
  background-image: url(/image/a\ monitor.jpg);
  background-attachment: scroll; /* Create a parallax scrolling effect */
  }
  `;

  return (
    <body>
       <Helmet>     
        <title>CSS Background Attachment</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="CSS Background Attachment, Step-by-step CSS programming tutorials, CSS coding exercises for beginners, Advanced CSS coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       CSS programming tips for real-world applications," />
      <meta name="description" content="Learn how CSS Background Attachment controls how the background image behaves as the content is scrolled,
      and unlock advanced features in CSS development" />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
      <br />
        <br />
        <br />
        <br />
        <br />
      <Sidenav />
      
      <div className="content">

        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/CSS_background_images"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/CSS_width"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>CSS Background Attachment</h1>
        </header>

        <Navbar />

        <main>
          <section>
            <p>The background-attachment property controls how the background image behaves as the content is
         scrolled.</p>
            <p> It can be set to scroll, which allows the background to scroll with the content, or 
        fixed, which keeps the background fixed in place even as the content moves.</p>

            <br />
            <h2>Example:</h2>
            <SyntaxHighlighterComponent code={code} language="css" />
            <ScrollToTopLink to="/try_css28"><Tryitout /></ScrollToTopLink>
            <br />
            <h2>Example:</h2>
            <SyntaxHighlighterComponent code={code2} language="css" />
            <ScrollToTopLink to="/try_css29"><Tryitout /></ScrollToTopLink>
          </section>

        </main>
        <div className="head">
          <ScrollToTopLink to="/CSS_background_images"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/CSS_width"><ButtonNext /></ScrollToTopLink>
        </div>

      </div>

      <Footertutorials />
    </body>
  )
}
