import React from 'react';

export default function Landing() {
    const landingStyles = {
        backgroundColor: '#f9f9f9',
        padding: '20px',
        borderRadius: '5px',
    };

    return (
        <div>
            <h1>Responsive Landing Page</h1>
            <div style={landingStyles}>
                <h2>Welcome to Our Website!</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div>
                <h2>Code Snippet (HTML):</h2>
                <pre>{`
                <div class="landingStyles">
                    <h2>Welcome to Our Website!</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
                `}</pre>
            </div>
            <div>
                <h2>Code Snippet (CSS):</h2>
                <pre>{`
                 .landingStyles {
                    backgroundColor: #f9f9f9;
                    padding: 20px;
                    borderRadius: 5px;
                };
                `}</pre>
            </div>
        </div>
    );
}
