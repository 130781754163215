import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyArrayMethods() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const pushPopCode = `
# Adding and Removing Elements
numbers = [1, 2, 3, 4, 5]

numbers.push(6)      # Adds 6 to the end
numbers << 7         # Adds 7 to the end
numbers.unshift(0)   # Adds 0 to the beginning

numbers.pop          # Removes and returns the last element
numbers.shift        # Removes and returns the first element

puts numbers
# Output: [1, 2, 3, 4, 5, 6]
  `;

  const mapSelectCode = `
# Transforming and Filtering Arrays
numbers = [1, 2, 3, 4, 5]

squared_numbers = numbers.map { |n| n * 2 }
even_numbers = numbers.select { |n| n.even? }

puts squared_numbers
puts even_numbers
# Output: [2, 4, 6, 8, 10]
# Output: [2, 4]
  `;

  const includeCode = `
# Searching in Arrays
numbers = [1, 2, 3, 4, 5]

puts numbers.include?(3)   # Output: true
puts numbers.include?(6)   # Output: false
  `;

  const sortReverseCode = `
# Sorting and Reversing Arrays
numbers = [5, 3, 8, 1, 2]

sorted_numbers = numbers.sort
reversed_numbers = numbers.reverse

puts sorted_numbers
puts reversed_numbers
# Output: [1, 2, 3, 5, 8]
# Output: [2, 1, 8, 3, 5]
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Array Methods</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Array Methods, Adding Elements, Removing Elements, Transforming Arrays, Filtering Arrays, Searching Arrays, Sorting Arrays, Reversing Arrays" />
        <meta name="description" content="Learn about various methods available for arrays in Ruby, including adding, removing, transforming, filtering, searching, sorting, and reversing elements." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_arrays"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_hashes"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Array Methods</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Ruby provides a variety of methods for working with arrays, allowing you to add, remove, search, and manipulate elements efficiently.</p>

            <h2>Adding and Removing Elements</h2>
            <p>Use <code>push</code> and <code>&lt;&lt;</code> to add elements to the end of an array, and <code>unshift</code> to add elements to the beginning. Use <code>pop</code> to remove elements from the end and <code>shift</code> to remove elements from the beginning.</p>
            <SyntaxHighlighterComponent code={pushPopCode} language="ruby" />
            <p>In the above example, we demonstrate adding and removing elements from an array using various methods.</p>

            <h2>Transforming and Filtering Arrays</h2>
            <p>Use <code>map</code> to transform each element in an array and <code>select</code> to filter elements based on a condition.</p>
            <SyntaxHighlighterComponent code={mapSelectCode} language="ruby" />
            <p>In the above example, we use <code>map</code> to create a new array with each element squared, and <code>select</code> to create a new array with only even numbers.</p>

            <h2>Searching in Arrays</h2>
            <p>Use <code>include?</code> to check if an array contains a specific element.</p>
            <SyntaxHighlighterComponent code={includeCode} language="ruby" />
            <p>In the above example, we check if the array contains the number 3 and the number 6.</p>

            <h2>Sorting and Reversing Arrays</h2>
            <p>Use <code>sort</code> to sort the elements in an array and <code>reverse</code> to reverse the order of elements.</p>
            <SyntaxHighlighterComponent code={sortReverseCode} language="ruby" />
            <p>In the above example, we sort the array in ascending order and reverse the order of elements in the array.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_arrays"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_hashes"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
