import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLAjax() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<!DOCTYPE html>
<html>
<body>

<h2>Using AJAX to load XML data</h2>
<div id="demo"></div>

<script>
function loadXMLDoc() {
  const xhttp = new XMLHttpRequest();
  xhttp.onload = function() {
    const xmlDoc = xhttp.responseXML;
    const txt = xmlDoc.getElementsByTagName("title")[0].childNodes[0].nodeValue;
    document.getElementById("demo").innerHTML = txt;
  }
  xhttp.open("GET", "note.xml", true);
  xhttp.send();
}
loadXMLDoc();
</script>

</body>
</html>
`;

  const example2 = `
<!DOCTYPE html>
<html>
<body>

<h2>AJAX with XML Data</h2>
<div id="content"></div>

<script>
function fetchData() {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      const xmlDoc = this.responseXML;
      const titles = xmlDoc.getElementsByTagName("title");
      let html = "";
      for (let i = 0; i < titles.length; i++) {
        html += "<p>" + titles[i].childNodes[0].nodeValue + "</p>";
      }
      document.getElementById("content").innerHTML = html;
    }
  };
  xhttp.open("GET", "books.xml", true);
  xhttp.send();
}
fetchData();
</script>

</body>
</html>
`;

  const example3 = `
<!DOCTYPE html>
<html>
<body>

<h2>Displaying XML Data with AJAX</h2>
<div id="books"></div>

<script>
function showBooks() {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      const xmlDoc = this.responseXML;
      const books = xmlDoc.getElementsByTagName("book");
      let html = "";
      for (let i = 0; i < books.length; i++) {
        html += "<h3>" + books[i].getElementsByTagName("title")[0].childNodes[0].nodeValue + "</h3>";
        html += "<p>Author: " + books[i].getElementsByTagName("author")[0].childNodes[0].nodeValue + "</p>";
      }
      document.getElementById("books").innerHTML = html;
    }
  };
  xhttp.open("GET", "library.xml", true);
  xhttp.send();
}
showBooks();
</script>

</body>
</html>
`;

  return (
    <body>
      <Helmet>
        <title>XML AJAX</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML AJAX, AJAX with XML, XML Tutorial, XML AJAX Examples" />
        <meta name="description" content="Learn how to use AJAX with XML data with examples and explanations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_deserialization"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_ajax_xmlhttp"><ButtonNext /></ScrollToTopLink>
          <h1>XML AJAX</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>AJAX (Asynchronous JavaScript and XML) allows web pages to be updated asynchronously by exchanging small amounts of data with the server behind the scenes. This means that it is possible to update parts of a web page, without reloading the whole page.</p>

            <h2>Introduction to XML AJAX</h2>
            <p>Using AJAX to retrieve XML data from a web server is a common practice in modern web development. AJAX uses the <code>XMLHttpRequest</code> object to communicate with the server. Below are examples of how to use AJAX to fetch and display XML data.</p>
            <p>It uses a combination of:</p>
            <ul>
                <li><b>JavaScript:</b>To send asynchronous requests to the server.</li><br />
                <li><b>XMLHttpRequest (XHR) Object:</b>To interact with the server and retrieve data.</li><br />
                <li><b>HTML and CSS</b>To update the user interface dynamically based on server responses.</li>
            </ul>
            <h2>Example 1: Basic AJAX Request to Load XML</h2>
            <SyntaxHighlighterComponent code={example1} language="html" />
            <p>This example shows how to load an XML file and display its content using AJAX. The <code>loadXMLDoc</code> function sends a request to the server and processes the response XML.</p>

            <h2>Example 2: Fetching and Displaying XML Data</h2>
            <SyntaxHighlighterComponent code={example2} language="html" />
            <p>In this example, we fetch an XML file containing book titles and display them on the web page. The <code>fetchData</code> function handles the AJAX request and response processing.</p>

            <h2>Example 3: Displaying More Complex XML Data</h2>
            <SyntaxHighlighterComponent code={example3} language="html" />
            <p>This example demonstrates how to fetch and display more complex XML data, such as a list of books with titles and authors. The <code>showBooks</code> function processes the XML response and updates the web page accordingly.</p>

            <h2>Benefits of Using AJAX with XML</h2>
            <ul>
              <li>Improves user experience by updating parts of the web page without reloading.</li>
              <li>Enables asynchronous communication with the server.</li>
              <li>Allows for structured data exchange using XML format.</li>
            </ul>

            <h2>Common Use Cases</h2>
            <ul>
              <li>Loading dynamic content without refreshing the entire page.</li>
              <li>Fetching configuration or metadata for web applications.</li>
              <li>Implementing real-time data updates and interactions.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_deserialization"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_ajax_xmlhttp"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
