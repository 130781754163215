import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import toggle from "../responsivetopnav";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../ScrollToTop";
import { useLocation } from 'react-router-dom';
import ReactSearchBar from "../Searchbar/React SearchBar/ReactSearchBar";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export default function Sidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  const toggleDropdown3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
  };

  const toggleDropdown4 = () => {
    setIsDropdownOpen4(!isDropdownOpen4);
  };

  const toggleDropdown5 = () => {
    setIsDropdownOpen5(!isDropdownOpen5);
  };
  var nav = {
    color: 'white',
    fontSize: '30px'
}
var newCode = {
  float: 'right'
}
    return (
      <div>
      <div className={`sidebar ${isOpen ? 'responsive' : ''}`} id="mySidebar">
        <span className="close" onClick={toggle}>&times;</span>
    <h2 style={nav}>React Tutorial</h2><br />
    <span><ReactSearchBar /></span><br />
    <p className="p-sidebar">...Empowering Your Online Presence...</p><br />
    <ScrollToTopLink to="/React_Tutorial_intro"><a className={location.pathname === '/React_Tutorial_intro' ? 'active' : ''}>React Intro</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_environment_setup"><a className={location.pathname === '/React_environment_setup' ? 'active' : ''}>React Environment Setup</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_create-react-app"><a className={location.pathname === '/React_create-react-app' ? 'active' : ''}>React create-react-app</a></ScrollToTopLink><br />
    <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown}>
        React ES6 {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen && (
          <div className="dropdown-content">
  <ScrollToTopLink to="/React_es6"><a className={location.pathname === '/React_es6' ? 'active' : ''}>ES6 Intro</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_es6-modules"><a className={location.pathname === '/React_es6-modules' ? 'active' : ''}>ES6 Modules</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_es6-arrow-functioning"><a className={location.pathname === '/React_es6-arrow-functioning' ? 'active' : ''}>ES6 Arrow Functions</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_es6-destructuring"><a className={location.pathname === '/React_es6-destructuring' ? 'active' : ''}>ES6 Destructuring Assignment</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_es6_template-literals"><a className={location.pathname === '/React_es6_template-literals' ? 'active' : ''}>ES6 Template literals</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_es6-classes"><a className={location.pathname === '/React_es6-classes' ? 'active' : ''}>ES6 Classes</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_es6-spread"><a className={location.pathname === '/React_es6-spread' ? 'active' : ''}>ES6 Spread Operator</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_es6-rest"><a className={location.pathname === '/React_es6-rest' ? 'active' : ''}>ES6 Rest Operator</a></ScrollToTopLink><br />
        </div>
        )}
      </div>

    <ScrollToTopLink to="/React_jsx"><a className={location.pathname === '/React_jsx' ? 'active' : ''}>React JSX</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_components"><a className={location.pathname === '/React_components' ? 'active' : ''}>React Components</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_state"><a className={location.pathname === '/React_state' ? 'active' : ''}>React State Management</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_props"><a className={location.pathname === '/React_props' ? 'active' : ''}>React Props</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_event"><a className={location.pathname === '/React_event' ? 'active' : ''}>React Event Handling</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_conditional"><a className={location.pathname === '/React_conditional' ? 'active' : ''}>React Conditional Rendering</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_lists"><a className={location.pathname === '/React_lists' ? 'active' : ''}>React Lists</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_keys"><a className={location.pathname === '/React_keys' ? 'active' : ''}>React Keys</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_forms"><a className={location.pathname === '/React_forms' ? 'active' : ''}>React Forms</a></ScrollToTopLink><br />
    <ScrollToTopLink to="/React_router"><a className={location.pathname === '/React_router' ? 'active' : ''}>React Router</a></ScrollToTopLink><br />
    </div>
  
  <div className="burger-icon" onClick={toggle}>
    &#9776;
    <p style={newCode}>Menu</p>
  </div>
</div>
    )
}