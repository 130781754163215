import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonFunctions() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const codeDefineFunction = `
# Defining a function
def greet():
    print("Hello, World!")
    `;

    const codeCallFunction = `
# Calling a function
greet()  # Output: Hello, World!
    `;

    const codeParameters = `
# Function with parameters
def greet(name):
    print(f"Hello, {name}!")
greet("Alice")  # Output: Hello, Alice!
    `;

    const codeReturnValue = `
# Function with a return value
def add(a, b):
    return a + b
result = add(2, 3)
print(result)  # Output: 5
    `;

    const codeDefaultParameters = `
# Function with default parameters
def greet(name="World"):
    print(f"Hello, {name}!")
greet()       # Output: Hello, World!
greet("Bob")  # Output: Hello, Bob!
    `;

    const codeKeywordArguments = `
# Function with keyword arguments
def greet(first_name, last_name):
    print(f"Hello, {first_name} {last_name}!")
greet(last_name="Doe", first_name="John")  # Output: Hello, John Doe!
    `;

    const codeArbitraryArguments = `
# Function with arbitrary arguments
def greet(*names):
    for name in names:
        print(f"Hello, {name}!")
greet("Alice", "Bob", "Charlie")  # Output: Hello, Alice! Hello, Bob! Hello, Charlie!
    `;

    return (
        <body>
            <Helmet>
                <title>Python Functions</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Functions, Define Function, Function Parameters, Return Value, Default Parameters, Keyword Arguments, Arbitrary Arguments" />
                <meta name="description" content="Learn about functions in Python, including defining functions, function parameters, return values, default parameters, keyword arguments, and arbitrary arguments." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_loops"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_lambda"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Functions</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Functions in Python are blocks of reusable code that perform a specific task. This tutorial covers how to define and use functions, as well as advanced concepts like default parameters and arbitrary arguments.</p>
                        
                        <h2>Defining a Function</h2>
                        <p>Use the <code>def</code> keyword to define a function.</p>
                        <SyntaxHighlighterComponent code={codeDefineFunction} language="python" />
                        
                        <h2>Calling a Function</h2>
                        <p>After defining a function, you can call it by its name followed by parentheses.</p>
                        <SyntaxHighlighterComponent code={codeCallFunction} language="python" />
                        
                        <h2>Function Parameters</h2>
                        <p>Functions can accept parameters, allowing you to pass data to them.</p>
                        <SyntaxHighlighterComponent code={codeParameters} language="python" />
                        
                        <h2>Return Value</h2>
                        <p>Functions can return values using the <code>return</code> statement.</p>
                        <SyntaxHighlighterComponent code={codeReturnValue} language="python" />
                        
                        <h2>Default Parameters</h2>
                        <p>You can define default values for function parameters.</p>
                        <SyntaxHighlighterComponent code={codeDefaultParameters} language="python" />
                        
                        <h2>Keyword Arguments</h2>
                        <p>Keyword arguments allow you to specify arguments by their parameter names.</p>
                        <SyntaxHighlighterComponent code={codeKeywordArguments} language="python" />
                        
                        <h2>Arbitrary Arguments</h2>
                        <p>Use <code>*args</code> to pass a variable number of arguments to a function.</p>
                        <SyntaxHighlighterComponent code={codeArbitraryArguments} language="python" />
                        
                        <h2>Conclusion</h2>
                        <p>Functions are essential for structuring and reusing code in Python. This tutorial covered defining functions, calling functions, function parameters, return values, default parameters, keyword arguments, and arbitrary arguments.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_loops"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_lambda"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
