import React, { useEffect, useState } from "react";
import "../css/my style.css";
import "../css/my style2.css";
import { Helmet } from "react-helmet";
import SaveCodeButton from "../components/Savecodebutton";
import SyntaxHighlighterComponent from "../components/SyntaxHighlighterComponent";
import runcode from "../components/CodeEditor";
import { useNavigate } from "react-router-dom";
import { getAuthStatus } from "../localstorage";


export default function SVGEditor() {

    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate(); // Move useNavigate here
  
    useEffect(() => {
      const checkAuthentication = () => {
        const isAuthenticated = getAuthStatus('_id');
        if (!isAuthenticated) {
          navigate("/login");
        } else {
          setAuthenticated(true);
        }
      };
  
      checkAuthentication();
    }, [navigate]);
  

    const code = `
    <svg xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 576 512" 
    width="25px" 
    height="20px">
    <path fill="#00008b"
    d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/>
    </svg>
     `;
 

    return (
    <div>
    <Helmet>
     <title>SVG Editor</title>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="SVG Editor, Bet Thread SVG Editor, SVG Viewer, SVG, SVG Files" />
   <meta name="description" content="Beta Thread SVG Editor and SVG Viewer for SVG files and images with syntax highlighting and other features" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>

<div class="row">
<div class="side">
<h1>Try it yourself</h1>

<div id="editor" contenteditable="true" aria-autocomplete="true">
 
<SyntaxHighlighterComponent code={code} language="html" />
   
</div>
<button onClick={runcode}>Run Code</button>
<SaveCodeButton code={code} />
</div>
<div class="main">
<h2>Output:</h2>
<div id="output">
    <svg xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 576 512" 
    width="25px" 
    height="20px">
    <path fill="#00008b"
    d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/>
     </svg>
     </div>
</div>
</div>
</div>
)
}