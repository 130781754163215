import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPString() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    $string1 = "Hello";
    $string2 = "World";
    $result = $string1 . " " . $string2; // Concatenating two strings with a space in between
    echo $result; // Output: Hello World
             `;
    const code2 = `
    $string = "Hello";
    $string .= " World"; // Appending " World" to the end of $string
    echo $string; // Output: Hello World
            `;
    const code3 = `
    $string1 = "Hello";
    $string2 = "hello";
    if ($string1 == $string2) {
        echo "Strings are equal";
    } else {
        echo "Strings are not equal";
    }
    // Output: Strings are not equal
            `;     
    const code4 = `
    $string1 = "Hello";
    $string2 = "Hello";
    if ($string1 === $string2) {
        echo "Strings are identical";
    } else {
        echo "Strings are not identical";
    }
    // Output: Strings are identical
            `;
    const code5 = `
    $string1 = "Hello";
    $string2 = "hello";
    if ($string1 != $string2) {
        echo "Strings are not equal";
    } else {
        echo "Strings are equal";
    }
    // Output: Strings are not equal
            `;
 
    const code6 = `
    $string1 = "Hello";
    $string2 = "Hello";
    if ($string1 !== $string2) {
        echo "Strings are not identical";
    } else {
        echo "Strings are identical";
    }
    // Output: Strings are not identical
               `;         
    return (
   <body>
      <Helmet>
        <title>PHP String Operators</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Operators, PHP String Operators, Concatenation Operator, Concatenation Assignment Operator, Equality Operator, Identity Operator, Not Equal Operator, Not Identical Operator,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="PHP decrement operators are used to decrease the value of a variable by one. It makes use of the '--' character at the beginning or the end of the variable." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_decrement"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_array"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP String Operators</h1>
</header>

<Navbar />

<main>
<section>
<p>PHP string operators are used to manipulate and concatenate strings, which are sequences of characters enclosed within single quotes (' ') or double quotes (" ").</p>
<p>These operators allow you to perform various operations such as concatenation, assignment, comparison, and manipulation on strings.</p>
<p>Below are some of the most commonly used string operators in PHP:</p>
<ul>
    <li>Concatenation Operator (.)</li>
    <li>Concatenation Assignment Operator (.=)</li>
    <li>Equality Operator (==)</li>
    <li>Identity Operator (===)</li>
    <li>Not Equal Operator (!= or &lt;&gt;)</li>
    <li>Not Identical Operator (!==)</li>
</ul>
<h2>Concatenation Operator (.)</h2>
<p>The concatenation operator ('.') is used to concatenate two strings together. </p>
<p>It simply combines two strings into a single string.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="php" />

<h2>Concatenation Assignment Operator (.=)</h2>
<p>The concatenation assignment operator ('.=') is used to append a string to the end of another string.</p>
<p>It is equivalent to performing concatenation followed by assignment.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="php" />

<h2>Equality Operator (==)</h2>
<p>The equality operator (==) compares two strings for equality, ignoring their data types. </p>
<p>It returns true if the strings have the same value.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code3} language="php" />

<h2>Identity Operator (===)</h2>
<p>The identity operator (===) compares two strings for equality, including their data types. </p>
<p>It returns true if the strings have the same value and data type.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code4} language="php" />

<h2>Not Equal Operator (!= or &lt;&gt;)</h2>
<p>The not equal operator (!= or &lt;&gt;) compares two strings for inequality. </p>
<p>It returns true if the strings do not have the same value.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code5} language="php" />

<h2>Not Identical Operator (!==)</h2>
<p>The not identical operator (!==) compares two strings for inequality, including their data types.</p>
<p> It returns true if the strings do not have the same value or data type.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code6} language="php" />


</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_decrement"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_array"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}