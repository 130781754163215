import React from 'react';
import '../css/my style.css';
import '../css/my style2.css';
import '../css/my style6.css';


 function Header() {
   
const headerServices = {
    padding: '140px',
    textAlign: 'center',
    background: 'darkblue',
    textAlign: 'center'
}
 const headerServicesh1 = {
    fontSize: '50px',
    color: 'white',
    textAlign: 'center'
 }
    return (
    <header style={headerServices}>
           <div className="header-content">
     <div className="centered-content">
   <h1 className="topple" style={headerServicesh1}>
            <span>O</span>
            <span>u</span>
            <span>r</span>
            <br />
            <span>S</span>
            <span>e</span>
            <span>r</span>
            <span>v</span>
            <span>i</span>
            <span>c</span>
            <span>e</span>
            <span>s</span>
  
  </h1>
  </div>
  </div>
      </header>
    );
}
export default Header;