import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLNestedQueries() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const nestedQueryCode = `
-- Basic Nested Query
SELECT employee_id, employee_name
FROM employees
WHERE department_id IN (
    SELECT department_id
    FROM departments
    WHERE location_id = 1700
);
  `;

  const nestedWithJoinCode = `
-- Nested Query with JOIN
SELECT e.employee_id, e.employee_name
FROM employees e
JOIN (
    SELECT department_id
    FROM departments
    WHERE location_id = 1700
) d ON e.department_id = d.department_id;
  `;

  const nestedInSelectCode = `
-- Nested Query in SELECT
SELECT employee_name, 
    (SELECT department_name FROM departments WHERE departments.department_id = employees.department_id) AS department_name
FROM employees;
  `;

  return (
    <body>
      <Helmet>
        <title>SQL Nested Queries</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL Nested Queries, SQL Subqueries, SQL Tutorial, Nested Query Examples" />
        <meta name="description" content="Learn about SQL nested queries, including syntax, examples, and using nested queries with JOIN and in SELECT statements." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_date-time_functions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_correlated"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL Nested Queries</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>SQL nested queries, also known as subqueries, are queries within another SQL query. They are useful for performing complex queries that need intermediate results.</p>

            <h2>Basic Nested Query</h2>
            <p>A basic nested query is one where the result of an inner query is used by an outer query.</p>
            <SyntaxHighlighterComponent code={nestedQueryCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The inner query retrieves <code>department_id</code> values where <code>location_id</code> is 1700.</li>
              <li>The outer query uses these values to filter employees who belong to those departments.</li>
            </ul>

            <h2>Nested Query with JOIN</h2>
            <p>You can combine nested queries with JOIN clauses to retrieve more complex datasets.</p>
            <SyntaxHighlighterComponent code={nestedWithJoinCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The inner query selects <code>department_id</code> values where <code>location_id</code> is 1700.</li>
              <li>The outer query joins the employees table with the result of the inner query to filter employees.</li>
            </ul>

            <h2>Nested Query in SELECT</h2>
            <p>You can use nested queries within the SELECT clause to include computed columns in the result set.</p>
            <SyntaxHighlighterComponent code={nestedInSelectCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The nested query within the SELECT clause retrieves the <code>department_name</code> for each employee.</li>
              <li>The outer query selects employee names and their corresponding department names.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_date-time_functions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_correlated"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
