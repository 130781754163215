import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function AICNNs() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const cnnExampleCode = `
import tensorflow as tf
from tensorflow.keras import layers, models

model = models.Sequential()
model.add(layers.Conv2D(32, (3, 3), activation='relu', input_shape=(28, 28, 1)))
model.add(layers.MaxPooling2D((2, 2)))
model.add(layers.Conv2D(64, (3, 3), activation='relu'))
model.add(layers.MaxPooling2D((2, 2)))
model.add(layers.Conv2D(64, (3, 3), activation='relu'))

model.add(layers.Flatten())
model.add(layers.Dense(64, activation='relu'))
model.add(layers.Dense(10, activation='softmax'))

model.compile(optimizer='adam', loss='sparse_categorical_crossentropy', metrics=['accuracy'])

# Display the model's architecture
model.summary()
  `;

  const cnnTrainingCode = `
# Assume X_train, y_train, X_test, and y_test are already defined and preprocessed
history = model.fit(X_train, y_train, epochs=10, validation_data=(X_test, y_test))

# Evaluate the model
test_loss, test_acc = model.evaluate(X_test, y_test)
print(f'Test accuracy: {test_acc}')
  `;

  return (
    <body>
      <Helmet>
        <title>AI Convolutional Neural Networks</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="CNNs, AI Convolutional Neural Networks, Deep Learning, AI Tutorial" />
        <meta name="description" content="Learn about Convolutional Neural Networks (CNNs), their architecture, and how to implement them using TensorFlow and Keras with practical examples." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_deep_learning"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_rnns"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>AI Convolutional Neural Networks</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Convolutional Neural Networks (CNNs) are a class of deep neural networks, most commonly applied to analyzing visual imagery. They are designed to automatically and adaptively learn spatial hierarchies of features from input images.</p>

            <h2>Architecture of CNNs</h2>
            <p>CNNs consist of several layers, primarily:</p>
            <ul>
              <li><strong>Convolutional Layers:</strong> Apply a convolution operation to the input, passing the result to the next layer.</li>
              <li><strong>Pooling Layers:</strong> Perform a down-sampling operation along the spatial dimensions (width, height), resulting in a reduced volume.</li>
              <li><strong>Fully Connected Layers:</strong> Flatten the input and pass it through one or more fully connected layers to make predictions.</li>
            </ul>

            <h2>Example: Creating a CNN with TensorFlow and Keras</h2>
            <SyntaxHighlighterComponent code={cnnExampleCode} language="python" />
            <p>This code defines a simple CNN using TensorFlow and Keras. The model includes convolutional layers, pooling layers, and dense layers.</p>

            <h2>Training the CNN</h2>
            <SyntaxHighlighterComponent code={cnnTrainingCode} language="python" />
            <p>This code demonstrates how to train the CNN model on a dataset. It assumes that <code>X_train</code>, <code>y_train</code>, <code>X_test</code>, and <code>y_test</code> are already defined and preprocessed.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_deep_learning"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_rnns"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
