import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import SQLMetatag from "../../components/SQL_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function SQLComments() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    -- I am a single-line comments
    `;
   
    const code2 = `
    /* I am a multi-line comment
       I am a multi-line comment
       I am a multi-line comment
       I am a multi-line comment */
    `;
  
    return (
   <body>
    <Helmet>
        
        <title>SQL Comments</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="SQL comments, Step-by-step SQL programming tutorials SQL coding exercises for beginners, Advanced SQL coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       SQL programming tips for real-world applications," />
      <meta name="description" content="Learn how SQL comments streamline code organization, 
      enhance readability, and unlock advanced features in PHP development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/SQL_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/SQL_queries"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>SQL Comments</h1>
</header>

<Navbar />

<main>
<section>
<p>SQL comments are used to provide a description of the code which you have written.</p>
<p>Comments are used to explain the purpose of the queries which are going to be executed by the compiler.</p>
<p>SQL comments are not executed by the compiler instead it is ignored.</p>
<p>In SQL there are two ways of writing comments</p>
<li>Single-line comments</li>
<li>Multi-line comments</li>
<br />
<h2>Single-line Comments</h2>
<p>A single-line comment begins with two hyphens (--).</p>
<SyntaxHighlighterComponent code={code} language="sql" />

<h2>Multi-line Comments</h2>
<p>A multi-line comment begins with a forward slash and an asterisk /* and it ends with another */.</p>
<SyntaxHighlighterComponent code={code2} language="sql" />
</section>
</main>
<div className="head">
<ScrollToTopLink to="/SQL_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/SQL_queries"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}