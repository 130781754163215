import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLblock_inline() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
   <div>I start a new line</div>
   <h1>I start a new line</h1>
   <h2>I start a new line</h2>
   <p>I start a new line</p>
  `;

  const code2 = `
   <span>I do not start a new line</span>
   <a href="#">I do not start a new line</a>
`;
  

  
    return (
   <body>
     <Helmet>    
        <title>HTML Block-Level and Inline Elements</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Block-Level and Inline Elements, Block-Level Elements, Examples of Block-Level Elements, Inline Elements, Examples of inline elements,
         Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
      <meta name="description" content="A block-level element in HTML takes the full width available and it starts on a new line.
        Usually block-level elements creates a horizontal boundary block. An inline elements in HTML does not start on a new line and they also do not create a horizontal block." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_image"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_subscript"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Block-Level and Inline Elements</h1>
</header>

<Navbar />

<main>

<section>
<h2>Block-Level Elements</h2>
<p>A block-level element in HTML takes the full width available and it starts on a new line.</p>
<p>Usually block-level elements creates a horizontal boundary block.</p>
<p>Block-Level elements are often used to nest content,information or other elements e.g,the &lt;div&gt; element can be used to nest the &lt;p&gt; element.</p>
<h3>Examples of Block-Level Elements</h3>
<li>&lt;div&gt; tag</li>
<li>&lt;h1&gt;-&lt;h6&gt; tags</li>
<li>&lt;p&gt; tag</li>
<li>&lt;blockquote&gt; tag</li>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html43"><Tryitout /></ScrollToTopLink>

<h2>Inline Elements</h2>
<p>An inline elements in HTML does not start on a new line and they also do not create a horizontal block.</p>
<h3>Examples of inline elements</h3>
<li>&lt;span&gt; tag</li>
<li>&lt;img&gt; tag</li>
<li>&lt;a&gt; tag</li>
<br />

    <SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html44"><Tryitout /></ScrollToTopLink>

</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_image"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_subscript"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}