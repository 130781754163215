import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import XMLMetatag from "../../components/XML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function XMLSyntax() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    <book>
    <title>Harry Potter</title>
    <author>J.K. Rowling</author>
    </book>
    `;
    const code2 = `
    <book category="fantasy">
    <title>Harry Potter</title>
    <author>J.K. Rowling</author>
    </book>

    `;
    const code3 = `
    <library>
    <book>
        <title>War and Peace</title>
        <author>Leo Tolstoy</author>
    </book>
    <book>
        <title>Pride and Prejudice</title>
        <author>Jane Austen</author>
    </book>
    </library>
    `;
    const code4 = `
    <image source="photo.jpg">
    `;
    const code5 = `
    <!-- I am a comment -->
    `;
    return (
   <body>
     <Helmet>
        
        <title>XML Syntax</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="XML Syntax, XML Step-by-step XML programming tutorials, XML coding exercises for beginners, Advanced XML coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       XML programming tips for real-world applications." />
      <meta name="description" content="Learn how XML syntax streamline code organization, 
      enhance readability, and unlock advanced features in PHP development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/XML_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/XML_tree"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>XML Syntax</h1>
</header>

<Navbar />

<main>
<section>
<p>XML (eXtensible Markup Language) has a simple and consistent syntax that revolves around the use
     of tags and elements to structure data.</p>
<p>The syntax follows specific rules to create well-formed XML documents. Let's dive into the 
    details of XML syntax
</p>
<h2>Tags</h2>
<p>In XML, tags are used to define elements and their boundaries. There are two types of tags:</p>
<li><b>Start Tags:</b><br />
 <p>These open a new element and are enclosed in angle brackets (&lt; and &gt;),for example&lt;book&gt;.</p>
</li>
<li><b>End Tags:</b><br />
<p>These close an element and are also enclosed in angle brackets, but include a forward slash (&lt;/ and &gt;)
    for example, &lt;/book&gt;
</p>
</li>

<h2>Elements</h2>
<p>Elements are the building blocks of an XML document. </p>
<p>They consist of a start tag, content (which can be text, nested elements, or a combination), and an end tag.</p>
  <br />
  <h2>Example:</h2>
  <SyntaxHighlighterComponent code={code} language="xml" />

      <h2>Attributes</h2>
        <p>Attributes provide additional information about an element. </p>
        <p>They're part of the start tag and consist of a name-value pair.</p>
        
        <br />
        <h2>Example:</h2>
        <SyntaxHighlighterComponent code={code2} language="xml" />

        <br />
      
<h2>Nesting</h2>
<p>XML elements can be nested within each other, creating a hierarchical structure.  </p>
<p>This nesting is fundamental to organizing and representing complex data.</p>

<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code3} language="xml" />
<br />
  <h2>Empty Elements</h2>
  <p>Some elements don't have content and can be self-closed using a forward slash within the start tag.</p>
    <br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code4} language="xml" />
<br />
  <h2>Comments</h2>
  <p>Comments are used to provide explanations or notes within an XML document.
     They start with <code>&lt;!--</code> and end with <code>--&gt;</code>.</p>
    <br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code5} language="xml" />

    <br />
    <h2>Case Sensitivity</h2>
    <p>XML is case-sensitive. Element names, attribute names, and values must be written with 
        consistent capitalization.</p>
      <br />
  <h2>Whitespace</h2>
  <p>Whitespace (spaces, tabs, line breaks) is generally ignored in XML. However, it's used to 
    format the document for readability.</p>
    <p> Whitespace within tags can be significant in specific contexts.</p>
    <br />
    <h2>Well-Formedness</h2>
    <p>For an XML document to be considered well-formed, it must adhere to the basic syntax rules.</p>
    <p>This includes having a single root element, correctly nested elements, and properly closed tags.</p>
    <br />
    <p>XML's syntax is designed to be human-readable and machine-parseable</p>
    <p>This makes it a reliable format for data representation, exchange, and storage.</p>
    

</section>
</main>
<div className="head">
<ScrollToTopLink to="/XML_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/XML_tree"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}