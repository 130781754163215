import Header from "../components/Header-ai";
import Footer from "../components/Footer";
import Navbar from "../components/navbar";
import Topnav from "../components/topnav";
import '../css/my style.css';
import '../css/my style2.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { Helmet } from 'react-helmet';
import ChatBots from "../Chatbot";
import ChatBot from 'react-simple-chatbot';
import { getAuthStatus, saveAuthStatus } from "../localstorage";


export default function BetaAI() {
  const newCode = {
    background: 'darkblue'
   }
   var h2 = {
    textAlign: 'center'
 }
 
 const [authenticated, setAuthenticated] = useState(false);
 const navigate = useNavigate(); // Move useNavigate here

 useEffect(() => {
   const checkAuthentication = () => {
     const isAuthenticated = getAuthStatus('_id');
     if (!isAuthenticated) {
       navigate("/login");
     } else {
       setAuthenticated(true);
     }
   };

   checkAuthentication();
 }, [navigate]);

    return (
        <body>
        <Helmet>
      
        <title>Beta AI | Beta Thread</title>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Beta Thread's AI Software, Beta AI, How to use AI, Beta Assistant, BetaGPT, AI Software, Contact us, HTML, Python, CSS, SQL, JavaScript, How to, PHP, XML, MySQL, React, AI, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, Examples, Learn to code, Coding the future, Source code, Website
    C++ programming tips for real-world applications," />
    <meta name="description" content="Unlock innovation with Beta Thread's AI Software: Elevating experiences, Creating content, driving efficiency, and shaping the future of technology." />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />
    
        </Helmet>
         <br />
        <br />
        <br />
        <br />
        <br />
        <Topnav />
       
        <Navbar />
        <section>
      
      
      <ChatBots />
     
        </section>

        <Footer />
        </body>
    )
}