import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyProcsLambda() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const procBasicCode = `
# Creating a Proc
my_proc = Proc.new { |name| puts "Hello, \#{name}!" }
my_proc.call("Alice")
# Output: Hello, Alice!
  `;

  const lambdaBasicCode = `
# Creating a Lambda
my_lambda = ->(name) { puts "Hello, \#{name}!" }
my_lambda.call("Bob")
# Output: Hello, Bob!
  `;

  const procVsLambdaCode = `
# Proc vs Lambda Differences
# Return behavior
def proc_example
  my_proc = Proc.new { return "Proc returns from the method" }
  my_proc.call
  "This line will never be reached"
end

def lambda_example
  my_lambda = -> { return "Lambda returns from the lambda" }
  result = my_lambda.call
  "Lambda returns: \#{result}"
end

puts proc_example
puts lambda_example
# Output: Proc returns from the method
# Output: Lambda returns: Lambda returns from the lambda
  `;

  const lambdaArityCode = `
# Lambda Arity
my_lambda = ->(a, b) { a + b }
puts my_lambda.call(2, 3)
# Output: 5
# Uncommenting the following line will raise an ArgumentError
# puts my_lambda.call(2)
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Procs and Lambda</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Procs, Ruby Lambda, Proc vs Lambda, Lambda Arity" />
        <meta name="description" content="Learn about Procs and Lambda in Ruby, including their differences, creation, and usage." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_blocks"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_arrays"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Procs and Lambda</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Procs and lambdas in Ruby are objects that can hold blocks of code, which can be stored in a variable and passed around like any other object.</p>

            <h2>Creating a Proc</h2>
            <p>A Proc object can be created using <code>Proc.new</code> or the <code>proc</code> method, and it can be called using the <code>call</code> method.</p>
            <SyntaxHighlighterComponent code={procBasicCode} language="perl" />
            <p>In the above example, <code>my_proc</code> is a Proc that takes a parameter <code>name</code> and prints a greeting.</p>

            <h2>Creating a Lambda</h2>
            <p>A lambda is a type of Proc that is created using the <code>-&gt;</code> syntax or the <code>lambda</code> method, and it can be called using the <code>call</code> method.</p>
            <SyntaxHighlighterComponent code={lambdaBasicCode} language="perl" />
            <p>In the above example, <code>my_lambda</code> is a lambda that takes a parameter <code>name</code> and prints a greeting.</p>

            <h2>Proc vs Lambda Differences</h2>
            <p>While both Procs and lambdas are similar, they have some key differences:</p>
            <ul>
              <li><strong>Return Behavior:</strong> A lambda returns control to the calling method, whereas a Proc returns immediately from the enclosing method.</li>
              <li><strong>Argument Checking:</strong> Lambdas check the number of arguments passed to them, while Procs do not.</li>
            </ul>
            <SyntaxHighlighterComponent code={procVsLambdaCode} language="perl" />
            <p>In the above example, the <code>proc_example</code> method demonstrates how a Proc returns from the enclosing method, while the <code>lambda_example</code> method shows how a lambda returns only from the lambda itself.</p>

            <h2>Lambda Arity</h2>
            <p>Lambdas enforce the number of arguments passed to them (arity), raising an error if the wrong number of arguments is given.</p>
            <SyntaxHighlighterComponent code={lambdaArityCode} language="ruby" />
            <p>In the above example, the lambda <code>my_lambda</code> takes two parameters. Calling it with the correct number of arguments returns the sum, while calling it with the wrong number of arguments raises an error.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_blocks"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_arrays"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
