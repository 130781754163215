export const rubyData = [
  {
    question: "What is Ruby?",
    answer: "Ruby is a dynamic, object-oriented programming language known for its simplicity and productivity."
  },
  {
    question: "Ruby",
    answer: "Ruby is a dynamic, object-oriented programming language known for its simplicity and productivity."
  },
  {
    question: "Who created Ruby?",
    answer: "Ruby was created by Yukihiro Matsumoto, also known as Matz, in the mid-1990s."
  },
  {
    question: "What are some key features of Ruby?",
    answer: "Some key features of Ruby include its object-oriented nature, dynamic typing, and automatic memory management."
  },
  {
    question: "What is the current version of Ruby?",
    answer: "As of now, the latest stable version of Ruby is Ruby 3.x, with ongoing development."
  },
  {
    question: "What is the RubyGems package manager?",
    answer: "RubyGems is a package manager for Ruby that provides a standard format for distributing Ruby programs and libraries."
  },
  {
    question: "What is Rails?",
    answer: "Rails is a web application framework written in Ruby. It provides structures for databases, web pages, and web services."
  },
  {
    question: "Who developed Ruby on Rails?",
    answer: "Ruby on Rails was created by David Heinemeier Hansson, also known as DHH, in 2003."
  },
  {
    question: "What are some popular Ruby frameworks other than Rails?",
    answer: "Some popular Ruby frameworks include Sinatra, Hanami, and Padrino."
  },
  {
    question: "What is the significance of 'gems' in Ruby?",
    answer: "'Gems' in Ruby refer to packages or libraries that extend the functionality of Ruby. They can be easily installed and managed using RubyGems."
  },
  {
    question: "What is the difference between 'puts' and 'print' in Ruby?",
    answer: "'puts' adds a newline after printing its argument, while 'print' does not."
  },
  {
    question: "What is a symbol in Ruby?",
    answer: "A symbol in Ruby is an immutable identifier represented by a colon followed by a name, like :symbol."
  },
  {
    question: "What is the 'nil' value in Ruby?",
    answer: "'nil' in Ruby represents the absence of a value or a null value."
  },
  {
    question: "What is a block in Ruby?",
    answer: "A block in Ruby is a chunk of code enclosed within do..end or curly braces {...} that can be passed to methods."
  },
  {
    question: "What is the purpose of 'yield' in Ruby?",
    answer: "'yield' is used within a method to call a block that was passed to it."
  },
  {
    question: "What is the 'attr_accessor' method in Ruby?",
    answer: "'attr_accessor' is a Ruby method used to define getter and setter methods for instance variables."
  },
  {
    question: "What is the difference between 'attr_reader', 'attr_writer', and 'attr_accessor' in Ruby?",
    answer: "'attr_reader' defines only a getter method, 'attr_writer' defines only a setter method, and 'attr_accessor' defines both getter and setter methods."
  },
  {
    question: "What does 'self' refer to in Ruby?",
    answer: "'self' refers to the current object or the current instance of a class in Ruby."
  },
  {
    question: "What is a module in Ruby?",
    answer: "A module in Ruby is a collection of methods and constants. It cannot be instantiated or inherited."
  },
  {
    question: "How do you include a module in a class in Ruby?",
    answer: "You include a module in a class using the 'include' keyword followed by the module name."
  },
  {
    question: "What is the difference between 'include' and 'extend' in Ruby?",
    answer: "'include' is used to mix a module's methods into a class as instance methods, while 'extend' is used to mix a module's methods into a class as class methods."
  },
  {
    question: "What is metaprogramming in Ruby?",
    answer: "Metaprogramming in Ruby refers to the technique of writing code that can modify itself or other code during runtime."
  },
  {
    question: "What is a gemfile in Ruby?",
    answer: "A Gemfile in Ruby is a file used to specify the dependencies of a Ruby project. It's commonly used with Bundler to manage gem dependencies."
  },
  {
    question: "What is a lambda in Ruby?",
    answer: "A lambda in Ruby is a way to define an anonymous function or block of code that can be passed around like an object."
  },
  {
    question: "What is the '&&' operator used for in Ruby?",
    answer: "The '&&' operator in Ruby is a logical operator used for 'and' operations. It returns true if both operands are true."
  },
  {
    question: "What is the '||' operator used for in Ruby?",
    answer: "The '||' operator in Ruby is a logical operator used for 'or' operations. It returns true if at least one operand is true."
  },
  {
    question: "What is a Proc in Ruby?",
    answer: "A Proc in Ruby is an object that encapsulates a block of code. It can be stored in a variable and passed around like any other object."
  },
  {
    question: "What is the 'yield_self' method in Ruby?",
    answer: "'yield_self' is a method in Ruby that yields the object to a block and returns the result of the block."
  },
  {
    question: "What is the 'singleton_class' method in Ruby?",
    answer: "The 'singleton_class' method in Ruby returns the singleton class of an object, which is a class specific to that object."
  },
  {
    question: "What is the 'module_function' method in Ruby?",
    answer: "'module_function' is a method in Ruby that allows you to make methods from a module available as module methods."
  },
  {
    question: "What is 'duck typing' in Ruby?",
    answer: "'Duck typing' in Ruby refers to the practice of not explicitly checking the type of an object but instead relying on the presence of certain methods or behaviors."
  },
  {
    question: "What is the 'to_proc' method in Ruby?",
    answer: "The 'to_proc' method in Ruby converts an object into a Proc, allowing it to be used as a block."
  },
  {
    question: "What is the 'singleton_method_added' callback in Ruby?",
    answer: "'singleton_method_added' is a callback in Ruby that gets triggered when a singleton method is added to an object."
  },
  {
    question: "What is the 'respond_to_missing?' method in Ruby?",
    answer: "'respond_to_missing?' is a method in Ruby used to determine if an object responds to a method that is not explicitly defined."
  },
  {
    question: "What is the 'autoload' method in Ruby?",
    answer: "'autoload' is a method in Ruby used to automatically load classes and modules when they are first referenced."
  },
  {
    question: "What is 'parallel assignment' in Ruby?",
    answer: "'Parallel assignment' in Ruby allows you to assign multiple variables at once using a single statement."
  },
  {
    question: "What is a 'gemspec' file in Ruby?",
    answer: "A 'gemspec' file in Ruby is a specification file used to define metadata and dependencies for a gem."
  },
  {
    question: "What is the purpose of 'Rake' in Ruby?",
    answer: "'Rake' is a build automation tool written in Ruby. It's similar to 'make' but uses a Ruby syntax for its tasks."
  },
  {
    question: "What is the 'Kernel' module in Ruby?",
    answer: "The 'Kernel' module in Ruby is a module that is automatically included in the scope of every Ruby program. It provides core methods like 'puts' and 'gets'."
  },
  {
    question: "What is the 'require' method in Ruby?",
    answer: "'require' is a method in Ruby used to load external libraries or files."
  },
  {
    question: "What is the 'load' method in Ruby?",
    answer: "'load' is a method in Ruby used to execute Ruby code from a file."
  },
  {
    question: "What is the 'Time' class in Ruby?",
    answer: "The 'Time' class in Ruby is a built-in class used to represent dates and times."
  },
  {
    question: "What is the 'Array' class in Ruby?",
    answer: "The 'Array' class in Ruby is a built-in class used to represent ordered collections of objects."
  },
  {
    question: "What is the 'Hash' class in Ruby?",
    answer: "The 'Hash' class in Ruby is a built-in class used to represent collections of key-value pairs."
  },
  {
    question: "What is the 'String' class in Ruby?",
    answer: "The 'String' class in Ruby is a built-in class used to represent sequences of characters."
  },
  {
    question: "What is the 'Enumerable' module in Ruby?",
    answer: "The 'Enumerable' module in Ruby provides methods to traverse and manipulate collections like arrays and hashes."
  },
  {
    question: "What is the 'Comparable' module in Ruby?",
    answer: "The 'Comparable' module in Ruby is a mixin that provides comparison methods like '<', '<=', '==', etc., based on the '<=>' method."
  },
  {
    question: "What is the 'IO' class in Ruby?",
    answer: "The 'IO' class in Ruby is a built-in class used for input and output operations."
  },
  {
    question: "What is the 'File' class in Ruby?",
    answer: "The 'File' class in Ruby is a built-in class used to perform file operations like reading, writing, and manipulating files."
  },
  {
    question: "What is the 'Range' class in Ruby?",
    answer: "The 'Range' class in Ruby is a built-in class used to represent a range of values."
  },
  {
    question: "What is the 'Enumerator' class in Ruby?",
    answer: "The 'Enumerator' class in Ruby is a built-in class used to represent an external iterator."
  },
  {
    question: "What is the 'Struct' class in Ruby?",
    answer: "The 'Struct' class in Ruby is a built-in class used to create classes with predetermined attributes."
  },
  {
    question: "What is the 'BigDecimal' class in Ruby?",
    answer: "The 'BigDecimal' class in Ruby is a built-in class used to perform arbitrary-precision arithmetic."
  },
  {
    question: "What is the 'URI' module in Ruby?",
    answer: "The 'URI' module in Ruby is a built-in module used to parse and manipulate URIs."
  },
  {
    question: "What is the 'Regexp' class in Ruby?",
    answer: "The 'Regexp' class in Ruby is a built-in class used to represent regular expressions."
  },
  {
    question: "What is the 'Exception' class in Ruby?",
    answer: "The 'Exception' class in Ruby is a built-in class used to handle errors and exceptions."
  },
  {
    question: "What is the 'Kernel#loop' method in Ruby?",
    answer: "'Kernel#loop' is a method in Ruby used to repeatedly execute a block of code."
  },
  {
    question: "What is the 'Kernel#exit' method in Ruby?",
    answer: "'Kernel#exit' is a method in Ruby used to terminate the program."
  },
  {
    question: "What is the 'Kernel#abort' method in Ruby?",
    answer: "'Kernel#abort' is a method in Ruby used to terminate the program with an error message."
  },
  {
    question: "What is the 'BEGIN' keyword in Ruby?",
    answer: "The 'BEGIN' keyword in Ruby is used to define code that will be executed before the program runs."
  },
  {
    question: "What is the 'END' keyword in Ruby?",
    answer: "The 'END' keyword in Ruby is used to define code that will be executed after the program runs."
  },
  {
    question: "What is the 'alias' keyword in Ruby?",
    answer: "The 'alias' keyword in Ruby is used to create an alias for a method."
  },
  {
    question: "What is the 'undef' keyword in Ruby?",
    answer: "The 'undef' keyword in Ruby is used to remove a method definition."
  },
  {
    question: "What is the purpose of 'Module#prepend' in Ruby?",
    answer: "'Module#prepend' in Ruby is used to insert a module into the inheritance chain of a class, making its methods override those of the class."
  },
  {
    question: "What is the 'then' keyword used for in Ruby?",
    answer: "The 'then' keyword in Ruby is optional and is used to separate the condition of an 'if' statement from the code that follows."
  },
  {
    question: "What is the 'redo' keyword used for in Ruby?",
    answer: "The 'redo' keyword in Ruby is used to restart the current iteration of a loop."
  },
  {
    question: "What is the 'retry' keyword used for in Ruby?",
    answer: "The 'retry' keyword in Ruby is used to restart a loop from the beginning."
  },
  {
    question: "What is the purpose of 'Module#refine' in Ruby?",
    answer: "'Module#refine' in Ruby is used to selectively modify methods within a class without affecting other parts of the program."
  },
  {
    question: "What is the 'BasicObject' class in Ruby?",
    answer: "The 'BasicObject' class in Ruby is the root of the Ruby object hierarchy. It defines the basic methods available to all objects."
  },
  {
    question: "What is the purpose of 'super' in Ruby?",
    answer: "'super' in Ruby is used to call the superclass's implementation of a method."
  },
  {
    question: "What is the 'public', 'private', and 'protected' access control keywords in Ruby?",
    answer: "'public', 'private', and 'protected' are access control keywords in Ruby used to define the visibility of methods within a class."
  },
  {
    question: "What is 'instance_eval' used for in Ruby?",
    answer: "'instance_eval' in Ruby is used to evaluate a block within the context of a specific object."
  },
  {
    question: "What is 'class_eval' used for in Ruby?",
    answer: "'class_eval' in Ruby is used to evaluate a block within the context of a specific class."
  },
  {
    question: "What is 'method_missing' used for in Ruby?",
    answer: "'method_missing' in Ruby is a method called when a method is invoked on an object that does not exist."
  },
  {
    question: "What is the 'class_variable_get' method in Ruby?",
    answer: "'class_variable_get' is a method in Ruby used to retrieve the value of a class variable."
  },
  {
    question: "What is the 'class_variable_set' method in Ruby?",
    answer: "'class_variable_set' is a method in Ruby used to set the value of a class variable."
  },
  {
    question: "What is the 'instance_variable_get' method in Ruby?",
    answer: "'instance_variable_get' is a method in Ruby used to retrieve the value of an instance variable."
  },
  {
    question: "What is the 'instance_variable_set' method in Ruby?",
    answer: "'instance_variable_set' is a method in Ruby used to set the value of an instance variable."
  },
  {
    question: "What is the 'binding' object in Ruby?",
    answer: "The 'binding' object in Ruby encapsulates the execution context, including local variables and methods, allowing them to be passed around as objects."
  },
  {
    question: "What is 'const_get' used for in Ruby?",
    answer: "'const_get' in Ruby is used to retrieve the value of a constant."
  },
  {
    question: "What is 'const_set' used for in Ruby?",
    answer: "'const_set' in Ruby is used to set the value of a constant."
  },
  {
    question: "What is 'const_defined?' used for in Ruby?",
    answer: "'const_defined?' in Ruby is used to check if a constant is defined."
  },
  {
    question: "What is the 'send' method in Ruby?",
    answer: "'send' in Ruby is used to dynamically invoke a method on an object."
  },
  {
    question: "What is the 'public_send' method in Ruby?",
    answer: "'public_send' in Ruby is similar to 'send' but only allows public methods to be called."
  },
  {
    question: "What is the 'method' method in Ruby?",
    answer: "'method' in Ruby is used to retrieve a Method object representing a method defined on an object."
  },
  {
    question: "What is the 'ancestors' method in Ruby?",
    answer: "'ancestors' in Ruby is a method that returns an array containing the ancestors of a class or module."
  },
  {
    question: "What is the 'included_modules' method in Ruby?",
    answer: "'included_modules' in Ruby is a method that returns an array containing the modules included in a class or module."
  },
  {
    question: "What is the 'class_variables' method in Ruby?",
    answer: "'class_variables' in Ruby is a method that returns an array containing the names of class variables in a class."
  },
  {
    question: "What is the 'instance_variables' method in Ruby?",
    answer: "'instance_variables' in Ruby is a method that returns an array containing the names of instance variables in an object."
  },
  {
    question: "What is the 'defined?' keyword used for in Ruby?",
    answer: "'defined?' in Ruby is used to check if a variable, method, or constant is defined."
  },
  {
    question: "What is the 'alias_method' method in Ruby?",
    answer: "'alias_method' in Ruby is used to create an alias for a method."
  },
  {
    question: "What is the purpose of 'Module#method_defined?' in Ruby?",
    answer: "'Module#method_defined?' in Ruby is used to check if a method is defined within a module."
  },
  {
    question: "What is the purpose of 'Module#module_function' in Ruby?",
    answer: "'Module#module_function' in Ruby is used to make a method both an instance method and a module method."
  },
  {
    question: "What is the 'Module#included' callback in Ruby?",
    answer: "'Module#included' in Ruby is a callback that gets triggered when a module is included in another module or class."
  },
  {
    question: "What is the 'Module#extended' callback in Ruby?",
    answer: "'Module#extended' in Ruby is a callback that gets triggered when a module is extended by another module or object."
  },
  {
    question: "What is the 'Module#prepended' callback in Ruby?",
    answer: "'Module#prepended' in Ruby is a callback that gets triggered when a module is prepended to another module or class."
  },
  {
    question: "What is the 'autoload?' method in Ruby?",
    answer: "'autoload?' in Ruby is a method used to check if autoloading is enabled for a particular constant."
  },
  {
    question: "What is the 'autoload' method used for in Ruby?",
    answer: "'autoload' in Ruby is used to automatically load a file when a constant is referenced for the first time."
  },
  {
    question: "What is the 'autoload_constant' method in Ruby?",
    answer: "'autoload_constant' in Ruby is a method used to retrieve the value of an autoload constant."
  },
  {
    question: "What is the 'autoload?' method in Ruby?",
    answer: "'autoload?' in Ruby is a method used to check if autoloading is enabled for a particular constant."
  },
  {
    question: "What is the 'autoload' method used for in Ruby?",
    answer: "'autoload' in Ruby is used to automatically load a file when a constant is referenced for the first time."
  }
];
