import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/AISidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import AIMetatag from "../../components/AI_Metatag";
import { Helmet } from "react-helmet";
import ScrollToTopLink from "../../components/ScrollToTop"; // Import the ScrollToTopLink component

export default function AITypes() {
  var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  }
  var secondCode = {
    color: 'black'
  }
  var thirdCode = {
    padding: '20px'
  }
  const code = `
  SELECT first_name FROM users;
  `;

  return (
    <body>
         <Helmet>
     <title>AI Types</title>
     <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Narrow AI, General (Strong) AI, Artificial Superintelligence, Applications of AI, Machine Learning (ML)" />
   <meta name="description" content="There are three types of Artificial Intelligence. Let's explore them and how they have 
   shaped the field. The types of AI include Narrow AI, General (Strong) AI, Artificial Superintelligence." />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
      <br />
        <br />
        <br />
        <br />
        <br />
      <Sidenav />
     
      <div className="content">

        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/AI_history"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_problem_solving"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>AI Types</h1>
        </header>

        <Navbar />

        <main>
          <section>
            <p> There are three types of Artificial Intelligence. Let's explore them and how they have shaped the field
            </p>
            <p>The types of AI include:</p>
            <h2>Narrow AI</h2>
            <p>Narrow AI is designed to perform specific tasks and is limited to a narrow domain. </p>
            <p>Examples include virtual personal assistants like Siri or Alexa, recommendation systems, and self-driving cars.</p>

            <h2>General (Strong) AI</h2>
            <p>General AI refers to machines that possess human-like cognitive abilities, enabling them to understand, learn, and
                 apply knowledge across various tasks. </p>
            <p>This level of AI has not been achieved and remains a theoretical concept.</p>

            <h2>Artificial Superintelligence</h2>
            <p>This hypothetical level of AI surpasses human intelligence in every aspect and has the ability to solve complex
                problems far beyond human capabilities. </p>
            <p> It raises ethical and existential questions.</p>

            <p>We will also dive into the applications of Artificial Intelligence and how the types of AI have been applied.</p>

            <h2>Applications of AI</h2>
            <p>The following are the areas where AI has been usefully applied.</p>

            <h2>Natural Language Processing (NLP)</h2>
            <p>AI is used to understand, interpret, and generate human language. Chatbots, language translation, and
                sentiment analysis are common NLP applications.</p>

            <h2>Machine Learning (ML)</h2>
            <p>Machine Learning algorithms enable systems to learn from data and make predictions or decisions. </p>
            <p>Applications include recommendation systems, fraud detection, and autonomous vehicles.</p>

            <h2>Computer Vision</h2>
            <p>AI systems can interpret and understand visual information from images and videos. Image recognition, object detection,
                 and facial recognition are examples.</p>

            <h2>Robotics</h2>
            <p>AI-powered robots are designed to perform tasks in various environments. </p>
            <p> They're used in manufacturing, healthcare, exploration, and more.</p>

            <h2>Expert Systems</h2>
            <p>These AI systems replicate the decision-making abilities of human experts in specific domains.</p>
            <p>They're used in fields like medicine, law, and finance.</p>
            <br />
            <p>AI has the potential to revolutionize various industries and enhance our everyday lives.</p>
            <p>From virtual personal assistants to self-driving cars, AI's capabilities continue to expand, and its applications are
                constantly evolving. </p>
            <p> Understanding the basics of AI provides a foundation for exploring its various subfields and applications
                    in greater depth.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/AI_history"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/AI_problem_solving"><ButtonNext /></ScrollToTopLink>
        </div>

      </div>

      <Footertutorials />
    </body>
  )
}
