export const sqlData = [
  {
      question: "What is SQL?",
      answer: "SQL stands for Structured Query Language, which is a domain-specific language used in programming and designed for managing data held in a relational database management system (RDBMS)."
  },
  {
    question: "SQL",
    answer: "SQL stands for Structured Query Language, which is a domain-specific language used in programming and designed for managing data held in a relational database management system (RDBMS)."
},
  {
      question: "When was SQL first developed?",
      answer: "SQL was first developed by IBM in the early 1970s. It was initially called SEQUEL (Structured English Query Language)."
  },
  {
      question: "What are the types of SQL statements?",
      answer: "SQL statements can be broadly categorized into four types: Data Definition Language (DDL), Data Manipulation Language (DML), Data Control Language (DCL), and Transaction Control Language (TCL)."
  },
  {
      question: "What is a primary key in SQL?",
      answer: "A primary key is a column or a set of columns that uniquely identifies each row in a table. It ensures the integrity and uniqueness of the data within that table."
  },
  {
      question: "What is the difference between SQL and MySQL?",
      answer: "SQL is a language used to manage and manipulate relational databases, while MySQL is an open-source relational database management system (RDBMS) that uses SQL as its language."
  },
  {
      question: "What is the purpose of the GROUP BY clause in SQL?",
      answer: "The GROUP BY clause is used in SQL to group rows that have the same values into summary rows, typically for use with aggregate functions like COUNT, SUM, AVG, etc."
  },
  {
      question: "What is an SQL injection?",
      answer: "SQL injection is a type of security exploit in which an attacker injects malicious SQL code into input fields to manipulate the database and gain unauthorized access to data."
  },
  {
      question: "What is the difference between SQL and NoSQL databases?",
      answer: "SQL databases are relational databases that store data in tables with predefined schemas, while NoSQL databases are non-relational databases that store data in flexible, schema-less formats."
  },
  {
      question: "What is the purpose of the WHERE clause in SQL?",
      answer: "The WHERE clause is used in SQL to filter records based on specified conditions, allowing users to retrieve only the data that meets certain criteria."
  },
  {
      question: "What is an SQL join?",
      answer: "An SQL join is used to combine rows from two or more tables based on a related column between them. There are several types of joins, including INNER JOIN, LEFT JOIN, RIGHT JOIN, and FULL JOIN."
  },
  {
      question: "What is normalization in SQL?",
      answer: "Normalization is the process of organizing data in a database efficiently. It involves eliminating redundancy and dependency by dividing large tables into smaller tables and defining relationships between them."
  },
  {
      question: "What is a foreign key in SQL?",
      answer: "A foreign key is a column or a set of columns in a table that references the primary key of another table. It establishes a link between the two tables and enforces referential integrity."
  },
  {
      question: "What is the purpose of the HAVING clause in SQL?",
      answer: "The HAVING clause is used in SQL to filter groups based on specified conditions, similar to the WHERE clause but for aggregated data after the GROUP BY clause."
  },
  {
      question: "What are some common SQL data types?",
      answer: "Common SQL data types include INTEGER, VARCHAR, CHAR, DATE, TIME, TIMESTAMP, BOOLEAN, FLOAT, and DECIMAL, among others."
  },
  {
      question: "What is a subquery in SQL?",
      answer: "A subquery, also known as a nested query or inner query, is a query nested within another SQL query. It is used to retrieve data based on the results of another query."
  },
  {
      question: "What is a trigger in SQL?",
      answer: "A trigger is a special type of stored procedure in SQL that is automatically executed or fired in response to specific events, such as insertions, updates, or deletions, in a table."
  },
  {
      question: "What is the purpose of the ORDER BY clause in SQL?",
      answer: "The ORDER BY clause is used in SQL to sort the result set of a query in ascending or descending order based on specified columns or expressions."
  },
  {
      question: "What is the difference between SQL and SQLite?",
      answer: "SQL is a language used to manage and manipulate relational databases, while SQLite is a self-contained, serverless, zero-configuration, transactional SQL database engine."
  },
  {
      question: "What is a stored procedure in SQL?",
      answer: "A stored procedure is a precompiled collection of one or more SQL statements and procedural logic stored in the database and executed as a single unit."
  },
  {
      question: "What is the purpose of the DISTINCT keyword in SQL?",
      answer: "The DISTINCT keyword is used in SQL to eliminate duplicate rows from the result set of a query, returning only unique rows."
  },
  {
      question: "What is a view in SQL?",
      answer: "A view in SQL is a virtual table based on the result set of a SELECT query. It does not store data itself but provides a way to present data stored in one or more tables in a specific format."
  },
  {
      question: "What is the purpose of the BETWEEN operator in SQL?",
      answer: "The BETWEEN operator is used in SQL to select values within a specified range. It is inclusive, meaning it includes the start and end values in the range."
  },
  {
      question: "What is the purpose of the AS keyword in SQL?",
      answer: "The AS keyword is used in SQL to alias column names, table names, or expressions in the result set of a query, providing a more readable or meaningful name."
  },
  {
      question: "What is a transaction in SQL?",
      answer: "A transaction in SQL is a sequence of one or more SQL statements executed as a single unit of work, typically involving multiple database operations that must either all succeed or all fail."
  },
  {
      question: "What is a constraint in SQL?",
      answer: "A constraint in SQL is a rule or condition enforced on the data in a table to maintain data integrity and consistency. Common types of constraints include NOT NULL, UNIQUE, PRIMARY KEY, FOREIGN KEY, and CHECK constraints."
  },
  {
      question: "What is the purpose of the UNION operator in SQL?",
      answer: "The UNION operator is used in SQL to combine the result sets of two or more SELECT queries into a single result set, removing duplicate rows by default."
  },
  {
      question: "What is the purpose of the LIKE operator in SQL?",
      answer: "The LIKE operator is used in SQL to search for a specified pattern in a column. It is often used with wildcard characters (%) to match any string of zero or more characters."
  },
  {
      question: "What is a database index in SQL?",
      answer: "A database index in SQL is a data structure that improves the speed of data retrieval operations on a database table by providing quick access to rows based on the values of certain columns."
  },
  {
      question: "What is the purpose of the COUNT() function in SQL?",
      answer: "The COUNT() function is used in SQL to return the number of rows that match a specified condition or to count the number of non-null values in a column."
  },
  {
      question: "What is the purpose of the TRUNCATE TABLE statement in SQL?",
      answer: "The TRUNCATE TABLE statement is used in SQL to quickly delete all rows from a table, effectively resetting the table without logging individual row deletions and without firing triggers."
  },
  {
      question: "What is a deadlock in SQL?",
      answer: "A deadlock in SQL occurs when two or more transactions are waiting indefinitely for each other to release locks on resources, resulting in a situation where no transaction can proceed."
  },
  {
      question: "What is the purpose of the COMMIT statement in SQL?",
      answer: "The COMMIT statement is used in SQL to permanently save any changes made during the current transaction to the database, making those changes visible to other transactions."
  },
  {
      question: "What is the purpose of the ROLLBACK statement in SQL?",
      answer: "The ROLLBACK statement is used in SQL to undo any changes made during the current transaction and restore the database to its state before the transaction began."
  },
  {
      question: "What is the purpose of the JOIN clause in SQL?",
      answer: "The JOIN clause is used in SQL to retrieve data from two or more tables based on a related column between them, combining rows with matching values into a single result set."
  },
  {
      question: "What is the purpose of the CASE statement in SQL?",
      answer: "The CASE statement is used in SQL to perform conditional logic within a query, allowing users to execute different actions based on specified conditions."
  },
  {
      question: "What is the purpose of the COALESCE() function in SQL?",
      answer: "The COALESCE() function is used in SQL to return the first non-null value in a list of expressions, providing a way to handle null values more effectively."
  },
  {
      question: "What is a self-join in SQL?",
      answer: "A self-join in SQL is a join operation where a table is joined with itself. It is useful for querying hierarchical data or comparing rows within the same table."
  },
  {
      question: "What is the purpose of the CASCADE option in SQL?",
      answer: "The CASCADE option is used in SQL to specify that changes to a parent table's data, such as deletions or updates, should automatically propagate to related child tables."
  },
  {
      question: "What is the purpose of the DEFAULT constraint in SQL?",
      answer: "The DEFAULT constraint is used in SQL to specify a default value for a column when no value is explicitly provided during an INSERT operation."
  },
  {
      question: "What is a materialized view in SQL?",
      answer: "A materialized view in SQL is a database object that stores the result set of a query as a physical table, allowing for faster access to the data by precomputing and storing the results."
  },
  {
      question: "What is the purpose of the EXISTS operator in SQL?",
      answer: "The EXISTS operator is used in SQL to test whether a subquery returns any rows. It returns true if the subquery returns at least one row, and false otherwise."
  },
  {
      question: "What is the purpose of the NOT IN operator in SQL?",
      answer: "The NOT IN operator is used in SQL to negate the results of a subquery, returning rows that do not match the values returned by the subquery."
  },
  {
      question: "What is a recursive common table expression (CTE) in SQL?",
      answer: "A recursive common table expression (CTE) in SQL is a CTE that references itself, allowing for iterative processing of hierarchical or recursive data structures."
  },
  {
      question: "What is the purpose of the ROW_NUMBER() function in SQL?",
      answer: "The ROW_NUMBER() function is used in SQL to assign a unique sequential integer to each row in the result set, typically for the purpose of pagination or ranking."
  },
  {
      question: "What is the purpose of the UNION ALL operator in SQL?",
      answer: "The UNION ALL operator is used in SQL to combine the result sets of two or more SELECT queries into a single result set, including all rows without removing duplicates."
  },
  {
      question: "What is the purpose of the FETCH FIRST clause in SQL?",
      answer: "The FETCH FIRST clause is used in SQL to limit the number of rows returned by a query, specifying the maximum number of rows to retrieve from the result set."
  },
  {
      question: "What is the purpose of the ROWID pseudocolumn in SQL?",
      answer: "The ROWID pseudocolumn in SQL is a unique identifier assigned to each row in a table by the database engine, allowing for efficient retrieval and manipulation of individual rows."
  },
  {
      question: "What is the purpose of the MERGE statement in SQL?",
      answer: "The MERGE statement is used in SQL to perform multiple data manipulation operations, such as INSERT, UPDATE, and DELETE, based on specified conditions within a single statement."
  },
  {
      question: "What is the purpose of the WITH clause in SQL?",
      answer: "The WITH clause, also known as a common table expression (CTE), is used in SQL to define temporary result sets that can be referenced within the context of a larger query."
  },
  {
      question: "What is the purpose of the CROSS JOIN operator in SQL?",
      answer: "The CROSS JOIN operator is used in SQL to generate a Cartesian product of two or more tables, combining every row from the first table with every row from the second table."
  },
  {
      question: "What is a correlated subquery in SQL?",
      answer: "A correlated subquery in SQL is a subquery that references columns from the outer query, allowing for data retrieval based on values from the outer query."
  },
  {
      question: "What is the purpose of the LEAD() function in SQL?",
      answer: "The LEAD() function is used in SQL to access data from subsequent rows within the same result set, providing a way to perform calculations or comparisons with values from future rows."
  },
  {
      question: "What is the purpose of the LAG() function in SQL?",
      answer: "The LAG() function is used in SQL to access data from preceding rows within the same result set, providing a way to perform calculations or comparisons with values from previous rows."
  },
  {
      question: "What is the purpose of the COALESCE() function in SQL?",
      answer: "The COALESCE() function is used in SQL to return the first non-null value in a list of expressions, providing a way to handle null values more effectively."
  },
  {
      question: "What is a window function in SQL?",
      answer: "A window function in SQL is a function that performs a calculation across a set of rows that are related to the current row, typically within a specified window or frame of rows."
  },
  {
      question: "What is the purpose of the PARTITION BY clause in SQL?",
      answer: "The PARTITION BY clause is used in SQL window functions to divide the result set into partitions or groups based on specified criteria, allowing for separate calculations within each partition."
  },
  {
      question: "What is the purpose of the ROW_NUMBER() function in SQL?",
      answer: "The ROW_NUMBER() function is used in SQL to assign a unique sequential integer to each row in the result set, typically for the purpose of pagination or ranking."
  },
  {
      question: "What is the purpose of the RANK() function in SQL?",
      answer: "The RANK() function is used in SQL to assign a rank to each row in the result set based on specified criteria, typically with gaps in the ranking for tied values."
  },
  {
      question: "What is the purpose of the DENSE_RANK() function in SQL?",
      answer: "The DENSE_RANK() function is used in SQL to assign a rank to each row in the result set based on specified criteria, with no gaps in the ranking for tied values."
  },
  {
      question: "What is the purpose of the NTILE() function in SQL?",
      answer: "The NTILE() function is used in SQL to divide the result set into a specified number of groups or 'buckets' based on a ranking of rows, assigning each row to a group."
  },
  {
      question: "What is the purpose of the FIRST_VALUE() function in SQL?",
      answer: "The FIRST_VALUE() function is used in SQL to return the value of the specified expression from the first row in a window frame defined by a window function."
  },
  {
      question: "What is the purpose of the LAST_VALUE() function in SQL?",
      answer: "The LAST_VALUE() function is used in SQL to return the value of the specified expression from the last row in a window frame defined by a window function."
  },
  {
      question: "What is the purpose of the LAG() function in SQL?",
      answer: "The LAG() function is used in SQL to access data from preceding rows within the same result set, providing a way to perform calculations or comparisons with values from previous rows."
  },
  {
      question: "What is the purpose of the LEAD() function in SQL?",
      answer: "The LEAD() function is used in SQL to access data from subsequent rows within the same result set, providing a way to perform calculations or comparisons with values from future rows."
  },
  {
      question: "What is the purpose of the NTH_VALUE() function in SQL?",
      answer: "The NTH_VALUE() function is used in SQL to return the value of the specified expression from the nth row in a window frame defined by a window function."
  },
  {
      question: "What is the purpose of the PERCENT_RANK() function in SQL?",
      answer: "The PERCENT_RANK() function is used in SQL to calculate the relative rank of a row within a result set as a percentage, indicating the row's position relative to others."
  },
  {
      question: "What is the purpose of the CUME_DIST() function in SQL?",
      answer: "The CUME_DIST() function is used in SQL to calculate the cumulative distribution of a value within a result set, representing the percentage of rows with values less than or equal to the current row's value."
  },
  {
      question: "What is the purpose of the WIDTH_BUCKET() function in SQL?",
      answer: "The WIDTH_BUCKET() function is used in SQL to categorize the distribution of a set of values into a specified number of buckets or ranges, returning the bucket number for each value."
  },
  {
      question: "What is the purpose of the RATIO_TO_REPORT() function in SQL?",
      answer: "The RATIO_TO_REPORT() function is used in SQL to calculate the ratio of a value to the total of a set of values within a result set, expressing the result as a percentage."
  },
  {
      question: "What is the purpose of the LISTAGG() function in SQL?",
      answer: "The LISTAGG() function is used in SQL to concatenate values from multiple rows into a single string, with an optional delimiter specified between each value."
  },
  {
      question: "What is the purpose of the XMLAGG() function in SQL?",
      answer: "The XMLAGG() function is used in SQL to aggregate XML data from multiple rows into a single XML document, combining elements from each row into a hierarchical structure."
  },
  {
      question: "What is the purpose of the GROUPING SETS() function in SQL?",
      answer: "The GROUPING SETS() function is used in SQL to define multiple grouping sets for a single query, allowing for the calculation of aggregate values at different levels of granularity."
  },
  {
      question: "What is the purpose of the ROLLUP() function in SQL?",
      answer: "The ROLLUP() function is used in SQL to generate subtotals and grand totals for a set of grouped data, producing a hierarchical summary of aggregated values."
  },
  {
      question: "What is the purpose of the CUBE() function in SQL?",
      answer: "The CUBE() function is used in SQL to generate all possible combinations of groupings for a set of grouped data, producing a multidimensional summary of aggregated values."
  },
  {
      question: "What is the purpose of the CHECK constraint in SQL?",
      answer: "The CHECK constraint is used in SQL to enforce domain integrity by limiting the values that can be stored in a column to those that satisfy a specified condition."
  },
  {
      question: "What is the purpose of the UNIQUE constraint in SQL?",
      answer: "The UNIQUE constraint is used in SQL to ensure that all values in a column or a combination of columns are unique, preventing duplicate entries in the table."
  },
  {
      question: "What is the purpose of the EXCEPT operator in SQL?",
      answer: "The EXCEPT operator is used in SQL to return distinct rows from the left input query that are not output by the right input query, effectively performing a set difference operation."
  },
  {
      question: "What is the purpose of the INTERSECT operator in SQL?",
      answer: "The INTERSECT operator is used in SQL to return distinct rows that are common to both the left and right input queries, effectively performing a set intersection operation."
  },
  {
      question: "What is the purpose of the UNION operator in SQL?",
      answer: "The UNION operator is used in SQL to combine the result sets of two or more SELECT queries into a single result set, removing duplicate rows by default."
  },
  {
      question: "What is the purpose of the MERGE statement in SQL?",
      answer: "The MERGE statement is used in SQL to perform multiple data manipulation operations, such as INSERT, UPDATE, and DELETE, based on specified conditions within a single statement."
  },
  {
      question: "What is the purpose of the TRUNCATE TABLE statement in SQL?",
      answer: "The TRUNCATE TABLE statement is used in SQL to quickly delete all rows from a table, effectively resetting the table without logging individual row deletions and without firing triggers."
  },
  {
      question: "What is the purpose of the DELETE statement in SQL?",
      answer: "The DELETE statement is used in SQL to remove one or more rows from a table based on specified conditions, permanently deleting the data from the table."
  },
  {
      question: "What is the purpose of the UPDATE statement in SQL?",
      answer: "The UPDATE statement is used in SQL to modify existing data in a table by changing the values of one or more columns in one or more rows based on specified conditions."
  },
  {
      question: "What is the purpose of the INSERT statement in SQL?",
      answer: "The INSERT statement is used in SQL to add one or more rows of data to a table, specifying the values to be inserted into each column of the new rows."
  },
  {
      question: "What is the purpose of the SELECT statement in SQL?",
      answer: "The SELECT statement is used in SQL to retrieve data from one or more tables, specifying the columns to be returned and any filtering or sorting criteria."
  },
  {
      question: "What is the purpose of the WHERE clause in SQL?",
      answer: "The WHERE clause is used in SQL to filter records based on specified conditions, allowing users to retrieve only the data that meets certain criteria."
  },
  {
      question: "What is the purpose of the ORDER BY clause in SQL?",
      answer: "The ORDER BY clause is used in SQL to sort the result set of a query in ascending or descending order based on specified columns or expressions."
  },
  {
      question: "What is the purpose of the GROUP BY clause in SQL?",
      answer: "The GROUP BY clause is used in SQL to group rows that have the same values into summary rows, typically for use with aggregate functions like COUNT, SUM, AVG, etc."
  },
  {
      question: "What is the purpose of the HAVING clause in SQL?",
      answer: "The HAVING clause is used in SQL to filter groups based on specified conditions, similar to the WHERE clause but for aggregated data after the GROUP BY clause."
  },
  {
      question: "What is the purpose of the JOIN clause in SQL?",
      answer: "The JOIN clause is used in SQL to retrieve data from two or more tables based on a related column between them, combining rows with matching values into a single result set."
  },
  {
      question: "What is the purpose of the ON clause in SQL?",
      answer: "The ON clause is used in SQL to specify the join condition between two tables in a JOIN operation, indicating how the tables should be linked together."
  },
  {
      question: "What is the purpose of the OUTER JOIN in SQL?",
      answer: "The OUTER JOIN is used in SQL to retrieve rows from one or both tables in a JOIN operation, including unmatched rows from one or both tables based on the join condition."
  },
  {
      question: "What is the purpose of the INNER JOIN in SQL?",
      answer: "The INNER JOIN is used in SQL to retrieve only the rows that have matching values in both tables being joined, excluding unmatched rows from either table."
  },
  {
      question: "What is the purpose of the LEFT JOIN in SQL?",
      answer: "The LEFT JOIN is used in SQL to retrieve all rows from the left table of the JOIN operation, along with matching rows from the right table if any, or NULL values if no match is found."
  },
  {
      question: "What is the purpose of the RIGHT JOIN in SQL?",
      answer: "The RIGHT JOIN is used in SQL to retrieve all rows from the right table of the JOIN operation, along with matching rows from the left table if any, or NULL values if no match is found."
  },
  {
      question: "What is the purpose of the CROSS JOIN in SQL?",
      answer: "The CROSS JOIN is used in SQL to generate a Cartesian product of two or more tables, combining every row from the first table with every row from the second table."
  },
  {
      question: "What is the purpose of the NATURAL JOIN in SQL?",
      answer: "The NATURAL JOIN is used in SQL to perform a join between two tables based on columns with the same name and data type, automatically matching them without the need for explicit join conditions."
  },
  {
      question: "What is the purpose of the SELF JOIN in SQL?",
      answer: "The SELF JOIN is used in SQL to join a table to itself, typically to query hierarchical data or to compare rows within the same table."
  }
];
