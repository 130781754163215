import Header from "../components/Header-services";
import Footer from "../components/Footer";
import Navbar from "../components/navbar";
import Topnav from "../components/topnav";
import { Helmet } from 'react-helmet';
import '../css/my style.css';
import '../css/my style2.css';
import { Link } from "react-router-dom";
import ScrollToTopLink from "../components/ScrollToTop";


export default function Services() {
  var linkCode = {
    textAlign: 'left'
  }
  var twoLinks = {
    color: 'black'
  }
    return (
        <body className="main-theme">
        <Helmet>
       
        <title>Services | Beta Thread</title>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Our services, Beta Thread services, HTML, Python, CSS, SQL, JavaScript, How to, PHP, Java, C, C++, C#, jQuery, Bootstrap, Colors, W3.CSS, XML, MySQL, Icons, NodeJS, React, Graphics, Angular, R, AI, Git, Data Science, Code Game, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, References, Examples, Learn to code, Source code, Demos, Tips, Website, Coding tutorials and guides Best coding courses,Front-end and back-end development,Step-by-step Python programming tutorials Java coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
    C++ programming tips for real-world applications," />
    <meta name="description" content="At Beta Thread, we're dedicated to providing you with the best learning experience in the world of programming languages. Our services are tailored to accommodate learners of all levels, from beginners to seasoned developers." />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />
    

        </Helmet>
         <br />
        <br />
        <br />
        <br />
        <br />
        <Topnav />
        <Header />
        <Navbar />
        <main>
    <section id="services">
      <h2>Our Services</h2>
      <p>At Beta Thread, we're dedicated to providing you with the best learning experience in the world of programming languages. Our services are tailored to accommodate learners of all levels, from beginners to seasoned developers.</p>

      <article>
        <h3>Programming Language Courses</h3>
        <p>Our programming language courses are meticulously crafted to cover a wide spectrum of languages, ensuring you find the perfect path for your programming journey. Whether it's Python, JavaScript, PHP or any other language, we've got you covered.</p>
      </article>

      
      <article>
        <h3>Interactive Learning</h3>
        <p>Learning by doing is our mantra. Engage in interactive learning through coding exercises, real-world projects, and quizzes. Our platform is designed to provide you with hands-on experience, ensuring you're well-equipped to tackle real coding challenges with confidence.</p>
      </article>

      <article>
        <h3>Personalized Learning Paths</h3>
        <p>We understand that each learner is unique. That's why we offer personalized learning paths tailored to your goals. Whether you're looking to break into the tech industry, enhance your career, or simply explore your coding interests, we've got a path just for you.</p>
      </article>

      <article>
        <h3>Supportive Community</h3>
        <p>Connect with fellow learners through our online community forums and chat groups. Share experiences, collaborate on projects, and learn from one another. The Beta Thread community is a supportive and vibrant space for networking and knowledge sharing.</p>
        <p>Also check out <a href="http://www.chase360.com.ng">www.chase360.com.ng</a></p>
      </article>

  <div style={{ position: "relative", marginBottom: "0" }}>
  <img src={require('../image/web.svg').default} style={{ width: "100%", height: "450px", paddingBottom: '10px', backgroundColor: 'teal' }} alt='code' />
  <img src={require('../image/betathread-teal.svg').default} width={90} height={70} style={{ position: "absolute", top: "0", left: "0" }} alt='betathread' />
  <div className="website">
  <ScrollToTopLink to="/needawebsite" title="Do You need a website?">
      <button class="button-web">Get Started</button>
    </ScrollToTopLink>
   </div>
  </div>
    </section>
  </main>


        <Footer />
        </body>
    )
}