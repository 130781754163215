 export const helpData = [
  {
    question: "How to",
    answer: "Hello! What can I do for you?",
  },
    {
      question: "Hey there!",
      answer: "Hello! What can I do for you?",
    },
    {
      question: "I need assistance.",
      answer: "Of course! How can I help you today?",
    },
    {
      question: "I need help.",
      answer: "What do you need help with?",
    },
    {
      question: "Can you lend me a hand?",
      answer: "Absolutely! What do you need help with?",
    },
    {
      question: "Is anyone there?",
      answer: "Yes, I'm here to assist you. What do you need?",
    },
    {
      question: "I'm lost. Can you guide me?",
      answer: "Sure! Where are you trying to go?",
    },
    {
      question: "Do you have a moment?",
      answer: "Of course! What can I do for you?",
    },
    {
      question: "Could you help me out?",
      answer: "Sure thing! What do you need assistance with?",
    },
    {
      question: "What's up?",
      answer: "Not much, just here to help. How about you?",
    },
    {
      question: "Can you assist me?",
      answer: "Absolutely! What do you need help with?",
    },
    {
      question: "I need some help.",
      answer: "Sure thing! What do you need assistance with?",
    },
    {
      question: "Hello?",
      answer: "Hi there! How can I assist you today?",
    },
    {
      question: "Is this the right place to ask for help?",
      answer: "Yes, you're in the right place! What do you need assistance with?",
    },
    {
      question: "Anyone here to help?",
      answer: "Yes, I'm here! What do you need assistance with?",
    },
    {
      question: "Need some assistance here.",
      answer: "Sure thing! What do you need assistance with?",
    },
    {
      question: "Could you give me a hand?",
      answer: "Of course! What do you need assistance with?",
    },
    {
      question: "How may I be of service?",
      answer: "I'm here to help! What do you need assistance with?",
    },
    {
      question: "What can you do for me?",
      answer: "I can assist you with a variety of tasks. What do you need help with?",
    },
    {
      question: "Need some help figuring something out.",
      answer: "I'm here to assist you. What do you need help with?",
    },
    {
      question: "Can you help me with this?",
      answer: "Sure thing! What do you need assistance with?",
    },
    {
      question: "I could use some help.",
      answer: "Of course! What do you need assistance with?",
    },
    {
      question: "Got a question.",
      answer: "Ask away! I'm here to help.",
    },
    {
      question: "What should I do?",
      answer: "It depends on what you need assistance with. Can you provide more details?",
    },
    {
      question: "Looking for some assistance.",
      answer: "You've come to the right place! What do you need help with?",
    },
    {
      question: "Can you lend me your expertise?",
      answer: "Absolutely! What do you need assistance with?",
    },
    {
      question: "Need help solving a problem.",
      answer: "I'm here to help! What's the problem?",
    },
    {
      question: "Can I ask for help here?",
      answer: "Yes, you can! What do you need assistance with?",
    },
    {
      question: "Who can assist me?",
      answer: "I can! What do you need help with?",
    },
    {
      question: "Need a hand with something.",
      answer: "Sure thing! What do you need assistance with?",
    },
    {
      question: "Can you assist me with this issue?",
      answer: "Of course! What seems to be the problem?",
    }
  ]
  