import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLIndexes() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const createIndexCode = `
-- Create an index on the 'employee_name' column in the 'employees' table
CREATE INDEX idx_employee_name ON employees(employee_name);
  `;

  const uniqueIndexCode = `
-- Create a unique index on the 'email' column in the 'employees' table
CREATE UNIQUE INDEX idx_unique_email ON employees(email);
  `;

  const compositeIndexCode = `
-- Create a composite index on 'employee_name' and 'department_id' columns in the 'employees' table
CREATE INDEX idx_employee_dept ON employees(employee_name, department_id);
  `;

  const dropIndexCode = `
-- Drop an index named 'idx_employee_name'
DROP INDEX idx_employee_name ON employees;
  `;

  const performanceExampleCode = `
-- Create an index to improve query performance
CREATE INDEX idx_department_id ON employees(department_id);

-- Query that benefits from the index
SELECT * FROM employees WHERE department_id = 2;
  `;

  const tableStructure = `
-- Create the 'employees' table
CREATE TABLE employees (
    employee_id INT PRIMARY KEY,
    employee_name VARCHAR(100),
    email VARCHAR(100),
    department_id INT
);

-- Create the 'departments' table
CREATE TABLE departments (
    department_id INT PRIMARY KEY,
    department_name VARCHAR(100)
);
  `;

  return (
    <body>
      <Helmet>
        <title>SQL Indexes</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL Indexes, SQL Tutorial, Creating Indexes, Unique Indexes, Composite Indexes, Dropping Indexes" />
        <meta name="description" content="Learn about SQL indexes, including how to create, use, and manage them to improve database query performance." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/SQL_view_optimization"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL Indexes</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>SQL indexes are used to retrieve data from a database very quickly. Indexes are created on columns in a table. The users cannot see the indexes, they are just used to speed up searches/queries.</p>

            <h2>Basic Syntax</h2>
            <p>To create an index, use the following syntax:</p>
            <SyntaxHighlighterComponent code={createIndexCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li><code>idx_employee_name</code> is the name of the index.</li>
              <li><code>employees</code> is the name of the table.</li>
              <li><code>employee_name</code> is the name of the column to be indexed.</li>
            </ul>

            <h2>Unique Index</h2>
            <p>A unique index ensures that the values in the indexed column(s) are unique.</p>
            <SyntaxHighlighterComponent code={uniqueIndexCode} language="sql" />
            <p>In this example, the unique index ensures that all values in the <code>email</code> column are unique.</p>

            <h2>Composite Index</h2>
            <p>A composite index is an index on two or more columns of a table.</p>
            <SyntaxHighlighterComponent code={compositeIndexCode} language="sql" />
            <p>In this example, the composite index is created on the <code>employee_name</code> and <code>department_id</code> columns.</p>

            <h2>Dropping an Index</h2>
            <p>To drop an index, use the following syntax:</p>
            <SyntaxHighlighterComponent code={dropIndexCode} language="sql" />
            <p>In this example, the index named <code>idx_employee_name</code> is dropped from the <code>employees</code> table.</p>

            <h2>Example: Improving Performance</h2>
            <p>Indexes can significantly improve the performance of queries. Here is an example:</p>
            <SyntaxHighlighterComponent code={performanceExampleCode} language="sql" />
            <p>In this example, an index is created on the <code>department_id</code> column to speed up queries that filter on this column.</p>

            <h2>Table Structure for Examples</h2>
            <p>The following table structure is used in the examples:</p>
            <SyntaxHighlighterComponent code={tableStructure} language="sql" />
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_view_optimization"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
