import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import ScrollToTopLink from "../ScrollToTop";
import { useLocation } from 'react-router-dom';
import ProgressSearchBar from "../Searchbar/Progress SearchBar/ProgressSearchBar";

export default function Sidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className={`sidebar ${isOpen ? 'responsive' : ''}`} id="mySidebar">
        <span className="close" onClick={toggle}>&times;</span>
        <h2 style={{ color: 'white', fontSize: '30px' }}>Tutorial Progress</h2><br />
        <span><ProgressSearchBar /></span><br />
        <p className="p-sidebar">...Empowering Your Online Presence...</p><br />
    <ScrollToTopLink to="/progress-tracker">
  <a className={location.pathname === '/progress-tracker' ? 'active' : ''}>Progress Tracker Home</a>
</ScrollToTopLink><br />
<ScrollToTopLink to="/dashboard">
  <a className={location.pathname === '/dashboard' ? 'active' : ''}>Dashboard</a>
</ScrollToTopLink><br />
  <ScrollToTopLink to="/html-progress-tracker">
  <a className={location.pathname === '/html-progress-tracker' ? 'active' : ''}>HTML Progress Tracker</a>
</ScrollToTopLink><br />
<ScrollToTopLink to="/css-progress-tracker">
  <a className={location.pathname === '/css-progress-tracker' ? 'active' : ''}>CSS Progress Tracker</a>
</ScrollToTopLink><br />
<ScrollToTopLink to="/js-progress-tracker">
  <a className={location.pathname === '/js-progress-tracker' ? 'active' : ''}>JavaScript Progress Tracker</a>
</ScrollToTopLink><br />
<ScrollToTopLink to="/php-progress-tracker">
  <a className={location.pathname === '/php-progress-tracker' ? 'active' : ''}>PHP Progress Tracker</a>
</ScrollToTopLink><br />
<ScrollToTopLink to="/python-progress-tracker">
  <a className={location.pathname === '/python-progress-tracker' ? 'active' : ''}>Python Progress Tracker</a>
</ScrollToTopLink><br />
<ScrollToTopLink to="/ruby-progress-tracker">
  <a className={location.pathname === '/ruby-progress-tracker' ? 'active' : ''}>Ruby Progress Tracker</a>
</ScrollToTopLink><br />
<ScrollToTopLink to="/sql-progress-tracker">
  <a className={location.pathname === '/sql-progress-tracker' ? 'active' : ''}>SQL Progress Tracker</a>
</ScrollToTopLink><br />
<ScrollToTopLink to="/xml-progress-tracker">
  <a className={location.pathname === '/xml-progress-tracker' ? 'active' : ''}>XML Progress Tracker</a>
</ScrollToTopLink><br />
<ScrollToTopLink to="/react-progress-tracker">
  <a className={location.pathname === '/react-progress-tracker' ? 'active' : ''}>React Progress Tracker</a>
</ScrollToTopLink><br />
<ScrollToTopLink to="/ai-progress-tracker">
  <a className={location.pathname === '/ai-progress-tracker' ? 'active' : ''}>AI Progress Tracker</a>
</ScrollToTopLink><br />
   </div>

      <div className="burger-icon" onClick={toggle}>
        &#9776;
        <p style={{ float: 'right' }}>Menu</p>
      </div>
    </div>
  );
}