import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import toggle from "../responsivetopnav";
import ScrollToTopLink from "../ScrollToTop";
import { useLocation } from 'react-router-dom';
import CSSSearchBar from "../Searchbar/CSS SearchBar/CSS_SearchBar";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export default function Sidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);
  var nav = {
    color: 'white',
    textAlign: 'center',
    fontSize: '30px'
  }
  var newCode = {
    float: 'right'
  }

  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  const toggleDropdown3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
  };

  const toggleDropdown4 = () => {
    setIsDropdownOpen4(!isDropdownOpen4);
  };

  const toggleDropdown5 = () => {
    setIsDropdownOpen5(!isDropdownOpen5);
  };

  return (
    <div>
      <div className={`sidebar ${isOpen ? 'responsive' : ''}`} id="mySidebar">
        <span className="close" onClick={toggle}>&times;</span>
        <h2>CSS Tutorial</h2><br />
        <span><CSSSearchBar /></span><br />
        <p className="p-sidebar">...Empowering Your Online Presence...</p><br />
        <ScrollToTopLink to="/CSS_Tutorial_intro"><a className={location.pathname === '/CSS_Tutorial_intro' ? 'active' : ''}>CSS Introduction</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/CSS_inserting"><a className={location.pathname === '/CSS_inserting' ? 'active' : ''}>CSS Inserting</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/CSS_comments"><a className={location.pathname === '/CSS_comments' ? 'active' : ''}>CSS Comments</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/CSS_syntax"><a className={location.pathname === '/CSS_syntax' ? 'active' : ''}>CSS Syntax</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/CSS_selectors"><a className={location.pathname === '/CSS_selectors' ? 'active' : ''}>CSS Selectors</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/CSS_combinators"><a className={location.pathname === '/CSS_combinators' ? 'active' : ''}>CSS Combinators</a></ScrollToTopLink><br />
        <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown}>
         CSS Colors {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen && (
          <div className="dropdown-content">
          <ScrollToTopLink to="/CSS_colors"><a className={location.pathname === '/CSS_colors' ? 'active' : ''}>CSS Colors</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/CSS_text_color"><a className={location.pathname === '/CSS_text_color' ? 'active' : ''}>CSS Text Color</a></ScrollToTopLink><br />
           </div>
        )}
      </div>
        <h2>CSS Backgrounds</h2><br />
        <ScrollToTopLink to="/CSS_backgrounds"><a className={location.pathname === '/CSS_backgrounds' ? 'active' : ''}>CSS Backgrounds</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/CSS_background_colors"><a className={location.pathname === '/CSS_background_colors' ? 'active' : ''}>CSS Background Colors</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/CSS_background_images"><a className={location.pathname === '/CSS_background_images' ? 'active' : ''}>CSS Background Images</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/CSS_background_attachment"><a className={location.pathname === '/CSS_background_attachment' ? 'active' : ''}>CSS Background Attachment</a></ScrollToTopLink><br />
        <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown2}>
        CSS Width & Height {isDropdownOpen2 ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen2 && (
          <div className="dropdown-content">
        <ScrollToTopLink to="/CSS_width"><a className={location.pathname === '/CSS_width' ? 'active' : ''}>CSS Width</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/CSS_height"><a className={location.pathname === '/CSS_height' ? 'active' : ''}>CSS Height</a></ScrollToTopLink><br />
        </div>
        )}
        </div>
         </div>
    
      <div className="burger-icon" onClick={toggle}>
        &#9776;
        <p style={newCode}>Menu</p>
      </div>
    </div>
  )
}