import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLSelect() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const selectSyntaxCode = `
-- Basic SELECT statement
SELECT Name, Age
FROM Employees;
  `;

  const selectAllCode = `
-- SELECT all columns
SELECT *
FROM Employees;
  `;

  const whereClauseCode = `
-- SELECT with WHERE clause
SELECT Name, Age
FROM Employees
WHERE Department = 'Engineering';
  `;

  const orderByCode = `
-- SELECT with ORDER BY clause
SELECT Name, Age
FROM Employees
ORDER BY Age ASC;
  `;

  const limitOffsetCode = `
-- SELECT with LIMIT and OFFSET
SELECT Name, Age
FROM Employees
LIMIT 2 OFFSET 1;
  `;

  return (
    <body>
      <Helmet>
        <title>SQL SELECT Statement</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL SELECT Statement, SQL Tutorial, SELECT with WHERE, ORDER BY, LIMIT, OFFSET" />
        <meta name="description" content="Learn about the SQL SELECT statement, including syntax, examples, and using WHERE, ORDER BY, LIMIT, and OFFSET clauses." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_queries"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_insert"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL SELECT Statement</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>The <code>SELECT</code> statement is used to fetch data from a database. The data returned is stored in a result table, sometimes called the result set.</p>

            <h2>Basic Syntax</h2>
            <p>The basic syntax of a <code>SELECT</code> statement is:</p>
            <SyntaxHighlighterComponent code={selectSyntaxCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li><code>Name</code> and <code>Age</code> are the names of the columns you want to retrieve data from.</li>
              <li><code>Employees</code> is the name of the table containing the data.</li>
            </ul>

            <h2>Example Table</h2>
            <p>Consider the following table named <code>Employees</code>:</p>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Department</th>
                  <th>Salary</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Alice</td>
                  <td>30</td>
                  <td>HR</td>
                  <td>5000</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Bob</td>
                  <td>25</td>
                  <td>Engineering</td>
                  <td>6000</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Charlie</td>
                  <td>35</td>
                  <td>Finance</td>
                  <td>7000</td>
                </tr>
              </tbody>
            </table>

            <h2>Select All Columns</h2>
            <p>To select all columns from the <code>Employees</code> table, you can use the <code>*</code> wildcard character:</p>
            <SyntaxHighlighterComponent code={selectAllCode} language="sql" />
            <p>This will return:</p>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Department</th>
                  <th>Salary</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Alice</td>
                  <td>30</td>
                  <td>HR</td>
                  <td>5000</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Bob</td>
                  <td>25</td>
                  <td>Engineering</td>
                  <td>6000</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Charlie</td>
                  <td>35</td>
                  <td>Finance</td>
                  <td>7000</td>
                </tr>
              </tbody>
            </table>

            <h2>Using the WHERE Clause</h2>
            <p>The <code>WHERE</code> clause is used to filter records based on specified conditions. For example, to select employees from the <code>Engineering</code> department:</p>
            <SyntaxHighlighterComponent code={whereClauseCode} language="sql" />
            <p>This will return:</p>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Department</th>
                  <th>Salary</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2</td>
                  <td>Bob</td>
                  <td>25</td>
                  <td>Engineering</td>
                  <td>6000</td>
                </tr>
              </tbody>
            </table>

            <h2>Using ORDER BY Clause</h2>
            <p>The <code>ORDER BY</code> clause is used to sort the result set in ascending or descending order based on one or more columns. For example, to sort employees by <code>Age</code>:</p>
            <SyntaxHighlighterComponent code={orderByCode} language="sql" />
            <p>This will return:</p>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Department</th>
                  <th>Salary</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2</td>
                  <td>Bob</td>
                  <td>25</td>
                  <td>Engineering</td>
                  <td>6000</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Alice</td>
                  <td>30</td>
                  <td>HR</td>
                  <td>5000</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Charlie</td>
                  <td>35</td>
                  <td>Finance</td>
                  <td>7000</td>
                </tr>
              </tbody>
            </table>

            <h2>Using LIMIT and OFFSET</h2>
            <p>The <code>LIMIT</code> clause is used to specify the number of records to return, while the <code>OFFSET</code> clause is used to skip a specific number of records. For example, to select the second and third employees:</p>
            <SyntaxHighlighterComponent code={limitOffsetCode} language="sql" />
            <p>This will return:</p>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Department</th>
                  <th>Salary</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2</td>
                  <td>Bob</td>
                  <td>25</td>
                  <td>Engineering</td>
                  <td>6000</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Charlie</td>
                  <td>35</td>
                  <td>Finance</td>
                  <td>7000</td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
        <div className="head">
        <ScrollToTopLink to="/SQL_queries"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/SQL_insert"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
