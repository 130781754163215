import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLDateTimeFunctions() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const currentDateFunctionCode = `
-- CURRENT_DATE Function: Returns the current date.
SELECT CURRENT_DATE AS Today;
-- Returns: Today
  `;

  const currentTimeFunctionCode = `
-- CURRENT_TIME Function: Returns the current time.
SELECT CURRENT_TIME AS CurrentTime;
-- Returns: CurrentTime
  `;

  const currentDateTimeFunctionCode = `
-- CURRENT_TIMESTAMP Function: Returns the current date and time.
SELECT CURRENT_TIMESTAMP AS Now;
-- Returns: Now
  `;

  const extractFunctionCode = `
-- EXTRACT Function: Extracts part of a date (e.g., year, month).
SELECT EXTRACT(YEAR FROM hire_date) AS Year, EXTRACT(MONTH FROM hire_date) AS Month
FROM employees;
-- Returns: Year, Month
  `;

  return (
    <body>
      <Helmet>
        <title>SQL Date and Time Functions</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL Date and Time Functions, CURRENT_DATE, CURRENT_TIME, CURRENT_TIMESTAMP, EXTRACT, SQL Tutorial" />
        <meta name="description" content="Learn about SQL date and time functions, including CURRENT_DATE, CURRENT_TIME, CURRENT_TIMESTAMP, EXTRACT with examples and usage in SQL queries." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_string_functions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_nested"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL Date and Time Functions</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>SQL date and time functions manipulate date and time values stored in database columns or provided as literals in SQL queries.</p>

            <h2>CURRENT_DATE Function</h2>
            <p>The <code>CURRENT_DATE</code> function returns the current date.</p>
            <SyntaxHighlighterComponent code={currentDateFunctionCode} language="sql" />

            <h2>CURRENT_TIME Function</h2>
            <p>The <code>CURRENT_TIME</code> function returns the current time.</p>
            <SyntaxHighlighterComponent code={currentTimeFunctionCode} language="sql" />

            <h2>CURRENT_TIMESTAMP Function</h2>
            <p>The <code>CURRENT_TIMESTAMP</code> function returns the current date and time.</p>
            <SyntaxHighlighterComponent code={currentDateTimeFunctionCode} language="sql" />

            <h2>EXTRACT Function</h2>
            <p>The <code>EXTRACT</code> function extracts part of a date or time value, such as year, month, day, hour, minute, or second.</p>
            <SyntaxHighlighterComponent code={extractFunctionCode} language="sql" />
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_string_functions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_nested"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
