import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PythonMetatag from "../../components/Python_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PythonDisplayOutput() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    print(10-3) #outputs 7
    print(4+5) #outputs 9
    print(12) #outputs 12
    `;
   
    const code2 = `
    print("I love python") #I love python
    print("Python is a programming language") #Python is a programming language
    `;
    const code3 = `
    a = 3
    print("I am learning python" , a) #text and number
    print("Python is a" , "programming language") #text and text
    `;
   
    return (
   <body>
     <Helmet>
        
        <title>Python Display Output</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="Python Display Output, Step-by-step Python programming tutorials, Python coding exercises for beginners, Advanced Python coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       Python programming tips for real-world applications," />
      <meta name="description" content="Learn how Python Display Output enhances streamline code organization, 
      enhance readability, and unlock advanced features in Python development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/Python_writing_code"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_syntax"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>Python Display Output</h1>
</header>

<Navbar />

<main>
<section>
<p>To display output in python we make use of the print() function.</p>
<p>The print() function can be used to display numbers and text.</p>
<h2>Printing Number</h2>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code} language="python" />
<h2>Printing Text</h2>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="python" />
<h2>Combining Objects</h2>
<p>We can print two objects using the print() function.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code3} language="python" />
</section>
</main>
<div className="head">
<ScrollToTopLink to="/Python_writing_code"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Python_syntax"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}