import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLparagraph() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <p>I am a paragraph.</p>
  `;
  
     const code2 = `
     <p style="text-align:center"> I am aligned to the center. </p>
     <p style="text-align:right"> I am aligned to the right. </p>
     <p style="text-align:left"> I am aligned to the left. </p>
  `;
     
    return (
   <body>
   <Helmet>    
        <title>HTML Paragraph</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Paragraph, What is a paragraph, What is a html paragraph, html <p> element, Paragraph Alignment, Paragraph Styling,
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="HTML paragraphs are used to represent the paragraph of an HTML document.The <p> tag defines HTML paragraphs.
       HTML paragraphs are block-level elements." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
    
    <div className="content">
<header className="headertutorials" style={newCode}>
   
<ScrollToTopLink to="/HTML_heading"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_styles"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Paragraph</h1>
</header>

<Navbar />

<main>


<section>
<p>HTML paragraphs are used to represent the paragraph of an HTML document.The &lt;p&gt; tag defines HTML paragraphs.</p>
<p>HTML paragraphs are block-level elements.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html16"><Tryitout /></ScrollToTopLink>
</section>


<section>
    <h2>Paragraph Alignment</h2>
    <p>HTML paragraphs can also be aligned to do that we use the text-align CSS property with the center,right or left values.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html17"><Tryitout /></ScrollToTopLink>
</section>
 
</main>
<div className="head">
<ScrollToTopLink to="/HTML_heading"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_styles"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
   
    )
}