import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLManagingViews() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const updateViewCode = `
-- Update an existing view to include employee IDs
CREATE OR REPLACE VIEW EmployeeDepartment AS
SELECT employees.employee_id, employees.employee_name, departments.department_name
FROM employees
INNER JOIN departments ON employees.department_id = departments.department_id;
  `;

  const dropViewCode = `
-- Drop the view if it's no longer needed
DROP VIEW IF EXISTS EmployeeDepartment;
  `;

  const employeesTable = `
CREATE TABLE employees (
    employee_id INT PRIMARY KEY,
    employee_name VARCHAR(100),
    department_id INT
);

INSERT INTO employees (employee_id, employee_name, department_id) VALUES
(1, 'John Doe', 1),
(2, 'Jane Smith', 2),
(3, 'Emily Davis', 1),
(4, 'Michael Brown', 3);
  `;

  const departmentsTable = `
CREATE TABLE departments (
    department_id INT PRIMARY KEY,
    department_name VARCHAR(100)
);

INSERT INTO departments (department_id, department_name) VALUES
(1, 'Human Resources'),
(2, 'Finance'),
(3, 'IT');
  `;

  return (
    <body>
      <Helmet>
        <title>SQL Managing Views</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL Managing Views, SQL Tutorial, Updating Views, Dropping Views" />
        <meta name="description" content="Learn how to manage SQL Views, including updating and dropping views with examples and explanations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_creating_views"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_view_optimization"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL Managing Views</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Managing views in SQL involves updating existing views and dropping views that are no longer needed. Here's how you can manage views.</p>

            <h2>Example Tables</h2>
            <p>Consider the following tables:</p>
            <SyntaxHighlighterComponent code={employeesTable} language="sql" />
            <SyntaxHighlighterComponent code={departmentsTable} language="sql" />

            <h2>Updating a View</h2>
            <p>You can update an existing view to change its definition. Here's an example:</p>
            <SyntaxHighlighterComponent code={updateViewCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The view <code>EmployeeDepartment</code> is updated to include <code>employee_id</code> in its definition.</li>
              <li>The <code>CREATE OR REPLACE VIEW</code> statement ensures that the view is updated if it already exists.</li>
            </ul>

            <h2>Dropping a View</h2>
            <p>If a view is no longer needed, you can drop it from the database. Here's an example:</p>
            <SyntaxHighlighterComponent code={dropViewCode} language="sql" />
            <p>In this example:</p>
            <ul>
              <li>The view <code>EmployeeDepartment</code> is dropped from the database using the <code>DROP VIEW</code> statement.</li>
              <li>The <code>IF EXISTS</code> clause ensures that the statement does not produce an error if the view does not exist.</li>
            </ul>
          </section>
        </main>
        <div className="head">
        <ScrollToTopLink to="/SQL_creating_views"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/SQL_view_optimization"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
