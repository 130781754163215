import React from "react";
import "../../../css/my style4.css";
import "../../../css/my style.css";
import "../../../css/my style2.css";
import { Helmet } from "react-helmet";
import runcode from "../../../components/CodeEditor";
import SyntaxHighlighterComponent from "../../../components/SyntaxHighlighterComponent";
import SaveCodeButton from "../../../components/Savecodebutton";


export default function HTMLTry59() {

    const code = `
    <!DOCTYPE html>
    <html>
    <head>
    <title>My First Web Page</title>
    </head>
    <body>
    <dl>
    <dt>HTML</dt>
    <dd>A markup language used to create web pages.</dd>
    <dt>JavaScript</dt>
    <dd>A programming language used to make web pages interactive.</dd>
    </dl>
    </body>
    </html>
`;
 

    return (
    <div>
    <Helmet>
     <title>Beta Thread Try it out Editor</title>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="HTML, Python, CSS, SQL, JavaScript, How to, PHP, Java, C, C++, C#, jQuery, Bootstrap, Colors, W3.CSS, XML, MySQL, Icons, NodeJS, React, Graphics, Angular, R, AI, Git, Data Science, Code Game, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, References, Examples, Learn to code, Source code, Demos, Tips, Website, Coding tutorials and guides Best coding courses,Front-end and back-end development,Step-by-step Python programming tutorials Java coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
    C++ programming tips for real-world applications," />
   <meta name="description" content="Beta Thread try it out editor for HTML,CSS,JavaScript with syntax highlighting,a button to change the theme and other features" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>

<div class="row">
<div class="side">
<h1>Try it yourself</h1>

<div id="editor" contenteditable="true" aria-autocomplete="true">
 
<SyntaxHighlighterComponent code={code} language="html" />
   
</div>
<button onClick={runcode}>Run Code</button>
<SaveCodeButton code={code} />
</div>
<div class="main">
<h2>Output:</h2>
<div id="output"></div>
</div>
</div>
</div>
)
}