import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLsubscript() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <p>Learning <sub>is</sub> fun</p>
      <p>H + O<sub>2</sub> -> H<sub>2</sub>O </p>
  `;

 
  

  
    return (
   <body>
    <Helmet>    
        <title>HTML Subscript</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Subscript, html subscript, HTML Subscript Example, What is a subscript, html <sub> element/tag, 
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="In HTML a text, number or piece of content can be displayed as a subscript.
       Inorder to achieve this we use the <sub> tag." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_block_inline"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_superscript"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Subscript</h1>
</header>

<Navbar />

<main>

<section>
<p>In HTML a text, number or piece of content can be displayed as a subscript.</p>
<p>Inorder to achieve this we use the &lt;sub&gt; tag.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html45"><Tryitout /></ScrollToTopLink>
</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_block_inline"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_superscript"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}