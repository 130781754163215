import React from "react";
import "../../css/my style.css";
import "../../css/my style2.css";
import { Helmet } from "react-helmet";
import GoBack from "../../components/Gobackbutton";
import { Link } from "react-router-dom";
import logo from "../../image/a monitor.jpg";
import logo2 from "../../image/A Ship.jpg";

export default function SpecialOutput2() {
   

  function manipulateImage() {
    document.getElementById("demo").src = require('../../image/A Ship.jpg');
  }

    return (
    <div>
    <Helmet>
     <title>Beta Thread Try it out Editor</title>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="HTML, Python, CSS, SQL, JavaScript, How to, PHP, Java, C, C++, C#, jQuery, Bootstrap, Colors, W3.CSS, XML, MySQL, Icons, NodeJS, React, Graphics, Angular, R, AI, Git, Data Science, Code Game, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, References, Examples, Learn to code, Source code, Demos, Tips, Website, Coding tutorials and guides Best coding courses,Front-end and back-end development,Step-by-step Python programming tutorials Java coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
    C++ programming tips for real-world applications," />
   <meta name="description" content="Beta Thread try it out editor for HTML,CSS,JavaScript with syntax highlighting,a button to change the theme and other features" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    </Helmet>
    <img src={logo} id="demo" /><br />
<button onClick={manipulateImage}>Manipulate Image</button>
<br /><br /><br /><br />
<Link to="/try_html76"><GoBack /></Link>

</div>

)
}