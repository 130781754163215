import React, { useEffect, useState } from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../css/special styles.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";
import { getAuthStatus, saveAuthStatus } from "../../localstorage";
import DemoProgress from "../../components/Tutorial Progress/DemoProgress";
import ButtonHome from "../../components/Buttonhome";

export default function SQLintro() {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();


  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const code = `
SELECT first_name FROM users;
  `;

  return (
    <body>
      <Helmet>
        <title>SQL Tutorial</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="HTML, Python, CSS, SQL, JavaScript, How to, PHP, Java, C, C++, C#, jQuery, Bootstrap, Colors, W3.CSS, XML, MySQL, Icons, NodeJS, React, Graphics, Angular, R, AI, Git, Data Science, Code Game, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, References, Examples, Learn to code, Source code, Demos, Tips, Website, Coding tutorials and guides, Best coding courses, Front-end and back-end development, Step-by-step Python programming tutorials, Java coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, C++ programming tips for real-world applications." />
        <meta name="description" content="Explore the power of SQL on our SQL tutorial page. Learn how to use SQL to streamline code organization, enhance readability, and unlock advanced features in SQL development. Discover practical examples and best practices to elevate your programming skills. Dive into the world of SQL with our concise and informative guide" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
        <ScrollToTopLink to="/SQL_comments"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL Introduction - Preamble</h1>
        </header>

        <Navbar />

        <main>
          <section>
            <h2>What is SQL?</h2>
            <p><strong>SQL (Structured Query Language)</strong> is a powerful programming language designed for managing and manipulating relational databases. SQL is used to insert, search, update, and delete database records. Despite SQL being a programming language, it is very different from most other programming languages because it is not used for creating applications. Instead, SQL is used to retrieve and manipulate data stored in a relational database.</p>

            <h2>History of SQL</h2>
            <p>SQL was initially developed at IBM by Donald D. Chamberlin and Raymond F. Boyce in the early 1970s. Originally named SEQUEL (Structured English Query Language), it was designed to manipulate and retrieve data stored in IBM's original quasi-relational database management system, System R. The name was later changed to SQL (Structured Query Language) as SEQUEL was a trademarked name.</p>

            <h2>Why Use SQL?</h2>
            <ul>
              <li>SQL is essential for database management. It allows users to create, read, update, and delete (CRUD) operations on the data.</li>
              <li>SQL is user-friendly and widely adopted in various database systems, including MySQL, PostgreSQL, Oracle, SQL Server, and more.</li>
              <li>SQL allows for the efficient management and querying of large datasets.</li>
            </ul>

            <h2>SQL Syntax</h2>
            <p>SQL syntax is used to communicate with databases. Here is an example of a simple SQL query:</p>
            <SyntaxHighlighterComponent code={code} language="sql" />
            <p>In this example, we use the <code>SELECT</code> statement to fetch the <code>first_name</code> column from the <code>users</code> table.</p>

            <h2>Basic SQL Commands</h2>
            <ul>
              <li><strong>SELECT</strong> - Retrieves data from a database.</li>
              <li><strong>INSERT</strong> - Adds new data to a database.</li>
              <li><strong>UPDATE</strong> - Modifies existing data in a database.</li>
              <li><strong>DELETE</strong> - Removes data from a database.</li>
              <li><strong>CREATE</strong> - Creates a new table, view, or other database object.</li>
              <li><strong>ALTER</strong> - Modifies an existing database object, such as a table.</li>
              <li><strong>DROP</strong> - Deletes a table, view, or other database object.</li>
            </ul>

            <h2>Example: Basic SELECT Statement</h2>
            <p>Here is an example of a basic <code>SELECT</code> statement:</p>
            <SyntaxHighlighterComponent code={code} language="sql" />
            <p>This command retrieves the <code>first_name</code> from the <code>users</code> table.</p>
          </section>

          <div className="second-div">
            <section className="features">
              <div className="progress-sample">
                <img src={require('../../image/beta-progress.svg').default} width={500} height={500} alt='betathread' />
                <p style={{ fontSize: '15px' }}>Our Progress Tracker is designed to help you monitor your progress through our extensive range of tutorials.</p>
                <ScrollToTopLink to="/progress-tracker"><button type="submit" className="buttonb">Track Progress</button></ScrollToTopLink>
              </div>

              <div className="progress-sampling">
                <h1 style={{ fontSize: '45px' }}>Track Your Progress</h1>
                <DemoProgress />
              </div>
            </section>
          </div>
        </main>
        
        <div className="head">
        <ScrollToTopLink to="/"><ButtonHome /></ScrollToTopLink>
        <ScrollToTopLink to="/SQL_comments"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>

      <Footertutorials />
    </body>
  )
}
