import React from "react";
import "../../../css/my style.css";
import "../../../css/my style3.css";
import "../../../components/my script";
import Sidenav from "../../../components/Sidenav/PythonSidenav";
import Navbar from "../../../components/navbar";
import Footertutorials from "../../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../../components/Buttonnext";
import Tryitout from "../../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../../components/Buttonprevious";
import ScrollToTopLink from "../../../components/ScrollToTop";

export default function PythonVariablesAssigning() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const code = `
x = 10
y = "Hello"
z = 3.14
    `;

    const code2 = `
a, b, c = 1, 2, "Three"
    `;

    const code3 = `
x = 5
print(x)  # Output: 5
x = "Hello"
print(x)  # Output: Hello
    `;

    const code4 = `
x = "100"
y = int(x)  # Convert to integer
print(y)  # Output: 100
    `;

    return (
        <body>
            <Helmet>
                <title>Python Variable Assigning</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python, Variables, Assigning Values" />
                <meta name="description" content="Learn how to assign values to variables in Python, a fundamental concept in programming." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                    <ScrollToTopLink to="/Python_variables_intro"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_variables_types"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}> Python Variable Assigning</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>In Python, assigning values to variables is straightforward. Variables can store different types of data such as numbers, strings, and more. This flexibility makes Python a powerful and easy-to-use language.</p>
                        
                        <h2>Basic Assignment</h2>
                        <p>To assign a value to a variable, use the equals sign (<code>=</code>).</p>
                        <SyntaxHighlighterComponent code={code} language="python" />
                        <p>In the example above:</p>
                        <ul>
                            <li><code>x</code> is assigned the integer value <code>10</code>.</li>
                            <li><code>y</code> is assigned the string value <code>"Hello"</code>.</li>
                            <li><code>z</code> is assigned the float value <code>3.14</code>.</li>
                        </ul>
                        
                        <h2>Multiple Assignment</h2>
                        <p>Python allows you to assign values to multiple variables in one line. This can make your code cleaner and more concise.</p>
                        <SyntaxHighlighterComponent code={code2} language="python" />
                        <p>Here, <code>a</code>, <code>b</code>, and <code>c</code> are assigned the values <code>1</code>, <code>2</code>, and <code>"Three"</code>, respectively, in a single line.</p>
                        
                        <h2>Reassigning Variables</h2>
                        <p>Variables in Python can be reassigned to different values and even different types at any time. This dynamic typing feature makes Python flexible.</p>
                        <SyntaxHighlighterComponent code={code3} language="python" />
                        <p>In this example, the variable <code>x</code> is first assigned an integer value, and then reassigned a string value.</p>
                        
                        <h2>Type Conversion</h2>
                        <p>You can convert between types using Python’s built-in functions like <code>int()</code>, <code>float()</code>, and <code>str()</code>.</p>
                        <SyntaxHighlighterComponent code={code4} language="python" />
                        <p>Here, the string <code>"100"</code> is converted to an integer using the <code>int()</code> function.</p>
                        
                        <h2>Conclusion</h2>
                        <p>Understanding how to assign and reassign variables is fundamental in Python programming. This knowledge allows you to store and manipulate data effectively.</p>
                    </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_variables_intro"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_variables_types"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
