import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLordered() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <!-- Example using the numbers -->
      <ol type="1">
      <li>HTML</li>
      <li>CSS</li>
      <li>JavaScript</li>
      </ol>
  
      <!-- Example using the capital letters -->
      <ol type="A">
      <li>HTML</li>
      <li>CSS</li>
      <li>JavaScript</li>
      </ol>
  
      <!-- Example using the small letters -->
      <ol type="a">
      <li>HTML</li>
      <li>CSS</li>
      <li>JavaScript</li>
      </ol>
  
      <!-- Example using the small roman numerals -->
      <ol type="i">
      <li>HTML</li>
      <li>CSS</li>
      <li>JavaScript</li>
      </ol>
  
      <!-- Example using the capital roman numerals -->
      <ol type="I">
      <li>HTML</li>
      <li>CSS</li>
      <li>JavaScript</li>
      </ol>
  `;

    return (
   <body>
    <Helmet>    
        <title>HTML Ordered Lists</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Ordered Lists, html ordered list, <ol> tag, <li> tag, <ol> tag Attribute, What is an ordered list, What is an <ol> element,
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips, HTML programming tips for real-world applications," />
       <meta name="description" content="An ordered list is represented by the <ol> element.List that appear with numbers are called ordered list.
       The list items are given the <li> tags." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_unordered"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_description"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Ordered Lists</h1>
</header>

<Navbar />

<main>

<section>
<p>An ordered list is represented by the &lt;ol&gt; element.List that appear with numbers are called ordered list.</p>
<p>The list items are given the &lt;li&gt; tags.</p>
<h3>&lt;ol&gt; tag Attribute</h3>
<p>The &lt;ol&gt; tag has more attributes than the &lt;ul&gt; tag.</p>
<li>(1,2,3,...): This involves listing items with numbers.</li>
<li>(A,B,C,...): This involves listing items with capital letters.</li>
<li>(a,b,c,...): This involves listing items with small letters</li>
<li>(i,ii,iii,...): This involves listing items with small roman numerals.</li>
<li>(I,II,III,...): This involves listing items with capital roman numerals</li>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html58"><Tryitout /></ScrollToTopLink>

</section>
 
<div className="head">
<ScrollToTopLink to="/HTML_unordered"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_description"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}