import Header from "../components/Header-home";
import Footer from "../components/Footer";
import Navbar from "../components/navbar";
import Body from "../components/Home-body";
import Topnav from "../components/topnav";
import { Helmet } from 'react-helmet';
import "../css/my style2.css";
import Topnav3 from "../components/topnav3";


export default function Home() {

  
    return (
       
        <body>
        <Helmet>
        <meta charset="utf-8" />
      <link rel="icon" type="image/x-icon" href="%PUBLIC_URL%/betathread.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta http-equiv="X-UA-compatible" content="IE-edge"/>
      <meta name="Keywords" content="HTML, Python, CSS, SQL, JavaScript, How to, PHP, XML, MySQL, React, AI, How to, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, Examples, Learn to code, Coding the future, Source code, Website" />
      <meta name="description" content="Free online web tutorials with code examples, quizzes, well organized tutorials for HTML, CSS, Javascript, PHP, Python and many more.The best place to learn anything concerning technology. Beta Thread Home page" />
      <link  rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"  integrity="sha512-+U/zeIz2w4FG06Q4FmszSds5z7Q5zFvh3vZn5F0t4PwxhlvZksC5l5oRpLJz7wC1f4L2zTBmz05L/uM5MfKC1Q==" crossorigin="anonymous" referrerpolicy="no-referrer" />   
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      <link rel="preload" href="../css/header.png" as="image" />
      <title>Beta Thread Online Tutorials</title>

        </Helmet>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Topnav3 />
        <Header />
        <Navbar />
        <Body />
        
        <Footer />
       
     
        </body>
    )
}