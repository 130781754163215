import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLComments() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<!--
  This is a comment that explains the following XML structure.
  Comments are useful for providing context or notes within the XML document.
-->
<note>
  <to>User</to>
  <from>Admin</from>
  <heading>Reminder</heading>
  <body>Don't forget to check your email!</body>
</note>
`;

  const example2 = `
<!--
  This XML document contains information about a book.
  Multiple comments can be added to explain different parts.
-->
<book>
  <title>Learning XML</title>
  <author>John Doe</author>
  <year>2024</year>
  <!-- Publisher details are important for metadata -->
  <publisher>Example Publisher</publisher>
</book>
`;

  const example3 = `
<library>
  <!-- This section contains fiction books -->
  <book category="fiction">
    <title>The Great Gatsby</title>
    <author>F. Scott Fitzgerald</author>
  </book>
  <!-- This section contains non-fiction books -->
  <book category="non-fiction">
    <title>Sapiens: A Brief History of Humankind</title>
    <author>Yuval Noah Harari</author>
  </book>
</library>
`;

  const example4 = `
<company>
  <department>
    <!-- Employee details -->
    <employee>
      <name>Jane Smith</name>
      <position>Manager</position>
    </employee>
  </department>
</company>
`;

  const example5 = `
<!--
  <message>This is a commented-out element</message>
  This technique can be used to temporarily remove parts of the code.
-->
<messages>
  <message>Hello, World!</message>
</messages>
`;

  return (
    <body>
      <Helmet>
        <title>XML Comments</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML Comments, XML Tutorial, XML Comment Syntax" />
        <meta name="description" content="Learn how to use comments in XML documents with examples and explanations of the syntax and usage." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_declaration"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_versioning"><ButtonNext /></ScrollToTopLink>
          <h1>XML Comments</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Comments in XML are used to add notes or explanations within the code, which are not processed by the XML parser. They are helpful for documenting the code and making it easier to understand.</p>

            <h2>Syntax</h2>
            <p>XML comments start with <code>&lt;!--</code> and end with <code>--&gt;</code>. The content within these markers is ignored by the XML parser.</p>
            <SyntaxHighlighterComponent code={`<!-- This is a comment -->`} language="xml" />

            <h2>Examples</h2>
            <h3>Comment Explaining XML Structure</h3>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This comment explains the structure of the XML document that follows it.</p>

            <h3>Commenting Multiple Parts of an XML Document</h3>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>Comments can be used to explain different parts of an XML document, providing useful context for each section.</p>

            <h3>Comments within an XML Structure</h3>
            <SyntaxHighlighterComponent code={example3} language="xml" />
            <p>Comments can be added within an XML structure to explain specific sections or categories of data.</p>

            <h3>Comments Inside Elements</h3>
            <SyntaxHighlighterComponent code={example4} language="xml" />
            <p>Comments can also be placed inside XML elements to provide notes or explanations about the content.</p>

            <h3>Commenting Out Elements</h3>
            <SyntaxHighlighterComponent code={example5} language="xml" />
            <p>Sometimes comments are used to temporarily remove parts of the XML code. This can be useful for testing or debugging.</p>

            <h2>When to Use Comments</h2>
            <p>Comments should be used to:</p>
            <ul>
              <li>Explain the purpose of specific sections or elements within the XML document.</li>
              <li>Provide context or notes for other developers or users reading the XML document.</li>
              <li>Temporarily remove sections of the code for testing or debugging purposes.</li>
            </ul>
          </section>
        </main>
        <div className="head">
        <ScrollToTopLink to="/XML_declaration"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_versioning"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
