import React, { useState, useEffect, useRef } from 'react';
import { data } from './data';
import Msg from './msg';
import Plane from '../image/paper-plane.svg';
import VoiceIcon from '../image/voice.svg';
import VoiceSlash from '../image/voice-slash.svg';
import { badWords } from './Data/Bad Words/badwords';
import ClickableQuestions from './ClickableQuestions'; // Import the new component
import { Link } from 'react-router-dom';
import './ClickableQuestions.css'; // Import CSS for stylin
import { FaPaperPlane, FaVolumeMute, FaVolumeOff, FaVolumeUp } from 'react-icons/fa';

export default function ChatBots() {
  const [msg, setMsg] = useState('');
  const [chatList, setChatList] = useState([]);
  const [isVoiceListening, setIsVoiceListening] = useState(false);
  const [speechSynthesisInstance, setSpeechSynthesisInstance] = useState(null);
  const [showQuestions, setShowQuestions] = useState(true); // Initially show questions
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const greetingMsg = "Welcome to betathread.com! I'm your AI assistant, here to make your experience exceptional. Let's get started!";
    setChatList([{ msg: greetingMsg, incomingMsg: true }]);
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatList]);

  const preprocessText = text => {
    return text.toLowerCase().replace(/[^\w\s]/gi, '');
  };

  const getAnswer = q => {
    const lowercaseQ = preprocessText(q);
    const typingSpeedThreshold = 5;
  
    let maxScore = 0;
    let bestMatch = null;
  
    data.forEach(item => {
      const question = preprocessText(item.question);
      const similarityScore = calculateSimilarity(lowercaseQ, question);
  
      if (similarityScore > maxScore) {
        maxScore = similarityScore;
        bestMatch = item;
      }
    });
  
    if (maxScore >= similarityThreshold) {
      const typingIndicatorMsg = { msg: '...', incomingMsg: true };
      setChatList(prevChatList => [...prevChatList, typingIndicatorMsg]);
  
      const answerMsg = bestMatch.answer;
      let typedMsg = '';
      const typingEffect = async () => {
        for (let i = 0; i < answerMsg.length; i++) {
          await new Promise(resolve => setTimeout(resolve, typingSpeedThreshold));
          typedMsg += answerMsg[i];
          setChatList(prevChatList => {
            const updatedChatList = [...prevChatList];
            updatedChatList[prevChatList.length - 1].msg = typedMsg;
            return updatedChatList;
          });
        }
        if (isVoiceListening) {
          speak(typedMsg); // Speak the answer
        }
      };
      typingEffect();
    } else {
      const typingIndicatorMsg = { msg: '...', incomingMsg: true };
      setChatList(prevChatList => [...prevChatList, typingIndicatorMsg]);
  
      const containsBadWords = checkForBadWords(q);
      if (containsBadWords) {
        const badWordsErrorMsg = `Sorry, your message contains inappropriate language. 
        Please refrain from using such language because it goes against our privacy policy,
         you can find more information go to https://www.betathread.com/terms&privacy`;
        let typedBadWordsErrorMsg = '';
        const typingEffect = async () => {
          for (let i = 0; i < badWordsErrorMsg.length; i++) {
            await new Promise(resolve => setTimeout(resolve, typingSpeedThreshold));
            typedBadWordsErrorMsg += badWordsErrorMsg[i];
            setChatList(prevChatList => {
              const updatedChatList = [...prevChatList];
              updatedChatList[prevChatList.length - 1].msg = typedBadWordsErrorMsg;
              return updatedChatList;
            });
          }
        };
        typingEffect();
      } else {
        const errorMessage = "Apologies, I can't seem to find an answer to your question. Please contact customer support for more help at: https://www.betathread.com/contact";
        let typedErrorMsg = '';
        const typingEffect = async () => {
          for (let i = 0; i < errorMessage.length; i++) {
            await new Promise(resolve => setTimeout(resolve, typingSpeedThreshold));
            typedErrorMsg += errorMessage[i];
            setChatList(prevChatList => {
              const updatedChatList = [...prevChatList];
              updatedChatList[prevChatList.length - 1].msg = typedErrorMsg;
              return updatedChatList;
            });
          }
        };
        typingEffect();
      }
    }
  };

  const checkForBadWords = text => {
    const words = preprocessText(text).split(' ');
    return words.some(word => badWords.includes(word));
  };

  const calculateSimilarity = (query, question) => {
    const wordsQuery = query.split(' ');
    const wordsQuestion = question.split(' ');
    const commonWords = wordsQuery.filter(word => wordsQuestion.includes(word));
    return commonWords.length / Math.max(wordsQuery.length, wordsQuestion.length);
  };

  const similarityThreshold = 0.8;
  const typingSpeedThreshold = 5; // Typing speed threshold for all messages

  const onSendMsg = () => {
    if (!msg.trim()) return;

    const newSentMsg = { msg: msg, sentMsg: true };
    setChatList(prevChatList => [...prevChatList, newSentMsg]);
    setMsg('');
    getAnswer(msg);
    setShowQuestions(false); // Remove clickable questions after sending a message
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      onSendMsg();
    }
  };

  const handleVoiceInput = () => {
    if (isVoiceListening) {
      setIsVoiceListening(false);
      if (speechSynthesisInstance) {
        speechSynthesisInstance.cancel();
      }
    } else {
      setIsVoiceListening(true);
      const lastBotResponse = chatList.filter(item => item.incomingMsg).pop();
      if (lastBotResponse) {
        speak(lastBotResponse.msg); // Speak the last bot response
      }
    }
  };
  
  const speak = message => {
    const utterance = new SpeechSynthesisUtterance(message);
    const synthesis = window.speechSynthesis;
    setSpeechSynthesisInstance(synthesis);
    synthesis.speak(utterance);
    utterance.onend = () => {
      setIsVoiceListening(false);
    };
  };

  const handleInputChange = (e) => {
    setMsg(e.target.value);
    if (!e.target.value.trim()) {
      setShowQuestions(true); // Show questions when input field is empty
    }
  };

  const handleQuestionClick = question => {
    setMsg(question);
    setShowQuestions(false);
    getAnswer(question); // Fetch answer based on the clicked question
  };

  return (
    <div className='message-container' ref={chatContainerRef}>

      <div id="robot">
        <div className="bot"></div>
        <h3 className='heading'>Beta AI</h3>
      
      </div>  <span style={{fontSize: "11px", marginLeft: "25px"}}>Beta AI is capable of making errors. Please verify crucial information as needed.</span>
      <div style={{ flex: 1, overflowY: 'auto' }}>
        {chatList.map((item, index) => (
          <Msg
            key={index}
            incomingMsg={item.incomingMsg}
            msg={item.msg}
            sentMsg={item.sentMsg}
          />
        ))}
      </div>
      {showQuestions && ( // Show questions only if the input field is empty
        <ClickableQuestions
          questions={['How to use Beta AI?', 'Explain HTML to me like a pro?', 'What is the purpose of CSS?', 'Tell me a joke']}
          onClick={handleQuestionClick}
        />
      )}
      <div className='typeMsgContainer'>
        <input
          type="text"
          className='typeMsgBox'
          id='msg_send'
          value={msg}
          placeholder="Message Beta AI..."
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
        
        <button
          className='sendbtn'
          disabled={!msg}
          onClick={onSendMsg}
          title='Send message'
          id='reply'
        >
          <span className='sendTxt'><FaPaperPlane style={{ color: 'white', fontSize: 20 }} /></span>
          </button>
        <button
          className='volumebtn'
          onClick={handleVoiceInput}
          title={isVoiceListening ? 'Stop reading aloud' : 'Read Aloud'}
          id='voice'
        >
            {isVoiceListening ? <FaVolumeMute style={{ color: 'darkblue', fontSize: 20 }} /> : <FaVolumeUp style={{ color: 'darkblue', fontSize: 20 }} />}
        </button>
      </div>
    </div>
  );
}  