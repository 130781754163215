import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSAssignmentOperators() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
            let x = 10;
            x += 5;  // x is now 15 (x = x + 5)
            x *= 2;  // x is now 30 (x = x * 2)
            /* When the value of
            the variable is 
            displayed on the 
            browser the final 
            output will be 30 */
`;
const code2 = `
var x = 6;
x -= 3;  // x is now 3 (x = x - 3)
x /= 2;  // x is now 1.5 (x = x / 2)
`;

const code3 = `
var y = 9;
y **= 3;  // x is now 729 (y = y ** 3)
y %= 3;  // x is now 0 (y = y % 3)
`;

    return (
   <body>
     <Helmet>
    
        <title>JavaScript Assignment Operators</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="JavaScript Assignment Operators, Step-by-step JavaScript programming tutorials, JavaScript coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       JavaScript programming tips for real-world applications," />
      <meta name="description" content="Learn how JavaScript Assignment Operators streamline code organization, 
      enhance readability, and unlock advanced features in JavaScript development." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_arithmetic_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_logical_conditional_operators"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Assignment Operators</h1>
</header>

<Navbar />

<main>
    <section>
    <p>The assignment operators assign values to variables.
</p>
<p>The assignment operators include =, +=, -=, *=, /=, %=</p>
<li>= (Assignment): The equal to assigns the value on the right to the variable on the left.</li>
<li>+=, -=, *=, /=, %= (Compound Assignment): Performs an operation and assigns the result to the variable.</li>

 <h2>Arithmetic Operators</h2>
 <p>The arithmetic operator perfors simple mathematical operations</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
<ScrollToTopLink to="/try_js32"><Tryitout /></ScrollToTopLink>  
    <p>This is another example of the assignent operators.</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js33"><Tryitout /></ScrollToTopLink>  
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code3} language="javascript" />
<ScrollToTopLink to="/try_js34"><Tryitout /></ScrollToTopLink>  
</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_arithmetic_operators"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_logical_conditional_operators"><ButtonNext /></ScrollToTopLink>
</div>


</div>

<Footertutorials />
   </body>
    )
}