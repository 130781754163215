import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLHTML5() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const code1 = `
<!DOCTYPE html>
<html>
<head>
  <title>XML and HTML5</title>
  <script>
    document.addEventListener("DOMContentLoaded", function() {
      const parser = new DOMParser();
      const xmlString = \`
        <book>
          <title>Learning XML</title>
        </book>
      \`;
      const xmlDoc = parser.parseFromString(xmlString, "application/xml");
      const title = xmlDoc.getElementsByTagName("title")[0].childNodes[0].nodeValue;
      document.getElementById("output").innerText = title;
    });
  </script>
</head>
<body>
  <h1>Displaying XML Data in HTML5</h1>
  <div id="output"></div>
</body>
</html>`;

  const code2 = `
<!DOCTYPE html>
<html>
<head>
  <title>XML with HTML5</title>
</head>
<body>
  <h1>XML in an HTML5 Document</h1>
  <div id="xmlContainer">
    <book>
      <title>Learning XML</title>
    </book>
  </div>
</body>
</html>`;

  const code3 = `
<!DOCTYPE html>
<html>
<head>
  <title>Loading XML via Ajax</title>
  <script>
    function loadXMLDoc() {
      const xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(this.responseText, "application/xml");
          const title = xmlDoc.getElementsByTagName("title")[0].childNodes[0].nodeValue;
          document.getElementById("output").innerText = title;
        }
      };
      xhttp.open("GET", "books.xml", true);
      xhttp.send();
    }
    document.addEventListener("DOMContentLoaded", loadXMLDoc);
  </script>
</head>
<body>
  <h1>Books List</h1>
  <div id="output"></div>
</body>
</html>`;

  return (
    <body>
      <Helmet>
        <title>XML and HTML5</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML, HTML5, XML with HTML5, XML Tutorial, HTML5 Tutorial" />
        <meta name="description" content="Learn how to use XML with HTML5, including examples and explanations of the syntax and usage." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_dom"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
          <h1>XML and HTML5</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Combining XML with HTML5 allows developers to utilize the strengths of both technologies. XML provides a way to structure data, while HTML5 offers a way to present that data on the web. In this tutorial, we will explore how to integrate XML with HTML5.</p>

            <h2>Using XML with HTML5</h2>
            <p>HTML5 provides robust support for integrating XML data. This can be done using JavaScript to parse and manipulate XML data, which can then be displayed within an HTML5 page.</p>

            <h3>Example 1: Displaying XML Data in HTML5</h3>
            <p>This example demonstrates how to parse a simple XML string and display its contents within an HTML5 document.</p>
            <SyntaxHighlighterComponent code={code1} language="html" />

            <h3>Example 2: Embedding XML Directly in HTML5</h3>
            <p>In this example, XML data is embedded directly within an HTML5 document, showing how XML data can be included in the page structure.</p>
            <SyntaxHighlighterComponent code={code2} language="html" />

            <h3>Example 3: Loading XML via Ajax</h3>
            <p>This example demonstrates how to load XML data via an Ajax request and display the parsed data in an HTML5 page.</p>
            <SyntaxHighlighterComponent code={code3} language="html" />

            <h2>Benefits of Using XML with HTML5</h2>
            <ul>
              <li>Separation of data and presentation: XML stores data separately from the presentation layer.</li>
              <li>Reusable data: XML data can be reused across different applications and platforms.</li>
              <li>Ease of data sharing: XML provides a standardized format for sharing data between different systems.</li>
            </ul>

            <h2>Key Methods and Properties</h2>
            <table>
              <thead>
                <tr>
                  <th>Method/Property</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><code>DOMParser</code></td>
                  <td>Parses a string containing XML data and returns an XML document.</td>
                </tr>
                <tr>
                  <td><code>getElementsByTagName</code></td>
                  <td>Returns a list of elements with a specified tag name.</td>
                </tr>
                <tr>
                  <td><code>childNodes</code></td>
                  <td>Returns a collection of a node's child nodes.</td>
                </tr>
                <tr>
                  <td><code>nodeValue</code></td>
                  <td>Gets or sets the value of a node.</td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_dom"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/coming_soon"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
