import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLXPathAxes() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
<bookstore>
  <book category="cooking">
    <title lang="en">Everyday Italian</title>
    <author>Giada De Laurentiis</author>
    <year>2005</year>
    <price>30.00</price>
  </book>
  <book category="children">
    <title lang="en">Harry Potter</title>
    <author>J K. Rowling</author>
    <year>2005</year>
    <price>29.99</price>
  </book>
  <book category="web">
    <title lang="en">Learning XML</title>
    <author>Erik T. Ray</author>
    <year>2003</year>
    <price>39.95</price>
  </book>
</bookstore>
`;

  const example2 = `
XPath: /bookstore/book/title
Result:
<title lang="en">Everyday Italian</title>
<title lang="en">Harry Potter</title>
<title lang="en">Learning XML</title>
`;

  const example3 = `
XPath: /bookstore/book/child::title
Result:
<title lang="en">Everyday Italian</title>
<title lang="en">Harry Potter</title>
<title lang="en">Learning XML</title>
`;

  const example4 = `
XPath: /bookstore/book/descendant::title
Result:
<title lang="en">Everyday Italian</title>
<title lang="en">Harry Potter</title>
<title lang="en">Learning XML</title>
`;

  const example5 = `
XPath: /bookstore/book/attribute::category
Result:
"cooking" "children" "web"
`;

  return (
    <body>
      <Helmet>
        <title>XPath Axes</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML XPath Axes, XML Tutorial, XML Data Querying" />
        <meta name="description" content="Learn how to use XPath axes for querying XML data with examples and explanations of the syntax and usage." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/XML_xpath_functions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xquery"><ButtonNext /></ScrollToTopLink>
          <h1>XPath Axes</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XPath axes are used to define the tree relationship between the selected nodes and the current node. They help in navigating through the XML document structure.</p>

            <h2>Example 1: Basic XML Structure</h2>
            <SyntaxHighlighterComponent code={example1} language="xml" />
            <p>This example shows a basic XML structure with a bookstore containing books. We will use XPath axes to query this data.</p>

            <h2>Example 2: Selecting All Titles</h2>
            <SyntaxHighlighterComponent code={example2} language="xml" />
            <p>This example demonstrates an XPath expression that selects all the titles of the books in the bookstore.</p>

            <h2>Example 3: Using the Child Axis</h2>
            <SyntaxHighlighterComponent code={example3} language="xml" />
            <p>This example demonstrates the use of the <code>child</code> axis to select all child titles of the books in the bookstore.</p>

            <h2>Example 4: Using the Descendant Axis</h2>
            <SyntaxHighlighterComponent code={example4} language="xml" />
            <p>This example demonstrates the use of the <code>descendant</code> axis to select all descendant titles of the books in the bookstore.</p>

            <h2>Example 5: Using the Attribute Axis</h2>
            <SyntaxHighlighterComponent code={example5} language="xml" />
            <p>This example demonstrates the use of the <code>attribute</code> axis to select all category attributes of the books in the bookstore.</p>

            <h2>Understanding XPath Axes</h2>
            <p>XPath axes provide a way to navigate the XML document tree in various directions. Some of the commonly used axes are:</p>

            <ul>
              <li><strong>child:</strong> Selects all children of the current node.</li>
              <li><strong>descendant:</strong> Selects all descendants (children, grandchildren, etc.) of the current node.</li>
              <li><strong>attribute:</strong> Selects all attributes of the current node.</li>
              <li><strong>parent:</strong> Selects the parent of the current node.</li>
              <li><strong>following-sibling:</strong> Selects all siblings after the current node.</li>
              <li><strong>preceding-sibling:</strong> Selects all siblings before the current node.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_xpath_functions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_xquery"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
