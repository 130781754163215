import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyStrings() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const stringCreationCode = `
# Creating Strings
single_quoted = 'Hello, world!'
double_quoted = "Hello, world!"

puts single_quoted  # Output: Hello, world!
puts double_quoted  # Output: Hello, world!
  `;

  const stringInterpolationCode = `
# String Interpolation
name = "Alice"
greeting = "Hello, \#{name}!"

puts greeting  # Output: Hello, Alice!
  `;

  const stringConcatenationCode = `
# String Concatenation
str1 = "Hello, "
str2 = "world!"

result = str1 + str2
puts result  # Output: Hello, world!
  `;

  const stringMethodsCode = `
# String Methods
str = "Hello, world!"

puts str.upcase      # Output: HELLO, WORLD!
puts str.downcase    # Output: hello, world!
puts str.length      # Output: 13
puts str.include?("world")  # Output: true
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Strings</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Strings, Creating Strings, String Interpolation, String Concatenation, String Methods" />
        <meta name="description" content="Learn about strings in Ruby, including how to create, interpolate, concatenate, and use various string methods." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_hash_methods"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_string_methods"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Strings</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Strings in Ruby are sequences of characters enclosed within single or double quotes. They are used to represent text.</p>

            <h2>Creating Strings</h2>
            <p>Strings can be created using single quotes or double quotes.</p>
            <SyntaxHighlighterComponent code={stringCreationCode} language="ruby" />
            <p>In the above example, we create strings using single and double quotes.</p>

            <h2>String Interpolation</h2>
            <p>String interpolation allows you to embed variables and expressions within a string. This is done using <code>{`#{}`}</code> inside a double-quoted string.</p>
            <SyntaxHighlighterComponent code={stringInterpolationCode} language="perl" />
            <p>In the above example, we use string interpolation to embed the value of the <code>name</code> variable within the <code>greeting</code> string.</p>

            <h2>String Concatenation</h2>
            <p>Strings can be concatenated using the <code>+</code> operator.</p>
            <SyntaxHighlighterComponent code={stringConcatenationCode} language="ruby" />
            <p>In the above example, we concatenate two strings to form a single string.</p>

            <h2>String Methods</h2>
            <p>Ruby provides various methods for manipulating strings. Here are a few examples:</p>
            <SyntaxHighlighterComponent code={stringMethodsCode} language="ruby" />
            <p>In the above example, we use methods like <code>upcase</code>, <code>downcase</code>, <code>length</code>, and <code>include?</code> to manipulate and query strings.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_hash_methods"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_string_methods"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
