import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLformselementattr() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <form action="form-handler.html"> 
      <input type="number" max="10" />
      <input type="date" max="2010-10-20" />
      <input type="submit" />
      </form>
  `;
  const code2 = `
  <!-- try inputing betathread without any space then click the space button -->
  <form action="form-handler.html">
  <input type="text" maxlength="8" />
  <input type="submit" />
  </form>

`;
const code3 = `
<input type="number" min="10" />
<input type="date" min="2010-10-20" />
<input type="submit" />
`;
const code4 = `
<!-- This allows only a minimum of eight characters
try typing less than eight characters and clicking the submit button -->
<form action="form-handler.html">
<input type="text" minlength="8" />
<input type="submit" />
</form>
`;

const code5 = `
<label>
<input type="text" autocomplete="on" />
</label>
<br />
<label>
<input type="text" autocomplete="off" />
</label>
<br />
<input type="submit" />
`;

const code6 = `
<input type="text" placeholder="Type your text here" />
<input type="submit" />
`;

const code7 = `
<input type="text" required />
<input type="submit" />
`;


    return (
   <body>
        <Helmet>    
        <title>HTML5 Forms Element Attributes</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML5 Forms Element Attributes, Boolean Attributes, max Attribute, maxlength Attribute, min Attribute, minlength Attribute, autocomplete Attribute, placeholder Attribute
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
       <meta name="description" content="In HTML the various form elements have attributes and in this lesson we will learn about HTML5 attributes(remember that HTML5 is the current version of HTML)." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_forms"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_forms_attr2"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML5 Forms Element Attributes</h1>
</header>

<Navbar />

<main>

<section>
<p>In HTML the various form elements have attributes and in this lesson we will learn about HTML5 attributes(remember that HTML5 is the 
    current version of HTML).</p>
<p>The form element such as input, textarea etc have the various attributes we are going to explain.</p>
  <h2>HTML5 Forms Element Attribute</h2>
  <h3>Boolean Attributes</h3>
  <p>These are attributes that do not have a value but they only need to be included at the start tag.</p>
  <p>They include:</p>
  <li>required Attribute</li>
  <li>autofocus Attribute</li>
  <li>multiple Attribute</li>
  <li>readonly Attribute</li>
  <li>disabled Attribute</li>
  <p>We will further explain them in both in this lesson and the next.</p>
  <p>The form element attributes include:</p>
  <h3>max Attribute</h3>
  <p>This attribute indicates a maximum value for a form control.</p>
  <br />
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html63"><Tryitout /></ScrollToTopLink>

<h3>maxlength Attribute</h3>
<p>This indicates the maximum number of characters that can be inputed in a text field.</p>
<br />
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html64"><Tryitout /></ScrollToTopLink>
</section>

<section>
<h3>min Attribute</h3>
    <p>This attribute indicates a minimum value for a form control.</p>
  <br />
  <SyntaxHighlighterComponent code={code3} language="html" />
  <ScrollToTopLink to="/try_html65"><Tryitout /></ScrollToTopLink>

  <h3>minlength Attribute</h3>
    <p>This indicates the minimum number of characters that can be inputed in a text field</p>
      <br />
      <SyntaxHighlighterComponent code={code4} language="html" />
<ScrollToTopLink to="/try_html66"><Tryitout /></ScrollToTopLink>
<br />

    <h3>autocomplete Attribute</h3>
    <p>This specifies whether or not the browser can complete a users text value.</p>
    <p>This attribute contains two values either "on" or "off"</p>
    <br />
    <SyntaxHighlighterComponent code={code5} language="html" />
<ScrollToTopLink to="/try_html67"><Tryitout /></ScrollToTopLink>

<br />

    <h3>placeholder Attribute</h3>
    <p>This attribute sort of a hint telling the user what to type into the text field</p>
    <br />
    <SyntaxHighlighterComponent code={code6} language="html" />
<ScrollToTopLink to="/try_html68"><Tryitout /></ScrollToTopLink>

<br />
<p>Below is another example of a form with the method post and autocomplete on</p>
    <br />
    <SyntaxHighlighterComponent code={code7} language="html" />
<ScrollToTopLink to="/try_html69"><Tryitout /></ScrollToTopLink>
</section>
<div className="head">
<ScrollToTopLink to="/HTML_forms"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_forms_attr2"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}