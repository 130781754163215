import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLXqueryAxes() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const example1 = `
let \$books := <books>
  <book>
    <title>Book 1</title>
    <author>Author 1</author>
  </book>
  <book>
    <title>Book 2</title>
    <author>Author 2</author>
  </book>
</books>
return \$books/book/title
`;

  const example2 = `
let \$books := <books>
  <book>
    <title>Book 1</title>
    <author>Author 1</author>
  </book>
  <book>
    <title>Book 2</title>
    <author>Author 2</author>
  </book>
</books>
return \$books//title
`;

  const example3 = `
let \$books := <books>
  <book>
    <title>Book 1</title>
    <author>Author 1</author>
  </book>
  <book>
    <title>Book 2</title>
    <author>Author 2</author>
  </book>
</books>
return \$books/book[1]/title
`;

  return (
    <body>
      <Helmet>
        <title>XQuery Axes</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML XQuery Axes, XQuery Tutorial, XML Axes" />
        <meta name="description" content="Learn about XML XQuery Axes with multiple examples and detailed explanations." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/XML_xquery_functions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_data_binding"><ButtonNext /></ScrollToTopLink>
          <h1>XQuery Axes</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>XQuery Axes are used to navigate through the elements and attributes in XML documents. They are crucial for locating and extracting specific data within an XML structure.</p>

            <h2>Common XQuery Axes</h2>
            <ul>
              <li><strong>child:</strong> Selects children of the current node.</li>
              <li><strong>descendant:</strong> Selects all descendants of the current node.</li>
              <li><strong>attribute:</strong> Selects attributes of the current node.</li>
              <li><strong>self:</strong> Selects the current node.</li>
              <li><strong>descendant-or-self:</strong> Selects the current node and all descendants.</li>
              <li><strong>following-sibling:</strong> Selects all siblings after the current node.</li>
              <li><strong>preceding-sibling:</strong> Selects all siblings before the current node.</li>
            </ul>

            <h2>Example: Using Child Axis</h2>
            <SyntaxHighlighterComponent code={example1} language="xquery" />
            <p>This example uses the child axis to select the title elements directly under each book element.</p>

            <h2>Example: Using Descendant Axis</h2>
            <SyntaxHighlighterComponent code={example2} language="xquery" />
            <p>This example uses the descendant axis to select all title elements within the books element, regardless of their depth.</p>

            <h2>Example: Using Positional Predicates</h2>
            <SyntaxHighlighterComponent code={example3} language="xquery" />
            <p>This example uses a positional predicate to select the title of the first book element.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_xquery_functions"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_data_binding"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
