import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import Tryitout from "../../components/Tryitoutbutton";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLfigures() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
        }
        var secondCode = {
            color: 'black'
        }
      const code1 = `
      <!-- An image as a figure -->
    <figure>
    <img src="A Ship.jpg" />
    <figcaption>Figure 10.4 I am a figcaption</figcaption>
    </figure>
  `;

  const code2 = `
    <!-- Marking a poem -->
    <figure>
    The old dinner just down town,
    has been around for a long time,
    the people there always frown,
    because they have no dime.
    </figure>
    <figcaption>The only dime By: Oruche Chukwudumebi</figcaption>
`;

  
    return (
   <body>
      <Helmet>    
        <title>HTML Figures</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Figures, What is HTML figures, HTML figures example, HTML <figure> element,
        Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
       <meta name="description" content="A figure in HTML can be related to a single item or unit and it is a self-comprised content.
       A figure can be an image, illustration or diagram and the <figure> element is used to create figures." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />

    <div className="content">
<header className="headertutorials" style={newCode}>
  
<ScrollToTopLink to="/HTML_superscript"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_nbsp"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Figures</h1>
</header>

<Navbar />

<main>

<section>
<p>A figure in HTML can be related to a single item or unit and it is a self-comprised content.</p>
<p>A figure can be an image, illustration or diagram and the &lt;figure&gt; element is used to create figures.</p>
<p>Inside the &lt;figure&gt; element a caption can be specified using the &lt;figcaption&gt; element.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code1} language="html" />
<ScrollToTopLink to="/try_html47"><Tryitout /></ScrollToTopLink>

<p>The &lt;figure&gt; element can be used for other purposes e.g,a poem can be marked using the &lt;figure&gt; element. </p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="html" />
<ScrollToTopLink to="/try_html48"><Tryitout /></ScrollToTopLink>
</section>
 

<div className="head">
<ScrollToTopLink to="/HTML_superscript"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_nbsp"><ButtonNext /></ScrollToTopLink>

</div>

</main>
</div>
<Footertutorials />
   </body>
   
    )
}