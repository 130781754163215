import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ScrollToTopLink from "./ScrollToTop"; // Assuming you have defined this component
import '../css/my style.css'; // Update the file path accordingly
import { getAuthStatus } from "../localstorage";
import { FaBook, FaChartLine, FaCogs, FaHome, FaLock, FaPhone, FaTasks, FaTimes } from "react-icons/fa";

export default function Topnav() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const isAuthenticated = getAuthStatus();

  const code = {
    backgroundColor: "darkblue",
    color: "white",
    textDecoration: "none"
  };
  const code2 = {
    backgroundColor: "darkblue",
    color: "white",
    textDecoration: "none",
    float: "left"
  };

  return (
    <div>
      <div className="burger-icon2" onClick={toggle}>
      &nbsp;&nbsp;
      Menu &#9776;
     
        </div>
        
      <div className={`topnav ${isOpen ? 'responsive' : 'closed'}`} id="mytopnav">

        <ScrollToTopLink>&nbsp;<span className="close2" onClick={toggle}><FaTimes /></span></ScrollToTopLink>
        {isAuthenticated ? (
        <ScrollToTopLink to="/dashboard"><a className={location.pathname === '/dashboard' ? 'active' : ''}><FaChartLine />DASHBOARD</a></ScrollToTopLink>
      
           ) : (
            <ScrollToTopLink to="/"><a className={location.pathname === '/' ? 'active' : ''}><FaHome />HOME</a></ScrollToTopLink>
    
           )}  <ScrollToTopLink to="/about"><a className={location.pathname === '/about' ? 'active' : ''}><FaBook /> ABOUT US</a></ScrollToTopLink>
        <ScrollToTopLink to="/contact"><a className={location.pathname === '/contact' ? 'active' : ''}><FaPhone />CONTACT US</a></ScrollToTopLink>
        <ScrollToTopLink to="/services"><a className={location.pathname === '/services' ? 'active' : ''}><FaCogs />OUR SERVICES</a></ScrollToTopLink>
        <ScrollToTopLink to="/projects"><a className={location.pathname === '/projects' ? 'active' : ''}><FaTasks />PROJECTS</a> </ScrollToTopLink>
         <ScrollToTopLink to="/terms&privacy"><a className={location.pathname === '/terms&privacy' ? 'active' : ''}><FaLock /> PRIVACY POLICY</a></ScrollToTopLink>
      
        </div>
       
    </div>
  );
}