import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSDisplayOutput() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
    function sum() {
    var x = 5;
    var y = 6;
    var z = x + y;
    document.write(z);
    }
`;
const code2 = `
<p id="demo"></p>
<script>
function sum() {
var x = 5;
var y = 6;
var z = x + y;
document.getElementById("demo").innerHTML = z;
}
</script>
`;

    return (
   <body>
        <Helmet>
    
    <title>JavaScript Display Output</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="JavaScript Display Output, Step-by-step JavaScript programming tutorials, JavaScript coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
   JavaScript programming tips for real-world applications," />
  <meta name="description" content="Learn how to display output in JavaScript, streamline code organization, 
  enhance readability, and unlock advanced features in JavaScript development.When learning JavaScript you need to know how to display the output of the code which has been entered." />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_syntax"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_method"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Display Output</h1>
</header>

<Navbar />

<main>
    <section>
    <p>When learning JavaScript you need to know how to display the output of the code which has been entered.
</p>
<p>Their are different methods we can use to generate output, they are:</p>
<li>document.getElementById() method</li>
<li>document.write() method</li>
<li>console.log() method</li>
<li>window.alert() method</li>
<p>All the listed methods above will be explained in the next lesson.</p>
<p>Here is an example of how they are used.</p>
 
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js8"><Tryitout /></ScrollToTopLink>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js9"><Tryitout /></ScrollToTopLink>
</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_syntax"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_method"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}