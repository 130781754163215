import React, { useState } from "react";
import "../../css/navigation.css";
import "../../css/my style.css";
import "../../css/my style2.css";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../ScrollToTop";
import { useLocation } from 'react-router-dom';
import XMLSearchBar from "../Searchbar/XML SearchBar/XMLSearchBar";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export default function Sidenav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isXPathOpen, setIsXPathOpen] = useState(false);
  const [isXQueryOpen, setIsXQueryOpen] = useState(false);
  const [isAJAXOpen, setIsAJAXOpen] = useState(false);
  const [isXMLDataQueryingOpen, setIsXMLDataQueryingOpen] = useState(false);
  const [isXMLDataTransformationOpen, setIsXMLDataTransformationOpen] = useState(false);
  const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleXPath = () => {
    setIsXPathOpen(!isXPathOpen);
  };

  const toggleXQuery = () => {
    setIsXQueryOpen(!isXQueryOpen);
  };

  const toggleAJAX = () => {
    setIsAJAXOpen(!isAJAXOpen);
  };

  const toggleXMLDataQuerying = () => {
    setIsXMLDataQueryingOpen(!isXMLDataQueryingOpen);
  };

  const toggleXMLDataTransformation = () => {
    setIsXMLDataTransformationOpen(!isXMLDataTransformationOpen);
  };

  const toggleDropdown5 = () => {
    setIsDropdownOpen5(!isDropdownOpen5);
  };

  const nav = {
    color: 'white',
    fontSize: '30px'
  };

  const newCode = {
    float: 'right'
  };

  return (
    <div>
      <div className={`sidebar ${isOpen ? 'responsive' : ''}`} id="mySidebar">
        <span className="close" onClick={toggle}>&times;</span>
        <h2>XML Tutorial</h2><br />
        <span><XMLSearchBar /></span><br />
        <p className="p-sidebar">...Empowering Your Online Presence...</p><br />

        {/* Main Links */}
        <ScrollToTopLink to="/XML_Tutorial_intro"><a className={location.pathname === '/XML_tutorial_intro' ? 'active' : ''}>XML Introduction</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_syntax"><a className={location.pathname === '/XML_syntax' ? 'active' : ''}>XML Syntax</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_tree"><a className={location.pathname === '/XML_tree' ? 'active' : ''}>XML Tree</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_elements"><a className={location.pathname === '/XML_elements' ? 'active' : ''}>XML Elements</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_attributes"><a className={location.pathname === '/XML_attributes' ? 'active' : ''}>XML Attributes</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_declaration"><a className={location.pathname === '/XML_declaration' ? 'active' : ''}>XML Declaration</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_comments"><a className={location.pathname === '/XML_comments' ? 'active' : ''}>XML Comments</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_versioning"><a className={location.pathname === '/XML_versioning' ? 'active' : ''}>XML Versioning</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_encoding"><a className={location.pathname === '/XML_encoding' ? 'active' : ''}>XML Encoding</a></ScrollToTopLink><br />
        <h2>XML Data Manipulation</h2>
        <ScrollToTopLink to="/XML_data_manipulation"><a className={location.pathname === '/XML_data_manipulation' ? 'active' : ''}>XML Data Manipulation</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_data_insertion"><a className={location.pathname === '/XML_data_insertion' ? 'active' : ''}>XML Data Insertion</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_data_modification"><a className={location.pathname === '/XML_data_modification' ? 'active' : ''}>XML Data Modification</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_data_deletion"><a className={location.pathname === '/XML_data_deletion' ? 'active' : ''}>XML Data Deletion</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_data_querying"><a className={location.pathname === '/XML_data_querying' ? 'active' : ''}>XML Data Querying</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_data_transformation"><a className={location.pathname === '/XML_data_transformation' ? 'active' : ''}>XML Data Transformation</a></ScrollToTopLink><br />

        {/* Dropdowns */}
        <h2>XML Data Querying</h2>
        <div className="dropdown">
          <a className="dropdown-toggle" onClick={toggleXPath}>XPath Tutorial {isXPathOpen ? <FaChevronUp /> : <FaChevronDown />}</a><br />
          {isXPathOpen && (
            <div className="dropdown-content">
              <ScrollToTopLink to="/XML_xpath"><a className={location.pathname === '/XML_xpath_introduction' ? 'active' : ''}>XPath Introduction</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/XML_xpath_expressions"><a className={location.pathname === '/XML_xpath_expressions' ? 'active' : ''}>XPath Expressions</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/XML_xpath_functions"><a className={location.pathname === '/XML_xpath_functions' ? 'active' : ''}>XPath Functions</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/XML_xpath_axes"><a className={location.pathname === '/XML_xpath_axes' ? 'active' : ''}>XPath Axes</a></ScrollToTopLink><br />
            </div>
          )}
        </div>

        <div className="dropdown">
          <a className="dropdown-toggle" onClick={toggleXQuery}>XQuery Tutorial {isXQueryOpen ? <FaChevronUp /> : <FaChevronDown />}</a><br />
          {isXQueryOpen && (
            <div className="dropdown-content">
              <ScrollToTopLink to="/XML_xquery"><a className={location.pathname === '/XML_xquery_introduction' ? 'active' : ''}>XQuery Introduction</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/XML_xquery_expressions"><a className={location.pathname === '/XML_xquery_expressions' ? 'active' : ''}>XQuery Expressions</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/XML_xquery_functions"><a className={location.pathname === '/XML_xquery_functions' ? 'active' : ''}>XQuery Functions</a></ScrollToTopLink><br />
              <ScrollToTopLink to="/XML_xquery_axes"><a className={location.pathname === '/XML_xquery_axes' ? 'active' : ''}>XQuery Axes</a></ScrollToTopLink><br />
            </div>
          )}
        </div>

        <h2>XML Data Binding</h2>
        <ScrollToTopLink to="/XML_data_binding"><a className={location.pathname === '/XML_data_binding' ? 'active' : ''}>XML Data Binding</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_serialization"><a className={location.pathname === '/XML_serialization' ? 'active' : ''}>XML Serialization</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_deserialization"><a className={location.pathname === '/XML_deserialization' ? 'active' : ''}>XML Deserialization</a></ScrollToTopLink><br />
     
        <h2>XML and Web Development</h2>
        <div className="dropdown">
        <a className="dropdown-toggle" onClick={toggleDropdown5}>
          XML and AJAX {isDropdownOpen5 ? <FaChevronUp /> : <FaChevronDown />}
        </a><br />
        {isDropdownOpen5 && (
          <div className="dropdown-content">
        <ScrollToTopLink to="/XML_ajax"><a className={location.pathname === '/XML_ajax' ? 'active' : ''}>AJAX Introduction</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_ajax_xmlhttp"><a className={location.pathname === '/XML_ajax_xmlhttp' ? 'active' : ''}>AJAX XMLHttp</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_ajax_requests"><a className={location.pathname === '/XML_ajax_requests' ? 'active' : ''}>AJAX Requests</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_ajax_responses"><a className={location.pathname === '/XML_ajax_responses' ? 'active' : ''}>AJAX Responses</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_ajax_Parsing"><a className={location.pathname === '/XML_ajax_parsing' ? 'active' : ''}>AJAX XML Parsing</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_ajax_php"><a className={location.pathname === '/XML_ajax_oho' ? 'active' : ''}>AJAX PHP</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_ajax_applications"><a className={location.pathname === '/XML_ajax_applications' ? 'active' : ''}>AJAX Applications</a></ScrollToTopLink><br />
          </div>
        )}
      </div>
        <ScrollToTopLink to="/XML_dom"><a className={location.pathname === '/XML_dom' ? 'active' : ''}>XML DOM</a></ScrollToTopLink><br />
        <ScrollToTopLink to="/XML_html5"><a className={location.pathname === '/XML_html5' ? 'active' : ''}>XML and HTML5</a></ScrollToTopLink><br />

      </div>

    </div>
  );
}
