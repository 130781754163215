import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from "../../css/styles.module.css";
import "../../css/success-modal.css";
import ScrollToTopLink from "../../components/ScrollToTop";
import { FaEnvelope, FaEye, FaEyeSlash, FaLock, FaTimes } from "react-icons/fa";

function ResetPassword() {
    const [newPassword, setNewPassword] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');

    const handleGoBack = () => {
        navigate(-1); // Go back to the previous route
      };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!newPassword) {
        alert("Please enter your new password");
        return;
      }
      try {
        const response = await fetch("https://betathread.com/auth/reset-password", {
          method: "POST",
          body: JSON.stringify({ token, newPassword }),
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        const data = await response.json();
  
        if (!response.ok) {
          throw new Error(data.error || "Failed to reset password. Please try again.");
        }
  
        alert(data.message);
        navigate("/login");
      } catch (error) {
        console.error(error);
        alert(error.message);
      }
    };
  return (
    <body>
      <Helmet>
        <title>Login | Beta Thread</title>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-compatible" content="IE-edge" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      </Helmet>

      <div className={styles.logoContainer}>
        <ScrollToTopLink to="/">
          <img src="./images/betathread-light.png" className={styles.homebtn} alt="Home Page" />
        </ScrollToTopLink>
      </div>
   
      <div className={styles.container}>
        <div className={styles.form_container}>
          <div className={styles.left}>
            <img className={styles.img} src="./images/login.jpg" alt="forgot password" />
          </div>

          <div className={styles.right}>
            <h2 className={styles.heading}>Reset Password</h2><br /><br /><br />
           
            <form onSubmit={handleSubmit}>
              <div method="post">
              <div className="input-cnt">
              <input
                type="password"
                name="newPassword"
                value={newPassword}
                className="input"
                placeholder="Enter your new password"
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
          
                  <i className="material-icons"><FaEnvelope /></i>
                </div>
              
                <br />

               
                <button className="button"
                  style={{ backgroundColor: "darkblue", color: "white", width: "100%", borderRadius: "10px", fontSize: "14px", fontWeight: "normal", fontFamily: "inherit" }}
                  type="submit">
                 Reset Password
                </button>

                <br />
              </div>
            </form>
          </div>
        </div>
      </div>
    </body>
  );
}

export default ResetPassword;
