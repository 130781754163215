import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyOperators() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const arithmeticOperatorsCode = `
# Arithmetic Operators
a = 10
b = 5

puts a + b   # Output: 15
puts a - b   # Output: 5
puts a * b   # Output: 50
puts a / b   # Output: 2
puts a % b   # Output: 0
puts a ** b  # Output: 100000
  `;

  const comparisonOperatorsCode = `
# Comparison Operators
a = 10
b = 5

puts a == b  # Output: false
puts a != b  # Output: true
puts a > b   # Output: true
puts a < b   # Output: false
puts a >= b  # Output: true
puts a <= b  # Output: false
  `;

  const logicalOperatorsCode = `
# Logical Operators
a = true
b = false

puts a && b  # Output: false
puts a || b  # Output: true
puts !a      # Output: false
  `;

  const assignmentOperatorsCode = `
# Assignment Operators
a = 10
puts a       # Output: 10

a += 5
puts a       # Output: 15

a -= 3
puts a       # Output: 12

a *= 2
puts a       # Output: 24

a /= 4
puts a       # Output: 6

a %= 4
puts a       # Output: 2
  `;

  const rangeOperatorsCode = `
# Range Operators
range1 = (1..5)  # Includes 1 to 5
range2 = (1...5) # Includes 1 to 4

puts range1.to_a # Output: [1, 2, 3, 4, 5]
puts range2.to_a # Output: [1, 2, 3, 4]
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Operators</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Operators, Arithmetic Operators, Comparison Operators, Logical Operators, Assignment Operators, Range Operators" />
        <meta name="description" content="Learn about operators in Ruby, including arithmetic, comparison, logical, assignment, and range operators." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_constants"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_conditionals"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Operators</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Operators in Ruby are used to perform various operations on variables and values. Ruby has a wide range of operators, including arithmetic, comparison, logical, assignment, and range operators.</p>

            <h2>Arithmetic Operators</h2>
            <p>Arithmetic operators are used to perform basic mathematical operations like addition, subtraction, multiplication, division, modulus, and exponentiation.</p>
            <SyntaxHighlighterComponent code={arithmeticOperatorsCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>a + b</code> adds <code>a</code> and <code>b</code>.</li>
              <li><code>a - b</code> subtracts <code>b</code> from <code>a</code>.</li>
              <li><code>a * b</code> multiplies <code>a</code> by <code>b</code>.</li>
              <li><code>a / b</code> divides <code>a</code> by <code>b</code>.</li>
              <li><code>a % b</code> returns the remainder when <code>a</code> is divided by <code>b</code>.</li>
              <li><code>a ** b</code> raises <code>a</code> to the power of <code>b</code>.</li>
            </ul>

            <h2>Comparison Operators</h2>
            <p>Comparison operators are used to compare two values. They return a boolean value indicating the result of the comparison.</p>
            <SyntaxHighlighterComponent code={comparisonOperatorsCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>a == b</code> checks if <code>a</code> is equal to <code>b</code>.</li>
              <li><code>a != b</code> checks if <code>a</code> is not equal to <code>b</code>.</li>
              <li><code>a &gt; b</code> checks if <code>a</code> is greater than <code>b</code>.</li>
              <li><code>a &lt; b</code> checks if <code>a</code> is less than <code>b</code>.</li>
              <li><code>a &gt;= b</code> checks if <code>a</code> is greater than or equal to <code>b</code>.</li>
              <li><code>a &lt;= b</code> checks if <code>a</code> is less than or equal to <code>b</code>.</li>
            </ul>

            <h2>Logical Operators</h2>
            <p>Logical operators are used to combine two or more conditions.</p>
            <SyntaxHighlighterComponent code={logicalOperatorsCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>a && b</code> returns <code>true</code> if both <code>a</code> and <code>b</code> are true.</li>
              <li><code>a || b</code> returns <code>true</code> if either <code>a</code> or <code>b</code> is true.</li>
              <li><code>!a</code> returns <code>true</code> if <code>a</code> is false.</li>
            </ul>

            <h2>Assignment Operators</h2>
            <p>Assignment operators are used to assign values to variables. Ruby also provides shorthand operators for performing arithmetic operations and assignment at the same time.</p>
            <SyntaxHighlighterComponent code={assignmentOperatorsCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>a = 10</code> assigns the value <code>10</code> to <code>a</code>.</li>
              <li><code>a += 5</code> adds <code>5</code> to <code>a</code> and then assigns the result to <code>a</code>.</li>
              <li><code>a -= 3</code> subtracts <code>3</code> from <code>a</code> and then assigns the result to <code>a</code>.</li>
              <li><code>a *= 2</code> multiplies <code>a</code> by <code>2</code> and then assigns the result to <code>a</code>.</li>
              <li><code>a /= 4</code> divides <code>a</code> by <code>4</code> and then assigns the result to <code>a</code>.</li>
              <li><code>a %= 4</code> assigns the remainder of <code>a</code> divided by <code>4</code> to <code>a</code>.</li>
            </ul>

            <h2>Range Operators</h2>
            <p>Range operators are used to create sequences. The two dot operator (<code>..</code>) creates an inclusive range, while the three dot operator (<code>...</code>) creates an exclusive range.</p>
            <SyntaxHighlighterComponent code={rangeOperatorsCode} language="ruby" />
            <p>In the above example:</p>
            <ul>
              <li><code>(1..5)</code> creates a range that includes <code>1</code> to <code>5</code>.</li>
              <li><code>(1...5)</code> creates a range that includes <code>1</code> to <code>4</code>.</li>
            </ul>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_constants"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_conditionals"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
