import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/XMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function XMLDOM() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };

  const code1 = `const parser = new DOMParser();
const xmlString = \`
<bookstore>
  <book>
    <title>Learning XML</title>
    <author>John Doe</author>
    <year>2024</year>
  </book>
</bookstore>
\`;
const xmlDoc = parser.parseFromString(xmlString, "application/xml");
console.log(xmlDoc);`;

  const code2 = `const books = xmlDoc.getElementsByTagName("book");
const title = books[0].getElementsByTagName("title")[0].childNodes[0].nodeValue;
console.log(title);  // Output: Learning XML`;

  const code3 = `const newTitle = xmlDoc.createElement("title");
newTitle.appendChild(xmlDoc.createTextNode("Advanced XML"));
books[0].replaceChild(newTitle, books[0].getElementsByTagName("title")[0]);`;

  const code4 = `const newBook = xmlDoc.createElement("book");
const newTitle = xmlDoc.createElement("title");
newTitle.appendChild(xmlDoc.createTextNode("XML in Action"));
newBook.appendChild(newTitle);

const newAuthor = xmlDoc.createElement("author");
newAuthor.appendChild(xmlDoc.createTextNode("Jane Doe"));
newBook.appendChild(newAuthor);

xmlDoc.documentElement.appendChild(newBook);`;

  const code5 = `const serializer = new XMLSerializer();
const xmlStringModified = serializer.serializeToString(xmlDoc);
console.log(xmlStringModified);`;

  return (
    <body>
      <Helmet>
        <title>XML DOM</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="XML DOM, XML Tutorial, XML DOM Methods" />
        <meta name="description" content="Learn about XML DOM (Document Object Model) with examples and explanations of the syntax and usage." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
        <ScrollToTopLink to="/XML_ajax_applications"><ButtonPrevious /></ScrollToTopLink>
        <ScrollToTopLink to="/XML_html5"><ButtonNext /></ScrollToTopLink>
          <h1>XML DOM</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>The XML DOM (Document Object Model) is a programming interface for XML documents. It represents the structure of an XML document as a tree of objects, allowing you to programmatically navigate, manipulate, and modify the document's content.</p>

            <h2>Structure of the XML DOM</h2>
            <p>Here's an example of a simple XML document:</p>
            <SyntaxHighlighterComponent code={`<bookstore>
  <book>
    <title>Learning XML</title>
    <author>John Doe</author>
    <year>2024</year>
  </book>
</bookstore>`} language="xml" />

            <p>This XML document can be represented as a tree:</p>
            <ul>
              <li><code>bookstore</code> (root element)
                <ul>
                  <li><code>book</code> (child of <code>bookstore</code>)
                    <ul>
                      <li><code>title</code> (child of <code>book</code>)</li>
                      <li><code>author</code> (child of <code>book</code>)</li>
                      <li><code>year</code> (child of <code>book</code>)</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>

            <h2>Accessing and Manipulating XML with JavaScript</h2>
            <p>To work with the XML DOM in JavaScript, you typically use the <code>DOMParser</code> and <code>XMLSerializer</code> objects, along with various DOM methods. Here are some examples:</p>

            <h3>Parsing XML</h3>
            <SyntaxHighlighterComponent code={code1} language="javascript" />
            <p>This code parses an XML string into an XML DOM object.</p>

            <h3>Accessing Elements</h3>
            <SyntaxHighlighterComponent code={code2} language="javascript" />
            <p>This code accesses elements within the XML document and retrieves the text content of the <code>&lt;title&gt;</code> element.</p>

            <h3>Modifying Elements</h3>
            <SyntaxHighlighterComponent code={code3} language="javascript" />
            <p>This code creates a new <code>&lt;title&gt;</code> element and replaces the existing <code>&lt;title&gt;</code> element with it.</p>

            <h3>Adding New Elements</h3>
            <SyntaxHighlighterComponent code={code4} language="javascript" />
            <p>This code creates a new <code>&lt;book&gt;</code> element with <code>&lt;title&gt;</code> and <code>&lt;author&gt;</code> children, and appends it to the <code>&lt;bookstore&gt;</code> root element.</p>

            <h3>Serializing XML</h3>
            <SyntaxHighlighterComponent code={code5} language="javascript" />
            <p>This code serializes the modified XML DOM back into a string.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/XML_ajax_applications"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/XML_html5"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
