import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PHPSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import PHPMetatag from "../../components/PHP_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function PHPDataTypes() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    $num = 10;
    $negative_num = -20;

    `;
    const code2 = `
    $float_num = 3.14;
    $exp_num = 6.02e23;
    
    `;
    const code3 = `
    $str_single = 'Hello';
    $str_double = "World";
    
    `;

    const code4 = `
    $is_active = true;
    $is_learning = true;
    $is_logged_in = false;
    $is_sunny = false;

    `;

    const code5 = `
    $numeric_array = [1, 2, 3, 4, 5];
    $fruits_array = [apple, banana, pineapple, mango];
    $assoc_array = ['name' => 'John', 'age' => 30];

    `;

    const code6 = `
    class Person {
        public $name;
        public $age;
    }
    
    $person1 = new Person();
    $person1->name = 'Alice';
    $person1->age = 25;
    

    `;

    const code7 = `
    $null_var = null;

    `;

    const code8 = `
    $file_handle = fopen("file.txt", "r");

    `;
    return (
   <body>
      <Helmet>
        <title>PHP Data Types</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="PHP Syntax, Escaping to PHP, Canonical PHP Tags, HTML Script Tags, Short-Open Tags(SGML-style), ASP-style Tags, Case Sensitive, Whitespaces,
       Step-by-step PHP programming tutorials, PHP coding exercises for beginners, Advanced PHP coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
       PHP programming tips for real-world applications," />
      <meta name="description" content="Learn how PHP variables are used to store information, streamline code organization, 
      enhance readability, and unlock advanced features in PHP development. " />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/PHP_variables"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_echo"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>PHP Data Types</h1>
</header>

<Navbar />

<main>
<section>
<p>In programming, data types define the type of data that can be stored and manipulated within a program. </p>
<p>PHP, like many other programming languages, supports various data types, each with its own set of operations and rules for manipulation.</p>
<p>Understanding data types is crucial for writing efficient and error-free code.</p>
<p>The following ae the data types we have in php:</p>
<h2>Integer</h2>
<p>Integers are whole numbers without any decimal point and they can either be positive or negative.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code} language="php" />

<h2>Float (Floating Point Number or Double)</h2>
<p>Floats represent numbers with a decimal point or in exponential form.</p>
<p>They are used for representing fractional values.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code2} language="php" />

<h2>String</h2>
<p>Strings represent sequences of characters, enclosed within single quotes ('') or double quotes ("").</p>
<p>They can contain letters, numbers, symbols, and whitespace.</p>
<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code3} language="php" />

<h2>Boolean</h2>
<p>Booleans represent true or false values and are often used in conditional statements and logical operations.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code4} language="php" />

<h2>Array</h2>
<p>Arrays are used to store multiple values in a single variable.</p>
<p>An array can hold elements of different data types and are indexed numerically or associatively.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code5} language="php" />

<h2>Object</h2>
<p>Objects are instances of user-defined classes.</p>
<p>They encapsulate data for manipulation and define behaviors through methods.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code6} language="php" />

<h2>NULL</h2>
<p>NULL represents a variable with no value or an undefined value.</p>
<p>It is often used to indicate the absence of a meaningful value.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code7} language="php" />

<h2>Resource</h2>
<p>Resources are special variables holding references to external resources (like file handles or database connections).</p>
<p>They encapsulate data for manipulation and define behaviors through methods.</p>

<br />
<h2>Example</h2>
<SyntaxHighlighterComponent code={code8} language="php" />
</section>

</main>
<div className="head">
<ScrollToTopLink to="/PHP_variables"><ButtonNext /></ScrollToTopLink>
<ScrollToTopLink to="/PHP_echo"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}