export const phpData = [
  {
    question: "What is PHP?",
    answer: "PHP(Hypertext Preprocessor) is a server-side scripting language designed for web development."
  },
  {
    question: "PHP",
    answer: "PHP(Hypertext Preprocessor) is a server-side scripting language designed for web development."
  },
  {
    question: "What did PHP stand for before",
    answer: "PHP was formally known as Personal Home Page."
  },
  {
    question: "What was the PHP formally known for",
    answer: "PHP was formally known as Personal Home Page."
  },
  {
    question: "Who created PHP?",
    answer: "PHP was created by Rasmus Lerdorf in 1994."
  },
  {
    question: "What does PHP stand for?",
    answer: "PHP originally stood for Personal Home Page, but now it stands for PHP: Hypertext Preprocessor."
  },
  {
    question: "What is the latest stable version of PHP?",
    answer: "As of now, the latest stable version of PHP is PHP 8."
  },
  {
    question: "What are some features of PHP 8?",
    answer: "PHP 8 introduced features like JIT compiler, union types, named arguments, and more."
  },
  {
    question: "How do you start a PHP script?",
    answer: "You can start a PHP script by opening a PHP tag <?php and end it with ?>."
  },
  {
    question: "What is the file extension for PHP files?",
    answer: "The file extension for PHP files is .php."
  },
  {
    question: "What is a PHP echo statement used for?",
    answer: "A PHP echo statement is used to output data to the screen."
  },
  {
    question: "What is a PHP variable?",
    answer: "A PHP variable is used to store data or values."
  },
  {
    question: "How do you declare a variable in PHP?",
    answer: "You declare a variable in PHP using the $ sign followed by the variable name."
  },
  {
    question: "What is a PHP function?",
    answer: "A PHP function is a block of code that can be repeatedly called."
  },
  {
    question: "How do you define a function in PHP?",
    answer: "You define a function in PHP using the function keyword followed by the function name and parameters."
  },
  {
    question: "What is the use of the PHP include statement?",
    answer: "The PHP include statement is used to include the contents of another PHP file."
  },
  {
    question: "What is the difference between include and require in PHP?",
    answer: "Include and require both include a file, but require will produce a fatal error if the file is not found, while include will only produce a warning."
  },
  {
    question: "What is a PHP class?",
    answer: "A PHP class is a blueprint for creating objects."
  },
  {
    question: "How do you define a class in PHP?",
    answer: "You define a class in PHP using the class keyword followed by the class name."
  },
  {
    question: "What is object-oriented programming (OOP) in PHP?",
    answer: "Object-oriented programming (OOP) in PHP is a programming paradigm based on the concept of objects."
  },
  {
    question: "What are access modifiers in PHP?",
    answer: "Access modifiers in PHP control the visibility of class properties and methods."
  },
  {
    question: "What are the access modifiers in PHP?",
    answer: "The access modifiers in PHP are public, private, and protected."
  },
  {
    question: "What is inheritance in PHP?",
    answer: "Inheritance in PHP allows a class to inherit properties and methods from another class."
  },
  {
    question: "How do you implement inheritance in PHP?",
    answer: "You implement inheritance in PHP using the extends keyword."
  },
  {
    question: "What is method overriding in PHP?",
    answer: "Method overriding in PHP allows a subclass to provide a specific implementation of a method that is already provided by its parent class."
  },
  {
    question: "What is method overloading in PHP?",
    answer: "Method overloading in PHP is the ability to define multiple methods with the same name but with different parameters."
  },
  {
    question: "What is a PHP interface?",
    answer: "A PHP interface defines a contract that implementing classes must follow."
  },
  {
    question: "How do you define an interface in PHP?",
    answer: "You define an interface in PHP using the interface keyword followed by the interface name."
  },
  {
    question: "What is a namespace in PHP?",
    answer: "A namespace in PHP is used to organize code into logical groups and prevent naming conflicts."
  },
  {
    question: "How do you declare a namespace in PHP?",
    answer: "You declare a namespace in PHP using the namespace keyword followed by the namespace name."
  },
  {
    question: "What is autoloading in PHP?",
    answer: "Autoloading in PHP is the process of automatically loading PHP classes without the need for manual includes or requires."
  },
  {
    question: "What is a trait in PHP?",
    answer: "A trait in PHP is a mechanism for code reuse in single inheritance languages such as PHP."
  },
  {
    question: "How do you use a trait in PHP?",
    answer: "You use a trait in PHP by using the use keyword followed by the trait name."
  },
  {
    question: "What is a PHP session?",
    answer: "A PHP session is a way to store information across multiple pages for a single user."
  },
  {
    question: "How do you start a session in PHP?",
    answer: "You start a session in PHP using the session_start() function."
  },
  {
    question: "What is a PHP cookie?",
    answer: "A PHP cookie is a small piece of data that is stored on the user's computer."
  },
  {
    question: "How do you set a cookie in PHP?",
    answer: "You set a cookie in PHP using the setcookie() function."
  },
  {
    question: "What is the difference between $_GET and $_POST in PHP?",
    answer: "$_GET and $_POST are both PHP superglobals used to collect form data, but $_GET sends data via the URL while $_POST sends data via HTTP POST method."
  },
  {
    question: "What is SQL injection?",
    answer: "SQL injection is a web security vulnerability that allows an attacker to interfere with the queries that an application makes to its database."
  },
  {
    question: "How do you prevent SQL injection in PHP?",
    answer: "You can prevent SQL injection in PHP by using prepared statements and parameterized queries."
  },
  {
    question: "What is cross-site scripting (XSS)?",
    answer: "Cross-site scripting (XSS) is a web security vulnerability that allows attackers to inject malicious scripts into web pages viewed by other users."
  },
  {
    question: "How do you prevent cross-site scripting (XSS) attacks in PHP?",
    answer: "You can prevent cross-site scripting (XSS) attacks in PHP by properly sanitizing and validating user input."
  },
  {
    question: "What is a PHP framework?",
    answer: "A PHP framework is a collection of classes and functions that provides a structure for web application development."
  },
  {
    question: "What are some popular PHP frameworks?",
    answer: "Some popular PHP frameworks include Laravel, Symfony, CodeIgniter, and Yii."
  },
  {
    question: "What is Composer in PHP?",
    answer: "Composer is a dependency manager for PHP that allows you to manage libraries and packages in your PHP projects."
  },
  {
    question: "How do you install Composer?",
    answer: "You can install Composer by downloading and running the Composer installer script."
  },
  {
    question: "What is PSR in PHP?",
    answer: "PSR stands for PHP Standards Recommendation, which are a set of PHP coding standards."
  },
  {
    question: "What is PSR-4 in PHP?",
    answer: "PSR-4 is a PHP Standards Recommendation that defines a standard for autoloading classes from file paths."
  },
  {
    question: "What is the purpose of PSR-12 in PHP?",
    answer: "PSR-12 is a PHP Standards Recommendation that provides a coding style guide for PHP code."
  },
  {
    question: "What is a closure in PHP?",
    answer: "A closure in PHP is an anonymous function that can be used as a parameter or returned as a value."
  },
  {
    question: "How do you define a closure in PHP?",
    answer: "You define a closure in PHP using the function keyword followed by parameters and the function body."
  },
  {
    question: "What is the use of the use keyword in PHP closures?",
    answer: "The use keyword in PHP closures is used to import variables from the parent scope into the closure."
  },
  {
    question: "What is the use of the yield keyword in PHP?",
    answer: "The yield keyword in PHP is used to pause execution of a function and return an intermediate result."
  },
  {
    question: "What is a generator in PHP?",
    answer: "A generator in PHP is a special type of function that allows you to iterate over a set of data without storing it all in memory at once."
  },
  {
    question: "How do you define a generator in PHP?",
    answer: "You define a generator in PHP using the function keyword followed by parameters and the yield keyword."
  },
  {
    question: "What is PHP Data Objects (PDO)?",
    answer: "PHP Data Objects (PDO) is a PHP extension that provides a uniform interface for accessing databases."
  },
  {
    question: "How do you connect to a database using PDO in PHP?",
    answer: "You connect to a database using PDO in PHP by creating a new PDO object with the database connection details."
  },
  {
    question: "What is a prepared statement in PDO?",
    answer: "A prepared statement in PDO is a feature that allows you to execute the same SQL statement repeatedly with high efficiency."
  },
  {
    question: "What is the purpose of the bindParam() method in PDO?",
    answer: "The bindParam() method in PDO is used to bind a PHP variable to a parameter in a prepared statement."
  },
  {
    question: "What is the purpose of the execute() method in PDO?",
    answer: "The execute() method in PDO is used to execute a prepared statement."
  },
  {
    question: "What is the fetch() method in PDO?",
    answer: "The fetch() method in PDO is used to fetch the next row from a result set."
  },
  {
    question: "What is the fetchAll() method in PDO?",
    answer: "The fetchAll() method in PDO is used to fetch all rows from a result set."
  },
  {
    question: "What is a PDOException in PHP?",
    answer: "A PDOException in PHP is an exception that is thrown when an error occurs while accessing a database with PDO."
  },
  {
    question: "How do you handle errors in PDO?",
    answer: "You can handle errors in PDO by using try-catch blocks to catch PDOException."
  },
  {
    question: "What is a closure in PHP?",
    answer: "A closure in PHP is an anonymous function that can be used as a parameter or returned as a value."
  },
  {
    question: "What is the use of the use keyword in PHP closures?",
    answer: "The use keyword in PHP closures is used to import variables from the parent scope into the closure."
  },
  {
    question: "What is the use of the yield keyword in PHP?",
    answer: "The yield keyword in PHP is used to pause execution of a function and return an intermediate result."
  },
  {
    question: "What is a generator in PHP?",
    answer: "A generator in PHP is a special type of function that allows you to iterate over a set of data without storing it all in memory at once."
  },
  {
    question: "How do you define a generator in PHP?",
    answer: "You define a generator in PHP using the function keyword followed by parameters and the yield keyword."
  },
  {
    question: "What is PHP Data Objects (PDO)?",
    answer: "PHP Data Objects (PDO) is a PHP extension that provides a uniform interface for accessing databases."
  },
  {
    question: "How do you connect to a database using PDO in PHP?",
    answer: "You connect to a database using PDO in PHP by creating a new PDO object with the database connection details."
  },
  {
    question: "What is a prepared statement in PDO?",
    answer: "A prepared statement in PDO is a feature that allows you to execute the same SQL statement repeatedly with high efficiency."
  },
  {
    question: "What is the purpose of the bindParam() method in PDO?",
    answer: "The bindParam() method in PDO is used to bind a PHP variable to a parameter in a prepared statement."
  },
  {
    question: "What is the purpose of the execute() method in PDO?",
    answer: "The execute() method in PDO is used to execute a prepared statement."
  },
  {
    question: "What is the fetch() method in PDO?",
    answer: "The fetch() method in PDO is used to fetch the next row from a result set."
  },
  {
    question: "What is the fetchAll() method in PDO?",
    answer: "The fetchAll() method in PDO is used to fetch all rows from a result set."
  },
  {
    question: "What is a PDOException in PHP?",
    answer: "A PDOException in PHP is an exception that is thrown when an error occurs while accessing a database with PDO."
  },
  {
    question: "How do you handle errors in PDO?",
    answer: "You can handle errors in PDO by using try-catch blocks to catch PDOException."
  }
];
