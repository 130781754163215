// Import programming language data
import { htmlData } from './Data/Tutorial Data/HTML Data/htmldata';
import { cssData } from './Data/Tutorial Data/CSS Data/cssdata';
import { jsData } from './Data/Tutorial Data/JS Data/jsdata';
import { phpData } from './Data/Tutorial Data/PHP Data/phpdata';
import { pythonData } from './Data/Tutorial Data/Python Data/pythondata';
import { rubyData } from './Data/Tutorial Data/Ruby Data/rubydata';
import { reactData } from './Data/Tutorial Data/React Data/reactdata';
import { xmlData } from './Data/Tutorial Data/XML Data/xmldata';
import { aiData } from './Data/Tutorial Data/AI Data/aidata';
import { sqlData } from './Data/Tutorial Data/SQL Data/sqldata';

// Import programming language code
import { htmlCode } from './Data/Tutorial Data/HTML Data/htmlcode';
import { cssCode } from './Data/Tutorial Data/CSS Data/csscode';
import { jsCode } from './Data/Tutorial Data/JS Data/jscode';
import { phpCode } from './Data/Tutorial Data/PHP Data/phpcode';
import { pythonCode } from './Data/Tutorial Data/Python Data/pythoncode';
import { rubyCode } from './Data/Tutorial Data/Ruby Data/rubycode';
import { reactCode } from './Data/Tutorial Data/React Data/reactcode';
import { xmlCode } from './Data/Tutorial Data/XML Data/xmlcode';
import { aiCode } from './Data/Tutorial Data/AI Data/aicode';
import { sqlCode } from './Data/Tutorial Data/SQL Data/sqlcode';


// Import General data
import { generalData } from './Data/General Data/generaldata.js';
import { subjectData } from './Data/General Data/subjectdata.js';


// Import Personal Data
import { personalData } from './Data/Personal Data/personaldata';
import { greetingData } from './Data/Personal Data/greetingdata';
import { helpData } from './Data/Personal Data/helpdata.js';
import { discussionData } from './Data/Personal Data/discussiondata.js';

// Import About data
import { aboutData } from './Data/BetaThread Data/aboutdata.js';


// Import Continent data
import { africaData } from './Data/Continent Data/africa.js';
import { asiaData } from './Data/Continent Data/asia.js';
import { antarcticaData } from './Data/Continent Data/antarctica.js';
import { australiaData } from './Data/Continent Data/australia.js';
import { europeData } from './Data/Continent Data/europe.js';
import { northamericaData } from './Data/Continent Data/northamerica.js';
import { southamericaData } from './Data/Continent Data/southamerica.js'; // Import Exams data
import { africaexamsData } from './Data/Exams Data/africaexams.js';
import { antarticaexamsData } from './Data/Exams Data/antarticaexams.js';
import { asiaexamsData } from './Data/Exams Data/asiaexams.js';
import { australiaexamsData } from './Data/Exams Data/australiaexams.js';
import { europexamsData } from './Data/Exams Data/europexams.js';
import { northamericaexamsData } from './Data/Exams Data/northamericaexams.js';
import { southamericaexamsData } from './Data/Exams Data/southamericaexams.js';






export const data = [
 
   // Programming languages
   ...htmlData,
   ...cssData,
   ...jsData,
   ...phpData,
   ...pythonData,
   ...rubyData,
   ...reactData,
   ...xmlData,
   ...aiData,
   ...sqlData,

   // Programming language code
   ...htmlCode,
   ...cssCode,
   ...jsCode,
   ...phpCode,
   ...pythonCode,
   ...rubyCode,
   ...reactCode,
   ...xmlCode,
   ...aiCode,
   ...sqlCode,

   // Personal questions
   ...personalData,
   ...greetingData,
   ...helpData,
   ...discussionData,

   // General information
   ...generalData,
   ...subjectData,

   // About information
   ...aboutData,


   // Continent information
   ...africaData,
   ...antarcticaData,
   ...asiaData,
   ...australiaData,
   ...europeData,
   ...northamericaData,
   ...southamericaData,


   // Exams information
   ...africaexamsData,
   ...antarticaexamsData,
   ...asiaexamsData,
   ...australiaexamsData,
   ...europexamsData,
   ...northamericaexamsData,
   ...southamericaexamsData,

];
