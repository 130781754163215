import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/SQLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import { Helmet } from "react-helmet";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function SQLInsert() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const insertSingleRowCode = `
-- Insert a single row into Employees table
INSERT INTO Employees (Name, Age, Department, Salary)
VALUES ('Alice', 30, 'HR', 5000);
  `;

  const insertMultipleRowsCode = `
-- Insert multiple rows into Employees table
INSERT INTO Employees (Name, Age, Department, Salary)
VALUES
  ('Bob', 25, 'Engineering', 6000),
  ('Charlie', 35, 'Finance', 7000);
  `;

  return (
    <body>
      <Helmet>
        <title>SQL INSERT Statement</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="SQL INSERT Statement, SQL Tutorial, INSERT INTO, VALUES" />
        <meta name="description" content="Learn about the SQL INSERT statement, including syntax, examples, and inserting single and multiple rows into a table." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/SQL_select"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_update"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>SQL INSERT Statement</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>The <code>INSERT</code> statement is used to insert new records (rows) into a table in a database.</p>

            <h2>Basic Syntax</h2>
            <p>The basic syntax of an <code>INSERT</code> statement is:</p>
            <SyntaxHighlighterComponent code={insertSingleRowCode} language="sql" />

            <p>In this example:</p>
            <ul>
              <li><code>Employees</code> is the name of the table where the data is inserted.</li>
              <li><code>Name</code>, <code>Age</code>, <code>Department</code>, and <code>Salary</code> are the columns into which data is inserted.</li>
              <li><code>'Alice', 30, 'HR', 5000</code> are the values inserted into the respective columns.</li>
            </ul>

            <h2>Inserting Multiple Rows</h2>
            <p>You can also insert multiple rows into a table using a single <code>INSERT</code> statement:</p>
            <SyntaxHighlighterComponent code={insertMultipleRowsCode} language="sql" />

            <p>In this example:</p>
            <ul>
              <li>Each set of values enclosed in parentheses represents a row to be inserted.</li>
              <li>Multiple rows are separated by commas.</li>
            </ul>

            <h2>Example Table</h2>
            <p>Consider the following table named <code>Employees</code> where data is being inserted:</p>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Department</th>
                  <th>Salary</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Alice</td>
                  <td>30</td>
                  <td>HR</td>
                  <td>5000</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Bob</td>
                  <td>25</td>
                  <td>Engineering</td>
                  <td>6000</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Charlie</td>
                  <td>35</td>
                  <td>Finance</td>
                  <td>7000</td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/SQL_select"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/SQL_update"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
