import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from "../../css/styles.module.css";
import "../../css/success-modal.css";
import ScrollToTopLink from "../../components/ScrollToTop";
import { FaEnvelope, FaEye, FaEyeSlash, FaLock, FaTimes } from "react-icons/fa";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false); // State to manage "Remember me" checkbox
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous route
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      alert("Please enter your email");
      return;
    }
    try {
      const response = await fetch("https://betathread.com/auth/forgot-password", {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to send reset email. Please try again.");
      }

      alert(data.message);
      navigate("/email-sent");
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };
  
  return (
    <body>
      <Helmet>
        <title>Forgot Passowrd</title>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-compatible" content="IE-edge" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      </Helmet>

      <div className={styles.logoContainer}>
        <ScrollToTopLink to="/">
          <img src="./images/betathread-light.png" className={styles.homebtn} alt="Home Page" />
        </ScrollToTopLink>
      </div>
      <div className={styles.closeIconContainer}>
        <button className={styles.closebtn} onClick={handleGoBack}>
          <i className="material-icons"><FaTimes /></i>
        </button>
      </div>
      <div className={styles.container}>
        <div className={styles.form_container}>
          <div className={styles.left}>
            <img className={styles.img} src="./images/login.jpg" alt="forgot password" />
          </div>

          <div className={styles.right}>
            <h2 className={styles.heading}>Forgot Password</h2><br /><br /><br />
           
            <form onSubmit={handleSubmit}>
              <div method="post">
                <div className="input-cnt">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    className="input"
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <i className="material-icons"><FaEnvelope /></i>
                </div>
              
                <br />

               
                <button className="button"
                  style={{ backgroundColor: "darkblue", color: "white", width: "100%", borderRadius: "10px", fontSize: "14px", fontWeight: "normal", fontFamily: "inherit" }}
                  type="submit">
                 Send Reset Email
                </button>

                <br />
              </div>
            </form>
          </div>
        </div>
      </div>
    </body>
  );
}

export default ForgotPassword;
