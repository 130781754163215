import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/PythonSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function PythonSets() {
    const newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black'
    };
    const secondCode = {
        color: 'black'
    };

    const code1 = `
# Creating a Set
my_set = {1, 2, 3, 4, 5}
print(my_set)  # Output: {1, 2, 3, 4, 5}

# Creating an Empty Set
empty_set = set()
print(empty_set)  # Output: set()
    `;

    const code2 = `
# Adding Elements to a Set
my_set.add(6)
print(my_set)  # Output: {1, 2, 3, 4, 5, 6}

# Adding Multiple Elements
my_set.update([7, 8, 9])
print(my_set)  # Output: {1, 2, 3, 4, 5, 6, 7, 8, 9}
    `;

    const code3 = `
# Removing Elements from a Set
my_set.remove(3)
print(my_set)  # Output: {1, 2, 4, 5, 6, 7, 8, 9}

# Removing Elements Safely with discard()
my_set.discard(10)  # No error if element not found
print(my_set)  # Output: {1, 2, 4, 5, 6, 7, 8, 9}

# Removing and Returning an Arbitrary Element
popped_element = my_set.pop()
print(popped_element)  # Output: 1 (or any other element, sets are unordered)
print(my_set)  # Output: {2, 4, 5, 6, 7, 8, 9}

# Clearing a Set
my_set.clear()
print(my_set)  # Output: set()
    `;

    const code4 = `
# Set Operations
a = {1, 2, 3, 4}
b = {3, 4, 5, 6}

# Union
union_set = a | b
print(union_set)  # Output: {1, 2, 3, 4, 5, 6}

# Intersection
intersection_set = a & b
print(intersection_set)  # Output: {3, 4}

# Difference
difference_set = a - b
print(difference_set)  # Output: {1, 2}
reverse_difference_set = b - a
print(reverse_difference_set)  # Output: {5, 6}

# Symmetric Difference
symmetric_difference_set = a ^ b
print(symmetric_difference_set)  # Output: {1, 2, 5, 6}
    `;

    const code5 = `
# Subset and Superset
a = {1, 2, 3}
b = {1, 2, 3, 4, 5}

# Subset
is_subset = a <= b
print(is_subset)  # Output: True
print(a.issubset(b))  # Output: True

# Superset
is_superset = b >= a
print(is_superset)  # Output: True
print(b.issuperset(a))  # Output: True
    `;

    const code6 = `
# Set Comprehensions
squared_set = {x**2 for x in range(6)}
print(squared_set)  # Output: {0, 1, 4, 9, 16, 25}

# Even Squared Set
even_squared_set = {x**2 for x in range(6) if x % 2 == 0}
print(even_squared_set)  # Output: {0, 4, 16}
    `;

    const code7 = `
# Set Membership
my_set = {1, 2, 3, 4, 5}
print(3 in my_set)  # Output: True
print(6 in my_set)  # Output: False
    `;

    const code8 = `
# Copying a Set
original_set = {1, 2, 3}
copied_set = original_set.copy()
print(copied_set)  # Output: {1, 2, 3}
    `;

    const code9 = `
# Frozen Sets
frozen_set = frozenset([1, 2, 3, 4, 5])
print(frozen_set)  # Output: frozenset({1, 2, 3, 4, 5})

# Frozen Sets are immutable
# frozen_set.add(6)  # Raises AttributeError
    `;

    return (
        <body>
            <Helmet>
                <title>Python Sets</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content="Python Sets, Creating Sets, Adding Elements, Removing Elements, Set Operations, Subset, Superset, Set Comprehensions, Set Membership, Copying Sets, Frozen Sets" />
                <meta name="description" content="Learn about sets in Python, including how to create, add, and remove elements from sets. Also covers set operations, subset and superset relationships, set comprehensions, set membership, copying sets, and frozen sets." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Sidenav />
            <div className="content">
                <header className="headertutorials" style={newCode}>
                <ScrollToTopLink to="/Python_tuple_concatenation"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_set_methods"><ButtonNext /></ScrollToTopLink>
                    <h1 style={secondCode}>Python Sets</h1>
                </header>
                <Navbar />
                <main>
                    <section>
                        <p>Sets in Python are unordered collections of unique elements. They are useful for storing items without duplicates and for membership testing.</p>
                        
                        <h2>Creating a Set</h2>
                        <p>Sets are created using curly braces <code>{'{}'}</code> or the <code>set()</code> function.</p>
                        <SyntaxHighlighterComponent code={code1} language="python" />
                        
                        <h2>Adding Elements to a Set</h2>
                        <p>You can add elements to a set using the <code>add()</code> method. To add multiple elements, use the <code>update()</code> method.</p>
                        <SyntaxHighlighterComponent code={code2} language="python" />
                        
                        <h2>Removing Elements from a Set</h2>
                        <p>Elements can be removed from a set using the <code>remove()</code> or <code>discard()</code> methods. The <code>pop()</code> method removes and returns an arbitrary element, while the <code>clear()</code> method removes all elements.</p>
                        <SyntaxHighlighterComponent code={code3} language="python" />
                        
                        <h2>Set Operations</h2>
                        <p>Perform mathematical operations like union, intersection, difference, and symmetric difference on sets.</p>
                        <SyntaxHighlighterComponent code={code4} language="python" />
                        
                        <h2>Subset and Superset</h2>
                        <p>Check if a set is a subset or superset of another set using the <code>issubset()</code> and <code>issuperset()</code> methods or the <code>&lt;=</code> and <code>&t;=</code> operators.</p>
                        <SyntaxHighlighterComponent code={code5} language="python" />
                        
                        <h2>Set Comprehensions</h2>
                        <p>Create sets using comprehensions, similar to list comprehensions.</p>
                        <SyntaxHighlighterComponent code={code6} language="python" />
                        
                        <h2>Set Membership</h2>
                        <p>Check if an element is present in a set using the <code>in</code> keyword.</p>
                        <SyntaxHighlighterComponent code={code7} language="python" />
                        
                        <h2>Copying a Set</h2>
                        <p>Create a copy of a set using the <code>copy()</code> method.</p>
                        <SyntaxHighlighterComponent code={code8} language="python" />
                  </section>
                </main>
                <div className="head">
                    <ScrollToTopLink to="/Python_tuple_concatenation"><ButtonPrevious /></ScrollToTopLink>
                    <ScrollToTopLink to="/Python_set_methods"><ButtonNext /></ScrollToTopLink>
                </div>
            </div>
            <Footertutorials />
        </body>
    );
}
