import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonPrevious from "../../components/Buttonprevious";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import RubyMetatag from "../../components/Ruby_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function Rubycomments() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    const code = `
    puts "I love Ruby!" # I am a single line comment
`;
const code2 = `
puts "I love Ruby!"
=begin
I am a multiline comment, i can span multiple lines
I am a multiline comment, i can span multiple lines
I am a multiline comment, i can span multiple lines
I am a multiline comment, i can span multiple lines
=end
`;



    return (
   <body>
   <Helmet>
        
        <title>Ruby Comments</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="Ruby comments, Step-by-step Ruby programming tutorials, Ruby coding exercises for beginners, Advanced Ruby coding techniques, Best practices for programming beginners,Search Engine Optimization Guide and tips
       Ruby programming tips for real-world applications," />
      <meta name="description" content="Learn how Ruby comments streamline code organization, 
      enhance readability and enhance code explanation in Ruby development." />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
       </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
  
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/Ruby_classes"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Ruby_variables"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>Ruby Comments</h1>
</header>

<Navbar />

<main>
<section>
<p>Ruby comments are lines of text that explain a block of code.Comments are not displayed on the browser when
    the page is run.
</p>
<p>There are two ways of applying comments in Ruby</p>
<li>Single-line Comments</li>
<li>Multi-line Comments</li>

<h2>Single-line Comments</h2>
<p>A single-line comment begins with the # character.</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code} language="ruby" />

<h2>Multi-line Coments</h2>
<p>Multiple lines can be commented using the =begin and =end syntax.</p>
<p>The =begin and =end statements should occupy the first and last line of the comment respectively</p>
<br />
<h2>Example:</h2>
<SyntaxHighlighterComponent code={code2} language="ruby" />
</section>
</main>
<div className="head">
<ScrollToTopLink to="/Ruby_classes"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/Ruby_variables"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}