import React from 'react';
import "../css/my style.css";
import "../css/my style2.css";
import { useState } from 'react';

const SaveCodeButton = ({ code }) => {

  const handleSaveClick = () => {
    // Create a Blob containing the code
    const blob = new Blob([code], { type: 'text/plain' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element and set its attributes
    const link = document.createElement('a');
    link.href = url;
    link.download = 'code.txt';
    link.target = '_blank';

    // Simulate a click on the link to trigger the download
    link.click();

    // Clean up by revoking the URL
    URL.revokeObjectURL(url);
  };
  

  return (
    <button onClick={handleSaveClick}>
      Save Code
    </button>
  );
};

export default SaveCodeButton;
