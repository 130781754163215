import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/JS_Sidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import Tryitout from "../../components/Tryitoutbutton";
import JSMetatag from "../../components/JS_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function JSMethods() {
    var newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
    }
    var secondCode = {
        color: 'black'
    }
    var thirdCode = {
        padding: '20px'
    }
    
    const code = `
        function addNumbers() {
        var x = 20;
        var y = 30;
        var add = x + y;
        document.getElementById("demo").innerHTML = add; //displays 50
        }
      
`;
const code2 = `
            function addText() {
            var text1 = "I";
            var text2 = "am a";
            var text3 ="boy";
            var sentence = text1 + text2 + text3;
            document.getElementById("example").innerHTML = sentence;
            }
`;

const code3 = `
    function addNumbers() {
    var x = 20;
    var y = 30;
    var add = x + y;
    document.write(add); 
    }
`;
const code4 = `
var x = 100;
var y = 30;
var minus = x - y;
console.log(minus);
// the output will only be displayed in the developer console

`;
const code5 = `
var name1 = "John ";
var name2 = "Doe";
var fullname = name1 + name2;
window.alert(fullname);

`;

    return (
   <body>
        <Helmet>
    
    <title>JavaScript Methods</title>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-compatible" content="IE-edge"/>
   <meta name="Keywords" content="JavaScript Methods, Step-by-step JavaScript programming tutorials, JavaScript coding exercises for beginners, Advanced JavaScript coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tips
   JavaScript programming tips for real-world applications," />
  <meta name="description" content="document.getElementById() Method, document.write() Method, console.log() Method,
  window.alert() Method" />
   <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  
   </Helmet>
    <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
   
    <div className="content">

<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/JS_display_output"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_variables"><ButtonNext /></ScrollToTopLink>
   <h1 style={secondCode}>JavaScript Methods</h1>
</header>

<Navbar />

<main>
    <section>
    <p>In this lesson we will learn about all the JavaScript methods.
</p>
<h2>document.getElementById() Method</h2>
<p>This method provides the innerHTML property which we can use to dynamically change the content of the specified
    element.Here we make use of the document.getElementById() as a function.
</p>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code} language="javascript" />
<ScrollToTopLink to="/try_js10"><Tryitout /></ScrollToTopLink>
    <br />
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code2} language="javascript" />
<ScrollToTopLink to="/try_js11"><Tryitout /></ScrollToTopLink>
 <h2>document.write() Method</h2>
 <p>We make use of the document.write() method to write the content of the document.</p>
 <p>This method should only be used for testing purposes because this method erases all the existing HTML in the 
    current page.
 </p>

 <h2>Example:</h2>
 <SyntaxHighlighterComponent code={code3} language="javascript" />
<ScrollToTopLink to="/try_js12"><Tryitout /></ScrollToTopLink>
 <h2>console.log() Method</h2>
 <p>The console.log() method is used to write to the developers console, here is an example of how it can be used.</p>
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code4} language="javascript" />
<ScrollToTopLink to="/try_js13"><Tryitout /></ScrollToTopLink>
    <h2>window.alert() Method</h2>
    <p>The window.alert() can be used to display a dialog box and alternatively the alert() function to
        display the output.
    </p>
    <p>We can also display the output without the use of a function for any of the methods.Take a look at the
        example below.
    </p>
    <h2>Example:</h2>
    <SyntaxHighlighterComponent code={code5} language="javascript" />
<ScrollToTopLink to="/try_js14"><Tryitout /></ScrollToTopLink>
    <br />
</section>
</main>
<div className="head">
<ScrollToTopLink to="/JS_display_output"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/JS_variables"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}