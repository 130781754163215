import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/HTMLSidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import ButtonPrevious from "../../components/Buttonprevious";
import HTMLMetatag from "../../components/HTML_Metatag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../../components/ScrollToTop";



export default function HTMLeditors() {
    var newCode = {
        padding: '20px',
        textAlign: 'left',
        background: 'white',
        color: 'black',
        display: 'grid'
        }
        var secondCode = {
            color: 'black'
        }

        var newCode = {
            color: 'white',
          };
      
    return (
   <body>
     <Helmet>    
        <title>HTML Editors</title>
       <meta charset="UTF-8" />
       <meta http-equiv="X-UA-compatible" content="IE-edge"/>
       <meta name="Keywords" content="HTML Editors, How to save HTML Files, HTML Editor
         Step-by-step HTML programming tutorials, HTML coding exercises for beginners, Advanced HTML coding techniques, Best practices for programming beginners, Search Engine Optimization Guide and tipsHTML programming tips for real-world applications," />
       <meta name="description" content="Inorder to create or edit your HTML files you will need a text editor for exmple Visual Studio Code or own own inbuilt try it out editor" />
       <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      
    </Helmet>
        <br />
        <br />
        <br />
        <br />
        <br />
    <Sidenav />
    
    <div className="content">
<header className="headertutorials" style={newCode}>
<ScrollToTopLink to="/HTML_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_comments"><ButtonNext /></ScrollToTopLink>

   <h1 style={secondCode}>HTML Editors</h1>
</header>

<Navbar />

<main>
    <section>
    <p>Inorder to create or edit your HTML files you will need a text editor.</p>
<p>Here is a list of some free apps you could use to edit HTML files.</p>
<li>Visual Studio Code</li>
<li>TextEdit</li>
<li>Notepad</li>
<li>Notepad++</li>
<li>VIM</li>
<li>ATOM</li>
<li>HTML Editor(android app)</li>
<br />
<p>You can also make use of our own customized try it out editor </p>
<div className="sampling">
     <h1 style={{color: 'white', fontSize: '48px'}}>HTML Editor</h1>
     <p style={newCode}>You can make use of our frontend code editor to run HTML code.</p>
     <ScrollToTopLink to="/try_html1"><button className="buttona">HTML Editor</button></ScrollToTopLink>
</div>
<h2>How to save HTML Files ?</h2>
<p>To save your an HTML file follow these simple steps</p>
<ol type="1">
<li>Create a new file on your device e.g, pc, mobile phone.</li>
<li>Choose whatever name you want as the file name e.g, firstpage.</li>
<li>Add the .html or .htm extenion after the file name.</li>
<li>Click save and start coding!</li>
</ol>

</section>
 
</main>
<div className="head">
<ScrollToTopLink to="/HTML_Tutorial_intro"><ButtonPrevious /></ScrollToTopLink>
<ScrollToTopLink to="/HTML_comments"><ButtonNext /></ScrollToTopLink>
</div>

</div>

<Footertutorials />
   </body>
    )
}