import React from "react";
import "../../css/my style.css";
import "../../css/my style3.css";
import "../../components/my script";
import Sidenav from "../../components/Sidenav/RubySidenav";
import Navbar from "../../components/navbar";
import Footertutorials from "../../components/Footer-tutorials";
import SyntaxHighlighterComponent from "../../components/SyntaxHighlighterComponent";
import ButtonNext from "../../components/Buttonnext";
import { Helmet } from "react-helmet";
import ButtonPrevious from "../../components/Buttonprevious";
import ScrollToTopLink from "../../components/ScrollToTop";

export default function RubyLoops() {
  const newCode = {
    padding: '20px',
    textAlign: 'left',
    background: 'white',
    color: 'black'
  };
  const secondCode = {
    color: 'black'
  };

  const whileLoopCode = `
# While Loop
i = 0
while i < 5 do
  puts i
  i += 1
end
# Output: 0 1 2 3 4
  `;

  const untilLoopCode = `
# Until Loop
i = 0
until i > 5 do
  puts i
  i += 1
end
# Output: 0 1 2 3 4 5
  `;

  const forLoopCode = `
# For Loop
for i in 0..5
  puts i
end
# Output: 0 1 2 3 4 5
  `;

  const timesLoopCode = `
# Times Loop
5.times do |i|
  puts i
end
# Output: 0 1 2 3 4
  `;

  const eachLoopCode = `
# Each Loop
(0..5).each do |i|
  puts i
end
# Output: 0 1 2 3 4 5
  `;

  return (
    <body>
      <Helmet>
        <title>Ruby Loops</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="Ruby Loops, While Loop, Until Loop, For Loop, Times Loop, Each Loop" />
        <meta name="description" content="Learn about loops in Ruby, including while, until, for, times, and each loops." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Sidenav />
      <div className="content">
        <header className="headertutorials" style={newCode}>
          <ScrollToTopLink to="/Ruby_conditionals"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_methods"><ButtonNext /></ScrollToTopLink>
          <h1 style={secondCode}>Ruby Loops</h1>
        </header>
        <Navbar />
        <main>
          <section>
            <p>Loops in Ruby allow you to execute a block of code multiple times. The most common types of loops in Ruby are <code>while</code>, <code>until</code>, <code>for</code>, <code>times</code>, and <code>each</code>.</p>

            <h2>While Loop</h2>
            <p>The <code>while</code> loop executes a block of code as long as the condition is true.</p>
            <SyntaxHighlighterComponent code={whileLoopCode} language="ruby" />
            <p>In the above example, the <code>while</code> loop runs as long as <code>i</code> is less than 5.</p>

            <h2>Until Loop</h2>
            <p>The <code>until</code> loop executes a block of code until the condition is true.</p>
            <SyntaxHighlighterComponent code={untilLoopCode} language="ruby" />
            <p>In the above example, the <code>until</code> loop runs until <code>i</code> is greater than 5.</p>

            <h2>For Loop</h2>
            <p>The <code>for</code> loop iterates over a range of values.</p>
            <SyntaxHighlighterComponent code={forLoopCode} language="ruby" />
            <p>In the above example, the <code>for</code> loop runs from 0 to 5.</p>

            <h2>Times Loop</h2>
            <p>The <code>times</code> loop executes a block of code a specified number of times.</p>
            <SyntaxHighlighterComponent code={timesLoopCode} language="ruby" />
            <p>In the above example, the <code>times</code> loop runs 5 times.</p>

            <h2>Each Loop</h2>
            <p>The <code>each</code> loop iterates over a collection of elements.</p>
            <SyntaxHighlighterComponent code={eachLoopCode} language="ruby" />
            <p>In the above example, the <code>each</code> loop runs over the range from 0 to 5.</p>
          </section>
        </main>
        <div className="head">
          <ScrollToTopLink to="/Ruby_conditionals"><ButtonPrevious /></ScrollToTopLink>
          <ScrollToTopLink to="/Ruby_methods"><ButtonNext /></ScrollToTopLink>
        </div>
      </div>
      <Footertutorials />
    </body>
  );
}
